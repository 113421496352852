/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import {Button} from '@spothero/ui';
import SpotListItemBase from 'common/spot-card-base/v2';
import FacilitySummary from 'common/facility-summary/v2';
import {routeToSpotDetails} from 'router/router-utils';
import {push as pushTo} from 'store/router/router-actions';
import {PROPTYPE_FACILITY_SUMMARY} from '@/proptypes/index';
import SpotCardAvailableSpotsBadge from '../spot-card-available-spots-badge';
import {showSpotsLeft} from 'utils/spot-left';
import {setSpotDetailReferrer as setSpotDetailReferrerTo} from 'store/spot/spot-actions';
import DynamicSpotListItemButton from '../spot-card-dynamic-CTA-button';
import {FACILITY_SUMMARY_VIEW_TYPES} from 'common/facility-summary/utils';
import {useDispatch} from 'react-redux';

export const SpotListItemTransient = ({
    spot,
    isActive,
    isHighlighted,
    isAdmin,
    isRebookingReservation,
    updateSelectedSpot,
    onShowDetailsClick,
    onBookSpotClick,
    referer = 'list',
    inExpressExperiment,
}) => {
    const {location} = useHistory();
    const dispatch = useDispatch();
    const {spotId, availability, facilityType} = spot;
    const {available, availableSpaces} = availability;
    const containerClasses = classNames(
        {'SpotListItem-active': isActive},
        {'SpotListItem-hovered': isHighlighted},
        {unavailable: !available}
    );

    const handleShowDetailsClick = evt => {
        evt.preventDefault();

        setSpotDetailReferrerTo(referer);
        routeToSpotDetails({
            method: (...args) => dispatch(pushTo(...args)),
            location,
            spotId,
            historyState: {referrer: referer},
        });

        if (onShowDetailsClick) {
            onShowDetailsClick({spotId});
        }
    };

    const handleBookSpotClick = evt => {
        evt.preventDefault();
        evt.stopPropagation();

        if (onBookSpotClick) {
            onBookSpotClick({spotId});
        }

        updateSelectedSpot({
            spot,
            referrer: referer,
            goToSpotUrl: true,
            isUserInExpressCheckoutExperiment: inExpressExperiment,
        });
    };

    const isShowSpotsLeftVisible = showSpotsLeft({
        available,
        availableSpaces,
        facilityType,
    });

    return (
        <SpotListItemBase
            spotId={spotId}
            additionalClasses={containerClasses}
            onClick={handleShowDetailsClick}
            MainContent={
                <FacilitySummary
                    spot={spot}
                    isAdmin={isAdmin}
                    isShowSpotsLeftVisible={isShowSpotsLeftVisible}
                    view={FACILITY_SUMMARY_VIEW_TYPES.SPOT_LIST}
                    CTAs={
                        <>
                            {isShowSpotsLeftVisible ? (
                                <SpotCardAvailableSpotsBadge
                                    availableSpots={availableSpaces}
                                />
                            ) : null}
                            <Button
                                variant="tertiary"
                                className="show-details hide-on-mobile"
                                onClick={handleShowDetailsClick}
                            >
                                Details
                            </Button>
                            <DynamicSpotListItemButton
                                isAdmin={isAdmin}
                                available={available}
                                isRebookingReservation={isRebookingReservation}
                                isHovered={isHighlighted}
                                onClick={handleBookSpotClick}
                            />
                        </>
                    }
                />
            }
        />
    );
};

SpotListItemTransient.propTypes = {
    spot: PROPTYPE_FACILITY_SUMMARY,
    isActive: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isRebookingReservation: PropTypes.bool,
    updateSelectedSpot: PropTypes.func,
    onShowDetailsClick: PropTypes.func,
    onBookSpotClick: PropTypes.func,
    referer: PropTypes.string,
    inExpressExperiment: PropTypes.bool,
};

export default React.memo(SpotListItemTransient);
