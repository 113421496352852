/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-param-description */
/* eslint-disable jsdoc/require-returns */
import APIUtils from '@spothero/utils/api';
/**
 * Object containing all Vehicle API methods
 *
 * @module api/vehicle
 */
const VehicleAPI = {
    /**
     *
     * @param requestData
     * @param userId
     * @function add
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Users/get_users_user_id_vehicles|Documentation}
     */
    add(requestData, userId) {
        return APIUtils.post(`/users/${userId}/vehicles/`, requestData, {
            headers: { 'SpotHero-Version': '2019-08-22' },
        });
    },
    /**
     * @function getVehicleInfos
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Vehicles/get_vehicle_types|Documentation}
     */
    getVehicleInfos(search) {
        return APIUtils.get('vehicle-types/', {
            search,
        });
    },
    /**
     *
     * @function getColorOptions
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Vehicles/get_vehicle_colors|Documentation}
     * @returns {Promise<object>} - API response
     */
    getColorOptions() {
        return APIUtils.get('vehicle-colors/');
    },
};
export default VehicleAPI;
