import React from 'react';
import PropTypes from 'prop-types';
import MultiRateChooser from 'common/spot-details/multi-rate-select';
import SingleRate from 'common/spot-details/multi-rate-select/single-rate/SingleRate';
import {useDispatch, useSelector} from 'react-redux';
import {getPrice} from 'hooks/spot/price/use-price';
import {useShowTotal} from 'store/selectors/hooks/useShowTotal';
import {setPriceBreakdownModalSpotId} from 'store/search/search-actions';

const ParkingOptionsMonthly = ({
    availableRates,
    selectedRate,
    onMultiRateChange,
    onRateDetailsClick,
    spotId,
}) => {
    const dispatch = useDispatch();
    const {title} = selectedRate;
    const isPowerBooking = useSelector(
        state => state.searchRequest.powerBooking
    );
    const showTotal = useShowTotal();
    const price = getPrice({
        spot: {
            // @ts-expect-error
            selectedRate,
        },
        isPowerBooking,
        showTotal,
    });
    const priceLabel = showTotal ? 'Total' : '/mo';
    const handleMultiRatePriceLabelClick = newSelectedRate => {
        dispatch(setPriceBreakdownModalSpotId(null, newSelectedRate));
    };
    const handleSingleRatePriceLabelClick = () => {
        dispatch(setPriceBreakdownModalSpotId(spotId));
    };

    return (
        <div className="ParkingOptions">
            <h3 className="ParkingOptions-header">Parking Options</h3>
            {availableRates.length > 1 && (
                <MultiRateChooser
                    selectedRate={selectedRate}
                    rates={availableRates}
                    priceLabel={priceLabel}
                    onMultiRateChange={onMultiRateChange}
                    onInfoButtonClick={onRateDetailsClick}
                    onPriceLabelClick={
                        showTotal ? handleMultiRatePriceLabelClick : null
                    }
                />
            )}
            {availableRates.length === 1 && (
                <SingleRate
                    title={title}
                    priceLabel={priceLabel}
                    price={price}
                    showTotal={showTotal}
                    onPriceLabelClick={
                        showTotal ? handleSingleRatePriceLabelClick : null
                    }
                />
            )}
        </div>
    );
};

ParkingOptionsMonthly.propTypes = {
    spotId: PropTypes.string || PropTypes.number,
    availableRates: PropTypes.arrayOf(PropTypes.object),
    selectedRate: PropTypes.object,
    onMultiRateChange: PropTypes.func,
    onRateDetailsClick: PropTypes.func,
};

export default ParkingOptionsMonthly;
