import SearchTracking from 'utils/search-tracking';
import SegmentUtils from 'utils/segment';
import { createTopSearchResultsList } from 'segment/utils/top-search-results';
import { getCitySlug, getParkingType, getPageType, getSearchFilters, getSearchLeadTime, getSearchLength, getSearchQuery, getSortBy, } from 'store/selectors/selectors';
import transformFilters from 'segment/utils/filters';
import { getTotalPriceUserPreference, TotalPriceSessionPreference, } from 'utils/total-price-session-utils';
export default function trackMapAdjusted({ state, args: { actionType, mapCenter, mapBounds }, }) {
    const { spots: { data: spots }, destination: { data: destination }, } = state;
    const sortBy = getSortBy(state);
    const airport = destination?.airport || null;
    const { searchUUID, actionUUID } = SearchTracking.getValues();
    // Below is bad actor prevention
    const isLongitudeLessThanThreeSpins = Math.abs(mapCenter.longitude) <= 720;
    const parkingType = getParkingType(state);
    const filters = getSearchFilters(state);
    const showTotal = getTotalPriceUserPreference() === TotalPriceSessionPreference.On;
    const properties = {
        page_type: getPageType(state),
        query: getSearchQuery(state),
        search_lead_time: getSearchLeadTime(state),
        search_length: getSearchLength(state),
        filters: transformFilters(filters),
        sort_by: sortBy,
        action_id: actionUUID,
        action_type: actionType,
        availability_check: actionType !== 'ZOOM_IN',
        city: getCitySlug(state),
        map_center: {
            latitude: mapCenter.latitude,
            longitude: isLongitudeLessThanThreeSpins
                ? mapCenter.longitude
                : null,
        },
        map_coordinates: {
            northeast: {
                latitude: mapBounds.northEast.latitude,
                longitude: mapBounds.northEast.longitude,
            },
            southwest: {
                latitude: mapBounds.southWest.latitude,
                longitude: mapBounds.southWest.longitude,
            },
        },
        parking_type: parkingType,
        search_id: searchUUID,
        top_search_results_list: createTopSearchResultsList({
            spots,
            airport,
            sortBy,
            parkingType,
            isMonthly: parkingType === 'monthly',
            filters,
        }),
        total_price_toggle_on: showTotal,
    };
    SegmentUtils.track({
        event: 'Map Adjusted',
        properties,
        additionalOptions: {
            context: {
                protocols: {
                    event_version: 7,
                },
            },
        },
    });
}
