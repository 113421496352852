// TODO MR - both spot and store need typing before this
// DOING THIS DURING THIS WORK
import Config from '@/config/index';

export const parseWalkingDuration = ({duration, isSpotDetails}) => {
    const minutes = Math.floor(duration / 60);
    const display = `${minutes || '<1'} ${
        isSpotDetails ? 'minute walk' : 'min'
    }`;

    return display;
};

const formatMiles = {
    short: 'mi',
    long: 'miles',
};

const formatFeet = {
    short: 'ft',
    long: 'feet',
};

/**
 * Parse walking distance
 *
 * @param {object} params
 * @param {number} params.linearMeters - linear distance in meters
 * @param {number} params.walkingMeters - walking distance in meters
 * @param {string} params.country - Country code for distance units (US or CA)
 * @param {string} params.format - format of the distance (short or long)
 * @param {boolean} params.showFeetOrMeters - show feet or meters. In same cases we only want to show miles or kilometers
 * @returns {string} - formatted distance
 */
export const parseDistance = ({
    linearMeters,
    walkingMeters,
    country,
    format = 'short',
    showFeetOrMeters = true,
}) => {
    const userCountry = country || Config.cloudfrontViewerCountryCode || 'US';
    // prefer walking distance when available
    const meters = walkingMeters || linearMeters;

    if (userCountry === 'CA') {
        const formattedKilometers = `${(meters / 1000).toFixed(1)} km`;

        if (!showFeetOrMeters) {
            return formattedKilometers;
        }

        return meters < 1000 ? `${meters.toFixed(0)} m` : formattedKilometers;
    }

    const miles = meters / 1609;
    const formattedMiles = `${miles.toFixed(1)} ${formatMiles[format]}`;

    if (!showFeetOrMeters) {
        return formattedMiles;
    }

    return miles <= 0.1
        ? `${Math.round(miles * 5280)} ${formatFeet[format]}`
        : formattedMiles;
};

/**
 * Parse walking duration and distance
 *
 * @param {object} obj - params
 * @param {number} obj.linearMeters - linear meters
 * @param {number} obj.walkingMeters - walking distance in meters
 * @param {number} obj.walkingDuration - duration in milliseconds
 * @param {boolean} obj.isSpotDetails - duration in milliseconds
 * @returns {{duration: string, distance: string}} formatted duration and distance
 */
export const parseWalkingDistanceAndDuration = ({
    linearMeters,
    walkingMeters,
    walkingDuration,
    isSpotDetails,
}) => {
    const duration = walkingDuration
        ? parseWalkingDuration({duration: walkingDuration, isSpotDetails})
        : null;

    const distance = parseDistance({
        linearMeters,
        walkingMeters,
    });

    return {
        duration,
        distance,
    };
};
