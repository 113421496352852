import React from 'react';
import {Portal} from '@spothero/ui-backlog';
import SHLoader from 'common/loader';

const RouterLoader = () => {
    return (
        <Portal className="RouterLoader">
            <SHLoader />
        </Portal>
    );
};

export default RouterLoader;
