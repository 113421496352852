import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
const trackChangedMonthlyRate = ({ page, title }) => {
    const properties = {
        page,
        title,
    };
    try {
        SegmentUtils.track({
            event: 'Changed Monthly Rate',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 1,
                    },
                },
            },
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackChangedMonthlyRate',
        });
    }
};
export default trackChangedMonthlyRate;
