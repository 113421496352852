import { formatTransientFacility } from '../transient/utils/formatTransientFacility';
import ObjectUtils from '@spothero/utils/object';
import { filterSearchResponse } from '../utils/filter-search-response';
import { buildCheckoutURL } from 'api/search-v2/utils';
/**
 * This is used to figure out what the total price should be for power booking.  Used in a few places hence the export
 */
export const calculateTotalPrice = (bulkRates) => {
    try {
        return bulkRates.reduce((prev, curr) => {
            const total = curr.quote.total_price;
            return {
                currencyCode: total.currency_code,
                value: prev.value + total.value,
            };
        }, { currencyCode: '', value: 0 });
    }
    catch (e) {
        return { currencyCode: '', value: 0 };
    }
};
export const getPBPriceBreakdownItems = bulkRates => {
    if (!bulkRates || !bulkRates.length) {
        return [];
    }
    // extract price breakdown items form rates[0].quotes
    const allQuoteItems = bulkRates.reduce((acc, curr) => {
        const hasItems = curr.rates?.length > 0 && curr.rates[0].quote?.items?.length;
        const items = hasItems ? curr.rates[0].quote?.items : [];
        return [...acc, ...items];
    }, []);
    // camelize keys
    const allPriceBreakdownItems = allQuoteItems.map(item => ObjectUtils.camelizeKeys(item));
    // the items contain multiple items for the same type, one for each day
    // merge the items by type and sum the price
    const breakdownItems = allPriceBreakdownItems.reduce((acc, curr) => {
        const { type, price } = curr;
        const existingItem = acc.find(item => item.type === type);
        if (existingItem) {
            existingItem.price.value += price.value;
        }
        else {
            acc.push(curr);
        }
        return acc;
    }, []);
    return breakdownItems;
};
export const getAvailabilityRatesFromBulkRates = (rates = []) => {
    const unavailableRate = rates?.find(rate => !rate?.availability?.available);
    return unavailableRate ? unavailableRate?.availability : rates[0]?.availability;
};
/**
 * Formats data for multiple transient facilities
 *
 * @function formatBulkSearchTransientResponse
 * @see {@link https://api.staging.spothero.com//v2/docs#tag/Search/operation/searchBulkTransientParking|Documentation}
 * @param {object} searchResults - Facility data returned from the search API for a multiple facilities
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @param {Array} powerBookingPeriods - Data that holds all the booking periods
 * @returns {Array} The array of transient facilities, with data transformation applied.
 */
export function formatBulkSearchTransientResponse(searchResults, formatOptions = {}, powerBookingPeriods = []) {
    let formattedResults = searchResults.map(result => {
        const bulkRates = result?.bulk_rates?.length && result?.bulk_rates[0];
        const availability = getAvailabilityRatesFromBulkRates(result?.bulk_rates);
        const rates = bulkRates?.rates;
        const bulkSearchResult = {
            ...result,
            availability,
            rates,
        };
        const formattedBulkSearchResult = formatTransientFacility(bulkSearchResult, {
            ...formatOptions,
            isFacilitySummary: true,
        });
        const { spotId, slug: facilitySlug } = formattedBulkSearchResult;
        const powerBookingCheckoutURL = buildCheckoutURL({
            spotId,
            facilitySlug,
            rateType: 'powerBooking',
            powerBookingPeriods,
            rateId: formattedBulkSearchResult?.selectedRate?.rateId,
            powerBookingSource: formatOptions?.powerBookingSource,
        });
        return {
            ...formattedBulkSearchResult,
            ...(formattedBulkSearchResult.selectedRate && {
                selectedRate: {
                    ...formattedBulkSearchResult.selectedRate,
                    averagePrice: ObjectUtils.camelizeKeys(result?.average_price || {}),
                    subTotal: ObjectUtils.camelizeKeys(result?.subtotal || {}),
                    fullUrl: powerBookingCheckoutURL,
                    powerBookingPriceBreakdown: getPBPriceBreakdownItems(result?.bulk_rates),
                    PBTotalPrice: result?.bulk_rates.reduce((prev, curr) => {
                        const rateTotal = calculateTotalPrice(curr.rates);
                        return {
                            value: prev.value + rateTotal.value,
                            currencyCode: rateTotal.currencyCode,
                        };
                    }, { value: 0, currencyCode: '' }), //hate this but unsure how to clean it up
                },
            }),
            // Power booking does not support Multirate.
            hasMultipleRates: false,
        };
    });
    if (formatOptions.filterSpot) {
        formattedResults = filterSearchResponse(formattedResults, formatOptions.filterSpot);
    }
    return formattedResults;
}
