import intersection from 'lodash/intersection';
import { convertRatingToSlug } from 'store/filters/rating-filter-utils';
// TODO MR - 8/9/23 Add typing (spot, amenityTypes, relatedReservationTypes)
/**
 * Return array of all spots providing certain amenities
 *
 * @function filterSpotsByAmenities
 * @param {Array} spots - list of all spots
 * @param {Array} amenityTypes - list of amenity types to use as filters
 * @returns {Array} filtered spots
 */
export const filterSpotsByAmenities = (spots, amenityTypes) => {
    return spots.reduce((filteredSpots, currentSpot) => {
        const spotAmenities = currentSpot.allAmenities;
        // return spots that match all amenityTypes
        const hasAllAmenities = amenityTypes.reduce((hasAmenities, currentAmenity) => {
            return (Boolean(spotAmenities.find(amenity => amenity.type === currentAmenity)) && hasAmenities);
        }, true);
        if (hasAllAmenities) {
            filteredSpots.push(currentSpot);
        }
        return filteredSpots;
    }, []);
};
/**
 * Return array of all spots providing the desired reservation type (or related types)
 *
 * @function filterSpotsByMonthlyReservationType
 * @param {Array} spots - list of all spots
 * @param {string} monthlyReservationType - reservation type to filter by
 * @param {Array} relatedReservationTypes - list of similar reservation types if no exact matches are found
 * @returns {Array} filtered spots
 */
export const filterSpotsByMonthlyReservationType = (spots, monthlyReservationType, relatedReservationTypes) => {
    return spots.reduce((filteredSpots, currentSpot) => {
        const spotReservationTypes = currentSpot.allMonthlyReservationTypes;
        // does this spot match the reservation type exactly?
        const isExactMatch = Boolean(spotReservationTypes.find(reservationType => reservationType === monthlyReservationType));
        if (isExactMatch) {
            filteredSpots.push(currentSpot);
        }
        else {
            // does this spot match any of the related reservation types?
            const isRelativeMatch = intersection(spotReservationTypes, relatedReservationTypes)
                .length >= 1;
            if (isRelativeMatch) {
                filteredSpots.push(currentSpot);
            }
        }
        return filteredSpots;
    }, []);
};
export const filterSpotsByRating = (spots, activeRatingTypes) => {
    return spots.reduce((filteredSpots, currentSpot) => {
        const slug = convertRatingToSlug(currentSpot?.rating);
        if (activeRatingTypes.includes(slug)) {
            filteredSpots.push(currentSpot);
        }
        return filteredSpots;
    }, []);
};
