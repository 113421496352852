/**
 * SEARCH RESULTS AND DISTANCE LIMITS
 *
 * BY SEARCH CONTEXT
 * Different search contexts (e.g. address, city, event) are associated with
 * different user intent and specificity. For example, a user searching by city
 * or airport might want to get a feeling for the "landscape" of options.
 * A user searching for a specific address likely has in mind acceptable
 * distance limits for their ideal parking spot.
 *
 * INITIAL SEARCH
 * The initial search is run using the provided initialResultLimit and
 * maxDistance values. This allows searches to be run independently of any
 * map logic--which is both a benefit to performance and decoupled architecture.
 *
 * SUBSEQUENT/REFINING SEARCHES
 * After the initial search has been run, the user is able to move the map by
 * zooming in/out, panning around, or combination of zoom/pan. There may be any
 * number of reasons behind this, for instance:
 * - A desire to see more of the "landscape" of options
 * - A desire to see more of (or specific) geographical context
 * - A desire to see more options around an optimal area (I want to find parking
 * near Navy Pier, and know that there is no point in searching in Lake Michigan)
 *
 * Therefore on subsequent searches, we increase the number of results that can
 * be returned. We also consider the bounds of the map, in addition to the max
 * distance from initial search.
 *
 */
// DEFAULT SEARCH
export const defaultLimits = {
    initialResultLimit: 15,
    subsequentResultLimit: 30,
    maxDistance: 2500, // in meters
};
// ADDRESS SEARCH
export const addressLimits = defaultLimits;
// CITY SEARCH
export const cityLimits = {
    initialResultLimit: 30,
    subsequentResultLimit: 200,
    maxDistance: 8000,
};
// DESTINATION SEARCH
export const destinationLimits = defaultLimits;
// EVENT SEARCH
export const eventLimits = {
    initialResultLimit: 30,
    subsequentResultLimit: 50,
    maxDistance: 3000,
};
export const multiDayLimits = {
    initialResultLimit: 100,
    subsequentResultLimit: 200,
    maxDistance: 8000,
};
// AIRPORT SEARCH
// This is non-existent for Airport, as we currently return all possible search results.
/**
 * Retrieves search limits for results and distances, based on provided location context
 *
 * @function getSearchLimitsByLocationContext
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientParking|Documentation}
 * @param {(string)} locationContext - The string corresponding to location context.
 * @returns {Limits} An object containing recommended initial result limit, subsequent result limit
 * and max distance from the search location.
 */
export const getSearchLimitsByLocationContext = locationContext => {
    switch (locationContext) {
        case 'address':
            return addressLimits;
        case 'city':
            return cityLimits;
        case 'destination':
            return destinationLimits;
        case 'event':
            return eventLimits;
        default:
            return defaultLimits;
    }
};
/**
 * Takes in the coordinates of the map's center, and northeast and southwest bounds.
 * Describes the smallest circle possible outside the map bounds and returns its radius from the center.
 * (This is the same as calculating the distance from the center to any corner of the map.)
 * Taking result limits aside, the search results would cover the entire map.
 *
 * As the earth is not a perfect sphere and we do not require an extremely high level of accuracy,
 * the Haversine formula for calculating distance between two points of earth suffices.
 *
 * @function calculateSearchRadiusOutsideMapBounds
 * @see {@link https://en.wikipedia.org/wiki/Haversine_formula|Haversine Formula}
 * @see {@link https://www.movable-type.co.uk/scripts/latlong.html|JS implementation}
 * @param {Coordinates} mapCenter - The coordinates of the map's center
 * @param {MapBounds} mapBounds - The northeast and southwest coordinates of the map
 * @returns {number} The distance to use for search radius
 */
export const calculateSearchRadiusOutsideMapBounds = (mapCenter, mapBounds) => {
    // We want the distance from the center of the map to the corner
    // We can use either the northeast or southwest coordinate; it does not matter
    const lat1 = mapCenter.latitude;
    const lon1 = mapCenter.longitude;
    const lat2 = mapBounds.southWest.latitude;
    const lon2 = mapBounds.southWest.longitude;
    // Haversine distance formula below
    const R = 6378 * 1000; // Approximate radius of the earth, in meters
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // in meters
};
/**
 * Takes in a location context, map center, and map bounds for a search and returns
 * an appropriate resultLimit and maxDistance to use with Search V2 calls. (While
 * we could calculate map center from the bounds, it is already being passed for
 * analytics purposes.)
 *
 * If the map center and bounds are provided, we will assume that we are
 * performing a subsequent/refining search.
 *
 * @function getSearchLimits
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientParking|Documentation}
 * @param {GetSearchLimitsOptions} params - location context and map information
 * @returns {object} An object containing the resultLimit and maxDistance (in meters).
 */
export const getSearchLimits = ({ locationContext, mapCenter, mapBounds, isSearchDurationMoreThan24Hours, }) => {
    const { initialResultLimit, subsequentResultLimit, maxDistance, } = isSearchDurationMoreThan24Hours
        ? multiDayLimits
        : getSearchLimitsByLocationContext(locationContext);
    let newSearchRadius = maxDistance;
    let newResultLimit = initialResultLimit;
    // presence of map bounds, indicates that user has interacted with map
    if (mapBounds) {
        const radiusOutsideMap = calculateSearchRadiusOutsideMapBounds(mapCenter, mapBounds);
        // Use the smaller of the two distance options
        // Technically this could end up being 0, but we will have to assume
        // that our map zoom logic has been respected
        newSearchRadius = Math.min(radiusOutsideMap, maxDistance);
        // increase the number of results
        newResultLimit = subsequentResultLimit;
    }
    return { resultLimit: newResultLimit, maxDistance: newSearchRadius };
};
