import { buildAirportSpotDetailsURL, buildCheckoutURL, formatAmenitySlug, } from 'api/search-v2/utils';
import pick from 'lodash/pick';
import { OBJECT_AIRPORT_RATE } from 'proptypes/search-v2';
import { formatDateTime } from 'utils/format-date-time';
export function formatAirportRate({ spotId, facilitySlug, quote: { order, advertisedPrice }, additionalRateDetails, timezone, rebookId, }) {
    // only keep properties that have corresponding rate type (transient, monthly, etc) prop types
    // and combine them with the additional rate details for that rate type as well as any additional
    // properties that rate type needs which are derived from other data not in the API
    const baseRate = order[0] || {
        starts: '',
        ends: '',
        rateId: '',
        items: [],
    };
    const { rateId, starts: originalStarts, ends: originalEnds } = baseRate;
    const formattedRate = pick({
        ...additionalRateDetails,
        ...baseRate,
        starts: formatDateTime(baseRate.starts, timezone),
        ends: formatDateTime(baseRate.ends, timezone),
        priceBreakdown: baseRate.items,
        amenities: additionalRateDetails.amenities.map(formatAmenitySlug),
        fullUrl: buildCheckoutURL({
            spotId,
            facilitySlug,
            rateId,
            rateType: 'airport',
            starts: originalStarts,
            ends: originalEnds,
            timezone,
            rebookId,
        }),
        spotDetailsUrl: buildAirportSpotDetailsURL({
            spotId,
            facilitySlug,
            starts: originalStarts,
            ends: originalEnds,
            timezone,
            rebookId,
        }),
    }, Object.keys(OBJECT_AIRPORT_RATE));
    return {
        ...formattedRate,
        advertisedPrice,
    };
}
