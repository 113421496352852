import dayjs from 'utils/dayjs-timezone';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { formatDateTime } from 'utils/format-date-time';
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
// https://stackoverflow.com/a/47225591
function partition(array, isValid) {
    return array.reduce(([pass, fail], elem) => {
        return isValid(elem)
            ? [[...pass, elem], fail]
            : [pass, [...fail, elem]];
    }, [[], []]);
}
/**
 * Following function breaks out today, tomorrow, and the rest of events for grouping
 *
 * https://www.figma.com/design/sYYOX6weBr7OlhxxrRWgyF/search-2.0?node-id=3049-12293&t=ACaNXEkxmCooJhW7-1
 * if there is an event today = "Happening today"
 * if there is no event today but there is tomorrow = "Happening tommorrow"
 * if there is no event today or tomorrow = no label/movement
 */
export const mapEventsList = (events, timezone) => {
    const [filterToday, restOfEventsPartial] = partition(events, e => dayjs(formatDateTime(e.starts, timezone)).isToday());
    const [filterTomorrow, restOfEventsFull] = partition(restOfEventsPartial, e => dayjs(formatDateTime(e.starts, timezone)).isTomorrow());
    if (filterToday.length !== 0) {
        return {
            Happening_today: filterToday,
            Coming_soon: restOfEventsPartial,
        };
    }
    else if (filterTomorrow.length !== 0) {
        return {
            Happening_tomorrow: filterTomorrow,
            Coming_soon: restOfEventsFull,
        };
    }
    else {
        return {
            Coming_soon: restOfEventsFull,
        };
    }
};
