import React from 'react';
import IconCenter from '@spothero/icons/target';
import IconPlus from '@spothero/icons/plus';
import IconMinus from '@spothero/icons/minus';
import { Button } from '@spothero/ui';
import useIsMobile from 'hooks/use-is-mobile';
const buttonStyles = {
    position: 'absolute',
    height: 10,
    width: 10,
    padding: '0',
    lineHeight: '1',
    backgroundColor: 'white',
    borderRadius: 'xs',
    boxShadow: 'sm',
    zIndex: '300',
    border: 'none',
};
const SpotMapControls = ({ isDestinationOutOfBounds, zoom, onZoomIn, onZoomOut, onReturnToDestination, highestAllowedZoomLevel = 19, lowestAllowedZoomLevel = 4, }) => {
    const isMobile = useIsMobile();
    return (<div className="SpotMapControls">
            {!isMobile && (<div className="SpotMapControl-zoom">
                    <Button {...buttonStyles} className="SpotMapControl SpotMapControl-zoom-in Button" onClick={onZoomIn} isDisabled={zoom > highestAllowedZoomLevel} aria-label="Zoom In">
                        <IconPlus />
                    </Button>
                    <Button {...buttonStyles} className="SpotMapControl SpotMapControl-zoom-out Button" onClick={onZoomOut} isDisabled={zoom < lowestAllowedZoomLevel} aria-label="Zoom Out">
                        <IconMinus />
                    </Button>
                </div>)}
            {!isMobile && isDestinationOutOfBounds && (<Button {...buttonStyles} className="SpotMapControl SpotMapControl-return-to-destination Button" onClick={onReturnToDestination} aria-label="Return to Destination">
                    <IconCenter />
                </Button>)}
        </div>);
};
export default SpotMapControls;
