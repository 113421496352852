// @ts-migration - believe deprecated, not bothering
import EventsAPI from 'api/events';
export const EVENT_LOAD_DATA = 'EVENT_LOAD_DATA';
export const EVENT_RESET = 'EVENT_RESET';
export const eventLoadData = (id) => {
    return {
        type: EVENT_LOAD_DATA,
        payload: EventsAPI.getById(id),
    };
};
export const eventReset = () => {
    return {
        type: EVENT_RESET,
    };
};
