import React, { createContext, useContext, useEffect, useState } from 'react';
import ErrorUtils from 'utils/error-utils';
import { Loader } from '@googlemaps/js-api-loader';
import useConfig from 'hooks/use-config';
const GoogleMapsContext = createContext({
    googleMapsLoaded: false,
    googleMapsLibraries: null,
});
const MAX_RETRIES = 3; // Set the maximum number of retries
const loadLibraries = (loader, retries = MAX_RETRIES) => {
    return Promise.allSettled([
        loader.importLibrary('core'),
        loader.importLibrary('maps'),
        loader.importLibrary('marker'),
        loader.importLibrary('streetView'),
        loader.importLibrary('geocoding'),
        loader.importLibrary('places'),
        loader.importLibrary('routes'),
    ]).then(results => {
        const loadedLibraries = results
            .filter(result => result.status === 'fulfilled')
            .map(result => result.value);
        const failedLibraries = results.filter(result => result.status === 'rejected');
        if (failedLibraries.length > 0) {
            if (retries === 0) {
                throw new Error(`Failed to load ${failedLibraries.length} libraries after ${MAX_RETRIES} attempts.`);
            }
            else {
                return loadLibraries(loader, retries - 1);
            }
        }
        return loadedLibraries;
    });
};
const GoogleMapsProvider = ({ children }) => {
    const { googleMapsApiKey: apiKey } = useConfig();
    const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
    const [googleMapsLibraries, setGoogleMapsLibraries] = useState(null);
    useEffect(() => {
        let isCancelled = false;
        const loader = new Loader({
            apiKey,
            version: 'weekly',
        });
        loadLibraries(loader)
            .then(libs => {
            if (!isCancelled) {
                setGoogleMapsLibraries({
                    core: libs[0],
                    maps: libs[1],
                    marker: libs[2],
                    streetView: libs[3],
                    geocoding: libs[4],
                    places: libs[5],
                    routes: libs[6],
                });
                setGoogleMapsLoaded(true);
            }
        })
            .catch(error => {
            ErrorUtils.sendSentryMessage({
                error,
                customErrorMessage: 'GoogleMapsProvider: Error loading Google Maps libraries',
            });
        });
        return () => {
            isCancelled = true;
        };
    }, [apiKey]);
    if (typeof window === 'undefined') {
        return children;
    }
    return (<GoogleMapsContext.Provider value={{
            googleMapsLoaded,
            googleMapsLibraries,
        }}>
            {children}
        </GoogleMapsContext.Provider>);
};
const useGoogleMapsLibraries = () => {
    return useContext(GoogleMapsContext);
};
const withGoogleMapsLibraries = Component => {
    const WrappedComponent = props => {
        const { googleMapsLoaded, googleMapsLibraries, } = useGoogleMapsLibraries();
        return (<Component {...props} googleMapsLoaded={googleMapsLoaded} googleMapsLibraries={googleMapsLibraries}/>);
    };
    WrappedComponent.displayName = `withGoogleMapsLibraries(${Component.displayName || Component.name})`;
    return WrappedComponent;
};
withGoogleMapsLibraries.displayName = 'withGoogleMapsLibraries';
export { GoogleMapsProvider, useGoogleMapsLibraries, withGoogleMapsLibraries };
