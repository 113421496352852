import { FACILITY_TYPES } from 'utils/types/facility';
import dayjs from 'utils/dayjs-timezone';
import buildMonthlyDetails from './build-monthly-details';
import buildTransientDetails from './build-transient-details';
import buildCommonRentalDetails from './build-common-rental-details';
import { v4 as uuidV4 } from 'uuid';
export const CHECKOUT_ITEM_TYPES = {
    RENTAL: 'rental',
};
export const buildRentalItem = ({ rentalDetails, ratePrice, checkoutData, }) => {
    const { selectedAddons } = checkoutData;
    const rentalItem = {
        item_context: rentalDetails,
        price: ratePrice,
        item_type: CHECKOUT_ITEM_TYPES.RENTAL,
        /*
        A unique identifier is added to rental item.
        Currently used only in powerbooking for relating each request rental item
        to its corresponding purchase item returned in the response
        to identify failed reservations if any
        */
        key: uuidV4(),
        add_ons: selectedAddons
            ? selectedAddons.map(addon => ({
                sku: addon.sku,
                price: addon.price,
            }))
            : [],
    };
    return rentalItem;
};
export const buildMonthlyRentalItem = ({ checkoutData, searchUUID, actionUUID, monthlyStarts, rentalSourceReferrer, selectedRate, source, }) => {
    const { contractFullName, vehicleColor, forceRedundantMonthly, spot, } = checkoutData;
    const ratePrice = selectedRate.price;
    const rentalDetails = {
        ...buildCommonRentalDetails({
            checkoutData,
            searchUUID,
            actionUUID,
            rentalSourceReferrer,
            source,
            licensePlateRequired: true,
        }),
        ...buildMonthlyDetails({
            monthlyStarts,
            contractFullName,
            vehicleColor,
            forceRedundantMonthly,
            selectedRate,
            timezone: spot?.facility?.timezone,
        }),
    };
    return buildRentalItem({
        rentalDetails,
        checkoutData,
        ratePrice,
    });
};
export const buildTransientAirportEventRentalItem = ({ checkoutData, searchUUID, actionUUID, rentalSourceReferrer, selectedRate, source, }) => {
    const { eventId, spot } = checkoutData;
    const ratePrice = selectedRate?.price_breakdown?.total_price || 0;
    const rentalDetails = {
        ...buildCommonRentalDetails({
            checkoutData,
            searchUUID,
            actionUUID,
            rentalSourceReferrer,
            source,
        }),
        ...buildTransientDetails({
            selectedRate,
            eventId,
            timezone: spot?.facility?.timezone,
        }),
    };
    return buildRentalItem({
        rentalDetails,
        checkoutData,
        ratePrice,
    });
};
export const buildEventPackageRentalItem = ({ checkoutData, searchUUID, actionUUID, rentalSourceReferrer, selectedRate, source, }) => {
    const { spot } = checkoutData;
    const eventPackageId = spot?.event_package?.id;
    const ratePrice = selectedRate?.price_breakdown?.total_price || 0;
    const rentalDetails = {
        ...buildCommonRentalDetails({
            checkoutData,
            searchUUID,
            actionUUID,
            rentalSourceReferrer,
            source,
        }),
        event_package_id: eventPackageId,
    };
    return buildRentalItem({
        rentalDetails,
        checkoutData,
        ratePrice,
    });
};
export const buildPowerBookingRentalItems = ({ checkoutData, searchUUID, actionUUID, rentalSourceReferrer, source, bulkPowerBookingRates, }) => {
    const commonRentalDetails = buildCommonRentalDetails({
        checkoutData,
        searchUUID,
        actionUUID,
        rentalSourceReferrer,
        source,
    });
    const { spot } = checkoutData;
    return bulkPowerBookingRates.map(({ rates = [] }) => {
        const { quote } = (rates.length && rates[0]) || {};
        const order = (quote?.order.length && quote?.order[0]) ||
            {};
        const rentalDetails = {
            ...commonRentalDetails,
            starts: dayjs(order?.starts).tz(spot?.facility?.timezone).format(),
            ends: dayjs(order?.ends).tz(spot?.facility?.timezone).format(),
        };
        if (order?.rate_id !== FACILITY_TYPES.RATE_EXCEPTIONS.EVENT_RATE) {
            rentalDetails.rule_group_id = Number(order?.rate_id);
        }
        return buildRentalItem({
            rentalDetails,
            checkoutData,
            ratePrice: quote?.total_price?.value,
        });
    });
};
