import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useConfig from 'hooks/use-config';
import { Box, Flex, Text, Button } from '@spothero/ui';
import { DatePicker } from '@spothero/ui-backlog';
import { EventListItem } from '../event-empty-state-svg';
import EventEmptyStateSvg from '../event-empty-state-svg/EventEmptyStateSvg';
import includes from 'lodash/includes';
import dayjs from 'dayjs';
import { isEventHappeningToday } from 'pages/search-transient-event-monthly/components/event-list/EventList';
import { getTransientDateDisplayFormat } from 'utils/dayjs-calendar';
import { v4 as uuidV4 } from 'uuid';
import { mapEventsList } from '../map-events-list/mapEventsList';
import { filterDates } from './filterDates';
export const getFilteredEvents = ({ date, displayDateFormat, timezone, eventsArray = [], }) => {
    let formattedDate = null;
    const filteredEvents = eventsArray.reduce((acc, event) => {
        formattedDate = dayjs(event.starts)
            .tz(timezone)
            .format(displayDateFormat);
        if (includes(formattedDate, date)) {
            acc.push(event);
        }
        return acc;
    }, []);
    return filteredEvents.reduce((acc, event) => {
        isEventHappeningToday(event.starts, timezone)
            ? acc.happeningTodayEvents.push(event)
            : acc.upcomingEvents.push(event);
        return acc;
    }, { happeningTodayEvents: [], upcomingEvents: [] });
};
export const UpcomingEvents = () => {
    const { siteUrl } = useConfig();
    const [date, setDate] = useState('');
    const [key, setKey] = useState(uuidV4());
    const partner = useSelector((state) => state.searchRequest.partner);
    const events = useSelector((state) => state.destination.data.events || []);
    const timezone = useSelector((state) => state.destination.data.timezone);
    const mapEventItem = (event, index) => {
        if (event) {
            const { id } = event;
            const eventUrl = `${siteUrl}/search?kind=event&id=${id}${partner ? `partner=${partner}` : ''}`;
            return (<EventListItem key={id} event={event} eventUrl={eventUrl} index={index}/>);
        }
        return undefined;
    };
    const hasEvents = Boolean(events?.length);
    const mappedEvents = mapEventsList(events || [], timezone || null);
    const mappedEventsKeys = Object.keys(mappedEvents);
    const eventList = hasEvents
        ? mappedEventsKeys.map((mapKey) => {
            const mappedEvent = mappedEvents[mapKey];
            const mappedEventItems = mappedEvent.map(mapEventItem);
            return (<Box key={mapKey} borderBottom="1px solid" borderColor="gray.light" marginBottom="7">
                      <Text fontSize="sm" color="text.secondary.light" marginLeft="1" // Match date/time
             marginBottom="2" fontWeight="medium">
                          {mapKey.replace('_', ' ')}
                      </Text>

                      {mappedEventItems}
                  </Box>);
        })
        : [];
    const onDateChange = selectedDate => {
        setDate(selectedDate);
    };
    const onClearClick = () => {
        setDate('');
        setKey(uuidV4());
    };
    const flattenedEvents = mappedEventsKeys
        .map(eventKey => mappedEvents[eventKey])
        .flat();
    const filteredDates = useMemo(() => filterDates(flattenedEvents, date, timezone), [date, flattenedEvents, timezone]).map(mapEventItem);
    return (
    // below adds scrolling on events, NO INDICATOR currently.  Height assumed bit taller than largest from design system
    <Box overflow="auto" maxHeight={{ desktop: '30rem', base: 'none' }}>
            {hasEvents ? (<Box key={key} sx={{
                '.DatePicker': {
                    width: '80',
                },
                '.DatePicker-input-container': {
                    span: {
                        display: 'block',
                    },
                },
                '.FormElement-item': {
                    fontSize: 'base',
                    height: '10',
                    color: 'black',
                    fontWeight: 'semibold',
                    '&.FormElement-item-placeholder,&::placeholder': {
                        color: 'gray.200',
                        fontWeight: 'normal',
                    },
                },
                '.DatePicker-input-container-with-icon .TextInput .Icon-calendar': {
                    fill: 'black',
                },
            }}>
                    <Flex justify="center" alignItems="center" marginBottom="4" data-testid="EventList-date-picker-container">
                        <DatePicker id="EventList-event-date" placeholder="Search by event date" iconPosition="left" displayFormat={getTransientDateDisplayFormat} onChange={onDateChange}/>
                        <Button variant="tertiary" fontSize="sm" p="4" onClick={onClearClick} data-testid="EventList-clear-date-picker-button" disabled={!date}>
                            Clear
                        </Button>
                    </Flex>
                    {filteredDates.length ? filteredDates : eventList}
                </Box>) : (<Flex flexDirection="column" justifyContent="center" alignItems="center" minHeight={60} textAlign="center">
                    <EventEmptyStateSvg />
                    <Text marginTop={4} data-testid="event-upcoming-text">
                        There are no upcoming events at this venue. <br />
                        Please try again later.
                    </Text>
                </Flex>)}
        </Box>);
};
