import { Page } from 'utils/page-utils';
import { showSpotsLeft } from 'utils/spot-left';
import getFilteredSearchResults from './filtered-search-results';
import dayjs from 'utils/dayjs';
import ErrorUtils from 'utils/error-utils';
import { isEventOrTransientOrPowerBookingParking } from 'segment/utils/parking-type';
/**
 * Calculates the time difference between a given start and end time.
 *
 * @function calculateTimeDifference
 * @param {{starts: string, ends: string, unit: string, isFloatingPointNumber: boolean}} args - The function arguments object
 * @param {string} starts - The ISO string for the start time
 * @param {string} ends - The ISO string for the end time
 * @param {string} [unit='hour'] - The unit to display the difference in (ie. hours, minutes, days, etc.) defaults to `'hour'` {@link https://day.js.org/docs/en/display/difference|More information on dayjs.diff()}
 * @param {boolean} [isFloatingPointNumber=true] - A boolean to determine if the returned time difference is a floting point number or an integer, defaults to `true`
 * @returns {number} The time difference between the start and end times
 */
export function calculateTimeDifference({ starts, ends, unit = 'hour', isFloatingPointNumber = true, }) {
    const startDateObj = dayjs(starts);
    const endDateObj = dayjs(ends);
    return endDateObj.diff(startDateObj, unit, isFloatingPointNumber) || 0;
}
/** @function getQueryValue
 * @param {object} args - The function arguments object
 * @param pageType - The page type (city,destination,venue,event,search,address)
 * @param cityName - The city name
 * @param destinationName - The destination name
 * @param eventName - The event name
 * @param airportName - The airport name
 * @param searchQuery - The user entered address search query

 If query is tied to a city, then use the city name
 If query is tied to a destination or venue, then user the Destination name
 If query is tied to an airport, then use the airport name
 If query is tied to an event, then use the event name
 If query is tied to a search(address), then use the address query
 * @returns {*|string} - The query string
 */
export function getQueryValue({ pageType, cityName, destinationName, eventName, eventPackageName, airportName, searchQuery, }) {
    if (pageType === Page.CITY) {
        return cityName;
    }
    else if (pageType === Page.DESTINATION || pageType === Page.VENUE) {
        return destinationName;
    }
    else if (pageType === Page.EVENT) {
        return eventName;
    }
    else if (pageType === Page.EVENT_PACKAGES_SEARCH) {
        return eventPackageName;
    }
    else if (pageType === Page.AIRPORT) {
        return airportName;
    }
    else {
        return searchQuery || '';
    }
}
/**
 * Creates a filtered and formatted list of spot data.
 *
 * @function createTopSearchResultsList
 * @param {{spots: Array, airport: object, sortBy: string, filters: object|null, parkingType: string|null, isMonthly: boolean}} args - The function arguments object containing an array of spot objects, an airport state data object, and the string representing the type of sorting for the spot list
 * @param {Array} spots - An Array of Spot objects
 * @param {object} airport - The airport state data object
 * @param {boolean} isMonthly - A boolean representing if the search is for monthly parking
 * @param {object|null} filters - The filters state data object
 * @param {string|null} parkingType - A string representing the type of parking
 * @param {string|null} sortBy - A string representing the type of sorting for the spot list
 * @returns {Array<FormattedSearchResultObject>} The list of {@link FormattedSearchResultObject|formatted spot data objects}
 */
export function createTopSearchResultsList({ spots, airport, sortBy, isMonthly = false, filters = null, parkingType = null, }) {
    try {
        const topSearchResultsList = getFilteredSearchResults({
            spots,
            airport,
            sortBy,
            isMonthly,
            filters,
            parkingType,
        }).map(({ availability, distance, facilityType, 
        //@ts-expect-error
        isOfficialParking, selectedRate, rating, spotId, tag, visualFlags, }, index) => {
            const { available, availableSpaces } = availability;
            const allowVisualFlags = isEventOrTransientOrPowerBookingParking(parkingType);
            const topSearchResultsObject = {
                ...(selectedRate && {
                    display_price: parseFloat(String(selectedRate.advertisedPrice.value / 100)),
                    total_price: parseFloat(String(selectedRate.totalPrice.value / 100)),
                }),
                facility_id: spotId,
                ...(selectedRate && {
                    number_of_hours: calculateTimeDifference({
                        starts: selectedRate.starts,
                        ends: selectedRate.ends,
                    }),
                }),
                official_parking: allowVisualFlags && isOfficialParking,
                visual_flags: allowVisualFlags ? visualFlags : [],
                ...(parkingType
                    ? {
                        tag_displayed_in_search: allowVisualFlags && visualFlags?.length > 0,
                    }
                    : {}),
                rank: index + 1,
                spots_left_displayed: showSpotsLeft({
                    available,
                    availableSpaces,
                    facilityType,
                }),
                ...(rating && { star_rating: rating }),
                ...(tag && { tag }),
                walking_distance: distance.walkingMeters,
            };
            return topSearchResultsObject;
        });
        return topSearchResultsList;
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - Create Top Search Results List',
        });
        return [];
    }
}
