/* eslint-disable jsdoc/check-param-names */
/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-param-type */
/* eslint-disable jsdoc/require-param-description */
/**
 * Create Cloudinary image template from a Cloudinary URL
 * The Search Team has agreed that these parameters will always appear in this order (width, height, quality),
 * and that there will only ever be one such occurrence in the URL.
 *
 * @function buildImageTemplate
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientParking|Documentation}
 * @param {object} image - An object representing a Cloudinary image.
 * @param {string} image.url - The URL of the image.
 * @returns {object} The image object containing a new urlTemplate string.
 */
export const buildImageTemplate = (image) => ({
    ...image,
    urlTemplate: image.url.replace(/w_\d+,h_\d+,q_\d+/, 'w_{{WIDTH}},h_{{HEIGHT}},q_{{QUALITY}}'),
});
