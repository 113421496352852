import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
function trackExperimentViewed({ experimentId, experimentName, variationId, variationName, }) {
    try {
        SegmentUtils.track({
            event: 'Experiment Viewed',
            properties: {
                /* eslint-disable camelcase */
                experiment_id: experimentId,
                experiment_name: experimentName,
                variation_id: variationId,
                variation_name: variationName,
                /* eslint-enable camelcase */
            },
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackExperimentViewed',
        });
    }
}
export default trackExperimentViewed;
