import { APIRegistry } from '@spothero/utils/api';
import { formatTransientSpotDetailsResponse } from '../utils/formatTransientSearchResponse';
import { transformV2SingleFacilityToV1RatesTransient } from 'api/search-v2/transient/format/format-v2-to-v1';
import SearchTracking from 'utils/search-tracking';
import { getWebExperimentCookie } from 'utils/web-experiment';
import { setBackendExperiments } from 'utils/session-storage/backend-experiment';
// These two are probably more widely used
// https://api.spothero.com/v2/docs#operation/searchTransientFacilityParking|Documentation
export function getTransientFacility(facilityId, params, asV2 = false) {
    const api = APIRegistry.get('craigSearch');
    const { sessionUUID, searchUUID, actionUUID, action, } = SearchTracking.getValues();
    const fingerprint = getWebExperimentCookie();
    const response = api
        .get(`search/transient/${facilityId}`, {
        show_unavailable: true,
        session_id: sessionUUID,
        search_id: searchUUID,
        action_id: actionUUID,
        action,
        fingerprint,
        ...params,
    }, {
        headers: {
            'SpotHero-Version': '2024-04-01',
        },
    })
        // @ts-expect-error - not in craig docs
        .then(({ data: { result, experiment } }) => {
        setBackendExperiments(experiment);
        return formatTransientSpotDetailsResponse(result, {
            eventId: params?.eventId,
            rebookId: params?.rebookId,
            // MR 10/2/2023 - Down stream this is needed, ideally we get the timezone from the spot data
            timezone: result?.facility?.common?.addresses[0]?.time_zone,
        });
    });
    if (asV2) {
        return response;
    }
    else {
        return response.then((resp) => 
        // @ts-expect-error - unknown till type function
        transformV2SingleFacilityToV1RatesTransient(resp));
    }
}
