import SegmentUtils from 'utils/segment';

export default function trackSearchClickedLoadMoreSpots({parkingType}) {
    const properties = {
        parking_type: parkingType,
    };

    SegmentUtils.track({
        event: 'Search Clicked Load More Spots',
        properties,
        additionalOptions: {
            context: {
                protocols: {
                    event_version: 1,
                },
            },
        },
    });
}
