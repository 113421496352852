import APIUtils from '@spothero/utils/api';
import ObjectUtils from '@spothero/utils/object';
import isEmpty from 'lodash/isEmpty';
import ErrorUtils from 'utils/error-utils';
import { INELIGIBILITY_ERROR_CODES, INELIGIBILITY_REASONS, INELIGIBLE_PERMISSIONS, } from 'store/sms-permissions/sms-permissions-constants';
/**
 * Strips string of non-number chars and only grabs last 10 chars
 *
 * @function
 * @param {string} phoneNumber phone number
 * @returns {string} Cleaned phone number
 */
const cleanPhoneNumber = (phoneNumber) => {
    let cleanedNumber = phoneNumber.replace(/\D/g, '');
    if (cleanedNumber.length > 10) {
        cleanedNumber = cleanedNumber.slice(-10);
    }
    return cleanedNumber;
};
const transformSmsPermissions = (smsPermissionsData) => {
    const camelizedData = ObjectUtils.camelizeKeys(smsPermissionsData);
    return {
        eligible: true,
        marketingOptIn: camelizedData.marketingOptIn,
        transactionOptIn: camelizedData.transactionOptIn,
        id: camelizedData.id,
        phoneNumber: cleanPhoneNumber(camelizedData.phoneNumber),
        ineligibilityReason: null,
    };
};
/**
 * GET to get SMS opt in status
 *
 * @function
 * @param {object} param Top level param
 * @param {string} param.phoneNumber phone number to get sms permissions
 * @returns {Promise<SMSPermissions>} SMS Permission object
 */
export const getSMSPermissions = async ({ phoneNumber }) => {
    try {
        const cleanedNumber = cleanPhoneNumber(phoneNumber);
        const response = await APIUtils.get('sms-permissions/', {
            phone_number: cleanedNumber,
        });
        if (!isEmpty(response.data.data)) {
            return transformSmsPermissions(response.data.data);
        }
        return {
            eligible: true,
            marketingOptIn: false,
            transactionOptIn: false,
            phoneNumber: cleanedNumber,
            id: null,
            ineligibilityReason: null,
        };
    }
    catch (response) {
        const error = response?.data?.data?.errors?.[0];
        if (response.status === 422) {
            if (error?.code === INELIGIBILITY_ERROR_CODES.NON_US) {
                return {
                    ...INELIGIBLE_PERMISSIONS,
                    ineligibilityReason: INELIGIBILITY_REASONS.NON_US,
                };
            }
            else if (error?.code === INELIGIBILITY_ERROR_CODES.LANDLINE) {
                return {
                    ...INELIGIBLE_PERMISSIONS,
                    ineligibilityReason: INELIGIBILITY_REASONS.LANDLINE,
                };
            }
            else {
                return {
                    ...INELIGIBLE_PERMISSIONS,
                    ineligibilityReason: INELIGIBILITY_REASONS.GENERIC,
                };
            }
        }
        return {
            ...INELIGIBLE_PERMISSIONS,
            ineligibilityReason: INELIGIBILITY_REASONS.GENERIC,
        };
    }
};
/**
 * POST or PUT to create/update SMS opt in status
 *
 * @function
 * @param {object} param Top level param
 * @param {SMSPermissions} param.smsPermissions modified smsPermissions
 * @param {string} param.phoneNumber phone number to associated with user/reservation
 * @returns {Promise<SMSPermissions>} SMS Permission object
 */
export const updateSMSPermissions = async ({ smsPermissions, phoneNumber }) => {
    try {
        const cleanedNumber = cleanPhoneNumber(phoneNumber);
        // if true, something went wrong and the opt-in data does not match final submitted phone number
        if (cleanedNumber !== smsPermissions.phoneNumber) {
            //@ts-expect-error - This is due to error not being typed and confusing.  didn't touch as I think it'll be a can of worms
            ErrorUtils.sendSentryMessage({
                customErrorMessage: `SMS Permissions Error: Submitted Number Mismatch`,
            });
            return INELIGIBLE_PERMISSIONS;
        }
        if (smsPermissions.id) {
            const response = await APIUtils.put(`sms-permissions/${smsPermissions.id}/`, {
                marketing_opt_in: Boolean(smsPermissions.marketingOptIn),
                transaction_opt_in: Boolean(smsPermissions.transactionOptIn),
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'SpotHero-Version': '2022-02-22',
                },
            });
            return transformSmsPermissions(response.data.data);
        }
        else {
            const response = await APIUtils.post('sms-permissions/', {
                phone_number: cleanedNumber,
                marketing_opt_in: Boolean(smsPermissions.marketingOptIn),
                transaction_opt_in: Boolean(smsPermissions.transactionOptIn),
            });
            return transformSmsPermissions(response.data.data);
        }
    }
    catch (e) {
        ErrorUtils.sendSentryMessage({
            error: e,
            customErrorMessage: `SMS Permissions Error: ${e.message}`,
        });
        return INELIGIBLE_PERMISSIONS;
    }
};
/**
 * @typedef SMSPermissions
 * @property {string} id - id of permission
 * @property {boolean} eligible - whether user is eligible for texts
 * @property {boolean} marketingOptIn - whether user is opted into marketing texts
 * @property {boolean} transactionOptIn - whether user is opted into marketing texts
 * @property {string} phoneNumber - phone number associated with the sms permission
 * @property {string} ineligibilityReason - reason (if applicable) number is ineligible
 */
