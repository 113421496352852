import PropTypes from 'prop-types';
import { PROPTYPE_ADDRESS, PROPTYPE_DISTANCE, PROPTYPE_SPOT_ID, PROPTYPE_CLOUDINARY_IMAGE, PROPTYPE_STAR_RATING, PROPTYPE_SPOT_TITLE, PROPTYPE_HOURS_OF_OPERATION, PROPTYPE_FACILITY_TYPE, PROPTYPE_PARKING_TYPE, PROPTYPE_VISUAL_FLAGS, } from './basic-prop-types';
import { PROPTYPE_AVAILABILITY, PROPTYPE_AMENITY, OBJECT_COMMON_RATE, } from './rate-prop-types';
/*
See ./index.js for notes on naming conventions
*/
// This is intended for use when working with multiple spots at a time
// This contains only the data needed for Spot List Cards, Spot Map, and Filters
// Different verticals will have slightly different requirements;
// {VERTICAL}_FACILITY_SUMMARY should extend this definition with vertical-specific fields.
export const OBJECT_COMMON_FACILITY_SUMMARY = {
    isV2: PropTypes.bool,
    spotId: PROPTYPE_SPOT_ID.isRequired,
    addresses: PropTypes.arrayOf(PROPTYPE_ADDRESS).isRequired,
    availability: PROPTYPE_AVAILABILITY,
    distance: PROPTYPE_DISTANCE,
    facilityType: PROPTYPE_FACILITY_TYPE.isRequired,
    images: PropTypes.arrayOf(PROPTYPE_CLOUDINARY_IMAGE).isRequired,
    rating: PROPTYPE_STAR_RATING.isRequired,
    selectedRate: PropTypes.shape(OBJECT_COMMON_RATE),
    title: PROPTYPE_SPOT_TITLE.isRequired,
    allAmenities: PropTypes.arrayOf(PROPTYPE_AMENITY).isRequired,
    visualFlags: PropTypes.arrayOf(PROPTYPE_VISUAL_FLAGS),
    supportedFeeTypes: PropTypes.arrayOf(PropTypes.string),
    reservationExtensionEnabled: PropTypes.bool,
    allowUpdatingReservationAfterEntry: PropTypes.bool,
    requireCreditCard: PropTypes.bool,
    status: PropTypes.string,
    oversizeFeesChargedOnsite: PropTypes.bool,
};
export const PROPTYPE_FACILITY_SUMMARY = PropTypes.shape(OBJECT_COMMON_FACILITY_SUMMARY);
// This is intended for use when working with a single spot
// This (in conjunction with vertical-specific data) provides the data needed for Spot Details and Checkout
// Different verticals will have slightly different requirements;
// {VERTICAL}_FACILITY should extend this definition with vertical-specific fields.
export const OBJECT_COMMON_FACILITY = {
    ...OBJECT_COMMON_FACILITY_SUMMARY,
    hoursOfOperation: PROPTYPE_HOURS_OF_OPERATION.isRequired,
    navigationTip: PropTypes.string.isRequired,
    parkingTypes: PropTypes.arrayOf(PROPTYPE_PARKING_TYPE).isRequired,
    rates: PropTypes.arrayOf(PropTypes.shape(OBJECT_COMMON_RATE)),
    operatorDisplayName: PropTypes.string.isRequired,
    requirements: PropTypes.shape({
        licensePlate: PropTypes.bool.isRequired,
        phoneNumber: PropTypes.bool.isRequired,
        printout: PropTypes.bool.isRequired,
    }).isRequired,
    restrictions: PropTypes.arrayOf(PropTypes.string).isRequired,
    cancellation: PropTypes.shape({
        allowedByCustomer: PropTypes.bool.isRequired,
        allowedBySpotheroCustomerService: PropTypes.bool.isRequired,
        minutes: PropTypes.number.isRequired,
    }).isRequired,
    slug: PropTypes.string,
    oversizeFeesChargedOnsite: PropTypes.bool,
    isScanToPay: PropTypes.bool,
    barcodeType: PropTypes.string,
    operatorId: PropTypes.number,
};
/*
OPEN QUESTIONS
https://docs.google.com/document/d/1-3EnjMQLBJJzwvnhDL-yn9xAkiZug8ZFGgwt7f0WH24/edit#heading=h.tjx2usxelzly

FACILITY
- How do we determine `oversizedFeeMessage`?
    - We opted to get rid of it... -MJ
- How do we determine `vehicleRestriction`?
    - We opted to get rid of it... -MJ
- What to do with `heated` amenity, which isn't one we got before and isn't part of AmenityUtils and has no icon?

RATE
- Should we still build checkout URLs into the facility data?
- Do we still need `hidden` (this may be needed for filters)?
*/
