export const spotsLeftToggleValue = 5;
/**
 * Returns a boolean indicating if spotsLeft tag is visible
 *
 * @function searchRequestSlice
 * @param {number} availableSpaces - Number of inventory available at a given facility
 * @param {string} facilityType - The type of given facility
 * @param {boolean} available - Boolean value indicating if spot is available for booking
 * @example
 * const state = showSpotsLeft(500);
 * @returns {boolean} - should spotsLeftShow.
 */
export const showSpotsLeft = ({ availableSpaces, facilityType = '', available, }) => {
    return (available &&
        availableSpaces > 0 &&
        availableSpaces <= spotsLeftToggleValue &&
        facilityType !== 'personal_spot');
};
