/* eslint-disable camelcase */
import React from 'react';
import { sortMultiDayItems } from 'utils/multiday-utils';
import { Flex, Text, Box, Divider } from '@spothero/ui';
import { Modal, ModalContent } from '@spothero/ui-backlog';
import Price from 'common/price';
import { useSelector } from 'react-redux';
import { getCreditWallet } from 'store/checkout/checkout-selectors';
import { formatPowerBookingItems } from 'utils/powerbooking-utils';
import Header from 'common/price-breakdown-modal/Header';
import PriceBreakdownItemAccordion from 'common/price-breakdown-modal/PriceBreakdownItemAccordion';
import { useShowTotal } from 'store/selectors/hooks/useShowTotal';
// This component is used on Spot Details and handles use cases where multiple rates are available
// based on src/js/pages/checkout/components/review-purchase/ReviewPurchase.jsx - probably worth componentizing
export const SpotDetailPriceBreakdownModal = ({ spot, onHidden, showExpandableDescriptions = true, }) => {
    // only one accordion item can be open at a time
    const [openAccordion, setOpenAccordion] = React.useState(null);
    const { selectedRate } = spot || {};
    const { currencyType } = useSelector((state) => ({
        ...getCreditWallet(state),
    }));
    const showTotal = useShowTotal();
    const { searchRequest: { powerBooking: isPowerBooking, powerBookingPeriods }, } = useSelector((state) => state);
    let priceBreakdownItems = selectedRate?.price_breakdown?.items || [];
    let totalPrice = selectedRate?.price;
    const handleAccordionChange = (short_description) => {
        if (openAccordion === short_description) {
            setOpenAccordion(null);
        }
        else {
            setOpenAccordion(short_description);
        }
    };
    if (isPowerBooking) {
        priceBreakdownItems = formatPowerBookingItems(powerBookingPeriods, selectedRate, showTotal);
        totalPrice = selectedRate.totalFee;
    }
    return (<Modal onHidden={onHidden}>
            <ModalContent className="RateDetails" data-testid="PriceBreakdownModal-Content">
                <Header />
                {sortMultiDayItems(priceBreakdownItems).map(({ price, short_description, full_description }, index) => (<PriceBreakdownItemAccordion key={`item${index}`} showExpandableDescriptions={showExpandableDescriptions} fullDescription={full_description} openAccordion={openAccordion} shortDescription={short_description} onChange={() => handleAccordionChange(short_description)} value={price} currencyType={currencyType}/>))}
                <Divider paddingTop={2}/>
                <Flex justify="space-between" paddingTop={2}>
                    <Text fontWeight="semibold">Total</Text>
                    <Box fontSize="base" fontWeight="semibold">
                        <Price amount={totalPrice} currencyType={currencyType} showCents/>
                    </Box>
                </Flex>
            </ModalContent>
        </Modal>);
};
export default SpotDetailPriceBreakdownModal;
/* eslint-enable camelcase */
