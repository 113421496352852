import CheckoutUtils from 'utils/checkout-utils';

export const PAYMENT_TYPE_MAPPING = {
    cc: 'credit card',
    [CheckoutUtils.PAYMENT_REQUEST_TYPES.APPLE_PAY]: 'apple pay',
    [CheckoutUtils.PAYMENT_REQUEST_TYPES.GOOGLE_PAY]: 'google pay',
    [CheckoutUtils.PAYMENT_REQUEST_TYPES.PAYPAL]: 'paypal',
};

export const PAYMENT_NONE = 'none';
