import React from 'react';
import PropTypes from 'prop-types';
import {Flex, Box, Button} from '@spothero/ui';
import SpotUtils from 'utils/spot';
import classNames from 'classnames';

const CheckoutSectionStickyResponsive = ({
    onBookSpotClick,
    displayPrice,
    currencyType,
    priceLabel,
    visible,
}) => {
    const displayPriceFormatted = SpotUtils.formatRatePrice({
        price: displayPrice,
        currencyType,
    });

    const classes = classNames('CheckoutSectionStickyResponsive', {visible});

    return (
        <Box className={classes}>
            <Flex justifyContent="space-between">
                <Flex flexDirection="column" paddingRight="4">
                    <span
                        className="price"
                        dangerouslySetInnerHTML={{
                            __html: displayPriceFormatted,
                        }}
                    />
                    <span className="price-label">{priceLabel}</span>
                </Flex>
                <Button onClick={onBookSpotClick} textTransform="none">
                    Checkout
                </Button>
            </Flex>
        </Box>
    );
};

CheckoutSectionStickyResponsive.propTypes = {
    onBookSpotClick: PropTypes.func.isRequired,
    displayPrice: PropTypes.number.isRequired,
    currencyType: PropTypes.string.isRequired,
    priceLabel: PropTypes.string,
    visible: PropTypes.bool,
};

CheckoutSectionStickyResponsive.defaultProps = {
    priceLabel: 'subtotal',
    visible: false,
};

export default CheckoutSectionStickyResponsive;
