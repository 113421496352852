import dayjs from 'utils/dayjs';
import Config from 'config/index';
import TimeUtils from 'utils/time';
export const dateToString = (date, timezone, formatString = Config.apiDateTimeFormat) => {
    return timezone
        ? dayjs(date).tz(timezone).format(formatString)
        : dayjs(date).format(formatString);
};
export const combineDateAndTime = (date, time) => {
    // validate time is a string and contains a colon
    if (typeof time !== 'string' || !time.includes(':'))
        throw new Error('combineDateAndTime: The supplied time is not valid.');
    return dayjs(date)
        .hour(parseInt(time.split(':')[0], 10))
        .minute(parseInt(time.split(':')[1], 10))
        .toDate();
};
export const mapToSearchURLDateTimeValues = ({ dateRangeStartDate, dateRangeEndDate, monthlyStartDate, powerBookingDates, powerBookingStartTime, powerBookingEndTime, parkingSubType, timezone, bookingType, }) => {
    const result = {
        starts: undefined,
        ends: undefined,
        powerBookingPeriods: [],
        powerBookingSource: undefined,
    };
    if (parkingSubType === 'hourly') {
        result.starts = dateToString(dateRangeStartDate, timezone);
        result.ends = dateToString(dateRangeEndDate, timezone);
    }
    else if (parkingSubType === 'monthly') {
        result.starts = dateToString(monthlyStartDate);
    }
    else if (parkingSubType === 'power_booking') {
        const powerBookingSource = bookingType === 'single' ? 'transient_search' : 'monthly_search';
        result.powerBookingPeriods = powerBookingDates.map((date) => ({
            starts: dateToString(combineDateAndTime(date, powerBookingStartTime), null, TimeUtils.V2_API_TIMESTAMP_FORMAT),
            ends: dateToString(combineDateAndTime(date, powerBookingEndTime), null, TimeUtils.V2_API_TIMESTAMP_FORMAT),
        }));
        result.powerBookingSource = powerBookingSource;
    }
    return result;
};
