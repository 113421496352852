import APIUtils from '@spothero/utils/api';
import ObjectUtils from '@spothero/utils/object';
import ErrorUtils from 'utils/error-utils';
/**
 * Get events by package id
 *
 * @function getEvents
 * @param {string} id - Event package Id
 * @returns {Promise<object>} - API response
 */
export const getEvents = (id) => {
    return APIUtils.get(`/event-packages/${id}/`)
        .then(response => {
        try {
            return ObjectUtils.camelizeKeys(response.data.data);
        }
        catch (error) {
            return Promise.reject(new Error('Response shape unexpected.'));
        }
    })
        .catch(error => {
        ErrorUtils.sendSentryException(error);
        return ErrorUtils.formatError(error);
    });
};
