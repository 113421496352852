import { v4 as uuidV4 } from 'uuid';
import StorageUtils from '@spothero/utils/storage';
export const SESSION_UUID_COOKIE = 'sh-session-uuid';
export const SEARCH_UUID_COOKIE = 'sh-search-uuid';
export const ACTION_UUID_COOKIE = 'sh-action-uuid';
export const ACTION_COOKIE = 'sh-action-id';
const HALF_HOUR_IN_DAYS = 0.021;
export const SEARCH_TRACKING_ACTIONS = {
    SEARCH: 'SEARCH',
    EVENT: 'EVENT',
    VEHICLE_CHANGED: 'VEHICLE_CHANGED',
    DATE_CHANGED: 'DATE_CHANGED',
    FIND_ME: 'FIND_ME',
    LIST_SEARCH: 'LIST_SEARCH',
    LIST_EVENT: 'LIST_EVENT',
    LIST_DATE_CHANGED: 'LIST_DATE_CHANGED',
    LIST_SEARCH_NEXT: 'LIST_SEARCH_NEXT',
    PAN: 'PAN',
    ZOOM: 'ZOOM',
    AUTOZOOM: 'AUTOZOOM',
    UNAVAILABLE_SHOW_NEARBY: 'UNAVAILABLE_SHOW_NEARBY',
};
const NEW_SEARCH_INTENT_ACTIONS = [
    SEARCH_TRACKING_ACTIONS.SEARCH,
    SEARCH_TRACKING_ACTIONS.EVENT,
    SEARCH_TRACKING_ACTIONS.DATE_CHANGED,
    SEARCH_TRACKING_ACTIONS.FIND_ME,
    SEARCH_TRACKING_ACTIONS.LIST_SEARCH,
    SEARCH_TRACKING_ACTIONS.LIST_EVENT,
    SEARCH_TRACKING_ACTIONS.LIST_DATE_CHANGED,
    SEARCH_TRACKING_ACTIONS.VEHICLE_CHANGED,
];
/**
 * Utility for tracking user search behavior.
 *
 * @module SearchTracking
 * @see {@link https://spothero.atlassian.net/wiki/spaces/FE/pages/1313964582/Search+Tracking} for overall documentation on Search Tracking within Consumer Web
 */
const SearchTracking = {
    updateAction(actionName = SEARCH_TRACKING_ACTIONS.SEARCH) {
        StorageUtils.remove(ACTION_UUID_COOKIE, 'cookie');
        // Does our new actionName correspond to a new search intent?
        if (NEW_SEARCH_INTENT_ACTIONS.find(action => action === actionName)) {
            StorageUtils.remove(SEARCH_UUID_COOKIE, 'cookie');
        }
        StorageUtils.set(ACTION_COOKIE, actionName, 'cookie', HALF_HOUR_IN_DAYS);
    },
    getValues() {
        const sessionUUIDCookie = StorageUtils.get(SESSION_UUID_COOKIE, 'cookie');
        const searchUUIDCookie = StorageUtils.get(SEARCH_UUID_COOKIE, 'cookie');
        const actionUUIDCookie = StorageUtils.get(ACTION_UUID_COOKIE, 'cookie');
        const actionCookie = StorageUtils.get(ACTION_COOKIE, 'cookie');
        const sessionUUID = sessionUUIDCookie
            ? sessionUUIDCookie
            : uuidV4();
        const searchUUID = sessionUUIDCookie && searchUUIDCookie && /\S/.test(searchUUIDCookie) // cookie is not empty and not just whitespace
            ? searchUUIDCookie
            : uuidV4();
        const actionUUID = sessionUUIDCookie && searchUUIDCookie && actionUUIDCookie
            ? actionUUIDCookie
            : uuidV4();
        const action = actionCookie
            ? actionCookie
            : SEARCH_TRACKING_ACTIONS.SEARCH;
        StorageUtils.set(SESSION_UUID_COOKIE, sessionUUID, 'cookie', HALF_HOUR_IN_DAYS);
        StorageUtils.set(SEARCH_UUID_COOKIE, searchUUID, 'cookie', HALF_HOUR_IN_DAYS);
        StorageUtils.set(ACTION_UUID_COOKIE, actionUUID, 'cookie', HALF_HOUR_IN_DAYS);
        StorageUtils.set(ACTION_COOKIE, action, 'cookie', HALF_HOUR_IN_DAYS);
        return {
            sessionUUID,
            searchUUID,
            actionUUID,
            action,
        };
    },
    destroySession() {
        StorageUtils.remove(SESSION_UUID_COOKIE, 'cookie');
        StorageUtils.remove(SEARCH_UUID_COOKIE, 'cookie');
        StorageUtils.remove(ACTION_UUID_COOKIE, 'cookie');
        StorageUtils.remove(ACTION_COOKIE, 'cookie');
    },
};
export default SearchTracking;
