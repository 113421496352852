import dayjs from 'utils/dayjs';
import APIUtils from '@spothero/utils/api';
/**
 * Object containing all Events API methods
 *
 * @property {get} get Get Event data for a destination at a specific time
 * @property {getById} getById Get event data given id
 * @module api/events
 */
const EventsAPI = {
    /**
     * Get Event data for a destination at a specific time
     *
     * @function get
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Events/get_events|Documentation}
     */
    get({ destinationId, eventTypes = null, starts = dayjs().format() }) {
        const data = {
            destination_id: destinationId,
            page_size: 150,
            starts,
            include: 'search_url',
        };
        if (eventTypes) {
            data.event_types = eventTypes;
        }
        return APIUtils.get('events/', data);
    },
    /**
     * Get data for one specific event
     *
     * @function getById
     * @param {string} eventId - Event Id
     * @returns {Promise<object>} - API response
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Events/get_events_pk|Documentation}
     */
    getById(eventId) {
        return APIUtils.get(`events/${eventId}/`, {
            include: 'search_url,meta_description,meta_title,short_description,description,custom_description',
        });
    },
};
export default EventsAPI;
