import React from 'react';
import { Box, Icon, Text, Button } from '@spothero/ui';
import { SearchComponent } from '@spothero/search-component';
import X from '@spothero/icons/x';
import { VenueEventEdit } from '../../events/edit/VenueEventEdit';
import { useSelector } from 'react-redux';
import { useSearchUpdates } from 'pages/search/hooks/use-search-updates';
export const NotAModal = () => {
    const onSearchUpdates = useSearchUpdates();
    const handleClose = async () => {
        await onSearchUpdates();
    };
    const eventView = useSelector((state) => state.search.data.searchComponentEventView);
    const getLabel = () => {
        if (eventView === 'select')
            return 'Change Event';
        if (eventView === 'selectWFilter')
            return 'Events';
        return 'Edit';
    };
    const label = getLabel();
    return (<Box paddingX="4">
            <Box paddingBottom="5">
                <Text width="100%" textAlign="center" position="relative" fontWeight="medium" fontSize="sm">
                    <Button variant="tertiary" color="black" onClick={handleClose} position="absolute" left="0" height="100%">
                        <Icon as={X} height="4.5" width="4.5"/>
                    </Button>
                    {label}
                </Text>
            </Box>
            {eventView ? <VenueEventEdit /> : <SearchComponent />}
        </Box>);
};
