import {
    Box,
    Button,
    Card,
    Flex,
    Image,
    List,
    ListItem,
    Text,
    useBreakpointValue,
} from '@spothero/ui';
import React from 'react';
import PropTypes from 'prop-types';

const NoResultsForFiltersMessage = ({onResetFiltersClick}) => {
    const isMobile = useBreakpointValue({base: true, tablet: false});
    const ContainerComponent = isMobile ? Box : Card;
    const listItemStyles = {
        listStylePosition: 'outside',
    };

    return (
        <ContainerComponent data-testid="NoResultsForFiltersMessage">
            <Flex justifyContent="center">
                <Image
                    src="/img/search/spot-list-item-magnifying-glass.svg"
                    alt="Magnifying glass"
                />
            </Flex>

            <Box padding={2}>
                <Text variant="body2" fontWeight="semibold" paddingY={2}>
                    We couldn&apos;t find any nearby spots that match the
                    filters you applied.
                </Text>
                <Text variant="body2" paddingBottom={2}>
                    But don&apos;t worry, there are a few things you can try to
                    broaden your search:
                </Text>
                <List type="ordered" marginLeft={4}>
                    <ListItem sx={listItemStyles}>
                        Consider removing the filters to see all available
                        spots.
                    </ListItem>
                    <Flex justifyContent="center" paddingY={4}>
                        <Button onClick={onResetFiltersClick}>
                            Reset Filters
                        </Button>
                    </Flex>
                    <ListItem sx={listItemStyles}>
                        Zoom out on the map to expand your search area.
                        Sometimes, there are great options just a little further
                        away.
                    </ListItem>
                </List>
            </Box>
        </ContainerComponent>
    );
};

NoResultsForFiltersMessage.propTypes = {
    onResetFiltersClick: PropTypes.func,
};

export default NoResultsForFiltersMessage;
