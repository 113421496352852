import React from 'react';
import PropTypes from 'prop-types';

const SpotNotes = ({type, notes}) => {
    if (!notes.length) {
        return <p className="SpotNotes-none">No {type} notes saved yet.</p>;
    }

    return (
        <ul className="SpotNotes">
            {notes.map((note, i) => {
                return (
                    <li key={i}>
                        <strong>{note.label}</strong>: {note.message}
                    </li>
                );
            })}
        </ul>
    );
};

SpotNotes.propTypes = {
    type: PropTypes.oneOf(['spot', 'operator']),
    notes: PropTypes.arrayOf(
        PropTypes.shape({label: PropTypes.string, message: PropTypes.string})
    ),
};

SpotNotes.defaultProps = {
    notes: [],
};

export default SpotNotes;
