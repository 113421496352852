export const AmenityTypes = Object.freeze({
    ATTENDANT: 'attendant',
    COVERED_PARKING: 'covered-parking',
    EV: 'ev',
    HEATED: 'heated',
    IMMEDIATE_PARKING: 'immediate-parking',
    IN_OUT: 'in-out',
    NO_CONTRACT: 'no-contract',
    ONE_TAP: 'one-tap',
    PAVED: 'paved',
    SELF_PARK: 'self-park',
    SHUTTLE: 'shuttle',
    TOUCHLESS: 'touchless',
    VALET: 'valet',
    WHEELCHAIR: 'wheelchair',
});
