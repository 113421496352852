import React from 'react';

const SpotListItemPlaceholder = () => {
    return (
        <div
            className="SpotListItemPlaceholder"
            data-testid="SpotListItemPlaceholder"
        >
            <div
                className="SpotListItemPlaceholder-img"
                data-testid="SpotListItemPlaceholder-img"
            />
            <div
                className="SpotListItemPlaceholder-info"
                data-testid="SpotListItemPlaceholder-info"
            >
                <div
                    className="SpotListItemPlaceholder-address1"
                    data-testid="SpotListItemPlaceholder-address1"
                />
                <div
                    className="SpotListItemPlaceholder-address2"
                    data-testid="SpotListItemPlaceholder-address2"
                />
                <div
                    className="SpotListItemPlaceholder-amenities"
                    data-testid="SpotListItemPlaceholder-amenities"
                >
                    <div
                        className="SpotListItemPlaceholder-amenity"
                        data-testid="SpotListItemPlaceholder-amenity"
                    />
                    <div
                        className="SpotListItemPlaceholder-amenity"
                        data-testid="SpotListItemPlaceholder-amenity"
                    />
                </div>
            </div>
        </div>
    );
};

export default SpotListItemPlaceholder;
