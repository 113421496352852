import { getApps, initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue as getRemoteConfigValue, } from 'firebase/remote-config';
import ErrorUtils from 'utils/error-utils';
let app;
let remoteConfig;
/**
 * Initializes the Firebase app and Remote Config.
 *
 * @param {initializeFirebaseAppParams} params The Firebase app initialization parameters.
 */
export function initializeFirebaseApp({ firebaseAppId, firebasePublicKey, firebaseProjectId, }) {
    if (!getApps().length) {
        const firebaseConfig = {
            apiKey: firebasePublicKey,
            projectId: firebaseProjectId,
            appId: firebaseAppId,
        };
        app = initializeApp(firebaseConfig);
    }
    else {
        app = getApps()[0];
    }
    remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000; // 1 hour
}
/**
 * Fetches and returns the Remote Config value for the "key" field.
 *
 * @returns {Promise<string>} The remote config value for "key". In case of an error, the default value is returned.
 */
export async function getValue(key, defaultValue) {
    try {
        await fetchAndActivate(remoteConfig);
        return getRemoteConfigValue(remoteConfig, key).asString();
    }
    catch (error) {
        ErrorUtils.captureException(error);
        return defaultValue;
    }
}
