import PropTypes from 'prop-types';
/* eslint-disable camelcase */
export const DESTINATION_DATA = {
    airport: PropTypes.object,
    categories: PropTypes.any,
    city: PropTypes.string,
    deep_links: PropTypes.any,
    desktop_zoom_level: PropTypes.number,
    destination_info_title: PropTypes.string,
    events: PropTypes.any,
    generic_destination: PropTypes.bool,
    id: PropTypes.number,
    google_places: PropTypes.arrayOf(PropTypes.shape({
        google_places_id: PropTypes.string,
        google_places_name: PropTypes.string,
        google_places_place_id: PropTypes.string,
        pk: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    has_upcoming_events: PropTypes.bool,
    kml_map_layer_enabled: PropTypes.bool,
    kml_map_layer_id: PropTypes.number,
    kml_map_layer_url: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    meta_title: PropTypes.string,
    mobile_zoom_level: PropTypes.number,
    monthly_desktop_zoom_level: PropTypes.number,
    monthly_destination_info_title: PropTypes.string,
    monthly_enabled: PropTypes.bool,
    monthly_mobile_zoom_level: PropTypes.number,
    monthly_relative_url: PropTypes.string,
    override_ideal_zoom: PropTypes.bool,
    parent_url: PropTypes.string,
    relative_url: PropTypes.string,
    short_description: PropTypes.string,
    spothero_city: PropTypes.number,
    state: PropTypes.string,
    street_address: PropTypes.string,
    timezone: PropTypes.string,
    title: PropTypes.string,
    venue_content: PropTypes.any,
    venue_content_enabled: PropTypes.bool,
    zipcode: PropTypes.string,
};
/* eslint-enable camelcase */
