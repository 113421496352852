import React from 'react';
import Image from 'common/image';
import useAppstoreRating from 'hooks/use-appstore-rating';

const TrustSealValueProps = () => {
    const {text: ratingText} = useAppstoreRating({
        ratingText: 'App Store Rating (iOS)',
    });

    return (
        <div className="TrustSealValueProps">
            <div className="digicert-seal-container">
                <Image
                    className="digicert-seal-image"
                    cloudinaryImageId="logos/digicert-secured-seal"
                    width={100}
                    height={56}
                    alt="Norton DigiCert Seal"
                />
            </div>
            <div className="footer-value-props">
                <ul>
                    <li>{ratingText}</li>
                    <li>300+ Cities in the U.S. and Canada</li>
                    <li>75+ Million Cars Parked</li>
                </ul>
            </div>
        </div>
    );
};

export default TrustSealValueProps;
