import React from 'react';
import { useMediaQuery, useTheme } from '@spothero/ui';
export const isMobilePortraitView = () => {
    const mobileXL = '30rem';
    if (typeof window === 'undefined') {
        return false;
    }
    return window.matchMedia(`(max-width: ${mobileXL})`).matches || false;
};
const useIsMobile = () => {
    const theme = useTheme();
    const [isMobileDevice] = useMediaQuery(`(max-width: ${theme.breakpoints.mobileXL})`, {
        ssr: false,
    });
    return isMobileDevice;
};
export function withIsMobile(Component) {
    const WithIsMobile = (props = {}) => {
        const isMobile = useIsMobile();
        return <Component {...props} isMobile={isMobile}/>;
    };
    WithIsMobile.displayName = `WithIsMobile(${Component.displayName || Component.name || 'Component'})`;
    return WithIsMobile;
}
export default useIsMobile;
