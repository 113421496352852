import dayjs from 'utils/dayjs-timezone';
import isEmpty from 'lodash/isEmpty';
import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
import UserUtils from 'utils/user-utils';
import CheckoutUtils from 'utils/checkout-utils';
import getParkingType from '../utils/parking-type';
import getUserLocation from '../utils/user-location';
import Config from '@/config/index';
import {PAYMENT_TYPE_MAPPING, PAYMENT_NONE} from '../utils/payment-type';
import TimeUtils from 'utils/time';
import FormatUtils from 'utils/format';
import {CAR_PROTECTION_ADDON_TYPE} from 'types/rental-addons';
import {showInsuranceUpsell} from 'utils/insurance-upsell';

export const FAILURE_LOCATIONS = {
    FRONTEND_VALIDATION: 'frontend/validation',
    FRONTEND_PAYMENT: 'frontend/payment',
    BACKEND_RESERVE: 'backend/reserve',
    BACKEND_REBOOK: 'backend/rebook',
};
const FALLBACK_DECLINE_CODE = 'card_declined';
const DECLINE_CODES = [
    'generic_decline',
    'insufficient_funds',
    'lost_card',
    'stolen_card',
    'expired_card',
    'incorrect_cvc',
    'processing_error',
    'incorrect_number',
];

const mapStripeErrorToSHErrors = errorCode => {
    if (!errorCode || errorCode.length === 0) {
        return '';
    }

    return DECLINE_CODES.includes(errorCode)
        ? errorCode
        : FALLBACK_DECLINE_CODE;
};

export default function trackCheckoutFailed(args) {
    try {
        const {
            isMonthly,
            isPowerBooking,
            powerBookingFailedCount,
            spot: {
                facility: {
                    parking_spot_id: facilityId,
                    city,
                    title,
                    country,
                    state,
                    airport,
                    operator_id: operatorId,
                    freePark,
                },
                selectedRate,
                event_package: eventPackage,
                timezone,
            },
            promoCode,
            user: {email, isAdmin, status},
            phone,
            eventId,
            vehicleInfoId,
            vehicleProfileId,
            currencyType,
            selectedLicensePlate,
            selectedPaymentMethod,
            rateSessionId,
            initMoment,
            errors,
            errorCodes,
            selectedAddons,
            rentalAddons,
            failureLocation,
            purchaseForCustomer,
            isAirportInsuranceUpsellFeatureFlagEnabled,
        } = args;
        const now = dayjs();
        const isEvent = Boolean(eventId);
        const isAirport = Boolean(airport);
        const isEventPackage = !isEmpty(eventPackage);
        const reservationEnd = dayjs.tz(selectedRate?.ends, timezone);
        const reservationStart = dayjs.tz(selectedRate?.starts, timezone);
        const parkingType = getParkingType({
            isMonthly,
            isAirport,
            isEvent,
            isEventPackage,
        });
        const oversizeFee = CheckoutUtils.getOversizeFeeFromPriceBreakdown(
            selectedRate?.price_breakdown
        );
        const price = selectedRate?.price;
        const citySlug = FormatUtils.slugify(city);
        const transformedErrorCodes = [...errorCodes];

        if (failureLocation === FAILURE_LOCATIONS.FRONTEND_PAYMENT) {
            transformedErrorCodes[0] = mapStripeErrorToSHErrors(
                transformedErrorCodes[0]
            );
        }

        const insuranceAddon = rentalAddons?.find(
            addon => addon.type === CAR_PROTECTION_ADDON_TYPE
        );

        const selectedInsuranceInfo = selectedAddons?.find(
            addon => addon.type === CAR_PROTECTION_ADDON_TYPE
        );

        const insuranceUpsellShown = showInsuranceUpsell({
            isAirport,
            country,
            insuranceAddon,
            purchaseForCustomer,
            isAirportInsuranceUpsellFeatureFlagEnabled,
        });

        const properties = {
            admin_user: isAdmin,
            ...(isAirport && {
                airport_code: airport.details.code,
            }),
            checkout_started_to_purchase_failed: initMoment
                ? now.diff(initMoment, 'seconds')
                : null,
            city: citySlug,
            country: Config.cloudfrontViewerCountryCode,
            currency: currencyType.toLowerCase(),
            email,
            entered_phone_number: Boolean(phone),
            errors,
            error_codes: transformedErrorCodes,
            ...(isEvent && {event_id: eventId}),
            facility_country: country,
            failure_location: failureLocation,
            ...(!isEmpty(selectedLicensePlate) &&
                selectedLicensePlate !== 'DONTKNOW' && {
                    license_plate: selectedLicensePlate,
                }),
            operator_id: operatorId,
            oversized_vehicle: Boolean(oversizeFee),
            parked_car_protection_selected: Boolean(selectedInsuranceInfo),
            parked_car_protection_shown: insuranceUpsellShown,
            ...(insuranceUpsellShown && {
                parked_car_protection_price: insuranceAddon.price,
            }),
            parking_spot_id: facilityId,
            parking_spot_name: title,
            parking_type: parkingType,
            payment_type: freePark
                ? PAYMENT_NONE
                : PAYMENT_TYPE_MAPPING[selectedPaymentMethod],
            phone_number: phone ? phone : null,
            power_booking: isPowerBooking,
            price,
            products: [
                {
                    category: parkingType,
                    ...(promoCode && {coupon: promoCode.code}),
                    name: title,
                    price,
                    product_id: String(facilityId),
                    quantity: 1,
                },
            ],
            promo_code_used: Boolean(promoCode),
            rental_end_time_local: isEventPackage
                ? null
                : reservationEnd.format(TimeUtils.FULL_DATETIME_STRING),
            rental_end_time_utc: isEventPackage
                ? null
                : reservationEnd.toISOString(),
            rental_lead_time: reservationStart.diff(
                now.tz(timezone),
                'minutes'
            ),
            rental_length:
                isEventPackage || isMonthly
                    ? null
                    : reservationEnd.diff(reservationStart, 'minutes'),
            rental_start_time_local: reservationStart.format(
                TimeUtils.FULL_DATETIME_STRING
            ),
            rental_start_time_utc: reservationStart.toISOString(),
            session_id: rateSessionId,
            state,
            ...getUserLocation(),
            user_logged_in: status === UserUtils.AUTH_STATE.USER,
            vehicle_info_id: vehicleInfoId,
            vehicle_profile_id: vehicleProfileId,
            ...(isPowerBooking && {
                power_booking_failed_count: powerBookingFailedCount,
            }),
        };

        SegmentUtils.track({
            event: 'Checkout Failed',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 2,
                    },
                },
            },
        });
    } catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackCheckoutFailed',
        });
    }
}
