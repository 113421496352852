import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { getRatings } from 'store/filters/filters-selectors';
import isUndefined from 'lodash/isUndefined';
import { VIEW_RATINGS } from 'store/filters/rating-filter-utils';
import { resetFilters, updateFilters } from 'store/filters/filters-actions';
import { updateAmenityChecked } from './updateAmenityChecked';
import { trackFilterChange } from './trackfilterChange';
export const useFilterModal = () => {
    const amenitiesSelectedOnModalOpen = useRef();
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const activeAmenities = useSelector((state) => state.filters.activeAmenities);
    const activeRatingTypes = useSelector((state) => state.filters.activeRatingTypes);
    const storeState = useSelector((state) => state);
    const ratings = getRatings(storeState);
    const onModalToggle = () => {
        let isTrackChange = false;
        // If modal is being closed and filters have been changed since open (and clear-all hasn't been pressed), track changes
        if (showModal) {
            if (!isEqual(activeAmenities, amenitiesSelectedOnModalOpen.current)) {
                isTrackChange = true;
            }
            amenitiesSelectedOnModalOpen.current = [];
        }
        else {
            amenitiesSelectedOnModalOpen.current = activeAmenities;
        }
        setShowModal(show => !show);
        if (isTrackChange) {
            trackFilterChange(storeState);
        }
    };
    const onRatingChange = (amenity, isChecked) => {
        let newActiveRatings = [...activeRatingTypes];
        if (isChecked) {
            // if view-rating is enabled, we want to add all rating categories
            if (amenity === VIEW_RATINGS) {
                Object.values(ratings).forEach(rating => {
                    if (rating.active)
                        newActiveRatings.push(rating.slug);
                });
            }
            else {
                newActiveRatings.push(amenity);
            }
        }
        else {
            if (amenity === VIEW_RATINGS) {
                newActiveRatings = [];
            }
            else {
                newActiveRatings = newActiveRatings.filter(item => item !== amenity);
            }
        }
        dispatch(updateFilters({
            activeRatingTypes: newActiveRatings,
            viaQuickFilter: false,
        }));
    };
    const onFilterChange = (amenity, isChecked, isQuickFilter = false) => {
        if (!isUndefined(ratings[amenity]) || amenity === VIEW_RATINGS) {
            onRatingChange(amenity, isChecked);
        }
        else {
            updateAmenityChecked({
                activeAmenities,
                showModal,
                dispatch,
                storeState,
                amenity,
                isChecked,
                isQuickFilter,
            });
        }
    };
    const onClearFilters = () => {
        dispatch(resetFilters());
    };
    return {
        showModal,
        onModalToggle,
        onFilterChange,
        onClearFilters,
    };
};
