import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { USER_TYPES } from 'utils/types/user';
const { CURRENCY_TYPES } = USER_TYPES;
/**
 * Utilities for formatting and normalizing certain display aspects.
 *
 * @module FormatUtils
 */
const FormatUtils = {
    /**
     * Formats a phone number to (XXX) XXX-XXXX for cleaner display.
     *
     * @static
     * @function phone
     * @param {string} phoneNumber - The phone number to format.
     * @example
     * FormatUtils.phone('+13125556789'); // (312) 555-6789
     * @returns {string} - The formatted phone number.
     */
    phone(phoneNumber) {
        if (!isString(phoneNumber)) {
            throw new Error('The phone number must be provided as a string.');
        }
        return phoneNumber.replace(/^(?:\+1)?(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    },
    /**
     * Formats a number to display commas where appropriate for cleaner display.
     *
     * @static
     * @function numWithCommas
     * @param {number} num - The number to format.
     * @example
     * FormatUtils.numWithCommas(100); // '100'
     * FormatUtils.numWithCommas(1000); // '1,000'
     * FormatUtils.numWithCommas(555900); // '555,900'
     * FormatUtils.numWithCommas(12555900); // '12,555,900'
     * @returns {string} - The formatted number with commas applied.
     */
    numWithCommas(num) {
        if (!isNumber(num)) {
            throw new Error('The num param must be provided as a number.');
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    /**
     * Returns the appropriate symbol for the provided currency.
     *
     * @static
     * @function currencySymbol
     * @param {string} currencyType - The currency to return the symbol for.
     * @example
     * FormatUtils.currencySymbol(); // '$'
     * FormatUtils.currencySymbol(null); // '$'
     * FormatUtils.currencySymbol(UserUtils.CURRENCY_TYPES.CAD); // 'CAD$'
     * @returns {string} - The symbol for the supplied currency.
     */
    currencySymbol(currencyType = CURRENCY_TYPES.USD) {
        if (!currencyType) {
            return '$';
        }
        switch (currencyType.toUpperCase()) {
            case CURRENCY_TYPES.CAD:
                return 'CA$';
            default:
                return '$';
        }
    },
    /**
     * Converts a price to an object containing string represantations of the parts as well as a fully formatted display price.
     *
     * @static
     * @function price
     * @param {number} price - The price to convert, in pennies.
     * @example
     * FormatUtils.price(); // {dollars: '0', cents: undefined, displayPrice: '0.00'}
     * FormatUtils.price(null); // {dollars: '0', cents: undefined, displayPrice: '0.00'}
     * FormatUtils.price(55); // {dollars: '0', cents: '55', displayPrice: '0.55'}
     * FormatUtils.price(600); // {dollars: '6', cents: undefined, displayPrice: '6.00'}
     * FormatUtils.price(1034); // {dollars: '10', cents: '34', displayPrice: '10.34'}
     * @returns {object} price - The object containing the parts of the price.
     * @returns {string} price.dollars - The formatted dollars, if falsy, returns '0'.
     * @returns {string} [price.cents] - The formatted cents.
     * @returns {string} [price.displayPrice] - The formatted value with dollars and cents separated by a period.
     */
    price(price = 0) {
        const [dollars, cents] = ((price || 0) / 100)
            .toFixed(2)
            .toString()
            .replace(/\.0+$/, '') // if no cents, clear string after '.'
            .split('.');
        return {
            dollars,
            cents,
            displayPrice: [dollars, cents || '00'].join('.'),
        };
    },
    /**
     * Returns the appropriate pluralization of the provided word based on the count.
     *
     * @param {number} count - The number to base the pluralization on.
     * @param {string} singular - The singular form of the word.
     * @param {string} plural - The plural form of the word.
     * @returns {string} - The appropriate form of the word.
     */
    pluralize(count, singular, plural) {
        return count === 1 ? singular : plural;
    },
    slugify(str) {
        return str
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
    },
};
export default FormatUtils;
