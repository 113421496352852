import React from 'react';
import dayjs from 'utils/dayjs-timezone';
import { formatDateTime } from 'utils/format-date-time';
import { EventDate } from '../event-line-item/EventLineItem';
import { useShowLoader } from '@spothero/search-component';
import { useSelector } from 'react-redux';
import trackEventListItemSelected from 'segment/events/event-list-item-selected';
import { GenericLineItem } from '../event-line-item/GenericLineItem';
import { GenericListItem } from './GenericListItem';
function EventListItem(props) {
    const timezone = useSelector((state) => state.city.data.timezone);
    const { isLoaderShowing, setIsLoaderShowing } = useShowLoader(false);
    const { event: { id: eventId, starts: eventStarts, ends: eventEnds, title: eventTitle, }, eventUrl, index, customTitle = '', } = props;
    const title = customTitle || eventTitle;
    const starts = dayjs(formatDateTime(eventStarts, timezone));
    let ends;
    if (eventEnds)
        ends = dayjs(formatDateTime(eventEnds, timezone));
    const onEventListItemSelected = async () => {
        if (trackEventListItemSelected)
            await trackEventListItemSelected({
                source: 'midfunnel',
                index: index + 1,
                eventId: Number(eventId),
                eventName: eventTitle,
            });
    };
    const handleClick = async () => {
        setIsLoaderShowing(true);
        await onEventListItemSelected();
    };
    const endDateFormat = !ends || ends.isSame(starts, 'day') ? 'h:mm A' : 'ddd, h:mm A';
    return (<GenericListItem handleClick={handleClick} eventUrl={eventUrl} isLoaderShowing={isLoaderShowing}>
            <GenericLineItem topText={title} bottomText={`${starts.format('ddd, h:mm A')} - ${(ends || starts).format(endDateFormat)}`}>
                <EventDate month={starts.format('MMM')} date={starts.format('DD')}/>
            </GenericLineItem>
        </GenericListItem>);
}
export default EventListItem;
