import { ROUTER_DATA_LOAD_FULFILLED } from 'store/router/router-actions';
export const initialState = () => ({
    isPending: false,
    data: {
        slug: null,
        results: [],
    },
    error: null,
});
export default function category(state = initialState(), { type, payload }) {
    if (type === ROUTER_DATA_LOAD_FULFILLED) {
        return {
            ...state,
            ...payload.category,
        };
    }
    return state;
}
