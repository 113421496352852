import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import UrlUtils from '@spothero/utils/url';
import { setSelectedSpotId } from 'store/spots/spots-actions';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
export const useSpotIdSync = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedSpotId } = useReduxSearchState();
    useEffect(() => {
        const { 'spot-id': querySpotId } = UrlUtils.parseParams(history.location.search);
        if (querySpotId && querySpotId !== selectedSpotId) {
            dispatch(setSelectedSpotId({ spotId: querySpotId }));
        }
        else if (!querySpotId && selectedSpotId) {
            dispatch(setSelectedSpotId({ spotId: undefined }));
        }
    }, [dispatch, history.location.search, selectedSpotId]);
};
