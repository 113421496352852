import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { getWindow } from 'ssr-window';
import Config from '@/config/index';
import BranchUtils from 'utils/branch';
const window = getWindow();
/**
 * App Store utilities
 *
 * @module utils/app-store
 */
const AppStoreUtils = {
    /**
     * App Store link clicked
     *
     * @function appStoreLinkClicked
     * @param {object} arg - Top level argument
     * @param {("ios"|"android")} arg.device - type of device being used
     * @param {("prepurchase"|"postpurchase")} arg.channel - channel they are arriving at
     * @param {string | undefined} arg.email - Email of user
     * @throws {Error}
     * @returns {void}
     */
    appStoreLinkClicked({ device, channel, email }) {
        if (isEmpty(channel)) {
            throw new Error('Channel attribute is required.');
        }
        if (!Config.isMobile && isEmpty(device)) {
            throw new Error('Device attribute is required for desktop browsers.');
        }
        // desktop leverages short links generated by the product team
        // mobile uses the branch sdk to generate links
        if (!Config.isMobile) {
            switch (device) {
                case 'ios':
                    switch (channel) {
                        case 'prepurchase':
                            window.open('https://spothero.app.link/8NqGQOv9tPb', '_blank');
                            break;
                        case 'postpurchase':
                            window.open('https://spothero.app.link/XXKJuKI9tPb', '_blank');
                            break;
                    }
                    break;
                case 'android':
                    switch (channel) {
                        case 'prepurchase':
                            window.open('https://spothero.app.link/JR8lPeTauPb', '_blank');
                            break;
                        case 'postpurchase':
                            window.open('https://spothero.app.link/cDA6CdaauPb', '_blank');
                            break;
                    }
                    break;
            }
            return false;
        }
        const data = {
            channel,
        };
        if (!isUndefined(email)) {
            data.email = email;
        }
        BranchUtils.link({
            data,
        }, (err, link) => {
            if (!err) {
                window.location = link;
            }
        });
    },
};
export default AppStoreUtils;
