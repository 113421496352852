import SegmentUtils from 'utils/segment';
import getFilteredSearchResults from '../utils/filtered-search-results';

export default function trackSearchResultsList({
    spots,
    filters,
    sortBy,
    isMonthly,
}) {
    const filteredSpots = getFilteredSearchResults({
        filters,
        spots,
        isMonthly,
        sortBy,
    });

    const properties = {
        top_search_results_list: filteredSpots.map(({spotId, tag}) => {
            return {
                facility_id: spotId,
                tag,
            };
        }),
    };

    SegmentUtils.track({
        event: 'Search Results List',
        properties,
        additionalOptions: {
            context: {
                protocols: {
                    event_version: 1,
                },
            },
        },
    });
}
