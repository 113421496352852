import { ROUTER_DATA_LOAD_FULFILLED } from '../router/router-actions';
export const initialState = () => ({
    data: {
        latitude: null,
        longitude: null,
    },
});
export default function cloudfront(state = initialState(), { type }) {
    switch (type) {
        case ROUTER_DATA_LOAD_FULFILLED: {
            return state || initialState();
        }
        default:
            return state;
    }
}
