/**
 * Filters spot or spots from list of facilities
 *
 * @function formatSearchResponse
 * @param {Array} results - Facility data returned from the search API for multiple facilities
 * @param {Array|string} filterSpot - spot or spots to exclude from the search results
 * @returns {Array} The array of filtered facilities
 */
export function filterSearchResponse(results, filterSpot) {
    const filterSpots = Array.isArray(filterSpot) ? filterSpot : [filterSpot];
    const formattedFilterSpots = filterSpots.map(filterId => parseInt(filterId, 10));
    return results.filter(spot => !formattedFilterSpots.includes(parseInt(spot.spotId, 10)));
}
