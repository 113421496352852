import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import {compose} from 'redux';
import {connect} from 'react-redux';
import StorageUtils from '@spothero/utils/storage';
import withConfig from '@/config/withConfig';
import {push} from 'store/router/router-actions';
import {routeToSearch} from 'router/router-utils';
import {
    setTerm,
    updateTimes,
} from 'store/search-request/search-request-actions';
import {resetSelectedSpot} from 'store/spot/spot-actions';
import SearchGeocoderUtils from 'utils/search-geocoder-utils';
import SearchAPI from 'api/search';
import {Page} from 'utils/page-utils';
import pick from 'lodash/pick';
import SHUtils from 'utils/sh-utils';
import {getRouteParam, paramNameOptions} from 'utils/url-utils';
import CityAPI from 'api/city';
import {withGoogleMapsLibraries} from 'common/google-map-context';
import ErrorUtils from 'utils/error-utils';

const DidYouMeanModal = loadable(() => import('../did-you-mean-modal'));

function redirectToUrl(url) {
    try {
        window.location.replace(url);
    } catch (error) {
        ErrorUtils.sendSentryMessage({error, additionalData: {url}});
    }
}

export default EnhancedComponent => {
    class WithSearchControls extends Component {
        static propTypes = {
            isMobile: PropTypes.bool,
            apiDateTimeFormat: PropTypes.string.isRequired,
            timezone: PropTypes.string,
            isAdmin: PropTypes.bool.isRequired,
            isMonthly: PropTypes.bool,
            isEvent: PropTypes.bool,
            isAirport: PropTypes.bool,
            isDefaultTimes: PropTypes.bool,
            starts: PropTypes.string,
            ends: PropTypes.string,
            searchString: PropTypes.string,
            searchBounds: PropTypes.shape({
                swLat: PropTypes.number,
                swLng: PropTypes.number,
                neLat: PropTypes.number,
                neLng: PropTypes.number,
            }),
            originalCitySlug: PropTypes.string,
            originalSearchString: PropTypes.string,
            usersLatitude: PropTypes.string,
            usersLongitude: PropTypes.string,
            clearSelectedSpot: PropTypes.func.isRequired,
            updateSearchTimes: PropTypes.func.isRequired,
            pushTo: PropTypes.func.isRequired,
            onSearchClick: PropTypes.func,
            onClosePanelClick: PropTypes.func,
            setSearchTerm: PropTypes.func,
            isPowerBooking: PropTypes.bool.isRequired,
            powerBookingPeriods: PropTypes.array,
            powerBookingSource: PropTypes.string,
            googleMapsLibraries: PropTypes.object,
        };
        static defaultProps = {
            searchString: '',
            starts: null,
            ends: null,
        };

        constructor(props) {
            super(props);

            const {isMonthly, isAirport, searchString, starts, ends} = props;

            /**
             * Temporal storage for user selected location from Google Autocomplete.
             * On Search result pages we require user to confirm selection by clicking the Update Search button.
             *
             * @type {null|object}
             * @private
             */
            this._queuedLocationData = null;

            this.state = {
                starts,
                ends,
                isValidStart: true,
                isSearching: false,
                hasChangedDateTime: false,
                hasChangedDestination: false,
                currentSearch: searchString,
                searchAddressModalResults: null,
                prevIsMonthly: isMonthly,
                prevIsAirport: isAirport,
            };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            const {isMonthly, isAirport} = nextProps;
            const {prevIsMonthly, prevIsAirport} = prevState;
            let nextState = null;

            if (isMonthly !== prevIsMonthly || isAirport !== prevIsAirport) {
                nextState = {
                    ...nextState,
                    searchAddressModalResults: null,
                    prevIsMonthly: isMonthly,
                    prevIsAirport: isAirport,
                };
            }

            return nextState;
        }

        _onInputChange = value => {
            this.setState({
                currentSearch: value,
                hasChangedDestination: true,
            });
        };

        _onDateTimeChange = ({start, end, isValidStart}) => {
            const {apiDateTimeFormat, isDefaultTimes} = this.props;
            const {starts, ends} = this.state;
            const newStarts = start.format(apiDateTimeFormat);
            const newEnds = end?.format(apiDateTimeFormat);
            const nextState = {
                starts: newStarts,
                ends: newEnds,
                isValidStart,
            };

            if (!isDefaultTimes || starts !== newStarts || ends !== newEnds) {
                nextState.hasChangedDateTime = true;
            }

            this.setState(nextState);
        };

        _onDidYouMeanModalHidden = result => {
            const {onClosePanelClick, onSearchClick} = this.props;
            const {starts, ends} = this.state;

            this.setState({
                searchAddressModalResults: null,
            });

            // Has user chosen a location from multiple suggestions
            if (result) {
                // clear queue so that we can search immediately
                this._queuedLocationData = null;

                this._onSearchAddressSelected(result);

                if (onSearchClick) {
                    onSearchClick({
                        starts,
                        ends,
                    });
                }
            }

            if (onClosePanelClick) {
                onClosePanelClick();
            }
        };

        _onSearchAddressSelected = results => {
            let data = results;

            if (isArray(results)) {
                if (results.length === 1) {
                    data = SearchGeocoderUtils.getAddressData(results[0]);
                } else {
                    data = map(data, result => {
                        return SearchGeocoderUtils.getAddressData(result);
                    });

                    // no results or more than one result found, show modal
                    this.setState({
                        searchAddressModalResults: data,
                    });

                    return;
                }
            }

            /* eslint-disable camelcase */
            const submitParams = {
                search_string: data.search_string,
                latitude: data.latitude,
                longitude: data.longitude,
                google_places_id: data.places_id,
                google_places_place_id: data.places_place_id,
                google_places_reference: data.places_reference,
            };
            /* eslint-enable camelcase */

            this._onSubmit(submitParams);
        };

        _onFindParkingNearMe = () => {
            const {pushTo, isMonthly} = this.props;
            const searchUrl = `/${isMonthly ? 'monthly-' : ''}parking-near-me`;

            pushTo(searchUrl);
        };

        _onSearchClick = () => {
            const {
                onSearchClick,
                updateSearchTimes,
                googleMapsLibraries,
            } = this.props;
            const {
                starts,
                ends,
                hasChangedDateTime,
                hasChangedDestination,
                currentSearch,
            } = this.state;

            if (hasChangedDestination) {
                // Geocode is needed as user might have typed and pressed Search/Update Search button without making an autocomplete selection.
                const geocoder = new googleMapsLibraries.geocoding.Geocoder();

                geocoder.geocode(
                    {
                        address: currentSearch,
                    },
                    (res, status) => {
                        let results = res;

                        if (
                            status !==
                            googleMapsLibraries.geocoding.GeocoderStatus.OK
                        ) {
                            results = [];
                        }

                        this._onSearchAddressSelected(results);
                    }
                );
            }

            if (onSearchClick) {
                const searchClickParams = {
                    starts,
                    ends,
                };

                if (hasChangedDateTime) {
                    searchClickParams.callback = () => {
                        updateSearchTimes({
                            starts,
                            ends,
                        });
                    };
                }

                onSearchClick(searchClickParams);
            } else if (hasChangedDateTime) {
                updateSearchTimes({
                    starts,
                    ends,
                });
            }

            this.setState({
                hasChangedDateTime: false,
                hasChangedDestination: false,
            });
        };

        _onSubmit = async (locationData, isFromSuggestSelection = false) => {
            const {
                isMonthly,
                originalSearchString,
                usersLatitude,
                usersLongitude,
                clearSelectedSpot,
                pushTo,
                isDefaultTimes,
                originalCitySlug,
                timezone,
                powerBookingPeriods,
                isPowerBooking,
                powerBookingSource,
            } = this.props;
            const {starts, ends, hasChangedDateTime} = this.state;

            if (isFromSuggestSelection) {
                // save the location data and don't run a search because the user has to click Update Search in the UI
                this._queuedLocationData = locationData;

                return;
            }

            /* eslint-disable camelcase */
            const params = {
                ...(this._queuedLocationData
                    ? this._queuedLocationData
                    : locationData),
                csrfmiddlewaretoken: StorageUtils.get('csrftoken', 'cookie'),
                monthly: isMonthly,
                original_search_string: originalSearchString,
                users_latitude: usersLatitude,
                users_longitude: usersLongitude,
                starts: isDefaultTimes && !hasChangedDateTime ? null : starts,
                ends: isDefaultTimes && !hasChangedDateTime ? null : ends,
            };
            /* eslint-enable camelcase */

            this.setState({
                isSearching: true,
            });

            this._queuedLocationData = null;

            this.setState({
                isSearching: false,
                hasChangedDateTime: false,
                hasChangedDestination: false,
            });

            clearSelectedSpot();

            // Is it worth removing searchParams and just having the query params be the state itself?  having two sources of truth is tough
            const {
                data: {data: searchParams},
            } = await SearchAPI.cleanParams(params);
            const {
                page_info: {
                    page_type: pageType,
                    setup: {
                        city: {id: cityId, slug: citySlug} = {},
                        destination: {id: destinationId} = {},
                    },
                },
            } = searchParams;
            const id =
                pageType === Page.CITY
                    ? cityId
                    : pageType === Page.DESTINATION
                    ? destinationId
                    : null;

            if (isPowerBooking) {
                const queryStarts = [];
                const queryEnds = [];

                powerBookingPeriods?.forEach(
                    ({starts: periodStarts, ends: periodEnds}) => {
                        queryStarts.push(periodStarts);
                        queryEnds.push(periodEnds);
                    }
                );

                return routeToSearch({
                    method: pushTo,
                    pageType,
                    id,
                    queryParams: {
                        ...(!id &&
                            pick(params, [
                                'latitude',
                                'longitude',
                                'search_string',
                            ])),
                        starts: queryStarts,
                        ends: queryEnds,
                        // eslint-disable-next-line camelcase
                        power_booking: true,
                        ...(powerBookingSource && {
                            // eslint-disable-next-line camelcase
                            pb_source: powerBookingSource,
                        }),
                    },
                    historyState: {searchParams},
                });
            }
            // detect if timezone has changed
            if (originalCitySlug && originalCitySlug !== citySlug) {
                const {
                    data: {
                        data: {timezone: newTimezone},
                    },
                } = await CityAPI.get(citySlug);

                // ignore the start and end times when timezone changes
                if (timezone && timezone !== newTimezone) {
                    return routeToSearch({
                        method: redirectToUrl,
                        pageType,
                        id,
                        queryParams: {
                            ...(isMonthly && {monthly: true}),
                            ...(!id &&
                                pick(params, [
                                    'latitude',
                                    'longitude',
                                    'search_string',
                                    paramNameOptions.VIEW,
                                ])),
                            starts: null,
                            ends: null,
                            view: getRouteParam(paramNameOptions.VIEW),
                        },
                        historyState: {searchParams},
                    });
                }
            }

            routeToSearch({
                method: redirectToUrl,
                pageType,
                id,
                queryParams: {
                    ...(isMonthly && {monthly: true}),
                    ...(!id &&
                        pick(params, [
                            'latitude',
                            'longitude',
                            'search_string',
                        ])),
                    starts: searchParams.starts,
                    ends: isMonthly ? null : searchParams.ends,
                    view: getRouteParam(paramNameOptions.VIEW),
                },
                historyState: {searchParams},
            });
        };

        _onNotLookingForEvent = () => {
            const {city, destination} = this.state;
            const {setSearchTerm} = this.props;

            setSearchTerm({
                term: 'daily',
                city,
                destination,
            });

            SHUtils.staticScrollTo({delay: 0});
        };

        render() {
            const {
                isSearching,
                isValidStart,
                searchAddressModalResults,
                currentSearch,
            } = this.state;

            return (
                <>
                    <EnhancedComponent
                        {...this.props}
                        isSearching={isSearching}
                        isValidStart={isValidStart}
                        currentSearch={currentSearch}
                        onInputChange={this._onInputChange}
                        onDateTimeChange={this._onDateTimeChange}
                        onFindParkingNearMe={this._onFindParkingNearMe}
                        onSearchClick={this._onSearchClick}
                        onNotLookingForEvent={this._onNotLookingForEvent}
                        onSubmit={this._onSubmit}
                    />
                    {searchAddressModalResults && (
                        <DidYouMeanModal
                            results={searchAddressModalResults}
                            onHidden={this._onDidYouMeanModalHidden}
                        />
                    )}
                </>
            );
        }
    }

    const mapStateToProps = ({
        city: {data: city},
        destination: {data: destination},
        search: {
            data: {isDefaultTimes},
        },
        searchRequest: {
            monthly,
            airport,
            event: isEvent,
            starts,
            ends,
            search_string: searchString,
            original_search_string: originalSearchString,
            users_latitude: usersLatitude,
            users_longitude: usersLongitude,
            pageType,
            powerBooking,
            powerBookingPeriods,
            powerBookingSource,
        },
        user: {
            data: {isAdmin},
        },
    }) => {
        const {
            display_name: cityDisplayName,
            state: displayState,
            search_bounds: searchBounds,
            timezone,
            slug: originalCitySlug,
        } = city;
        let derivedSearchString = searchString ? searchString : '';

        if (isEmpty(derivedSearchString)) {
            if (pageType === 'city') {
                derivedSearchString = `${cityDisplayName}, ${displayState}`;
            } else if (pageType === 'destination' || pageType === 'event') {
                derivedSearchString = `${destination.title}, ${cityDisplayName}, ${displayState}`;
            }
        }

        return {
            isAdmin,
            timezone,
            isDefaultTimes,
            isMonthly: monthly,
            isAirport: airport,
            isEvent,
            isPowerBooking: powerBooking,
            powerBookingPeriods,
            starts,
            ends,
            searchString: derivedSearchString,
            originalCitySlug,
            originalSearchString,
            usersLatitude,
            usersLongitude,
            powerBookingSource,
            searchBounds: searchBounds && {
                swLat: searchBounds.sw_lat,
                swLng: searchBounds.sw_lng,
                neLat: searchBounds.ne_lat,
                neLng: searchBounds.ne_lng,
            },
        };
    };
    const mapDispatchToProps = {
        updateSearchTimes: updateTimes,
        clearSelectedSpot: resetSelectedSpot,
        setSearchTerm: setTerm,
        pushTo: push,
    };
    const enhance = compose(
        withConfig(['apiDateTimeFormat', 'isMobile']),
        withGoogleMapsLibraries,
        connect(mapStateToProps, mapDispatchToProps)
    );

    return enhance(WithSearchControls);
};
