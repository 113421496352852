import findIndex from 'lodash/findIndex';
import omit from 'lodash/omit';
import {ActionType} from 'redux-promise-middleware';
import {DESTINATION_LOAD_EVENTS} from '../destination/destination-actions';
import {FILTERS_UPDATE} from '../filters/filters-actions';
import {ROUTER_DATA_LOAD_FULFILLED} from '../router/router-actions';
import {
    SEARCH_SET_SORT_TYPE,
    SEARCH_SET_PAGE_VIEW,
} from '../search/search-actions';
import {
    SEARCH_REQUEST_SET_TERM,
    SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES,
} from '../search-request/search-request-actions';
import {
    SPOTS_SET_SPOT_SELECTED_RATE,
    SPOTS_SET_SELECTED_SPOT_ID,
    SPOTS_SET_HIGHLIGHTED_SPOT_ID,
    SPOTS_SET_PREVIEWED_SPOT_ID,
    SPOTS_SET_MOBILE_MAPVIEW_INTERACTION_TRIGGER,
} from './spots-actions';
import {
    SPOTS_GET_TRANSIENT_V2,
    SPOTS_GET_MONTHLY_V2,
    SPOTS_GET_AIRPORT_V2,
    SPOTS_GET_TRAVEL_DISTANCES_V2,
    SPOTS_GET_BULK_TRANSIENT_V2,
} from './spots-actions-v2';
import {SPOTS_GET_EVENT_PACKAGE} from './spots-event-packages-actions';
import EnvironmentUtils from '@spothero/utils/environment';
import merge from 'lodash/merge';
import {SearchPageView} from 'utils/search-page-view';

/**
 * @typedef SpotsState
 * @type {object}
 * @property {boolean} isPending - true if any requests for spots are pending
 * @property {*[]} airportPendingRequests - array to track pending requests for airport spots (workaround for issues with slow APIs)
 * @property {*[]} data - array of formatted spot data
 * @property {string} selectedSpotId - id of selected spot (used for displaying spot details panel)
 * @property {string} highlightedSpotId - id of highlighted spot (used for highlighting spot on map)
 * @property {string} previewedSpotId - id of previewed spot (used for previewing spot on Desktop map)
 * @property {string} mobileMapViewInteractionTrigger - Most recent trigger source for interation on mobile map view
 * @property {object} error - data returned if API errors
 * @property {string} loadMoreSpotsUrl tracks the @next URL that is used to fetch next set of spots after initial search request. Reference: https://api.staging.spothero.com/v2/docs#operation/searchTransientParking
 */

/** @type {SpotsState} */
export const initialState = {
    isPending: true,
    airportPendingRequests: [],
    data: [],
    selectedSpotId: null,
    highlightedSpotId: '',
    previewedSpotId: '',
    mobileMapViewInteractionTrigger: '',
    error: null,
    loadMoreSpotsUrl: null,
};

export default function spots(state = initialState, {type, payload}) {
    switch (type) {
        case SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES:
        case SEARCH_REQUEST_SET_TERM: {
            return {
                ...initialState,
            };
        }

        case ROUTER_DATA_LOAD_FULFILLED: {
            // On Server return initial state
            if (!EnvironmentUtils.isBrowser()) {
                return {
                    ...initialState,
                };
            }

            // On Client, keep state
            return {
                ...state,
            };
        }

        case `${DESTINATION_LOAD_EVENTS}_${ActionType.Pending}`: {
            return {
                ...initialState,
                selectedSpotId: state.selectedSpotId,
            };
        }

        case `${SPOTS_GET_TRANSIENT_V2}_${ActionType.Pending}`:
        case `${SPOTS_GET_MONTHLY_V2}_${ActionType.Pending}`:
        case `${SPOTS_GET_AIRPORT_V2}_${ActionType.Pending}`:
        case `${SPOTS_GET_BULK_TRANSIENT_V2}_${ActionType.Pending}`:
        case `${SPOTS_GET_EVENT_PACKAGE}_${ActionType.Pending}`: {
            return {...state, loadMoreSpotsUrl: null, isPending: true};
        }

        case `${SPOTS_GET_TRANSIENT_V2}_${ActionType.Rejected}`:
        case `${SPOTS_GET_MONTHLY_V2}_${ActionType.Rejected}`:
        case `${SPOTS_GET_AIRPORT_V2}_${ActionType.Rejected}`:
        case `${SPOTS_GET_BULK_TRANSIENT_V2}_${ActionType.Rejected}`:
        case `${SPOTS_GET_EVENT_PACKAGE}_${ActionType.Rejected}`: {
            const {error} = payload;

            return {...state, data: [], isPending: false, error};
        }

        case `${SPOTS_GET_TRANSIENT_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_MONTHLY_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_AIRPORT_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_BULK_TRANSIENT_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_EVENT_PACKAGE}_${ActionType.Fulfilled}`: {
            const {results, loadMoreSpotsUrl} = payload;

            return {
                ...state,
                data: [...results],
                loadMoreSpotsUrl,
                isPending: false,
            };
        }

        case `${SPOTS_GET_TRAVEL_DISTANCES_V2}_${ActionType.Fulfilled}`: {
            const {results: travelDistanceResults} = payload;
            const allSpots = state.data;
            const first10Spots = allSpots.slice(0, 10);
            const remainingSpots = allSpots.slice(10);
            const updatedSpots = first10Spots.map((spot, index) => {
                // check that we have the right spotId
                const idMatches =
                    travelDistanceResults[index]?.spotId === spot.spotId;

                return {
                    ...spot,
                    distance: {
                        ...spot.distance,
                        ...(idMatches
                            ? omit(travelDistanceResults[index], 'spotId')
                            : {}),
                    },
                };
            });

            return {
                ...state,
                data: [...updatedSpots, ...remainingSpots],
            };
        }

        case SEARCH_SET_SORT_TYPE:
        case FILTERS_UPDATE: {
            // when these actions are fired just set the spots to themselves so that
            // the selector runs on the data and updates the component props accordingly
            return {
                ...state,
                data: state.data,
            };
        }

        case SPOTS_SET_SPOT_SELECTED_RATE: {
            const allSpots = [...state.data];
            const spot = payload.spotWithNewRate;
            const index = findIndex(allSpots, {
                // eslint-disable-next-line camelcase
                parking_spot_id: spot.parking_spot_id,
            });

            if (index !== -1) {
                allSpots.splice(index, 1, spot);
            }

            return {
                ...state,
                data: allSpots,
            };
        }

        case SPOTS_SET_SELECTED_SPOT_ID: {
            const {spotId} = payload;

            return {
                ...state,
                selectedSpotId: spotId,
            };
        }

        case SPOTS_SET_PREVIEWED_SPOT_ID: {
            const {spotId} = payload;

            return {
                ...state,
                previewedSpotId: spotId,
                highlightedSpotId: '', // clear highlighted spot when a spot is previewed
            };
        }

        case SPOTS_SET_MOBILE_MAPVIEW_INTERACTION_TRIGGER: {
            const {trigger} = payload;

            return {
                ...state,
                mobileMapViewInteractionTrigger: trigger,
            };
        }

        case SPOTS_SET_HIGHLIGHTED_SPOT_ID: {
            const {spotId} = payload;

            return {
                ...state,
                highlightedSpotId: spotId,
            };
        }

        // clear highlighted spot when mobile list view is activated
        case SEARCH_SET_PAGE_VIEW: {
            return merge({}, state, {
                highlightedSpotId:
                    payload.view !== SearchPageView.MOBILE_MAP
                        ? ''
                        : state.highlightedSpotId,
            });
        }

        default:
            return state;
    }
}
