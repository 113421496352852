import {Page} from 'utils/page-utils';
import {initialState as searchRequestInitialState} from 'store/search-request/search-request-reducer';

const getInitialState = ({state}) => {
    return {
        ...state,
        searchRequest: {
            ...searchRequestInitialState,
            pageType: Page.HOME,
        },
        appRating: {isPending: false},
    };
};

export default getInitialState;
