import {ROUTER_HISTORY_CALLED} from '../router/router-actions';

export const routerMiddleware = history => () => next => action => {
    const {type, payload} = action;

    if (type === ROUTER_HISTORY_CALLED) {
        const {method, url, state} = payload;

        history[method](url, state);
    }

    return next(action);
};
