import isFunction from 'lodash/isFunction';
import includes from 'lodash/includes';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getDocument, getWindow} from 'ssr-window';
import IconMenu from '@spothero/icons/menu';
import IconSpothero from '@spothero/icons/spothero';
import {Button, Icon, Box} from '@spothero/ui';
import BrandUtils from '@spothero/utils/brand';
import DOMUtils from '@spothero/utils/dom';
import {withAuth0} from '@auth0/auth0-react';
import Link from 'router/Link';
import NavigationCollapsed from './mobile';
import NavigationExpanded from './desktop';

const document = getDocument();
const window = getWindow();

class Navigation extends Component {
    static propTypes = {
        isWhiteHeader: PropTypes.bool,
        user: PropTypes.object,
        isMonolith: PropTypes.bool,
        isProduction: PropTypes.bool,
        expandedData: PropTypes.array,
        collapsedData: PropTypes.array,
        onItemSelected: PropTypes.func,
        auth0: PropTypes.shape({
            logout: PropTypes.func,
            isAuthenticated: PropTypes.bool,
        }).isRequired,
    };
    static defaultProps = {
        collapsedData: [],
        expandedData: [],
        isWhiteHeader: false,
    };

    constructor(props) {
        super(props);

        this._HTML = DOMUtils.el('html');
        this._viewportLarge = BrandUtils.getBreakpoint('lg');

        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this._onWindowResize);

        this._onWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._onWindowResize);
    }

    _onWindowResize = () => {
        const windowWidth = window.innerWidth || document.body.clientWidth;

        if (windowWidth >= this._viewportLarge && this.state.isOpen) {
            this._onCloseClick();
        }
    };

    _onOpenClick = () => {
        this.setState({
            isOpen: true,
        });

        DOMUtils.addClass(this._HTML, 'nav-menu-open');
    };

    _onCloseClick = cb => {
        this.setState({
            isOpen: false,
        });

        setTimeout(() => {
            DOMUtils.removeClass(this._HTML, 'nav-menu-open');

            if (isFunction(cb)) {
                cb();
            }
        }, 500);
    };

    _onItemSelected = evt => {
        const {onItemSelected} = this.props;
        const isExternalLink = includes(
            DOMUtils.attr(evt.target, 'href'),
            'http'
        );

        if (onItemSelected && !isExternalLink) {
            onItemSelected();
        }
    };

    render() {
        const {
            isProduction,
            isMonolith,
            user,
            collapsedData,
            expandedData,
            isWhiteHeader,
        } = this.props;
        const {isOpen} = this.state;
        const backgroundColor = isWhiteHeader ? 'white' : 'primary.default';

        return (
            <Box
                height={{desktop: '16', base: '14'}}
                position="relative"
                width="100%"
                maxWidth={{tablet: '82rem'}}
                backgroundColor={backgroundColor}
                zIndex="layer10"
                margin="0 auto"
            >
                <Box
                    position={{base: 'fixed', tablet: 'relative'}}
                    width="100%"
                    height={{base: '14', tablet: '16'}}
                    backgroundColor={backgroundColor}
                    zIndex={{base: 'layer10', tablet: '0'}}
                >
                    <Link
                        to="/"
                        forceAnchor
                        aria-label="Home"
                        data-testid="Header-logo"
                    >
                        <Icon
                            as={IconSpothero}
                            position="absolute"
                            top="50%"
                            transform="translate(0, -50%)"
                            left={{base: '4', tablet: '10', desktop: '2.5'}}
                            width="14"
                            height="14"
                            fill={isWhiteHeader ? 'primary.default' : 'white'}
                        />
                    </Link>
                    <Button
                        onClick={this._onOpenClick}
                        variant="tertiary"
                        aria-label="Collapse Navigation Toggle"
                        data-testid="Header-menu-mobile"
                        fontSize="xl"
                        position="absolute"
                        top="50%"
                        transform="translate(0, -50%)"
                        padding="2"
                        left="auto"
                        right={{base: '6', tablet: '1'}}
                        height="auto"
                        width="12"
                        margin="auto"
                        color={isWhiteHeader ? 'black' : 'white'}
                        zIndex="layer5"
                        display={{desktop: 'none'}}
                        sx={{
                            '&:hover': {
                                background: 'inherit',
                                border: 'none',
                                color: isWhiteHeader ? 'black' : 'white',
                            },
                        }}
                    >
                        <Icon
                            as={IconMenu}
                            data-testid="Header-menu-mobile-icon"
                            height="6"
                            width="6"
                            position="relative"
                            marginX="0"
                            marginTop="-3px"
                            marginBottom="0"
                            verticalAlign="middle"
                        />
                    </Button>
                </Box>
                <Box position="fixed" zIndex="layer10">
                    {isOpen && (
                        <Box
                            data-testid="NavigationShim"
                            position="fixed"
                            left="0"
                            top="0"
                            bottom="0"
                            right="0"
                            margin="auto"
                            height="100%"
                            width="100%"
                            backgroundColor="rgba(33, 33, 33, 0.6)"
                            onClick={this._onCloseClick}
                        />
                    )}
                    <NavigationCollapsed
                        forceAnchors={isMonolith}
                        user={user}
                        isOpen={isOpen}
                        items={collapsedData}
                        onItemSelected={this._onItemSelected}
                        onCloseClick={this._onCloseClick}
                    />
                </Box>
                <NavigationExpanded
                    isWhiteHeader={isWhiteHeader}
                    forceAnchors={isMonolith}
                    isProduction={isProduction}
                    user={user}
                    items={expandedData}
                    onItemSelected={this._onItemSelected}
                />
            </Box>
        );
    }
}

export default withAuth0(Navigation);
