import React from 'react';
import PropTypes from 'prop-types';
import {Box, Image, Flex, Text, Button} from '@spothero/ui';

const MonthlyLowInventory = ({onNavigateToHourlyClick}) => {
    return (
        <Flex
            justify="center"
            align="center"
            direction="column"
            gap="2.5"
            margin="4"
            marginBottom="16"
        >
            <Box width="106px">
                <Image
                    alt="Monthly low inventory message"
                    cloudinaryImageId="monthly/low-inventory"
                    data-testid="MonthlyLowInventory-img"
                    htmlHeight={1024}
                    htmlWidth={1024}
                    quality={100}
                />
            </Box>
            <Text
                fontSize="sm"
                textAlign="center"
                paddingX="8"
                marginBottom="2"
                data-testid="MonthlyLowInventory-text"
            >
                Not finding what you&apos;re looking for? You can either modify
                your reservation or take a look at our hourly parking options.
            </Text>
            <Button
                onClick={onNavigateToHourlyClick}
                variant="secondary"
                data-testid="MonthlyLowInventory-button"
            >
                Find Hourly Parking
            </Button>
        </Flex>
    );
};

MonthlyLowInventory.propTypes = {
    onNavigateToHourlyClick: PropTypes.func.isRequired,
};

export default MonthlyLowInventory;
