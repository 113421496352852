import pick from 'lodash/pick';
import { OBJECT_MONTHLY_RATE } from '@/proptypes/index';
import { buildCheckoutURL, formatAmenitySlug, formatDateTime } from '../../utils';
export function formatMonthlyRate({ spotId, facilitySlug, quote: { order, advertisedPrice, totalPrice }, additionalRateDetails, eventId, timezone, rebookId, }) {
    // only keep properties that have corresponding rate type (transient, monthly, etc) prop types
    // and combine them with the additional rate details for that rate type as well as any additional
    // properties that rate type needs which are derived from other data not in the API
    const baseRate = order[0] || {};
    const formattedRate = pick({
        ...additionalRateDetails,
        ...baseRate,
        starts: formatDateTime(baseRate.starts, timezone),
        ends: formatDateTime(baseRate.ends, timezone),
        priceBreakdown: baseRate.items,
        amenities: additionalRateDetails.amenities?.map(formatAmenitySlug),
        fullUrl: buildCheckoutURL({
            spotId,
            facilitySlug,
            rateId: baseRate.rateId,
            rateType: 'monthly',
            starts: baseRate.starts,
            ends: baseRate.ends,
            eventId,
            timezone,
            rebookId,
        }),
        monthlyReservationType: additionalRateDetails.reservationType,
        pricingDetails: additionalRateDetails.pricingDetails || null,
    }, Object.keys(OBJECT_MONTHLY_RATE));
    return {
        ...formattedRate,
        advertisedPrice,
        totalPrice,
    };
}
