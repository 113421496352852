import includes from 'lodash/includes';
import UserUtils from 'utils/user-utils';
import APIUtils from '@spothero/utils/api';
import StorageUtils from '@spothero/utils/storage';
import ErrorUtils from 'utils/error-utils';
import SearchTracking from 'utils/search-tracking';
import { getErrorsFromResponse, redirectToConfirmationPage, } from '../reservation';
import { PaymentOverlayCompletionStatuses, } from './types';
import buildPayment from './helpers/build-payment';
import { buildTransientAirportEventRentalItem, buildMonthlyRentalItem, buildEventPackageRentalItem, buildPowerBookingRentalItems, } from './helpers/build-rental-items';
import handlePowerBookingResponse from './helpers/handle-powerbooking-response';
import SegmentUtils from 'utils/segment';
import { reduceAddonPrices } from './utils';
export const purchase = async ({ checkoutData, onError, onSuccess, selectedRate, setNewCardAsDefault = false, source = null, stripePaymentType = 'cc', stripeToken = null, routerPush, monthlyStarts = null, rentalSourceReferrer = null, bulkPowerBookingRates = [], isPowerBooking = false, setFailedPeriods = () => null, }) => {
    const { currencyType, isMonthly, user, selectedCreditCard, spot, paypal, purchaseForCustomer, paymentRequired, paymentOverlay, promoCode, purchaseForCustomerDescription, initialReservationId, } = checkoutData;
    const eventPackageId = spot?.event_package?.id;
    const { searchUUID, actionUUID } = SearchTracking.getValues();
    let rentalItems = [];
    const commonArgs = {
        searchUUID,
        actionUUID,
        checkoutData,
        rentalSourceReferrer,
        source,
    };
    if (isMonthly) {
        rentalItems = [
            buildMonthlyRentalItem({
                ...commonArgs,
                monthlyStarts,
                selectedRate,
            }),
        ];
    }
    else if (eventPackageId) {
        rentalItems = [
            buildEventPackageRentalItem({
                ...commonArgs,
                selectedRate,
            }),
        ];
    }
    else if (isPowerBooking) {
        rentalItems = buildPowerBookingRentalItems({
            ...commonArgs,
            bulkPowerBookingRates,
        });
    }
    else {
        rentalItems = [
            buildTransientAirportEventRentalItem({
                searchUUID,
                actionUUID,
                checkoutData,
                rentalSourceReferrer,
                selectedRate,
                source,
            }),
        ];
    }
    const payment = buildPayment({
        user,
        selectedCreditCard,
        paypal,
        purchaseForCustomer,
        paymentRequired,
        eventPackageId,
        paymentOverlay,
        stripePaymentType,
        stripeToken,
        setNewCardAsDefault,
        promoCode,
        saveCardOverride: selectedCreditCard === 'new' &&
            isMonthly &&
            selectedRate.recurrable,
        useCreditEnabled: !eventPackageId,
        usePromoEnabled: !eventPackageId,
        purchaseForCustomerDescription,
        initialReservationId,
    });
    const totalPrice = rentalItems.reduce((total, item) => total + item.price + reduceAddonPrices(item), 0);
    const shExperimentVariations = StorageUtils.get('sh-experiment-variations', 'cookie');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'SpotHero-Version': '2024-04-01',
            ...(shExperimentVariations && {
                'x-spothero-experiments': JSON.stringify(shExperimentVariations),
            }),
        },
    };
    const postData = {
        items: rentalItems,
        payment,
        total_price: totalPrice,
        currency: currencyType.toLowerCase() ||
            UserUtils.CURRENCY_TYPES.DEFAULT.toLowerCase(),
        email: user.email,
        mixpanel_id: SegmentUtils.getAnonymousId(),
        ...(isPowerBooking && { accept_partial_success: true }),
    };
    try {
        const { data: { data: { purchases, order_id: orderId, order_access_key: accessKey, }, }, } = await APIUtils.post('/checkout/', postData, config, false);
        const pbData = isPowerBooking
            ? handlePowerBookingResponse({
                setFailedPeriods,
                purchases,
                spot,
                onError,
                paymentOverlay,
                rentalItems: rentalItems,
            })
            : null;
        // Stay on checkout if all reservations failed
        if (isPowerBooking && !pbData?.powerBookingReservationsIds?.length) {
            return;
        }
        const powerBookingReservationsIds = pbData
            ? pbData.powerBookingReservationsIds
            : [];
        // This is to accommodate new confirmation receipt
        const failedPowerBookingPeriods = pbData
            ? pbData.failedPowerBookingPeriods
            : [];
        const formattedFailedPeriods = pbData
            ? pbData.formattedFailedPeriods
            : [];
        const reservationDetails = isPowerBooking
            ? pbData?.powerBookingReservationDetail
            : purchases[0].detail;
        const reservation = {
            reservation_id: reservationDetails.rental_id,
            starts: reservationDetails.starts,
            ends: reservationDetails.ends,
        };
        if (onSuccess) {
            await onSuccess({
                checkoutData,
                reservation,
                rentalId: orderId,
                powerBookingReservationsIds,
                failedPowerBookingPeriodsLength: failedPowerBookingPeriods.length,
            });
        }
        // remove any existing referrer cookies on purchase
        StorageUtils.remove('sh-referrer', 'cookie');
        // hide the Apple/Google Pay payment overlay
        if (paymentOverlay) {
            paymentOverlay.complete(PaymentOverlayCompletionStatuses.SUCCESS);
        }
        if (!(source && includes(source, 'widget'))) {
            redirectToConfirmationPage({
                orderId,
                accessKey,
                routerPush,
                failedPeriods: formattedFailedPeriods,
            });
        }
    }
    catch (error) {
        // We want to log code failures here, not network failures
        if (error instanceof Error) {
            ErrorUtils.sendSentryException(error);
        }
        const { errors, extra } = getErrorsFromResponse(error);
        // show error in the Apple/Google Pay payment overlay so that it doesn't look like it keeps trying to process
        if (paymentOverlay) {
            paymentOverlay.complete(PaymentOverlayCompletionStatuses.FAIL);
        }
        onError(errors, extra);
    }
};
