import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import withConfig from '@/config/withConfig';

const ApplicationMeta = ({
    appStoreLink,
    metaLogoUrl,
    playStoreLink,
    facebookAppId,
    isDeployed,
}) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta
                name="description"
                itemProp="description"
                content="Find, book & save on both daily & monthly parking with convenient garages, lots & valets near your destination."
            />
            <meta name="robots" content="noodp, noydir" />
            <meta property="fb:app_id" content={facebookAppId} />
            <meta name="twitter:card" content="app" />
            <meta name="twitter:site" content="@spothero" />
            <meta name="twitter:creator" content="@spothero" />
            <meta name="twitter:app:id:iphone" content="499097243" />
            <meta name="twitter:app:url:iphone" content={appStoreLink} />
            <meta name="twitter:app:name:ipad" content="SpotHero" />
            <meta name="twitter:app:url:ipad" content={appStoreLink} />
            <meta
                name="twitter:app:id:googleplay"
                content="com.spothero.spothero"
            />
            <meta name="twitter:app:url:googleplay" content={playStoreLink} />
            {isDeployed && (
                <meta
                    name="google-site-verification"
                    content="xoYVsdp3bXNxwds_Sw1C46aJ_9pJ0tQOXI3bxgBGyfQ"
                />
            )}
            {isDeployed && (
                <meta
                    name="msvalidate.01"
                    content="E44412335167C2135871BD45A0A0B36C"
                />
            )}
            <meta property="og:type" content="website" />
            <meta property="og:image" content={metaLogoUrl} />
            <meta property="og:site_name" content="SpotHero" />
            <title>
                SpotHero - Park Smarter. | Reserve Parking Now &amp; Save
            </title>
        </Helmet>
    );
};

ApplicationMeta.propTypes = {
    appStoreLink: PropTypes.string.isRequired,
    metaLogoUrl: PropTypes.string.isRequired,
    playStoreLink: PropTypes.string.isRequired,
    facebookAppId: PropTypes.string.isRequired,
    isDeployed: PropTypes.bool.isRequired,
};

export default withConfig([
    'appStoreLink',
    'facebookAppId',
    'isDeployed',
    'metaLogoUrl',
    'playStoreLink',
])(ApplicationMeta);
