import useConfig from 'hooks/use-config';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isStaff } from 'utils/user-utils';
const useShakeTheApp = () => {
    const email = useSelector((state) => state?.user?.data?.email); // Conditionals for safety
    const { isProduction } = useConfig();
    const stg = {
        val: 'GuQ70I8GhJrbOvoqrSq7II040ZLLrvDZvobBSRw9iDDWh7AeyubFtPG',
        url: 'awsstaging.kickthe.tires',
    };
    const prd = {
        val: 'y87aUOzEd0MDAQaXzq790StNAvfCv00dPfoVraFr3Vaf23p0EzISGXl',
        url: 'spothero.com',
    };
    const env = isProduction ? prd : stg;
    useEffect(() => {
        const startShake = () => {
            if (window.Shake) {
                if (isStaff(email)) {
                    window.Shake.start(env.val, env.url);
                }
            }
        };
        startShake();
    }, [email, env]);
};
export default useShakeTheApp;
