import { Flex, Box, Image } from '@spothero/ui';
import React from 'react';
import { VISUAL_FLAG_TYPES } from 'utils/visual-flags';
import { FACILITY_SUMMARY_VIEW_TYPES } from '../utils';
import { getParkingType } from 'store/selectors/selectors';
import { useSelector } from 'react-redux';
import { isEventOrTransientOrPowerBookingParking } from 'segment/utils/parking-type';
const mapStyles = {
    width: 'fit-content',
    minWidth: 'unset',
    marginLeft: {
        base: 0,
        tablet: '-0.5rem',
    },
    marginTop: {
        base: '-0.5rem',
        tablet: '-1rem',
    },
    marginBottom: {
        base: 0,
        tablet: '0.75rem',
    },
    borderTopLeftRadius: 0,
    borderTopRightRadius: '0.5rem',
    zIndex: 100,
};
const spotDetailStyles = {
    marginLeft: '-1rem',
    marginTop: '-0.5rem',
    marginBottom: '0.5rem',
    borderTopRightRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
};
const VisualFlag = ({ view, title, type, icon }) => {
    const rootState = useSelector(state => state);
    const parkingType = getParkingType(rootState);
    const renderVisualFlag = isEventOrTransientOrPowerBookingParking(parkingType);
    const isMapView = view === FACILITY_SUMMARY_VIEW_TYPES.SPOT_MAP;
    const isListView = view === FACILITY_SUMMARY_VIEW_TYPES.SPOT_LIST;
    const isDetailView = view === FACILITY_SUMMARY_VIEW_TYPES.SPOT_DETAIL;
    const flagTitle = isListView && type === VISUAL_FLAG_TYPES.OFFICIAL_PARKING
        ? 'Official Parking'
        : title;
    const showIcon = type === VISUAL_FLAG_TYPES.OFFICIAL_PARKING;
    if (view === FACILITY_SUMMARY_VIEW_TYPES.CHECKOUT_LIST ||
        !renderVisualFlag) {
        return null;
    }
    return (<Flex>
            <Box paddingX={4} paddingY={isMapView ? '2px' : '1px'} textAlign="center" backgroundColor="black" color="white" borderTopLeftRadius="0.5rem" borderBottomRightRadius="0.5rem" {...(isDetailView ? spotDetailStyles : {})} {...(isMapView ? mapStyles : {})} data-testid={`VisualFlag-${encodeURIComponent(title)}`}>
                <Flex fontSize={isMapView ? 'xs' : 'sm'} fontWeight="semibold" alignItems="center" gap="2">
                    {showIcon ? (<Image width="14px" height="14px" data-testid="VisualFlag-Icon" src={!icon ||
                icon === 'https://res.cloudinary.com/spothero/'
                ? '/img/official-icon.png'
                : icon}/>) : null}
                    {flagTitle}
                </Flex>
            </Box>
        </Flex>);
};
export default VisualFlag;
