import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import UserUtils from 'utils/user-utils';
import Auth0Loading from './Auth0Loading';
const Login = ({ history }) => {
    const { loginWithRedirect } = useAuth0();
    const userStatus = useSelector(({ user }) => user.data.status);
    const urlParams = new URLSearchParams(history?.location?.search);
    const rawNextParam = urlParams.get('next');
    // next param needs to stay encoded so it can be decoded properly in Auth0Callback
    const nextParam = rawNextParam ? encodeURIComponent(rawNextParam) : null;
    useEffect(() => {
        // if the user is already logged in, redirect to the home page
        if (userStatus === UserUtils.AUTH_STATE.USER) {
            history.push('/');
            return;
        }
        // otherwise redirect to the auth0 ULP
        const appState = nextParam ? { returnTo: nextParam } : null;
        loginWithRedirect({ appState }).catch(() => history.push('/auth/error'));
    }, [history, loginWithRedirect, nextParam, userStatus]);
    return <Auth0Loading />;
};
Login.propTypes = {
    history: PropTypes.object.isRequired,
};
export default Login;
