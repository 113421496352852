/* eslint-disable jsdoc/require-property-description */
import { FACILITY_TYPES } from 'utils/types/facility';
/**
 * To parse version and id from the illustration url
 *
 * @param {string} url - url to pull version and id out of
 * @returns {string[]} - [full string, version, id]
 */
export const handleIllustrations = (url) => {
    // Array for parsing illustartion string to return: [full string, version, id]
    const imageRegex = /\/v(.*?)\/(.+?)(\.[^.]*$|$)/;
    return url.match(imageRegex);
};
/**
 *  To parse version and id from the illustration url
 *
 * @param {object} hoursObj - Top level hours object
 * @param {boolean} hoursObj.alwaysOpen - Is the spot always open
 * @param {Period[]} hoursObj.periods - periods of opening
 * @returns {string[]} - [full string, version, id]
 */
export const handleHours = (hoursObj = {}) => {
    const { alwaysOpen, periods = [] } = hoursObj;
    const daysOfTheWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']; // Not ideal but quick solve as dayjs has sun as 0 but our db is mon as 0
    const result = {
        text: alwaysOpen ? ['This facility is open 24/7.'] : [],
        periods: periods.map(period => {
            const { startDayOfWeek, endDayOfWeek, startTime, endTime, hoursTypes, } = period;
            return {
                start_dow: startDayOfWeek,
                start_dow_int: daysOfTheWeek.indexOf(startDayOfWeek),
                start_time: startTime,
                end_dow: endDayOfWeek,
                end_dow_int: daysOfTheWeek.indexOf(endDayOfWeek),
                end_time: endTime,
                hours_type: hoursTypes,
            };
        }),
    };
    return result;
};
/**
 * Wrapper for if statement on facility types
 *
 * @param {object} cancellation - generic top level object
 * @param {boolean} cancellation.allowedByCustomer - can customer cancel
 * @param {boolean} cancellation.allowedBySpotheroCustomerService - can heros cancel
 * @returns {string} Returns one of FACILITY_TYPES
 */
export const handleCancellation = ({ allowedByCustomer, allowedBySpotheroCustomerService, }) => {
    if (allowedByCustomer) {
        return FACILITY_TYPES.CANCELLATION_ALLOWED_ENUM.CUSTOMER;
    }
    else if (allowedBySpotheroCustomerService) {
        return FACILITY_TYPES.CANCELLATION_ALLOWED_ENUM.HERO;
    }
    return FACILITY_TYPES.CANCELLATION_ALLOWED_ENUM.NOT_ALLOWED;
};
export const formatRedemptionInstructions = ({ text, illustration, id }, i) => {
    const illustrationValues = illustration?.url
        ? handleIllustrations(illustration?.url)
        : [];
    return {
        illustration_id: illustrationValues[2],
        illustration_version: illustrationValues[1],
        text,
        id: parseInt(id, 10),
        position: i,
    };
};
