import { useCallback, useRef } from 'react';
/**
 * useTouch hook to detect touch events and call a callback if the touch is a tap, otherwise, the touch is ignored
 * @param {Function} callback - callback to call if the touch is a tap
 */
const useTouch = (callback) => {
    const touchStart = useRef({ x: 0, y: 0 });
    const onTouchStart = useCallback((e) => {
        touchStart.current = {
            x: e.touches[0].clientX,
            y: e.touches[0].clientY,
        };
    }, []);
    const onTouchEnd = useCallback((e) => {
        if (callback) {
            const deltaX = e.changedTouches[0].clientX - touchStart.current.x;
            const deltaY = e.changedTouches[0].clientY - touchStart.current.y;
            if (Math.abs(deltaX) < 10 && Math.abs(deltaY) < 10) {
                callback(e);
            }
        }
    }, [callback]);
    return { onTouchStart, onTouchEnd };
};
export default useTouch;
