import React from 'react';
import PropTypes from 'prop-types';
import {Box, Text} from '@spothero/ui';
import Image from 'common/image';

export const spriteStyleProps = {
    display: 'inline-flex',
    width: '26px',
    height: '18px',
};

const PaymentMethodsIcons = ({
    showAlternatePaymentOptions,
    showLesserUsed,
    showAmexAndDiscover = true,
    showMoreText = true,
    alignment = 'center',
}) => {
    return (
        <Box justifyContent={alignment} display="flex" alignItems="center">
            {showAlternatePaymentOptions && (
                <>
                    <Box as="span" {...spriteStyleProps} marginRight={1}>
                        <Image
                            src="/img/cc-icons/apple-pay.svg"
                            alt="Apple Pay Icon"
                            showLoader={false}
                        />
                    </Box>
                    <Box as="span" {...spriteStyleProps} marginRight={1}>
                        <Image
                            src="/img/cc-icons/google-pay.svg"
                            alt="Google Pay Icon"
                            showLoader={false}
                        />
                    </Box>
                    <Box as="span" {...spriteStyleProps} marginRight={1}>
                        <Image
                            src="/img/cc-icons/paypal.svg"
                            alt="Paypal Icon"
                            showLoader={false}
                        />
                    </Box>
                </>
            )}
            <Box as="span" {...spriteStyleProps} marginRight={1}>
                <Image
                    src="/img/cc-icons/visa.svg"
                    alt="VISA Icon"
                    showLoader={false}
                />
            </Box>
            <Box as="span" {...spriteStyleProps} marginRight={1}>
                <Image
                    src="/img/cc-icons/mastercard.svg"
                    alt="MasterCard Icon"
                    showLoader={false}
                />
            </Box>
            {showAmexAndDiscover && (
                <>
                    <Box as="span" {...spriteStyleProps} marginRight={1}>
                        <Image
                            src="/img/cc-icons/american-express.svg"
                            alt="American Express Icon"
                            showLoader={false}
                        />
                    </Box>
                    <Box as="span" {...spriteStyleProps}>
                        <Image
                            src="/img/cc-icons/discover.svg"
                            alt="Discover Card Icon"
                            showLoader={false}
                        />
                    </Box>
                </>
            )}
            {showLesserUsed && (
                <>
                    <Box
                        as="span"
                        {...spriteStyleProps}
                        marginRight={1}
                        marginLeft={1}
                    >
                        <Image
                            src="/img/cc-icons/jcb.svg"
                            alt="JCB Icon"
                            showLoader={false}
                        />
                    </Box>
                    <Box as="span" {...spriteStyleProps}>
                        <Image
                            src="/img/cc-icons/diners-club.svg"
                            alt="Diners Club Icon"
                            showLoader={false}
                        />
                    </Box>
                </>
            )}
            {showMoreText ? (
                <Text
                    color="black"
                    marginLeft={1}
                    fontSize="xs"
                    display="inline-block"
                >
                    + more
                </Text>
            ) : null}
        </Box>
    );
};

PaymentMethodsIcons.propTypes = {
    showAlternatePaymentOptions: PropTypes.bool,
    showLesserUsed: PropTypes.bool,
    showAmexAndDiscover: PropTypes.bool,
    showMoreText: PropTypes.bool,
    alignment: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
};

export default PaymentMethodsIcons;
