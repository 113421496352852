import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterCheckboxBase } from '../checkbox-like/FilterCheckboxBase';
import { updateAmenityChecked } from '../../utils/updateAmenityChecked';
export const FilterAmenity = ({ children, amenity, }) => {
    const dispatch = useDispatch();
    const storeState = useSelector((state) => state);
    const { activeAmenities } = useSelector((state) => state.filters);
    const isActive = activeAmenities.includes(amenity);
    const onChange = () => {
        updateAmenityChecked({
            activeAmenities,
            isChecked: !isActive,
            amenity,
            isQuickFilter: true,
            showModal: false,
            dispatch,
            storeState,
        });
    };
    return (<FilterCheckboxBase testid={`FilterItem-${amenity}`} isActive={isActive} onClick={onChange}>
            {children}
        </FilterCheckboxBase>);
};
