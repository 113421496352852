import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Flex, RadioGroup, Text, } from '@spothero/ui';
import BorderedRadio from 'common/bordered-radio';
const ChooseVehicleModalContent = ({ vehicle: selectedVehicle, userVehicles, onAddNewVehicle, onSelectVehicle, isOpen, onClose, handleOnHidden, }) => {
    // Local temporary state
    const [newVehicle, setNewVehicle] = React.useState(selectedVehicle);
    let selectedId = selectedVehicle?.id || Number.MIN_SAFE_INTEGER;
    let allVehicles = userVehicles;
    if (selectedVehicle &&
        'vehicleProfileId' in selectedVehicle &&
        selectedVehicle.vehicleProfileId) {
        selectedId = selectedVehicle.vehicleProfileId;
    }
    // When the selected vehicle is not in the user's vehicles, add it to the list
    if (selectedVehicle &&
        !userVehicles.find(v => v.id === selectedVehicle.id)) {
        allVehicles = [...userVehicles, selectedVehicle];
    }
    return (<Modal title={'Choose Vehicle'} onHidden={handleOnHidden} isOpen={isOpen} onClose={onClose} hasHeader hasFooter scrollBehavior="inside" contentStyling={{
            maxWidth: { base: '100%', mobileXL: '30%' },
            minWidth: { base: '100%', mobileXL: '332px' },
        }}>
            <ModalHeader fontSize="md">Choose Vehicle</ModalHeader>
            <ModalBody>
                <Flex flexGrow={1} flexDirection="column" data-testid="ChooseVehicleModal">
                    <RadioGroup defaultValue={`${selectedId}`} onChange={(id) => {
            const selected = allVehicles.find(v => v.id === Number(id) ||
                ('vehicleProfileId' in v &&
                    v.vehicleProfileId === Number(id)));
            setNewVehicle(selected);
        }} paddingBottom={4}>
                        {allVehicles.map(vehicle => {
            const id = 'vehicleProfileId' in vehicle &&
                vehicle.vehicleProfileId
                ? vehicle.vehicleProfileId
                : vehicle.id;
            const description = 'licensePlate' in vehicle &&
                vehicle.licensePlate?.length
                ? `${vehicle.display_description} · ${vehicle.licensePlate}`
                : vehicle.display_description;
            return (<BorderedRadio value={`${id}`} key={id} radioStyles={{
                    paddingY: 3,
                    height: 'auto',
                }}>
                                    <Text data-testid="AddVehicleModal-default-vehicle" marginBottom={1}>
                                        {description}
                                    </Text>
                                </BorderedRadio>);
        })}
                    </RadioGroup>
                </Flex>
            </ModalBody>
            <ModalFooter background="white">
                <Flex justifyContent="flex-end" gap={{ base: 2, mobileXL: 4 }}>
                    <Button variant="secondary" paddingX={{ base: 4, mobileXL: 8 }} onClick={onAddNewVehicle} data-testid="ChooseVehicleModal-add-vehicle">
                        Add New Vehicle
                    </Button>
                    <Button onClick={() => onSelectVehicle(newVehicle)} variant="primary" fontFamily="body" data-testid="ChooseVehicleModal-select-vehicle" paddingX={{ base: 4, mobileXL: 8 }}>
                        Select Vehicle
                    </Button>
                </Flex>
            </ModalFooter>
        </Modal>);
};
export default ChooseVehicleModalContent;
