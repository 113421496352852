import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSortType } from 'store/search/search-actions';
import { getParkingType } from 'store/selectors/selectors';
import { SPOT_SORT_OPTIONS } from 'utils/spot-sort';
// Optimizely [Sort by Relevance] A/B Test implementation
// Wait for Optimizely to be ready and then sets the sort type
const WithOptimizelySortByRelevance = WrappedComponent => {
    function Component(props) {
        const dispatch = useDispatch();
        const state = useSelector((s) => s);
        const parkingType = getParkingType(state);
        useEffect(() => {
            const isEventOrTransientOrPowerBookingParking = parkingType === 'event' ||
                parkingType === 'transient' ||
                parkingType === 'power_booking';
            if (isEventOrTransientOrPowerBookingParking) {
                dispatch(setSortType(SPOT_SORT_OPTIONS.RELEVANCE, false));
            }
        }, [dispatch, parkingType]);
        return <WrappedComponent {...props}/>;
    }
    return Component;
};
export default WithOptimizelySortByRelevance;
