import React, {useState} from 'react';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import IconInfo from '@spothero/icons/info-circle-filled';
import {Modal, ModalContent} from '@spothero/ui-backlog';
import {Button, Icon as IconUI} from '@spothero/ui';
import DOMUtils from '@spothero/utils/dom';
import AmenityUtils from '@spothero/utils/amenity';

/**
 * @function renderIcon - Handler for Amenity icons
 * @param {string} icon - Icon Name
 * @returns {React.Component | null} - Icon comes back
 */
const renderIcon = icon => {
    const Icon = AmenityUtils.getIcon(icon);

    if (!Icon) {
        return null;
    }

    return (
        <span className="AmenityIcon">
            <Icon />
        </span>
    );
};

/**
 * @function renderInOut - This is a wrapper on a switch for the render in and out amenities
 * @param {object} props - Generic top level object
 * @param {object} props.rate - rate object from spot data
 * @param {boolean} props.isMonthly - isMonthly spot boolean
 * @param {Function} props.onRateDetailsClick - onclick for fee function (param due to parent prop coming down into core function)
 * @returns {React.Component} - react component comes back
 */
const renderInOut = ({rate, isMonthly, onRateDetailsClick}) => {
    if (rate && isMonthly) {
        const inOut = rate.in_out.allowed;
        const items = [];
        const buttonProps = {
            variant: 'tertiary',
            fontSize: 'sm',
            marginLeft: 1.5,
            'data-section': 'in-out',
            onClick: onRateDetailsClick,
        };

        switch (inOut) {
            case 'yes':
                items.push(
                    <li key={inOut}>
                        {renderIcon('in-out')}
                        <span
                            className="AmenitiesList-item-content"
                            data-testid="amenity"
                        >
                            Unlimited In/Out
                        </span>
                    </li>
                );
                break;

            case 'yes_fee':
                items.push(
                    <li key={inOut}>
                        {renderIcon('in-out')}
                        <span
                            className="AmenitiesList-item-content"
                            data-testid="amenity"
                        >
                            Unlimited In/Out
                            <Button {...buttonProps}>with Fee</Button>
                        </span>
                    </li>
                );
                break;

            case 'yes_limited':
                items.push(
                    <li key={inOut}>
                        {renderIcon('in-out')}
                        <span
                            className="AmenitiesList-item-content"
                            data-testid="amenity"
                        >
                            <Button
                                {...buttonProps}
                                marginLeft={0}
                                marginRight={1.5}
                            >
                                Limited
                            </Button>{' '}
                            In/Out
                        </span>
                    </li>
                );
                break;

            case 'yes_limited_fee':
                items.push(
                    <li key={inOut}>
                        {renderIcon('in-out')}
                        <span className="AmenitiesList-item-content">
                            Limited In/Out
                            <Button {...buttonProps}>with Fee</Button>
                        </span>
                    </li>
                );
                break;
        }

        return items;
    } else {
        return null;
    }
};

/**
 * @function renderOtherAmenities - handles non in and out amenities
 * @param {object} props - Generic top level object
 * @param {object} props.rate - rate object from spot data
 * @param {boolean} props.nonRateAmenities - non rate amenities coming from facilities
 * @param {Function} props.onToggleTouchlessModal - onclick for modal toggle (param due to state pass down)
 * @returns
 */
const renderOtherAmenities = ({
    rate,
    nonRateAmenities,
    onToggleTouchlessModal,
}) => {
    if (rate) {
        return values(rate.amenities).map(({slug, name}, i) => {
            return (
                <li key={i}>
                    {renderIcon(slug)}
                    <span
                        className="AmenitiesList-item-content"
                        data-testid="amenity"
                    >
                        {name}
                    </span>
                    {slug === 'touchless' && (
                        <Button
                            variant="tertiary"
                            fontSize="base"
                            marginLeft={1.5}
                            alignSelf="auto"
                            padding="0 0 3px 0"
                            className="AmenitiesList-touchless"
                            onClick={onToggleTouchlessModal}
                        >
                            <IconUI as={IconInfo} />
                        </Button>
                    )}
                </li>
            );
        });
    } else {
        return nonRateAmenities.map(({visible, slug, name}, i) => {
            if (visible) {
                return (
                    <li key={i}>
                        {renderIcon(slug)}
                        <span
                            className="AmenitiesList-item-content"
                            data-testid="amenity"
                        >
                            {name}
                        </span>
                    </li>
                );
            } else {
                return null;
            }
        });
    }
};

const AmenitiesList = ({
    rate,
    isMonthly,
    nonRateAmenities,
    onShowRateDetailsClick,
}) => {
    const [showTouchlessModal, setShowTouchlessModal] = useState(false);

    const onRateDetailsClick = evt => {
        evt.preventDefault();

        onShowRateDetailsClick(
            DOMUtils.attr(evt.currentTarget, 'data-section')
        );
    };

    const onToggleTouchlessModal = () => {
        setShowTouchlessModal(!showTouchlessModal);
    };

    const items = values(
        renderInOut({rate, isMonthly, onRateDetailsClick})
    ).concat(
        values(
            renderOtherAmenities({
                rate,
                nonRateAmenities,
                onToggleTouchlessModal,
            })
        )
    );

    return (
        <div
            className="SpotDetails-section Amenities"
            data-testid="AmenitiesList"
        >
            <h4 className="subtitle">Amenities</h4>
            <ul className="AmenitiesList">{items}</ul>
            {showTouchlessModal && (
                <Modal
                    className="AmenitiesList-touchless-modal"
                    title="Touchless Redemption"
                    onHidden={onToggleTouchlessModal}
                >
                    <ModalContent>
                        This is a self-park facility that doesn&apos;t require
                        you to touch buttons or surfaces to redeem your
                        reservation.
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
};

AmenitiesList.propTypes = {
    rate: PropTypes.object,
    isMonthly: PropTypes.bool.isRequired,
    nonRateAmenities: PropTypes.arrayOf(PropTypes.object),
    onShowRateDetailsClick: PropTypes.func,
};

export default AmenitiesList;
