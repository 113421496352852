import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { ActionType } from 'redux-promise-middleware';
import Config from '@/config/index';
import { SPOT_SORT_OPTIONS } from 'utils/spot-sort';
import { DESTINATION_UPDATE } from '../destination/destination-actions';
import { ROUTER_DATA_LOAD_FULFILLED } from '../router/router-actions';
import { SEARCH_REQUEST_SET_TERM, SEARCH_REQUEST_SET_LOCATION, SEARCH_REQUEST_UPDATE_TIMES, SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES, } from '../search-request/search-request-actions';
import { SPOTS_GET_TRANSIENT_V2, SPOTS_GET_MONTHLY_V2, SPOTS_GET_AIRPORT_V2, SPOTS_GET_BULK_TRANSIENT_V2, } from '../spots/spots-actions-v2';
import { SPOTS_GET_EVENT_PACKAGE } from '../spots/spots-event-packages-actions';
import { SEARCH_GET_AIRPORTS, SEARCH_SET_SORT_TYPE, SEARCH_SET_NO_SPOTS_FOUND, SEARCH_SET_ZOOM, SEARCH_SET_PAGE_VIEW, SEARCH_SET_RECENTLY_VIEWED, SEARCH_PAN_MAP, SEARCH_VEHICLE_PROFILE, SEARCH_OVERSIZE_POPOVER_VISIBLE, SEARCH_SET_SEARCH_CONTROLS_VISIBILITY, SEARCH_SET_PRICE_BREAKDOWN_MODAL_SPOT_ID, SEARCH_SET_COMPONENT_IS_OPEN, SEARCH_SET_COMPONENT_EVENT_VIEW, SEARCH_SET_STATE_WITH_FEE_INCLUDED, } from './search-actions';
import SearchUtils from 'utils/search-utils';
import { getRouteParam, paramNameOptions } from 'utils/url-utils';
import { getDefaultStatesWithFeeIncluded } from 'pages/search/hooks/total-price-toggle/use-fetch-states-with-fee-included';
export const initialState = {
    isPending: false,
    recentlyViewedSpots: new Set([1]),
    searchCenterPostZoomOrPan: { latitude: 0, longitude: 0 },
    statesWithFeeIncluded: getDefaultStatesWithFeeIncluded(),
    data: {
        ...SearchUtils.defaultSearchSettings,
        ...(getRouteParam(paramNameOptions.VIEW)
            ? { view: getRouteParam(paramNameOptions.VIEW) }
            : {}),
    },
    error: null,
};
export default function search(state = initialState, { type, payload }) {
    switch (type) {
        case `${SEARCH_GET_AIRPORTS}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    airports: payload,
                },
            };
        }
        case SEARCH_SET_SORT_TYPE: {
            return merge({}, state, {
                data: {
                    sortBy: payload.sortBy,
                },
            });
        }
        case SEARCH_REQUEST_SET_LOCATION: {
            const { title } = payload;
            return merge({}, state, {
                data: {
                    destinationTitle: title,
                    breadcrumbs: {
                        activeBreadcrumb: {
                            label: title,
                        },
                    },
                },
            });
        }
        case SEARCH_REQUEST_UPDATE_TIMES: {
            return merge({}, state, {
                data: {
                    isDefaultTimes: false,
                },
            });
        }
        case ROUTER_DATA_LOAD_FULFILLED: {
            const { search: cleanedSearchState } = payload;
            if (cleanedSearchState) {
                return merge({}, state, cleanedSearchState);
            }
            else {
                return state;
            }
        }
        case `${SPOTS_GET_TRANSIENT_V2}_${ActionType.Pending}`:
        case `${SPOTS_GET_MONTHLY_V2}_${ActionType.Pending}`:
        case `${SPOTS_GET_BULK_TRANSIENT_V2}_${ActionType.Pending}`:
        case `${SPOTS_GET_EVENT_PACKAGE}_${ActionType.Pending}`: {
            const isMonthly = type === `${SPOTS_GET_MONTHLY_V2}_${ActionType.Pending}`;
            return merge({}, state, {
                isPending: true,
                data: {
                    showNoSpotsFound: false,
                    ...(isMonthly ? { sortBy: SPOT_SORT_OPTIONS.DISTANCE } : {}),
                },
                error: null,
            });
        }
        case `${SPOTS_GET_TRANSIENT_V2}_${ActionType.Rejected}`:
        case `${SPOTS_GET_MONTHLY_V2}_${ActionType.Rejected}`:
        case `${SPOTS_GET_AIRPORT_V2}_${ActionType.Rejected}`:
        case `${SPOTS_GET_BULK_TRANSIENT_V2}_${ActionType.Rejected}`:
        case `${SPOTS_GET_EVENT_PACKAGE}_${ActionType.Rejected}`: {
            return merge({}, state, {
                isPending: false,
                data: {},
                error: payload,
            });
        }
        case `${SPOTS_GET_TRANSIENT_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_MONTHLY_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_AIRPORT_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_BULK_TRANSIENT_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_EVENT_PACKAGE}_${ActionType.Fulfilled}`: {
            const { results } = payload;
            return merge({}, state, {
                isPending: false,
                data: {
                    showNoSpotsFound: results.length === 0,
                },
            });
        }
        case `${SPOTS_GET_AIRPORT_V2}_${ActionType.Pending}`: {
            return merge({}, state, {
                isPending: true,
                data: {
                    showNoSpotsFound: false,
                    sortBy: SPOT_SORT_OPTIONS.FEATURED,
                },
                error: null,
            });
        }
        case SEARCH_SET_PAGE_VIEW: {
            return merge({}, state, {
                data: {
                    view: payload.view,
                },
            });
        }
        case SEARCH_SET_NO_SPOTS_FOUND: {
            return merge({}, state, {
                data: {
                    showNoSpotsFound: payload.showNoSpotsFound,
                },
            });
        }
        case SEARCH_PAN_MAP:
        case SEARCH_SET_ZOOM: {
            return merge({}, state, {
                data: {
                    zoom: payload.level,
                },
                searchCenterPostZoomOrPan: payload.mapCenter,
            });
        }
        case DESTINATION_UPDATE: {
            return merge({}, state, {
                data: {
                    searchType: payload.searchType,
                },
            });
        }
        case SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES:
        case SEARCH_REQUEST_SET_TERM: {
            const { monthly, city, destination = {} } = payload;
            const { zoom } = state;
            const hasDestination = !isEmpty(destination);
            const updatedZoom = city || hasDestination
                ? city
                    ? SearchUtils.getCityZoom({
                        city,
                        monthly,
                        mobile: Config.isMobile,
                    })
                    : hasDestination
                        ? destination.override_ideal_zoom &&
                            (destination.desktop_zoom_level ||
                                destination.mobile_zoom_level)
                            ? SearchUtils.getDestinationZoom({
                                destination,
                                monthly,
                                mobile: Config.isMobile,
                            })
                            : destination.default_map_zoom_level ||
                                city.default_map_zoom_level
                        : zoom
                : zoom;
            return merge({}, state, {
                data: {
                    zoom: updatedZoom,
                },
            });
        }
        case SEARCH_SET_RECENTLY_VIEWED: {
            return merge({}, state, {
                ...payload,
            });
        }
        case SEARCH_SET_SEARCH_CONTROLS_VISIBILITY: {
            return merge({}, state, {
                data: {
                    searchControlsVisibility: payload,
                },
            });
        }
        case SEARCH_VEHICLE_PROFILE: {
            // clear any previous vehicle profile if not provided
            if (payload && !('vehicleProfileId' in payload)) {
                payload.vehicleProfileId = null;
                payload.licensePlate = null;
            }
            return merge({}, state, {
                data: {
                    vehicle: payload,
                },
            });
        }
        case SEARCH_OVERSIZE_POPOVER_VISIBLE: {
            return merge({}, state, {
                data: {
                    searchOversizePopoverVisible: payload,
                },
            });
        }
        case SEARCH_SET_PRICE_BREAKDOWN_MODAL_SPOT_ID: {
            return merge({}, state, {
                data: {
                    priceBreakdownModalSpotId: payload.spotId,
                    priceBreakdownModalSpotRate: payload.rate,
                },
            });
        }
        case SEARCH_SET_COMPONENT_IS_OPEN: {
            return merge({}, state, {
                data: {
                    searchComponentIsOpen: payload,
                },
            });
        }
        case SEARCH_SET_COMPONENT_EVENT_VIEW: {
            return merge({}, state, {
                data: {
                    searchComponentEventView: payload,
                },
            });
        }
        case SEARCH_SET_STATE_WITH_FEE_INCLUDED: {
            return merge({}, state, {
                statesWithFeeIncluded: payload,
            });
        }
        default:
            return state;
    }
}
