import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import UrlUtils from '@spothero/utils/url';
import RouterLoader from 'router/RouterLoader';
import { Route, useLocation } from 'react-router-dom';
import routes, { matchPathToRoute } from 'router/routes';
import { dataLoadFulfilled } from './store/router/router-actions';
import { updateData as updateSearchRequest } from './store/search-request/search-request-actions';
import { Page } from 'utils/page-utils';
import NotFound from 'pages/not-found';
import ErrorUtils from 'utils/error-utils';
import SearchUtils from 'utils/search-utils';
import { getSearchVehicleFromSession } from 'utils/search-vehicle';
// Get state for next route/page via `getInitialState` and publish to reducers
export async function reduceStateForRoute(prevLocation, location, dispatch, getState, setIsLoading) {
    const { pathname, search, state: historyState = {} } = location;
    const { pathname: prevPathname } = prevLocation;
    const route = matchPathToRoute(pathname);
    const prevRoute = matchPathToRoute(prevPathname);
    const query = UrlUtils.parseParams(search);
    if (isNil(route)) {
        throw new Error(`Unable to match path to route: ${pathname}`);
    }
    // If updating the url on search page, might not want to refetch data,
    // like if just updating for persistence or if spot-id is added or removed from the URL,
    // in which case, we let the search component handle showing and hiding spot details
    if (route.isSearch && prevRoute.isSearch && historyState.reload === false) {
        SearchUtils.setPreviousSearch();
        return;
    }
    const { component, params } = route;
    const isReplacedRoute = historyState.method === 'replace';
    if (!isReplacedRoute && isFunction(component.getInitialState)) {
        setIsLoading(true);
        const newState = await component.getInitialState({
            state: getState(),
            params,
            query,
            route: { location },
        });
        // initialize search vehicle from session
        newState.search.data.vehicle = getSearchVehicleFromSession();
        dispatch(dataLoadFulfilled(newState));
    }
    // In the case where:
    // - we are replacing the URL
    // - and do not want to fetch new data
    // - but do need to change the page type
    // for checkout, this occurs when adding new URL query parameters
    if (isReplacedRoute && pathname.startsWith('/checkout/')) {
        dispatch(updateSearchRequest({ pageType: Page.CHECKOUT }));
    }
}
const getPath = ({ pathname }) => {
    const { isSearch } = matchPathToRoute(pathname) || {};
    return !includes(pathname, 'airport') &&
        !includes(pathname, 'event-packages-search') &&
        isSearch
        ? '/search'
        : pathname;
};
function Navigator({ extraProps }) {
    const dispatch = useDispatch();
    const store = useStore();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [path, setPath] = useState(getPath(location));
    const prevLocationRef = useRef(null);
    useEffect(() => {
        const prevLocation = prevLocationRef.current;
        prevLocationRef.current = location;
        if (prevLocation === null)
            return;
        if (location.pathname !== prevLocation.pathname ||
            location.search !== prevLocation.search ||
            !isEqual(location.state, prevLocation.state)) {
            reduceStateForRoute(prevLocation, location, dispatch, store.getState, setIsLoading)
                .then(() => {
                setPath(getPath(location));
                setIsNotFound(false);
                setIsLoading(false);
            })
                .catch(error => {
                ErrorUtils.sendSentryException(error);
                setIsNotFound(true);
                setIsLoading(false);
            });
        }
    }, [location, dispatch, store]);
    return (<Route location={{ pathname: path }} render={() => isNotFound ? (<NotFound />) : isLoading ? (<RouterLoader />) : (renderRoutes(routes, extraProps))}/>);
}
export default Navigator;
