import SegmentUtils from 'utils/segment';
import { createTopSearchResultsList } from 'segment/utils/top-search-results';
import SearchTracking from 'utils/search-tracking';
import { getParkingType, getCitySlug, getSearchFilters, getMapCenter, getPageType, getSearchQuery, getSearchLeadTime, getSearchLength, } from 'store/selectors/selectors';
import transformFilters from '../utils/filters';
import { getTotalPriceUserPreference, TotalPriceSessionPreference, } from 'utils/total-price-session-utils';
export default function trackSearchSortOrderChanged({ state, args: { sortBy }, }) {
    const filters = getSearchFilters(state);
    const parkingType = getParkingType(state);
    const { destination: { data: destination }, spots: { data: spots }, } = state;
    const { searchUUID, actionUUID } = SearchTracking.getValues();
    const airport = destination?.airport || null;
    const showTotal = getTotalPriceUserPreference() === TotalPriceSessionPreference.On;
    const properties = {
        city: getCitySlug(state),
        map_center: getMapCenter(state),
        page_type: getPageType(state),
        query: getSearchQuery(state),
        search_lead_time: getSearchLeadTime(state),
        search_length: getSearchLength(state),
        filters: transformFilters(filters),
        sort_by: sortBy,
        parking_type: parkingType,
        action_id: actionUUID,
        search_id: searchUUID,
        top_search_results_list: createTopSearchResultsList({
            spots,
            airport,
            sortBy,
            parkingType,
            isMonthly: parkingType === 'monthly',
            filters,
        }),
        total_price_toggle_on: showTotal,
    };
    SegmentUtils.track({
        event: 'Search Sort Order Changed',
        properties,
        additionalOptions: {
            context: {
                protocols: {
                    event_version: 7,
                },
            },
        },
    });
}
