import ErrorUtils from 'utils/error-utils';
export const setBackendExperiments = (experiments = []) => {
    try {
        window.sessionStorage.setItem('sh-backend-experiment', JSON.stringify(experiments));
    }
    catch (err) {
        ErrorUtils.sendSentryMessage({
            error: err,
            customErrorMessage: 'Error with setBackendExperiments',
        });
    }
    return;
};
export const getBackendExperiments = () => {
    try {
        const val = window.sessionStorage.getItem('sh-backend-experiment');
        if (val) {
            const asJson = JSON.parse(val);
            const experiments = {};
            asJson.forEach(experiment => {
                experiments[experiment.experiment_name] =
                    experiment.variation_name;
            });
            return experiments;
        }
    }
    catch (err) {
        ErrorUtils.sendSentryMessage({
            error: err,
            customErrorMessage: 'Error with setBackendExperiments',
        });
    }
    return undefined;
};
