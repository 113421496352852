import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetSelectedSpot } from 'store/spot/spot-actions';
import { userLogout } from 'store/user/user-actions';
import Navigation from '../../navigation';
import { Box } from '@spothero/ui';
import { headerStyles, whiteHeaderStyles } from '../headerStyles';
import { expandedData, collapsedData } from '../constants';
import useConfig from 'hooks/use-config';
import { OPTIMIZELY_VARIATION_REFRESHED, useFeatureVariation, } from 'plugins/optimizely/hooks/use-feature-variation';
import { IMMEDIATE_FEATURES } from 'utils/experiment';
import { useHeaderKey } from 'pages/search/hooks/use-header-key';
const HeaderMobile = () => {
    const key = useHeaderKey();
    const isWhiteHeader = useFeatureVariation(IMMEDIATE_FEATURES.ACQUISITION_MOBILE_WEB_SEARCH_REVAMP) === OPTIMIZELY_VARIATION_REFRESHED;
    const { isProduction } = useConfig();
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);
    const clearSelectedSpot = () => {
        dispatch(resetSelectedSpot());
    };
    const onUserLogout = () => {
        dispatch(userLogout());
    };
    return (<Box {...(isWhiteHeader ? whiteHeaderStyles : headerStyles)} as="header" role="banner" data-testid="Header" key={key}>
            <Navigation isWhiteHeader={isWhiteHeader} isProduction={isProduction} user={user} collapsedData={collapsedData} expandedData={expandedData} onItemSelected={clearSelectedSpot} onUserLogout={onUserLogout}/>
        </Box>);
};
export default HeaderMobile;
