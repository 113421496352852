import SegmentUtils from 'utils/segment';
import { createTopSearchResultsList } from '../utils/top-search-results';
import getFilteredSearchResults from '../utils/filtered-search-results';
import SearchTracking from 'utils/search-tracking';
import { getCitySlug, getMapCenter, getPageType, getParkingType, getSearchLeadTime, getSearchLength, getSearchQuery, getSortBy, } from 'store/selectors/selectors';
import transformFilters from 'segment/utils/filters';
import { getTotalPriceUserPreference, TotalPriceSessionPreference, } from 'utils/total-price-session-utils';
export default function trackProductListFiltered({ state, filters }) {
    const { destination: { data: destination }, searchRequest: { monthly: isMonthly }, spots: { data: spots }, } = state;
    const { searchUUID, actionUUID } = SearchTracking.getValues();
    const airport = destination?.airport || null;
    const parkingType = getParkingType(state);
    const sortBy = getSortBy(state);
    const showTotal = getTotalPriceUserPreference() === TotalPriceSessionPreference.On;
    const filteredSpots = getFilteredSearchResults({
        spots,
        filters,
        isMonthly,
        sortBy,
        parkingType,
    });
    const properties = {
        city: getCitySlug(state),
        map_center: getMapCenter(state),
        page_type: getPageType(state),
        query: getSearchQuery(state),
        search_lead_time: getSearchLeadTime(state),
        search_length: getSearchLength(state),
        sort_by: sortBy,
        filters: transformFilters(filters),
        parking_type: parkingType,
        products: filteredSpots.map(({ spotId, title, price }) => {
            return {
                category: parkingType,
                name: title,
                price,
                product_id: String(spotId),
                quantity: 1,
            };
        }),
        via_quick_filter: filters.viaQuickFilter,
        action_id: actionUUID,
        search_id: searchUUID,
        top_search_results_list: createTopSearchResultsList({
            spots,
            airport,
            sortBy,
            parkingType,
            isMonthly: parkingType === 'monthly',
            filters,
        }),
        total_price_toggle_on: showTotal,
    };
    SegmentUtils.track({
        event: 'Product List Filtered',
        properties,
        additionalOptions: {
            context: {
                protocols: {
                    event_version: 6,
                },
            },
        },
    });
}
