import uniqBy from 'lodash/uniqBy';
import ObjectUtils from '@spothero/utils/object';
import { initialState as userInitialState } from 'store/user/user-reducer';
import FormatUtils from 'utils/format';
import GTMUtils from 'utils/gtm';
import SegmentUtils from 'utils/segment';
import * as Sentry from '@sentry/browser';
import { USER_TYPES } from 'utils/types/user';
const { CURRENCY_TYPES, AUTH_STATE } = USER_TYPES;
// TODO MR - both User needs typing before this
export const PAYPAL_WALLET_VALUE = 'paypal';
export const creditCardTransformer = (card) => {
    const transformedCard = ObjectUtils.camelizeKeys(card);
    return {
        externalId: transformedCard.cardExternalId,
        type: transformedCard.cardType,
        last4: transformedCard.cardLast4,
        isDefault: transformedCard.isDefault,
        wallet: transformedCard.wallet,
        deleted: transformedCard.deleted,
    };
};
export const paypalCardTransformer = (paypalCard) => {
    const transformedCard = ObjectUtils.camelizeKeys(paypalCard);
    return {
        externalId: transformedCard.cardExternalId,
        paypalEmail: transformedCard.paypalEmail,
        wallet: transformedCard.wallet,
    };
};
export const isStaff = (email) => {
    return typeof email === 'string' ? email.includes('spothero.com') : false;
};
const UserUtils = {
    AUTH_STATE,
    INCLUDES: 'phone_number,admin,is_seller,credit_cards,license_plates,vehicles,date_joined,has_usable_password',
    CURRENCY_TYPES,
    identify(user = null) {
        SegmentUtils.identify(user);
        GTMUtils.identify(user);
        Sentry.configureScope(scope => {
            scope.setUser({
                email: user?.email,
                id: user?.id,
            });
        });
    },
    transformUserVehicle: vehicle => {
        const vehicleInfo = vehicle.info;
        const vehiclePlate = vehicle.licensePlate;
        const vehicleData = {
            vehicleProfileId: vehicle.id,
            isDefault: vehicle.isDefault,
            unlistedModel: vehicle.unlistedModel || !vehicle.info,
        };
        if (vehiclePlate) {
            vehicleData.licensePlateValue = vehiclePlate.value;
            vehicleData.licensePlateState = vehiclePlate.state;
        }
        if (vehicleInfo) {
            vehicleData.id = vehicleInfo.id;
            vehicleData.vehicleInfoId = vehicleInfo.id;
            vehicleData.make = vehicleInfo.make;
            vehicleData.makeLabel = vehicleInfo.displayMake;
            vehicleData.model = vehicleInfo.model;
            vehicleData.modelLabel = vehicleInfo.displayModel;
            vehicleData.oversize = vehicleInfo.oversize;
        }
        return vehicleData;
    },
    formatPayload: (user) => {
        if (!user) {
            throw new Error('No user from payload');
        }
        const { 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        spotheroCredit, admin: isAdmin, creditCards = [], creditWallets = [], licensePlates = [], vehicles = [], ...formattedPayload } = ObjectUtils.camelizeKeys(user);
        const paypalCard = creditCards.find(card => card.wallet === PAYPAL_WALLET_VALUE);
        return UserUtils.normalize({
            ...formattedPayload,
            isAdmin,
            creditCards: creditCards
                .filter(card => !card.deleted && !card.wallet)
                .map(creditCardTransformer),
            paypalCard: paypalCard
                ? paypalCardTransformer(paypalCard)
                : null,
            creditWallets: Object.keys(UserUtils.CURRENCY_TYPES).reduce((wallets, currencyType) => {
                const wallet = creditWallets.find(w => w.currencyType.toUpperCase() ===
                    currencyType) || {};
                return {
                    ...wallets,
                    [currencyType]: {
                        credit: wallet.amount || 0,
                        currencySymbol: FormatUtils.currencySymbol(currencyType),
                        currencyType: UserUtils.CURRENCY_TYPES[currencyType],
                    },
                };
            }, {}),
            licensePlates: licensePlates.map(({ licensePlateId: id, ...plateData }) => ({
                ...plateData,
                id,
            })),
            vehicles: uniqBy(vehicles, (vehicle) => {
                return vehicle.id;
            }).map(UserUtils.transformUserVehicle),
        }, {
            resetToDefaults: true,
        });
    },
    // Should return same data structure as the one passed in
    normalize(data, { resetToDefaults = false } = {}) {
        return Object.keys(data).reduce((obj, key) => ({
            ...obj,
            ...(data[key] && { [key]: data[key] }),
        }), resetToDefaults ? userInitialState().data : {});
    },
};
export default UserUtils;
