import map from 'lodash/map';
import range from 'lodash/range';
import React from 'react';
import PropTypes from 'prop-types';
import StarRatingStar from './star';

const StarRating = ({stars}) => {
    const numStars = stars ? stars : 0;

    return (
        <div className="StarRating">
            {map(range(5), item => {
                return (
                    <StarRatingStar
                        key={item}
                        hasForegroundStar={numStars > Number(item)}
                        foregroundStarHalf={
                            numStars >= Number(item) + 0.5 &&
                            numStars < Number(item) + 1
                        }
                        foregroundStarFilled={numStars >= Number(item) + 0.5}
                    />
                );
            })}
        </div>
    );
};

StarRating.propTypes = {
    stars: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

StarRating.defaultProps = {
    stars: 0,
};

export default StarRating;
