export default function getParkingType({ isMonthly, isAirport, isEvent, isEventPackage, isPowerBooking = false, }) {
    return isAirport
        ? 'airport'
        : isEvent
            ? 'event'
            : isEventPackage
                ? 'event_package'
                : isMonthly
                    ? 'monthly'
                    : isPowerBooking
                        ? 'power_booking'
                        : 'transient';
}
export const isEventOrTransientOrPowerBookingParking = (parkingType) => parkingType === 'event' ||
    parkingType === 'transient' ||
    parkingType === 'power_booking';
export const isMonthlyOrAirportParking = (parkingType) => parkingType === 'monthly' || parkingType === 'airport';
