import {Page} from 'utils/page-utils';
import {fetchCleanedData} from 'router/router-utils';

const getInitialState = async ({state: initialState, route}) => {
    // TODO: `fetchCleanedData` is a relic of the previous data-fetching system and does more than Checkout needs

    const state = await fetchCleanedData({
        state: initialState,
        route,
    });

    state.searchRequest.pageType = Page.CHECKOUT;

    return state;
};

export default getInitialState;
