import APIUtils from '@spothero/utils/api';
import ObjectUtils from '@spothero/utils/object';
import ErrorUtils from 'utils/error-utils';
export const transformOperatorInfo = (rawOperatorInfo) => {
    const camelizedRawOperatorInfo = ObjectUtils.camelizeKeys(rawOperatorInfo);
    const transformedAddOns = {
        name: camelizedRawOperatorInfo.name,
        termsAndConditionsLink: camelizedRawOperatorInfo.termsConditionsLink,
        privacyPolicyLink: camelizedRawOperatorInfo.privacyPolicyLink,
        willSendSMS: camelizedRawOperatorInfo.sendSmsMessage,
    };
    return transformedAddOns;
};
export const getOperatorInfo = async ({ facilityId }) => {
    try {
        const response = await APIUtils.get(`/facilities/${facilityId}/operator-info`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'SpotHero-Version': '2022-02-22',
            },
        });
        return { operatorInfo: transformOperatorInfo(response.data) };
    }
    catch (err) {
        return ErrorUtils.transformAndReject(err);
    }
};
