import APIUtils from '@spothero/utils/api';
import isNil from 'lodash/isNil';
/**
 * An Object containing all the relevant information about a city
 *
 * @module api/city
 * @typedef {object} cityObj
 * @property {number} id - The `id` of the city
 * @property {string} title - The `title` of the city
 * @property {string} slug - The `slug` of the city
 * @property {number} latitude - The latitude numeric value of the city
 * @property {number} longitude - The longitude numeric value of the city
 * @property {boolean} is_spothero_city - A boolean describing if the city is a SpotHero city
 * @property {string} country_code - The country code in which the city is apart of
 * @example
 * const cityObj = {
 *  id: 15,
 *  title: 'NYC',
 *  slug: 'nyc',
 *  latitude: 40.7579191296,
 *  longitude: -73.9834536596,
 *  is_spothero_city: true,
 *  country_code: 'US',
 * };
 */
const CityAPI = {
    /**
     * Returns detailed city data via the city slug
     *
     * @function get
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Cities/get_cities_slug Documentation}
     * @param {string} slug - The Slug of the city to retrieve
     * @returns {object} The City data matching the provided slug
     * @example
     * const citySlug = 'chicago';
     * CityAPI.get(citySlug);
     */
    get(slug) {
        return APIUtils.get(`cities/${slug}/`);
    },
    /**
     * Returns detailed city data via the city ID
     *
     * This is a 'placeholder' API that uses /cities to get a particular city by ID.
     * There is currently no API to retrieve a city directly via ID.
     * TODO: work with BE to get an API for this
     *
     * @function getById
     * @param {number} id - The ID of a city to retrive the data for
     * @returns {object} The City data matching the provided ID
     * @example
     * const cityId = 1;
     * CityAPI.getById(cityId);
     */
    async getById(id) {
        const cities = await CityAPI.getAll();
        const city = cities.data.data.results.find(c => `${c.id}` === `${id}`);
        if (isNil(city)) {
            // eslint-disable-next-line no-throw-literal
            throw { status: 404, message: `Unable to find city by ID ${id}` };
        }
        return city;
    },
    /**
     * Returns detailed city data of the nearest SpotHero city to a given Geolocation
     *
     * @function getNearestCities
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Cities/get_cities Documentation}
     * @param {{latitude: number, longitude: number}} location - The Lat/Lon object used to calculate the nearest SpotHero city from
     * @returns {object} The nearest city data from the provided location.
     * @example
     * const location = {
     *  latitude: 39.7178,
     *  longitude: -76.710441,
     * };
     * CityAPI.getNearestCity(location);
     */
    //TODO - update api documentation as its missing the lat/long in example
    async getNearestCities(location, limit = 1) {
        const { latitude, longitude } = location;
        const nearestCity = await APIUtils.get(`cities/?latitude=${latitude}&longitude=${longitude}&sort=distance&order=asc&limit=${limit}`);
        return nearestCity.data.data.results;
    },
    //Would this be better sitting in a UTIL?  Just wanted it close to real function
    async getNearestCitiesWithLatLon(latlon, limit) {
        const hasCloudfrontViewerGeoHeader = Boolean(latlon.latitude) &&
            Boolean(latlon.longitude);
        const defaultValues = [
            {
                id: 1,
                title: 'Chicago',
                slug: 'chicago',
                latitude: 41.8839288418,
                longitude: -87.6309569143,
                is_spothero_city: true,
                country_code: 'US',
            },
            {
                id: 716,
                title: "South Bend",
                slug: "south-bend",
                latitude: 41.6763545,
                longitude: -86.2519898,
                is_spothero_city: true,
                country_code: "US"
            },
        ];
        if (hasCloudfrontViewerGeoHeader) {
            try {
                const nearestCityList = await this.getNearestCities(latlon, limit);
                return nearestCityList;
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.error(`Error: ${error.message}, Unable to fetch nearest SpotHero City, reverting to default.`);
                // Downtown Chicago lat/lon
                return defaultValues;
            }
        }
        return defaultValues;
    },
    /**
     * Returns basic data for all cities.
     *
     * @function getAll
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Cities/get_cities Documentation}
     * @returns {{meta: object, data: {results: cityObj[]}, notifications: Array}} The response of the cities API, includes the `data` object containing the `results` array made up of {@link cityObj} objects
     * @example
     * CityAPI.getAll();
     */
    getAll() {
        return APIUtils.get(`cities/`);
    },
};
export default CityAPI;
