import {ActionType} from 'redux-promise-middleware';
import SpotUtils from 'utils/spot';
import {
    CHECKOUT_FETCH_RATES,
    CHECKOUT_SWAP_UPSELL_RATE,
} from '../checkout/checkout-actions';
import {FILTERS_RESET} from '../filters/filters-actions';
import {ROUTER_DATA_LOAD_FULFILLED} from '../router/router-actions';
import {SEARCH_SET_SORT_TYPE} from '../search/search-actions';
import {
    SEARCH_REQUEST_SET_TERM,
    SEARCH_REQUEST_SET_LOCATION,
    SEARCH_REQUEST_UPDATE_TIMES,
    SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES,
} from '../search-request/search-request-actions';
import {
    SPOT_UPDATE_SELECTED,
    SPOT_CHANGE_MULTI_RATE,
    SPOT_RESET_SELECTED_SPOT,
    SPOT_RESET_SELECTED_RATE,
    SPOT_DETAIL_REFERRER,
} from './spot-actions';

const initialState = {
    selected: null,
    previousSelected: null,
    showSpotDetailsModal: false,
    spotDetailReferrer: null,
};

export default function spot(state = initialState, {type, payload, meta}) {
    switch (type) {
        case SPOT_UPDATE_SELECTED: {
            const {spot: selectedSpot, goToSpotUrl} = payload;

            return {
                ...state,
                selected: selectedSpot ? selectedSpot : null,
                previousSelected: state.selected || selectedSpot,
                showSpotDetailsModal: selectedSpot && !goToSpotUrl,
            };
        }

        case SPOT_CHANGE_MULTI_RATE: {
            const {newRate} = payload;

            let newState = {
                ...state,
                selected: {
                    ...state.selected,
                    selectedRate: {...state.selected.selectedRate, ...newRate},
                },
            };

            if (state?.selected?.bulkPowerBookingRates?.length) {
                newState = {
                    ...newState,
                    selected: {
                        ...newState.selected,
                        selectedRate: {
                            ...newState.selected.selectedRate,
                            ...newRate,
                        },
                    },
                };
            }

            return newState;
        }

        case CHECKOUT_SWAP_UPSELL_RATE: {
            const {rate} = payload;

            return {
                ...state,
                selected: {
                    ...state.selected,
                    selectedRate: rate,
                },
            };
        }

        case `${CHECKOUT_FETCH_RATES}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                selected: SpotUtils.prepare({
                    spot: {
                        ...state.selected,
                        ...payload.data.data,
                    },
                    rid: meta.rid,
                    searchRequest: meta,
                    timezone: meta.timezone,
                }),
            };
        }

        case ROUTER_DATA_LOAD_FULFILLED: {
            const {
                searchRequest: {pageType, isSearchPage},
                spot: selectedSpot,
            } = payload;

            if (
                (pageType === 'checkout' || pageType === 'facility') &&
                selectedSpot &&
                selectedSpot.selected
            ) {
                return {
                    ...state,
                    selected: selectedSpot.selected,
                };
            } else if (isSearchPage) {
                return state;
            } else {
                return initialState;
            }
        }

        case SPOT_RESET_SELECTED_RATE: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    selectedRate: null,
                },
            };
        }

        case FILTERS_RESET:
        case SEARCH_SET_SORT_TYPE:
        case SEARCH_REQUEST_UPDATE_TIMES:
        case SEARCH_REQUEST_SET_TERM:
        case SEARCH_REQUEST_SET_LOCATION:
        case SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES:
        case SPOT_RESET_SELECTED_SPOT: {
            return initialState;
        }
        case SPOT_DETAIL_REFERRER: {
            return {
                ...state,
                spotDetailReferrer: payload.referrer,
            };
        }

        default:
            return state;
    }
}
