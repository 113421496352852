import isUndefined from 'lodash/isUndefined';
import FormatUtils from 'utils/format';
import { getPrice } from 'hooks/spot/price/use-price';
export const getMarkerLabelText = ({ unavailable, displayPrice, hasMultipleRates, }) => {
    if (unavailable) {
        return 'N/A';
    }
    if (isUndefined(displayPrice)) {
        return 'P';
    }
    // always round up pennies to next dollar
    const roundedPrice = Math.ceil(displayPrice / 100);
    const currencySymbol = FormatUtils.currencySymbol();
    // ensure price is two decimals unless pennies are equal to .00
    return `${currencySymbol}${roundedPrice
        .toFixed(2)
        .toString()
        .replace('.00', '')}${hasMultipleRates ? '+' : ''}`;
};
const isSpotFlagged = (spot) => {
    const flagged = spot?.visualFlags?.length > 0;
    if (flagged) {
        return true;
    }
    return false;
};
export const getSpotAddress = (spot) => {
    const { addresses } = spot;
    const searchAddress = addresses.find(address => address.types?.includes('search'));
    // use search address if available
    return searchAddress || addresses[0];
};
export const transformSpotToMarkerData = (spot, isPowerBooking = false, showTotal = false) => {
    const { spotId, distance, availability, selectedRate, title, recentlyViewed, visualFlags, rating, images, hasMultipleRates, supportedFeeTypes, oversizeFeesChargedOnsite, vehicle, requireCreditCard, } = spot;
    const { latitude, longitude } = getSpotAddress(spot);
    const distanceFromDestination = distance?.linearMeters || 0; // this can be calculated from lat/long but we already have it
    const { available } = availability;
    const displayPrice = getPrice({
        spot: { selectedRate },
        isPowerBooking,
        showTotal,
    })?.value;
    const markerType = !available
        ? 'disabled'
        : recentlyViewed
            ? 'recent'
            : 'medium';
    const markerLabelText = getMarkerLabelText({
        displayPrice,
        unavailable: !available,
        hasMultipleRates,
    });
    const isFlagged = isSpotFlagged(spot);
    return {
        spotId,
        latitude,
        longitude,
        distanceFromDestination,
        title,
        markerLabelText,
        markerType,
        state: 'default',
        recentlyViewed,
        isFlagged,
        durationSeconds: distance?.durationSeconds,
        // following properties are required by the FacilitySummary component
        images,
        distance,
        rating,
        hasMultipleRates,
        availability,
        selectedRate,
        visualFlags,
        supportedFeeTypes,
        oversizeFeesChargedOnsite,
        vehicle,
        requireCreditCard,
    };
};
/**
 * This adds recentlyViewed to the spot object to be used in the marker
 *
 * @param {object} param - Top level param
 * @todo Find Spot schema to add below
 * @param {Array<object>} param.spots - spots
 * @param {Array<number>} param.recentlyViewedSpots - Array of recently viewed ids
 * @returns {object} - Spot object with recentlyViewed
 */
export const foldRecentlyViewedIntoSpots = ({ spots, recentlyViewedSpots = [], }) => spots.map(({ spotId, ...spot }) => ({
    spotId,
    ...spot,
    recentlyViewed: recentlyViewedSpots?.map(el => `${el}`).indexOf(`${spotId}`) !== -1,
}));
