export const initialState = () => ({
    isPending: false,
    data: {},
    error: null,
    paid: false,
});
export default function promotion(state = initialState(), { type }) {
    switch (type) {
        default:
            return state;
    }
}
