const METERS_IN_MILES = 1609;
/**
 * Creates a default distance objects with linearMeters set to 0.
 * There is a Search bug where facilities located on the search location come back with null distance.
 *
 * @function formatDistance
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientParking|Documentation}
 * @param {Distance} distance - An object containing different measures of distance from a facility to search location.
 * @returns {Distance} The same object that was passed in, or a default distance object with linearMeters set to 0.
 */
export const formatDistance = (distance) => {
    if (!distance) {
        return {
            linearMeters: 0,
            walkingMeters: 0,
            walkingInMiles: 0,
            durationSeconds: 0,
        };
    }
    else {
        return {
            ...distance,
            walkingInMiles: distance.walkingMeters / METERS_IN_MILES || 0,
        };
    }
};
