import {createSelector} from 'reselect';
import FormatUtils from 'utils/format';
import ReservationUtils from 'utils/reservation-utils';
import UserUtils from 'utils/user-utils';

const getCreditWallets = state => state.user.data.creditWallets;
const getCurrencyType = state => state.checkout.currencyType;

/*
 * @function getCredits
 * Converts credit wallets into display dollars with conditional prefixes and separated by a pipe
 * @returns <String> credits - "US$5 | CA$25" or "$50" or "CA$12" or "US$10" or ""
 */
export const getCredits = ({creditWallets, currencyType}) =>
    Object.keys(creditWallets)
        .map(type => creditWallets[type])
        .filter(({credit}) => Boolean(credit))
        .map(({currencyType: walletType, credit, currencySymbol}, i, arr) => {
            const isCanadian = walletType === UserUtils.CURRENCY_TYPES.CAD; // always prefixed
            const prefix =
                !isCanadian &&
                [
                    walletType !== currencyType, // is alternate credit currency from selected spot
                    arr.length > 1, // has multiple credit currencies
                ].find(Boolean)
                    ? walletType.substr(0, 2)
                    : '';

            return `${prefix}${currencySymbol}${
                FormatUtils.price(credit).dollars
            }`;
        })
        .join(' | ');

export const getCreditWallet = createSelector(
    [getCreditWallets, getCurrencyType],
    (creditWallets = {}, currencyType = UserUtils.CURRENCY_TYPES.USD) => {
        return {
            currencyType,
            ...creditWallets[currencyType],
            credits: getCredits({creditWallets, currencyType}),
        };
    }
);

const getParkingPassType = state =>
    state.spot?.selected?.selectedRate?.parking_pass_type;
const getLicensePlateRequired = state => state.checkout?.licensePlateRequired;

// Soon (hopefully) - this will be replaced by a new API field, but here as a stopgap
export const getNeedsLicensePlate = createSelector(
    [getLicensePlateRequired, getParkingPassType],
    (licensePlateRequired = false, parkingPassType = '') => {
        return (
            licensePlateRequired ||
            ReservationUtils.LICENSE_PLATE_REDEMPTION_TYPES.includes(
                parkingPassType
            )
        );
    }
);
