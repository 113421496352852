// eslint-disable-next-line no-shadow
export var SearchPageView;
(function (SearchPageView) {
    SearchPageView["DESKTOP_LIST"] = "dl";
    SearchPageView["MOBILE_LIST"] = "ml";
    SearchPageView["MOBILE_MAP"] = "mm";
})(SearchPageView || (SearchPageView = {}));
export const getSearchPageViewString = (searchPageView) => {
    switch (searchPageView) {
        case SearchPageView.DESKTOP_LIST:
            return 'desktop_list';
        case SearchPageView.MOBILE_LIST:
            return 'mobile_list';
        case SearchPageView.MOBILE_MAP:
            return 'mobile_map';
        default:
            return null;
    }
};
