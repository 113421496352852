import find from 'lodash/find';

import {push} from '../router/router-actions';
import {OVERSIZE_FEE_TYPE} from 'utils/reservation-utils';

export const SPOT_UPDATE_SELECTED = 'SPOT_UPDATE_SELECTED';
export const SPOT_CHANGE_MULTI_RATE = 'SPOT_CHANGE_MULTI_RATE';
export const SPOT_RESET_SELECTED_SPOT = 'SPOT_RESET_SELECTED_SPOT';
export const SPOT_RESET_SELECTED_RATE = 'SPOT_RESET_SELECTED_RATE';
export const SPOT_DETAIL_REFERRER = 'SPOT_DETAIL_REFERRER';

export const isExpressCheckoutEligible = ({
    spot,
    searchRequest,
    isRebookingReservation,
    eventPackage,
    isAdmin,
    isUserInExpressCheckoutExperiment,
}) => {
    let isEligible = false;

    if (!spot.selectedRate) {
        return false;
    }
    try {
        const {
            monthly: isMonthly,
            airport: isAirport,
            rebook_reservation_id: rebookId,
            powerBooking: isPowerBooking,
        } = searchRequest;

        const oversizePossible =
            spot.supportedFeeTypes?.includes(OVERSIZE_FEE_TYPE) ||
            spot.oversizeFeesChargedOnsite ||
            spot.vehicle?.unknownOnsiteFee ||
            Boolean(spot.vehicle?.onsiteFee);
        const isEventPackage = Boolean(eventPackage?.id);
        const isVerticalExpressAllowed =
            !isMonthly && !isPowerBooking && !isAirport && !isEventPackage;
        const isRebooking = rebookId || isRebookingReservation;
        const freePark = spot.requireCreditCard === false;

        if (
            !freePark &&
            !spot.hasMultipleRates &&
            !oversizePossible &&
            isVerticalExpressAllowed &&
            !isRebooking &&
            !isAdmin &&
            isUserInExpressCheckoutExperiment
        ) {
            isEligible = true;
        }
    } catch (e) {
        console.error('Error in isExpressCheckoutEligible', e);
    }

    return isEligible;
};

export const routeToCheckout = ({spot, isUserInExpressCheckoutExperiment}) => {
    return (dispatch, getState) => {
        const state = getState();

        const {
            searchRequest,
            checkout: {isRebookingReservation},
            user: {data: user},
            eventPackage: {data: eventPackage},
        } = state;

        const shouldSend = isExpressCheckoutEligible({
            spot,
            searchRequest,
            isRebookingReservation,
            eventPackage,
            isAdmin: user.isAdmin,
            isUserInExpressCheckoutExperiment,
        });

        if (shouldSend && spot.selectedRate.expressCheckoutUrl) {
            window.location.href = spot.selectedRate.expressCheckoutUrl;
        } else {
            dispatch(push(spot.selectedRate.fullUrl));
        }
    };
};

export const updateSelected = ({
    spot,
    referrer,
    goToSpotUrl = false,
    isUserInExpressCheckoutExperiment = false,
} = {}) => {
    return dispatch => {
        dispatch({
            type: SPOT_UPDATE_SELECTED,
            payload: {
                spot,
                referrer,
                goToSpotUrl,
            },
        });

        if (goToSpotUrl && spot) {
            dispatch(
                routeToCheckout({spot, isUserInExpressCheckoutExperiment})
            );
        }
    };
};

// Used on search controls only
export const resetSelectedSpot = () => {
    return {
        type: SPOT_RESET_SELECTED_SPOT,
    };
};

// Used on Checkout only
// newRateRule is number or string
export const changeMultiRate = ({newRateRule, rates}) => {
    const ruleNumber = parseInt(newRateRule, 10);
    const ruleRate = Number.isNaN(ruleNumber) ? newRateRule : ruleNumber;

    return {
        type: SPOT_CHANGE_MULTI_RATE,
        payload: {
            newRate: find(rates, {fullRule: ruleRate}) || null,
            rid: ruleRate,
        },
    };
};

// Used on Checkout only
export const resetSelectedRate = () => {
    return {
        type: SPOT_RESET_SELECTED_RATE,
    };
};

export const setSpotDetailReferrer = referrer => {
    return {
        type: SPOT_DETAIL_REFERRER,
        payload: {referrer},
    };
};
