import filter from 'lodash/filter';
export const getSelectItems = (reservationTypes) => {
    const activeItems = filter(reservationTypes, ({ active, slug }) => {
        return active && slug !== 'all';
    });
    const items = activeItems.map(({ name, slug }) => {
        return {
            label: name,
            value: slug,
        };
    });
    items.unshift({
        label: reservationTypes.all.name,
        value: reservationTypes.all.slug,
    });
    return items;
};
