import { ActionType } from 'redux-promise-middleware';
import { CITY_LOAD_DATA } from './city-actions';
import { ROUTER_DATA_LOAD_FULFILLED } from '../router/router-actions';
export const initialState = () => ({
    isPending: false,
    data: {},
    error: null,
});
export default function city(state = initialState(), { type, payload }) {
    switch (type) {
        case `${CITY_LOAD_DATA}_${ActionType.Pending}`: {
            return {
                ...state,
                isPending: true,
            };
        }
        case `${CITY_LOAD_DATA}_${ActionType.Fulfilled}`: {
            // Used for narrowing
            if (payload.data) {
                return {
                    ...state,
                    isPending: false,
                    data: payload.data.data,
                };
            }
            else {
                return {
                    ...state,
                    isPending: false,
                    data: {},
                };
            }
        }
        case `${CITY_LOAD_DATA}_${ActionType.Rejected}`: {
            return {
                ...state,
                isPending: false,
                data: {},
                error: 'Could not load city data.',
            };
        }
        case ROUTER_DATA_LOAD_FULFILLED: {
            const { city: cleanedCityState } = payload;
            if (cleanedCityState) {
                return cleanedCityState;
            }
            else {
                return state;
            }
        }
        default:
            return state;
    }
}
