import React from 'react';
import PropTypes from 'prop-types';
import template from 'lodash/template';
import {ImageCarousel} from 'common/image-carousel';
import useMobileBreakPoint from 'hooks/use-mobile-breakpoint';

const DEFAULT_LOGO_HEIGHT = 165;
const DEFAULT_LOGO_WIDTH = 165;

const SpotImages = ({
    images,
    alt,
    width,
    height,
    logoWidth,
    logoHeight,
    carouselHeight,
    carouselVariant,
    urlProperty = 'url_template',
    logos = [],
    reinitializeCarousel = false,
}) => {
    const isMobile = useMobileBreakPoint();
    const defaultWidth = isMobile ? 750 : 1024;
    const defaultHeight = isMobile ? 512 : 704;
    let componentHeight = carouselHeight;

    if (!componentHeight) {
        componentHeight = isMobile ? 16 : 22;
    }

    const spotImages = images.map(image => {
        // replace the width, height and quality variables in the url template to create a valid image url
        const imgSrc = template(image[urlProperty], {
            interpolate: /{{([\s\S]+?)}}/g, // matches template vars in the format '{{ var }}' or '{{var}}'
        })({
            WIDTH: width || defaultWidth,
            HEIGHT: height || defaultHeight,
            QUALITY: 50,
        });

        return {
            src: imgSrc,
            alt: image?.alt || alt,
        };
    });

    const spotLogos = logos.map(logo => {
        const imgSrc = template(logo[urlProperty], {
            interpolate: /{{([\s\S]+?)}}/g, // matches template vars in the format '{{ var }}' or '{{var}}'
        })({
            WIDTH: logoWidth || DEFAULT_LOGO_WIDTH,
            HEIGHT: logoHeight || DEFAULT_LOGO_HEIGHT,
            QUALITY: 50,
        });

        return {
            src: imgSrc,
            alt: logo?.alt || alt,
            isLogo: true,
        };
    });

    if (images.length + logos.length <= 0) {
        return null;
    }

    return (
        // height is divided by 16 to get rem value
        // width is ommitted because the carousel width defaults to 100% and omitting the width allows the carousel to be responsive
        <ImageCarousel
            variant={carouselVariant}
            images={[...spotLogos, ...spotImages]}
            height={componentHeight}
            reinitialize={reinitializeCarousel}
        />
    );
};

SpotImages.propTypes = {
    images: PropTypes.array.isRequired,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    logoWidth: PropTypes.number,
    logoHeight: PropTypes.number,
    urlProperty: PropTypes.string,
    carouselHeight: PropTypes.number,
    carouselVariant: PropTypes.string,
    logos: PropTypes.array,
    reinitializeCarousel: PropTypes.bool,
};

export default SpotImages;
