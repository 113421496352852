import APIUtils from '@spothero/utils/api';
import ObjectUtils from '@spothero/utils/object';
import { FACILITY_TYPES } from 'utils/types/facility';
/**
 * Object containing all Credit API methods
 *
 * @property {checkPromoCode} checkPromoCode - Check Promo Code for amounts and percentages
 * @property {update} update - Update credit on account
 * @property {getUpdateReasons} getUpdateReasons - get Credit reasons
 * @module api/credit
 */
const CreditAPI = {
    /**
     * @function checkPromoCode -  Set card as default
     * @see https://spothero.com/api/v1/docs/endpoints/#!/Promo_Code/post_promocodes_check
     * @returns {Promise} - returns results of API call
     * @todo work on return
     */
    checkPromoCode({ email, price, promoCode: promo_code, ruleGroupId: rule_group_id, ruleType: rule_type, spotId: parking_spot_id, currencyType: currency_type, starts, ends, }) {
        return APIUtils.post('promocodes/check/', {
            email,
            parking_spot_id,
            price,
            promo_code,
            rule_type,
            currency_type,
            starts,
            ends,
            ...(rule_group_id !== FACILITY_TYPES.RATE_EXCEPTIONS.EVENT_RATE && {
                rule_group_id,
            }),
        })
            .then(({ data: { data } }) => ObjectUtils.camelizeKeys(data))
            .then((data) => {
            return {
                ...data,
                creditAmount: (data.creditAmount || 0) / 100,
                discountAmount: (data.discountAmount || 0) / 100,
                percentageOff: data.percentageOff || 0,
                percentageOffDiscountMax: (data.percentageOffDiscountMax || 0) / 100,
            };
        });
    },
    /**
     * @function update
     * @param {object} requestData - Top level param
     * @param {string} requestData.userId - Id of user
     * @param {string} requestData.amountChanged - amount charged
     * @param {string} requestData.reason - reason they are getting credit
     * @param {string} requestData.reservationId - Id of the reservation
     * @param {string} requestData.currencyType - type of currency being bought with
     * @see https://spothero.com/api/v1/docs/endpoints/#!/Users/patch_users_user_id_credit
     * @returns {Promise<string>} - Credit remaining on account
     */
    update(requestData) {
        const { userId, amountChanged, reason, reservationId, currencyType, } = requestData;
        return APIUtils.patch(`users/${userId}/credit/`, {
            currency_type: currencyType.toLowerCase(),
            credit_delta: parseFloat(String(amountChanged)),
            reason,
            referenced_rental_id: reservationId,
            notify_user: false,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(({ data: { data: { spothero_credit: credit }, }, }) => credit);
    },
    /**
     * @function getUpdateReasons
     * @see https://spothero.com/api/v1/docs/endpoints/#!/CreditReasons/get_credit_reason
     * @returns {Promise<object>} - response from API call
     */
    getUpdateReasons() {
        return APIUtils.get('credit-reason/');
    },
};
export default CreditAPI;
