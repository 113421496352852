export default function getFilters(filters) {
    if (!filters) {
        return [];
    }

    const {activeReservationType, activeAmenities} = filters;
    const allFilters = [
        ...activeAmenities,
        ...(activeReservationType !== 'all' ? [activeReservationType] : []),
    ];

    return allFilters.map(filter => {
        return {value: filter};
    });
}
