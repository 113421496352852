import React, { useEffect, useState } from 'react';
import VehicleAPI from 'api/vehicle';
import ErrorUtils from 'utils/error-utils';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchVehiclePopoverVisible, setSearchVehicle, } from 'store/search/search-actions';
import trackSearchSelectVehicle from 'segment/events/search-select-vehicle';
import AddVehicleModalContent from 'pages/search-transient-event-monthly/components/add-vehicle/AddVehicleModalContent';
import ChooseVehicleModalContent from 'pages/search-transient-event-monthly/components/add-vehicle/ChooseVehicleModalContent';
const AddVehicleModal = ({ isOpen, defaultVehicle, onClose, }) => {
    const dispatch = useDispatch();
    const { data: { vehicles }, } = useSelector((state) => state.user);
    const userVehicles = (vehicles || []).map(v => ({
        ...v,
        display_make: v.make,
        display_model: v.model,
        display_description: v.make && v.model ? `${v.make} ${v.model}` : '',
        licensePlate: v.licensePlateValue,
    }));
    // The user selected vehicle is store in redux search.data.vehicle slice and is passed down as defaultVehicle.
    // Use local state as a user can change mind and not commit their selection to redux store.
    const [vehicle, setVehicle] = useState(defaultVehicle || null);
    const [showAddVehicleModal, setShowAddVehicleModal] = useState(false);
    // Sync default vehicle with vehicle
    useEffect(() => {
        if (defaultVehicle?.id) {
            setVehicle(defaultVehicle);
        }
    }, [defaultVehicle]);
    const getVehicles = async (term) => {
        try {
            const response = await VehicleAPI.getVehicleInfos(term);
            const results = response.data.data.results;
            const size = 10;
            const trimmedResults = results.slice(0, size).map(result => ({
                label: result.display_description,
                value: result.id,
                vehicleData: result,
            }));
            trimmedResults.push({
                label: 'Vehicle Not Listed',
                value: 0,
                vehicleData: {
                    id: 0,
                    display_description: 'Not Listed',
                    make: '',
                    model: '',
                },
            });
            return trimmedResults;
        }
        catch (error) {
            ErrorUtils.sendSentryException(error);
            return null;
        }
    };
    const handleVehicleSelection = (newVehicle) => {
        setShowAddVehicleModal(false);
        dispatch(setSearchVehicle(newVehicle));
        if (newVehicle) {
            trackSearchSelectVehicle(newVehicle);
            dispatch(setSearchVehiclePopoverVisible(newVehicle.oversize));
        }
        onClose();
    };
    const handleReset = () => {
        handleVehicleSelection(null);
    };
    const handleChooseVehicle = (newVehicle) => {
        handleVehicleSelection(newVehicle);
    };
    const handleAddNewVehicle = () => {
        setShowAddVehicleModal(true);
    };
    const handleOnHidden = () => {
        setShowAddVehicleModal(false);
        onClose();
    };
    const showChooseVehicleModal = !showAddVehicleModal &&
        (Boolean(vehicle?.id) || userVehicles.length > 0);
    return showChooseVehicleModal ? (<ChooseVehicleModalContent isOpen={isOpen} onClose={onClose} handleOnHidden={handleOnHidden} vehicle={vehicle} userVehicles={userVehicles} onAddNewVehicle={handleAddNewVehicle} onSelectVehicle={handleChooseVehicle}/>) : (<AddVehicleModalContent isOpen={isOpen} onClose={onClose} handleOnHidden={handleOnHidden} getVehicles={getVehicles} handleVehicleChange={handleChooseVehicle} handleReset={handleReset}/>);
};
export default AddVehicleModal;
