import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@spothero/ui';
import SpotUtils from 'utils/spot';
import FormatUtils from 'utils/format';

/* eslint-disable camelcase */

const parkingPassMessages = {
    scan_in_out: 'QR Code',
    hang: 'Hang Tag',
    license_plate: 'License Plate',
    access_card: 'Access Pass',
    sticker: 'Sticker',
    attendant: 'SpotHero Parking Pass',
    operator_scan: 'SpotHero Parking Pass',
    self_park_see_attendant: 'SpotHero Parking Pass',
};
/* eslint-enable camelcase */

const ThingsYouShouldKnowMonthly = ({
    heightRestrictionDescription,
    contractRequired,
    inOut,
    parkingPassType,
    rateDescription,
    onShowRateDetailsClick,
    showNoStorageMessage,
    activationFee,
}) => {
    const onImportantDetailsClicked = () => {
        onShowRateDetailsClick('contract');
    };

    const renderParkingPass = () => {
        if (
            parkingPassType === 'ble_one_tap' ||
            parkingPassType === 'ble_or_scan_in_and_out' ||
            parkingPassType === 'ble_zero_touch'
        ) {
            return null;
        }

        return (
            <li className="ThingsYouShouldKnow-list-item">
                Pass type:{' '}
                <strong>{parkingPassMessages[parkingPassType]}</strong>
            </li>
        );
    };

    return (
        <div
            className="SpotDetails-section-monthly ThingsYouShouldKnow"
            data-testid="ThingsYouShouldKnowMonthly"
        >
            <h4 className="subtitle">Things You Should Know</h4>

            <ul className="ThingsYouShouldKnow-list">
                {parkingPassType && renderParkingPass()}
                {heightRestrictionDescription && (
                    <li className="ThingsYouShouldKnow-list-item">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: heightRestrictionDescription,
                            }}
                        />
                    </li>
                )}
                {activationFee !== 0 && (
                    <li className="ThingsYouShouldKnow-list-item">
                        {`There is an additional $${
                            FormatUtils.price(activationFee).displayPrice
                        } due on-site for your access device.`}
                    </li>
                )}
                {contractRequired && (
                    <li className="ThingsYouShouldKnow-list-item">
                        Contract Required.
                        <Button
                            lineHeight="1.4"
                            marginLeft={1}
                            variant="tertiary"
                            fontSize="sm"
                            data-section="contract"
                            onClick={onImportantDetailsClicked}
                        >
                            Important Details
                        </Button>
                        .
                    </li>
                )}
                {inOut && SpotUtils.IN_OUT_MESSAGES[inOut.allowed] && (
                    <li className="ThingsYouShouldKnow-list-item">
                        {SpotUtils.IN_OUT_MESSAGES[inOut.allowed](inOut)}
                    </li>
                )}
            </ul>

            {rateDescription && (
                <div
                    className="OtherDescription"
                    dangerouslySetInnerHTML={{__html: rateDescription}}
                />
            )}
            {showNoStorageMessage && (
                <ul className="OtherDescription">
                    <li className="ThingsYouShouldKnow-list-item">
                        No Vehicle Storage. No vehicles may be stored in this
                        parking facility for more than 5 consecutive business
                        days without exiting the facility. If you&apos;re
                        planning on leaving your vehicle for an extended period
                        of time, please contact the facility manager for
                        approval. Any vehicle stored in this facility over 5
                        days without notification is subject to towing at the
                        vehicle owner&apos;s expense.
                    </li>
                </ul>
            )}
        </div>
    );
};

ThingsYouShouldKnowMonthly.propTypes = {
    heightRestrictionDescription: PropTypes.string,
    contractRequired: PropTypes.bool,
    inOut: PropTypes.shape({
        allowed: PropTypes.string,
        fee: PropTypes.number,
        limit: PropTypes.number,
        limit_type: PropTypes.string, // eslint-disable-line camelcase
    }),
    parkingPassType: PropTypes.string,
    rateDescription: PropTypes.string,
    onShowRateDetailsClick: PropTypes.func.isRequired,
    showNoStorageMessage: PropTypes.bool,
    activationFee: PropTypes.number,
};

ThingsYouShouldKnowMonthly.defaultProps = {
    showNoStorageMessage: false,
    activationFee: 0,
};

export default ThingsYouShouldKnowMonthly;
