import ObjectUtils from '@spothero/utils/object';
import dayjs from 'utils/dayjs-timezone';
import FormatUtils from 'utils/format';
import { formatTransientRate } from '../transient/utils/formatTransientRate';
import { transformRateV2ToRateV1Transient } from '../transient/format/format-v2-to-v1';
const formatExtensionUpsellQuote = ({ extensionUpsellQuote, originalRate, facilityId, facilitySlug, timezone, }) => {
    const currencySymbol = FormatUtils.currencySymbol(extensionUpsellQuote.price_difference.currency_code);
    const { displayPrice } = FormatUtils.price(extensionUpsellQuote.price_difference.value);
    const now = dayjs.utc(dayjs()).tz(timezone);
    const newEnds = extensionUpsellQuote.quote.order[0].ends;
    let newEndsFormattedLong = dayjs
        .utc(newEnds)
        .tz(timezone)
        .format('h:mm A on MMMM D');
    const newEndsFormatted = dayjs.utc(newEnds).tz(timezone).format('h:mm A');
    if (now.isSame(newEnds, 'day')) {
        newEndsFormattedLong = newEndsFormatted;
    }
    return {
        priceDifference: extensionUpsellQuote.price_difference.value,
        hourDifference: extensionUpsellQuote.extension_hours,
        newEnds,
        newEndsFormatted,
        newEndsFormattedLong,
        displayPrice: `${currencySymbol}${displayPrice}`,
        rate: transformRateV2ToRateV1Transient({
            access_hours: originalRate.access_hours_formatted,
            // @ts-expect-error - exists till we type transformRateV2ToRateV1Transient
            rate: formatTransientRate({
                quote: ObjectUtils.camelizeKeys(extensionUpsellQuote.quote),
                spotId: facilityId,
                facilitySlug,
                timezone,
                eventId: null,
                userRequestedStarts: null,
                userRequestedEnds: null,
                rebookId: null,
                additionalRateDetails: originalRate.additionalRateDetails,
            }),
        }),
    };
};
export const formatExtensionUpsellInfo = ({ responseRates, originalRates, facilityId, facilitySlug, timezone, }) => {
    return responseRates.reduce((groupedRates, rate) => {
        const originalRate = originalRates.find(nonUpsellRate => `${rate.rate_id}` === `${nonUpsellRate.rule_group_id}`);
        if (!originalRate) {
            return groupedRates;
        }
        groupedRates[rate.rate_id] = {
            originalRate,
            upsellItems: rate.extension_quotes.map(extensionUpsellQuote => formatExtensionUpsellQuote({
                extensionUpsellQuote,
                originalRate,
                facilityId,
                facilitySlug,
                timezone,
            })),
        };
        return groupedRates;
    }, {});
};
