import { APIRegistry } from '@spothero/utils/api';
import dayjs from 'utils/dayjs-timezone';
import SearchTracking from 'utils/search-tracking';
import Config from '@/config/index';
import { FACILITY_TYPES } from 'utils/types/facility';
import { formatExtensionUpsellInfo } from './formatExtensionUpsellRates';
import ErrorUtils from 'utils/error-utils';
import { getWebExperimentCookie } from 'utils/web-experiment';
export function getExtensionUpsellRates({ facilityId, facilitySlug, starts, ends, rates, extensionHours, timezone, vehicleInfoId, }) {
    try {
        const { sessionUUID, searchUUID, actionUUID, } = SearchTracking.getValues();
        const fingerprint = getWebExperimentCookie();
        const rateIds = rates
            .map(rate => rate.rule_group_id)
            .filter(ruleGroupId => ruleGroupId !== FACILITY_TYPES.RATE_EXCEPTIONS.EVENT_RATE);
        if (rateIds.length < 1) {
            return {
                extensionUpsellRateInfo: {},
            };
        }
        const api = APIRegistry.get('craigSearch');
        return api
            .get(`search/extension_rates/${facilityId}`, {
            starts: dayjs(starts).format(Config.craigApiDateTimeFormat),
            ends: dayjs(ends).format(Config.craigApiDateTimeFormat),
            rate_ids: rateIds.join(','),
            extension_hours: extensionHours.join(','),
            session_id: sessionUUID,
            search_id: searchUUID,
            action_id: actionUUID,
            fingerprint,
            ...(vehicleInfoId && { vehicle_info_id: vehicleInfoId }),
        }, {
            headers: {
                'SpotHero-Version': '2024-04-01',
            },
        })
            .then(({ data: { results }, }) => {
            const groupedExtensionRates = formatExtensionUpsellInfo({
                responseRates: results,
                originalRates: rates,
                facilityId,
                facilitySlug,
                timezone,
            });
            return {
                extensionUpsellRateInfo: groupedExtensionRates,
            };
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Extension Upsell Fetch Failed',
        });
        return {
            extensionUpsellRateInfo: {},
        };
    }
}
