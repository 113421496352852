import StorageUtils from '@spothero/utils/storage';
const TOTAL_PRICE_SESSION_PREFERENCE = 'total_price_session_preference';
const TOTAL_PRICE_SESSION_PREFERENCE_STORAGE_TYPE = 'session';
// eslint-disable-next-line no-shadow
export var TotalPriceSessionPreference;
(function (TotalPriceSessionPreference) {
    TotalPriceSessionPreference["NotSelected"] = "not_selected";
    TotalPriceSessionPreference["On"] = "on";
    TotalPriceSessionPreference["Off"] = "off";
})(TotalPriceSessionPreference || (TotalPriceSessionPreference = {}));
export const persistTotalPriceSessionPreference = (value) => StorageUtils.set(TOTAL_PRICE_SESSION_PREFERENCE, value ? 'on' : 'off', TOTAL_PRICE_SESSION_PREFERENCE_STORAGE_TYPE);
export const getTotalPriceUserPreference = () => {
    const value = StorageUtils.get(TOTAL_PRICE_SESSION_PREFERENCE, TOTAL_PRICE_SESSION_PREFERENCE_STORAGE_TYPE);
    if (value === 'on') {
        return TotalPriceSessionPreference.On;
    }
    if (value === 'off') {
        return TotalPriceSessionPreference.Off;
    }
    return TotalPriceSessionPreference.NotSelected;
};
