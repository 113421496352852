import React from 'react';
import { Box, Flex } from '@spothero/ui';
import { Select } from '@spothero/ui-backlog';
import MobileToggle from 'common/search-filters-controls/mobile-toggle';
import useIsMobile from 'hooks/use-is-mobile';
const SpotListSorter = ({ sortBy, onChangeSort, sortSelectItems, showMobileToggle = true, }) => {
    const isMobile = useIsMobile();
    return (<Flex className="SpotListSorter" alignItems="center" justify={isMobile ? 'space-between' : 'flex-end'} sx={{
            '.Select-dropdown-icon': {
                height: 'auto',
                width: '16px',
            },
        }}>
            {isMobile && showMobileToggle ? <MobileToggle /> : <Box />}
            <Select value={sortBy} onChange={onChangeSort} items={sortSelectItems} data-testid="SpotListSorter-select"/>
        </Flex>);
};
export default SpotListSorter;
