/* eslint-disable no-useless-escape */
import { formatHoursOfOperation, } from './format-hours-of-operation';
/**
 * Formats transportation information from V2 Search
 *
 * @function formatAirportTransportation
 * @param transportation.type
 * @param transportation.hoursOfOperation
 * @see {@link https://api.spothero.com/v2/docs#operation/searchAirportParking|Documentation}
 * @param {object} transportation - An object representing transportation information.
 * @returns {object} The same object that was passed in, with the appropriate transform.
 */
export const formatAirportTransportation = (transportation) => ({
    ...transportation,
    type: transportation.type.replace(/\_/g, '-'),
    hoursOfOperation: formatHoursOfOperation(transportation.hoursOfOperation),
});
