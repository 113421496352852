import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import dayjs from 'utils/dayjs-timezone';
import {getWindow} from 'ssr-window';
import UrlUtils from '@spothero/utils/url';
import Config from '@/config/index';
import TimeUtils from 'utils/time';

// TODO MR - Need Rate and spot typed first

const window = getWindow();

/**
 * Checkout utilities
 *
 * @module utils/checkout-utils
 */
const CheckoutUtils = {
    PAYMENT_REQUEST_TYPES: {
        APPLE_PAY: 'apple_pay',
        GOOGLE_PAY: 'android_pay',
        PAYPAL: 'paypal',
    },

    CHECKOUT_SECTIONS: Object.freeze({
        ACCOUNT_INFO: 'Account Info',
        RESERVATION_DETAILS: 'Reservation Details',
        PAYMENT_DETAILS: 'Payment Details',
        RESERVATION_SUMMARY: 'Reservation Summary',
    }),

    /**
     * Select which rate should be used based on rule id and if its isMonthly
     *
     * @function selectRate
     * @param {object} param - Top level param
     * @param {Spot} param.spot - spot to get rate from
     * @param {boolean} param.isMonthly - see name
     * @param {string} param.rid - rate id
     * @returns {Rate} - Rate that was selected
     */
    selectRate({spot, isMonthly, rid}) {
        let selectedRate = isMonthly
            ? spot.monthly_rates[0]
            : spot.hourly_rates[0];

        if (rid) {
            if (isMonthly) {
                selectedRate = filter(spot.monthly_rates, rate => {
                    return rate.rule_id === parseInt(rid, 10);
                });
            } else {
                selectedRate = filter(spot.hourly_rates, rate => {
                    return rate.rule_group_id === parseInt(rid, 10);
                });
            }

            selectedRate = selectedRate.length ? selectedRate[0] : null;
        }

        return selectedRate;
    },

    /**
     * Get the Monthly start date based on rates passed in or returns the reservation start if nothing else passed in
     *
     * @function getMonthlyStart
     * @param {object} reservationState - see name
     * @param {boolean} reservationState.monthly - Is reservation for monthly
     * @param {string|null} reservationState.starts - isostring for when the reservation starts
     * @param {object|null} newSelectedRate - rate to get start from
     * @param {("none"|"1st_only"|"15"|"1st_or_15th")} newSelectedRate.start_date_restrictions - rate to get start from
     * @param {string} newSelectedRate.starts - isostring for when the rate starts
     * @returns {string} - isostring of the monthly start
     */
    getMonthlyStart(reservationState, newSelectedRate) {
        const {monthly: isMonthly, starts} = reservationState;
        let newMonthlyStarts = starts;

        // if monthly and start date restrictions present
        // default starts to the 1st of the month (when not already)
        if (
            isMonthly &&
            newSelectedRate &&
            newSelectedRate.start_date_restrictions !== 'none'
        ) {
            const monthlyStartsDay = starts.split('-')[2];
            let updateMonthlyStartDate = false;

            if (newSelectedRate.start_date_restrictions === '1st_only') {
                if (monthlyStartsDay !== '01') {
                    updateMonthlyStartDate = true;
                }
            } else {
                if (monthlyStartsDay !== '01' && monthlyStartsDay !== '15') {
                    updateMonthlyStartDate = true;
                }
            }

            if (updateMonthlyStartDate) {
                const selectedRateStartObj = dayjs(newSelectedRate.starts);

                if (
                    newSelectedRate.start_date_restrictions === '1st_or_15th' &&
                    selectedRateStartObj.date() > 14
                ) {
                    newMonthlyStarts = selectedRateStartObj
                        .date(15)
                        .format(Config.apiDateTimeFormat);
                } else {
                    newMonthlyStarts = selectedRateStartObj
                        .date(1)
                        .format(Config.apiDateTimeFormat);
                }
            }
        }

        return newMonthlyStarts;
    },

    /**
     * Creates a formatted object of all reservation info
     * This also enables us to know if the user had their start or end extended by the rate
     *
     * @function getTransientReservationInfo
     * @param {object} param - top level param
     * @param {string} param.reservationStarts - When reservation starts
     * @param {string} param.reservationEnds - When reservation ends
     * @param {string} param.rateStarts - When the rate start
     * @param {string} param.rateEnds - When the rate ends
     * @param {string} param.format - Format to return (default to "ddd MMM D [at] h:mma")
     * @returns {ReservationInfo} - see type
     */
    getTransientReservationInfo({
        reservationStarts,
        reservationEnds,
        rateStarts,
        rateEnds,
        format = 'ddd, MMM D, h:mm A',
        facilityTimezone,
    }) {
        const TIME_FORMAT = 'h:mm A';
        const startDateTimeObj = dayjs.tz(rateStarts, facilityTimezone);
        const endDateTimeObj = dayjs.tz(rateEnds, facilityTimezone);
        const nowObj = dayjs().tz(facilityTimezone);

        const isStartToday = nowObj.isSame(startDateTimeObj, 'day');
        const isEndToday = nowObj.isSame(endDateTimeObj, 'day');
        const isStartTomorrow = startDateTimeObj.isSame(
            nowObj.add(1, 'day'),
            'day'
        );
        const isEndTomorrow = endDateTimeObj.isSame(
            nowObj.add(1, 'day'),
            'day'
        );

        let startDateTimeFormatted = startDateTimeObj.format(format);
        let endDateTimeFormatted = endDateTimeObj.format(format);

        if (isStartToday) {
            startDateTimeFormatted = `Today, ${startDateTimeObj.format(
                TIME_FORMAT
            )}`;

            if (isEndToday) {
                endDateTimeFormatted = endDateTimeObj.format(TIME_FORMAT);
            } else if (isEndTomorrow) {
                endDateTimeFormatted = `Tomorrow, ${endDateTimeObj.format(
                    TIME_FORMAT
                )}`;
            }
        } else if (isStartTomorrow) {
            startDateTimeFormatted = `Tomorrow, ${startDateTimeObj.format(
                TIME_FORMAT
            )}`;

            if (isEndTomorrow) {
                endDateTimeFormatted = endDateTimeObj.format(TIME_FORMAT);
            }
        }

        return {
            startDateTimeFormatted,
            endDateTimeFormatted,
            duration: TimeUtils.prettyDuration(
                endDateTimeObj.diff(startDateTimeObj)
            ),
            timeExtended:
                startDateTimeObj < dayjs(reservationStarts) ||
                endDateTimeObj > dayjs(reservationEnds),
        };
    },

    /**
     * Creates a formatted object of all monthly reservation info
     *
     * @function getMonthlyReservationInfo
     * @param {Rate} selectedRate - Rate object
     * @param {string} starts - When reservation starts isostring
     * @returns {MonthlyReservationInfo} - see type
     */
    getMonthlyReservationInfo(selectedRate, starts) {
        const dateTimeShortFormat = 'MMM D';
        const startDateTimeObj = dayjs(starts || selectedRate.starts);
        const {reservation_dates: reservationDates} = selectedRate;
        const invoiceStartDateTimeObj = dayjs(reservationDates[0].starts);
        const invoiceEndDateTimeObj = dayjs(reservationDates[0].ends);
        const invoiceNextStartDateTimeObj =
            reservationDates.length > 1
                ? dayjs(reservationDates[1].starts)
                : null;
        const invoiceNextEndDateTimeObj = invoiceNextStartDateTimeObj
            ? dayjs(reservationDates[1].ends)
            : null;

        return {
            startDateFormatted: startDateTimeObj.format(dateTimeShortFormat),
            endDateFormatted: startDateTimeObj
                .clone()
                .add(1, 'months')
                .subtract(1, 'days')
                .format(dateTimeShortFormat),
            invoiceStartDate: invoiceStartDateTimeObj.format(
                Config.apiDateTimeFormat
            ),
            invoiceStartDateFormatted: invoiceStartDateTimeObj.format(
                dateTimeShortFormat
            ),
            invoiceEndDateFormatted: invoiceEndDateTimeObj.format(
                dateTimeShortFormat
            ),
            invoiceNextStartDate: invoiceNextStartDateTimeObj
                ? invoiceNextStartDateTimeObj.format(Config.apiDateTimeFormat)
                : null,
            invoiceNextStartDateFormatted: invoiceNextStartDateTimeObj
                ? invoiceNextStartDateTimeObj.format(dateTimeShortFormat)
                : null,
            invoiceNextEndDateFormatted: invoiceNextEndDateTimeObj
                ? invoiceNextEndDateTimeObj.format(dateTimeShortFormat)
                : null,
        };
    },

    /**
     * Build URL from given params
     *
     * @function buildURLFromParams
     * @param {string} url - url to build up
     * @param {object} params - Object of query parameters
     * @returns {string} - facility url with params
     */
    buildURLFromParams(url, params) {
        const urlSplit = url.split('/');
        let facilityUrl = url;

        if (includes(urlSplit[0], 'http')) {
            facilityUrl = `/${urlSplit.slice(3).join('/')}`;
        }

        return `${facilityUrl}?${UrlUtils.createQueryString(params)}`;
    },

    /**
     * update route with relevant information
     *
     * @function updateRoute
     * @param {object} param - Top level param
     * @param {string} param.currentUrl - See name
     * @param {SearchParams} param.searchParams - Params from search
     * @param {object} param.adParams - Additional params to add
     * @returns {string} - Cleaned up url with params and hash
     */
    updateRoute({currentUrl, searchParams, adParams}) {
        const cleanedUrl = currentUrl.split(/[?#]/)[0];
        const hash = window.location.hash ? window.location.hash : '';
        const {
            monthly,
            airport,
            starts,
            ends,
            eid,
            rid,
            rebook_reservation_id: rebookReservationId,
            isEventPackage,
            powerBooking: isPowerBooking,
            powerBookingPeriods,
            powerBookingSource,
        } = searchParams;
        const urlParams = {
            ...adParams,
        };

        if (!isEventPackage) {
            urlParams.starts = starts;

            if (monthly) {
                urlParams.monthly = monthly;
            } else {
                urlParams.ends = ends;
            }
        }

        if (airport) {
            urlParams.airport = airport;
        }

        if (rid) {
            urlParams.rid = rid;
        }

        if (eid) {
            urlParams.eid = eid;
        }

        if (isPowerBooking) {
            const powerBookingStarts = [];
            const powerBookingEnds = [];
            powerBookingPeriods?.forEach(
                ({starts: periodStarts, ends: periodEnds}) => {
                    powerBookingStarts.push(periodStarts);
                    powerBookingEnds.push(periodEnds);
                }
            );
            urlParams.starts = powerBookingStarts;
            urlParams.ends = powerBookingEnds;
            urlParams.power_booking = true;
            urlParams.pb_source = powerBookingSource;
        }

        if (rebookReservationId) {
            urlParams.rebook_reservation_id = rebookReservationId; // eslint-disable-line camelcase
        }

        return `${cleanedUrl}?${UrlUtils.createQueryString(urlParams).replace(
            /%3A%3A/g,
            '::'
        )}${hash}`;
    },

    /**
     * Get amount to charge user
     *
     * @function getAmountToCharge
     * @param {object} param - Top level param
     * @param {number} param.price - Price of reservation
     * @param {number} param.discount - Discount on reservation
     * @param {number} param.userCredit - User Credit available
     * @param {Array<{price: number}>} param.selectedAddons - Addons the user has currently selected
     * @returns {ChargeAmount} Object with charge information
     */
    getAmountToCharge({price, discount, userCredit, selectedAddons = []}) {
        let amountToCharge = 0;
        let creditUsed = 0;

        if (price) {
            amountToCharge = discount ? price - discount : price;
        }

        if (selectedAddons && selectedAddons.length) {
            selectedAddons.forEach(addon => {
                amountToCharge += addon.price;
            });
        }

        if (userCredit && userCredit > 0) {
            creditUsed =
                userCredit < amountToCharge ? userCredit : amountToCharge;
            amountToCharge -= creditUsed;
        }

        return {
            amountToCharge: amountToCharge > 0 ? amountToCharge : 0,
            creditUsed,
        };
    },

    getAmountToChargeFromSelectedRate({
        selectedRate,
        isPowerBooking,
        isEventPackage,
        isMonthly,
        promoCode,
        credit,
        selectedAddons,
    }) {
        const fallbackPrice = selectedRate ? selectedRate.price : 0;
        let price = fallbackPrice;

        if (!isMonthly) {
            if (isPowerBooking) {
                price = selectedRate?.totalFee;
            } else {
                price = get(
                    selectedRate,
                    'price_breakdown.total_price',
                    fallbackPrice
                );
            }
        }

        return {
            originalPrice: price,
            ...CheckoutUtils.getAmountToCharge({
                price,
                discount:
                    promoCode?.discountAmount && !isPowerBooking
                        ? Math.round(promoCode.discountAmount * 100)
                        : 0,
                userCredit: isEventPackage ? 0 : credit,
                selectedAddons,
            }),
        };
    },

    /**
     * Returns what the oversize fee is only
     *
     * @function getOversizeFeeFromPriceBreakdown
     * @param {object} priceBreakdown - Breakdown object
     * @param {Array<PriceBreakdownItem>} priceBreakdown.items - Array of price breakdowns
     * @returns {PriceBreakdownItem} - Oversize fee information
     */
    getOversizeFeeFromPriceBreakdown(priceBreakdown) {
        // using this in lieu of a default param since it won't catch null
        // priceBreakdown is frequently null
        const workingPriceBreakdown = priceBreakdown || {};

        return find(workingPriceBreakdown.items, {
            type: 'oversize_vehicle_fee_flat',
        });
    },

    /**
     * Finds stored user vehicle with matching vehicleInfoId and then transforms shape of user vehicle into search vehicle
     *
     * @function transformVehicleForSearch
     * @param {array} userVehicle - array of a user's stored vehicles
     * @param {number} vehicleInfoId - vehicleInfoId of vehicle to be matched against user's stored vehicles
     */
    transformVehicleForSearch(userVehicles, vehicleInfoId) {
        const selectedUserVehicle = userVehicles?.find(
            userVehicle => userVehicle.vehicleInfoId === vehicleInfoId
        );

        if (!selectedUserVehicle) {
            return null;
        }

        return {
            display_description: `${selectedUserVehicle.makeLabel} ${selectedUserVehicle.modelLabel}`,
            display_make: selectedUserVehicle.makeLabel,
            display_model: selectedUserVehicle.modelLabel,
            height: null,
            id: selectedUserVehicle.vehicleInfoId,
            label: `${selectedUserVehicle.makeLabel} ${selectedUserVehicle.modelLabel}`,
            length: null,
            make: selectedUserVehicle.make,
            model: selectedUserVehicle.model,
            oversize: selectedUserVehicle.oversize,
            width: null,
            year: null,
        };
    },
};

export default CheckoutUtils;

/**
 * @todo MR 1/3/2023 - Evaluate schema of object - feels bloated
 * @typedef Spot - Single spot object
 * @type {object}
 * @property {number} parking_spot_id - Id of parking spot
 * @property {string} title - Title of spot
 * @property {number} distance - Distance to spot based on lat lon given
 * @property {string} google_places_reference - see name
 * @property {number} latitude - Latitude of spot
 * @property {number} longitude - Longitude of spot
 * @property {number} lowest_price - Lowest price spot can be
 * @property {number} highest_price - Highest Price spot can be
 * @property {number} lowest_monthly_price - Lowest Monthly price spot can be
 * @property {number} highest_monthly_price - Highest monthly price spot can be
 * @property {string} default_image_url - Default image to display
 * @property {Array<Rate>} hourly_rates - Hourly Rate information
 * @property {Array<Rate>} monthly_rates - Monthly Rate information
 * @property {Array<Rate>} bulk_event_rates - Bulk Event Rate information
 * @property {boolean} license_plate_required - See name
 * @property {boolean} phone_number_required - See name
 * @property {string} timezone - Timezone of spot
 * @property {string} spot_url - Url of spot to checkout with (Need confirmation)
 * @property {number} wd_latitude - Latitude of walking distance
 * @property {number} wd_longitude - Longitude of walking distance
 * @property {boolean} commuter_card_eligible - See name
 * @property {boolean} publish_to_mobile - See name
 * @property {CurrencyType} currency_type - type of currency
 * @property {string} currency_symbol - Currency Symbol
 * @property {RatingInfo} rating_info - Info on spot rating
 * @property {Array<Note>} operator_notes - Notes shown from operator to admin
 * @property {Array<Note>} spot_notes - Notes from admin to admin
 * @property {number} available_transient_inventory - see name
 * @property {Facility} facility - Facility information
 * @property {number} spotId - id of spot
 * @property {EventPackage} event_package - Event Package Object
 * @property {boolean} isFavorite - Is user favorite
 * @property {boolean} hasTravelDistanceUpdated - See name
 * @property {boolean} hidden - Hidden from view
 * @property {Rate} selectedRate - Selected Rate object
 * @property {string} url - Url of spot
 */

/**
 * @todo MR 1/3/2023 - CurrencyType and CurrencySymbol should be broadened as used in reservation api as well
 * @typedef Rate - Rate Object
 * @type {object}
 * @property {PriceBreakdown} price_breakdown - Breakdown of price
 * @property {string} ends - When rate ends as isostring
 * @property {number} display_price - price to display in pennies
 * @property {number} price - price in pennies
 * @property {string} unavailable_reason - reason rate isn't available at this time
 * @property {CurrencyType} currency_type - see name
 * @property {CurrencySymbol} currency_symbol - symbol of currency used
 * @property {RuleType} rule_type - see name
 * @property {(string|null)} online_commuter_rate_enter_start - see name
 * @property {boolean} unavailable - see name
 * @property {string} title - Title of rate
 * @property {(number|null)} event_id - id of event
 * @property {string} starts - When rate starts as isostring
 * @property {number} rule_group_id - see name
 * @property {(string|null)} online_commuter_rate_description - see name
 * @property {boolean} online_commuter_rate - see name
 * @property {string} url - Url of rate (checkout with query params)
 * @property {Array<Amenity>} amenities - amenities of rate
 * @property {string} rule_trail - rule trail, will be number and if multiple will be 412.1241..
 * @property {ParkingType} parking_type -
 * @property {(string|null)} online_commuter_rate_enter_end - see name
 * @property {number} rule_id - see name
 * @property {("license_plate"|"scan_in_out"|"self_park_see_attendant"|"pull_ticket_scan_out"|"hang"|"operator_scan"|"scan_in_ticket_out"|null)} parking_pass_type - see name
 */

/**
 * @typedef PriceBreakdown - Price Breakdown Object
 * @type {object}
 * @property {Array<PriceBreakdownItem>} item - items of the price breakdown
 * @property {number} total_price - total price in pennies
 * @property {CurrencyType} currency - Currency type
 */

/**
 * @todo MR 1/3/2023 - list types
 * @typedef PriceBreakdownItem - Price Breakdown Item Object
 * @type {object}
 * @property {string} short_description - Short description to be displayed
 * @property {string} full_description - Full description to be displayed if read more clicked or user scrolled
 * @property {number} price - price of item in pennies
 * @property {string} type - type of item
 */

/**
 * @typedef Amenity - Amentity of spot/rate
 * @type {object}
 * @property {string} name - see name
 * @property {string} slug - see name
 * @property {boolean} visible - is visible
 * @property {string} icon_url - url of icon
 * @property {number} sort_order - type of item
 */

/**
 * @typedef Note - Note for Admin
 * @type {object}
 * @property {string} type - see name
 * @property {Array<NoteItem>} notes - see name
 */

/**
 * @typedef NoteItem - Note Item for Admin
 * @type {object}
 * @property {string} label - Label to display
 * @property {string} message - Message to display
 */

/**
 * @todo MR 1/3/2023 - This probably needs to be hoisted
 * @typedef Facility - Facility Object
 * @type {object}
 * @property {number} id - Facility id
 * @property {number} parking_spot_id - Spot id
 * @property {string} title - Title of facility
 * @property {string} description - Rich text description (basic string though with html in the string)
 * @property {string} status - Status of facility
 * @property {string} slug - slug for url
 * @property {string} street_address - see name
 * @property {string} city - see name
 * @property {string} state - see name
 * @property {string} zipcode - see name
 * @property {string} latitude - see name
 * @property {string} longitude - see name
 * @property {number} company_id - id of company that owns facility
 * @property {string} timezone - timezone facility is in
 * @property {boolean} display_price_on_receipt - see name
 * @property {string} height_restriction - Height restriction in inches
 * @property {string} height_restriction_description - see name
 * @property {("qrcode"|"code128")} barcode_type - see name
 * @property {string} post_purchase_instructions - see name
 * @property {Array<string>} restrictions - restrictions to be displayed
 * @property {HoursOfOperation} hours_of_operation - Hours of Operation for facility
 * @property {string} getting_here - Instructions to get to location
 * @property {Array<Address>} addresses - Addresses of facility
 * @property {Array<RedemptionInstruction>} redemption_instructions - see name
 * @property {boolean} mobile_enabled - see name
 * @property {string} support_description - Rich text description (basic string though with html in the string)
 * @property {FacilityType} facility_type - Type of facility
 * @property {string} cancellation_allowed - Who can cancel this
 * @property {number} cancellation_minutes - NOTE: only seen as 0 in all mocks
 * @property {Array<Amenity>} amenities_full - All amenities for facility
 * @property {Array<Image>} images - see name
 * @property {string} facility_url - url for facility
 * @property {RatingInfo} rating_info - Facility rating object
 * @property {boolean} oversize_fees_charged_onsite - see name
 * @property {boolean} transient_available - see name
 * @property {boolean} monthly_available - see name
 * @property {Array<any>} supported_fee_types - see name
 * @property {number} operator_id - see name
 */

/**
 * Type of currency
 *
 * @typedef {("usd"|"cad")} CurrencyType
 */

/**
 * Symbol of currency
 *
 * @typedef {("$"|"CA$")} CurrencySymbol
 */

/**
 * Rule Type
 *
 * @typedef {("monthly"|"multirate")} RuleType
 */

/**
 * Parking Type
 *
 * @typedef {("self"|"valet")} ParkingType
 */

/**
 * Hours of Operation
 *
 * @todo MR 1/3/2023 - Make this more detailed
 * @typedef {object} HoursOfOperation
 * @property {Array<string>} text - text of operations
 * @property {Array<any>} periods - periods of operations
 */

/**
 * Single address of facility
 *
 * @typedef {object} Address
 * @property {number} id - id of address
 * @property {string} latitude - see name
 * @property {string} longitude - see name
 * @property {string} street_address - see name
 * @property {string} city - see name
 * @property {string} state - see name
 * @property {string} zipcode - see name
 * @property {Array<string>} types - types listed for address
 */

/**
 * Redemption Instruction for facility
 *
 * @typedef {object} RedemptionInstruction
 * @property {string} illustration_id - Illustration id used for redemption
 * @property {string} illustration_version - Illustration version used for redemption
 * @property {string} text - see name
 * @property {number} id - see name
 * @property {number} position - see name
 */

/**
 * Type information for facility
 *
 * @typedef {object} FacilityType
 * @property {string} slug - Slug for type
 * @property {string} display_name - Type to display
 */

/**
 * Type information for facility
 *
 * @typedef {object} Image
 * @property {string} id - Id of illustration(image)
 * @property {string} version - Version of illustration(image)
 * @property {number} center_x - center pixel x axis
 * @property {number} center_y - center pixel y axis
 * @property {number} order - order to show image
 * @property {string} url_template - Template for cloudinary image
 */

/**
 * @typedef {object} RatingInfo
 * @property {number} number_of_ratings - number of ratings
 * @property {number|null} star_rating - star rating float or null if not filled
 */

/**
 * @typedef {object} EventPackage
 * @property {number} id - id of event package
 * @property {string} title - see name
 * @property {string} description - see name
 * @property {number} event_count - number of events as part of the package
 * @property {Array<Event>} events - Events in package
 */

/**
 * @typedef {object} Event
 * @property {number} id - id of event
 * @property {string} title - see name
 * @property {string} starts - Event start as an isostring
 * @property {string} ends - Event end as an isostring
 * @property {object} parking_window - starts and end parking window
 * @property {object} parking_window.starts - Parking window start as an isostring
 * @property {object} parking_window.ends - Parking window ends as an isostring
 */

/**
 * @typedef {object} ReservationInfo
 * @property {string} startDateTimeFormatted - Formatted start date and time
 * @property {string} endDateTimeFormatted - Formatted end date and time
 * @property {string} duration - Formatted pretty duration (ie '4 Days, 4 minutes')
 * @property {boolean} timeExtended - Was the time extended
 */

/**
 * Monthly reservation info
 * Formats are either Short (MMM D) or Config (set in app config)
 * Format shown at end of description
 *
 * @typedef {object} MonthlyReservationInfo
 * @property {string} startDateFormatted - Start date - Short
 * @property {string} endDateFormatted - End date - Short
 * @property {string} invoiceStartDate - Start date - Config
 * @property {string} invoiceStartDateFormatted - Invoice Start date - Short
 * @property {string} invoiceEndDateFormatted - Invoice End date - Short
 * @property {string} invoiceNextStartDate - Next start date - Config
 * @property {string} invoiceNextStartDateFormatted - Next start date - Short
 * @property {string} invoiceNextEndDateFormatted - Next end date - Short
 */

/**
 * @typedef {object} SearchParams
 * @property {boolean} monthly - isMonthly
 * @property {boolean} airport - isAirport
 * @property {string} starts - isostring of start time
 * @property {string} ends - isostring of ends time
 * @property {string} eid - event id
 * @property {string} rid - rate id
 * @property {string} rebook_reservation_id - id for rebook
 * @property {boolean} isEventPackage - see name
 */

/**
 * @typedef {object} ChargeAmount
 * @property {number} amountToCharge - See name
 * @property {number} creditUsed - See name
 */
