import React from 'react';
import { Button, Flex, Modal, ModalHeader, ModalBody, ModalFooter, } from '@spothero/ui';
import FilterItem from '../filter-item/FilterItem';
import RatingFilters from './RatingFilters';
const FiltersModal = ({ numSpots, amenities, activeAmenities, numActiveFilters, onChange, onClear, onHidden, isOpen, allRatings, activeRatingTypes = [], }) => {
    return (<Modal hasHeader hasFooter isOpen={isOpen} onClose={onHidden} data-testid="FiltersModal" scrollBehavior="inside" contentStyling={{
            maxWidth: { base: '100%', mobileXL: '30%' },
            minWidth: { base: '100%', mobileXL: '332px' },
        }}>
            <ModalHeader fontSize="md">Filters</ModalHeader>
            <ModalBody>
                <Flex flexGrow={1} flexDirection="column" gap={2}>
                    {Object.values(amenities).map(({ slug, name, count }) => {
            // if no filters are active and this amenity doesn't apply to the current search, hide it
            // so that it does not show up as a disabled amenity when it really has no business being there
            if (numActiveFilters === 0 && count === 0) {
                return null;
            }
            return (<FilterItem id={slug} key={slug} slug={slug} label={name} defaultChecked={activeAmenities.includes(slug)} disabled={count === 0} count={count} showIcon showCount onChange={onChange}/>);
        })}
                    <RatingFilters allRatings={allRatings} activeRatingTypes={activeRatingTypes} onChange={onChange}/>
                </Flex>
            </ModalBody>
            <ModalFooter>
                <Flex gap={4}>
                    <Button variant="primary" fontSize="sm" data-testid="FiltersModal-button" isDisabled={numSpots === 0} onClick={onHidden}>
                        Show {numSpots} Results
                    </Button>
                    {(activeAmenities.length > 0 ||
            activeRatingTypes.length > 0) && (<Button variant="secondary" fontSize="sm" onClick={onClear} data-testid="FiltersModal-clear-button">
                            Clear All
                        </Button>)}
                </Flex>
            </ModalFooter>
        </Modal>);
};
export default FiltersModal;
