import React, { useState } from 'react';
import { Button, Flex, Icon } from '@spothero/ui';
import IconChevronDown from '@spothero/icons/chevron-down';
import XCircleFilled from '@spothero/icons/x-circle-filled';
import { useDispatch, useSelector } from 'react-redux';
import AddVehicleModal from 'pages/search-transient-event-monthly/components/add-vehicle';
import { getParkingType, getSearchVehicle } from 'store/selectors/selectors';
import { FilterCheckboxBase } from '../../../filter-bases';
import { isOversizedFeatureEnabled } from 'utils/search-vehicle';
import { setSearchVehicle } from 'store/search/search-actions';
const VehicleWrapper = ({ children }) => (<Flex justifyContent="flex-start" alignItems="center" gap="1">
        {children}
    </Flex>);
const VehicleName = ({ title, onClick, }) => (<VehicleWrapper>
        {title}
        <Button onClick={onClick} data-testid="filter-vehicle-clear" variant="tertiary">
            <Icon as={XCircleFilled} fill="black" height="4" width="4"/>
        </Button>
    </VehicleWrapper>);
const VehicleSelect = () => (<VehicleWrapper>
        Vehicle Type
        <Icon as={IconChevronDown} fill="black" height="4" width="4"/>
    </VehicleWrapper>);
export const FilterVehicle = () => {
    const [isOpen, setIsOpen] = useState(false);
    const vehicle = useSelector((state) => getSearchVehicle(state));
    const storeState = useSelector((state) => state);
    const dispatch = useDispatch();
    const vehicleTitle = vehicle?.display_description || 'Not Listed';
    const parkingType = getParkingType(storeState);
    const isOversizedEnabled = isOversizedFeatureEnabled(parkingType);
    const clearVehicle = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(setSearchVehicle(null));
    };
    return (isOversizedEnabled && (<FilterCheckboxBase testid="VehicleExtraFilter-add-vehicle-button" isActive={Boolean(vehicle)} onClick={() => setIsOpen(true)}>
                {vehicle ? (<VehicleName title={vehicleTitle} onClick={clearVehicle}/>) : (<VehicleSelect />)}
                <AddVehicleModal isOpen={isOpen} onClose={() => setIsOpen(false)} defaultVehicle={vehicle}/>
            </FilterCheckboxBase>));
};
