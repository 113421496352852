import SpotUtils from 'utils/spot';
import { isEventOrTransientOrPowerBookingParking, } from 'segment/utils/parking-type';
import { VISUAL_FLAG_TYPES } from 'utils/visual-flags';
export default function getFilteredSearchResults({ filters, spots, isMonthly, airport, sortBy, parkingType, }) {
    let filteredSpots;
    const allowVisualFlags = isEventOrTransientOrPowerBookingParking(parkingType);
    /* V2-CLEANUP
    Remove the else branch when Search V2 Integration is complete
    Remove the isV2 flag from spot data when Search V2 Integration is complete
    This is used on Search for Transient, Monthly, Airport, and Event verticals */
    // there is no easy way to pass a V2 flag down, so get it from the spots
    if (spots[0]?.isV2) {
        const visibleSpots = filters
            ? SpotUtils.sortByAttributeV2(SpotUtils.filterV2(spots, filters, isMonthly), sortBy)
            : SpotUtils.sortByAttributeV2(spots, sortBy);
        filteredSpots = visibleSpots
            .map(({ tag, ...el }) => {
            const visualFlags = allowVisualFlags ? el.visualFlags : [];
            return {
                ...el,
                tag: isMonthly || airport ? tag : visualFlags[0]?.title,
            };
        })
            .map(({ availability, distance, facilityType, rating, selectedRate, spotId, tag, title, visualFlags, }) => ({
            availability,
            distance,
            facilityType,
            isOfficialParking: allowVisualFlags &&
                Boolean(visualFlags?.find(visualFlag => visualFlag.type ===
                    VISUAL_FLAG_TYPES.OFFICIAL_PARKING)),
            visualFlags: allowVisualFlags ? visualFlags : [],
            price: selectedRate?.totalPrice?.value,
            ...(rating.count && { rating }),
            selectedRate,
            spotId,
            title,
            ...(!isMonthly && { tag }),
        }));
    }
    else {
        const visibleSpots = filters
            ? SpotUtils.sortByAttribute(SpotUtils.filter(spots, filters, isMonthly), sortBy)
            : spots;
        filteredSpots = visibleSpots.map(({ spotId, title, selectedRate, facility: { tag } }) => ({
            spotId,
            title,
            price: selectedRate?.price,
            ...(airport && { tag }),
        }));
    }
    return filteredSpots;
}
