//@ts-migration MR: blocked till search-v2 typing completed

import forEach from 'lodash/forEach';
import isUndefined from 'lodash/isUndefined';
import sortBy from 'lodash/sortBy';
import {convertRatingToSlug} from './rating-filter-utils';

/**
 * This object is a list of all possible options for monthly reservation types.
 * The monthly spots present in the store will help determine which options will
 * actually be displayed for the user to select.
 *
 * Related reservation types are meant to give users a wider range of options.
 * Say for example, the user is mainly interested in nights_and_247_weekend and selects
 * that from the filters. nights_and_247_weekend may be a less common offering, so there
 * will not be many spots with that exact reservation type. However, 247 parking
 * (the related reservation type) includes nights and weekends, and therefore
 * still meets the user's needs.
 *
 */
export const initialReservationTypes = {
    all: {
        slug: 'all',
        name: 'All Parking Options',
    },
    // eslint-disable-next-line quote-props
    '247': {
        slug: '247',
        name: '24/7 Regular Parking',
        description: 'Unlimited parking 7 days a week, 24 hours a day.',
        count: 0,
        related: [],
        active: false,
    },
    // eslint-disable-next-line camelcase
    daytime_only: {
        slug: 'daytime_only',
        name: 'Daytime Only Parking',
        description:
            'Great option if you drive to work but don’t need to park overnight.',
        count: 0,
        related: ['daytime_and_247_weekend', '247'],
        active: false,
    },
    // eslint-disable-next-line camelcase
    daytime_and_247_weekend: {
        slug: 'daytime_and_247_weekend',
        name: 'Daytime + 24/7 Weekend Parking',
        description:
            'Great option if you drive to work during the week and need unlimited parking during the weekend.',
        count: 0,
        related: ['247'],
        active: false,
    },
    // eslint-disable-next-line camelcase
    nights_only: {
        slug: 'nights_only',
        name: 'Nights Only Parking',
        description:
            'Great option if you need to park overnight, but use your car during the day.',
        count: 0,
        related: ['nights_and_247_weekend', '247'],
        active: false,
    },
    // eslint-disable-next-line camelcase
    nights_and_247_weekend: {
        slug: 'nights_and_247_weekend',
        name: 'Nights + 24/7 Weekend Parking',
        description:
            'Great option if you need to park overnight and throughout the weekend.',
        count: 0,
        related: ['247'],
        active: false,
    },
    storage: {
        slug: 'storage',
        name: 'Storage',
        description:
            'Safe and secure storage if you want to drop off your car for an extended period of time',
        count: 0,
        related: [],
        active: false,
    },
    valet: {
        slug: 'valet',
        name: 'On-Demand Valet',
        description:
            'Book through SpotHero and and a third party valet will pick up/drop off your car at your desired location. Restrictions apply.',
        count: 0,
        related: [],
        active: false,
    },
};

/**
 * Takes in a list of all spots and all currently displayed spots
 * (which may already have amenities or reservation types filters applied)
 * and returns an object representing amenity information
 * (slug, name, total number and current number of spots offering amenity).
 *
 * As a user checks/unchecks amenities in the filters modal, they can see the
 * spot counts change for each amenity.
 *
 * @function setAmenities
 * @param {object} options - options to pass in
 * @param {Array} options.spots - all spots in store, i.e. all spots that we care to filter over
 * @param {Array} options.filteredSpots - all spots that are currently shown to the user, based on existing filter selections
 * @returns {object} sortedAmenities - array of updated information on amenities to display
 */
export const setAmenities = ({spots, filteredSpots}) => {
    const aggregatedSearchAmenities = {};

    // builds object of amenities
    spots.forEach(spot => {
        const allAmenities = spot.allAmenities;

        allAmenities?.forEach(
            ({type: slug, displayName: name, sortOrder: order}) => {
                if (isUndefined(aggregatedSearchAmenities[slug])) {
                    aggregatedSearchAmenities[slug] = {
                        slug,
                        name,
                        ...(order && {order}), // is this necessary and does it change per spot?
                        totalCount: 1,
                        count: 0,
                    };
                } else {
                    aggregatedSearchAmenities[slug].totalCount++;
                }
            }
        );
    });

    // updates the filteredCount based on current filtered spots
    filteredSpots.forEach(spot => {
        const allAmenities = spot.allAmenities;

        allAmenities?.forEach(({type: slug, displayName: name}) => {
            // assume that all filteredSpots have amenities that we have already seen
            if (aggregatedSearchAmenities[slug]) {
                aggregatedSearchAmenities[slug].count++;
            }
        });
    });

    // sort all of the amenities based on the order from the BE
    const sorted = sortBy(aggregatedSearchAmenities, ['order']);
    const sortedAmenities = {};

    // repopulate an object for consumption downstream with the sorted amenities
    forEach(sorted, item => {
        sortedAmenities[item.slug] = item;
    });

    return sortedAmenities;
};

/**
 * Takes in a list of all currently shown spots (which may already have amenities or
 * reservation types filters applied) and returns updated reservationTypes information
 * (display info and whether it should be displayed in the reservation types dropdown).
 *
 * @function setReservationTypes
 */
export const setReservationTypes = ({spots, reservationTypes}) => {
    // reset all reservation types to inactive
    forEach(reservationTypes, reservationType => {
        if (reservationType.slug !== 'all') {
            reservationType.active = false;
        }
    });

    // populate reservation types
    forEach(spots, spot => {
        forEach(spot.allMonthlyReservationTypes, spotReservationType => {
            if (!isUndefined(reservationTypes[spotReservationType])) {
                reservationTypes[spotReservationType].active = true;
            }
        });
    });

    return reservationTypes;
};

/**
 * Takes in a list of all spots and all currently displayed spots
 * (which may already have amenities or reservation types filters applied)
 * and returns updated ratingTypes object.
 *
 * @function setRatings
 */
export const setRatings = ({spots, ratingTypes}) => {
    // reset all rating types to inactive
    forEach(ratingTypes, ratingType => {
        ratingType.count = 0;
    });

    spots.forEach(spot => {
        const slug = convertRatingToSlug(spot?.rating);
        ratingTypes[slug].count++;
        ratingTypes[slug].active = true;
    });

    return ratingTypes;
};
