import { ActionType } from 'redux-promise-middleware';
import UserUtils from 'utils/user-utils';
import { CHECKOUT_UPDATE_DATA, CHECKOUT_CLEAR_CUSTOMER, } from '../checkout/checkout-actions';
import { USER_LOGIN, USER_LOGOUT, USER_UPDATE, ADD_VEHICLE, } from './user-actions';
export const initialState = (data) => ({
    isPending: false,
    isLoading: true,
    data: {
        creditCards: [],
        paypalCard: null,
        creditWallets: {},
        dateJoined: null,
        displayName: null,
        email: null,
        experiments: {},
        firstName: null,
        id: null,
        isAdmin: false,
        isSeller: false,
        justCreated: false,
        lastName: null,
        licensePlates: [],
        phoneNumber: null,
        referralToken: null,
        vehicles: [],
        status: UserUtils.AUTH_STATE.LOGGED_OUT,
        ...data,
    },
    error: null,
});
export default function user(state = initialState(), { type, payload }) {
    switch (type) {
        case `${USER_LOGIN}_${ActionType.Pending}`: {
            return {
                ...state,
                isPending: true,
                error: null,
            };
        }
        case `${USER_LOGIN}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                isPending: false,
                data: {
                    ...payload,
                    status: UserUtils.AUTH_STATE.USER,
                },
            };
        }
        case `${USER_LOGIN}_${ActionType.Rejected}`: {
            return {
                ...state,
                error: 'Could not log in.',
            };
        }
        case `${USER_LOGOUT}_${ActionType.Pending}`: {
            return {
                ...state,
                isPending: true,
                error: null,
            };
        }
        case `${USER_LOGOUT}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                data: {
                    ...initialState().data,
                    ...payload,
                    status: UserUtils.AUTH_STATE.LOGGED_OUT,
                },
            };
        }
        case `${USER_LOGOUT}_${ActionType.Rejected}`: {
            return {
                ...state,
                error: 'Could not log out.',
            };
        }
        case USER_UPDATE: {
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    ...payload,
                },
            };
        }
        case CHECKOUT_UPDATE_DATA: {
            const { phone } = payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    phoneNumber: Object.prototype.hasOwnProperty.call(payload, 'phone')
                        ? phone
                        : state.data.phoneNumber,
                },
            };
        }
        case CHECKOUT_CLEAR_CUSTOMER: {
            return {
                ...state,
                data: payload,
            };
        }
        case ADD_VEHICLE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    vehicles: [...state.data.vehicles, payload],
                },
            };
        }
        default:
            return state;
    }
}
