import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidV4 } from 'uuid';
import { Checkbox, Label } from '@spothero/ui-backlog';
import { Box } from '@spothero/ui';
import AmenityUtils from '@spothero/utils/amenity';
import { getRatingIcons } from 'store/filters/rating-filter-utils';
const FilterItem = ({ className, slug, label, count, defaultChecked, disabled, showIcon, showCount, isQuickFilter, onChange, id, styleProps, iconColor, }) => {
    const [key, setKey] = useState(uuidV4());
    const Icon = AmenityUtils.getIcon(slug) || getRatingIcons(slug);
    const classes = classNames('FilterItem', className);
    const onAmenityChange = ({ target: { name, checked } }) => {
        onChange(name, checked, isQuickFilter);
    };
    useEffect(() => {
        setKey(uuidV4());
    }, [defaultChecked]);
    return (<Box id={id} className="FilterItem-container" data-testid={`FilterItem-${slug}`} width="100%" textAlign="left" sx={{
            '.FilterItem-label': {
                display: 'flex',
                alignItems: 'center',
                '& .Icon': {
                    // TODO there should be a cleanup opportunity here, feels weird to do this
                    fill: iconColor,
                    top: 0,
                },
            },
        }} {...styleProps}>
            <Checkbox key={key} id={`FilterItem-${slug}`} className={classes} name={slug} defaultChecked={defaultChecked} label={<Label className="FilterItem-label">
                        {showIcon && Icon && <Icon />}
                        {label}{' '}
                        {showCount && (<Box as="span" marginLeft="1">
                                ({count})
                            </Box>)}
                    </Label>} disabled={disabled} onChange={onAmenityChange}/>
        </Box>);
};
FilterItem.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    slug: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    count: PropTypes.number,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    showIcon: PropTypes.bool,
    showCount: PropTypes.bool,
    isQuickFilter: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};
export default FilterItem;
