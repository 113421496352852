/* eslint-disable no-unused-vars */
// TODO MR - Leaving this as the spot schema is a lot

/* V2-CLEANUP
Most of this file will likely be obsolete when Search V2 Integration is complete
It may take a little effort to figure out which functions can be deleted vs kept
This is used on Search for Transient, Monthly, Airport, and Event verticals
*/
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import some from 'lodash/some';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'utils/dayjs-timezone';
import dayjsDuration from 'utils/dayjs-duration';
import APIUtils from '@spothero/utils/api';
import FormatUtils from 'utils/format';
import Config from '@/config/index';
import FacilityAPI from 'api/facility';
import {
    SPOT_SORT_OPTIONS,
    distanceComparator,
    advertisedPriceComparator,
    spotTitleComparator,
    ratingComparator,
    airportFeaturedComparator,
    airportTransportationComparator,
} from './spot-sort';
import {
    filterSpotsByAmenities,
    filterSpotsByMonthlyReservationType,
    filterSpotsByRating,
} from './spot-filter';
import {formatDateTime} from './format-date-time';
import ErrorUtils from 'utils/error-utils';
import {getTransientFacility} from 'api/search-v2/transient/spot/getTransientFacility';
import {getAiportFacilityV2ThenConvert} from 'api/search-v2/airport/spot/getAirportFacility';
import {ALWAYS_OPEN_TYPE, formatAccessHours} from './spot-format-access-hours';
import {getMonthlyFacility} from 'api/search-v2/monthly/spot/getMonthlyFacility';
export {ALWAYS_OPEN_TYPE};
import TimeUtils from 'utils/time';
import {getBulkSearchTransientFacility} from 'api/search-v2/power-booking/spot/getBulkSearchTransientFacility';
import UrlUtils from '@spothero/utils/url';
import {OVERSIZE_FEE_TYPE} from './reservation-utils';

const SpotUtils = {
    airportSortValues: {
        ALPHABETICAL: 'alphabetical',
        PRICE: 'price',
        FEATURED: 'featured',
        SHUTTLE_TIME: 'shuttleTime',
    },

    formatAccessHours,

    buildCheckoutURL(spot, rate, searchRequest) {
        const rebookId = searchRequest.rebook_reservation_id; // eslint-disable-line camelcase
        let spotUrl = spot.spot_url || spot.facility.facility_url;

        if (searchRequest.monthly) {
            const monthlyStarts = rate?.starts || searchRequest?.starts;

            spotUrl += `?rid=${rate.rule_id}&starts=${monthlyStarts}&monthly=true`;
        } else if (searchRequest.powerBooking) {
            const {powerBookingPeriods, powerBookingSource} = searchRequest;
            const powerBookingStarts = [];
            const powerBookingEnds = [];

            powerBookingPeriods?.forEach(
                ({starts: periodStarts, ends: periodEnds}) => {
                    powerBookingStarts.push(periodStarts);
                    powerBookingEnds.push(periodEnds);
                }
            );

            const query = {
                rid: rate.fullRule,
                // eslint-disable-next-line camelcase
                power_booking: true,
                starts: powerBookingStarts,
                ends: powerBookingEnds,
                // eslint-disable-next-line camelcase
                ...(powerBookingSource && {pb_source: powerBookingSource}),
            };

            spotUrl += `?${UrlUtils.createQueryString(query)}`;
        } else {
            if (!isNil(searchRequest.eid)) {
                spotUrl = !includes(rate.url, 'eid')
                    ? `${rate.url}&eid=${searchRequest.eid}`
                    : rate.url;
            } else {
                spotUrl += `?rid=${rate.rule_group_id}&starts=${searchRequest.starts}&ends=${searchRequest.ends}`;

                if (searchRequest.airport) {
                    spotUrl += `&airport=true`;
                }
            }
        }

        if (rebookId) {
            if (includes(spotUrl, '?')) {
                spotUrl += `&rebook_reservation_id=${rebookId}`;
            } else {
                spotUrl += `?rebook_reservation_id=${rebookId}`;
            }
        }

        const spotUrlArr = spotUrl.split('/');

        if (includes(spotUrlArr[0], 'http')) {
            spotUrl = spotUrlArr.slice(3).join('/');
        }

        return `/${spotUrl}`;
    },

    buildExpressCheckoutURL(spot, rate, searchRequest) {
        // Eventually will switch this based on vertical
        const vertical = 'hourly';
        let spotUrl = `/purchase/${vertical}?facility=${spot.spotId}`;

        if (!isNil(searchRequest.eid)) {
            const rateUrl = !includes(rate.url, 'eid')
                ? `${rate.url}&eid=${searchRequest.eid}`
                : rate.url;

            spotUrl += `&${rateUrl.split('?')[1]}`;
        } else {
            spotUrl += `&rid=${rate.rule_group_id}&starts=${searchRequest.starts}&ends=${searchRequest.ends}`;
        }

        return spotUrl;
    },

    prepare({spot, rid, searchRequest, timezone}) {
        const facility =
            spot.facility && spot.facility.id ? spot.facility : spot;
        /* eslint-disable camelcase */
        const preparedResult = {
            ...spot,
            spotId: facility.parking_spot_id || facility.id,
            hourly_rates: spot.hourly_rates || [],
            monthly_rates: spot.monthly_rates || [],
            bulk_event_rates: spot.bulk_event_rates || [],
            event_package: spot.event_package || {},
            isFavorite: false,
            hasTravelDistanceUpdated: false,
            hidden: false,
            selectedRate: null,
            url: spot.spot_url,
            bulkPowerBookingRates: spot.bulkPowerBookingRates || [],
            facility: {
                ...facility,
                access_hours_formatted: this.formatAccessHours(
                    facility.hours_of_operation
                ),
            },
        };
        /* eslint-enable camelcase */
        let currentRate;

        // starts & ends: remove timezone info to conform datetimes to `YYYY-MM-DDTHH:MM` format
        const handleStartEnd = (start, end) => {
            // defaulting to etc/utc matches moment functionality
            return {
                start: formatDateTime(
                    start,
                    timezone || 'Etc/UTC',
                    Config.apiDateTimeFormat
                ),
                end: formatDateTime(
                    end,
                    timezone || 'Etc/UTC',
                    Config.apiDateTimeFormat
                ),
            };
        };

        // prepare bulk event rates
        forEach(preparedResult.bulk_event_rates, (rate, index) => {
            currentRate = preparedResult.bulk_event_rates[index];

            if (isNil(currentRate.hidden)) {
                currentRate.hidden = false;
            }

            // url
            currentRate.fullUrl = SpotUtils.buildCheckoutURL(
                spot,
                currentRate,
                searchRequest
            );

            currentRate.expressCheckoutUrl = SpotUtils.buildExpressCheckoutURL(
                spot,
                currentRate,
                searchRequest
            );

            const {start, end} = handleStartEnd(
                currentRate.starts,
                currentRate.ends
            );

            currentRate.starts = start;
            currentRate.ends = end;

            // access hours
            /* eslint-disable camelcase */
            if (
                preparedResult.facility.access_hours_formatted &&
                preparedResult.facility.access_hours_formatted.length
            ) {
                currentRate.access_hours_formatted =
                    preparedResult.facility.access_hours_formatted;
            } else {
                currentRate.access_hours_formatted = false;
            }
            /* eslint-enable camelcase */

            // full rule id
            currentRate.fullRule = currentRate.rule_group_id;
        });

        // prepare hourly rates
        forEach(preparedResult.hourly_rates, (rate, index) => {
            currentRate = preparedResult.hourly_rates[index];

            if (isNil(currentRate.hidden)) {
                currentRate.hidden = false;
            }

            // is this a recently updated monthly rate? - BK 4/1/2015
            currentRate.newMonthlyRate = false;

            // url
            currentRate.fullUrl = SpotUtils.buildCheckoutURL(
                spot,
                currentRate,
                searchRequest
            );

            currentRate.expressCheckoutUrl = SpotUtils.buildExpressCheckoutURL(
                spot,
                currentRate,
                searchRequest
            );

            const {start, end} = handleStartEnd(
                currentRate.starts,
                currentRate.ends
            );

            currentRate.starts = start;
            currentRate.ends = end;

            // access hours
            /* eslint-disable camelcase */
            if (
                preparedResult.facility.access_hours_formatted &&
                preparedResult.facility.access_hours_formatted.length
            ) {
                currentRate.access_hours_formatted =
                    preparedResult.facility.access_hours_formatted;
            } else {
                currentRate.access_hours_formatted = false;
            }
            /* eslint-enable camelcase */

            // full rule id
            currentRate.fullRule = currentRate.rule_group_id;
        });

        // prepare monthly rates
        forEach(preparedResult.monthly_rates, (rate, index) => {
            currentRate = preparedResult.monthly_rates[index];

            if (isNil(currentRate.hidden)) {
                currentRate.hidden = false;
            }

            // is this a recently updated monthly rate? - BK 4/1/2015
            if (
                !isNil(currentRate.redemption_instructions) &&
                currentRate.redemption_instructions.length
            ) {
                currentRate.newMonthlyRate = true;
            } else {
                currentRate.newMonthlyRate = false;
            }

            // url
            currentRate.fullUrl = SpotUtils.buildCheckoutURL(
                spot,
                currentRate,
                searchRequest
            );

            currentRate.expressCheckoutUrl = SpotUtils.buildExpressCheckoutURL(
                spot,
                currentRate,
                searchRequest
            );

            // amenities
            // only update if result contains facility otherwise we assume its already present
            // TODO this is a fallback for monthly rates that have not yet been updated at the rate level
            if (!currentRate.newMonthlyRate) {
                currentRate.amenities = spot.facility.amenities_full;
            } else {
                currentRate.amenities = currentRate.amenities_full;
            }

            // access hours
            /* eslint-disable camelcase */
            if (currentRate.access_hours.length) {
                currentRate.access_hours_formatted = currentRate.access_hours; // eslint-disable-line camelcase
            } else {
                if (spot.facility.hours_of_operation.periods.length) {
                    currentRate.access_hours_formatted =
                        preparedResult.facility.access_hours_formatted; // eslint-disable-line camelcase
                } else {
                    currentRate.access_hours_formatted = false; // eslint-disable-line camelcase
                }
            }
            /* eslint-enable camelcase */

            // full rule id
            currentRate.fullRule = currentRate.rule_id;
        });

        // set selectedRate
        if (
            isNil(preparedResult.selectedRate) ||
            !preparedResult.selectedRate
        ) {
            // only use bulk_event_rates if it is an event package
            const ratesArrayKey = searchRequest.monthly
                ? 'monthly_rates'
                : preparedResult?.event_package?.id
                ? 'bulk_event_rates'
                : 'hourly_rates';
            const ratesArray = preparedResult[ratesArrayKey];

            preparedResult.selectedRate = this.prepareSelectedRate(
                ratesArray,
                rid,
                ratesArrayKey
            );
        }

        if (
            preparedResult.selectedRate &&
            preparedResult.bulkPowerBookingRates.length
        ) {
            let totalServiceFee = 0;
            let totalPrice = 0;

            preparedResult?.bulkPowerBookingRates.forEach(({rates}) => {
                let requiredRate = null;

                if (rates.legnth > 1) {
                    requiredRate = rates.find(rate => rate.fullRule === rid);
                } else {
                    requiredRate = rates[0];
                }

                if (requiredRate) {
                    /* eslint-disable camelcase */
                    const {
                        quote: {items = [], total_price = {}} = {},
                    } = requiredRate;
                    const serviceFeeItem = items.find(
                        ({short_description}) =>
                            short_description === 'Service Fee'
                    );

                    totalServiceFee += serviceFeeItem?.price?.value;
                    totalPrice += total_price?.value;
                }
                /* eslint-enable camelcase*/
            });

            preparedResult.selectedRate.totalServiceFee = totalServiceFee;
            preparedResult.selectedRate.totalFee = totalPrice;
            preparedResult.selectedRate.subTotal = spot.selectedRate?.subTotal;
            preparedResult.selectedRate.averagePrice =
                spot.selectedRate?.averagePrice;
        }

        /* eslint-disable camelcase */
        if (!isEmpty(preparedResult.event_package)) {
            preparedResult.selectedRate.event_package =
                preparedResult.event_package;
        }
        /* eslint-enable camelcase */

        return preparedResult;
    },

    // This function takes the bulk_event_rates array and adds together all the prices and price breakdown items so it can be collected into a single selected rate
    // All of the other data that isn't a price is taken from the first item in the bulk_event_rates array
    consolidateBulkEventRates: arr => {
        /* eslint-disable camelcase */
        const displayPrice = arr.reduce((accm, element) => {
            return accm + element.display_price;
        }, 0);
        const totalPrice = arr.reduce((accm, element) => {
            return accm + element.price;
        }, 0);
        const priceBreakdownTotal = arr.reduce((accm, element) => {
            return accm + element.price_breakdown.total_price;
        }, 0);

        const priceBreakdownItems = [];

        arr.forEach(rate => {
            // if object has a new short_description value that isn't present in priceBreakdownItems, add the whole object
            // if short_description value is already present in priceBreakdownItems, add the price to existing price
            rate.price_breakdown.items.forEach(element => {
                if (
                    priceBreakdownItems.some(
                        elem =>
                            elem?.short_description ===
                            element?.short_description
                    )
                ) {
                    // get correct index of price breakdown item
                    const correctIndex = priceBreakdownItems.findIndex(
                        elem =>
                            elem.short_description === element.short_description
                    );

                    // add price to existing price for a given price breakdown item
                    priceBreakdownItems[correctIndex].price += element.price;
                } else {
                    priceBreakdownItems.push(cloneDeep(element));
                }
            });
        });

        return {
            ...arr[0],
            display_price: displayPrice,
            price: totalPrice,
            price_breakdown: {
                ...arr[0].price_breakdown,
                total_price: priceBreakdownTotal,
                items: priceBreakdownItems,
            },
        };
        /* eslint-enable camelcase */
    },

    prepareSelectedRate(ratesArray, rid, ratesArrayKey) {
        let selectedRate = null;
        let ridRate = null;

        if (ratesArray.length) {
            if (rid) {
                ridRate = find(ratesArray, {
                    fullRule: rid,
                });
            }

            selectedRate =
                ratesArrayKey === 'bulk_event_rates'
                    ? this.consolidateBulkEventRates(ratesArray)
                    : cloneDeep(ridRate || ratesArray[0]);
        }

        return selectedRate;
    },

    filter(spots, filterState, isMonthly) {
        const {
            activeReservationType,
            activeAmenities,
            price,
            reservationTypes,
        } = filterState;
        const relatedReservationTypes =
            reservationTypes[activeReservationType].related;
        const newFiltered = [];

        forEach(spots, spot => {
            const rateType = isMonthly ? 'monthly_rates' : 'hourly_rates';
            const rates = spot[rateType];
            let show = true;

            if (price && spot.selectedRate.price > price) {
                show = false;
            }

            // default rates to hidden = false
            forEach(rates, rate => {
                rate.hidden = false;
            });

            // filter monthly results by reservationType
            if (show && isMonthly && activeReservationType !== 'all') {
                let hasMatchingRate = false;

                forEach(rates, rate => {
                    // set selectedRate if type matches
                    // if not set as hidden (from filters)
                    if (
                        !hasMatchingRate &&
                        rate.reservation_type === activeReservationType
                    ) {
                        spot.selectedRate = rate;
                        hasMatchingRate = true;
                    }

                    // attempt to match related rates if neccesary
                    if (!hasMatchingRate) {
                        forEach(
                            relatedReservationTypes,
                            relatedReservationType => {
                                if (
                                    rate.reservation_type ===
                                    relatedReservationType
                                ) {
                                    rate.hidden = false;
                                    spot.selectedRate = rate;
                                    hasMatchingRate = true;
                                }
                            }
                        );
                    }
                });

                show = hasMatchingRate;
            }

            if (show && activeAmenities.length) {
                let hasMatchingRate = false;

                forEach(rates, rate => {
                    // filter by active amenities
                    const rateMatches = [];

                    forEach(activeAmenities, amenity => {
                        if (includes(amenity, 'no-')) {
                            if (
                                !some(rate.amenities, {
                                    slug: amenity.replace('no-', ''),
                                })
                            ) {
                                rateMatches.push(amenity);
                            }
                        } else {
                            if (some(rate.amenities, {slug: amenity})) {
                                rateMatches.push(amenity);
                            }
                        }
                    });

                    if (rateMatches.length === activeAmenities.length) {
                        hasMatchingRate = true;
                    } else {
                        rate.hidden = true;
                    }
                });

                show = hasMatchingRate;
            }

            spot[rateType] = rates;

            if (show) {
                spot.hidden = false;
                newFiltered.push(spot);
            } else {
                spot.hidden = true;
            }
        });

        return newFiltered;
    },

    filterV2(spots, filterState, isMonthly = false) {
        if (!filterState) {
            return spots;
        }

        const {
            activeAmenities,
            activeReservationType,
            reservationTypes,
            activeRatingTypes,
        } = filterState;
        const relatedReservationTypes =
            reservationTypes[activeReservationType].related;

        const filteredSpotsByRating =
            activeRatingTypes.length !== 0
                ? filterSpotsByRating(spots, activeRatingTypes)
                : spots;

        if (isMonthly && activeReservationType !== 'all') {
            const filteredSpotsByReservationType = filterSpotsByMonthlyReservationType(
                filteredSpotsByRating,
                activeReservationType,
                relatedReservationTypes
            );

            return filterSpotsByAmenities(
                filteredSpotsByReservationType,
                activeAmenities
            );
        } else {
            return filterSpotsByAmenities(
                filteredSpotsByRating,
                activeAmenities
            );
        }
    },

    sortByAttribute(spots, attr = 'distance') {
        const comparator = {
            [this.airportSortValues.ALPHABETICAL]: spot => spot.title,
            [this.airportSortValues.PRICE]: spot =>
                spot.selectedRate.display_price,
            [this.airportSortValues.FEATURED]: spot => spot.facility.order,
            [this.airportSortValues.SHUTTLE_TIME]: spot =>
                spot.facility.airport.shuttle.duration,
        };
        const sortAttr =
            attr !== 'distance'
                ? attr
                : ({distance, distanceInMiles}) =>
                      !isNil(distanceInMiles)
                          ? distanceInMiles
                          : distance / 1609;

        return sortBy(spots, [comparator[attr] || sortAttr]);
    },

    // eslint-disable-next-line no-shadow
    sortByAttributeV2(spots, sortBy = 'distance') {
        // The API returns spots sorted by relevance.
        // For Sort By Relevance we return the spots as is.
        if (sortBy === SPOT_SORT_OPTIONS.RELEVANCE) {
            return spots;
        }

        const comparator = {
            [SPOT_SORT_OPTIONS.DISTANCE]: distanceComparator,
            [SPOT_SORT_OPTIONS.PRICE]: advertisedPriceComparator,
            [SPOT_SORT_OPTIONS.RATING]: ratingComparator,
            [SPOT_SORT_OPTIONS.ALPHABETICAL]: spotTitleComparator,
            [SPOT_SORT_OPTIONS.FEATURED]: airportFeaturedComparator,
            [SPOT_SORT_OPTIONS.SHUTTLE_TIME]: airportTransportationComparator,
        };

        // To avoid mutating the original array of spots, we first clone the
        // array, then sort that. If we just sort the array in place with
        // the `.sort()` method, this creates issues where the Analytics
        // middleware sorts the array, and then the array gets sorted again
        // anytime the `getSpotsV2` selector gets called.
        const sortedSpots = [...spots].sort(comparator[sortBy]);

        return sortedSpots;
    },

    prepareGroup({spots, searchRequest, timezone}) {
        return map(spots, spot => {
            return SpotUtils.prepare({
                spot,
                searchRequest,
                timezone,
            });
        });
    },

    loadRatesSpotDetail({
        spotId,
        starts,
        ends,
        powerBookingPeriods,
        isPowerBooking,
        isMonthly,
        isAirport,
        searchDataParams,
        timezone,
        spot = null,
        eventId = null,
        rebookId = null,
        activeAmenities = [],
        onSuccessWithRates,
        onSuccessWithoutRates,
        onError,
        powerBookingSource = null,
        vehicleInfoId = null,
    }) {
        let aliasFunction = getTransientFacility;

        if (isMonthly) {
            aliasFunction = getMonthlyFacility;
        }

        if (isAirport) {
            aliasFunction = getAiportFacilityV2ThenConvert;
        }

        if (isPowerBooking) {
            aliasFunction = getBulkSearchTransientFacility;
        }

        let rateLoader = null;

        if (isPowerBooking) {
            rateLoader = APIUtils.makeCancelable(
                aliasFunction(spotId, {
                    powerBookingPeriods,
                    eventId,
                    rebookId,
                    powerBookingSource,
                })
            );
        } else {
            rateLoader = APIUtils.makeCancelable(
                aliasFunction(spotId, {
                    starts: dayjs(starts).format(
                        TimeUtils.V2_API_TIMESTAMP_FORMAT
                    ),
                    ends: dayjs(ends).format(TimeUtils.V2_API_TIMESTAMP_FORMAT),
                    eventId,
                    rebookId,
                    powerBookingPeriods,
                    ...(vehicleInfoId ? {vehicle_info_id: vehicleInfoId} : {}),
                })
            );
        }

        rateLoader.promise
            .then(spotData => {
                const rateType = isMonthly ? 'monthly_rates' : 'hourly_rates';

                // TODO: BK - is there another way to better handle this?
                // need to assign updated starts and ends to params on state in this case because
                // updateSearchTimes is not called until the callback to first ensure time changes are valid
                const newSpotData = SpotUtils.prepare({
                    spot: spotData,
                    searchRequest: {
                        ...searchDataParams,
                        starts,
                        ends,
                        powerBooking: isPowerBooking,
                        powerBookingPeriods,
                        powerBookingSource,
                    },
                    timezone,
                });
                // When loading rates from a `spot-id` query param on page load, no spot data will be provided to
                // this method so we just want to use the one that comes back from the response so that we have spot
                // data to show the spot details properly on the search page
                const savedSpotData = spot ? spot : newSpotData;

                if (spotData[rateType].length > 0) {
                    let newRate = newSpotData.selectedRate;

                    if (
                        spotData[rateType].length > 1 &&
                        spot &&
                        spot.selectedRate
                    ) {
                        // if there are multiple rates make sure to select the same rate that was selected pre-reloading
                        // so that the UI matches up correctly and the proper rate is selected in the choices
                        const priorMatchedRate = find(newSpotData[rateType], {
                            fullRule: spot.selectedRate.fullRule,
                        });

                        if (priorMatchedRate) {
                            newRate = priorMatchedRate;
                        }
                    }

                    const isInOutActive = activeAmenities?.includes('in-out');
                    const isMultiRate = newSpotData?.hourly_rates?.length > 1;

                    const selectedRateHasInOut = newSpotData?.selectedRate?.amenities?.filter(
                        ({slug}) => slug === 'in-out'
                    ).length;

                    if (
                        rateType === 'hourly_rates' &&
                        isInOutActive &&
                        isMultiRate &&
                        !selectedRateHasInOut
                    ) {
                        const firstRateWithInOut = newSpotData?.hourly_rates?.find(
                            rate =>
                                rate?.amenities?.some(
                                    ({slug}) => slug === 'in-out'
                                )
                        );

                        if (firstRateWithInOut) {
                            newRate = find(newSpotData[rateType], {
                                fullRule: firstRateWithInOut.fullRule,
                            });
                        }
                    }

                    const finalRate = isPowerBooking
                        ? {
                              ...newRate,
                              subTotal: spotData?.selectedRate?.subTotal,
                              averagePrice:
                                  spotData?.selectedRate?.averagePrice,
                              PBTotalPrice:
                                  spotData?.selectedRate?.PBTotalPrice,
                          }
                        : newRate;

                    if (onSuccessWithRates) {
                        /* eslint-disable camelcase */
                        onSuccessWithRates({
                            ...savedSpotData,
                            hourly_rates: newSpotData.hourly_rates,
                            monthly_rates: newSpotData.monthly_rates,
                            lowest_monthly_price:
                                newSpotData.lowest_monthly_price,
                            selectedRate: finalRate,
                        });
                        /* eslint-disable camelcase */
                    }
                } else {
                    if (onSuccessWithoutRates) {
                        onSuccessWithoutRates({
                            ...savedSpotData,
                            hourly_rates: [],
                            monthly_rates: [],
                            lowest_monthly_price: null,
                            selectedRate: null,
                        });
                    }
                }
            })
            .catch(error => {
                ErrorUtils.sendSentryException(error);

                if (onError) {
                    onError(error);
                }
            });

        return rateLoader;
    },

    loadRates({
        spotId,
        starts,
        ends,
        isMonthly,
        searchDataParams,
        timezone,
        spot = null,
        eventId = null,
        onSuccessWithRates,
        onSuccessWithoutRates,
        onError,
    }) {
        // Legacy
        const rateLoader = APIUtils.makeCancelable(
            FacilityAPI.getRates({
                spotId,
                starts,
                ends,
                isMonthly,
                searchDataParams,
                timezone,
                spot,
                eventId,
                onSuccessWithRates,
                onSuccessWithoutRates,
                onError,
            })
        );

        rateLoader.promise
            .then(spotData => {
                const rateType = isMonthly ? 'monthly_rates' : 'hourly_rates';

                // TODO: BK - is there another way to better handle this?
                // need to assign updated starts and ends to params on state in this case because
                // updateSearchTimes is not called until the callback to first ensure time changes are valid
                const newSpotData = SpotUtils.prepare({
                    spot: spotData,
                    searchRequest: {
                        ...searchDataParams,
                        starts,
                        ends,
                    },
                    timezone,
                });
                // When loading rates from a `spot-id` query param on page load, no spot data will be provided to
                // this method so we just want to use the one that comes back from the response so that we have spot
                // data to show the spot details properly on the search page
                const savedSpotData = spot ? spot : newSpotData;

                if (spotData[rateType].length > 0) {
                    let newRate = newSpotData.selectedRate;

                    if (
                        spotData[rateType].length > 1 &&
                        spot &&
                        spot.selectedRate
                    ) {
                        // if there are multiple rates make sure to select the same rate that was selected pre-reloading
                        // so that the UI matches up correctly and the proper rate is selected in the choices
                        const priorMatchedRate = find(newSpotData[rateType], {
                            fullRule: spot.selectedRate.fullRule,
                        });

                        if (priorMatchedRate) {
                            newRate = priorMatchedRate;
                        }
                    }

                    if (onSuccessWithRates) {
                        /* eslint-disable camelcase */
                        onSuccessWithRates({
                            ...savedSpotData,
                            hourly_rates: newSpotData.hourly_rates,
                            monthly_rates: newSpotData.monthly_rates,
                            lowest_monthly_price:
                                newSpotData.lowest_monthly_price,
                            selectedRate: newRate,
                        });
                        /* eslint-disable camelcase */
                    }
                } else {
                    if (onSuccessWithoutRates) {
                        onSuccessWithoutRates({
                            ...savedSpotData,
                            hourly_rates: [],
                            monthly_rates: [],
                            lowest_monthly_price: null,
                            selectedRate: null,
                        });
                    }
                }
            })
            .catch(error => {
                ErrorUtils.sendSentryException(error);

                if (onError) {
                    onError(error);
                }
            });

        return rateLoader;
    },

    formatRatePrice({price, currencyType, postFix = ''}) {
        const {dollars, cents} = FormatUtils.price(price);

        return `<sup>${FormatUtils.currencySymbol(
            currencyType
        )}</sup>${dollars}${cents ? `<sup>.${cents}</sup>` : ''}${postFix}`;
    },

    IN_OUT_MESSAGES: {
        no: () => "In/Out NOT allowed. Once you leave, that's it, my friend.",
        yes_fee: inOut =>
            `There is a ${inOut.currency || '$'}${
                FormatUtils.price(inOut.fee).displayPrice
            } charge for each in/out.`,
        yes_limited: inOut =>
            `In/outs are limited to ${inOut.limit} per ${inOut.limit_type}.`,
        yes_limited_fee: inOut =>
            `In/outs are limited to ${inOut.limit} per ${
                inOut.limit_type
            }. Additional in/outs are allowed for a ${inOut.currency || '$'}${
                FormatUtils.price(inOut.fee).displayPrice
            } fee.`,
    },

    canCancelHPEReservation({
        reservationStart,
        reservationTimezone,
        eventCancellationMinutes,
    }) {
        const cancellationCutOffTime = dayjsDuration(reservationStart).subtract(
            eventCancellationMinutes,
            'minute'
        );

        return dayjsDuration()
            .tz(reservationTimezone)
            .isBefore(cancellationCutOffTime);
    },

    isExpressCheckoutEligible({
        spotData,
        searchRequest,
        isRebookingReservation,
        isAdmin,
        isUserInExpressCheckoutExperiment,
    }) {
        let isEligible = false;

        if (!spotData.selectedRate) {
            return false;
        }

        try {
            const {
                monthly: isMonthly,
                airport: isAirport,
                rebook_reservation_id: rebookId,
                powerBooking: isPowerBooking,
            } = searchRequest;

            const oversizePossible =
                spotData.facility.supported_fee_types?.includes(
                    OVERSIZE_FEE_TYPE
                ) ||
                spotData.facility.oversize_fees_charged_onsite ||
                spotData.facilityVehicle?.unknownOnsiteFee ||
                Boolean(spotData.facilityVehicle?.onsiteFee);

            const isEventPackage = !isEmpty(spotData.event_package);

            const isVerticalExpressAllowed =
                !isMonthly && !isPowerBooking && !isAirport && !isEventPackage;

            const isRebooking = rebookId || isRebookingReservation;

            if (
                !spotData.facility.freePark &&
                spotData.hourly_rates.length === 1 &&
                !oversizePossible &&
                isVerticalExpressAllowed &&
                !isRebooking &&
                !isAdmin &&
                isUserInExpressCheckoutExperiment
            ) {
                isEligible = true;
            }
        } catch (e) {
            console.error('Error in isExpressCheckoutEligible', e);
        }

        return isEligible;
    },
};

export default SpotUtils;
