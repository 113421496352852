/* eslint-disable jsdoc/check-param-names */
/* eslint-disable jsdoc/require-param */
import { buildImageTemplate } from './build-image-template';
/**
 * Builds the logo image object for an airport spot
 *
 * @function buildAirportLogo
 * @param {object} logo - An object containing Cloudinary image data for the logo image
 * @param {Array} images - An array of Cloudinary image objects, for other spot images
 * @returns {object} The appropriate image object to use in place of the logo.
 */
export const buildAirportLogo = (logo, images) => {
    if (logo) {
        return buildImageTemplate(logo);
    }
    else if (images[0]) {
        // if no logo is configured, replace it with the first facility image
        return buildImageTemplate(images[0]);
    }
    else {
        return null;
    }
};
