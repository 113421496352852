import UrlUtils from '@spothero/utils/url';
import Config from '@/config/index';
import { formatDateTime } from './format-date-time';
/**
 * Builds a checkout URL for use across Search/Spot Details/Checkout
 *
 * @function buildCheckoutURL
 * @param {object} params - An object containing different relevant params for building a valid checkout URL.
 * @param {string} params.rateType - Whether this is a transient, monthly, or airport rate
 * @param {string} params.spotId - The unique identifier for this spot
 * @param {string} params.facilitySlug - The human-readable, hypenated string that describes this spot (not necessarily unique)
 * @param {string} params.rateId - The rate or rate group id
 * @param {string} params.starts - The start date/time
 * @param {string} params.ends - The end date/time
 * @param {string} params.timezone - The timezone local to this spot
 * @param {string} params.eventId - the unique identifier for the event associated with this spot and rate
 * @param params.powerBookingPeriods
 * @param params.powerBookingSource
 * @param {string} params.rebookId - The identifier used when a Customer Hero is rebooking a reservation
 * @returns {string} The checkout URL.
 */
export const buildCheckoutURL = ({ spotId, facilitySlug, rateId, starts, ends, timezone, rateType, eventId, rebookId, powerBookingPeriods, powerBookingSource = null, }) => {
    // we need the facility slug, because searchParams API call returns the wrong data otherwise
    const relativeUrl = `/checkout/${spotId}/${facilitySlug}/`;
    let urlParams = {
        rebook_reservation_id: rebookId,
        rid: null,
        starts: null,
        ends: null,
    };
    const powerBookingStarts = [];
    const powerBookingEnds = [];
    switch (rateType) {
        case 'airport':
            urlParams = {
                ...urlParams,
                rid: rateId,
                starts: formatDateTime(starts, timezone),
                ends: formatDateTime(ends, timezone),
                airport: true,
            };
            break;
        case 'monthly':
            urlParams = {
                ...urlParams,
                rid: rateId,
                starts: formatDateTime(starts, timezone, Config.apiDateFormat),
                monthly: true,
            };
            break;
        case 'powerBooking':
            powerBookingPeriods?.forEach(({ starts: periodStarts, ends: periodEnds }) => {
                powerBookingStarts.push(periodStarts);
                powerBookingEnds.push(periodEnds);
            });
            urlParams = {
                ...urlParams,
                rid: rateId,
                starts: powerBookingStarts,
                ends: powerBookingEnds,
                power_booking: true,
                ...(powerBookingSource && { pb_source: powerBookingSource }),
            };
            break;
        case 'transient':
        default:
            urlParams = {
                ...urlParams,
                rid: eventId ? null : rateId,
                starts: formatDateTime(starts, timezone),
                ends: formatDateTime(ends, timezone),
                eid: eventId,
            };
    }
    return `${relativeUrl}?${UrlUtils.createQueryString(urlParams)}`;
};
export const buildExpressCheckoutURL = ({ spotId, rateId, starts, ends, timezone, rateType, eventId, rebookId, }) => {
    // we need the facility slug, because searchParams API call returns the wrong data otherwise
    let relativeUrl = '/purchase';
    let urlParams = {
        rebook_reservation_id: rebookId,
        rid: null,
        starts: null,
        ends: null,
        facility: spotId,
    };
    switch (rateType) {
        // Will need to add other verticals here as they become supported in express checkout
        case 'transient':
        default:
            relativeUrl += '/hourly';
            urlParams = {
                ...urlParams,
                rid: eventId ? null : rateId,
                starts: formatDateTime(starts, timezone),
                ends: formatDateTime(ends, timezone),
                eid: eventId,
            };
    }
    return `${relativeUrl}?${UrlUtils.createQueryString(urlParams)}`;
};
