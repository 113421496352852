import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import ObjectUtils from '@spothero/utils/object';
import { OBJECT_MONTHLY_FACILITY_SUMMARY, OBJECT_MONTHLY_FACILITY, } from '@/proptypes/index';
import { buildImageTemplate, buildUniqueRateAmenities, formatDistance, formatHoursOfOperation, } from '../../utils';
import { formatMonthlyRate } from './formatMonthlyRate';
/**
 * Formats data for a single monthly facility
 *
 * @function formatMonthlyFacility
 * @see {@link https://api.spothero.com/v2/docs#operation/searchMonthlyFacilityParking|Documentation}
 * @param {object} data - The untransformed data from the API for a single facility
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @param {string} formatOptions.timezone - The timezone of the nearest city, used for formatting date/time
 * @param {string} formatOptions.rebookId - The ID of the original reservation that we are rebooking for
 * @param {boolean} formatOptions.isFacilitySummary - If true, will return the shortened version of the facility data (e.g. to display on spot cards)
 * @returns {object} The formatted transient facility data.
 */
export function formatMonthlyFacility(data, formatOptions = {}) {
    const { timezone, rebookId, isFacilitySummary } = formatOptions;
    const dataFormat = isFacilitySummary
        ? OBJECT_MONTHLY_FACILITY_SUMMARY
        : OBJECT_MONTHLY_FACILITY;
    const facilityData = ObjectUtils.camelizeKeys(data);
    const { availability, distance, facility: { common, monthly }, rates: ratesResponse, options: { commuterCardEligible }, } = facilityData;
    const facilityTimezone = timezone || common?.addresses?.[0]?.timeZone;
    const { id: spotId, slug: facilitySlug, hoursOfOperation } = common;
    // only keep properties that have corresponding monthly rate prop types
    const rates = ratesResponse.map(({ quote, monthly: additionalRateDetails }) => formatMonthlyRate({
        spotId,
        facilitySlug,
        quote,
        additionalRateDetails,
        timezone: facilityTimezone || '',
        rebookId,
    }));
    // consolidate reservationTypes found across all rate options
    const allMonthlyReservationTypes = uniq(rates.map(rate => rate.monthlyReservationType));
    // only keep properties that have corresponding monthly facility prop types
    return pick({
        isV2: true,
        ...common,
        ...monthly,
        images: common.images.map(buildImageTemplate),
        spotId: common.id,
        availability,
        distance: distance === null
            ? {}
            : formatDistance(
            // Below is speghetti due to not knowing how to type camelize
            distance),
        rates,
        selectedRate: rates[0],
        allAmenities: buildUniqueRateAmenities(rates),
        allMonthlyReservationTypes,
        hoursOfOperation: formatHoursOfOperation(hoursOfOperation),
        operatorId: common.operatorId,
        allowUpdatingReservationAfterEntry: common.allowUpdatingReservationAfterEntry,
        oversizeFeesChargedOnsite: common.oversizeFeesChargedOnsite,
        barcodeType: common.barcodeType,
        commuterCardEligible,
    }, Object.keys(dataFormat));
}
