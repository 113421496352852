import React from 'react';
import PropTypes from 'prop-types';
import IconWalkingMan from '@spothero/icons/walk';
import {parseWalkingDistanceAndDuration} from 'utils/travel-distance';
import {Flex, Text, Icon} from '@spothero/ui';

// TODO remove usage of distanceInMiles and replace with linearMeters
const WalkingDistanceDisplay = ({
    distance,
    distanceInMiles,
    linearMeters,
    walkingMeters,
    walkingDuration,
    isSpotDetails,
}) => {
    const {
        duration,
        distance: distanceString,
    } = parseWalkingDistanceAndDuration({
        // TODO remove temporary fix once distance is replaced with linearMeters
        linearMeters:
            linearMeters ||
            distance ||
            (Number.isFinite(distanceInMiles) ? distanceInMiles * 1609 : 0),
        walkingMeters,
        walkingDuration,
        isSpotDetails,
    });

    return (
        <Flex
            className="WalkingDistanceDisplay"
            alignItems="center"
            data-testid="WalkingDistance"
        >
            <Icon
                as={IconWalkingMan}
                alt="Walking Man"
                h={5}
                w={5}
                color="gray.dark"
                paddingRight={0.5}
            />
            {duration && (
                <Text
                    as="span"
                    color="black"
                    paddingRight={1}
                    fontWeight="semibold"
                    fontSize="sm"
                    data-testid="WalkingDistance-Duration"
                    data-test-walking-duration={walkingDuration}
                >
                    {duration}
                </Text>
            )}
            <Text
                as="span"
                color="gray.dark"
                fontSize="sm"
                data-testid="WalkingDistance-Distance"
                data-test-walking-distance={walkingMeters}
            >
                ({distanceString})
            </Text>
        </Flex>
    );
};

WalkingDistanceDisplay.propTypes = {
    distance: PropTypes.number,
    distanceInMiles: PropTypes.number,
    linearMeters: PropTypes.number,
    walkingMeters: PropTypes.number,
    walkingDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isSpotDetails: PropTypes.bool,
};

export default WalkingDistanceDisplay;
