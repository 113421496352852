import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';

export default function trackAppleMapsViewNearbySpotsClicked({
    facility,
    location,
}) {
    try {
        SegmentUtils.track({
            event: 'Apple Maps View Nearby Spots Clicked',
            properties: {
                facility_id: facility?.id || null,
                location,
            },
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 1,
                    },
                },
            },
        });
    } catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage:
                'Segment Event Failed - trackAppleMapsViewNearbySpotsClicked',
        });
    }
}
