import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as UILink } from '@spothero/ui';
const Link = ({ className, forceAnchor, to, children, onClick, ...props }) => {
    if (forceAnchor || includes(to, 'http')) {
        return (<UILink className={className} href={to} onClick={onClick} {...props}>
                {children}
            </UILink>);
    }
    else {
        return (<UILink as={ReactRouterLink} to={to} className={className} onClick={onClick} {...props}>
                {children}
            </UILink>);
    }
};
Link.propTypes = {
    className: PropTypes.string,
    forceAnchor: PropTypes.bool,
    to: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClick: PropTypes.func,
};
export default Link;
