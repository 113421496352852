import { AutoSuggestSelect, Box, Button, Flex, Modal, ModalHeader, ModalBody, ModalFooter, Text, } from '@spothero/ui';
import React from 'react';
const AddVehicleModalContent = ({ getVehicles, handleVehicleChange, handleReset, isOpen, onClose, handleOnHidden, }) => {
    // Local temporary state
    const [newVehicle, setNewVehicle] = React.useState(null);
    return (<Modal title="Add Vehicle" onHidden={handleOnHidden} isOpen={isOpen} onClose={onClose} hasHeader hasFooter scrollBehavior="inside" contentStyling={{
            maxWidth: { base: '100%', mobileXL: '30%' },
            minWidth: { base: '100%', mobileXL: '332px' },
            minHeight: '480px',
        }}>
            <ModalHeader fontSize="md">Add Vehicle</ModalHeader>
            <ModalBody>
                <Flex flexGrow={1} data-testid="AddVehicleModalContent" flexDirection="column">
                    <Box pb={4}>
                        <Text variant="body">
                            Tell us what you need to park and we&apos;ll help
                            you find the best spot.
                        </Text>
                    </Box>
                    <Box data-testid="AddVehicleModalContent-autosuggest-vehicle" sx={{
            '& #vehicleMakeModel *': {
                maxHeight: '150px',
            },
        }}>
                        <AutoSuggestSelect id="vehicleMakeModel" label="Make and Model" isRequired getOptions={getVehicles} onChange={(v) => setNewVehicle(v?.vehicleData)} lineHeight="1.4" placeholder="Type to Search" iconSrc="/img/parking-pass/search.svg"/>
                        <Text variant="caption" color="text.secondary.light">
                            Example: Honda Civic
                        </Text>
                    </Box>
                </Flex>
            </ModalBody>
            <ModalFooter background="white">
                <Flex justifyContent="flex-end" gap={{ base: 2, mobileXL: 4 }}>
                    <Button variant="secondary" paddingX={{ base: 4, mobileXL: 8 }} onClick={handleReset} data-testid="AddVehicleModalContent-not-now">
                        Not Now
                    </Button>
                    <Button onClick={() => handleVehicleChange(newVehicle)} variant="primary" fontFamily="body" data-testid="AddVehicleModalContent-add-vehicle" paddingX={{ base: 4, mobileXL: 8 }}>
                        Add Vehicle
                    </Button>
                </Flex>
            </ModalFooter>
        </Modal>);
};
export default AddVehicleModalContent;
