export const formatPowerBookingItems = (bookingPeriods, selectedRate, showTotal = false) => {
    const priceBreakdownItems = selectedRate?.price_breakdown?.items || [];
    return priceBreakdownItems.map(item => {
        const bookingPeriodLength = bookingPeriods.length;
        const shortDescription = item.short_description === 'Subtotal'
            ? `${bookingPeriodLength} Day ${showTotal ? 'Total' : 'Subtotal'}`
            : item.short_description;
        let price = item.price;
        if (item.short_description === 'Subtotal') {
            price = selectedRate?.subTotal?.value;
        }
        if (item.short_description === 'Service Fee') {
            price = selectedRate.totalServiceFee;
        }
        return {
            ...item,
            short_description: shortDescription,
            price,
        };
    });
};
