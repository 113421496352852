import React from 'react';
import PropTypes from 'prop-types';
import { USER_DATA } from '@/proptypes/index';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withConfig from '@/config/withConfig';
import { resetSelectedSpot } from 'store/spot/spot-actions';
import { userLogout } from 'store/user/user-actions';
import Navigation from '../../navigation';
import { Box } from '@spothero/ui';
import { headerStyles } from '../headerStyles';
import { expandedData, collapsedData } from '../constants';
const HeaderDesktop = ({ isProduction, user, clearSelectedSpot, onUserLogout, }) => {
    return (<Box {...headerStyles} as="header" role="banner" data-testid="Header">
            <Navigation isProduction={isProduction} user={user} collapsedData={collapsedData} expandedData={expandedData} onItemSelected={clearSelectedSpot} onUserLogout={onUserLogout}/>
        </Box>);
};
HeaderDesktop.propTypes = {
    className: PropTypes.string,
    isProduction: PropTypes.bool,
    user: PropTypes.shape(USER_DATA).isRequired,
    clearSelectedSpot: PropTypes.func.isRequired,
    onUserLogout: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
    const { user: { data: userData }, } = state;
    return {
        user: userData,
    };
};
const mapDispatchToProps = {
    clearSelectedSpot: resetSelectedSpot,
    onUserLogout: userLogout,
};
const enhance = compose(withConfig(['isProduction']), connect(mapStateToProps, mapDispatchToProps));
export default enhance(HeaderDesktop);
