import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import has from 'lodash/has';
import map from 'lodash/map';
import dayjs from 'utils/dayjs-timezone';
import {getDocument} from 'ssr-window';
import StorageUtils from '@spothero/utils/storage';
import SearchUtils from 'utils/search-utils';
import SegmentUtils from 'utils/segment';
import UserUtils from 'utils/user-utils';
import getParkingType from '../utils/parking-type';
import ErrorUtils from 'utils/error-utils';
import SearchTracking from 'utils/search-tracking';

const document = getDocument();
const isOpenedFromMap = spotDetailReferrer => spotDetailReferrer === 'pin';

export default function trackSpotDetails({
    city,
    destination = null,
    event = null,
    isMonthly,
    starts,
    ends,
    spot,
    referrer,
    referrerAffiliate,
    spotsLeftDisplayed = false,
    spotDetailReferrer = null,
    rank,
}) {
    try {
        const {searchUUID, actionUUID} = SearchTracking.getValues();

        const {
            parking_spot_id: spotId,
            facility: {
                title,
                airport,
                amenities_full: facilityAmenities,
                operator_id: operatorId,
                cancellation_allowed: cancellationAllowed,
                tag,
                visual_flags: visualFlags,
            },
            selectedRate,
            distanceInMiles,
            drivingDuration,
            rating_info: ratingInfo,
            available_transient_inventory,
            available_monthly_inventory,
        } = spot;
        const isAirport = !isNil(airport);
        const isEvent = !isEmpty(event);
        const parkingType = getParkingType({
            isMonthly,
            isAirport,
            isEvent,
        });
        const price = selectedRate
            ? (selectedRate.price / 100).toString()
            : undefined;
        const currency = selectedRate
            ? selectedRate.currency_type
            : UserUtils.CURRENCY_TYPES.USD.toLowerCase();
        const startsTime =
            selectedRate && selectedRate.starts
                ? dayjs(selectedRate.starts)
                : dayjs(starts);
        const endsTime =
            selectedRate && selectedRate.ends
                ? dayjs(selectedRate.ends)
                : isMonthly
                ? startsTime.clone().add(1, 'month')
                : dayjs(ends);
        const amenities = selectedRate
            ? selectedRate.amenities
            : facilityAmenities;
        const reservationDuration = parseInt(
            dayjs.duration(endsTime.diff(startsTime)).format('m'),
            10
        );
        const tzDiff =
            dayjs().tz(city.timezone).utcOffset() - dayjs().utcOffset();
        const timeUntilReservationStart =
            parseInt(dayjs.duration(startsTime.diff(dayjs())).format('m'), 10) -
            tzDiff;
        const fromScreen = SearchUtils.getReferrerDetails({
            referrer,
        });
        const spotMeta = StorageUtils.get(SearchUtils.SEGMENT_SPOT_META);
        const taxBreakdown = get(selectedRate, 'price_breakdown.items');
        const availableSpaces =
            available_transient_inventory || available_monthly_inventory || 0;
        let sortType;
        let spotRank;

        if (spotMeta) {
            const {metaRank, sortBy: sortByMeta} = spotMeta;

            spotRank = metaRank;
            sortType = sortByMeta;

            StorageUtils.remove(SearchUtils.SEGMENT_SPOT_META);
        }

        const properties = {
            'parking type': parkingType, // MARKED FOR DEPRECATION: parking_type will be used moving forward
            'spot id': spotId, // MARKED FOR DEPRECATION: parking_spot_id will be used moving forward
            'reservation duration': reservationDuration, // MARKED FOR DEPRECATION: reservation_duration will be used moving forward
            'time until reservation start': timeUntilReservationStart, // MARKED FOR DEPRECATION: time_until_reservation_start will be used moving forward
            'from screen': fromScreen, // MARKED FOR DEPRECATION
            'referrer affiliate': referrerAffiliate, // MARKED FOR DEPRECATION: referrer_affiliate will be used moving forward
            referrer: referrer || document.referrer,
            reservation_duration: reservationDuration,
            time_until_reservation_start: timeUntilReservationStart,
            referrer_affiliate: referrerAffiliate,
            currency,
            price,
            parking_type: parkingType,
            opened_from_map: isOpenedFromMap(spotDetailReferrer),
            city: city.slug,
            state: city.state,
            google_places_name: get(
                destination,
                'google_places.0.google_places_name'
            ),
            google_places_place_id: get(
                destination,
                'google_places.0.google_places_place_id'
            ),
            operator_id: operatorId,
            parking_spot_id: spotId,
            parking_spot_name: title,
            spot_rank: spotRank,
            travel_distance: isNumber(distanceInMiles)
                ? distanceInMiles * 1609
                : undefined,
            travel_distance_type: isNil(drivingDuration)
                ? 'walking'
                : 'driving',
            star_rating: get(ratingInfo, 'star_rating'),
            amenities: amenities.map(({name}) => name),
            free_cancellation: cancellationAllowed !== 'no',
            taxes_fees: taxBreakdown
                ? taxBreakdown.map(({type, price: itemPrice}) => ({
                      type,
                      price: itemPrice,
                  }))
                : undefined,
            sort_type: sortType,
            available_spaces: availableSpaces,
            spots_left_displayed: Boolean(spotsLeftDisplayed),
            visual_flags: visualFlags,
            ...(tag && {tag}),
            ...(destination &&
                !isEmpty(destination) && {
                    destination_id: destination.id,
                    destination_name: destination.title,
                }),
            ...(isEvent && {
                event_id: event.id,
                event_name: event.title,
            }),
            ...(isAirport && {
                airport_code: airport.details.code,
                banner_details: !isNil(tag) ? tag : undefined,
                shuttle_available: Boolean(get(airport, 'shuttle.duration')),
                shuttle_min_frequency: get(airport, 'shuttle.slow_frequency'),
                shuttle_max_frequency: get(airport, 'shuttle.fast_frequency'),
                shuttle_duration: get(airport, 'shuttle.duration'),
                shuttle_24_7: Boolean(airport.shuttle.hours.text.length),
                shuttle_hours: airport.shuttle.hours.periods,
            }),
            ...(rank ? {rank} : {}),
            search_id: searchUUID,
            action_id: actionUUID,
        };

        SegmentUtils.page({
            name: 'Spot Details',
            properties,
        });
    } catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - Track Spot Details',
        });
    }
}

export function trackEventPackageSpotDetails({
    eventPackage,
    destination,
    spot,
    spotsLeftDisplayed,
    spotDetailReferrer,
}) {
    const {
        spotId,
        title,
        selectedRate,
        rating,
        city,
        state,
        operatorId,
        cancellationAllowed,
        distance: {walkingDistanceInMiles},
        available_transient_inventory,
        available_monthly_inventory,
    } = spot;

    const price = selectedRate
        ? (selectedRate.totalPrice.value / 100).toString()
        : undefined;
    const currency = selectedRate
        ? selectedRate.totalPrice.currencyCode
        : UserUtils.CURRENCY_TYPES.USD.toLowerCase();
    const spotMeta = StorageUtils.get(SearchUtils.SEGMENT_SPOT_META);
    const availableSpaces =
        available_transient_inventory || available_monthly_inventory || 0;
    let sortType;
    let spotRank;

    if (spotMeta) {
        const {rank, sortBy: sortByMeta} = spotMeta;

        spotRank = rank;
        sortType = sortByMeta;

        StorageUtils.remove(SearchUtils.SEGMENT_SPOT_META);
    }

    // Aggregate price breakdowns for each event
    const totalPriceBreakdown = spot.eventPackage.events.reduce(
        (accmPriceBreakdown, event) => {
            event.price.priceBreakdown.forEach(breakdownItem => {
                if (has(accmPriceBreakdown, breakdownItem.type)) {
                    accmPriceBreakdown[breakdownItem.type] +=
                        breakdownItem.price;
                } else {
                    accmPriceBreakdown[breakdownItem.type] =
                        breakdownItem.price;
                }
            });

            return accmPriceBreakdown;
        },
        {}
    );

    const properties = {
        'parking type': 'event_package', // MARKED FOR DEPRECATION: parking_type will be used moving forward
        'spot id': spotId, // MARKED FOR DEPRECATION: parking_spot_id will be used moving forward
        amenities: selectedRate.amenities.map(({displayName}) => displayName),
        available_spaces: availableSpaces,
        city,
        currency,
        opened_from_map: isOpenedFromMap(spotDetailReferrer),
        destination_id: destination.id,
        destination_name: destination.title,
        free_cancellation: cancellationAllowed !== 'no',
        operator_id: operatorId,
        package_id: eventPackage.id,
        package_name: eventPackage.title,
        parking_spot_id: spotId,
        parking_spot_name: title,
        parking_type: 'event_package',
        price,
        sort_type: sortType,
        spot_rank: spotRank,
        spots_left_displayed: Boolean(spotsLeftDisplayed),
        star_rating: rating.average,
        state,
        travel_distance: isNumber(walkingDistanceInMiles)
            ? walkingDistanceInMiles * 1609
            : undefined,
        travel_distance_type: 'walking',
        taxes_fees: map(totalPriceBreakdown, (itemPrice, type) => ({
            type,
            price: itemPrice,
        })),
    };

    SegmentUtils.page({
        name: 'Spot Details',
        properties,
    });
}
