import ErrorUtils from 'utils/error-utils';
/**
 * Optimizely customErrorHandler
 *
 * Object that has a property `handleError` which will be called
 * when an error is thrown in the SDK.
 */
export const customErrorHandler = {
    handleError(error) {
        ErrorUtils.sendSentryException(error);
    },
};
