import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconStar from '@spothero/icons/star-filled';
import IconStarHalf from '@spothero/icons/star-half';

const StarRatingStar = ({
    hasForegroundStar,
    foregroundStarHalf,
    foregroundStarFilled,
}) => {
    const Icon = foregroundStarHalf ? IconStarHalf : IconStar;
    const foregroundClasses = classNames(
        {'Icon-star-half': foregroundStarHalf},
        {'StarRating-Star-filled': foregroundStarFilled}
    );

    return (
        <div className="StarRating-Star">
            {(!hasForegroundStar || foregroundStarHalf) && <IconStar />}
            {hasForegroundStar && <Icon className={foregroundClasses} />}
        </div>
    );
};

StarRatingStar.propTypes = {
    hasForegroundStar: PropTypes.bool.isRequired,
    foregroundStarHalf: PropTypes.bool.isRequired,
    foregroundStarFilled: PropTypes.bool.isRequired,
};

export default StarRatingStar;
