import DestinationAPI from 'api/destination';
import EventsAPI from 'api/events';
import {getAirportFacilities} from 'api/search-v2/airport/spot/getAirportFacilities';

export const DESTINATION_LOAD_DATA = 'DESTINATION_LOAD_DATA';
export const DESTINATION_LOAD_EVENTS = 'DESTINATION_LOAD_EVENTS';
export const DESTINATION_UPDATE = 'DESTINATION_UPDATE';
export const DESTINATION_RESET = 'DESTINATION_RESET';
export const DESTINATION_GET_AIRPORT_SPOTS = 'DESTINATION_GET_AIRPORT_SPOTS';

export const destinationLoadData = id => {
    return {
        type: DESTINATION_LOAD_DATA,
        payload: DestinationAPI.get(id),
    };
};

export const destinationLoadEvents = ({destinationId, eventTypes, starts}) => {
    return {
        type: DESTINATION_LOAD_EVENTS,
        payload: EventsAPI.get({
            destinationId,
            eventTypes,
            starts,
        }),
    };
};

export const destinationUpdate = data => {
    return {
        type: DESTINATION_UPDATE,
        payload: data,
    };
};

export const destinationReset = () => {
    return {
        type: DESTINATION_RESET,
    };
};

export const getAirportSpots = iataCode => {
    return dispatch => {
        return dispatch({
            type: DESTINATION_GET_AIRPORT_SPOTS,
            payload: getAirportFacilities({
                iata: iataCode,
            }).then(response => {
                return {
                    results: response,
                };
            }),
        });
    };
};
