// @ts-migration: I tried to convert this but the way the dispatch is being caught as a promise when it isn't expected as a promise makes it tough to type

import * as SmsPermissionsAPI from 'api/sms-permissions';
import APIUtils from '@spothero/utils/api';
export const CHECKOUT_SMS_PERMISSION = 'CHECKOUT_SMS_PERMISSION';
export const CHECKOUT_UPDATE_SMS_PERMISSION = 'CHECKOUT_UPDATE_SMS_PERMISSION';
export const RESET_SMS_PERMISSIONS = 'RESET_SMS_PERMISSIONS';

export const getSMSPermissions = phoneNumber => dispatch => {
    if (!phoneNumber) {
        return {};
    }

    const permissionsLoader = APIUtils.makeCancelable(
        SmsPermissionsAPI.getSMSPermissions({
            phoneNumber,
        })
    );

    dispatch({
        type: CHECKOUT_SMS_PERMISSION,
        payload: permissionsLoader,
    }).catch(() => {}); // eslint-disable-line no-empty-function

    return permissionsLoader;
};

export const updateSMSPermissions = ({
    phoneNumber,
    smsPermissions,
}) => dispatch => {
    if (!phoneNumber) {
        return {};
    }

    return dispatch({
        type: CHECKOUT_UPDATE_SMS_PERMISSION,
        payload: SmsPermissionsAPI.updateSMSPermissions({
            phoneNumber,
            smsPermissions,
        }),
    })
        .then(({value}) => {
            if (!value || !value.id) {
                return {
                    success: false,
                };
            }

            return {success: true};
        })
        .catch(() => {
            return {
                success: false,
            };
        });
};

export const resetSmsPermissions = () => ({
    type: RESET_SMS_PERMISSIONS,
});
