import ObjectUtils from '@spothero/utils/object';
import { handleCancellation, handleIllustrations, } from 'api/search-v2/utils/format-v2-to-v1-common';
import { FACILITY_TYPES } from 'utils/types/facility';
import { formatDateTime } from 'utils/format-date-time';
import { buildAirportSpotDetailsURL, buildCheckoutURL, formatAddressTypes, } from 'api/search-v2/utils';
import FormatUtils from 'utils/format';
import { formatHoursOfOperationToV1 } from './formatHoursOfOperationToV1';
import { formatTransportationType } from './formatTransportationType';
import { formatFacilityRestrictions } from './formatFacilityRestrictions';
import { formatAirportRedemptionInstructions } from './formatAirportRedemptionInstructions';
// Below is a bit odd. It ends up being kind of a mix between our v2 formatted objects and v1.
// Ideally, we update transformV2SingleFacilityToV1RatesAirport and formatAirportFacility to get all
// of the required airport-y things from this that they are missing then kill this and getAiportFacilityV2
// And transition everything over to getAiportFacilityV2ThenConvert, which follows the pattern of the other v2 endpoints
/**
 * Formats data for a single airport facility
 *
 * @function formatAirportFacilityV2Response
 * @see {@link https://api.spothero.com/v2/docs#tag/Search/operation/searchAirportFacilityParking|Documentation}
 * @param {object} v2Response - All the data returned from the search API for a single facility
 * @returns {object} The airport facility, with data transformation applied.
 */
export const formatAirportFacilityV2Response = (v2Response) => {
    const { airport, result: { distance, availability, facility, rates, vehicle }, } = v2Response;
    const addresses = Array.isArray(facility?.common?.addresses)
        ? facility.common.addresses
        : [];
    const airportRate = rates?.length ? rates[0] : null;
    const order = airportRate?.quote?.order.length
        ? airportRate?.quote?.order[0]
        : null;
    const shuttleHoursOfOperation = formatHoursOfOperationToV1(facility.airport?.transportation?.hours_of_operation);
    const facilityHoursOfOperation = formatHoursOfOperationToV1(facility?.common?.hours_of_operation, true);
    const formattedLogo = facility?.airport?.logo?.url
        ? handleIllustrations(facility?.airport?.logo?.url) || []
        : [];
    const airportLogo = {
        id: formattedLogo.length > 2 ? formattedLogo[2] : '',
        src: facility?.airport?.logo?.url,
        alt: facility?.airport?.logo?.alt_text,
        version: formattedLogo[1] || '',
        center_x: 375,
        center_y: 250,
        order: '',
    };
    const transformedResp = {
        data: {
            isV2: true,
            availability,
            parking_spot_id: Number(facility?.common?.id),
            title: facility?.common?.title,
            distance: distance?.linear_meters,
            latitude: addresses[0]?.latitude,
            longitude: addresses[0]?.longitude,
            default_image_url: facility?.common?.images?.length
                ? facility?.common?.images[0]?.url
                : '',
            online_commuter_rate: false,
            online_commuter_rate_description: null,
            online_commuter_rate_enter_start: null,
            online_commuter_rate_enter_end: null,
            hourly_rates: [
                {
                    rule_type: FACILITY_TYPES.RULE_TYPES.MULTIRATE,
                    title: 'Airport',
                    rule_id: '',
                    price: airportRate?.quote?.total_price?.value,
                    display_price: airportRate?.quote?.advertised_price?.value,
                    unavailable: !availability?.available,
                    unavailable_reason: availability?.unavailable_reasons
                        ?.length
                        ? availability?.unavailable_reasons[0]
                        : '',
                    rule_group_id: Number(order?.rate_id),
                    event_id: null,
                    rule_trail: '',
                    starts: formatDateTime(String(order?.starts), addresses[0]?.time_zone || ''),
                    ends: formatDateTime(String(order?.ends), addresses[0]?.time_zone || ''),
                    url: buildCheckoutURL({
                        spotId: facility?.common?.id,
                        facilitySlug: facility?.common?.slug,
                        starts: order?.starts,
                        ends: order?.ends,
                        timezone: addresses[0]?.time_zone,
                        rateType: 'airport',
                    }),
                    duration: '',
                    consumer_breakdown: {
                        // Not used
                        days: '',
                        price_per_day: airportRate?.quote?.advertised_price?.value,
                        total_day_price: null,
                        total_price: order?.items?.length
                            ? order?.items[0]?.price?.value
                            : null,
                        partial_day_price: null,
                        taxes_and_fees: null, // Not present in V2 response. Not used
                    },
                    currency_type: airportRate?.quote?.total_price?.currency_code?.toLowerCase() ||
                        '',
                    currency_symbol: FormatUtils.currencySymbol(airportRate?.quote?.total_price?.currency_code?.toLowerCase()),
                    price_breakdown: {
                        items: order?.items?.map(item => ({
                            price: item?.price?.value,
                            type: item?.type,
                            short_description: item?.short_description,
                            full_description: item?.full_description,
                        })),
                        currency: airportRate?.quote?.total_price?.currency_code?.toLowerCase(),
                        total_price: airportRate?.quote?.total_price?.value,
                    },
                    amenities: airportRate?.airport?.amenities?.map(amenity => ({
                        slug: amenity?.type,
                        name: amenity?.display_name,
                        visible: true,
                        sort_order: '',
                        icon_url: '', // Not present in V2 response
                    })) || [],
                    parking_type: airportRate?.airport?.redemption_type,
                },
            ],
            license_plate_required: facility?.common?.requirements?.license_plate,
            phone_number_required: facility?.common?.requirements?.phone_number,
            timezone: addresses[0]?.time_zone,
            wd_latitude: addresses[0]?.latitude,
            wd_longitude: addresses[0]?.longitude,
            currency_type: airportRate?.quote?.total_price?.currency_code?.toLowerCase() ||
                '',
            currency_symbol: FormatUtils.currencySymbol(airportRate?.quote?.total_price?.currency_code?.toLowerCase()),
            rating_info: {
                number_of_ratings: facility?.common?.rating?.count,
                star_rating: facility?.common?.rating?.average,
            },
            facility: {
                id: Number(facility?.common?.id),
                parking_spot_id: Number(facility?.common?.id),
                title: facility?.common?.title,
                description: '',
                status: facility?.common?.status,
                slug: facility?.common?.slug,
                street_address: addresses[0]?.street_address,
                city: addresses[0]?.city,
                state: addresses[0]?.state,
                zipcode: addresses[0]?.postal_code,
                latitude: addresses[0]?.latitude,
                longitude: addresses[0]?.longitude,
                timezone: addresses[0]?.time_zone,
                country: addresses[0]?.country,
                company_id: null,
                display_price_on_receipt: '',
                height_restriction: facility?.common?.clearance_inches,
                height_restriction_description: '',
                barcode_type: facility?.common?.barcode_type,
                post_purchase_instructions: '',
                restrictions: formatFacilityRestrictions(airportRate?.airport?.amenities, facility?.common?.restrictions),
                hours_of_operation: facilityHoursOfOperation,
                getting_here: facility?.common?.navigation_tip,
                addresses: addresses?.map(address => ({
                    ...address,
                    zipcode: address?.postal_code,
                    types: formatAddressTypes(address?.types),
                    id: Number(address?.id),
                })),
                parking_type: airportRate?.airport?.redemption_type,
                redemption_instructions: {
                    arrival: formatAirportRedemptionInstructions(facility?.airport?.redemption_instructions?.arrival),
                    departure: formatAirportRedemptionInstructions(facility?.airport?.redemption_instructions?.departure),
                },
                mobile_enabled: null,
                parking_pass_type: null,
                support_description: '',
                facility_type: {
                    slug: facility?.common?.facility_type,
                    display_name: '', // Not present in V2 response. Not used in airport.
                },
                cancellation_allowed: handleCancellation({
                    allowedByCustomer: facility?.common?.cancellation?.allowed_by_customer ||
                        false,
                    allowedBySpotheroCustomerService: facility?.common?.cancellation
                        ?.allowed_by_spothero_customer_service || false,
                }),
                cancellation_minutes: facility?.common?.cancellation?.minutes,
                is_scan_to_pay: facility?.common?.is_scan_to_pay,
                visual_flags: facility?.common?.visual_flags,
                operator_display_name: facility?.common?.operator_display_name,
                amenities_full: airportRate?.airport?.amenities?.map(amenity => ({
                    slug: amenity?.type,
                    name: amenity?.display_name,
                    visible: true,
                    sort_order: '',
                    icon_url: '', // Not present in V2 response
                })) || [],
                images: facility?.common?.images?.map(image => ({
                    id: image.id,
                    url_template: image.url,
                    alt: image.alt_text,
                    version: '',
                    center_x: '',
                    center_y: '',
                    order: '', // Not present in V2 response.
                })),
                facility_url: '',
                rating_info: {
                    number_of_ratings: facility?.common?.rating?.count,
                    star_rating: facility?.common?.rating?.average,
                },
                vehicle_size: '',
                oversize_vehicle_type: '',
                oversize_description: '',
                oversize_fees_charged_onsite: facility?.common?.oversize_fees_charged_onsite,
                transient_available: facility?.common?.parking_types?.includes('transient'),
                monthly_available: facility?.common?.parking_types?.includes('monthly'),
                vehicle_restriction_description: '',
                supported_fee_types: facility?.common?.supported_fee_types,
                operator_id: facility?.common?.operator_id,
                reservationExtensionEnabled: facility?.common?.reservation_extension_enabled,
                // @ts-expect-error - Signal to craig team this isn't coming as an option from docs
                freePark: facility?.common?.require_credit_card === false,
                airport: {
                    shuttle: {
                        contact_phone_number: facility?.airport?.transportation
                            ?.contact_phone_number,
                        duration: facility?.airport?.transportation?.schedule
                            ?.duration,
                        fast_frequency: facility?.airport?.transportation?.schedule
                            ?.fast_frequency,
                        hours: {
                            ...shuttleHoursOfOperation,
                            periods: shuttleHoursOfOperation?.periods?.map(period => ({
                                start_dow: period?.start_dow,
                                start_dow_int: period?.start_dow_int,
                                start_time: period?.start_time,
                                end_dow: period?.end_dow,
                                end_dow_int: period?.end_dow_int,
                                end_time: period?.end_time,
                            })),
                        },
                        slow_frequency: facility?.airport?.transportation?.schedule
                            ?.slow_frequency,
                        images: facility?.airport?.transportation?.images?.map(image => ({
                            id: image.id,
                            url_template: image.url,
                            alt: image.alt_text,
                            version: '',
                            center_x: '',
                            center_y: '',
                            order: '', // Not present in V2 response.
                        })),
                        type: formatTransportationType(facility?.airport?.transportation?.type),
                    },
                    redemption_instructions: {
                        arrival: formatAirportRedemptionInstructions(facility?.airport?.redemption_instructions?.arrival),
                        departure: formatAirportRedemptionInstructions(facility?.airport?.redemption_instructions
                            ?.departure),
                    },
                    details: {
                        latitude: airport?.latitude,
                        longitude: airport?.longitude,
                        title: airport?.short_name,
                        url: '',
                        spot_type: '',
                        code: airport?.code,
                        name: airport?.full_name,
                    },
                    lowest_daily_rate: airportRate?.airport?.lowest_daily_rate?.value,
                    currency_type: airportRate?.airport?.lowest_daily_rate?.currency_code.toLowerCase() ||
                        '',
                    currency_symbol: FormatUtils.currencySymbol(airportRate?.airport?.lowest_daily_rate?.currency_code.toLowerCase()),
                },
                logos: [airportLogo],
                distance: '',
                relative_url: `/checkout/${facility?.common?.id}/${facility?.common?.slug}`,
                spot_details_url: buildAirportSpotDetailsURL({
                    spotId: facility?.common?.id,
                    facilitySlug: facility?.common?.slug,
                    starts: String(order?.starts),
                    ends: String(order?.ends),
                    timezone: String(addresses[0]?.time_zone),
                }),
                available_transient_inventory: availability?.available_spaces,
                tag: airportRate?.airport?.tag,
                order: airportRate?.quote?.order,
            },
            comparisons: airportRate?.airport?.comparisons,
            facilityVehicle: ObjectUtils.camelizeKeys(vehicle),
        },
    };
    return { data: transformedResp };
};
