import React, {useEffect} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {compose} from 'redux';
import SpotListItemPlaceholder from './spot-card-placeholder';
import SpotListNoResults from './no-results-message';
import {updateSelected} from 'store/spot/spot-actions';
import {resetFilters} from 'store/filters/filters-actions';
import {canFetchLoadMoreSpots as canFetchLoadMoreSpotsSelector} from 'store/spots/spots-selectors';
import {Grid, Button} from '@spothero/ui';
import {setSortType} from 'store/search/search-actions';
import MonthlyLowInventory from './monthly-low-inventory';
import {setTerm} from 'store/search-request/search-request-actions';
import trackSearchHourlyFromMonthlyClicked from 'segment/events/search-hourly-from-monthly';
import {getParkingType} from 'store/selectors/selectors';
import {
    setPreviewedSpotId,
    setMobileMapViewInteractionTrigger,
} from 'store/spots/spots-actions';
import useIsMobile from 'hooks/use-is-mobile';
import {SearchPageView} from 'utils/search-page-view';
import {
    OPTIMIZELY_FEATURE_ON,
    useFeatureVariation,
} from 'plugins/optimizely/hooks/use-feature-variation';
import {IMMEDIATE_FEATURES} from 'utils/experiment';
import {PreSpots} from './pre-spots/PreSpots';

const renderPlaceholders = (count = 5) => {
    const placeholders = [];
    let i = 0;

    for (i; i < count; i++) {
        placeholders.push(<SpotListItemPlaceholder key={i} />);
    }

    return placeholders;
};

export const SpotList = ({
    spots,
    numActiveFilters,
    showListLoader,
    showLoadingComplete,
    loadNextPage,
    onResetFiltersClick,
    updateSelectedSpot,
    isRebookingReservation,
    isAdmin,
    sortBy,
    selectedSpotId,
    showNoSpotsFound,
    onShowDetailsClick,
    VerticalPromo,
    SpotListItem,
    canFetchLoadMoreSpots,
    filters,
    showSpotListSorter,
    isSearchPending,
    onChangeSort,
    unfilteredSpotsData,
    city,
    destination,
    searchRequest,
    highlightedSpotId,
    searchPageView,
    showMobileToggle = true,
}) => {
    const isMobile = useIsMobile();
    const spotsRef = React.useRef(null);
    const rootState = useSelector(state => state);
    const dispatch = useDispatch();
    const isUserInExpressCheckoutExperiment =
        useFeatureVariation(IMMEDIATE_FEATURES.EXPRESS_CHECKOUT_WEB) ===
        OPTIMIZELY_FEATURE_ON;
    // Note: We can not use showNoSpotsFound as it is based on the API response, not the number of filtered spots.
    const areNoResultsMatchingTheFilters =
        // active filters exist
        numActiveFilters > 0 &&
        // no spots after filtering
        spots.length < 1 &&
        // API request is complete
        !isSearchPending;

    // empty spot place holders are shown while search api request happens first time on page load.
    // place holders are UI indicator (similar to spinners/progress bars) that search is in progress.
    const placeholders =
        isSearchPending &&
        !showNoSpotsFound &&
        spots.length < 1 &&
        renderPlaceholders();
    const parkingType = getParkingType(rootState);

    const handleHourlyParkingClick = () => {
        const segmentEventData = {
            city,
            unfilteredSpotsData,
            searchRequest,
            destination,
        };

        trackSearchHourlyFromMonthlyClicked(segmentEventData);

        dispatch(
            setTerm({
                term: 'daily',
            })
        );
    };

    const {monthly: isMonthly} = searchRequest;
    const spotsCount = unfilteredSpotsData?.length;

    const showMonthlyLowInventory = isMonthly
        ? spotsCount <= 5 && spotsCount > 0 && !isSearchPending
        : false;
    const classes = classNames('SpotList', `parking-type-${parkingType}`);

    useEffect(() => {
        const elementRef = spotsRef.current;
        const handleInteraction = () => {
            dispatch(setMobileMapViewInteractionTrigger({trigger: 'scroll'}));
        };

        if (spotsRef.current) {
            spotsRef.current.addEventListener('touchstart', handleInteraction);
        }

        return () => {
            if (elementRef) {
                elementRef.removeEventListener('touchstart', handleInteraction);
            }
        };
    }, [dispatch]);

    useEffect(() => {
        if (isMobile && searchPageView === SearchPageView.MOBILE_LIST) {
            const spotId = spots[0]?.spotId || '';

            dispatch(setPreviewedSpotId({spotId}));
        }
    }, [spots, isMobile, dispatch, searchPageView]);

    return (
        <div className={classes}>
            <PreSpots
                showSpotListSorter={showSpotListSorter}
                onChangeSort={onChangeSort}
                showMobileToggle={showMobileToggle}
                VerticalPromo={VerticalPromo}
            />
            <Grid
                ref={spotsRef}
                className="SpotList-spots"
                gridTemplateColumns="minmax(1em, 1fr)"
                gridGap="0"
                gridAutoRows="1fr"
                paddingX={
                    isMobile && searchPageView === SearchPageView.MOBILE_MAP
                        ? '3'
                        : '0'
                }
            >
                {placeholders}
                {filters ? filters : null}
                {(!isMobile || searchPageView === SearchPageView.MOBILE_LIST) &&
                    (showNoSpotsFound || areNoResultsMatchingTheFilters) && (
                        <SpotListNoResults
                            numFilters={numActiveFilters}
                            onResetFiltersClick={onResetFiltersClick}
                        />
                    )}
                {spots.map(spot => {
                    const isActive = Number(spot.spotId) === selectedSpotId;
                    const spotId = spot.spotId;
                    const isHighlighted = spotId === highlightedSpotId;

                    return (
                        <SpotListItem
                            key={`${spotId}-${isActive}-${isHighlighted}`}
                            spot={spot}
                            isMobile={isMobile}
                            isActive={isActive}
                            isHighlighted={isHighlighted}
                            isRebookingReservation={isRebookingReservation}
                            isAdmin={isAdmin}
                            updateSelectedSpot={updateSelectedSpot}
                            onShowDetailsClick={onShowDetailsClick}
                            inExpressExperiment={
                                isUserInExpressCheckoutExperiment
                            }
                        />
                    );
                })}
            </Grid>
            {showMonthlyLowInventory && (
                <MonthlyLowInventory
                    onNavigateToHourlyClick={handleHourlyParkingClick}
                />
            )}
            {isMobile && (
                <>
                    {placeholders}
                    {!showLoadingComplete && canFetchLoadMoreSpots && (
                        <div className="SpotList-additional-actions">
                            <Button
                                variant="primary"
                                width="100%"
                                fontSize="sm"
                                isLoading={showListLoader}
                                onClick={loadNextPage}
                                data-testid="SpotList-load-more-button"
                            >
                                {sortBy === 'price'
                                    ? 'Load More and Re-Sort'
                                    : 'Load More Spots'}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

SpotList.propTypes = {
    isSearchPending: PropTypes.bool,
    showSpotListSorter: PropTypes.bool,
    numActiveFilters: PropTypes.number,
    showListLoader: PropTypes.bool,
    showLoadingComplete: PropTypes.bool,
    spots: PropTypes.arrayOf(PropTypes.object),
    loadNextPage: PropTypes.func,
    onResetFiltersClick: PropTypes.func,
    updateSelectedSpot: PropTypes.func.isRequired,
    isRebookingReservation: PropTypes.bool,
    isAdmin: PropTypes.bool,
    sortBy: PropTypes.string.isRequired,
    selectedSpotId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // V1 uses number; V2 uses string
    showNoSpotsFound: PropTypes.bool,
    onShowDetailsClick: PropTypes.func,
    onChangeSort: PropTypes.func,
    VerticalPromo: PropTypes.node,
    SpotListItem: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    filters: PropTypes.func,
    canFetchLoadMoreSpots: PropTypes.bool,
    unfilteredSpotsData: PropTypes.arrayOf(PropTypes.object),
    city: PropTypes.object,
    history: PropTypes.shape({
        location: PropTypes.object,
    }),
    destination: PropTypes.object,
    searchRequest: PropTypes.object,
    highlightedSpotId: PropTypes.string,
    searchPageView: PropTypes.string,
    showMobileToggle: PropTypes.bool,
};

SpotList.defaultProps = {
    showMonthlyDetour: false,
    showMobileToggle: true,
};

const mapStateToProps = state => {
    const {
        search: {
            isPending: isSearchPending,
            data: {sortBy, showNoSpotsFound, view: searchPageView},
        },
        checkout: {isRebookingReservation},
        filters: {numActive: numActiveFilters},
        user: {
            data: {isAdmin},
        },
        spot: {selected: selectedSpot},
        searchRequest,
        spots: {data: unfilteredSpotsData, highlightedSpotId},
        city: {data: city},
        destination: {data: destination},
    } = state;

    return {
        numActiveFilters,
        sortBy,
        isRebookingReservation,
        isAdmin,
        selectedSpot,
        showNoSpotsFound,
        searchPageView,
        isSearchPending,
        highlightedSpotId,
        canFetchLoadMoreSpots: canFetchLoadMoreSpotsSelector(state),
        searchRequest,
        unfilteredSpotsData,
        city,
        destination,
    };
};

const mapDispatchToProps = {
    onResetFiltersClick: resetFilters,
    updateSelectedSpot: updateSelected,
    onChangeSort: setSortType,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(SpotList);
