import {useEffect, useRef} from 'react';

/**
 * Used to hold onto previous value of prop or state
 *
 * @function usePrevious
 * @param {any} value - data to track previous value of
 * @returns {any} - previous value of that piece of data
 */
export default function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
