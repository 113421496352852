import React from 'react';
import { Box } from '@spothero/ui';
import { EventInfo } from './EventLineItem';
export const GenericLineItem = ({ children, topText, bottomText, isSelected, styleOverride = {}, }) => (<Box display="flex" justifyContent="flex-start" alignItems="center" width="90%" // Dont love this but it was too support the left chevron for events view - bottom makes it so if its only thing it goes 100%
 _last={{ width: '100%' }} paddingY={{ base: '1', tablet: '0' }} paddingLeft={{ base: 0, mobileXL: 2 }} marginBottom="1" gap={2} _hover={children
        ? {
            '& .event-date': {
                transition: 'transform 0.1s ease-in',
                transform: 'scale(1)',
            },
        }
        : undefined} {...styleOverride}>
        {children}
        <EventInfo topText={topText} bottomText={bottomText} showDate={Boolean(children)} isSelected={isSelected}/>
    </Box>);
