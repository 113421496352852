import SegmentUtils from 'utils/segment';
/**
 * ### trackSearchAsIMoveButtonClicked
 * Sends event 'Search As I Move Button Clicked' to Segment.
 *
 * @function trackSearchAsIMoveButtonClicked
 * @param {string} actionType
 * - Action type can be one of three options "CHECKED", "UNCHECKED", or "SEARCH_AREA".
 * @returns {void}
 */
export default function trackSearchAsIMoveButtonClicked({ actionType, }) {
    const properties = {
        action_type: actionType,
    };
    SegmentUtils.track({
        event: 'Search As I Move Button Clicked',
        properties,
        additionalOptions: {
            context: {
                protocols: {
                    event_version: 1,
                },
            },
        },
    });
}
