import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'common/image';

const imgWidth = 200;
const imgHeight = 141;
const imgURL = `https://res.cloudinary.com/spothero/image/upload/c_fill,dpr_2.0,h_${imgHeight},w_${imgWidth},q_50,f_auto/`;

const HowToRedeem = ({sectionTitle, instructions, displayImages = true}) => {
    return (
        <div
            className="SpotDetails-section HowToRedeem"
            data-testid="HowToRedeem"
        >
            <h4 className="subtitle">{sectionTitle}</h4>
            <ol>
                {instructions.map((instruction, i) => {
                    const classes = classNames(
                        {
                            'no-illustration':
                                !instruction.illustration_id || !displayImages,
                        },
                        {'no-number': instructions.length < 2}
                    );
                    const key = `${instruction?.illustration_id}-${i}`;
                    const imageUrl =
                        instruction?.illustration?.url ||
                        `${imgURL}${instruction.illustration_id}`;

                    return (
                        <li className={classes} key={key}>
                            <div className="RedemptionInstruction">
                                <div className="RedemptionInstructionRow">
                                    <div className="RedemptionInstructionIllustration">
                                        {instruction.illustration_id &&
                                            displayImages && (
                                                <Image
                                                    width={imgWidth}
                                                    height={imgHeight}
                                                    src={imageUrl}
                                                />
                                            )}
                                    </div>
                                    <div className="RedemptionInstructionText">
                                        <span
                                            className="RedemptionInstructionText-content"
                                            dangerouslySetInnerHTML={{
                                                __html: instruction.text.replace(
                                                    '\n',
                                                    '<br>'
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

HowToRedeem.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    displayImages: PropTypes.bool,
    instructions: PropTypes.arrayOf(
        PropTypes.shape({
            illustration_id: PropTypes.string, // eslint-disable-line camelcase
            illustration_version: PropTypes.string, // eslint-disable-line camelcase
            text: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            position: PropTypes.number,
        })
    ),
};

export default HowToRedeem;
