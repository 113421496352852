import includes from 'lodash/includes';
import React from 'react';
import makeLoadable from '@loadable/component';
import RouterLoader from './RouterLoader';
import {matchRoutes} from 'react-router-config';
import Home from 'pages/home';
import Search from 'pages/search';
import Developers from 'pages/developers';
import Checkout from 'pages/checkout';
import AuthError from 'pages/auth/error';
import SpotDetailsAirport from 'pages/spot-details-airport';
import EventPackagesSearch from 'pages/event-packages-search';
import Confirmation from 'pages/confirmation';
import ParkingPass from 'pages/parking-pass';
import Auth0Callback from '@/auth/Auth0Callback';
import Auth0CheckoutRedirect from 'pages/auth/checkout';
import Logout from 'pages/auth/logout';
import Login from '@/auth/Login';
import SpotDetailsFacility from 'pages/spot-details-facility';

import AccountVerification from 'pages/account-verification';
import Auth0Loading from '../auth/Auth0Loading';
import SmsPermissions from 'pages/sms-permissions';
import Playground from 'pages/playground/Playground';

export function loadable(module) {
    return makeLoadable(module, {fallback: <RouterLoader />});
}

const routes = [
    {
        path: '/',
        exact: true,
        component: Home,
    },
    {
        path: '/developers',
        component: Developers,
        exact: true,
    },
    {
        path: '/checkout/:spotId/event-packages/:eventPackageId',
        component: Checkout,
    },
    {
        path: '/checkout/:spotId/:facilitySlug',
        component: Checkout,
    },
    {
        path: '/airport-parking/:spotId/:facilitySlug',
        component: SpotDetailsAirport,
    },
    {
        path: '/facility/:spotId',
        component: SpotDetailsFacility,
    },
    {
        path: '/search',
        component: Search,
        isSearch: true,
    },
    {
        path: '/parking-near-me',
        component: Search,
        isSearch: true,
    },
    {
        path: '/monthly-parking-near-me',
        component: Search,
        isSearch: true,
    },
    {
        path: '/event-packages-search',
        component: EventPackagesSearch,
        isSearch: true,
    },
    {
        path: '/confirmation',
        component: Confirmation,
    },
    {
        path: '/auth/callback',
        component: Auth0Callback,
        exact: true,
    },
    {
        path: '/auth/pending',
        component: Auth0Loading,
        exact: true,
    },
    {
        path: '/auth/restricted-login',
        component: Login,
        exact: true,
    },
    {
        path: '/login',
        component: Login,
        exact: true,
    },
    {
        path: '/auth/error',
        component: AuthError,
    },
    {
        path: '/auth/checkout-redirect',
        component: Auth0CheckoutRedirect,
    },
    {
        path: '/logout',
        component: Logout,
    },
    {
        path: '/pass/:id',
        component: ParkingPass,
    },
    {
        path: '/account-verification',
        component: AccountVerification,
    },
    {
        path: '/spot-details/:spotId',
        component: SpotDetailsFacility,
    },
    {
        path: '/sms-permissions',
        component: SmsPermissions,
    },
    {
        path: '/consumer-web/playground/search',
        component: Playground,
    },
];

export function matchPathToRoute(path) {
    const matches = matchRoutes(routes, path);

    if (!matches || matches.length === 0) {
        return null;
    }

    const [
        {
            match: {params},
            route,
        },
    ] = matches;

    return {...route, params};
}

export function isSearchPage(page, section) {
    return (
        page === 'search' ||
        includes(page, 'parking-near-me') ||
        includes(section, 'parking-near-me') ||
        (includes(page, '-parking') && !section) ||
        (!includes(page, '-parking') && includes(section, '-parking')) ||
        includes(page, '-monthly-parking')
    );
}

const possibleBasePages = routes
    .filter(route => !route.path.includes(':page'))
    .map(route => route.path.split('/')[1]);

export const excludedRoutes = ['favicon.ico'];

/**
 * Checks whether the provided path is a valid route on the server that requires loading of APIs and other page setup functionality.
 * This is necessary so that certain initial load paths (like the excluded routes aboveor error routes) don't overload the server with
 * unnecessary API calls. This method cannot check against citySlugs like the handleRoute method does above because we won't have that
 * information on the server to check against (loading it in an API call is not feasible since the city data will potentially become
 * stale if the node server isn't restarted for a long time and re-makes the API call again to get new city slugs).
 *
 * @function isValidRoute
 * @param {string} path - The requested path.
 * @example
 * isValidRoute('/'); // true
 * isValidRoute('/chicago/ohare-airport-parking'); // true
 * isValidRoute('/logout'); // false
 * @returns {boolean} - Whether the path is a valid route.
 */
export function isValidRoute(path) {
    const [, page, section] = path.split('/');

    return (
        path === '/' || // homepage
        includes(possibleBasePages, page) || // a base route match (/checkout/*, /airport-parking/*, etc)
        isSearchPage(page, section) || // a search page (excluding redirects like /[city] -> /[city]-parking)
        !includes(excludedRoutes, page) // this MUST be the last condition checked
    );
}

export default routes;
