import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import dayjs from 'utils/dayjs';
import {Page} from 'utils/page-utils';
import SegmentUtils, {getOS} from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
import SearchTracking from 'utils/search-tracking';
import StorageUtils from '@spothero/utils/storage';
import getParkingType from '../utils/parking-type';
import getUserLocation from '../utils/user-location';
import {
    calculateTimeDifference,
    getQueryValue,
    createTopSearchResultsList,
} from '../utils/top-search-results';
import transformFilters from '../utils/filters';
import {getStartAndEndDateOfPowerBookingPeriod} from 'pages/checkout/components/reservation-details-panel/power-booking-reservation-details/utils';
import {getRouteParam, paramNameOptions} from 'utils/url-utils';
import {getSearchPageViewString} from 'utils/search-page-view';
import {isOversizedFeatureEnabled, isSpotOversize} from 'utils/search-vehicle';
import {
    getTotalPriceUserPreference,
    TotalPriceSessionPreference,
} from 'utils/total-price-session-utils';

/**
 * @typedef {object} FormattedSearchResultObject
 * @property {number} display_price - The displayed price within the spot list
 * @property {string} facility_id - The ID of this spot/facility
 * @property {number} number_of_hours - The number of hours of the selected rate
 * @property {boolean} official_parking - Whether or not the Spot is marked as 'Official Parking' via the visual flags
 * @property {number} rank - The position of the spot in the list of results, dependant on the 'sort_by' option
 * @property {boolean} spots_left_displayed - Whether or not the spotsLeft tag is visible, (five or fewer spots, not shown for personal spots)
 * @property {object} star_rating - The star rating object
 * @property {number} star_rating.average - The average rating of the spot
 * @property {number} star_rating.count - The number of ratings
 * @property {string} tag - Tag (Lowest Price, Highest Rated, Most Popular). This is only applicable to some airport spots
 * @property {number} walking_distance - The walking distance in meters from the searched location to the spot
 */

export default function trackProductsSearched(args) {
    try {
        const view = getSearchPageViewString(
            getRouteParam(paramNameOptions.VIEW)
        );
        const {
            city: {display_name: cityName, slug: citySlug, state, timezone},
            destination: {
                id: destinationId,
                title: destinationName,
                google_places: destinationGooglePlaces,
                airport,
            },
            event: {id: eventId, title: eventName},
            eventPackage: {title: eventPackageName},
            destinationTitle,
            searchRequest: {
                monthly,
                referrer,
                search_string: searchQuery,
                latitude,
                longitude,
                starts,
                ends,
                pageType,
                powerBooking: isPowerBooking,
                powerBookingPeriods,
                powerBookingSource = null,
            },
            filters,
            activeAmenities,
            spots = [],
            searchAPIVersion,
            sortBy,
            breadcrumbs,
            vehicle,
            backendExperiments,
        } = args;

        const destinationCategory = breadcrumbs?.categoryBreadcrumb?.label;
        const airportName = airport ? airport.airport_name : null;
        const isEvent = Boolean(eventId);
        const isEventPackage = pageType === Page.EVENT_PACKAGES_SEARCH;
        const {
            sessionUUID,
            searchUUID,
            action: trackingAction,
            actionUUID,
        } = SearchTracking.getValues();
        const parkingType = getParkingType({
            isMonthly: monthly,
            isAirport: airport,
            isEvent,
            isEventPackage,
            isPowerBooking,
        });
        const searchSource = !isEmpty(referrer) ? referrer : 'map';
        const googlePlaceId = get(
            destinationGooglePlaces,
            '0.google_places_place_id'
        );
        const googlePlaceName = get(
            destinationGooglePlaces,
            '0.google_places_name'
        );
        const query = getQueryValue({
            pageType,
            cityName,
            destinationName,
            eventName,
            eventPackageName,
            airportName,
            searchQuery,
        });

        let startDate = starts;
        let endDate = ends;

        if (isPowerBooking) {
            const {startingDate} = getStartAndEndDateOfPowerBookingPeriod(
                powerBookingPeriods
            );

            const startingPeriodOfPowerBooking = powerBookingPeriods.find(
                ({starts: periodStarts}) =>
                    dayjs(periodStarts).isSame(dayjs(startingDate))
            );

            startDate = startingDate;
            endDate = startingPeriodOfPowerBooking?.ends;
        }
        const showTotal =
            getTotalPriceUserPreference() === TotalPriceSessionPreference.On;
        const includeOversize = isOversizedFeatureEnabled(parkingType);
        const isVehicleSelected = Boolean(vehicle?.id) && includeOversize;
        const vehicleMake = (isVehicleSelected && vehicle?.make) || null;
        const vehicleModel = (isVehicleSelected && vehicle?.model) || null;
        const oversizeCount = isVehicleSelected
            ? spots.filter(s => isSpotOversize(s)).length
            : 0;
        const vehicleProperties = includeOversize
            ? {
                  vehicle_in_search_viewed: true,
                  vehicle_make: vehicleMake,
                  vehicle_model: vehicleModel,
                  oversize_count: oversizeCount,
              }
            : {};

        const properties = {
            device_os: getOS(),
            page_type: pageType,
            action_id: actionUUID,
            action_type: trackingAction,
            ...(airport && {
                airport_code: airport.iata_code,
            }),
            city: citySlug,
            destination_id: destinationId,
            destination_name: destinationName,
            event_id: eventId,
            event_name: eventName,
            filters: transformFilters(filters),
            'filters selected': activeAmenities.join(),
            google_places_name: googlePlaceName,
            google_places_place_id: googlePlaceId,
            map_center: {
                latitude,
                longitude,
            },
            'number of filters applied': activeAmenities.length,
            parking_type: parkingType,
            query,
            search_api_version: searchAPIVersion,
            search_end_time_utc:
                monthly || isEventPackage ? null : dayjs(endDate).toISOString(),
            search_id: searchUUID,
            search_lead_time: isEventPackage
                ? null
                : calculateTimeDifference({
                      // Setting timezones here allows for normalization of time differences across timezones
                      starts: dayjs().tz(timezone),
                      ends: dayjs(startDate).tz(timezone, true),
                      unit: 'minute',
                  }),
            search_length:
                monthly || isEventPackage
                    ? null
                    : calculateTimeDifference({
                          starts: startDate,
                          ends: endDate,
                      }),
            search_source: searchSource,
            search_start_time_utc: isEventPackage
                ? null
                : dayjs(startDate).toISOString(),
            session_id: sessionUUID,
            sort_by: sortBy,
            state,
            top_search_results_list: createTopSearchResultsList({
                spots,
                airport,
                sortBy,
                parkingType,
                isMonthly: parkingType === 'monthly',
                filters,
            }),
            total_price_toggle_on: showTotal,
            power_booking: isPowerBooking,
            source: powerBookingSource,
            days_selected: powerBookingPeriods.map(
                ({starts: periodStarts, ends: periodEnds}) => ({
                    search_start_time_utc: dayjs(periodStarts).toISOString(),
                    search_end_time_utc: dayjs(periodEnds).toISOString(),
                })
            ),
            view,
            partner_affiliate:
                StorageUtils.get('sh-partner-affiliate', 'session') || null,
            ...getUserLocation(),

            /* --- DEPRECATED PROPERTIES --- */
            ...(airport && {
                'airport code search': airport.iata_code, // MARKED FOR DEPRECATION: airport_code will be used moving forward
            }),
            ...(destinationCategory && {
                destination_category: destinationCategory,
            }),
            ...vehicleProperties,
            ...(backendExperiments && {
                backend_experiments: backendExperiments,
            }),
            'parking type': parkingType, // MARKED FOR DEPRECATION: parking_type will be used moving forward
            'search city': cityName, // MARKED FOR DEPRECATION: city will be used moving forward
            'search end time':
                monthly || isEventPackage ? null : dayjs(endDate).toISOString(), // MARKED FOR DEPRECATION: search_end_time_utc will be used moving forward
            'search id': searchUUID, // MARKED FOR DEPRECATION: search_id will be used moving forward
            'search query': searchQuery || destinationTitle, // MARKED FOR DEPRECATION: query will be used moving forward
            'search source': searchSource, // MARKED FOR DEPRECATION: search_source will be used moving forward
            'search start time': isEventPackage
                ? null
                : dayjs(startDate).toISOString(), // MARKED FOR DEPRECATION: search_start_time_utc will be used moving forward
            'search type': trackingAction, // MARKED FOR DEPRECATION: action_type will be used moving forward
            'session id': sessionUUID, // MARKED FOR DEPRECATION: session_id will be used moving forward
        };

        SegmentUtils.track({
            event: 'Products Searched',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 20,
                    },
                },
            },
        });
    } catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackProductsSearched',
        });
    }
}

// Currently just used on SpotDetailsFacility, but could be extended to be used elsewhere like Checkout or SpotDetailsAirport
export function trackFacilityDetailsProductsSearched(args) {
    try {
        const {starts, ends, spot, noResults} = args;
        const {
            sessionUUID,
            searchUUID,
            actionUUID,
            action,
        } = SearchTracking.getValues();

        const startDate = starts;
        const endDate = ends;
        const parkingType = 'transient';

        const properties = {
            page_type: Page.FACILITY,
            action_id: actionUUID,
            action_type: action,
            city: spot.facility.city,
            filters: [],
            map_center: {
                latitude: spot.latitude,
                longitude: spot.longitude,
            },
            parking_type: parkingType,
            query: '',
            search_api_version: 2,
            search_end_time_utc: dayjs(endDate).toISOString(),
            search_id: searchUUID,
            search_lead_time: calculateTimeDifference({
                starts: dayjs().tz(spot.timezone),
                ends: dayjs(startDate).tz(spot.timezone, true),
                unit: 'minute',
            }),
            search_length: calculateTimeDifference({
                starts: startDate,
                ends: endDate,
            }),
            search_source: 'product_details',
            search_start_time_utc: dayjs(startDate).toISOString(),
            session_id: sessionUUID,
            sort_by: 'n/a',
            state: spot.facility.state,
            top_search_results_list: noResults
                ? []
                : [
                      {
                          visual_flags: [],
                          display_price: spot.selectedRate?.display_price,
                          facility_id: `${spot.spotId}`,
                          rank: 1,
                          number_of_hours: calculateTimeDifference({
                              starts: startDate,
                              ends: endDate,
                          }),
                          official_parking: false,
                          spots_left_displayed: false,
                          star_rating: {
                              average: spot.rating_info.star_rating,
                              count: spot.rating_info.number_of_ratings,
                          },
                          tag_displayed_in_search: false,
                      },
                  ],
            power_booking: false,
            view: 'facility_details',
            partner_affiliate:
                StorageUtils.get('sh-partner-affiliate', 'session') || null,
            ...getUserLocation(),
        };

        SegmentUtils.track({
            event: 'Products Searched',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 16,
                    },
                },
            },
        });
    } catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackProductsSearched',
        });
    }
}
