import React from 'react';
import PropTypes from 'prop-types';
import {Button, Text} from '@spothero/ui';
import useTouch from 'hooks/use-touch';

const DynamicSpotListItemButton = ({
    isAdmin,
    available,
    isRebookingReservation,
    isHovered,
    onClick,
    isMonthly = false,
    ...props
}) => {
    const isUnavailable = isAdmin && !available;
    const dynamicCTA = isUnavailable
        ? 'Unavailable'
        : isRebookingReservation
        ? 'Rebook It'
        : isMonthly
        ? 'View Details'
        : 'Book Now';

    // The hover state is applied when:
    // 1. The button is hovered
    // 2. The corresponding spot pin on the map is hovered
    const dynamicButtonStyleProps = {
        marginLeft: 4,
        backgroundColor: isHovered ? 'primary.600' : 'primary.default',
        borderColor: isHovered ? 'primary.600' : 'primary.default',
        _hover: {
            backgroundColor: isUnavailable ? 'gray.medium' : 'primary.600',
            borderColor: isUnavailable ? 'gray.medium' : 'primary.600',
            color: isUnavailable ? 'gray.dark' : 'white',
        },
    };
    const monthlyDynamicButtonStyleProps = {
        color:
            isHovered && !isUnavailable
                ? 'primary.600'
                : isUnavailable
                ? 'gray.dark'
                : 'primary.default',
        _hover: {
            color: !isUnavailable ? 'primary.600' : 'gray.dark',
        },
    };
    const adminBorderButtonStyleProps = {
        borderColor: 'warning',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderRadius: '4px',
        ...(isMonthly && {padding: 1}),
        _hover: {
            borderColor: 'warning',
            ...(!isMonthly && {backgroundColor: 'primary.600'}),
        },
    };
    const handleClick = e => {
        // We stop propagation here to prevent the parent element from being clicked.
        e.stopPropagation();

        onClick(e);
    };
    const {handleTouchStart, handleTouchEnd} = useTouch(handleClick);

    return (
        <Button
            variant={isMonthly && !isUnavailable ? 'tertiary' : 'primary'}
            onClick={handleClick}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            isDisabled={isUnavailable}
            {...(isMonthly
                ? {...monthlyDynamicButtonStyleProps}
                : {...dynamicButtonStyleProps})}
            {...(isAdmin &&
                isRebookingReservation && {...adminBorderButtonStyleProps})}
            {...props}
        >
            {dynamicCTA}
            {isUnavailable && (
                <Text as="sup" variant="caption" lineHeight={0}>
                    *
                </Text>
            )}
        </Button>
    );
};

DynamicSpotListItemButton.propTypes = {
    isAdmin: PropTypes.bool,
    available: PropTypes.bool,
    isRebookingReservation: PropTypes.bool,
    isHovered: PropTypes.bool,
    onClick: PropTypes.func,
    isMonthly: PropTypes.bool,
};

export default DynamicSpotListItemButton;
