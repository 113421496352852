import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidV4} from 'uuid';
import dayjs from 'utils/dayjs';
import {getTransientDateDisplayFormat} from 'utils/dayjs-calendar';
import {DatePicker} from '@spothero/ui-backlog';
import StorageUtils from '@spothero/utils/storage';
import {push} from 'store/router/router-actions';
import {routeToSearch} from 'router/router-utils';
import {Page} from 'utils/page-utils';
import SegmentUtils from 'utils/segment';
import EventListItem from '../event-list-item';
import {Box, Heading, Text, Button, Flex, Divider, Icon} from '@spothero/ui';
import useConfig from 'hooks/use-config';
import {useDispatch, useSelector} from 'react-redux';
import {formatDateTime} from 'utils/format-date-time';
import UrlUtils from '@spothero/utils/url';
import IconArrowLeft from '@spothero/icons/arrow-left';
import withConfig from '@/config/withConfig';
import {getRouteParam, paramNameOptions} from 'utils/url-utils';
import trackEventListItemSelected from 'segment/events/event-list-item-selected';

export const isEventHappeningToday = (date, timezone) => {
    const formattedDate = dayjs(formatDateTime(date, timezone));

    return formattedDate.isSame(dayjs(), 'day');
};

const defaultEventHeaderTextStyles = {
    color: 'secondary.default',
    marginTop: {base: '4', desktop: '5'},
    paddingLeft: '5',
    marginBottom: '2',
    fontSize: 'sm',
    lineHeight: '4',
};

const eventListOverrideStyles = {
    background: 'white',
    sx: {
        '.EventListItem': {
            alignItems: 'center',
            ':hover': {
                background: 'none',
            },
        },
        '.EventListItem-bottom': {
            marginTop: '1.5',
            p: {color: 'black', fontWeight: 'normal', fontSize: 'sm'},
        },
        '.EventListItem-title': {
            marginTop: '-1',
            '> p:first-of-type': {fontSize: 'base', paddingRight: '4'},
        },
        '.EventListItem-date': {
            fontWeight: 'semibold',
            'span:first-of-type': {
                fontSize: {desktop: 'sm'},
            },
            'span:last-of-type': {
                fontSize: 'base',
            },
        },
    },
};

export const getFilteredEvents = ({
    date,
    displayDateFormat,
    timezone,
    eventsArray = [],
}) => {
    let formattedDate = null;

    const filteredEvents = eventsArray.reduce((acc, event) => {
        formattedDate = dayjs(event.starts)
            .tz(timezone)
            .format(displayDateFormat);

        if (includes(formattedDate, date)) {
            acc.push(event);
        }

        return acc;
    }, []);

    return filteredEvents.reduce(
        (acc, event) => {
            isEventHappeningToday(event.starts, timezone)
                ? acc.happeningTodayEvents.push(event)
                : acc.upcomingEvents.push(event);

            return acc;
        },
        {happeningTodayEvents: [], upcomingEvents: []}
    );
};

const EventList = ({
    onViewTransientClick,
    isChangeEvent,
    isMobile,
    onBackButtonClick,
    listStyleOverrides,
    ctaStyleOverrides,
}) => {
    const [date, setDate] = useState('');
    const [key, setKey] = useState(uuidV4());
    const {displayDateFormat} = useConfig();
    const {
        city: {data: city},
        destination: {data: destination},
        searchRequest: {search_string: searchString, partner},
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const {title, id: destinationId} = destination;

    useEffect(() => {
        const userLatLng = StorageUtils.get('sh-user-location', 'session');

        SegmentUtils.track({
            event: 'Event List Display',
            properties: {
                /* eslint-disable camelcase */
                ...(userLatLng && {user_location: userLatLng}),
                ...(searchString && {search_query: searchString}),
                destination: title,
                /* eslint-enable camelcase */
            },
        });
    }, [searchString, title]);

    const onViewTransientClickWithSegment = () => {
        SegmentUtils.track({
            event: 'Find Non-Event Parking Clicked',
            properties: {
                /* eslint-disable camelcase */
                destination_name: title,
                destination_id: destinationId,
                /* eslint-enable camelcase */
            },
        });

        onViewTransientClick();
    };

    const onDateChange = selectedDate => {
        setDate(selectedDate);
    };

    const onClearClick = () => {
        setDate('');
        setKey(uuidV4());
    };

    const onEventSelected = ({id, title: eventName}, index) => {
        // eslint-disable-next-line camelcase
        const {operator_id, kind, id: eventId} = UrlUtils.parseParams(
            window.location.search
        );

        const queryParams = {
            ...(partner && {partner}),
            ...(operator_id && {operator_id}), // eslint-disable-line camelcase
            view: getRouteParam(paramNameOptions.VIEW),
        };

        trackEventListItemSelected({
            source: 'modal',
            index: index + 1,
            eventId: id,
            eventName,
        });
        if (isChangeEvent && kind === 'event' && id === eventId) {
            onBackButtonClick();

            return;
        }

        routeToSearch({
            method: (...args) => dispatch(push(...args)),
            pageType: Page.EVENT,
            id,
            queryParams,
        });
    };

    const {happeningTodayEvents, upcomingEvents} = useMemo(
        () =>
            getFilteredEvents({
                eventsArray: destination.events,
                date,
                displayDateFormat,
                timezone: city.timezone,
            }),
        [destination.events, date, displayDateFormat, city.timezone]
    );

    if (isEmpty(destination.events)) {
        return null;
    }

    const isEmptyEventList =
        happeningTodayEvents.length === 0 && upcomingEvents.length === 0;

    const applePartnerTitle = partner === 'apple' ? 'Book Parking for ' : '';
    const eventTrayTitle = isChangeEvent
        ? `Change Selected Event`
        : `${applePartnerTitle}${destination.title} Events`;

    const happeningEventHeaderTextStyles = {
        ...defaultEventHeaderTextStyles,
        ...(isChangeEvent && {
            marginTop: {base: '0', desktop: '5'},
        }),
    };

    const upcomingEventHeaderTextStyles = {
        ...defaultEventHeaderTextStyles,
        ...(isChangeEvent &&
            !happeningTodayEvents.length && {
                marginTop: {base: '0', desktop: '5'},
            }),
    };

    return (
        <Box
            background="gray.50"
            paddingBottom="24"
            bottom={{base: '0'}}
            top={{base: '0'}}
            left={{base: '0'}}
            right={{base: '0'}}
            position="relative"
            overflow={{base: 'auto'}}
            height="100%"
        >
            <Box overflowY="auto" height="100%" sx={{...listStyleOverrides}}>
                <Box background={{desktop: 'white'}}>
                    <Flex
                        paddingTop="5"
                        marginBottom="1"
                        justify="center"
                        paddingRight={{base: '5'}}
                        paddingLeft={{base: '5'}}
                        {...(isChangeEvent && {position: 'relative'})}
                    >
                        {isMobile && isChangeEvent && (
                            <Box position="absolute" top="3" left="3">
                                <Button
                                    variant="tertiary"
                                    onClick={onBackButtonClick}
                                    padding="3"
                                    data-testid="EventList-back-button"
                                >
                                    <Icon as={IconArrowLeft} color="black" />
                                </Button>
                            </Box>
                        )}

                        <Heading
                            as="h4"
                            paddingLeft={isMobile && isChangeEvent ? '14' : '5'}
                            paddingRight={
                                isMobile && isChangeEvent ? '14' : '5'
                            }
                            fontSize={{base: 'md', desktop: 'lg'}}
                            data-testid="EventList-title"
                        >
                            {eventTrayTitle}
                        </Heading>
                    </Flex>

                    <Box
                        key={key}
                        paddingRight={{base: '5', desktop: '14'}}
                        paddingLeft={{base: '5', desktop: '14'}}
                        paddingBottom={{
                            base: '0',
                            desktop: isChangeEvent ? '6' : '8',
                        }}
                        marginTop="1"
                        sx={{
                            '.DatePicker': {
                                marginTop: '4',
                                width: '80',
                            },
                            '.DatePicker-input-container': {
                                span: {
                                    display: 'block',
                                },
                            },
                            '.FormElement-item': {
                                fontSize: 'base',
                                height: '10',
                                color: 'black',
                                fontWeight: 'semibold',
                                '&.FormElement-item-placeholder,&::placeholder': {
                                    color: 'gray.200',
                                    fontWeight: 'normal',
                                },
                            },
                        }}
                    >
                        {!isChangeEvent && (
                            <Text
                                textAlign="center"
                                fontSize="sm"
                                color="gray.600"
                                data-testid="EventList-sub-title"
                            >
                                Select an event to view parking
                            </Text>
                        )}
                        <Flex
                            justify="center"
                            ml={{base: '0', desktop: '14'}}
                            align={{base: 'center', desktop: 'baseline'}}
                            direction={{base: 'column', desktop: 'row'}}
                            data-testid="EventList-date-picker-container"
                        >
                            <DatePicker
                                id="EventList-event-date"
                                placeholder="Select Event Date"
                                iconPosition="left"
                                displayFormat={getTransientDateDisplayFormat}
                                onChange={onDateChange}
                            />
                            <Button
                                variant="tertiary"
                                fontSize="sm"
                                p="4"
                                onClick={onClearClick}
                                data-testid="EventList-clear-date-picker-button"
                                disabled={!date}
                            >
                                Clear
                            </Button>
                        </Flex>
                    </Box>
                    {isChangeEvent && (
                        <Text
                            fontWeight="semibold"
                            color="black"
                            fontSize="sm"
                            paddingBottom={{desktop: '3', base: '2'}}
                            paddingLeft="5"
                            paddingRight="5"
                            data-testid="EventList-change-event-title"
                        >
                            Events at {destination.title}
                        </Text>
                    )}
                </Box>
                {isEmptyEventList ? (
                    <Text
                        mt="5"
                        textAlign="center"
                        data-testid="EventList-no-events-text"
                        fontSize="sm"
                    >
                        No events match this search
                    </Text>
                ) : (
                    <Box paddingBottom="2">
                        {happeningTodayEvents.length > 0 && (
                            <>
                                <Text
                                    data-testid="EventList-events-happening-today"
                                    {...happeningEventHeaderTextStyles}
                                >
                                    Happening Today
                                </Text>
                                {happeningTodayEvents.map((event, index) => {
                                    return (
                                        <React.Fragment key={event.id}>
                                            <Divider
                                                variant="solid"
                                                colorScheme="low"
                                            />

                                            <Box {...eventListOverrideStyles}>
                                                <EventListItem
                                                    event={event}
                                                    destination={destination}
                                                    city={city}
                                                    onClick={ev =>
                                                        onEventSelected(
                                                            ev,
                                                            index
                                                        )
                                                    }
                                                />
                                            </Box>
                                        </React.Fragment>
                                    );
                                })}
                                <Divider variant="solid" colorScheme="low" />
                            </>
                        )}

                        {upcomingEvents.length > 0 && (
                            <>
                                <Text
                                    data-testid="EventList-upcoming-events"
                                    {...upcomingEventHeaderTextStyles}
                                >
                                    Upcoming Events
                                </Text>
                                {upcomingEvents.map((event, index) => {
                                    return (
                                        <React.Fragment key={event.id}>
                                            <Divider
                                                variant="solid"
                                                colorScheme="low"
                                            />
                                            <Box {...eventListOverrideStyles}>
                                                <EventListItem
                                                    event={event}
                                                    destination={destination}
                                                    city={city}
                                                    onClick={ev =>
                                                        onEventSelected(
                                                            ev,
                                                            index
                                                        )
                                                    }
                                                />
                                            </Box>
                                        </React.Fragment>
                                    );
                                })}
                                <Divider variant="solid" colorScheme="low" />
                            </>
                        )}
                    </Box>
                )}
            </Box>
            <Box
                boxShadow="2px 0px 6px rgba(203, 212, 222, 0.8)"
                height={{base: '86px', desktop: '98px'}}
                background="background.white"
                width="full"
                bottom="0"
                position="absolute"
                sx={{...ctaStyleOverrides}}
            >
                <Flex
                    align="center"
                    justify="center"
                    direction="column"
                    height="100%"
                >
                    <Text
                        fontSize="sm"
                        lineHeight="1"
                        marginBottom="2"
                        marginTop="3"
                        color="text.secondary.light"
                        data-testid="EventList-transient-search-text"
                    >
                        Not going to an event?
                    </Text>
                    <Button
                        variant="secondary"
                        marginBottom="3"
                        onClick={onViewTransientClickWithSegment}
                        data-testid="EventList-transient-search-button"
                    >
                        Find Parking Near Venue
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
};

EventList.propTypes = {
    onViewTransientClick: PropTypes.func.isRequired,
    isChangeEvent: PropTypes.bool,
    onBackButtonClick: PropTypes.func,
    isMobile: PropTypes.bool,
    listStyleOverrides: PropTypes.object,
    ctaStyleOverrides: PropTypes.object,
};

EventList.defaultProps = {
    isChangeEvent: false,
};

export default withConfig(['isMobile'])(EventList);
