import React from 'react';
import { Box, Button, ToggleButtonGroup } from '@spothero/ui';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchPageView } from 'store/search/search-actions';
import { SearchPageView } from 'utils/search-page-view';
import { paramNameOptions, updateRouteParam } from 'utils/url-utils';
import trackShowMobileSearchListView from 'segment/events/show-mobile-search-list-view';
export default function MobileToggle() {
    const dispatch = useDispatch();
    const searchPageView = useSelector((state) => state.search.data.view);
    const handleClick = () => {
        const newView = searchPageView === SearchPageView.MOBILE_LIST
            ? SearchPageView.MOBILE_MAP
            : SearchPageView.MOBILE_LIST;
        if (newView === SearchPageView.MOBILE_LIST) {
            // Invoking  dispatch(setSearchPageView({view: SearchPageView.MOBILE_LIST}))
            // causes a UI flicker as it will be followed by a reload.
            // Therefore, we avoid using it here.
            // Update the URL with the new view
            updateRouteParam(paramNameOptions.VIEW, SearchPageView.MOBILE_LIST);
            // Track the Segment event
            trackShowMobileSearchListView();
            // When switching back to list view we do a full page reload
            // to show the original search results
            // Slack: https://spothero.slack.com/archives/C025ELN6L2G/p1704485541060059?thread_ts=1704482935.260369&cid=C025ELN6L2G
            return window.location.reload();
        }
        dispatch(setSearchPageView({
            view: newView,
        }));
    };
    return (<Box sx={{
            '& button': {
                paddingY: '6px',
            },
            '@media (max-width: 360px)': {
                '& button': {
                    paddingX: 2,
                },
            },
        }}>
            <ToggleButtonGroup onChange={handleClick} value={searchPageView}>
                <Button key="mobile-list" value={SearchPageView.MOBILE_LIST} fontSize="sm">
                    List
                </Button>
                <Button key="mobile-map" value={SearchPageView.MOBILE_MAP} fontSize="sm">
                    Map
                </Button>
            </ToggleButtonGroup>
        </Box>);
}
