import pick from 'lodash/pick';
import ObjectUtils from '@spothero/utils/object';
import { buildAirportSpotDetailsURL, buildAirportLogo, buildCheckoutURL, buildImageTemplate, buildUniqueRateAmenities, formatDistance, formatAirportTransportation, formatHoursOfOperation, AmenityTypes, } from '../../utils';
import { OBJECT_AIRPORT_FACILITY_SUMMARY, OBJECT_AIRPORT_FACILITY, } from '@/proptypes/index';
import { formatAirportRate } from './formatAirportRate';
/**
 * Formats data for a single airport facility
 *
 * @function formatAirportFacility
 * @see {@link https://api.spothero.com/v2/docs#operation/searchAirportFacilityParking|Documentation}
 * @param {object} data - The untransformed data from the API for a single facility
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @param {object} formatOptions.userRequestedStarts - The start date/time that the request was made with
 * @param {object} formatOptions.userRequestedEnds - The end date/time that the request was made with
 * @param {string} formatOptions.rebookId - The ID of the original reservation that we are rebooking for
 * @param {boolean} formatOptions.isFacilitySummary - If true, will return the shortened version of the facility data (e.g. to display on spot cards)
 * @param {string} formatOptions.airportCode - The airport code associated with the facility
 * @returns {object} The formatted airport facility data.
 */
export function formatAirportFacility(data, formatOptions = {}) {
    const { userRequestedStarts, userRequestedEnds, rebookId, isFacilitySummary, airportCode, } = formatOptions;
    const dataFormat = isFacilitySummary
        ? OBJECT_AIRPORT_FACILITY_SUMMARY
        : OBJECT_AIRPORT_FACILITY;
    const facilityData = ObjectUtils.camelizeKeys(data); // I have not figured out how to do this...
    const { availability, distance: rawDistance, facility: { common, airport }, rates: ratesResponse, } = facilityData;
    const distance = ObjectUtils.camelizeKeys(rawDistance);
    const timezone = common.addresses[0]?.timeZone;
    const { id: spotId, slug: facilitySlug, hoursOfOperation } = common;
    const { lowestDailyRate, tag, comparisons } = ratesResponse[0]?.airport || {};
    // only keep properties that have corresponding airport rate prop types
    const rates = ratesResponse.map(({ quote, airport: additionalRateDetails }) => formatAirportRate({
        spotId,
        facilitySlug,
        quote,
        additionalRateDetails,
        timezone: String(timezone),
        rebookId,
    }));
    const amenities = rates[0]?.amenities || [];
    const isCoveredParking = Boolean(amenities.find(item => item.type === AmenityTypes.COVERED_PARKING));
    const isSelfParking = Boolean(amenities.find(item => item.type === AmenityTypes.SELF_PARK));
    const isValetParking = Boolean(amenities.find(item => item.type === AmenityTypes.VALET));
    // only keep properties that have corresponding airport facility prop types
    return pick({
        isV2: true,
        ...common,
        ...airport,
        airportCode,
        logo: buildAirportLogo(airport.logo, common.images),
        images: common.images.map(buildImageTemplate),
        spotId,
        availability,
        distance: distance === null ? undefined : formatDistance(distance),
        lowestDailyRate,
        cancellationAllowed: common.cancellation.allowedByCustomer ||
            common.cancellation.allowedBySpotheroCustomerService,
        tag: tag?.text,
        comparisons,
        transportation: formatAirportTransportation(airport.transportation),
        isCoveredParking,
        isSelfParking,
        isValetParking,
        rates,
        selectedRate: rates[0],
        // @ts-expect-error - getting a bit burnt out from the file, to revisit
        allAmenities: buildUniqueRateAmenities(rates),
        hoursOfOperation: formatHoursOfOperation(hoursOfOperation),
        fullUrl: buildCheckoutURL({
            spotId,
            facilitySlug,
            rateType: 'airport',
            starts: userRequestedStarts,
            ends: userRequestedEnds,
            timezone,
            rebookId,
        }),
        spotDetailsUrl: buildAirportSpotDetailsURL({
            spotId,
            facilitySlug,
            starts: userRequestedStarts,
            ends: userRequestedEnds,
            timezone: String(timezone),
            rebookId,
        }),
    }, Object.keys(dataFormat));
}
