import { filterSearchResponse } from 'api/search-v2/utils/filter-search-response';
import { formatMonthlyFacility } from './formatMonthlyFacility';
/**
 * Formats data for multiple monthly facilities
 *
 * @function formatMonthlySearchResponse
 * @see {@link https://api.spothero.com/v2/docs#operation/searchMonthlyParking|Documentation}
 * @param {object} searchResults - Facility data returned from the search API for a multiple facilities
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @returns {Array} The array of monthly facilities, with data transformation applied.
 */
export function formatMonthlySearchResponse(searchResults, formatOptions = {} // TODO MR - Need to type better
) {
    let formattedResults = searchResults.map(result => formatMonthlyFacility(result, {
        ...formatOptions,
        isFacilitySummary: true,
    }));
    if (formatOptions.filterSpot) {
        formattedResults = filterSearchResponse(formattedResults, formatOptions.filterSpot);
    }
    return formattedResults;
}
