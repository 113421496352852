import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import AuthenticationUtils from 'utils/authentication';
import ErrorUtils from 'utils/error-utils';
import { redirect } from 'utils/window';
import Auth0Loading from './Auth0Loading';
import useConfig from 'hooks/use-config';
import useAuth0APISetup from './useAuth0APISetup';
import AuthenticationAPI from 'api/authentication';
const Auth0Callback = ({ history }) => {
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
    const { isReady } = useAuth0APISetup();
    const { siteUrl } = useConfig();
    useEffect(() => {
        if (isLoading) {
            return;
        }
        // check if there's a next param provided to redirect the user back to their original page
        const urlParams = new URLSearchParams(history?.location?.search);
        const encodedNextParam = urlParams.get('next');
        const nextParam = encodedNextParam
            ? decodeURIComponent(encodedNextParam)
            : null;
        const appState = nextParam ? { returnTo: nextParam } : null;
        if (!isAuthenticated) {
            (async () => {
                await loginWithRedirect({
                    appState,
                });
            })();
        }
        if (isReady) {
            const redirectUser = async () => {
                AuthenticationUtils.configureLoginCookies();
                await AuthenticationAPI.setCookie();
                redirect(nextParam
                    ? `${siteUrl}${nextParam}`
                    : `${siteUrl}/account-settings/`);
            };
            redirectUser().catch(err => {
                ErrorUtils.sendSentryException(err);
                history?.push('/auth/error/');
            });
        }
    }, [
        isAuthenticated,
        isReady,
        history,
        siteUrl,
        loginWithRedirect,
        isLoading,
    ]);
    return <Auth0Loading />;
};
export default Auth0Callback;
