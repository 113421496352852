import SegmentUtils from 'utils/segment';
import getUserLocation from '../utils/user-location';

export default function trackSignedUp({email, socialNetworkUsed}) {
    const properties = {
        email,
        ...(socialNetworkUsed && {
            social_signup: socialNetworkUsed,
        }),
        ...getUserLocation(),
    };

    SegmentUtils.track({
        event: 'Signed Up',
        properties,
        additionalOptions: {
            context: {
                protocols: {
                    event_version: 1,
                },
            },
        },
    });
}
