import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { setPreviewedSpotId } from 'store/spots/spots-actions';
import useIsMobile from 'hooks/use-is-mobile';
import { SearchPageView } from 'utils/search-page-view';
export const handleInViewChange = (inView, isMobile, searchPageView, previewedSpotId, spotId, mobileMapViewInteractionTrigger, dispatch) => {
    const isSpotNotPreviewed = `${previewedSpotId}` !== `${spotId}`;
    if (isMobile &&
        searchPageView === SearchPageView.MOBILE_MAP &&
        inView &&
        isSpotNotPreviewed &&
        mobileMapViewInteractionTrigger === 'scroll') {
        dispatch(setPreviewedSpotId({ spotId }));
    }
};
const useSpotInView = (spotId) => {
    const { previewedSpotId, mobileMapViewInteractionTrigger } = useSelector((state) => state.spots);
    const { view: searchPageView } = useSelector((state) => state.search.data);
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    // detect when a Spot Card is visible on mobile map view
    return useInView({
        threshold: 0.7,
        delay: 200,
        onChange: inView => handleInViewChange(inView, isMobile, searchPageView, previewedSpotId, spotId, mobileMapViewInteractionTrigger, dispatch),
    });
};
export default useSpotInView;
