import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'utils/dayjs';
import {useDispatch} from 'react-redux';
import {
    Button,
    Text,
    Grid,
    Icon,
    Flex,
    Box,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Alert,
    Heading,
} from '@spothero/ui';
import IconCheck from '@spothero/icons/checkmark';
import IconInfo from '@spothero/icons/info-circle-filled';
import useMobileBreakPoint from 'hooks/use-mobile-breakpoint';
import SearchDateTimePickers from 'common/search-controls/date-time-pickers';
import SpotListItemPlaceholder from 'common/spot-list/spot-card-placeholder';
import useConfig from 'hooks/use-config';
import usePrevious from 'hooks/use-previous';
import {updateTimes} from 'store/search-request/search-request-actions';
import trackAppleMapsSetTimesClicked from 'segment/events/apple-maps-set-times-clicked';
import trackAppleMapsViewNearbySpotsClicked from 'segment/events/apple-maps-view-nearby-spots-clicked';
import trackAppleMapsAvailabilityErrorThrown from 'segment/events/apple-maps-availability-error-thrown';
import SpotListItemTransient from '../../spot-card-transient/SpotListItemTransient';
import trackAppleMapsSelectNewTimesClicked from 'segment/events/apple-maps-select-new-times-clicked';
import trackAppleMapsSpotCardClicked from 'segment/events/apple-maps-spot-card-clicked';
import {
    OPTIMIZELY_FEATURE_ON,
    useFeatureVariation,
} from 'plugins/optimizely/hooks/use-feature-variation';
import {IMMEDIATE_FEATURES} from 'utils/experiment';

const labelStyles = {
    fontWeight: 'semibold',
    fontSize: 'sm',
    lineHeight: '1.4',
};

const LocationInfo = ({operatorDisplayName, title, ...props}) => {
    return (
        <Flex {...props} flexDirection="column">
            <Text {...labelStyles} color="gray.dark" marginBottom={1}>
                Parking Location
            </Text>

            <Text
                {...labelStyles}
                marginBottom={operatorDisplayName.length > 0 ? 1 : 0}
            >
                {operatorDisplayName}
            </Text>

            <Text lineHeight="1.4" fontSize="sm">
                {title}
            </Text>
        </Flex>
    );
};

LocationInfo.propTypes = {
    operatorDisplayName: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
};

const EditTimesModalContent = ({
    onClose,
    facility,
    isAdmin,
    timezone,
    starts,
    ends,
    isLoading,
    showSpotUnavailable,
    onBackToSearch,
    showExpandedVersion,
    spotId,
    spots,
    updateSelectedSpot,
    buttonRef,
    errorLoadingRates,
}) => {
    const isMobile = useMobileBreakPoint();
    const [isInInvalidState, setIsInInvalidState] = useState(false);
    const [newStarts, setNewStarts] = useState(starts);
    const [availabilityError, setAvailabilityError] = useState(
        showSpotUnavailable
    );
    const [showInitialUnavailable, setShowInitialUnavailable] = useState(
        showSpotUnavailable
    );
    const [hadAvailabilityError, setHadAvailabilityError] = useState(false);
    const [setTimesClicked, setSetTimesClicked] = useState(false);
    const [newEnds, setNewEnds] = useState(ends);
    const dispatch = useDispatch();
    const {apiDateTimeFormat} = useConfig();
    const prevLoading = usePrevious(isLoading);
    const isUserInExpressCheckoutExperiment =
        useFeatureVariation(IMMEDIATE_FEATURES.EXPRESS_CHECKOUT_WEB) ===
        OPTIMIZELY_FEATURE_ON;

    useEffect(() => {
        // If goes from pending to not & set times was clicked (ie, went into pending state b/c of user action), close modal
        if (prevLoading && !isLoading) {
            if (showSpotUnavailable) {
                setAvailabilityError(true);
            } else if (setTimesClicked) {
                onClose();
            }

            setSetTimesClicked(false);
        }
    }, [
        setTimesClicked,
        prevLoading,
        onClose,
        isLoading,
        showSpotUnavailable,
        facility,
        spotId,
    ]);

    useEffect(() => {
        if (availabilityError) {
            trackAppleMapsAvailabilityErrorThrown({
                facility,
                spotId,
                errorFetching: errorLoadingRates,
            });
        }
    }, [facility, spotId, availabilityError, errorLoadingRates]);

    const moreSpots = useMemo(() => {
        return spots.filter(spot => spot.spotId !== spotId).slice(0, 3);
    }, [spots, spotId]);

    useEffect(() => {
        // If there is an error hide the green box and keep it hidden
        if (availabilityError) {
            setHadAvailabilityError(true);
        }
    }, [availabilityError]);

    const availabilityMessage = useMemo(() => {
        const dayJsStarts = dayjs(starts);
        const dayJsEnds = dayjs(ends);

        if (dayJsStarts.isSame(dayJsEnds, 'day')) {
            return dayJsEnds.format('h:mm A');
        } else {
            return dayJsEnds.format('h:mm A, MMM D');
        }
    }, [starts, ends]);

    if (!isMobile) {
        return null;
    }

    const operatorDisplayName = facility?.operator_display_name || '';
    const title = facility?.title;
    const buttonDisabled =
        isInInvalidState ||
        availabilityError ||
        (!showExpandedVersion && starts === newStarts && ends === newEnds) ||
        setTimesClicked;

    const handleDateTimeChange = ({start, end, isValidStart}) => {
        setIsInInvalidState(false);
        setAvailabilityError(false);

        if (!isValidStart) {
            setIsInInvalidState(true);

            return;
        }

        setNewStarts(start.format(apiDateTimeFormat));
        setNewEnds(end.format(apiDateTimeFormat));
    };

    const handleSpotCardClick = ({spotId: clickedFacilityId}) => {
        trackAppleMapsSpotCardClicked({
            facility,
            clickedFacilityId,
            areaClicked: 'Spot Card',
        });

        onClose();
    };

    const handleBookSpotClick = ({spotId: clickedFacilityId}) => {
        trackAppleMapsSpotCardClicked({
            facility,
            clickedFacilityId,
            areaClicked: 'Book Now',
        });
    };

    const handleViewNearbySpotsClick = e => {
        e.preventDefault();

        trackAppleMapsViewNearbySpotsClicked({
            facility,
            location: 'Availability Error Alert',
        });

        onBackToSearch();
    };

    const handleSetTimesClick = () => {
        if (isLoading) {
            return;
        }

        if (starts === newStarts && ends === newEnds) {
            onClose();
            trackAppleMapsSetTimesClicked({
                facility,
                editedStartTime: false,
                editedEndTime: false,
            });
        } else {
            setSetTimesClicked(true);

            dispatch(
                updateTimes({
                    starts: newStarts,
                    ends: newEnds,
                })
            );

            trackAppleMapsSetTimesClicked({
                facility,
                editedStartTime: starts !== newStarts,
                editedEndTime: ends !== newEnds,
            });
        }
    };

    const handleSelectNewTimesClick = () => {
        trackAppleMapsSelectNewTimesClicked({facility});
        setShowInitialUnavailable(false);
    };

    return (
        <Flex
            sx={{
                '.FormElement-control .FormElement-item': {
                    borderColor: availabilityError ? 'error' : null,
                },
            }}
            className="EditTimesModal"
            flexDirection="column"
            maxWidth="100%"
            data-testid="EditTimesModalContent"
        >
            {showInitialUnavailable && showExpandedVersion ? (
                <Box
                    padding={4}
                    backgroundColor="gray.50"
                    borderRadius="md"
                    marginBottom={8}
                >
                    <LocationInfo
                        operatorDisplayName={operatorDisplayName}
                        title={title}
                        marginBottom={4}
                    />
                    <Text {...labelStyles} marginBottom={4}>
                        There are currently no spots available here
                    </Text>
                    <Button
                        onClick={handleSelectNewTimesClick}
                        variant="secondary"
                    >
                        Select New Times
                    </Button>
                </Box>
            ) : (
                <>
                    {showExpandedVersion && (
                        <LocationInfo
                            operatorDisplayName={operatorDisplayName}
                            title={title}
                            marginBottom={6}
                        />
                    )}
                    <SearchDateTimePickers
                        isAdmin={isAdmin}
                        timezone={timezone}
                        isMonthly={false}
                        starts={starts}
                        ends={ends}
                        isStartReadonly={false}
                        onChange={handleDateTimeChange}
                        parentClassname=".EditTimesModal"
                    />
                    {!hadAvailabilityError && showExpandedVersion && (
                        <Grid
                            width="100%"
                            borderRadius="base"
                            bg="green.100"
                            templateColumns="0.75rem 1fr"
                            gap={2}
                            alignItems="center"
                            paddingY={3}
                            paddingX={5}
                            marginTop={4}
                        >
                            <Icon
                                width="0.75rem"
                                height="auto"
                                as={IconCheck}
                                color="success"
                            />
                            <Text lineHeight="1.4" fontSize="sm">
                                Fast, prepaid reservation
                            </Text>
                            <Icon
                                width="0.75rem"
                                height="auto"
                                as={IconCheck}
                                color="success"
                            />
                            <Text lineHeight="1.4" fontSize="sm">
                                Easily change & extend your reservation
                            </Text>
                            <Icon
                                width="0.75rem"
                                height="auto"
                                as={IconCheck}
                                color="success"
                            />

                            <Popover variant="dark" placement="top">
                                <PopoverTrigger>
                                    <Button
                                        color="black"
                                        justifyContent="start"
                                        display="flex"
                                        variant="tertiary"
                                        fontFamily="body"
                                        alignItems="center"
                                        _hover={{
                                            color: 'black',
                                        }}
                                    >
                                        <Text
                                            textDecoration="underline"
                                            lineHeight="1.4"
                                            fontSize="sm"
                                        >
                                            Guaranteed by SpotHero
                                        </Text>
                                        <Icon
                                            marginLeft={2}
                                            width="1rem"
                                            height="auto"
                                            as={IconInfo}
                                        />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent paddingRight={6}>
                                    When you pay with SpotHero, we guarantee you
                                    will have a spot to park in, at the price
                                    you paid, or your money back.
                                </PopoverContent>
                            </Popover>
                        </Grid>
                    )}
                    {availabilityError && (
                        <Alert
                            marginTop={4}
                            overflow="initial"
                            title={
                                <Box as="span" fontWeight="semibold">
                                    Sorry, those times aren&apos;t available
                                    here
                                </Box>
                            }
                            status="error"
                        />
                    )}

                    <Button
                        width="100%"
                        marginTop={4}
                        sx={
                            // Not the best solution, but we don't want focus starting on datepicker v1 since it auto-opens the calendar
                            // which is undesired. Half-measure to be able to focus on the button even though it's "disabled".
                            // Once we update to new datepicker can remove this behavior
                            isLoading
                                ? {
                                      cursor: 'not-allowed',
                                      opacity: 1,
                                      bg: 'gray.medium',
                                      borderColor: 'gray.medium',
                                      color: 'gray.dark',
                                      shadow: 'none',
                                  }
                                : {}
                        }
                        variant="primary"
                        fontFamily="body"
                        onClick={handleSetTimesClick}
                        ref={buttonRef}
                        disabled={buttonDisabled}
                        loadingText="Confirming"
                        isLoading={setTimesClicked}
                    >
                        {showExpandedVersion ? 'Set Times' : 'Change Times'}
                    </Button>
                    {hadAvailabilityError && (
                        <Box
                            marginBottom={6}
                            paddingBottom={6}
                            borderBottomWidth="1px"
                            borderColor="gray.200"
                            marginX="-1rem"
                        />
                    )}
                </>
            )}

            {hadAvailabilityError && (
                <>
                    <Box>
                        <Heading
                            lineHeight="1.4"
                            as="h3"
                            fontSize="lg"
                            marginBottom={2}
                        >
                            Check out available spots nearby
                        </Heading>
                        <Text color="text.secondary.light">
                            {showInitialUnavailable ? (
                                <>
                                    Available: Now &ndash; {availabilityMessage}
                                </>
                            ) : (
                                'Availability & pricing for your selected times'
                            )}
                        </Text>
                    </Box>
                    <Grid
                        templateColumns="minmax(1em, 1fr)"
                        autoRows="1fr"
                        gridGap="1rem"
                        marginTop={6}
                        backgroundColor="gray.50"
                        marginX={'-1rem'}
                        paddingY={4}
                        paddingX={3}
                        borderColor="gray.100"
                        borderStyle="solid"
                        borderTopWidth="1px"
                        borderBottomWidth="1px"
                        marginBottom={4}
                        sx={{
                            '.SpotListItem': {
                                marginBottom: 0,
                            },
                        }}
                    >
                        {moreSpots.length > 0 ? (
                            moreSpots.map(spot => (
                                <SpotListItemTransient
                                    key={spot.spotId}
                                    spot={spot}
                                    isMobile={isMobile}
                                    isActive={false}
                                    isRebookingReservation={false}
                                    isAdmin={isAdmin}
                                    updateSelectedSpot={updateSelectedSpot}
                                    onShowDetailsClick={handleSpotCardClick}
                                    onBookSpotClick={handleBookSpotClick}
                                    referer="apple maps"
                                    inExpressExperiment={
                                        isUserInExpressCheckoutExperiment
                                    }
                                />
                            ))
                        ) : (
                            <>
                                <SpotListItemPlaceholder />
                                <SpotListItemPlaceholder />
                                <SpotListItemPlaceholder />
                            </>
                        )}
                    </Grid>

                    <Button
                        variant="secondary"
                        fontFamily="body"
                        textTransform="none"
                        width="100%"
                        onClick={handleViewNearbySpotsClick}
                    >
                        View more nearby spots
                    </Button>
                </>
            )}
        </Flex>
    );
};

EditTimesModalContent.propTypes = {
    showExpandedVersion: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    timezone: PropTypes.string,
    starts: PropTypes.string.isRequired,
    ends: PropTypes.string.isRequired,
    facility: PropTypes.shape({
        city: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        operator_display_name: PropTypes.string.isRequired, // eslint-disable-line camelcase
    }),
    showSpotUnavailable: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onBackToSearch: PropTypes.func.isRequired,
    spotId: PropTypes.number,
    spots: PropTypes.array,
    updateSelectedSpot: PropTypes.func.isRequired,
    buttonRef: PropTypes.any.isRequired,
    errorLoadingRates: PropTypes.bool.isRequired,
};

export default EditTimesModalContent;
