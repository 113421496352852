import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@spothero/ui';

const ParkingRestrictions = ({
    hasOnlineCommuterRate,
    onOnlineCommuterRateClick,
}) => {
    return (
        <div className="ParkingRestrictions">
            {hasOnlineCommuterRate && (
                <small className="online-commuter-rate">
                    Online Special.&nbsp;
                    <Button
                        variant="tertiary"
                        fontSize="xs"
                        onClick={onOnlineCommuterRateClick}
                    >
                        Details
                    </Button>
                </small>
            )}
        </div>
    );
};

ParkingRestrictions.propTypes = {
    hasOnlineCommuterRate: PropTypes.bool,
    onOnlineCommuterRateClick: PropTypes.func,
};

export default ParkingRestrictions;
