import { ActionType } from 'redux-promise-middleware';
import { EVENT_LOAD_DATA, EVENT_RESET } from './event-actions';
import { ROUTER_DATA_LOAD_FULFILLED } from '../router/router-actions';
export const initialState = () => ({
    isPending: false,
    data: {},
    // {
    //     starts,
    //     ends,
    //     parking_window: { starts, ends }
    //     search_url,
    //     description,
    //     seo_url,
    //     title,
    //     destination_id,
    //     short_description,
    //     id
    // }
    error: null,
});
export default function event(state = initialState(), { type, payload }) {
    switch (type) {
        case `${EVENT_LOAD_DATA}_${ActionType.Pending}`: {
            return {
                ...state,
                isPending: true,
                error: null,
            };
        }
        case `${EVENT_LOAD_DATA}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                isPending: false,
                data: payload.data.data,
            };
        }
        case `${EVENT_LOAD_DATA}_${ActionType.Rejected}`: {
            return {
                ...state,
                error: 'Could not load event data.',
            };
        }
        case EVENT_RESET: {
            return initialState();
        }
        case ROUTER_DATA_LOAD_FULFILLED: {
            const { event: cleanedEventState } = payload;
            if (cleanedEventState) {
                return cleanedEventState;
            }
            else {
                return state;
            }
        }
        default:
            return state;
    }
}
