import { ROUTER_DATA_LOAD_FULFILLED } from '../router/router-actions';
export const initialState = () => ({
    isPending: false,
    data: {
        id: null,
        title: '',
        description: '',
        events: [],
        venue: null,
    },
    error: null,
});
export default function eventPackage(state = initialState(), { type, payload }) {
    if (type === ROUTER_DATA_LOAD_FULFILLED) {
        return {
            ...state,
            ...payload.eventPackage,
        };
    }
    return state;
}
