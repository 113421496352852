import dayjs, { isDayjs } from 'dayjs';
import TimeUtils from '@spothero/utils/time';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const { getValuesFromString } = TimeUtils;
/**
 * Splits apart dates delimited by argument
 *
 * @static
 * @function splitMobileDate
 * @param {string} date - a string with with the pattern MM/DD/YYYY
 * @param {string} delimiter - a string with with the delimiter of the date (example above is of / as delimiter)
 * @example
 * DateUtils.splitMobileDate('1/30/1991', '/')
 * @returns {Date} - Date object with year, month, and day set
 */
const splitTextDate = (date, delimiter) => {
    const d = date.split(delimiter);
    return new Date(+d[2], +d[0] - 1, +d[1]);
};
/**
 * Splits apart dates delimited by hyphens
 *
 * @static
 * @function splitMobileDate
 * @param {string} date - a string with with the pattern YYYY-MM-DD, YYYY-M-D, ...etc
 * @example
 * DateUtils.splitMobileDate('1887-1-16')
 * @returns {Date} - Date object with year, month, and day set
 */
const splitMobileDate = date => {
    const d = date.split('-');
    return new Date(+d[0], +d[1] - 1, +d[2]);
};
export const combineDateAndTime = (date, time) => {
    if (!isDayjs(date) || !isDayjs(time)) {
        throw new Error('The date and/or time supplied was not an instance of Dayjs.');
    }
    return date.hour(time.hour()).minute(time.minute());
};
/**
 * Converts a string date from a text or date input to a dayjs instance.
 *
 * @static
 * @function getAsDayjsDate
 * @param {string} date - The date in the format MM/DD/YYYY (text input) or YYYY-MM-DD (date input).
 * @example
 * DateUtils.getAsDayjs('1983-01-19');
 * DateUtils.getAsDayjs('01/19/1983');
 * @returns {DayjsType} - The dayjs instance.
 */
export const getAsDayjsDate = date => {
    if (typeof date !== 'string') {
        throw new Error('The supplied date is not a string.');
    }
    if (
    // Not MM/DD/YYYY and not YYYY-MM-DD.  Includes considerations for single digit months and days.
    !date.match(/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/) &&
        !date.match(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/)) {
        throw new Error('The supplied date not formatted as expected.');
    }
    if (date.indexOf('-') !== -1) {
        // date input
        return dayjs(splitMobileDate(date));
    }
    else {
        // text input
        return dayjs(splitTextDate(date, '/'));
    }
};
/**
 * Converts a time to a dayjs instance.
 *
 * @static
 * @function getAsDayjsTime
 * @param {string} time - The input value in the format hh:mm A.
 * @example
 * TimeUtils.getAsDayjs('10:19 PM');
 * @returns {DayjsType} - The time inside of a dayjs instance.
 */
export const getAsDayjsTime = time => {
    if (!time) {
        return null;
    }
    if (typeof time !== 'string') {
        throw new Error('The supplied time is not a string.');
    }
    const { hours, mins } = getValuesFromString(time);
    return dayjs().startOf('day').hour(hours).minute(mins);
};
export default dayjs;
