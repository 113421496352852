import { formatMonthlyFacility } from './formatMonthlyFacility';
/**
 * Formats data for a single monthly facility
 *
 * @function formatMonthlySpotDetailsResponse
 * @see {@link https://api.spothero.com/v2/docs#operation/searchMonthlyFacilityParking|Documentation}
 * @param {object} facilityData - All the data returned from the search API for a single facility
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @returns {object} The monthly facility, with data transformation applied.
 */
export function formatMonthlySpotDetailsResponse(facilityData, formatOptions) {
    return formatMonthlyFacility(facilityData, {
        ...formatOptions,
        isFacilitySummary: false,
    });
}
