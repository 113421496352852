/**
 * Hours of operation data coming off the API contains more information than we need,
 * including some fields that are deprecated but still exist for backwards-compatibility.
 *
 * @see {@link https://spothero.atlassian.net/wiki/spaces/SEAR/pages/1888551003/V2+Hours+of+Operation+API+Contract+Proposal | Hours of Operation API Contract Proposal}
 * @function hoursOfOperation
 * @param {object} hoursOfOperation - An object representing hours of operation
 * @returns {object} re-formatted hours of operation with extraneous data removed
 */
export const formatHoursOfOperation = (hoursOfOperation = { periods: [] }) => {
    const { alwaysOpen, periods } = hoursOfOperation;
    const formattedPeriods = periods?.map(period => {
        const { hoursType, firstDay, lastDay, startTime, endTime } = period;
        return {
            hoursType,
            startDayOfWeek: firstDay,
            endDayOfWeek: lastDay,
            startTime,
            endTime,
        };
    });
    return {
        alwaysOpen,
        periods: formattedPeriods,
    };
};
