import APIUtils from '@spothero/utils/api';
import UserUtils from 'utils/user-utils';
/**
 * Object containing all User API methods
 *
 * @property {lookup} lookup - lookup user
 * @property {lookupByReservation} lookupByReservation - lookup user
 * @property {getUser} getUser - lookup user
 * @property {getUserReservations} getUserReservations - lookup user
 * @property {isAuthenticated} isAuthenticated - lookup user
 * @module api/user
 */
const UserAPI = {
    /**
     * Lookup user
     *
     * @function lookup
     * @param {string} email - email to lookup
     * @returns {Promise<Login>} - API response
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Users/get_users|Documentation}
     */
    lookup(email) {
        return APIUtils.get('users/', {
            email,
            include: UserUtils.INCLUDES,
        }, { headers: { 'SpotHero-Version': '2022-02-22' } })
            .then(payload => payload.data.data)
            .then(UserUtils.formatPayload);
    },
    /**
     * Lookup guest user
     *
     * @function lookupByReservation
     * @param {string} reservationId - Id of reservation for guest user
     * @returns {Promise<Login>} - API response
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Users/get_users|Documentation}
     */
    lookupByReservation(reservationId) {
        return APIUtils.get('users/', {
            reservation_id: reservationId,
        }, { headers: { 'SpotHero-Version': '2022-02-22' } })
            .then(payload => payload.data.data)
            .then(UserUtils.formatPayload);
    },
    /**
     * Get user data
     *
     * @function getUser
     * @param config.headers
     * @param {object} config - additional configuration
     * @returns {Promise<Login>} - API response
     */
    getUser(config = {}) {
        return APIUtils.get('users/me/', {
            include: UserUtils.INCLUDES,
        }, {
            ...config,
            // @ts-expect-error - Unsure how to do unknown but spreadable
            headers: { ...config.headers, 'SpotHero-Version': '2022-02-22' },
        })
            .then(payload => payload.data.data)
            .then(UserUtils.formatPayload);
    },
    /**
     * get users reservations
     *
     * @function getUserReservations
     * @param {string} userId - User id
     * @param {string|number} count - page size
     * @returns {Promise<GetUserReservationsResponse>} - API response
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Users/get_users_user_id_reservations|Documentation}
     */
    getUserReservations(userId, count) {
        return APIUtils.get(`users/${userId}/reservations/`, {
            page_size: String(count),
        }).then(({ data }) => data?.data);
    },
    /**
     * get users vehicles
     *
     * @param userId - User id
     * @returns {Promise<GetUserVehiclesResponse>} - API response
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Users/get_users_user_id_vehicles|Documentation}
     */
    getUserVehicles(userId) {
        return APIUtils.get(`users/${userId}/vehicles/`).then(({ data }) => data?.data);
    },
    /**
     * is User authenticated
     *
     * @function isAuthenticated
     * @param {object} config - additional configuration
     * @returns {Promise<boolean>} - API response
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Users/get_users_logged_in|Documentation}
     */
    isAuthenticated(config = {}) {
        return APIUtils.get('users/logged-in/', null, config).then(({ data: { data: { logged_in: loggedIn }, }, }) => loggedIn);
    },
};
export default UserAPI;
