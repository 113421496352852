import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import throttle from 'lodash/throttle';
import React, {Component, createRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet-async';
import dayjs from 'utils/dayjs';
import {getWindow} from 'ssr-window';
import IconTimes from '@spothero/icons/x';
import {Alert} from '@spothero/ui-backlog';
import {Box, Button} from '@spothero/ui';
import DOMUtils from '@spothero/utils/dom';
import FacilitySummary from 'common/facility-summary/v1';
import {push} from 'store/router/router-actions';
import trackAvailabilityCheck from 'segment/events/availability-check';
import trackSpotDetails from 'segment/pages/spot-details';
import DateUtils from 'utils/date';
import GTMUtils from 'utils/gtm';
import SpotUtils from 'utils/spot';
import ParkingOptionsMonthly from './parking-options-monthly';
import SpotDetailsReservationSummary from 'common/spot-details/reservation-summary';
import ParkingRestrictionsMonthly from './parking-restrictions-monthly';
import AccessHours from 'common/spot-details/access-hours';
import AmenitiesList from 'common/spot-details/amenities-list';
import SpotDetailsPlaceholder from 'common/spot-details/placeholder';
import SpotImages from 'common/spot-details/spot-images';
import SpotNotes from 'common/spot-details/operator-and-admin-notes';
import ThingsYouShouldKnowMonthly from './things-you-should-know-monthly';
import PricingDetailsMonthly from './pricing-details-monthly/PricingDetailsMonthly';
import RateDetailsModal from 'common/spot-details/rate-details-modal';
import TrustSealValueProps from 'common/spot-details/trust-seal-and-value-props';
import {
    getRankOfSpot,
    getSelectedSpot,
    getSelectedSpotDistance,
} from 'pages/search-transient-event-monthly/utils/spot-details-utils';
import SpotDetailsMonthlyFooterPanel from './spot-details-monthly-footer-panels';
import CheckoutSectionMonthlyResponsive from './checkout-section-monthly-responsive';
import CheckoutSectionStickyResponsive from './checkout-section-sticky-responsive';
import trackOpenedSpotDetails from 'segment/events/opened-spot-details';
import trackChangedMonthlyRate from 'segment/events/changed-monthly-rate';
import {CarouselVariant} from 'common/image-carousel';
import {FACILITY_SUMMARY_VIEW_TYPES} from 'common/facility-summary/utils';

const window = getWindow();
const MONTHLY_247_RESERVATION_TYPE = '247';
const HEIGHT_RESTRICTION = ['Height restriction', 'Height Restriction'];

export class SpotDetailsMonthlyResponsive extends Component {
    static propTypes = {
        isMobile: PropTypes.bool,
        user: PropTypes.object,
        spotId: PropTypes.number,
        city: PropTypes.object,
        destination: PropTypes.object,
        event: PropTypes.object,
        searchRequest: PropTypes.object,
        referrerAffiliate: PropTypes.string,
        onCloseClick: PropTypes.func.isRequired,
        pushTo: PropTypes.func.isRequired,
        spots: PropTypes.array,
        spotDetailReferrer: PropTypes.string,
        showTotal: PropTypes.bool,
    };
    _container = createRef();

    constructor(props) {
        super(props);

        this.state = {
            updatedSpotData: null,
            isShowing: false,
            isLoadingRates: true,
            isRateLoadError: false,
            showSpotUnavailable: false,
            showRateDetailsModal: false,
            rateDetailsSection: null,
            showStickyCheckoutSection: false,
        };
        this._initSpotDetails = this._initSpotDetails.bind(this);
    }

    componentDidMount() {
        DOMUtils.addClass('html', 'SpotDetails-open');

        this._initSpotDetails();

        window.addEventListener('keydown', this._onKeyDown);
    }

    componentDidUpdate(prevProps) {
        const {starts: newStarts, ends: newEnds} = this.props.searchRequest;
        const {starts: prevStarts, ends: prevEnds} = prevProps.searchRequest;
        const hasSearchRequestTimeChanged = !(
            prevStarts === newStarts && prevEnds === newEnds
        );

        if (
            prevProps.spotId !== this.props.spotId ||
            hasSearchRequestTimeChanged
        ) {
            this._initSpotDetails();
        }
    }

    componentWillUnmount() {
        this._isUnmounted = true;

        DOMUtils.removeClass('html', 'SpotDetails-open');

        if (this._rateLoader) {
            this._rateLoader.cancel();
            this._rateLoader = null;
        }

        window.removeEventListener('keydown', this._onKeyDown);
    }

    _onKeyDown = evt => {
        // close spot details if escape is pressed on keyboard
        if (evt.which === 27) {
            this._onCloseClick();
        }
    };

    _onRetryClick = () => {
        this._loadRates();
    };

    _onScroll = () => {
        const {isLoadingRates, isRateLoadError} = this.state;

        if (isLoadingRates || isRateLoadError) {
            return;
        }

        const checkoutSectionEl = DOMUtils.el(
            '.CheckoutSectionMonthlyResponsive'
        );
        const {top: checkoutSectionTop} = DOMUtils.getDocumentOffset(
            checkoutSectionEl
        );
        const checkoutSectionHeight = DOMUtils.height(checkoutSectionEl);
        const headerHeight = DOMUtils.height('[role="banner"]');
        const checkoutSectionBottom =
            checkoutSectionTop + checkoutSectionHeight;

        this.setState({
            showStickyCheckoutSection: checkoutSectionBottom < headerHeight,
        });
    };

    _onMultiRateChange = rateId => {
        const {updatedSpotData: spotData} = this.state;
        const rateType = 'monthly_rates';
        const newRate = find(spotData[rateType], {
            fullRule: parseInt(rateId, 10),
        });
        const newSpotData = {
            ...spotData,
            selectedRate: false,
        };

        if (newRate) {
            newSpotData.selectedRate = newRate;
        }

        this.setState({
            updatedSpotData: newSpotData,
        });

        trackChangedMonthlyRate({page: 'spot details', title: newRate.title});
    };

    _onShowRateDetailsClick = section => {
        this.setState({
            showRateDetailsModal: true,
            rateDetailsSection: isString(section) ? section : null,
        });
    };

    _onBookSpotClick = evt => {
        evt.preventDefault();

        const {pushTo} = this.props;
        const {updatedSpotData: spotData} = this.state;

        if (!spotData.selectedRate) {
            return null;
        }

        const {
            selectedRate: {fullUrl},
        } = spotData;

        pushTo(fullUrl);
    };

    _onHideAllModals = () => {
        this.setState({
            showRateDetailsModal: false,
        });
    };

    _onCloseClick = () => {
        const {isMobile, onCloseClick} = this.props;
        const node = this._container.current;

        this.setState({
            isShowing: false,
            showStickyCheckoutSection: false,
        });

        if (isMobile) {
            onCloseClick();
        } else {
            window.transitionEnd(node).bind(() => {
                window.transitionEnd(node).unbind();

                onCloseClick();
            });
        }
    };

    _initSpotDetails() {
        this._loadRates();

        this.setState({
            isShowing: true,
        });

        trackOpenedSpotDetails({
            referrer: this.props.spotDetailReferrer,
        });
    }

    _trackSegmentEvents(spot) {
        const {
            user: {id: userId, status: userStatus},
            searchRequest,
            referrerAffiliate,
            city,
            destination,
            event,
            spotDetailReferrer,
        } = this.props;
        const {monthly: isMonthly, starts, ends, referrer} = searchRequest;

        const rank = Array.isArray(this.props.spots)
            ? getRankOfSpot(this.props.spots)
            : undefined;

        trackSpotDetails({
            userId,
            city,
            destination,
            event,
            isMonthly,
            starts,
            ends,
            spot,
            referrer,
            referrerAffiliate,
            spotDetailReferrer,
            rank,
        });

        trackAvailabilityCheck({
            searchRequest,
            city,
            destination,
            event,
            spot,
            userStatus,
            screen: 'spot details',
        });
    }

    _loadRates() {
        const {spotId, searchRequest, city} = this.props;
        const {starts, rebook_reservation_id: rebookId} = searchRequest;

        this.setState({
            isLoadingRates: true,
            isRateLoadError: false,
        });

        this._rateLoader = SpotUtils.loadRatesSpotDetail({
            spotId,
            starts,
            ends: null,
            isMonthly: true,
            timezone: city.timezone,
            searchDataParams: {
                monthly: true,
                starts,
                ends: null,
                eid: null,
            },
            spot: null,
            eventId: null,
            rebookId,
            onSuccessWithRates: updatedSpotData => {
                this.setState({
                    updatedSpotData,
                    showSpotUnavailable: false,
                    isLoadingRates: false,
                });
                this._trackSegmentEvents(updatedSpotData);
                this._rateLoader = null;
            },
            onSuccessWithoutRates: partialSpotData => {
                GTMUtils.push({
                    event: 'GAEvent',
                    eventCategory: 'automatic',
                    eventAction: 'rate_unavailable',
                    eventLabel: 'monthly_rate_toggle',
                    eventValue: undefined, // eslint-disable-line no-undefined
                    hitCallback: undefined, // eslint-disable-line no-undefined
                });

                this.setState({
                    updatedSpotData: partialSpotData,
                    showSpotUnavailable: true,
                    isLoadingRates: false,
                });
                this._rateLoader = null;
            },
            onError: () => {
                if (this._isUnmounted) {
                    return;
                }

                this.setState({
                    updatedSpotData: null,
                    showSpotUnavailable: true,
                    isLoadingRates: false,
                    isRateLoadError: true,
                });
                this._rateLoader = null;
            },
        });
    }

    _renderSpotImages(facility) {
        const {
            parking_spot_id: facilityId,
            title: facilityTitle,
            images: facilityImages,
        } = facility;

        return (
            <Box marginTop="2" marginBottom="4">
                <SpotImages
                    key={facilityId}
                    images={facilityImages}
                    alt={facilityTitle}
                    carouselVariant={CarouselVariant.SINGLE}
                />
            </Box>
        );
    }

    _renderFacilitySummary(spot) {
        const {
            user: {isAdmin},
            spots,
        } = this.props;
        const {available_monthly_inventory: monthlyInventory} = spot;
        const inventory = monthlyInventory?.default_rate;
        const distance = getSelectedSpotDistance(getSelectedSpot(spots));
        const spotValue = {
            ...spot,
            distance,
        };

        return (
            <FacilitySummary
                isAdmin={isAdmin}
                spot={spotValue}
                priceLabel="/month"
                availableInventory={inventory}
                lowInventoryThreshold={3}
                showImages={false}
                showPrice={false}
                showFullTitle
                showAdminLink
                view={FACILITY_SUMMARY_VIEW_TYPES.SPOT_DETAIL}
            />
        );
    }

    _renderParkingOptions(spot) {
        const {selectedRate, monthly_rates: monthlyRates, spotId} = spot;

        return (
            <ParkingOptionsMonthly
                availableRates={monthlyRates}
                selectedRate={selectedRate}
                onMultiRateChange={this._onMultiRateChange}
                onRateDetailsClick={this._onShowRateDetailsClick}
                spotId={spotId}
            />
        );
    }

    _renderMonthlyParkingRestrictions(selectedRate) {
        const {introductory} = selectedRate;
        const hasIntroRate = Boolean(introductory && introductory.rate);

        return (
            <ParkingRestrictionsMonthly
                hasIntroRate={hasIntroRate}
                onRateDetailsClick={this._onShowRateDetailsClick}
            />
        );
    }

    _renderReservationSummary(spot, isSticky, isStickyVersionShowing) {
        const {isMobile, showTotal} = this.props;
        const {isLoadingRates, showSpotUnavailable} = this.state;
        const {selectedRate} = spot;
        let displayPrice = null;
        let currencyType = null;
        let reservationDateTimeFormatted = '';

        if (selectedRate) {
            displayPrice = showTotal
                ? selectedRate.price
                : selectedRate.display_price;
            currencyType = selectedRate.currency_type;

            reservationDateTimeFormatted = DateUtils.getDateTimeSummary({
                startDateTimeObj: dayjs(selectedRate.starts),
                endDateTimeObj: dayjs(selectedRate.ends),
                isMonthly: true,
            });
        }

        return (
            <SpotDetailsReservationSummary
                reservationLabel="Parking Starting"
                priceLabel="/month"
                isMobile={isMobile}
                isLoadingRates={isLoadingRates}
                displayPrice={displayPrice}
                currencyType={currencyType}
                reservationDateTimeFormatted={reservationDateTimeFormatted}
                isReservationExtended={false}
                showSpotUnavailable={showSpotUnavailable}
                onBookSpotClick={this._onBookSpotClick}
                isSticky={isSticky}
                isShowing={isStickyVersionShowing}
                showConfirmationButton={false}
                showPrice={false}
            />
        );
    }

    _renderPricingDetails(spot) {
        const {selectedRate} = spot;
        const {pricingDetails, recurrable: isRecurring} = selectedRate;

        return (
            <PricingDetailsMonthly
                pricingDetails={pricingDetails}
                isRecurring={isRecurring}
            />
        );
    }

    _renderThingsYouShouldKnowMonthly(spot) {
        const {selectedRate, facility} = spot;
        const {restrictions = []} = facility;
        const heightRestrictionDescription = restrictions.filter(
            restriction =>
                restriction.includes(HEIGHT_RESTRICTION[0]) ||
                restriction.includes(HEIGHT_RESTRICTION[1])
        )[0];

        const {
            contract: {required: contractRequired},
            in_out: inOut,
            parking_pass_type: parkingPassType,
            new_description: newRateDescription, // there is a description field that no longer seems to be used
            reservation_type: monthlyReservationType,
            activation_fee: activationFee,
        } = selectedRate;

        const showNoStorageMessage =
            monthlyReservationType === MONTHLY_247_RESERVATION_TYPE;

        return (
            <ThingsYouShouldKnowMonthly
                heightRestrictionDescription={heightRestrictionDescription}
                contractRequired={contractRequired}
                inOut={inOut}
                parkingPassType={parkingPassType}
                rateDescription={newRateDescription}
                onShowRateDetailsClick={this._onShowRateDetailsClick}
                showNoStorageMessage={showNoStorageMessage}
                activationFee={activationFee}
            />
        );
    }

    _renderAmenities(spot) {
        const {selectedRate, facility} = spot;
        const {amenities_full: nonRateAmenities} = facility;

        return (
            <AmenitiesList
                rate={selectedRate}
                isMonthly
                nonRateAmenities={nonRateAmenities}
                onShowRateDetailsClick={this._onShowRateDetailsClick}
            />
        );
    }

    _renderAccessHours(spot) {
        const {selectedRate, facility} = spot;
        const {access_hours_formatted: facilityAccessHours} = facility;
        const {
            access_hours_formatted: rateAccessHours,
            display_hours_comment: hoursComment,
        } = selectedRate;
        const showAccessHours = Boolean(
            rateAccessHours || hoursComment || !isEmpty(facilityAccessHours)
        );

        return (
            showAccessHours && (
                <AccessHours
                    rate={selectedRate}
                    facility={facility}
                    sectionTitle="Access Hours"
                />
            )
        );
    }

    _renderSpotNotes(spot) {
        const {operator_notes: operatorNotes, spot_notes: spotNotes} = spot;

        return (
            <>
                <div
                    className="SpotDetails-section"
                    data-testid="SpotDetailsMonthlyResponsive-SpotNotes"
                >
                    <h4 className="subtitle">Spot Notes (Admin)</h4>
                    <SpotNotes type="spot" notes={spotNotes} />
                </div>
                <div
                    className="SpotDetails-section"
                    data-testid="SpotDetailsMonthlyResponsive-OperatorNotes"
                >
                    <h4 className="subtitle">Operator Notes (Admin)</h4>
                    <SpotNotes type="operator" notes={operatorNotes} />
                </div>
            </>
        );
    }

    _renderFooter(spot) {
        let rateInstructions = [];

        if (spot?.selectedRate?.redemption_instructions) {
            rateInstructions = spot.selectedRate.redemption_instructions;
        }

        return (
            <div className="SpotDetails-footer-responsive">
                <SpotDetailsMonthlyFooterPanel
                    sectionTitle="How to Redeem"
                    instructions={rateInstructions}
                    className="FooterAccordion"
                />
                <div className="SpotDetails-footer-background">
                    <TrustSealValueProps />
                </div>
            </div>
        );
    }

    render() {
        const {
            isMobile,
            user: {isAdmin},
            showTotal,
        } = this.props;
        const {
            updatedSpotData: spot,
            isShowing,
            isLoadingRates,
            isRateLoadError,
            rateDetailsSection,
            showStickyCheckoutSection,
            showRateDetailsModal,
        } = this.state;
        const classes = classNames('SpotDetails', 'SpotDetailsResponsive', {
            'SpotDetails-showing': isShowing,
            'SpotDetails-monthly': !isMobile,
        });
        const shimClasses = classNames('SpotDetails-shim', 'shim', {
            'SpotDetails-shim-showing': isShowing,
        });
        // These booleans are used to conditionally display subcomponents
        // Some subcomponents are rate-dependent, while others can be displayed if no rate is available
        const hasFacilityData = Boolean(spot);
        const hasFacilityAndRateData = Boolean(spot?.selectedRate);
        const {cancellation_allowed: cancellationAllowed} =
            hasFacilityAndRateData && spot?.facility;
        const displayPrice = showTotal
            ? spot?.selectedRate?.totalPrice?.value
            : spot?.selectedRate?.advertisedPrice?.value;

        return (
            <>
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <div className={shimClasses} onClick={this._onCloseClick} />
                <div
                    ref={this._container}
                    className={classes}
                    onScroll={throttle(this._onScroll, 500)}
                >
                    <Button
                        className="SpotDetailsMonthlyResponsive-close"
                        onClick={this._onCloseClick}
                        variant="tertiary"
                        position="absolute"
                        width="14"
                        height="14"
                        top="3"
                        right="0"
                        zIndex="layer5"
                    >
                        <IconTimes width="18px" height="18px" color="black" />
                    </Button>

                    {hasFacilityData && this._renderFacilitySummary(spot)}
                    {hasFacilityData && this._renderSpotImages(spot.facility)}
                    {hasFacilityAndRateData && this._renderParkingOptions(spot)}
                    {hasFacilityAndRateData &&
                        this._renderMonthlyParkingRestrictions(
                            spot.selectedRate
                        )}
                    {hasFacilityData && (
                        <>
                            {this._renderReservationSummary(spot, false, false)}
                            {hasFacilityAndRateData && (
                                <CheckoutSectionMonthlyResponsive
                                    onBookSpotClick={this._onBookSpotClick}
                                    cancellationAllowed={
                                        cancellationAllowed &&
                                        cancellationAllowed !== 'no'
                                    }
                                    displayPrice={displayPrice || null}
                                    currencyType={
                                        spot?.selectedRate?.currency_type ||
                                        null
                                    }
                                    showSpotUnavailable={
                                        this.state.showSpotUnavailable
                                    }
                                    priceLabel="/month"
                                />
                            )}
                        </>
                    )}
                    {isLoadingRates && <SpotDetailsPlaceholder />}
                    {isRateLoadError && (
                        <Alert className="SpotDetails-rate-error" type="danger">
                            There was an error retrieving the facility
                            information.{' '}
                            <Button
                                fontSize="sm"
                                variant="tertiary"
                                onClick={this._onRetryClick}
                            >
                                Retry
                            </Button>
                        </Alert>
                    )}
                    {hasFacilityAndRateData && this._renderPricingDetails(spot)}
                    {hasFacilityAndRateData && this._renderAccessHours(spot)}
                    {hasFacilityAndRateData && this._renderAmenities(spot)}
                    {hasFacilityAndRateData &&
                        this._renderThingsYouShouldKnowMonthly(spot)}
                    {isAdmin && hasFacilityData && this._renderSpotNotes(spot)}
                    {hasFacilityAndRateData &&
                        !this.state.showSpotUnavailable && (
                            <CheckoutSectionStickyResponsive
                                onBookSpotClick={this._onBookSpotClick}
                                displayPrice={
                                    spot?.selectedRate?.display_price || null
                                }
                                currencyType={
                                    spot?.selectedRate?.currency_type || null
                                }
                                priceLabel="/month"
                                visible={showStickyCheckoutSection}
                            />
                        )}
                    {hasFacilityData && this._renderFooter(spot)}
                </div>
                {showRateDetailsModal && (
                    <RateDetailsModal
                        spot={spot}
                        section={rateDetailsSection}
                        onHidden={this._onHideAllModals}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    const {
        city: {data: city},
        destination: {data: destination},
        event: {data: event},
        filters: {showTotal},
        user: {data: user},
        search: {
            data: {referrerAffiliate},
        },
        searchRequest,
        spots: {data: spots},
        spot: {spotDetailReferrer},
    } = state;

    return {
        city,
        destination,
        event,
        user,
        referrerAffiliate,
        searchRequest,
        spots,
        spotDetailReferrer,
        showTotal,
    };
};

const mapDispatchToProps = {
    pushTo: push,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpotDetailsMonthlyResponsive);
