import {useBreakpointValue} from '@spothero/ui';
import useConfig from './use-config';

const useMobileBreakPoint = (breakpointValue = 'tablet') => {
    const {isMobile} = useConfig();

    return useBreakpointValue(
        {base: true, [breakpointValue]: false},
        isMobile ? 'base' : breakpointValue
    );
};

export default useMobileBreakPoint;
