import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@spothero/ui';

const ParkingRestrictionsMonthly = ({hasIntroRate, onRateDetailsClick}) => {
    const onRateDetailsClicked = () => {
        onRateDetailsClick('intro-rate');
    };

    return (
        <div className="ParkingRestrictions">
            {hasIntroRate && (
                <small className="intro-rate">
                    Intro Rate.&nbsp;
                    <Button
                        variant="tertiary"
                        fontSize="xs"
                        data-section="intro-rate"
                        onClick={onRateDetailsClicked}
                    >
                        Details
                    </Button>
                </small>
            )}
        </div>
    );
};

ParkingRestrictionsMonthly.propTypes = {
    hasIntroRate: PropTypes.bool,
    onRateDetailsClick: PropTypes.func,
};

export default ParkingRestrictionsMonthly;
