import React, { useEffect, useRef, useState } from 'react';
import NoResultsModal from '../no-results-modal';
import SpotMapControls from '../map-controls';
import SHGoogleMap from '../google-map';
import { foldRecentlyViewedIntoSpots, transformSpotToMarkerData, } from './spot-map-utils';
import { Box, Flex, Spinner } from '@spothero/ui';
import { DEFAULT_ZOOM_LEVEL, HIGHEST_ALLOWED_ZOOM_LEVEL, LOWEST_ALLOWED_ZOOM_LEVEL, } from '../google-map/map-utils';
import { useSelector } from 'react-redux';
import useIsMobile from 'hooks/use-is-mobile';
import { SearchPageView } from 'utils/search-page-view';
import { useShowTotal } from 'store/selectors/hooks/useShowTotal';
/**
 * SpotMap is the middle "layer" of the map components (SearchMap, SpotMap, SHGoogleMap).
 *
 * This component is responsible for:
 * - Preparing spot marker data from a list of spots
 * - Coordination between SHGoogleMap and various controls (zoom in/out, return to destination)
 * - Display of NoResultsModal when user is on mobile map view.
 * - Handling zoom and pan events from the map
 *
 * @param {SpotMapProps} props - top level properties
 * @returns {React.Component} - Spot Map component
 */
const SpotMap = (props) => {
    const isMobile = useIsMobile();
    const [zoom, setZoom] = useState(DEFAULT_ZOOM_LEVEL);
    const [showNoResultsModal, setShowNoResultsModal] = useState(false);
    const { searchRequest: { powerBooking: isPowerBooking }, search: { isPending, data: { view: searchPageView }, }, spots: { isPending: isSpotsPending }, } = useSelector((state) => state);
    const showTotal = useShowTotal();
    const [isDestinationOutOfBounds, setIsDestinationOutOfBounds] = useState(false);
    const returnToDestination = useRef(null);
    // Below enables us to know when the user has interacted with the map after load
    const hasInteracted = useRef(false);
    const { isFiltered, isNoResultsModalEnabled = true, searchRequestId, destination, spots, onMarkerClick, isAdmin, recentlyViewedSpots, showAllSpots, onZoom, onPan, resetFiltersClick, } = props;
    useEffect(() => {
        if (!isSpotsPending && !spots.length) {
            // typically if you're filtered and search a new area which has no spots we need to make sure
            // that we show the no results modal on top of the map
            setShowNoResultsModal(true);
        }
    }, [spots, isSpotsPending]);
    useEffect(() => {
        function clearHandlers() {
            /* eslint-disable @typescript-eslint/no-use-before-define */
            document.removeEventListener('click', handleInteraction);
            document.removeEventListener('keydown', handleInteraction);
            document.removeEventListener('touchstart', handleInteraction);
            document.removeEventListener('touchmove', handleInteraction);
            document.removeEventListener('mousewheel', handleInteraction);
            document.removeEventListener('wheel', handleWheel);
            /* eslint-disable @typescript-eslint/no-use-before-define */
        }
        function handleInteraction() {
            hasInteracted.current = true;
            clearHandlers();
        }
        function handleWheel(e) {
            if (e.ctrlKey) {
                handleInteraction();
            }
        }
        document.addEventListener('click', handleInteraction);
        document.addEventListener('keydown', handleInteraction);
        document.addEventListener('touchstart', handleInteraction);
        document.addEventListener('touchmove', handleInteraction);
        document.addEventListener('mousewheel', handleInteraction);
        document.addEventListener('wheel', handleWheel);
        return () => {
            hasInteracted.current = false;
            clearHandlers();
        };
    }, []);
    const onGoogleMapLoaded = (value) => {
        if (value) {
            returnToDestination.current = value;
        }
    };
    // fired when the user zooms using pinch/scroll as well as after a UI control is used to zoom
    const onMapZoomChanged = ({ zoom: newZoom, prevZoom, mapCenter, mapBounds, }) => {
        setZoom(newZoom);
        if (hasInteracted.current) {
            onZoom({
                zoom: newZoom,
                prevZoom,
                mapCenter,
                mapBounds,
            });
        }
    };
    // fired when the user clicks zoom in UI control
    const onZoomInControlClick = () => {
        hasInteracted.current = true;
        setZoom(v => v + 1);
    };
    // fired when the user clicks zoom out UI control
    const onZoomOutControlClick = () => {
        hasInteracted.current = true;
        setZoom(v => v - 1);
    };
    const handleMapPan = ({ zoom: newZoom, mapCenter, mapBounds, isDestinationOutOfBounds: isOutOfBounds, }) => {
        if (onPan) {
            onPan({
                mapCenter,
                mapBounds,
            });
        }
        setZoom(newZoom);
        setIsDestinationOutOfBounds(isOutOfBounds);
    };
    const close = () => {
        setShowNoResultsModal(false);
    };
    const onResultsFound = () => {
        close();
    };
    const onNoResultsModalResetFiltersClick = () => {
        setShowNoResultsModal(false);
        if (resetFiltersClick) {
            resetFiltersClick();
        }
    };
    const spotsWRecentlyViewed = foldRecentlyViewedIntoSpots({
        spots,
        recentlyViewedSpots: Array.isArray(recentlyViewedSpots)
            ? recentlyViewedSpots
            : [],
    });
    const transformedSpots = spotsWRecentlyViewed.map(spot => transformSpotToMarkerData(spot, isPowerBooking, showTotal));
    return (<Box className="SpotMap">
            <Box position="relative" sx={{
            '.do-not-enter': {
                paddingBottom: '0.25rem',
            },
        }} className="SpotMap-map">
                {isPending ? (<Flex position="absolute" top="0" right="0" zIndex="201" padding={2} alignItems="center" gap={1}>
                        <Spinner thickness={2} height={3} width={3}/>
                        Loading...
                    </Flex>) : null}
                <SHGoogleMap isAdmin={isAdmin} searchRequestId={searchRequestId} spots={transformedSpots} zoom={zoom} destination={destination} onPan={handleMapPan} onZoom={onMapZoomChanged} onMarkerClick={onMarkerClick} onGoogleMapLoaded={onGoogleMapLoaded} showAllSpots={showAllSpots}/>
                {isMobile && searchPageView === SearchPageView.MOBILE_MAP ? (<NoResultsModal isOpen={isNoResultsModalEnabled && showNoResultsModal} isMobile={isMobile} isFiltered={isFiltered} onHidden={onResultsFound} onResetFilters={onNoResultsModalResetFiltersClick} close={close}/>) : null}
            </Box>
            <SpotMapControls isDestinationOutOfBounds={isDestinationOutOfBounds} zoom={zoom} highestAllowedZoomLevel={HIGHEST_ALLOWED_ZOOM_LEVEL} lowestAllowedZoomLevel={LOWEST_ALLOWED_ZOOM_LEVEL} onZoomIn={onZoomInControlClick} onZoomOut={onZoomOutControlClick} 
    // eslint-disable-next-line react/jsx-handler-names
    onReturnToDestination={returnToDestination.current}/>
        </Box>);
};
export default SpotMap;
