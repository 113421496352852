import Config from '@/config/index';
import GTMUtils from 'utils/gtm';

const defaultGoogleRemarketingData = {
    google_conversion_id: 985850933, // eslint-disable-line camelcase
    google_conversion_label: '_qoXCPuwqQMQtciL1gM', // eslint-disable-line camelcase
    google_remarketing_only: true, // eslint-disable-line camelcase
};

const track = ({data, defaultData, trackConversion}) => {
    let finalData = defaultGoogleRemarketingData;

    if (defaultData) {
        finalData = defaultData;
    }

    if (data) {
        finalData.google_custom_params = data; // eslint-disable-line camelcase
    }

    if (trackConversion) {
        finalData.google_conversion_value = 1.0; // eslint-disable-line camelcase
    }

    if (Config.isDeployed) {
        GTMUtils.push({
            event: 'RemarketingEvent',
            remarketing_params: finalData, // eslint-disable-line camelcase
        });
    } else if (process.env.NODE_ENV !== 'test') {
        console.log('Google Remarketing: ', finalData); // eslint-disable-line no-console
    }
};

export default {
    track,
};
