import { Page } from 'utils/page-utils';
import getParkingTypeUtil from 'segment/utils/parking-type';
import { calculateTimeDifference, getQueryValue, } from 'segment/utils/top-search-results';
import dayjs from 'utils/dayjs';
import UserUtils from 'utils/user-utils';
export function getParkingType(state) {
    const { destination: { data: { airport }, }, event: { data: { id: eventId }, }, searchRequest: { monthly, pageType, powerBooking }, } = state;
    const isEvent = Boolean(eventId);
    const isEventPackage = pageType === Page.EVENT_PACKAGES_SEARCH;
    return getParkingTypeUtil({
        isMonthly: monthly,
        isAirport: airport,
        isEvent,
        isEventPackage,
        isPowerBooking: powerBooking,
    });
}
export function getCitySlug(state) {
    if (state.city && state.city.data)
        return state.city.data.slug;
    else
        return null;
}
export function getMapCenter(state) {
    const latitude = state.searchRequest.latitude;
    const longitude = state.searchRequest.longitude;
    return latitude && longitude ? { latitude, longitude } : null;
}
export function getPageType(state) {
    return state.searchRequest.pageType || null;
}
export function getSearchQuery(rootState) {
    const { city: { data: { display_name: cityName } = {} }, destination: { data: { title: destinationName, airport }, }, event: { data: { title: eventName }, }, eventPackage: { data: { title: eventPackageName }, }, searchRequest: { search_string: searchQuery, pageType }, } = rootState;
    const airportName = airport ? airport.airport_name : null;
    const query = getQueryValue({
        pageType,
        cityName,
        destinationName,
        eventName,
        eventPackageName,
        airportName,
        searchQuery,
    });
    return query || null;
}
export function getSearchLeadTime(rootState) {
    const { city: { data: { timezone } = {} }, searchRequest: { pageType, starts }, } = rootState;
    const isEventPackage = pageType === Page.EVENT_PACKAGES_SEARCH;
    return isEventPackage
        ? null
        : calculateTimeDifference({
            // Setting timezones here allows for normalization of time differences across timezones
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ends: dayjs(starts).tz(timezone, true),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            starts: dayjs().tz(timezone),
            unit: 'minute',
        });
}
export function getSearchLength(rootState) {
    const { searchRequest: { monthly, pageType, starts, ends }, } = rootState;
    const isEventPackage = pageType === Page.EVENT_PACKAGES_SEARCH;
    return monthly || isEventPackage
        ? null
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            calculateTimeDifference({ starts, ends });
}
export function getSearchFilters(rootState) {
    const { filters } = rootState;
    return filters ? filters : null;
}
export function getSortBy(rootState) {
    const { search: { data: { sortBy }, }, } = rootState;
    return sortBy;
}
export function getSearchVehicle(rootState) {
    const { search: { data: { vehicle }, }, user: { data: { status: userStatus, id: userId, vehicles: userVehicles }, }, } = rootState;
    if (vehicle) {
        return vehicle;
    }
    // Fallback: use the default vehicle of a logged-in user
    if (userId && userStatus === UserUtils.AUTH_STATE.USER) {
        const userDefaultVehicle = (userVehicles || []).find(v => v.isDefault);
        if (userDefaultVehicle) {
            const data = {
                isDefault: userDefaultVehicle.isDefault,
            };
            const defaultVehicle = {
                ...data,
                id: userDefaultVehicle.id,
                vehicleProfileId: userDefaultVehicle.vehicleProfileId,
                licensePlate: userDefaultVehicle.licensePlateValue,
                make: userDefaultVehicle.make,
                model: userDefaultVehicle.model,
                year: userDefaultVehicle.year,
                display_make: userDefaultVehicle.makeLabel,
                display_model: userDefaultVehicle.modelLabel,
                display_description: `${userDefaultVehicle.makeLabel} ${userDefaultVehicle.modelLabel}`,
                oversize: userDefaultVehicle.oversize,
                height: userDefaultVehicle.height,
                length: userDefaultVehicle.length,
            };
            return defaultVehicle;
        }
    }
    return vehicle || null;
}
