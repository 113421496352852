import {v4 as uuidV4} from 'uuid';
import SearchTracking, {SEARCH_TRACKING_ACTIONS} from 'utils/search-tracking';
import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
import {notify} from '../notification/notification-actions';
import trackErrorMessageDisplayed from 'segment/events/error-message-displayed';
import {getFacilitiesByPackageV2} from 'api/search-v2/event-packages/get-facilities-by-package';

export const SPOTS_GET_EVENT_PACKAGE = 'SPOTS_GET_EVENT_PACKAGE';
const DEFAULT_MAX_DISTANCE_IN_METERS = 3500;

export const loadSpotsEventPackage = ({eventPackageId}) => {
    return (dispatch, getState) => {
        const segmentId = SegmentUtils.getAnonymousId();
        const prevState = getState();
        const searchRequestState = prevState.searchRequest;
        const {filterSpot} = searchRequestState;
        const userState = prevState.user;
        const {
            data: {isAdmin},
        } = userState;

        SearchTracking.updateAction(SEARCH_TRACKING_ACTIONS.SEARCH);
        const {
            sessionUUID,
            searchUUID,
            actionUUID,
        } = SearchTracking.getValues();

        /* eslint-disable camelcase */
        return dispatch({
            type: SPOTS_GET_EVENT_PACKAGE,
            payload: getFacilitiesByPackageV2(
                eventPackageId,
                {
                    session_id: sessionUUID,
                    search_id: searchUUID,
                    action_id: actionUUID,
                    segment_id: segmentId,
                    include: 'amenities_full',
                    max_distance_meters: DEFAULT_MAX_DISTANCE_IN_METERS,
                    show_unavailable: isAdmin,
                },
                {filterSpot}
            ).then(response => {
                return {
                    results: response,
                };
            }),
        }).catch(error => {
            trackErrorMessageDisplayed({
                message: 'Error fetching spots',
                screen: 'search',
                action: 'fetching spots',
            });

            dispatch(
                notify({
                    id: uuidV4(),
                    type: 'error',
                    message: 'Error fetching spots',
                })
            );

            return ErrorUtils.formatError(error);
        });
        /* eslint-enable camelcase */
    };
};

export const loadEventPackageSpot = ({eventPackageId}) => {
    return dispatch => {
        return dispatch(
            loadSpotsEventPackage({
                eventPackageId,
            })
        );
    };
};
