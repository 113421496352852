import React from 'react';
import { Select, Box, Icon, Flex } from '@spothero/ui';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilters } from 'store/filters/filters-actions';
import IconChevronDown from '@spothero/icons/chevron-down';
import { trackFilterChange } from '../../../utils/trackfilterChange';
import { getSelectItems } from './utils';
import { getMonthlyReservationTypes } from 'store/filters/filters-selectors';
export const MonthlyAvailability = () => {
    const dispatch = useDispatch();
    const fullStore = useSelector((state) => state);
    const { reservationTypes, activeReservationType } = useSelector(getMonthlyReservationTypes);
    const onReservationTypeChange = ({ target, }) => {
        const { value } = target;
        const changedFilters = {
            activeReservationType: value,
        };
        dispatch(updateFilters(changedFilters));
        trackFilterChange(fullStore, changedFilters);
    };
    const options = getSelectItems(reservationTypes);
    const isAll = activeReservationType === 'all';
    return (<Box position="relative" sx={{
            '.chakra-select__icon-wrapper': { display: 'none' },
            '.chakra-select': {
                width: 'unset',
                paddingRight: '8',
                backgroundColor: isAll ? 'transparent' : 'gray.light',
                borderColor: isAll ? 'accent' : 'black',
                fontSize: 'xs',
                fontWeight: 'medium',
            },
        }}>
            <Select value={activeReservationType} variant="outline" onChange={onReservationTypeChange} data-testid="monthlyReservationTypes">
                {options.map(({ label, value }) => (<option value={value} key={value} data-testid={`monthlyReservationTypes-${value}`}>
                        {label}
                    </option>))}
            </Select>
            <Flex position="absolute" right="0" top="0" height="100%" justifyContent="center" alignItems="center" paddingRight="2" pointerEvents="none">
                <Icon as={IconChevronDown} fill="black" height="4" width="4"/>
            </Flex>
        </Box>);
};
