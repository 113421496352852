import { STRIPE_LOADED, SET_PAYMENT_FLOW, PAYMENT_FLOWS, RESET_STRIPE, } from './payments-actions';
export const initialState = () => ({
    isGooglePayAvailable: null,
    isApplePayAvailable: null,
    paymentRequest: null,
    isStripeLoaded: false,
    paymentFlow: PAYMENT_FLOWS.STANDARD,
});
export default function promotion(state = initialState(), { type, payload }) {
    switch (type) {
        case STRIPE_LOADED:
            return {
                ...state,
                isGooglePayAvailable: payload.isGooglePayAvailable,
                isApplePayAvailable: payload.isApplePayAvailable,
                paymentRequest: payload.paymentRequest,
                isStripeLoaded: true,
            };
        case RESET_STRIPE:
            return initialState();
        case SET_PAYMENT_FLOW:
            return {
                ...state,
                paymentFlow: payload,
            };
        default:
            return state;
    }
}
