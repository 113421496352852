import React, {Component} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'utils/dayjs-timezone';
import {getAsDayjsTime, getAsDayjsDate, combineDateAndTime} from 'utils/dayjs';
import {
    getTransientDateDisplayFormat,
    getMonthlyDateDisplayFormat,
} from 'utils/dayjs-calendar';
import TimeUtils from 'utils/time';
import {
    DatePicker,
    DateTimePicker,
    DateTimeRangePicker,
    Label,
    Tooltip,
} from '@spothero/ui-backlog';
import withConfig from '@/config/withConfig';
import IconCalendar from '@spothero/icons/calendar';
import IconClock from '@spothero/icons/clock';
import {Box, Flex} from '@spothero/ui';
import trackErrorMessageDisplayed from 'segment/events/error-message-displayed';

const defaultTimePickerFormat = 'h:mm A';
const errorMessage = 'Cannot search for more than 30 minutes in the past.';

export const clampTimeToHalfHourIntervals = ({time, mode}) => {
    const minuteValue = time.minute();

    if (minuteValue > 0 && minuteValue < 30) {
        return mode === 'end' ? time.minute(0) : time.minute(30);
    } else if (minuteValue > 30 && minuteValue <= 59) {
        return mode === 'end' ? time.minute(30) : time.add(1, 'hour').minute(0);
    } else if (minuteValue === 0 || minuteValue === 30) {
        return time;
    }
};

/**
 * This function is the calculation for ontransient data transformation.
 * There is some work done to keep the data coming down from prop, held in state, and passed from component are all what we want
 *
 * @param {object} arg  - Top Level
 * @param {TimeFromDateTimeComponent} arg.componentProps - props coming from the datetimepicker
 * @param {string} arg.displayDateFormat - Format of dates display
 * @param {string} arg.standardTimeFormat - Format of time to display
 * @param {object} arg.endState - End dayjs object kept in component state
 * @param {string} arg.endProp - End string given as component prop
 * @param {boolean} arg.isMonthly - is function monthly or not
 * @returns {{starts: object, ends: object}} - end and start to keep in state, dayjs objects
 */
export const findTransientStartEnd = ({
    componentProps: {startDate, startTime, endDate, endTime},
    displayDateFormat,
    standardTimeFormat,
    isMonthly,
    endState,
    endProp,
}) => {
    const starts = combineDateAndTime(
        getAsDayjsDate(startDate),
        getAsDayjsTime(startTime)
    );

    const startTimePlus30Min = starts.add(30, 'minutes');

    const dayjsStartTime = getAsDayjsTime(startTime);
    const dayjsEndTime = getAsDayjsTime(endTime);

    let ends;
    // If the start and end date are the same,
    // and the start and end time are the same or the end time is less than start time,
    // and the search is not monthly
    // then the end time should be 30 minutes after the start time
    if (
        startDate === endDate &&
        dayjsEndTime.isSameOrBefore(dayjsStartTime) &&
        !isMonthly
    ) {
        const time = getAsDayjsTime(
            dayjs(startTimePlus30Min).format(standardTimeFormat)
        );
        //Handle case if end time is set to 12am also adjust the date
        const date =
            time.hour() === 0
                ? getAsDayjsDate(endDate).add(1, 'day')
                : getAsDayjsDate(endDate);
        ends = combineDateAndTime(date, time);
    } else if (
        endDate !== endState.format(displayDateFormat) &&
        startDate !== endDate &&
        (endTime === dayjs(endProp).format(standardTimeFormat) ||
            endTime === dayjs(startTimePlus30Min).format(standardTimeFormat)) &&
        !isMonthly
    ) {
        ends = combineDateAndTime(
            getAsDayjsDate(endDate),
            getAsDayjsTime(startTime)
        );
    } else {
        ends = combineDateAndTime(
            getAsDayjsDate(endDate),
            getAsDayjsTime(endTime)
        );
    }

    return {ends, starts};
};

class SearchDateTimePickers extends Component {
    static propTypes = {
        isAdmin: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isMonthly: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
        starts: PropTypes.string,
        ends: PropTypes.string,
        startDateLabel: PropTypes.element,
        endDateLabel: PropTypes.element,
        monthlyStartDateLabel: PropTypes.element,
        displayDateFormat: PropTypes.string.isRequired,
        standardTimeFormat: PropTypes.string.isRequired,
        useCalendarWithShim: PropTypes.bool,
        isStartReadonly: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        parentClassname: PropTypes.string,
    };
    static defaultProps = {
        starts: null,
        ends: null,
        startDateLabel: <Label>Enter After</Label>,
        endDateLabel: <Label>Exit Before</Label>,
        monthlyStartDateLabel: (
            <Label className="monthly-label">Monthly Parking Starting</Label>
        ),
        parentClassname: '',
    };

    constructor(props) {
        super(props);

        const {isMobile, starts, ends, endDateLabel} = props;

        this._endDateLabel = endDateLabel ? (
            endDateLabel
        ) : !isMobile ? (
            <Label>to</Label>
        ) : null;

        this.state = {
            startTooltipMessage: null,
            start: starts
                ? clampTimeToHalfHourIntervals({
                      time: dayjs(starts),
                      mode: 'start',
                  })
                : dayjs(),
            end: ends
                ? clampTimeToHalfHourIntervals({time: dayjs(ends), mode: 'end'})
                : dayjs(),
            isValidStart: true,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {isMonthly} = nextProps;
        const {startTooltipMessage} = prevState;

        if (isMonthly && startTooltipMessage) {
            return {
                startTooltipMessage: null,
            };
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        const {ends, isMonthly} = this.props;
        const {ends: prevEnds} = prevProps;

        const {start, end, isValidStart, startTooltipMessage} = this.state;
        const {
            start: prevStart,
            end: prevEnd,
            isValidStart: prevIsValidStart,
        } = prevState;

        // if ends prop is reset by auto extend
        if (prevEnds !== ends) {
            this.setState({
                end: clampTimeToHalfHourIntervals({
                    time: dayjs(ends),
                    mode: 'end',
                }),
            });
        }

        if (
            !prevStart.isSame(start) ||
            (!isMonthly && !prevEnd?.isSame(end)) ||
            prevIsValidStart !== isValidStart
        ) {

            this.props.onChange({start, end, isValidStart});

            if (!isValidStart && startTooltipMessage === errorMessage) {
                trackErrorMessageDisplayed({
                    screen: 'search',
                    currentScreenName: 'search',
                    pageType: 'search',
                    message: errorMessage,
                    errorMessage,
                    action: errorMessage,
                    errorHeader: errorMessage,
                });
            }
        }
    }

    _onTransientEndDateTimeChange = ({
        selectedDate: endDate,
        selectedTime: endTime,
    }) => {
        const ends = combineDateAndTime(
            getAsDayjsDate(endDate),
            getAsDayjsTime(endTime)
        );

        const newState = {
            end: ends,
        };

        this.setState(newState);
    };

    _onTransientChange = componentProps => {
        const {
            displayDateFormat,
            ends: endsProp,
            standardTimeFormat,
            isMonthly,
        } = this.props;
        const {end: endState} = this.state;
        const {ends, starts} = findTransientStartEnd({
            componentProps,
            displayDateFormat,
            standardTimeFormat,
            isMonthly,
            endsProp,
            endState,
        });

        const newState = {
            startTooltipMessage: null,
            start: starts,
            end: ends,
            isValidStart: true,
        };

        this.setState(newState);
    };

    _onMonthlyChange = selectedDate => {
        const selectedDateMoment = getAsDayjsDate(selectedDate);
        const start = selectedDateMoment.clone().hour(dayjs().hour());

        this.setState({
            start,
            end: start.clone().add(3, 'hours'),
            isValidStart: true,
        });
    };

    _onStartTooltipHidden = () => {
        this.setState({
            startTooltipMessage: null,
        });
    };

    _renderTransientStartReadonly() {
        const {
            startDateLabel,
            displayDateFormat,
            standardTimeFormat,
            useCalendarWithShim,
        } = this.props;
        const {start, end} = this.state;

        return (
            <Flex
                justifyContent="space-between"
                data-testid="SearchDateTimePickers-transient-start-readonly"
            >
                <Box
                    flexGrow={1}
                    marginRight={4}
                    sx={{
                        '& .FormElement-item.FormElement-item': {
                            backgroundColor: 'gray.light',
                        },
                    }}
                >
                    {startDateLabel}
                    <Box
                        position="relative"
                        marginBottom={2}
                        className="TextInput FormElement FormElement-with-icon-right"
                    >
                        <Box
                            className="FormElement-item"
                            data-testid="SearchDateTimePickers-StartDate"
                        >
                            Today
                        </Box>
                        <IconCalendar />
                    </Box>
                    <Box
                        position="relative"
                        className="TextInput FormElement FormElement-with-icon-right"
                    >
                        <Box
                            className="FormElement-item"
                            data-testid="SearchDateTimePickers-StartTime"
                        >
                            {start.format(standardTimeFormat)}
                        </Box>
                        <IconClock />
                    </Box>
                </Box>
                <DateTimePicker
                    key={end.format(displayDateFormat)}
                    id="SearchDateTimePickers-transient-end"
                    className="SearchDateTimePickers-transient"
                    dateLabel={this._endDateLabel}
                    dateDisplayFormat={getTransientDateDisplayFormat}
                    timeDisplayFormat={defaultTimePickerFormat}
                    selectedDate={end.format(displayDateFormat)}
                    selectedTime={end.format(standardTimeFormat)}
                    timeBump={180}
                    useTetherShim={useCalendarWithShim}
                    onChange={this._onTransientEndDateTimeChange}
                    disableTimeBefore
                />
            </Flex>
        );
    }

    _getDisableStartDateAndTime() {
        const {start} = this.state;
        const {
            displayDateFormat,
            standardTimeFormat,
            timezone,
            isAdmin,
        } = this.props;
        const now = dayjs();
        const minStarts = TimeUtils.getMinimumStartDateTimeForTimezone(
            timezone
        );
        const disableStartDateBefore = minStarts.format(displayDateFormat);
        const isSameDay = now.isSame(start, 'day');
        // only disable the start time if the start date is today and user is a non-admin
        const disableStartTimeBefore =
            !isAdmin && isSameDay
                ? minStarts.format(standardTimeFormat)
                : '12:00 AM';

        return {
            disableStartDateBefore,
            disableStartTimeBefore,
        };
    }

    _renderTransient() {
        const {
            startDateLabel,
            displayDateFormat,
            standardTimeFormat,
            useCalendarWithShim,
            isStartReadonly,
        } = this.props;
        const {start, end} = this.state;

        const {
            disableStartDateBefore,
            disableStartTimeBefore,
        } = this._getDisableStartDateAndTime();

        return isStartReadonly ? (
            this._renderTransientStartReadonly()
        ) : (
            <DateTimeRangePicker
                key={end.format(standardTimeFormat)}
                startId="SearchDateTimePickers-transient-start"
                endId="SearchDateTimePickers-transient-end"
                className="SearchDateTimePickers-transient"
                startDateLabel={startDateLabel}
                endDateLabel={this._endDateLabel}
                startDateDisplayFormat={getTransientDateDisplayFormat}
                startTimeDisplayFormat={defaultTimePickerFormat}
                startDate={start.format(displayDateFormat)}
                startTime={start.format(standardTimeFormat)}
                endDateDisplayFormat={getTransientDateDisplayFormat}
                endTimeDisplayFormat={defaultTimePickerFormat}
                endDate={end.format(displayDateFormat)}
                endTime={end.format(standardTimeFormat)}
                endTimeBump={30}
                useTetherShim={useCalendarWithShim}
                autoBumpInvalidEnabled
                onStartChange={this._onTransientChange}
                onEndChange={this._onTransientChange}
                disableStartDateBefore={disableStartDateBefore}
                disableStartTimeBefore={disableStartTimeBefore}
            />
        );
    }

    _renderMonthly() {
        const {monthlyStartDateLabel, displayDateFormat} = this.props;
        const {start} = this.state;

        return (
            <div className="SearchDateTimePickers-monthly">
                {monthlyStartDateLabel}
                <DatePicker
                    id="SearchDateTimePickers-monthly-start"
                    useIcon
                    calendarClassName="calendar-start"
                    selectedDate={start.format(displayDateFormat)}
                    displayFormat={getMonthlyDateDisplayFormat}
                    onChange={this._onMonthlyChange}
                    disablePast
                    aria-label="Monthly Start Date"
                />
            </div>
        );
    }

    render() {
        const {
            parentClassname,
            displayDateFormat,
            standardTimeFormat,
        } = this.props;
        const {startTooltipMessage, end} = this.state;

        return (
            <div
                className="SearchDateTimePickers"
                key={end?.format(displayDateFormat)}
            >
                {/* {TODO: Migrate DateTimeRangePicker to fe-ui v2 (ChakraUI)} */}
                {this.props.isMonthly
                    ? this._renderMonthly()
                    : this._renderTransient()}
                {startTooltipMessage && (
                    <Tooltip
                        triggerSelector={`${parentClassname} .SearchDateTimePickers-transient .DateTimePicker-start select`}
                        position="bottom"
                        updateOnMove
                        onHidden={this._onStartTooltipHidden}
                    >
                        {startTooltipMessage}
                    </Tooltip>
                )}
            </div>
        );
    }
}

/**
 * @typedef {object} TimeFromDateTimeComponent
 * @property {string} startDate - date string
 * @property {string} startTime - time string
 * @property {string} endDate - date string
 * @property {string} endTime - time string
 */

export default withConfig([
    'displayDateFormat',
    'isMobile',
    'standardTimeFormat',
])(SearchDateTimePickers);
