import { useEffect } from 'react';
import { getValue } from 'api/firebase-remote-config';
import { useDispatch } from 'react-redux';
import { setStatesWithFeeIncluded } from 'store/search/search-actions';
import ErrorUtils from 'utils/error-utils';
// Reference: https://console.firebase.google.com/project/spothero-mobile-dev/config/env/firebase
// Keep in sync with the value in the Firebase console.
// Config name: fees_included_list
export const _defaultStatesWithFeeIncluded = `[{"name":"US","states":["CA","MN"]},{"name":"CA","states":null}]`;
export const getDefaultStatesWithFeeIncluded = () => {
    return JSON.parse(_defaultStatesWithFeeIncluded);
};
export const useFetchStatesWithFeeIncluded = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        getValue('fees_included_list', _defaultStatesWithFeeIncluded).then(result => {
            try {
                const statesWithFeeIncluded = result
                    ? JSON.parse(result)
                    : [];
                dispatch(setStatesWithFeeIncluded(statesWithFeeIncluded));
            }
            catch (error) {
                ErrorUtils.sendSentryMessage({
                    error,
                    customErrorMessage: 'useFetchStatesWithFeeIncluded: Error parsing JSON',
                });
                dispatch(setStatesWithFeeIncluded([]));
            }
        });
    }, [dispatch]);
};
