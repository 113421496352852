import { sha256 } from 'js-sha256';
import { md5 } from 'js-md5';
export const getSha256EmailHash = () => {
    try {
        return localStorage?.getItem('sh-gtmsh-id');
    }
    catch {
        return null;
    }
};
export const getMd5EmailHash = () => {
    try {
        return localStorage?.getItem('sh-gtmmd-id');
    }
    catch {
        return null;
    }
};
export const persistEmailHash = (email) => {
    if (!email ||
        typeof localStorage === 'undefined' ||
        typeof window === 'undefined') {
        return;
    }
    try {
        const sha256Email = sha256(email);
        const md5Email = md5(email);
        localStorage.setItem('sh-gtmsh-id', sha256Email);
        localStorage.setItem('sh-gtmmd-id', md5Email);
    }
    catch (e) {
        console.error('Failed to persist data:', e);
    }
};
