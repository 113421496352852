import React from 'react';
// Required for getTimeExtension
import dayjs from 'utils/dayjs-duration';
import DateUtils from 'utils/date';
import TimeUtils from 'utils/time';
import SpotDetailsReservationSummary from 'common/spot-details/reservation-summary';
import useIsMobile from 'hooks/use-is-mobile';
import { useSelector } from 'react-redux';
import { usePrice } from 'hooks/spot/price/use-price';
import { multidayWODiscountGenerator } from 'utils/multiday-utils';
import InOutReservationSummary from 'pages/search-transient-event-monthly/components/spot-details-transient/inout-reservarion-summary';
import FormatUtils from 'utils/format';
import { Box, Button } from '@spothero/ui';
import { useLabel } from 'hooks/spot/price/use-label';
import { useShowTotal } from 'store/selectors/hooks/useShowTotal';
export const ReservationSummary = ({ spot, isSticky, isStickyVersionShowing, isNonRefundable, isLoadingRates, showSpotUnavailable, onBookSpotClick, onToggleEditTimesModal, onShowRateDetailsClick, }) => {
    const isMobile = useIsMobile();
    const { starts, ends, powerBooking: isPowerBooking, powerBookingPeriods, partner, } = useSelector((state) => state.searchRequest);
    const showTotal = useShowTotal();
    const price = usePrice(spot);
    const priceLabelString = useLabel();
    const appleMapsPartner = partner === 'apple' || partner === 'appleTest'; // Could move to its own hook
    const { selectedRate, facility: { reservationExtensionEnabled }, } = spot;
    let priceWithoutDiscount;
    let reservationDateTimeFormatted = '';
    let isReservationExtended = false;
    const rateAmenities = selectedRate?.amenities || [];
    const hasInOutAmenity = Boolean(rateAmenities.find(amenity => amenity?.slug === 'in-out'));
    if (selectedRate) {
        const { isExtended } = TimeUtils.getTimeExtension({
            actualStartDateTimeObj: dayjs(selectedRate.starts),
            actualEndDateTimeObj: dayjs(selectedRate.ends),
            requestedStartDateTimeObj: dayjs(starts),
            requestedEndDateTimeObj: dayjs(ends),
            isMonthly: false,
        });
        isReservationExtended = isExtended;
        // Ideally this map can be streamlined when we do full search v2 crossover
        try {
            const currencyCode = price.currencyCode.toUpperCase();
            const priceBreakdown = selectedRate?.price_breakdown.items.map(
            // shorthand used due to scoping
            ({ price: localPrice, type, priceBreakdown: pb }) => ({
                price: {
                    value: localPrice,
                    currencyCode,
                },
                priceBreakdown: pb,
                type,
            }));
            priceWithoutDiscount = multidayWODiscountGenerator(priceBreakdown, showTotal);
        }
        catch (e) {
            // Fail silently
        }
        if (isPowerBooking) {
            const reservationStartTime = powerBookingPeriods?.length
                ? dayjs(powerBookingPeriods[0].starts).format('h:mm A')
                : '';
            const reservationEndTime = powerBookingPeriods?.length
                ? dayjs(powerBookingPeriods[0].ends).format('h:mm A')
                : '';
            reservationDateTimeFormatted = (<Box data-testid="ReservationSummary-power-booking-period">
                    {powerBookingPeriods.length}{' '}
                    {FormatUtils.pluralize(powerBookingPeriods.length, 'day', 'days')}{' '}
                    <Box as="span" color="gray.200" fontWeight="light">
                        |
                    </Box>{' '}
                    {reservationStartTime} - {reservationEndTime}
                </Box>);
            isReservationExtended = false;
        }
        else {
            reservationDateTimeFormatted = DateUtils.getDateTimeSummary({
                startDateTimeObj: dayjs(selectedRate.starts),
                endDateTimeObj: dayjs(selectedRate.ends),
                isMonthly: false,
            });
        }
    }
    // TODO This will need to accurately display info on modal for powerbooking - Part of DRAC-1638
    const priceLabel = (<Button variant="tertiary" display="block" width="100%" text-align="right" onClick={onShowRateDetailsClick} textDecoration="underline" color="text.secondary.light" fontWeight="normal" fontSize="sm" textAlign="right">
            {priceLabelString}
        </Button>);
    return (<SpotDetailsReservationSummary isMobile={isMobile} isLoadingRates={isLoadingRates} displayPrice={price.value} priceLabel={priceLabel} currencyType={price.currencyCode} reservationDateTimeFormatted={reservationDateTimeFormatted} isReservationExtended={isReservationExtended} showSpotUnavailable={showSpotUnavailable} isSticky={isSticky} isShowing={isStickyVersionShowing} showEditTimesButton={appleMapsPartner && isMobile} onBookSpotClick={onBookSpotClick} onEditButtonClick={onToggleEditTimesModal} reservationExtensionEnabled={reservationExtensionEnabled} isNonRefundable={isNonRefundable} inOutReservationSummary={() => selectedRate && (<InOutReservationSummary starts={isPowerBooking
                ? powerBookingPeriods[0]?.starts
                : selectedRate.starts} ends={isPowerBooking
                ? powerBookingPeriods[0]?.ends
                : selectedRate.ends} hasInOutAmenity={hasInOutAmenity}/>)} priceWithoutDiscount={priceWithoutDiscount}/>);
};
