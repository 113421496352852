import { APIRegistry } from '@spothero/utils/api';
import { transformV2SingleFacilityToV1RatesTransient } from 'api/search-v2/transient/format/format-v2-to-v1';
import { formatTransientSpotDetailsResponse } from 'api/search-v2/transient/utils/formatTransientSearchResponse';
import SearchTracking from 'utils/search-tracking';
import ObjectUtils from '@spothero/utils/object';
import { buildCheckoutURL } from 'api/search-v2/utils';
import { getWebExperimentCookie } from 'utils/web-experiment';
import { calculateTotalPrice, getAvailabilityRatesFromBulkRates, } from '../power-booking-utils';
import { setBackendExperiments } from 'utils/session-storage/backend-experiment';
/**
 * Retrieves data for a single bulk search transient facility
 *
 * @function getBulkSearchTransientFacility
 * @see {@link https://api.staging.spothero.com//v2/docs#tag/Search/operation/searchBulkTransientFacilityParking|Documentation}
 * @param {string} facilityId - The id which uniquely identifies a facility
 * @param {object} params - The parameters to pass to the endpoint.
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @example
 * getBulkSearchTransientFacility(123, {lat: 41.8781, lon: -87.6298});
 * @returns {object} The transient facility, with data transformation applied.
 */
export const getBulkSearchTransientFacility = async (facilityId, params, asV2 = false) => {
    const api = APIRegistry.get('craigSearch');
    const { sessionUUID, searchUUID, actionUUID, action, } = SearchTracking.getValues();
    const fingerprint = getWebExperimentCookie();
    const { powerBookingPeriods, vehicleInfoId } = params;
    const postBody = {
        periods: powerBookingPeriods,
        show_unavailable: true,
    };
    const configParams = {
        session_id: sessionUUID,
        search_id: searchUUID,
        action_id: actionUUID,
        action,
        fingerprint,
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'SpotHero-Version': '2024-04-01',
        },
        configParams,
    };
    let postURL = `search/bulk/transient/${facilityId}`;
    if (vehicleInfoId) {
        postURL += `?vehicle_info_id=${vehicleInfoId}`;
    }
    const { data: { result, experiment }, } = await api.post(postURL, JSON.stringify(postBody), config, false);
    setBackendExperiments(experiment);
    const bulkRates = result?.bulk_rates?.length && result?.bulk_rates[0];
    const availability = getAvailabilityRatesFromBulkRates(result?.bulk_rates);
    const rates = bulkRates?.rates;
    const formattedResult = {
        ...result,
        availability,
        rates,
    };
    const formattedResponse = formatTransientSpotDetailsResponse(formattedResult, {
        eventId: params?.eventId,
        rebookId: params?.rebookId,
    });
    if (asV2) {
        // @ts-expect-error - will try to resolve but this is an issue of V1/V2 gobbledy gook
        return formattedResponse;
    }
    const transformedResponse = transformV2SingleFacilityToV1RatesTransient(
    // @ts-expect-error - to remove when function typed
    formattedResponse);
    const { spotId, facility: { slug: facilitySlug }, } = transformedResponse;
    const powerBookingCheckoutURL = buildCheckoutURL({
        spotId,
        facilitySlug,
        rateType: 'powerBooking',
        powerBookingPeriods,
        rateId: transformedResponse?.selectedRate?.rateId,
        powerBookingSource: params?.powerBookingSource || null,
    });
    // Power booking does not support Multirate.
    const hourlyRate = transformedResponse.hourly_rates.length > 1
        ? [transformedResponse.hourly_rates[0]]
        : transformedResponse.hourly_rates;
    return {
        ...transformedResponse,
        ...(transformedResponse?.selectedRate && {
            selectedRate: {
                ...transformedResponse?.selectedRate,
                averagePrice: ObjectUtils.camelizeKeys(result?.average_price),
                subTotal: ObjectUtils.camelizeKeys(result?.subtotal),
                PBTotalPrice: result?.bulk_rates.reduce((prev, curr) => {
                    const rateTotal = calculateTotalPrice(curr.rates);
                    return {
                        value: prev.value + rateTotal.value,
                        currencyCode: rateTotal.currencyCode,
                    };
                }, { value: 0, currencyCode: '' }),
                fullUrl: powerBookingCheckoutURL,
            },
        }),
        bulkPowerBookingRates: result?.bulk_rates,
        hourly_rates: hourlyRate,
    };
};
