import { Flex, Icon, Text } from '@spothero/ui';
import { Calendar as IconCalendar } from '@spothero/icons';
import React from 'react';
import dayjs from 'utils/dayjs-duration';
import FormatUtils from 'utils/format';
const ReservationDuration = ({ starts, ends, ...styleProps }) => {
    const duration = dayjs.duration(dayjs(ends).diff(dayjs(starts)));
    const days = duration.days() > 0
        ? `${duration.format('D')} ${FormatUtils.pluralize(duration.days(), 'day', 'days')}`
        : '';
    const remainingHours = duration.hours() > 0
        ? `${duration.format('H')} ${FormatUtils.pluralize(duration.hours(), 'hour', 'hours')}`
        : '';
    const remainingMinutes = duration.minutes() > 0
        ? `${duration.format('m')} ${FormatUtils.pluralize(duration.hours(), 'minute', 'minutes')}`
        : '';
    let durationText = [days, remainingHours, remainingMinutes]
        .filter(i => i.length)
        .join(', ');
    // If the duration is exactly 1 day, we want to display it as 24 hours
    if (duration.days() === 1 &&
        duration.hours() === 0 &&
        duration.minutes() === 0) {
        durationText = '24 hours';
    }
    return (<Flex {...styleProps} data-testid="ReservationDuration">
            <Icon alt="Calendar" h={5} w={5} color="gray.dark" paddingRight={1} as={IconCalendar}/>

            <Text as="span" variant="body2" color="gray.dark">
                {durationText}
            </Text>
        </Flex>);
};
export default ReservationDuration;
