import dayjs from 'utils/dayjs-timezone';
export const ALWAYS_OPEN_TYPE = '247';
export const formatAccessHours = accessHours => {
    const accessHoursFormatted = [];
    let alwaysOpen = false;
    let accessHoursTmp = accessHours;
    // if accessHours contain text and periods, evaluate text
    // and update accessHours to reflect accessHours.periods
    if (accessHoursTmp?.text) {
        if (accessHoursTmp.text.length &&
            accessHoursTmp.text[0] === 'This facility is open 24/7.') {
            alwaysOpen = true;
        }
        accessHoursTmp = accessHoursTmp.periods;
    }
    if (!alwaysOpen && accessHoursTmp?.length) {
        const { start_dow_int: startDowInt, end_dow_int: endDowInt, start_time: startTime, end_time: endTime, } = accessHoursTmp[0];
        if (startDowInt === endDowInt && startTime === endTime) {
            alwaysOpen = true;
        }
    }
    if (alwaysOpen) {
        accessHoursFormatted.push({
            type: ALWAYS_OPEN_TYPE,
        });
        return accessHoursFormatted;
    }
    accessHoursTmp?.forEach(period => {
        let days = period.start_dow;
        let value;
        if (period.start_dow !== period.end_dow) {
            days = `${period.start_dow} \u2013 ${period.end_dow}`;
        }
        if (period.start_time && period.end_time) {
            const inFormat = 'YYYY-MM-DD HH:mm:ss';
            const outFormat = 'h:mma';
            value = `${dayjs(`2025-01-01 ${period.start_time}`, inFormat).format(outFormat)} \u2013 ${dayjs(`2025-01-01 ${period.end_time}`, inFormat).format(outFormat)}`;
        }
        else if (period.hours_type) {
            value = period.hours_type;
        }
        accessHoursFormatted.push({
            days,
            value,
            type: period.hours_type,
        });
    });
    return accessHoursFormatted;
};
