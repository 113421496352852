import React from 'react';
import PropTypes from 'prop-types';

const OnlineCommuterRateModalContent = ({description, hideTitle}) => {
    return (
        <div className="OnlineCommuterRateModalContent">
            {!hideTitle && <h3>Online Commuter Rate</h3>}
            <p>{description}</p>
        </div>
    );
};

OnlineCommuterRateModalContent.propTypes = {
    description: PropTypes.string.isRequired,
    hideTitle: PropTypes.bool,
};

export default OnlineCommuterRateModalContent;
