import { useDispatch } from 'react-redux';
import { setSearchComponentIsOpen } from 'store/search/search-actions';
import { useGoogleMapsLibraries } from 'common/google-map-context';
import { useFindParkingSpotsButtonPartialStore, useShowLoader, } from '@spothero/search-component';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import { mapToSearchURLDateTimeValues } from 'pages/search/utils/date-utils';
import { handleSearchUpdates } from 'pages/search/utils/search-utils';
import { mapParkingTypes } from 'pages/search/utils/state-mapping-utils';
import { useHistory } from 'react-router';
export const useSearchUpdates = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { bookingType, parkingSubType, timezone, dates, startTime, endTime, startDate, endDate, monthlyStartDate, selectedSuggestion, errorType, } = useFindParkingSpotsButtonPartialStore();
    const closeSearchComponent = () => dispatch(setSearchComponentIsOpen(false));
    const { googleMapsLibraries } = useGoogleMapsLibraries();
    const { setIsLoaderShowing } = useShowLoader();
    const { originalCitySlug, originalLatitude, originalLongitude, originalPageType, originalId, originalSearchString, parkingType: reduxParkingType, powerBookingSource: reduxPowerBookingSource, } = useReduxSearchState();
    const { parkingSubType: originalParkingSubType } = mapParkingTypes(reduxParkingType, reduxPowerBookingSource);
    return async () => {
        setIsLoaderShowing(true);
        // Edge case: User closed the modals with Power Booking validation errors
        // We refresh the browser to clear the error state
        if (!selectedSuggestion ||
            (parkingSubType === 'power_booking' && errorType)) {
            setIsLoaderShowing(false);
            window.location.reload();
            return;
        }
        const dateTimeValues = mapToSearchURLDateTimeValues({
            parkingSubType,
            dateRangeStartDate: startDate,
            dateRangeEndDate: endDate,
            monthlyStartDate,
            powerBookingDates: dates,
            powerBookingStartTime: startTime,
            powerBookingEndTime: endTime,
            timezone,
            bookingType,
        });
        await handleSearchUpdates({
            selectedSuggestion,
            parkingSubType,
            dateTimeValues,
            timezone,
            originalCitySlug,
            originalPageType,
            originalId,
            originalSearchString,
            originalLatitude,
            originalLongitude,
            originalParkingSubType,
            googleMapsLibraries,
            dispatch,
            history,
            onClose: () => {
                setIsLoaderShowing(false);
                closeSearchComponent();
            },
        });
    };
};
