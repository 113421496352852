import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import {Modal, ModalBody, ModalHeader, Flex, Loader} from '@spothero/ui';
import EditTimesModalContent from './EditTimesModalContent';

const EditTimesModalWrapper = ({
    isOpen,
    onClose,
    facility,
    isAdmin,
    timezone,
    starts,
    ends,
    isLoading,
    showSpotUnavailable,
    onBackToSearch,
    showExpandedVersion,
    spotId,
    spots,
    updateSelectedSpot,
    errorLoadingRates,
}) => {
    const [contentKey, setContentKey] = useState(uuidv4());
    const buttonRef = useRef(null);

    const resetContent = () => {
        setContentKey(uuidv4);
    };

    return (
        <Modal
            hasHeader
            hasFooter={false}
            size="sm"
            hideCloseButton={showExpandedVersion}
            closeOnOverlayClick={!showExpandedVersion}
            isOpen={isOpen}
            onCloseComplete={resetContent}
            onClose={onClose}
            initialFocusRef={buttonRef}
        >
            <ModalHeader
                fontWeight="semibold"
                as="span"
                lineHeight="1.4"
                fontSize="lg"
            >
                {showExpandedVersion
                    ? 'Reserve Parking Now and Save'
                    : 'Change Parking Times'}
            </ModalHeader>
            <ModalBody borderRadius="none">
                {!facility && !errorLoadingRates ? (
                    <Flex
                        className="EditTimesModal"
                        flexDirection="column"
                        maxWidth="100%"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                        paddingY={36}
                    >
                        <Loader isInfinite />
                    </Flex>
                ) : (
                    <EditTimesModalContent
                        key={contentKey}
                        onClose={onClose}
                        facility={facility}
                        isAdmin={isAdmin}
                        timezone={timezone}
                        starts={starts}
                        ends={ends}
                        isLoading={isLoading}
                        showSpotUnavailable={showSpotUnavailable}
                        onBackToSearch={onBackToSearch}
                        showExpandedVersion={showExpandedVersion}
                        spotId={spotId}
                        spots={spots}
                        updateSelectedSpot={updateSelectedSpot}
                        buttonRef={buttonRef}
                        errorLoadingRates={errorLoadingRates}
                    />
                )}
            </ModalBody>
        </Modal>
    );
};

EditTimesModalWrapper.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    showExpandedVersion: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    timezone: PropTypes.string,
    starts: PropTypes.string.isRequired,
    ends: PropTypes.string.isRequired,
    facility: PropTypes.shape({
        city: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        operator_display_name: PropTypes.string.isRequired, // eslint-disable-line camelcase
    }),
    showSpotUnavailable: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onBackToSearch: PropTypes.func.isRequired,
    spotId: PropTypes.number,
    spots: PropTypes.array,
    updateSelectedSpot: PropTypes.func.isRequired,
    errorLoadingRates: PropTypes.bool.isRequired,
};

export default EditTimesModalWrapper;
