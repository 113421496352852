import React from 'react';
import { Button, Flex, Loader } from '@spothero/ui';
import { useShowLoader } from '@spothero/search-component';
import { useSearchUpdates } from 'pages/search/hooks/use-search-updates';
export const FindParkingSpotsButton = ({ label = 'Submit', }) => {
    const onSearchUpdates = useSearchUpdates();
    const { isLoaderShowing } = useShowLoader();
    const handleClick = async () => {
        await onSearchUpdates();
    };
    return (<Button width="100%" data-testid="FindParkingSpotsButton-button" onClick={handleClick} variant="primary" height="md" iconSpacing={3} leftIcon={isLoaderShowing && (<Flex alignItems="center" justifyContent="center" width={3.5} height={3.5}>
                        <Loader size={5} thickness={10} color="primary.default" data-testid="FindParkingSpotsButton-loader"/>
                    </Flex>)}>
            {label}
        </Button>);
};
