import { APIRegistry } from '@spothero/utils/api';
import { formatMonthlySpotDetailsResponse } from '../utils/formatMonthlySpotDetailsResponse';
import { transformV2SingleFacilityToV1RatesMonthly } from '../format/format-v2-to-v1';
import SearchTracking from 'utils/search-tracking';
import { getWebExperimentCookie } from 'utils/web-experiment';
import { setBackendExperiments } from 'utils/session-storage/backend-experiment';
/**
 * Retrieves data for a single monthly facility
 *
 * @function getMonthlyFacility
 * @see {@link https://api.spothero.com/v2/docs#operation/searchMonthlyFacilityParking|Documentation}
 * @param {string} facilityId - The id which uniquely identifies a facility
 * @param {object} params - The parameters to pass to the endpoint.
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @example
 * getMonthlyFacility(123, {lat: 41.8781, lon: -87.6298});
 * @returns {object} The monthly facility, with data transformation applied.
 */
export function getMonthlyFacility(facilityId, params, asV2 = false) {
    const api = APIRegistry.get('craigSearch');
    const { sessionUUID, searchUUID, actionUUID, action, } = SearchTracking.getValues();
    const fingerprint = getWebExperimentCookie();
    const response = api
        .get(`search/monthly/${facilityId}`, {
        session_id: sessionUUID,
        search_id: searchUUID,
        action_id: actionUUID,
        action,
        fingerprint,
        ...params,
    })
        // @ts-expect-error - not in craig docs
        .then(({ data: { result, experiment } }) => {
        setBackendExperiments(experiment);
        return formatMonthlySpotDetailsResponse(result, {
            rebookId: params?.rebookId,
        });
    });
    if (asV2) {
        return response;
    }
    else {
        return response.then((res) => {
            // @ts-expect-error - error until we properly type function
            return transformV2SingleFacilityToV1RatesMonthly(res);
        });
    }
}
