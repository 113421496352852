import UrlUtils from '@spothero/utils/url';
import CityAPI from 'api/city';
import SearchAPI from 'api/search';
import SearchUtils, {
    createPowerBookingPeriods,
    getDefaultSearchPageView,
} from 'utils/search-utils';
import {
    loadDataSequence,
    sanitizeSearchRequestResponse,
} from 'store/search-request/search-request-utils';
import {initialState as searchRequestInitialState} from 'store/search-request/search-request-reducer';
import ErrorUtils from 'utils/error-utils';

export async function createCleanedData({
    currentCity,
    cleanedParams,
    currentSearchState,
    checkoutState = {},
}) {
    const {
        page_info: {page_type: pageType, setup},
        rid,
    } = cleanedParams;

    const loadedData = await loadDataSequence({
        pageType,
        setup,
        isNewCity: setup.city.slug !== currentCity.data.slug,
    });

    const isScan2payFacility =
        pageType === 'checkout' && setup.facility.is_scan_to_pay;
    const isScan2payLanding = isScan2payFacility;
    const cleanedData = {
        searchRequest: sanitizeSearchRequestResponse({
            state: {...searchRequestInitialState},
            response: cleanedParams,
        }),
        checkout: {
            ...checkoutState,
            rid,
            isScan2payFacility,
            isScan2payLanding,
        },
        ...loadedData,
    };

    if (!cleanedData.city) {
        cleanedData.city = currentCity;
    }

    cleanedData.search = SearchUtils.prepareStoreData({
        initData: cleanedData,
        pageType,
        currentSearchState,
    });

    return cleanedData;
}

export async function fetchCleanedData({state, route, paramOverrides = {}}) {
    const {
        city,
        search: searchState,
        checkout: checkoutState,
        cloudfront: cloudfrontState,
    } = state;
    const {
        location: {pathname, search},
    } = route;

    const urlParams = UrlUtils.parseParams(search);
    const isPowerBooking = Boolean(urlParams?.power_booking);

    // Send some error messages to Sentry if links coming from Apple Maps somehow have messed up URLs
    if (urlParams.partner === 'apple') {
        if (!urlParams.kind) {
            ErrorUtils.sendSentryMessage({
                customErrorMessage: 'Apple Maps missing URL param',
                additionalData: {missingParam: 'kind'},
            });
        }
        if (urlParams.kind === 'destination' && !urlParams.id) {
            ErrorUtils.sendSentryMessage({
                customErrorMessage: 'Apple Maps missing URL param',
                additionalData: {missingParam: 'destination ID'},
            });
        }
        if (urlParams.kind === 'address' && !urlParams['spot-id']) {
            ErrorUtils.sendSentryMessage({
                customErrorMessage: 'Apple Maps missing URL param',
                additionalData: {missingParam: 'spot ID'},
            });
        }
        if (urlParams.kind === 'address' && !urlParams.latitude) {
            ErrorUtils.sendSentryMessage({
                customErrorMessage: 'Apple Maps missing URL param',
                additionalData: {missingParam: 'latitude'},
            });
        }
        if (urlParams.kind === 'address' && !urlParams.longitude) {
            ErrorUtils.sendSentryMessage({
                customErrorMessage: 'Apple Maps missing URL param',
                additionalData: {missingParam: 'longitude'},
            });
        }
    }

    const isDefaultSearch =
        (pathname === '/search' && !search) ||
        pathname.startsWith('/parking-near-me') ||
        pathname.startsWith('/monthly-parking-near-me');

    let cleanedParams;

    const hasCloudfrontViewerGeoHeader =
        Boolean(cloudfrontState?.data?.latitude) &&
        Boolean(cloudfrontState?.data?.longitude);

    /* ---------------------------------------------------------------------------- *\
        Only show the nearest SpotHero city as a default search experience if it's
        either the `/parking-near-me` or `/search` routes (search route only falls
        into this if there are no query parameters present) AND if the user's
        cloudfront geolocation headers are present
    \* ---------------------------------------------------------------------------- */
    if (isDefaultSearch && hasCloudfrontViewerGeoHeader) {
        const nearestSHCity = CityAPI.getNearestCitiesWithLatLon(
            cloudfrontState,
            1
        );

        cleanedParams = await SearchAPI.cleanParams({
            latitude: nearestSHCity.latitude,
            longitude: nearestSHCity.longitude,
        }).then(response => response.data.data);
    } else {
        cleanedParams = await SearchAPI.cleanParams({
            url: `${pathname}${search || ''}`,
        }).then(response => response.data.data);
    }

    let searchPeriods = [];

    if (isPowerBooking) {
        const periodStarts = urlParams?.starts || cleanedParams?.starts;
        const periodEnds = urlParams?.ends || cleanedParams?.ends;

        searchPeriods = createPowerBookingPeriods({
            starts: periodStarts,
            ends: periodEnds,
        });
    }

    return createCleanedData({
        currentCity: city,
        cleanedParams: {
            ...cleanedParams,
            ...paramOverrides,
            ...(isPowerBooking && {powerBooking: isPowerBooking}),
            ...(isPowerBooking && {powerBookingPeriods: searchPeriods}),
            ...(isPowerBooking && {
                powerBookingSource: urlParams.pb_source || null,
            }),
        },
        currentSearchState: searchState,
        checkoutState,
    });
}

export function routeToSearch({
    method,
    pageType = 'address',
    id = null,
    queryParams = {},
    historyState = {},
}) {
    // TODO: right now the search-params endpoint returns 'search' instead of 'address' for default page type
    // the new endpoint that replaces search-params should return a better aligned mapping to the page types we are
    // expecting in this method, 'search' should become 'address' and 'venue' should become 'destination'
    // venue determination happens on the FE only, as in, a destination that has events
    const kind =
        pageType === 'venue'
            ? 'destination'
            : pageType === 'search'
            ? 'address'
            : pageType;
    const query = UrlUtils.createQueryString({
        kind,
        // pageType 'address' does not have an associated ID
        ...(id && {id}),
        ...queryParams,
    });

    method(`/search?${query}`, historyState);
}

export function routeToSpotDetails({
    method,
    location: {pathname, search},
    spotId,
    historyState = {},
}) {
    const query = UrlUtils.createQueryString({
        ...UrlUtils.parseParams(search),
        // always get the view from the default search page view
        // React-router sometimes is out of sync with the current view
        view: getDefaultSearchPageView(),
        'spot-id': spotId,
    });

    method(`${pathname}?${query}`, {...historyState, reload: false});
}
