import {AIRPORT_TRANSPORTATION_TYPES} from '@/proptypes/index';
const MINIMUM_RATING_COUNT = 10;

export const SPOT_SORT_OPTIONS = Object.freeze({
    RELEVANCE: 'relevance',
    DISTANCE: 'distance',
    RATING: 'rating',
    PRICE: 'price',
    ALPHABETICAL: 'alphabetical',
    FEATURED: 'featured',
    SHUTTLE_TIME: 'shuttleTime',
});

// TODO MR - all functions inputs need typing

/**
 * Compare function for sorting spots by linear distance from destination.
 * Null distances are treated as 0m due to a bug in the back end with calculating
 * the distance of a spot that is at the destination.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort|Array Sort}
 * @function distanceComparator
 * @param {object} spotA - first spot for comparison
 * @param {object} spotB - second spot for comparison
 * @returns {number} 1 if spotA is farther than spotB; -1 if spotA is closer than spotB; 0 if equal
 */
export const distanceComparator = (spotA, spotB) => {
    const distanceA =
        spotA.distance?.durationSeconds ||
        spotA.distance?.walkingMeters ||
        spotA.distance?.linearMeters ||
        0;
    const distanceB =
        spotB.distance?.durationSeconds ||
        spotB.distance?.walkingMeters ||
        spotB.distance?.linearMeters ||
        0;

    return distanceA - distanceB;
};

/**
 * Compare function for sorting spots by average rating.
 * A spot may have a high rating, but not enough reviews to be considered
 * trustworthy, so it will get sorted to the end.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort|Array Sort}
 * @function ratingComparator
 * @param {object} spotA - first spot for comparison
 * @param {object} spotB - second spot for comparison
 * @returns {number} 1 if spotA is rated lower than spotB; -1 if spotA is rated higher than spotB; 0 if equal
 */
export const ratingComparator = (spotA, spotB) => {
    const ratingA =
        spotA.rating.count >= MINIMUM_RATING_COUNT ? spotA.rating.average : 0;
    const ratingB =
        spotB.rating.count >= MINIMUM_RATING_COUNT ? spotB.rating.average : 0;

    return ratingB - ratingA;
};

/**
 * Compare function for sorting spots by advertised price.
 * A spot may not have a selected rate or advertised price
 * if it is unavailable (sold out, blacked out, etc.).
 * In this case we treat it as having the highest possible price
 * it gets sorted to the end.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort|Array Sort}
 * @function advertisedPriceComparator
 * @param {object} spotA - first spot for comparison
 * @param {object} spotB - second spot for comparison
 * @returns {number} 1 if spotA is more expensive than spotB; -1 if spotA is cheaper than spotB; 0 if equal
 */
export const advertisedPriceComparator = (spotA, spotB) => {
    const priceA =
        spotA.selectedRate?.advertisedPrice?.value || Number.MAX_SAFE_INTEGER;
    const priceB =
        spotB.selectedRate?.advertisedPrice?.value || Number.MAX_SAFE_INTEGER;

    return priceA - priceB;
};

/**
 * Compare function for sorting spots alphabetically by title
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort|Array Sort}
 * @function spotTitleComparator
 * @param {object} spotA - first spot for comparison
 * @param {object} spotB - second spot for comparison
 * @returns {number} 1 if spotA is alphabetically after spotB; -1 if spotA is before spotB; 0 if equal
 */
export const spotTitleComparator = (spotA, spotB) => {
    const titleA = spotA.title.toUpperCase(); // ignore upper and lowercase
    const titleB = spotB.title.toUpperCase(); // ignore upper and lowercase

    if (titleA < titleB) {
        return -1;
    }
    if (titleA > titleB) {
        return 1;
    }

    return 0;
};

/**
 * Given an airport tag (generated in the monolith), return the order in which this
 * should be featured. Most Popular should be featured first, followed by Lowest Price,
 * Highest Rated. Any spot without one of these tags comes last.
 *
 * @function getAirportTagOrder
 * @param {string} tag - an optional string describing spot superlatives
 * @returns {number} indicating featured order
 */
const getAirportTagOrder = tag => {
    switch (tag) {
        case 'Most Popular':
            return 0;
        case 'Lowest Price':
            return 1;
        case 'Highest Rated':
            return 2;
        default:
            return 3;
    }
};

/**
 * Given an airport shuttle type, return the order in which this
 * should be featured. Spot with shuttle type scheduled/public transit/on demand/valet has precendence over
 * spots with shuttle type taxi or spot without shuttle.
 *
 * @function getAirportShuttleOrder
 * @param {string} shuttleType - an optional string describing spot shuttle type
 * @returns {number} indicating featured order
 */
const getAirportShuttleOrder = shuttleType => {
    if (!shuttleType || shuttleType === AIRPORT_TRANSPORTATION_TYPES.TAXI) {
        return 1;
    } else {
        return 0;
    }
};

/**
 * Compare function for sorting spots by tag, then by shuttle availability and then advertised price as a tiebreaker
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort|Array Sort}
 * @function airportFeaturedComparator
 * @param {object} spotA - first spot for comparison
 * @param {object} spotB - second spot for comparison
 * @returns {number} 1 if spotA is featured after spotB; -1 if spotA is featured before spotB; 0 if equal
 */
export const airportFeaturedComparator = (spotA, spotB) => {
    const officialParkingOrderA = getAirportOfficialParkingOrder(spotA);
    const officialParkingOrderB = getAirportOfficialParkingOrder(spotB);

    const tagOrderA = getAirportTagOrder(spotA.tag);
    const tagOrderB = getAirportTagOrder(spotB.tag);

    const shuttleOrderA = getAirportShuttleOrder(spotA?.transportation?.type);
    const shuttleOrderB = getAirportShuttleOrder(spotB?.transportation?.type);

    if (officialParkingOrderA !== officialParkingOrderB) {
        return officialParkingOrderA - officialParkingOrderB;
    } else if (tagOrderA !== tagOrderB) {
        return tagOrderA - tagOrderB;
    } else if (shuttleOrderA !== shuttleOrderB) {
        return shuttleOrderA - shuttleOrderB;
    } else {
        return advertisedPriceComparator(spotA, spotB);
    }
};

/**
 * Compare function for sorting spots by transportation duration.
 * All airport spots will have some form of transportation associated with it,
 * however only airport spots with scheduled or on-demand shuttle service
 * will have duration information available.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort|Array Sort}
 * @function airportTransportationComparator
 * @param {object} spotA - first spot for comparison
 * @param {object} spotB - second spot for comparison
 * @returns {number} 1 if spotA is featured after spotB; -1 if spotA is featured before spotB; 0 if equal
 */
export const airportTransportationComparator = (spotA, spotB) => {
    const shuttleDurationA =
        spotA.transportation?.schedule?.duration || Number.MAX_SAFE_INTEGER;
    const shuttleDurationB =
        spotB.transportation?.schedule?.duration || Number.MAX_SAFE_INTEGER;

    return shuttleDurationA - shuttleDurationB;
};

/**
 * Function for indicating order of a spot
 *
 * @function getAirportOfficialParkingOrder
 * @param {object} spot
 * @returns {number} indicating featured order
 */
export const getAirportOfficialParkingOrder = spot => {
    const flags = spot?.visualFlags;
    if (!flags) {
        return 1;
    }

    const officialParking = flags.filter(flag => flag.type === "official_parking");
    if (officialParking.length > 0) {
        return 0;
    } else {
        return 1;
    }
};
