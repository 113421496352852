import pick from 'lodash/pick';
import dayjs from 'utils/dayjs';
import TimeUtils from 'utils/time';
import { buildCheckoutURL, buildExpressCheckoutURL, formatAmenitySlug, formatDateTime, } from '../../utils';
import { OBJECT_TRANSIENT_RATE } from '@/proptypes/index';
import { getPriceBreakdown } from 'utils/multiday-utils';
/**
 * Transform just rate
 */
export function formatTransientRate({ spotId, facilitySlug, quote: { order, advertisedPrice }, additionalRateDetails: { ruleGroupTitle }, additionalRateDetails, userRequestedStarts, userRequestedEnds, eventId, timezone = '', rebookId, }) {
    // only keep properties that have corresponding rate type (transient, monthly, etc) prop types
    // and combine them with the additional rate details for that rate type as well as any additional
    // properties that rate type needs which are derived from other data not in the API
    const baseRate = order[0]; // ! here as there will be a rate returned
    const priceBreakdown = getPriceBreakdown(baseRate);
    const { rateId, starts: originalStarts, ends: originalEnds } = baseRate;
    const starts = formatDateTime(originalStarts, timezone);
    const ends = formatDateTime(originalEnds, timezone);
    // If ever need to add back an oversize message to ParkingRestriction.jsx, this will identify if oversize is being applied
    // const hasOversizedFee = baseRate.items.some(
    //     lineItem => lineItem.type === 'oversize_vehicle_fee_flat'
    // );
    const { isExtended: isAutoExtended } = TimeUtils.getTimeExtension({
        actualStartDateTimeObj: dayjs(starts),
        actualEndDateTimeObj: dayjs(ends),
        requestedStartDateTimeObj: dayjs(userRequestedStarts),
        requestedEndDateTimeObj: dayjs(userRequestedEnds),
        isMonthly: false,
    });
    const formattedRate = pick({
        ...additionalRateDetails,
        ...baseRate,
        starts: formatDateTime(baseRate.starts, timezone),
        ends: formatDateTime(baseRate.ends, timezone),
        priceBreakdown,
        amenities: additionalRateDetails.amenities.map(formatAmenitySlug),
        fullUrl: buildCheckoutURL({
            spotId: String(spotId),
            facilitySlug,
            rateId,
            rateType: 'transient',
            starts: originalStarts,
            ends: originalEnds,
            eventId,
            timezone,
            rebookId,
        }),
        expressCheckoutUrl: buildExpressCheckoutURL({
            spotId: String(spotId),
            rateId,
            rateType: 'transient',
            starts: originalStarts,
            ends: originalEnds,
            eventId,
            timezone,
            rebookId,
        }),
        isAutoExtended,
        title: ruleGroupTitle,
        eventId: eventId || null,
        additionalRateDetails, // We later need these to combine with the quote object when the BE just sends quote in other requests
    }, Object.keys(OBJECT_TRANSIENT_RATE));
    return {
        ...formattedRate,
        advertisedPrice,
        cancellationThresholdMinutes: additionalRateDetails?.cancellationThresholdMinutes,
    };
}
