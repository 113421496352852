import get from 'lodash/get';
import * as Sentry from '@sentry/browser';

// TODO MR - our sentry config here is a bit off so implementing would need a refactor

const ErrorUtils = {
    formatError(error) {
        let errorData = null;
        let errorMessage = null;

        if (typeof error === 'string') {
            return error;
        }

        try {
            // Several of our handled backend errors are returned this way
            errorData = error?.data?.data?.errors?.[0]?.messages?.[0];
        } catch {
            errorData = null;
        }

        if (errorData) {
            errorMessage = errorData;
        } else if (error) {
            if (error.message) {
                errorMessage = error.message;
            } else if (error.statusText) {
                errorMessage = error.statusText;
            } else if (get(error, 'response.data', null)) {
                errorMessage = JSON.stringify(error.response.data);
            } else if (error.status) {
                errorMessage = `${error.status}: ${
                    window?.location?.pathname
                        ? window?.location?.pathname
                        : 'Unknown Page'
                }`;
            }
        }

        if (!errorMessage) {
            errorMessage = 'Unlabeled Error';
        }

        return errorMessage;
    },
    transformObjectToError(errorObject) {
        return errorObject instanceof Error
            ? errorObject
            : new Error(this.formatError(errorObject));
    },
    sendSentryMessage({error, additionalData = {}, customErrorMessage = null}) {
        const errorMessage = this.formatError(error);

        Sentry.configureScope(scope => {
            scope.setExtra('data', {
                caughtError: error,
                ...additionalData,
            });
        });
        Sentry.captureMessage(
            customErrorMessage ? customErrorMessage : errorMessage,
            'error'
        );
    },
    sendSentryException(error) {
        const errorObj = this.transformObjectToError(error);

        Sentry.captureException(errorObj);
    },
    transformAndReject(errorObject) {
        let error = errorObject;

        if (errorObject instanceof Error) {
            this.sendSentryException(errorObject);
        } else {
            error = this.transformObjectToError(errorObject);
        }

        return Promise.reject(error);
    },
};

export default ErrorUtils;
