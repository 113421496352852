import React from 'react';
import { FilterVehicle } from './vehicle/FilterVehicle';
import { FilterAmenity } from '../../filter-bases';
import FilterInOut from './in-out/FilterInOut';
import TotalToggle from './total-toggle';
export const FiltersHourly = () => (<>
        <FilterVehicle />
        <FilterAmenity amenity="self-park">Self Park</FilterAmenity>
        <FilterInOut />
        <TotalToggle />
    </>);
