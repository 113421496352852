import ErrorUtils from 'utils/error-utils';
export const MOBILE_DEVICES_MAX_WIDTH = 767;
export default function getPopup({ maps, map, position: popupPosition, content: popupContentElement, }) {
    // Reference: https://developers.google.com/maps/documentation/javascript/examples/overlay-popup#maps_overlay_popup-javascript
    class Popup extends maps.OverlayView {
        constructor(content) {
            super();
            this.isPanAdjusted = false;
            this.position = popupPosition;
            content.classList.add('SpotHero-popup-bubble');
            // This zero-height div is positioned at the bottom of the bubble.
            const bubbleAnchor = document.createElement('div');
            bubbleAnchor.classList.add('SpotHero-popup-bubble-anchor');
            bubbleAnchor.appendChild(content);
            // This zero-height div is positioned at the bottom of the tip.
            this.containerDiv = document.createElement('div');
            this.containerDiv.classList.add('SpotHero-popup-container');
            this.containerDiv.appendChild(bubbleAnchor);
            // Optionally stop clicks, etc., from bubbling up to the map.
            Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
        }
        /** Called when the popup is added to the map. */
        onAdd() {
            this.getPanes().floatPane.appendChild(this.containerDiv);
        }
        /** Called when the popup is removed from the map. */
        onRemove() {
            if (this.containerDiv.parentElement) {
                this.containerDiv.parentElement.removeChild(this.containerDiv);
            }
        }
        /** Called each frame when the popup needs to draw itself. */
        draw() {
            if (!this.position) {
                return;
            }
            const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
            // Hide the popup when it is far out of view.
            const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                ? 'block'
                : 'none';
            if (display === 'block') {
                const containerHeight = this.containerDiv.offsetHeight;
                this.containerDiv.style.left = `${divPosition.x}px`;
                this.containerDiv.style.top = `${divPosition.y - containerHeight}px`;
            }
            if (this.containerDiv.style.display !== display) {
                this.containerDiv.style.display = display;
            }
            const projection = this.getProjection();
            const position = projection.fromLatLngToDivPixel(this.position);
            this.containerDiv.style.left = `${position.x}px`;
            this.containerDiv.style.top = `${position.y}px`;
            if (!this.isPanAdjusted) {
                this.isPanAdjusted = true;
                this.panToFullView();
            }
        }
        isClipped() {
            const projection = this.getProjection();
            const position = projection.fromLatLngToContainerPixel(this.position);
            // The container width and height on desktop
            const containerWidth = 250;
            const containerHeight = 300;
            const mapWidth = map.getDiv().offsetWidth;
            const mapHeight = map.getDiv().offsetHeight;
            const left = position.x - containerWidth / 2 < 0;
            const right = position.x + containerWidth > mapWidth;
            const top = position.y - containerHeight < 0;
            const bottom = position.y + 20 > mapHeight;
            const clipped = top || bottom || right || left;
            return { clipped, left, right, top, bottom };
        }
        panToFullView() {
            try {
                // The container width is 250px, so half is 125px. But we want to add a little extra room.
                const containerHalfWidth = 150;
                const containerFullHeight = 300;
                const bounds = this.getBounds();
                const { clipped, left, right, top, bottom } = this.isClipped();
                if (clipped) {
                    const projection = this.getProjection();
                    if (!projection) {
                        return false;
                    }
                    const sw = bounds.getSouthWest();
                    const ne = bounds.getNorthEast();
                    // Get the pixel coordinates of the corners relative to the map container
                    const swPixel = projection.fromLatLngToContainerPixel(sw);
                    const nePixel = projection.fromLatLngToContainerPixel(ne);
                    // Calculate the top, bottom, left, and right offsets based on the pixel coordinates
                    const topOffset = swPixel.y;
                    const bottomOffset = map.getDiv().offsetHeight - nePixel.y;
                    const leftOffset = swPixel.x;
                    const rightOffset = map.getDiv().offsetWidth - nePixel.x;
                    // Calculate the x and y offsets
                    let x = 0;
                    let y = 0;
                    if (left) {
                        // popup is clipped on the left side
                        x = leftOffset - containerHalfWidth;
                    }
                    else if (right) {
                        // popup is clipped on the right side
                        x = containerHalfWidth - rightOffset;
                    }
                    if (top) {
                        // popup is clipped on the top side
                        // so offset based on the height of the popup
                        y = topOffset - containerFullHeight;
                    }
                    else if (bottom) {
                        // popup is clipped on the bottom side
                        // just add a little extra room, so it's not right on the edge
                        y = bottomOffset + 20;
                    }
                    map.panBy(x, y);
                }
            }
            catch (error) {
                ErrorUtils.sendSentryMessage({
                    error,
                    customErrorMessage: 'Search Google Map - Error panning map to show full popup',
                });
            }
        }
        getBounds() {
            const projection = this.getProjection();
            const position = projection.fromLatLngToDivPixel(this.position);
            const containerWidth = this.containerDiv.offsetWidth;
            const containerHeight = this.containerDiv.offsetHeight;
            const sw = projection.fromDivPixelToLatLng(new maps.Point(position.x, position.y + containerHeight));
            const ne = projection.fromDivPixelToLatLng(new maps.Point(position.x + containerWidth, position.y));
            return new google.maps.LatLngBounds(sw, ne);
        }
    }
    const pop = new Popup(popupContentElement);
    pop.setMap(map);
    return pop;
}
