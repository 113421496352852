import trackProductListFiltered from 'segment/events/product-list-filtered';
export const trackFilterChange = (storeState, newFilters = {}) => {
    trackProductListFiltered({
        state: storeState,
        filters: {
            ...storeState.filters,
            ...newFilters,
        },
    });
};
