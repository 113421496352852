import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EnvironmentUtils from '@spothero/utils/environment';
import UserUtils from 'utils/user-utils';
import AppStoreUtils from 'utils/app-store';
import Link from 'router/Link';
import {AccountMobileIcon, SmartphoneIcon} from '../icons';
import {Button, Icon, Box} from '@spothero/ui';
import IconTimes from '@spothero/icons/x';
import {withRouter} from 'react-router-dom';

const linkStyling = {
    paddingTop: 3.5,
    paddingBottom: 3.5,
    paddingLeft: 6,
    fontSize: 'base',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    width: '100%',
    color: 'gray.800',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: 6,
    transition: 'none 0s ease 0s',
    borderBottom: '1px solid',
    borderColor: 'accent',
    borderRadius: '0',
};

const iconStyling = {
    fill: 'brandBlue',
    height: 5,
    width: 5,
    marginRight: 0.5,
};

class NavigationCollapsed extends Component {
    static propTypes = {
        user: PropTypes.object,
        isOpen: PropTypes.bool,
        items: PropTypes.arrayOf(PropTypes.object).isRequired,
        onItemSelected: PropTypes.func,
        onCloseClick: PropTypes.func.isRequired,
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this._IS_IOS = EnvironmentUtils.isiOS();
        this._IS_ANDROID = EnvironmentUtils.isAndroid();
        this._secondaryMenus = [];
    }

    _onDownloadLinkClicked = () => {
        if (!this._IS_ANDROID && !this._IS_IOS) {
            return;
        }

        const {
            user: {email},
        } = this.props;
        const args = {
            device: this._IS_IOS ? 'ios' : 'android',
            channel: 'prepurchase',
            ...(email && {email}),
        };

        AppStoreUtils.appStoreLinkClicked(args);
        this.props.onCloseClick();
    };

    _renderSecondaryMenuItem({key, item}) {
        const {onCloseClick} = this.props;

        return (
            <li key={key}>
                <Link
                    to={item.link_path}
                    onClick={onCloseClick}
                    data-testid={`header-menu-mobile-${item?.link_path}`}
                    forceAnchor
                    {...linkStyling}
                >
                    {item?.link_text}
                </Link>
            </li>
        );
    }

    render() {
        const {
            user: {status, isAdmin, isSeller},
            items,
            onCloseClick,
            isOpen,
            history,
        } = this.props;

        const isLoggedIn = status === UserUtils.AUTH_STATE.USER;
        const currentURL = `${history.location.pathname}${history.location.search}`;

        return (
            <Box
                position="fixed"
                width="18rem"
                transform={isOpen ? 'translateX(0)' : 'translateX(100%)'}
                top="0"
                bottom="0"
                right="0"
                margin="auto"
                background="gray.50"
                transition="0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)"
                zIndex="layer6"
                overflow="auto"
                role="navigation"
                data-testid={`header-mobile-navigation`}
                as="ul"
            >
                <li>
                    <Button
                        variant="tertiary"
                        onClick={onCloseClick}
                        marginLeft="auto"
                        paddingRight={6}
                        marginTop={4}
                        display="block"
                        textAlign="right"
                        data-testid={`header-menu-mobile-close`}
                    >
                        <Icon
                            as={IconTimes}
                            color="black"
                            height={3.5}
                            width={3.5}
                            display="inline-block"
                            data-testid={`header-menu-mobile-close-icon`}
                        />
                    </Button>
                </li>
                {isAdmin && (
                    <li>
                        <Link
                            to="/admin"
                            onClick={onCloseClick}
                            forceAnchor
                            data-testid={`header-menu-mobile-admin`}
                            {...linkStyling}
                        >
                            Admin
                        </Link>
                    </li>
                )}
                {isSeller && (
                    <li>
                        <Link
                            to="/control-panel"
                            onClick={onCloseClick}
                            forceAnchor
                            data-testid={`header-menu-mobile-control-panel`}
                            {...linkStyling}
                        >
                            Control Panel
                        </Link>
                    </li>
                )}
                {isLoggedIn ? (
                    <li>
                        <Link
                            to="/account-settings"
                            onClick={onCloseClick}
                            forceAnchor
                            {...linkStyling}
                            fontWeight="semibold"
                            data-testid={`login-status-link-logged-in`}
                        >
                            <Icon
                                alt="Account Mobile Icon"
                                fill="brandBlue"
                                {...iconStyling}
                            >
                                <AccountMobileIcon />
                            </Icon>
                            My Account
                        </Link>
                    </li>
                ) : (
                    <li>
                        <Link
                            to={`/auth/restricted-login?next=${encodeURIComponent(
                                currentURL
                            )}`}
                            onClick={onCloseClick}
                            forceAnchor
                            {...linkStyling}
                            fontWeight="semibold"
                            data-testid={`login-status-link`}
                        >
                            <Icon
                                alt="Account Mobile Icon"
                                fill="brandBlue"
                                {...iconStyling}
                            >
                                <AccountMobileIcon />
                            </Icon>
                            Log In or Sign Up
                        </Link>
                    </li>
                )}
                {items.map((item, i) => {
                    return item.items.map((link, j) => {
                        return this._renderSecondaryMenuItem({
                            key: `menuItem-${i}-${j}`,
                            item: link,
                        });
                    });
                })}
                <li>
                    <Link
                        to="#"
                        onClick={this._onDownloadLinkClicked}
                        forceAnchor
                        {...linkStyling}
                        fontWeight="semibold"
                        data-testid={`header-menu-mobile-get-the-app-link`}
                    >
                        <Icon
                            alt="Account Mobile Icon"
                            fill="brandBlue"
                            data-testid={`header-menu-mobile-get-the-app-logo`}
                            {...iconStyling}
                        >
                            <SmartphoneIcon />
                        </Icon>
                        Get the app
                    </Link>
                </li>
            </Box>
        );
    }
}

export default withRouter(NavigationCollapsed);
