export const footerData = [
    {
        primary: {
            list_title: 'Book Parking',
        },
        items: [
            {
                link_text: 'Chicago Parking',
                link_path: '/city/chicago-parking',
            },
            {
                link_text: 'New York City Parking',
                link_path: '/city/nyc-parking',
            },
            {
                link_text: 'San Francisco Parking',
                link_path: '/city/san-francisco-parking',
            },
            {
                link_text: 'All Locations',
                link_path: '/cities',
            },
            {
                link_text: 'Airport Parking',
                link_path: '/parking/airport-parking',
            },
            {
                link_text: 'Monthly Parking',
                link_path: '/parking/monthly-parking',
            },
            {
                link_text: 'New Parker Promo Code',
                link_path: '/about/promo-code',
            },
            {
                link_text: 'Commuter Benefits',
                link_path: '/solutions/commuter-benefits',
            },
            {
                link_text: 'Touchless Parking',
                link_path: '/about/touchless-parking',
            },
        ],
        id: 'internal_link_list$d2ddb656-0496-4a74-b7ee-d685c0e8f6cc',
        slice_type: 'internal_link_list',
        slice_label: null,
    },
    {
        primary: {
            list_title: 'Sell Parking',
        },
        items: [
            {
                link_text: 'Operators',
                link_path: '/sell-parking/operators',
            },
            {
                link_text: 'Monthly Parking',
                link_path: '/sell-parking/monthly-parking',
            },
            {
                link_text: 'Airport Parking',
                link_path: '/sell-parking/airport-parking',
            },
            {
                link_text: 'Event Partnerships',
                link_path: '/sell-parking/event-parking-partnerships',
            },
            {
                link_text: 'Property Managers',
                link_path: '/sell-parking/property-managers',
            },
            {
                link_text: 'Rent My Parking Space',
                link_path: '/sell-parking/independent-sellers',
            },
            {
                link_text: 'Flex Rates',
                link_path: '/sell-parking/flex-rates',
            },
            {
                link_text: 'Scan2Pay',
                link_path: '/sell-parking/scan2pay',
            },
            {
                link_text: 'SpotHero IQ',
                link_path: '/sell-parking/iq',
            },
        ],
        id: 'internal_link_list$c7c321b4-5679-44fe-a8db-c2629fed384c',
        slice_type: 'internal_link_list',
        slice_label: null,
    },
    {
        primary: {
            list_title: 'Company/Legal',
        },
        items: [
            {
                link_text: 'About Us',
                link_path: '/about',
            },
            {
                link_text: 'Careers',
                link_path: '/careers',
            },
            {
                link_text: 'FAQs',
                link_path: '/faq',
            },
            {
                link_text: 'Press',
                link_path: '/press',
            },
            {
                link_text: 'Blog',
                link_path: 'https://blog.spothero.com/',
            },
            {
                link_text: 'Technology Blog',
                link_path: 'https://technology.spothero.com/',
            },
            {
                link_text: 'Terms & Conditions',
                link_path: '/legal/terms-of-use',
            },
            {
                link_text: 'Privacy Policy',
                link_path: '/legal/privacy-policy',
            },
            {
                link_text: 'Exercise Your Privacy Rights',
                link_path: 'https://privacyportal.onetrust.com/webform/f5788256-225f-4137-a95b-903bb8ac22f0/e4394c68-bb73-4c6c-83ab-74701e53fe04',
            },
            {
                link_text: 'Cookies and Interest-Based Ads',
                link_path: '/legal/cookie-policy',
            },
            {
                link_text: 'Contact Us',
                link_path: '/contact',
            },
        ],
        id: 'internal_link_list$7ac54b3c-f1f1-4bca-814e-c2c8d677aa57',
        slice_type: 'internal_link_list',
        slice_label: null,
    },
    {
        primary: {
            list_title: 'Solutions',
        },
        items: [
            {
                link_text: 'Developer Platform',
                link_path: '/developers',
            },
            {
                link_text: 'SpotHero for Business',
                link_path: '/business/',
            },
        ],
        id: 'internal_link_list$b385e1a5-9949-4a27-b9ff-a4e1df153321',
        slice_type: 'internal_link_list',
        slice_label: null,
    },
];
