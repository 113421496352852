import merge from 'lodash/merge';
import { getWindow } from 'ssr-window';
import EnvironmentUtils from '@spothero/utils/environment';
const window = getWindow();
const apiDateFormat = 'YYYY-MM-DD';
const currencyFormat = '$0,0';
const Config = {
    // local
    isMobile: EnvironmentUtils.isMobileDevice(),
    apiDateFormat,
    apiDateTimeFormat: `${apiDateFormat}THH:mm`,
    appStoreLink: 'http://itunes.apple.com/us/app/spothero/id499097243?mt=8',
    basename: '/',
    craigUrl: 'https://api.spothero.com/v2',
    craigApiDateTimeFormat: `${apiDateFormat}THH:mm:ss`,
    currencyFormat,
    currencyFormatExtended: `${currencyFormat}.00`,
    displayDateFormat: 'MM/DD/YYYY',
    displayEventDateFormat: 'dddd, MMM D, YYYY [at] h:mm a',
    facilityIncludes: 'inventory_availability,facility,facility.amenities_full,facility.details,facility.images,facility.vehicle_size,facility.oversize_description,facility.transient_available,facility.monthly_available,facility.vehicle_restriction_description,facility.oversize_vehicle_type,facility.rating_info,facility.facility_url,facility.supported_fee_types,facility.operator_id',
    integerFormat: '0,0',
    metaLogoUrl: 'https://res.cloudinary.com/spothero/image/upload/v1513282776/consumer-web-spa/spothero-logo-share.jpg',
    militaryTimeFormat: 'HH:mm',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.spothero.spothero',
    spotHeroAPIUrl: '/api/v1',
    standardTimeFormat: 'hh:mm A',
    urlDateFormat: 'MM-DD-YYYY',
    clientIPAddress: null,
    staticBaseUrl: '/',
    isBot: !EnvironmentUtils.isBrowser()
        ? false
        : window.navigator.userAgent.trim().length < 3
            ? true
            : /bot|slurp|ios-native|android-native|crawler|spider|crawling|python-requests|http-kit|scrapy|stripe/i.test(window.navigator.userAgent),
    release: null,
    // from config endpoint
    branchIOApiKey: null,
    facebookAppId: '171344769571965',
    facebookChannelUrl: null,
    googleMapsApiKey: null,
    googleMapsId: null,
    isDeployed: false,
    isProduction: false,
    mobileDateFormat: apiDateFormat,
    mobilePaginationLimit: 30,
    recaptchaSitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    recaptchaSitekeySalesforce: '6LdvICkTAAAAAJ_vB2iBArBAP9iYkeNtm82TmuVP',
    sentryApiPublicKey: 'https://86fa92571fdb4aea90ec328ff01e3885@sentry.io/1186698',
    siteUrl: 'https://spothero.com',
    stripePublicApiKey: 'pk_test_61W6KdctVrYTI4UzY1tvfUUv',
    isSegmentEnabled: false,
    segmentApiKey: 'DSBO42HVekfwO1joUX2uQ9h1kJDEzJKv',
    citySlugs: [],
    cloudfrontViewerCountryCode: 'US',
    gtmAccount: 'GTM-5VQ7Z4',
    redirectCacheTTL: 5 * 60,
    redirectCacheRefreshInterval: 4 * 60,
    isPaypalEnabled: false,
    paypalClientIdUsd: 'AT02yluA_jAUInlT6xcBEddoJdhHjMGb2M23J3CndtKXrasGMWTMktg7RI-RMHsTmEQ9vXp7sYso--6o',
    paypalClientIdCad: 'ASmv5pLdM6MOhpJiD2nO8G0Ony0ma5bJwpUYD_Q2H0HG3Ft7l_Fj2kJZf_-5ZdN_BYKHTSrmnZ7aM7Ip',
    braintreeTokenizationKey: 'sandbox_yk8php4f_nrgy7wgsztzqfry9',
    googlePartnerId: '20002717',
    cloudinaryCloudName: null,
    firebaseAppId: '',
    firebasePublicKey: '',
    firebaseProjectId: '',
    set(opts) {
        opts.firebaseProjectId = opts.isProduction
            ? 'spothero-mobile'
            : 'spothero-mobile-dev';
        // Uncomment the two lines below to enable segment on local machine
        // opts.isSegmentEnabled = true;
        // opts.isDeployed = true;
        merge(this, opts);
    },
};
export default Config;
