import FormatUtils from 'utils/format';
import { FACILITY_TYPES } from 'utils/types/facility';
import { formatRedemptionInstructions, handleCancellation, } from '../utils/format-v2-to-v1-common';
import ObjectUtils from '@spothero/utils/object';
import SpotUtils from 'utils/spot';
import { formatDistance } from '../utils/format-distance';
import { formatAmenitySlug, buildImageTemplate } from '../utils';
import dayjs from 'utils/dayjs-timezone';
import Config from 'config/index';
import { formatHoursOfOperationToV1 } from '../airport/utils/formatHoursOfOperationToV1';
import { formatFacilityRestrictions } from '../airport/utils/formatFacilityRestrictions';
export const transformEventPackageSearchResults = ({ eventPackageResult, eventPackageId, }) => {
    const { availability, distance, facility, rates, vehicle, } = eventPackageResult;
    const { common } = facility;
    const { bulkEventQuote: quote, event } = rates;
    const amenities = event?.amenities?.map(amenity => ({
        ...formatAmenitySlug(amenity),
    })) || [];
    const transformedResults = {
        isV2: true,
        spotId: common?.id,
        title: common?.title || '',
        selectedRate: {
            fullUrl: `/checkout/${common?.id}/event-packages/${eventPackageId}/`,
            advertisedPrice: {
                currencyCode: quote?.advertisedPrice?.currencyCode?.toUpperCase() ||
                    'USD',
                value: quote?.advertisedPrice?.value / quote?.order?.length || 0,
            },
            totalPrice: {
                currencyCode: quote?.totalPrice?.currencyCode?.toUpperCase() || 'USD',
                value: quote?.totalPrice?.value || 0,
            },
            amenities,
            // Below keys are expressly to comply with spotDetailPriceBreakdown.  May be worth a converter function if v2 type detected or something
            priceBreakdown: quote?.items,
            price_breakdown: {
                items: quote?.items?.map(item => ({
                    full_description: item.fullDescription,
                    short_description: item.shortDescription,
                    price: item.price.value,
                    type: '',
                })),
            },
            price: quote?.totalPrice?.value,
        },
        rating: common?.rating,
        images: common?.images?.map(image => ({
            ...image,
            urlTemplate: buildImageTemplate(image).urlTemplate,
        })) || [],
        addresses: common?.addresses || [],
        availability,
        distance,
        facilityType: common?.facilityType || 'unknown',
        allAmenities: amenities,
        vehicle,
    };
    return transformedResults;
};
export const transformEventPackageFacilityDetail = ({ result, }) => {
    const unformattedHoursOfOperation = result.results?.facility?.common?.hours_of_operation;
    const camelizedResultData = ObjectUtils.camelizeKeys(result);
    const { eventPackage, events, results } = camelizedResultData;
    // differences between spot detail and search response
    // images, event package data, total price, currency symbol and type
    const { distance, facility, rates: { bulkEventQuote: quote = {}, event = {} }, } = results;
    const transformedSearchResults = transformEventPackageSearchResults({
        eventPackageResult: results,
        eventPackageId: eventPackage?.eventPackageId,
    });
    const { order = {} } = quote;
    const transformedEvents = events.map(eventItem => {
        const eventId = Number(eventItem?.eventId);
        const eventOrder = order?.find(ord => ord?.eventId === eventId) || {};
        const { priceBreakdown = {} } = eventOrder;
        const formattedPriceItems = priceBreakdown?.items?.map(item => ({
            ...item,
            price: item?.price?.value,
        }));
        return {
            ...eventItem,
            id: eventId,
            price: {
                value: priceBreakdown?.totalPrice?.value,
                priceBreakdown: formattedPriceItems,
                currencyCode: priceBreakdown?.totalPrice?.currencyCode?.toUpperCase(),
            },
        };
    });
    const { common = {}, transient = {} } = facility;
    const finalTransformedDetails = {
        ...transformedSearchResults,
        distance: formatDistance(distance),
        eventPackage: {
            ...eventPackage,
            id: eventPackage?.eventPackageId,
            events: transformedEvents,
        },
        facilityRestrictions: formatFacilityRestrictions(event?.amenities, common?.restrictions),
        // we leave access hours formatted according to v1 data structure
        // because the AccessHours component that displays this data
        // is tightly coupled to it
        accessHoursFormattedV1: SpotUtils.formatAccessHours(formatHoursOfOperationToV1(unformattedHoursOfOperation)),
        navigationTip: common?.navigationTip,
        redemptionInstructions: transient?.redemptionInstructions?.driveUp || [],
        operatorNotes: [],
        adminNotes: [],
        operatorId: common?.operatorId,
        city: common?.addresses?.[0]?.city,
        state: common?.addresses?.[0]?.state,
        cancellationAllowed: handleCancellation(common?.cancellation), // refer airport
    };
    // @ts-expect-error - Pain to fix
    return finalTransformedDetails;
};
export const transformV2toV1EventPackageDetailsResponse = (result) => {
    const { event_package, events = [], results } = result;
    const { facility: { transient, common }, distance, rates, options, availability, } = results;
    const { bulk_event_quote: quote, event } = rates;
    const formattedAmenities = event?.amenities?.map(singleAmenity => {
        const amenity = formatAmenitySlug(singleAmenity);
        return {
            slug: amenity?.type,
            name: amenity?.display_name,
            visible: true,
            icon_url: '', // Not present in V2 response
        };
    });
    const finalResult = {
        data: {
            parking_spot_id: Number(common?.id),
            title: common?.title,
            distance: distance?.linear_meters || 0,
            latitude: common?.addresses?.[0]?.latitude,
            longitude: common?.addresses?.[0]?.longitude,
            lowest_price: 0,
            highest_price: 0,
            lowest_monthly_price: 0,
            highest_monthly_price: 0,
            default_image_url: common?.images?.[0]?.url,
            hourly_rates: [],
            monthly_rates: [],
            bulk_event_rates: quote?.order?.map((eventOrder, index) => {
                const rate = eventOrder?.price_breakdown;
                const v1FormattedStarts = dayjs(rate?.starts)
                    .tz(common?.addresses?.[0]?.time_zone)
                    .format(Config.apiDateTimeFormat);
                const v1FormattedEnds = dayjs(rate?.ends)
                    .tz(common?.addresses?.[0]?.time_zone)
                    .format(Config.apiDateTimeFormat);
                const urlParams = new URLSearchParams(`starts=${v1FormattedStarts}&ends=${v1FormattedEnds}&eid=${eventOrder.event_id}`);
                return {
                    rule_type: FACILITY_TYPES.RULE_TYPES.MULTIRATE,
                    title: 'Default',
                    rule_id: rate?.rate_id,
                    price: rate?.total_price?.value,
                    display_price: rate?.total_price?.value,
                    unavailable: !availability?.available,
                    unavailable_reason: availability?.unavailable_reasons?.[0] || '',
                    rule_group_id: rate?.rate_id,
                    // event_id is required in order to relate rates with its events [WIP- SEAR-3294]
                    event_id: Number(events?.[index]?.event_id),
                    rule_trail: rate?.rate_id,
                    starts: v1FormattedStarts,
                    ends: v1FormattedEnds,
                    url: `/checkout/${common?.id}/${common?.slug}?${urlParams}`,
                    duration: 7,
                    currency_type: rate?.total_price?.currency_code?.toLowerCase(),
                    currency_symbol: FormatUtils.currencySymbol(rate?.total_price?.currency_code?.toLowerCase()),
                    price_breakdown: {
                        total_price: rate?.total_price?.value,
                        currency: rate?.total_price?.currency_code?.toLowerCase(),
                        items: rate?.items?.map(item => ({
                            ...item,
                            price: item?.price?.value,
                        })),
                    },
                    online_commuter_rate: false,
                    online_commuter_rate_description: null,
                    online_commuter_rate_enter_start: null,
                    online_commuter_rate_enter_end: null,
                    amenities: formattedAmenities,
                    parking_type: event?.redemption_type?.replace(/_/g, '-'),
                };
            }),
            license_plate_required: common?.requirements?.license_plate,
            phone_number_required: common?.requirements?.phone_number,
            timezone: common?.addresses?.[0]?.time_zone,
            spot_url: `/checkout/${common?.id}/${common?.slug}`,
            wd_latitude: common?.addresses?.[0]?.latitude,
            wd_longitude: common?.addresses?.[0]?.longitude,
            commuter_card_eligible: options?.commuter_card_eligible,
            publish_to_mobile: null,
            currency_type: quote?.total_price?.currency_code?.toLowerCase(),
            currency_symbol: FormatUtils.currencySymbol(quote?.total_price?.currency_code?.toLowerCase()),
            rating_info: {
                number_of_ratings: common?.rating?.count,
                star_rating: common?.rating?.average,
            },
            available_transient_inventory: availability?.available_spaces,
            spot_notes: [],
            operator_notes: [],
            facility: {
                id: Number(common?.id),
                parking_spot_id: Number(common?.id),
                title: common?.title,
                description: common?.description,
                status: common?.status,
                slug: common?.slug,
                street_address: common?.addresses?.[0]?.street_address,
                city: common?.addresses?.[0]?.city,
                state: common?.addresses?.[0]?.state,
                zipcode: common?.addresses?.[0]?.postal_code,
                latitude: common?.addresses?.[0]?.latitude,
                longitude: common?.addresses?.[0]?.longitude,
                company_id: common?.company_id,
                timezone: common?.addresses?.[0]?.time_zone,
                display_price_on_receipt: true,
                height_restriction: common?.clearance_inches,
                height_restriction_description: null,
                barcode_type: common?.barcode_type,
                post_purchase_instructions: '',
                restrictions: formatFacilityRestrictions(event?.amenities, common?.restrictions),
                hours_of_operation: formatHoursOfOperationToV1(common?.hours_of_operation),
                getting_here: common?.navigation_tip,
                addresses: common?.addresses?.map(address => ({
                    ...address,
                    zipcode: address?.postal_code,
                    id: Number(address?.id),
                    types: address?.types?.map(t => t?.replace(/_/g, '-')),
                })),
                redemption_instructions: transient?.redemption_instructions?.drive_up?.map((instruction, index) => formatRedemptionInstructions(instruction, index + 1)),
                mobile_enabled: null,
                parking_pass_type: event?.parking_pass?.type,
                support_description: '',
                facility_type: {
                    slug: common?.facility_type,
                    display_name: '', // not present
                },
                cancellation_allowed: handleCancellation({
                    allowedByCustomer: common?.cancellation?.allowed_by_customer,
                    allowedBySpotheroCustomerService: common?.cancellation
                        ?.allowed_by_spothero_customer_service,
                }),
                cancellation_minutes: common?.cancellation?.minutes,
                is_scan_to_pay: common?.is_scan_to_pay,
                visual_flags: common?.visual_flags,
                operator_display_name: common?.operator_display_name,
                images: common?.images?.map(image => ({
                    id: image.id,
                    url_template: buildImageTemplate(image).urlTemplate,
                    alt: image.alt_text,
                    version: '',
                    center_x: '',
                    center_y: '',
                    order: '', // Not present in V2 response.
                })),
                allow_updating_reservation_after_entry: common?.allow_updating_reservation_after_entry,
                amenities_full: formattedAmenities,
                facility_url: `/checkout/${common?.id}/${common?.slug}`,
                rating_info: {
                    number_of_ratings: common?.rating?.count,
                    star_rating: common?.rating?.average,
                },
                vehicle_size: '',
                oversize_vehicle_type: null,
                oversize_description: null,
                oversize_fees_charged_onsite: common?.oversize_fees_charged_onsite,
                transient_available: common?.parking_types?.includes('transient'),
                monthly_available: common?.parking_types?.includes('monthly'),
                vehicle_restriction_description: null,
                supported_fee_types: common?.supported_fee_types,
                operator_id: common?.operator_id,
                online_commuter_rate: false,
                online_commuter_rate_description: '',
                online_commuter_rate_enter_start: null,
                online_commuter_rate_enter_end: null,
                reservation_extension_enabled: common?.reservation_extension_enabled,
            },
            event_package: {
                id: Number(event_package?.event_package_id),
                title: event_package?.title,
                description: event_package?.description,
                event_count: events?.length,
                events: events?.map(eventItem => ({
                    ...eventItem,
                    id: Number(eventItem?.event_id),
                })),
            },
        },
    };
    return finalResult;
};
