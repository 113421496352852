import ErrorUtils from 'utils/error-utils';
export class Auth0ServiceInterceptor {
    /**
     * Sets the token generator function that will later be called in the interceptor
     *
     * @param {Function} tokenGenerator - an async function that gets an access token (getAccessTokenSilently())
     * @returns {Promise} which resolves to this Auth0ServiceInterceptor
     */
    setTokenGenerator(tokenGenerator) {
        return new Promise(resolve => {
            this.getToken = tokenGenerator;
            resolve(this);
        });
    }
    /**
     * Resets the token generator function that will later be called in the interceptor. To undo setTokenGenerator after a logout
     */
    resetTokenGenerator() {
        this.getToken = () => {
            return new Promise(resolve => {
                resolve('');
            });
        };
    }
    /**
     * Get the access token.
     * A default token generator function, which is meant to be replaced in setTokenGenerator
     *
     * @see {@link https://auth0.github.io/auth0-react/interfaces/index.gettokensilentlyoptions.html | Auth0 GetTokenSilentlyOptions}
     * @param {object} [_tokenOptions] - options such as audience and scope. If unspecified, the default options will be used.
     * @returns {Promise} which resolves to a string
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getToken(_tokenOptions) {
        return new Promise(resolve => {
            resolve('');
        });
    }
    /**
     * Adds an interceptor to an axios instance that gets a token using the provided options,
     * then attaches the token information in the Authorization header.
     *
     * Different APIs (e.g. V1/Monolith and V2/Craig) will have different audience/scope.
     * Within the category of V1 APIs, multiple axios instances have been created to handle
     * specific needs.
     *
     * If no tokenOptions are specified, the default options (see auth0 initialization) will be used.
     */
    attachInterceptorWithToken(axiosInstance, tokenOptions) {
        axiosInstance.attachInterceptor({
            method: 'request',
            fulfilled: async (config) => {
                const token = await this.getToken(tokenOptions);
                if (token) {
                    return {
                        ...config,
                        headers: {
                            ...config.headers,
                            Authorization: `Bearer ${token}`,
                        },
                    };
                }
                return config;
            },
            rejected: (err) => ErrorUtils.transformAndReject(err),
        });
    }
}
const instance = new Auth0ServiceInterceptor();
export default instance;
