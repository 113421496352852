import { filterSearchResponse } from 'api/search-v2/utils/filter-search-response';
import { formatAirportFacility } from './formatAirportFacility';
/**
 * Formats data for a multiple airport facilities
 *
 * @function formatAirportSpotDetailsResponse
 * @see {@link https://api.spothero.com/v2/docs#operation/searchAirportParking|Documentation}
 * @param {object} searchResults - Facility data returned from the search API for a multiple facilities
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @returns {Array} The array of airport facilities, with data transformation applied.
 */
export function formatAirportSearchResponse(searchResults, formatOptions = {}) {
    let formattedResults = searchResults.map(result => formatAirportFacility(result, {
        ...formatOptions,
        isFacilitySummary: true,
    }));
    if (formatOptions.filterSpot) {
        formattedResults = filterSearchResponse(formattedResults, formatOptions.filterSpot);
    }
    return formattedResults;
}
