import dayjs from 'utils/dayjs';
export const getStartAndEndDateOfPowerBookingPeriod = (powerBookingPeriods) => {
    if (!powerBookingPeriods?.length) {
        return { startingDate: '', endingDate: '' };
    }
    let startingDate = powerBookingPeriods[0].starts;
    let endingDate = powerBookingPeriods[0].ends;
    if (powerBookingPeriods.length > 1)
        powerBookingPeriods.forEach(({ starts: periodStarts, ends: periodEnds }) => {
            if (dayjs(periodStarts).isBefore(dayjs(startingDate))) {
                startingDate = periodStarts;
            }
            if (dayjs(periodEnds).isAfter(dayjs(endingDate))) {
                endingDate = periodEnds;
            }
        });
    return { startingDate, endingDate };
};
