import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Box, Button } from '@spothero/ui';
import SpotImages from 'common/spot-details/spot-images';
import FacilitySummary from 'common/facility-summary/v2';
import { useDispatch, useSelector } from 'react-redux';
import { getParkingType } from 'store/selectors/selectors';
import useDOMReady from './use-domready';
import { MOBILE_DEVICES_MAX_WIDTH } from './Popup';
import { CarouselVariant } from 'common/image-carousel';
import { FACILITY_SUMMARY_VIEW_TYPES } from 'common/facility-summary/utils';
import { useLabel } from 'hooks/spot/price/use-label';
import { routeToCheckout } from 'store/spot/spot-actions';
import { OPTIMIZELY_FEATURE_ON, useFeatureVariation, } from 'plugins/optimizely/hooks/use-feature-variation';
import { IMMEDIATE_FEATURES } from 'utils/experiment';
const GoogleMapPopup = ({ spot, onClick, onClose }) => {
    const dispatch = useDispatch();
    const [carouselHeight, setCarouselHeight] = useState(10);
    const state = useSelector((s) => s);
    const label = useLabel(undefined, true);
    const parkingType = getParkingType(state);
    const isBrowser = typeof window !== 'undefined';
    const isPowerBooking = state.searchRequest.powerBooking;
    const { isReady: isDOMReady, container: parentContainer } = useDOMReady(spot);
    const [container] = useState(() => isBrowser ? document.createElement('div') : null);
    const isMonthly = parkingType === 'monthly';
    const isAirport = parkingType === 'airport';
    const isToCheckout = !(isMonthly || isAirport);
    const isUserInExpressCheckoutExperiment = useFeatureVariation(IMMEDIATE_FEATURES.EXPRESS_CHECKOUT_WEB) ===
        OPTIMIZELY_FEATURE_ON;
    const handleClick = evt => {
        evt.stopPropagation();
        if (evt.target.dataset.testid === 'InfoWindowCTA-button' &&
            isToCheckout)
            return;
        return onClick(spot);
    };
    const handleClose = evt => {
        evt.stopPropagation();
        onClose();
    };
    useEffect(() => {
        if (spot && isDOMReady && parentContainer) {
            parentContainer.appendChild(container);
            // remove unwanted focus on the Details button
            const timeoutHandle = setTimeout(() => {
                document.activeElement?.blur();
            }, 50);
            return () => {
                clearTimeout(timeoutHandle);
            };
        }
    }, [spot, container, isDOMReady, parentContainer]);
    // Set the carousel height to 6rem on mobile devices.
    useEffect(() => {
        const mediaQuery = window.matchMedia(`(max-width: ${MOBILE_DEVICES_MAX_WIDTH}px)`);
        function handleMediaQueryChange(event) {
            if (event.matches) {
                setCarouselHeight(6);
            }
        }
        mediaQuery.addEventListener('change', handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);
        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);
    // If we're server-side rendering, don't render anything.
    if (typeof window === 'undefined') {
        return null;
    }
    return ReactDOM.createPortal(spot ? (<Box data-testid="GoogleMapPopup" width="250px" sx={{
            '& .FacilitySummary': {
                padding: 0,
            },
            '& .FacilitySummary-price-container .apply-fixed-width': {
                width: 'auto',
            },
        }}>
                <Box data-testid="GoogleMapPopup-Images" sx={{
            '@media (max-width: 374px), (max-height: 570px)': {
                display: 'none',
            },
        }}>
                    {isDOMReady && spot.images?.length > 0 ? (<Box position="relative">
                            <Box position="absolute" top={1} right={1} backgroundColor="black" opacity={0.6} borderRadius="50%" zIndex={1} color="white" fontSize={{ base: 'xs', tablet: 'sm' }} fontWeight="semibold" paddingY={1} paddingX={2} cursor="pointer" onClick={handleClose} tabIndex={0}>
                                ✕
                            </Box>
                            <SpotImages key={spot.spotId} images={spot.images} alt={spot.title} urlProperty="urlTemplate" carouselHeight={carouselHeight} carouselVariant={CarouselVariant.SINGLE} reinitializeCarousel/>
                        </Box>) : null}
                </Box>
                <Box data-testid="GoogleMapPopup-FacilitySummary" cursor="pointer" onClick={handleClick} padding={2} sx={{
            '& .controls': {
                display: 'flex',
                justifyContent: 'flex-end',
            },
        }}>
                    <FacilitySummary 
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    spot={spot} priceLabel={label || ''} showPrice showImages={false} showFlair isPowerBooking={isPowerBooking} view={FACILITY_SUMMARY_VIEW_TYPES.SPOT_MAP} CTAs={<Button marginRight={1} data-testid="InfoWindowCTA-button" variant="tertiary" onClick={(e) => {
                e.stopPropagation();
                if (isToCheckout) {
                    // Use router middleware to push the target URL to the history stack
                    return dispatch(routeToCheckout({
                        spot,
                        isUserInExpressCheckoutExperiment,
                    }));
                }
                onClick(spot);
            }}>
                                {isToCheckout ? 'Book Now' : 'Details'}
                            </Button>}/>
                </Box>
            </Box>) : null, container);
};
export default GoogleMapPopup;
