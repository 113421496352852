import React from 'react';
import PropTypes from 'prop-types';
import {
    Flex,
    Text,
    Icon,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Box,
} from '@spothero/ui';
import IconInfoCircle from '@spothero/icons/info-circle-filled';
import IconSemiCircleCheck from '@spothero/icons/checkmark-circle-filled';
import SpotUtils from 'utils/spot';

export const setValueProps = cancellationAllowed => {
    const cancellationValues = {
        text: 'Free Cancellation',
        popoverTitle: 'Free Cancellation',
        popoverContent:
            'At this facility, you have up until the minute before your reservation begins to cancel your parking for a full refund.',
    };

    const guaranteedParkingValues = {
        text: 'Guaranteed parking',
        popoverTitle: 'Guaranteed Parking',
        popoverContent:
            'When you park and pay with SpotHero, we guarantee you will have a spot to park in at the price you paid or your money back.',
    };

    return cancellationAllowed
        ? [{...cancellationValues}, {...guaranteedParkingValues}]
        : [{...guaranteedParkingValues}];
};

const CheckoutSectionMonthlyResponsive = ({
    onBookSpotClick,
    cancellationAllowed,
    showSpotUnavailable,
    displayPrice,
    currencyType,
    priceLabel,
}) => {
    const footerValues = setValueProps(cancellationAllowed);
    const displayPriceFormatted = SpotUtils.formatRatePrice({
        price: displayPrice,
        currencyType,
    });

    return (
        <div className="CheckoutSectionMonthlyResponsive">
            <Box marginLeft="4" marginRight="4" marginTop="4" marginBottom="6">
                <Flex
                    justifyContent="space-between"
                    height="inherit"
                    alignItems="center"
                    paddingBottom="6"
                >
                    <Box>
                        {footerValues.map(
                            ({text, popoverTitle, popoverContent}, index) => (
                                <Flex
                                    key={index}
                                    alignItems="center"
                                    className="ValueProps"
                                >
                                    <Icon
                                        as={IconSemiCircleCheck}
                                        alt="Semi Circle Check Icon"
                                        fontSize="sm"
                                        color="green.700"
                                    />
                                    <Text
                                        fontSize="xs"
                                        fontWeight="normal"
                                        paddingRight="1.5"
                                        paddingLeft="1"
                                    >
                                        {text}
                                    </Text>
                                    <Popover
                                        variant="dark"
                                        placement="top"
                                        flip={false}
                                    >
                                        <Flex
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <PopoverTrigger>
                                                <Button variant="tertiary">
                                                    <Icon
                                                        alt="Info Circle Icon"
                                                        as={IconInfoCircle}
                                                        fontSize="xs"
                                                    />
                                                </Button>
                                            </PopoverTrigger>
                                        </Flex>

                                        <PopoverContent
                                            header={popoverTitle}
                                            data-testid="value-props-popover"
                                            outline="0"
                                        >
                                            {popoverContent}
                                        </PopoverContent>
                                    </Popover>
                                </Flex>
                            )
                        )}
                    </Box>
                    <Flex flexDirection="column">
                        <span
                            className="price"
                            dangerouslySetInnerHTML={{
                                __html: displayPriceFormatted,
                            }}
                        />
                        <span className="price-label">{priceLabel}</span>
                    </Flex>
                </Flex>
                {!showSpotUnavailable && (
                    <Button
                        onClick={onBookSpotClick}
                        textTransform="none"
                        width="100%"
                    >
                        Continue to Checkout
                    </Button>
                )}
            </Box>
        </div>
    );
};

CheckoutSectionMonthlyResponsive.propTypes = {
    onBookSpotClick: PropTypes.func.isRequired,
    cancellationAllowed: PropTypes.bool,
    showSpotUnavailable: PropTypes.bool,
    displayPrice: PropTypes.number,
    currencyType: PropTypes.string,
    priceLabel: PropTypes.string,
};

CheckoutSectionMonthlyResponsive.defaultProps = {
    cancellationAllowed: true,
    showSpotUnavailable: false,
    priceLabel: 'subtotal',
};

export default CheckoutSectionMonthlyResponsive;
