import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
/**
 * This file is used for any duration related dayjs functions by leveraging the duration plugins
 *
 * @module dayjs-duration
 */
dayjs.extend(duration);
dayjs.extend(isBetween);
export default dayjs;
