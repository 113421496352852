import {loadable} from 'router/routes';

const AuthError = loadable(() => import('./Error'));

AuthError.getInitialState = ({state, query}) => {
    return {
        ...state,
        page: {
            error: query.error,
        },
    };
};

export default AuthError;
