import { createSelector } from 'reselect';
import SpotUtils from 'utils/spot';
import { initialReservationTypes, setAmenities, setReservationTypes, setRatings, } from './filters-utils';
import { initialRatingTypes } from './rating-filter-utils';
import isUndefined from 'lodash/isUndefined';
const getIsMonthly = (state) => state.searchRequest.monthly;
const getAllSpots = (state) => state.spots.data;
const getCurrentFilters = (state) => state.filters;
const getAllReservationTypes = (state) => state.filters.reservationTypes;
const getActiveReservationTypes = (state) => state.filters.activeReservationType;
const getAllRatingTypes = (state) => state.filters.ratingTypes;
export const getAmenities = createSelector([getAllSpots, getCurrentFilters], (spots, filters) => {
    if (!spots.length) {
        return {};
    }
    return setAmenities({
        spots,
        filteredSpots: SpotUtils.filterV2(spots, filters),
    });
});
export const getReservationTypes = createSelector([getIsMonthly, getAllSpots, getCurrentFilters, getAllReservationTypes], (isMonthly, spots, filters, reservationTypes) => {
    if (!spots.length || !isMonthly) {
        return initialReservationTypes;
    }
    return setReservationTypes({
        spots: SpotUtils.filterV2(spots, filters),
        reservationTypes,
    });
});
export const getRatings = createSelector([getAllSpots, getCurrentFilters, getAllRatingTypes], (spots, filters, ratingTypes) => {
    if (!spots.length) {
        return initialRatingTypes;
    }
    return setRatings({
        spots: SpotUtils.filterV2(spots, filters),
        ratingTypes,
    });
});
export const getMonthlyReservationTypes = createSelector([getReservationTypes, getActiveReservationTypes], (reservationTypes, activeRefeservationTypes) => {
    let newActiveReservationType = activeRefeservationTypes;
    // check if reservation type is valid
    if (newActiveReservationType !== 'all' &&
        isUndefined(reservationTypes[newActiveReservationType])) {
        newActiveReservationType = 'all';
    }
    return {
        reservationTypes,
        activeReservationType: newActiveReservationType,
    };
});
