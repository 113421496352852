import React from 'react';
import PropTypes from 'prop-types';

/* this string is very tightly coupled to the `facilities.py` serializer. they should be changed in tandem and/or
refactored to not be garbage. */
export const IN_OUT_RESTRICTION =
    'This facility does NOT allow in/out privileges. You CANNOT enter & exit more than once.';

const ThingsYouShouldKnow = ({
    hasInOutAmenity,
    restrictions,
    appleMapsPartner,
}) => {
    if (!restrictions || !restrictions.length) {
        return null;
    }

    return (
        <div
            className="SpotDetails-section ThingsYouShouldKnow"
            data-testid="ThingsYouShouldKnow"
        >
            <h4 className="subtitle">
                {appleMapsPartner
                    ? 'About This Spot'
                    : 'Things You Should Know'}
            </h4>
            <ul className="ThingsYouShouldKnow-list">
                {restrictions.map((restriction, i) => {
                    if (hasInOutAmenity && restriction === IN_OUT_RESTRICTION) {
                        return null;
                    }

                    return (
                        <li key={i} className="ThingsYouShouldKnow-list-item">
                            <span
                                className={
                                    restriction.includes('VIP')
                                        ? 'text-red'
                                        : ''
                                }
                                dangerouslySetInnerHTML={{
                                    __html: restriction,
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

ThingsYouShouldKnow.propTypes = {
    hasInOutAmenity: PropTypes.bool,
    restrictions: PropTypes.arrayOf(PropTypes.string),
    appleMapsPartner: PropTypes.bool,
};

ThingsYouShouldKnow.defaultProps = {
    hasInOutAmenity: false,
    appleMapsPartner: false,
};

export default ThingsYouShouldKnow;
