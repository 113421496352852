import { AmenityTypes } from 'utils/types/amenity';
// type Amenity<T extends CamelizedAmenity | RawAmenity > = T;
/**
 * Formats amenity slugs from V2 Search to match with V1 slug names
 * so filter selections can transfer across search experiences.
 * V2-CLEANUP: We can remove this after all search experiences are on V2, if we want to use the Search V2 Slugs
 *
 * @function formatAmenitySlug
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientParking|Documentation}
 * @param {object} amenity - An object representing a single amenity.
 * @returns {object} The same object that was passed in, with the slug transformed.
 */
export const formatAmenitySlug = (amenity) => ({
    ...amenity,
    type: (() => {
        switch (amenity.type) {
            case 'wheelchair_accessible':
                return AmenityTypes.WHEELCHAIR;
            case 'covered':
                return AmenityTypes.COVERED_PARKING;
            default:
                return amenity.type.replace(/_/g, '-');
        }
    })(),
});
