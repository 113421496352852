import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 * @function renderDisplayHoursComment
 * @param {object} facility - Facility object from a spot
 * @param {string} facility.display_hours_comment - comment to always be shown if passed down
 * @returns {React.Component | null} - React p tag
 */
const renderDisplayHoursComment = ({
    display_hours_comment: displayHoursComment,
}) => {
    if (!displayHoursComment) {
        return null;
    }

    return <p className="AccessHoursDetailsCentered">{displayHoursComment}</p>;
};

/**
 * @function renderPeriods
 * @param {Array} hours - Array containing object of days and values
 * @returns {React.Component} - React p tag
 */
const renderPeriods = hours => {
    return hours.map((period, i) => {
        return (
            <p className="hours-of-operation-period" key={i}>
                <span className="day">{period.days}</span>
                <span>{period.value}</span>
                <br />
            </p>
        );
    });
};

/**
 * @function renderOffRate
 * @param {object} facility - Facility from spot data
 * @param {Array} facility.hours_of_operation - array of text only showing the first string
 * @param {object} rate - Rate from spot data
 * @param {Array} rate.access_hours_formatted - array of access hours, either index 0 is 247 of specific information is the array
 * @returns {React.Component | null} - Access information
 */
const renderOffRate = (
    {hours_of_operation: hoursOfOperation},
    {access_hours_formatted: accessHoursFormatted}
) => {
    if (accessHoursFormatted) {
        return (
            <div className="AccessHoursDetails">
                {accessHoursFormatted.length === 1 &&
                accessHoursFormatted[0].type === '247'
                    ? 'This facility is open 24/7.'
                    : renderPeriods(accessHoursFormatted)}
            </div>
        );
    } else {
        if (hoursOfOperation.text.length > 0) {
            return (
                <p className="AccessHoursDetailsCentered">
                    {hoursOfOperation.text[0]}
                </p>
            );
        } else {
            return null;
        }
    }
};

/**
 * @function renderOffFacility
 * @param {object} rate - Rate from spot data
 * @param {Array} rate.access_hours_formatted - array of access hours, either index 0 is 247 of specific information is the array
 * @returns
 */
const renderOffFacility = ({access_hours_formatted: accessHoursFormatted}) => {
    return (
        <div className="AccessHoursDetails">
            {accessHoursFormatted.length === 1 &&
            accessHoursFormatted[0].type === '247'
                ? 'This facility is open 24/7.'
                : renderPeriods(accessHoursFormatted)}
        </div>
    );
};

const AccessHours = ({className, facility, rate, sectionTitle}) => {
    const classes = classNames('SpotDetails-section', 'AccessHours', className);
    const title = sectionTitle ? sectionTitle : facility.title;

    return (
        <div className={classes} data-testid="AcessHours">
            <h3 className="title">{title}</h3>
            {rate &&
            (rate.access_hours_formatted ||
                rate.display_hours_comment ||
                facility.hours_of_operation.text.length) ? (
                <span>
                    {renderOffRate(facility, rate)}
                    {renderDisplayHoursComment(rate)}
                </span>
            ) : (
                renderOffFacility(facility)
            )}
        </div>
    );
};

AccessHours.propTypes = {
    className: PropTypes.string,
    facility: PropTypes.object.isRequired,
    rate: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /** An optional title to set the section to. If none is set, the facility title is used. */
    sectionTitle: PropTypes.string,
};

export default AccessHours;
