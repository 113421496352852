import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Image, Icon, useTheme} from '@spothero/ui';
import useEmblaCarousel from 'embla-carousel-react';
import IconChevronLeft from '@spothero/icons/chevron-left';
import IconChevronRight from '@spothero/icons/chevron-right';

export const CarouselVariant = {
    SINGLE: 'single',
    MEDIUM: 'medium',
    WIDE: 'wide',
};

export const ImageCarousel = ({
    images,
    width,
    height,
    variant = CarouselVariant.SINGLE,
    reinitialize = false,
    ...props
}) => {
    const imgWidth = width ? `${width}rem` : '100%';
    const imgHeight = `${height}rem`;
    const {colors, shadows} = useTheme();

    // make sure variant isn't some unexpected value, default to "single"
    const carouselVariant = Object.values(CarouselVariant).includes(variant)
        ? variant
        : CarouselVariant.SINGLE;
    const isWide = carouselVariant === CarouselVariant.WIDE;
    const isMedium = carouselVariant === CarouselVariant.MEDIUM;
    const isSingle = carouselVariant === CarouselVariant.SINGLE;
    const carouselImgWidth = isWide ? '36%' : isMedium ? '75%' : '100%';
    const carouselImgMargin = isWide ? 6 : isMedium ? 2 : 0;
    const carouselBorderRadius = isSingle ? '0' : '1rem';

    const options = useMemo(
        () => ({
            skipSnaps: false,
            loop: true,
            slidesToScroll: 1,
            align: isSingle ? 'center' : 'start',
        }),
        [isSingle]
    );

    const [viewportRef, embla] = useEmblaCarousel(options);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        embla.on('select', onSelect);
    }, [embla, onSelect]);

    // Reinitializing Embla is required when using it inside a modal or Google Maps InfoWindow/Popup
    useEffect(() => {
        if (reinitialize && embla) {
            embla.reInit(options);
        }
    }, [embla, options, reinitialize]);

    const LogoStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70%',
        height: '65%',
        background: 'white',
        borderRadius: '2xl',
    };

    const LogoContainerStyles = {
        background: 'gray.200',
        borderRadius: isSingle ? '0' : '2xl',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    if (images.length === 1) {
        const image = images[0];

        return image.isLogo ? (
            <Box
                width={carouselImgWidth}
                {...LogoContainerStyles}
                height={imgHeight}
            >
                <Box {...LogoStyles}>
                    <Image
                        borderRadius={carouselBorderRadius}
                        objectFit="cover"
                        width="50%"
                        src={image.src}
                        alt={image.alt}
                    />
                </Box>
            </Box>
        ) : (
            <Box width={imgWidth} height={imgHeight} {...props}>
                <Image
                    width={carouselImgWidth}
                    borderRadius={carouselBorderRadius}
                    height="100%"
                    objectFit="cover"
                    src={image.src}
                    alt={image.alt}
                />
            </Box>
        );
    }

    return (
        <Box
            as="section"
            position="relative"
            overflow="hidden"
            width={imgWidth}
            borderRadius={carouselBorderRadius}
            {...props}
            data-testid="ImageCarousel"
        >
            <Box ref={viewportRef}>
                <Box display="flex" role="group">
                    {images.map((image, index) => {
                        return (
                            <Box
                                position="relative"
                                flex={`0 0 ${carouselImgWidth}`}
                                marginRight={
                                    isWide &&
                                    images.length === 3 &&
                                    index === images.length - 1
                                        ? 0
                                        : carouselImgMargin
                                }
                                key={index}
                                aria-label={`Slide ${index + 1} of ${
                                    images.length
                                }`}
                                {...(image.isLogo && LogoContainerStyles)}
                            >
                                <Box
                                    position="relative"
                                    height={imgHeight}
                                    {...(image.isLogo && LogoStyles)}
                                    data-testid="ImageCarousel-imagecontainer"
                                >
                                    <Image
                                        borderRadius={carouselBorderRadius}
                                        width={image.isLogo ? '50%' : '100%'}
                                        height={!image.isLogo && '100%'}
                                        objectFit="cover"
                                        src={image.src}
                                        alt={image.alt}
                                    />
                                </Box>
                                {!isSingle ? (
                                    <Box
                                        color="white"
                                        borderRadius="1rem"
                                        bgColor="black"
                                        opacity="0.6"
                                        paddingX={3}
                                        paddingY={1}
                                        fontSize="xs"
                                        position="absolute"
                                        top={3}
                                        right={3}
                                    >
                                        {index + 1}/{images.length}
                                    </Box>
                                ) : null}
                            </Box>
                        );
                    })}
                </Box>
            </Box>
            <Button
                onClick={scrollPrev}
                width="16"
                height="100%"
                position="absolute"
                left="0"
                top="0"
                bg="none"
                borderRadius="0"
                border="0"
                _hover={{
                    bg: isSingle
                        ? 'none'
                        : 'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.318944) 20.83%, rgba(0, 0, 0, 0.204399) 41.67%, rgba(0, 0, 0, 0.0807292) 72.4%, rgba(0, 0, 0, 0) 100%);',
                    border: 0,
                }}
                background="none"
                _focus={{
                    boxShadow: 'none',
                }}
                _focusVisible={{
                    '>div': {
                        border: `2px solid ${colors.gray['50']}`,
                        boxShadow: shadows.outline,
                    },
                }}
                padding="3"
                sx={{
                    '@media print': {
                        display: 'none',
                    },
                }}
            >
                <Box
                    border="2px solid rgba(0,0,0,0.6)"
                    padding={2.5}
                    paddingLeft="0.5625rem"
                    paddingRight="0.6875rem"
                    borderRadius="50%"
                    bgColor="black"
                    opacity="0.6"
                >
                    <Icon
                        as={IconChevronLeft}
                        width={4}
                        height="auto"
                        color="white"
                    />
                </Box>
            </Button>
            <Button
                onClick={scrollNext}
                width="16"
                height="100%"
                position="absolute"
                right="0"
                top="0"
                bg="none"
                borderRadius="0"
                border="0"
                _hover={{
                    bg: isSingle
                        ? 'none'
                        : 'linear-gradient(270deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.318944) 20.83%, rgba(0, 0, 0, 0.204399) 41.67%, rgba(0, 0, 0, 0.0807292) 72.4%, rgba(0, 0, 0, 0) 100%);',

                    border: 0,
                }}
                background={
                    isSingle
                        ? 'none'
                        : 'linear-gradient(270deg, rgba(255, 255, 255, 0.83) 0%, rgba(255, 255, 255, 0.637889) 14.49%, rgba(255, 255, 255, 0.408798) 28.97%, rgba(255, 255, 255, 0.161458) 50.34%, rgba(255, 255, 255, 0) 69.53%)'
                }
                _focus={{boxShadow: 'none'}}
                _focusVisible={{
                    '>div': {
                        border: `2px solid ${colors.gray['50']}`,
                        boxShadow: shadows.outline,
                    },
                }}
                padding="3"
                sx={{
                    '@media print': {
                        display: 'none',
                    },
                }}
            >
                <Box
                    borderRadius="50%"
                    bgColor="black"
                    opacity="0.6"
                    border="2px solid rgba(0,0,0,0.6)"
                    padding={2.5}
                    paddingRight="0.5625rem"
                    paddingLeft="0.6875rem"
                >
                    <Icon
                        as={IconChevronRight}
                        width={4}
                        height="auto"
                        color="white"
                    />
                </Box>
            </Button>
            {isSingle && (
                <Box
                    color="white"
                    borderRadius="1rem"
                    bgColor="black"
                    opacity="0.6"
                    paddingX={3}
                    paddingY={1}
                    fontSize="xs"
                    position="absolute"
                    bottom={3}
                    left="50%"
                    transform="translateX(-50%)"
                    className="ImageCarousal-image-count"
                >
                    {selectedIndex + 1}/{images.length}
                </Box>
            )}
        </Box>
    );
};

ImageCarousel.propTypes = {
    /** Array of images with src and alt */
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
            isLogo: PropTypes.bool,
        })
    ).isRequired,
    /** Height of the carousel in rem */
    height: PropTypes.number.isRequired,
    /** Width of the carousel in rem */
    width: PropTypes.number,
    /** Whether the image carousel should be "single", "medium" or "wide" */
    variant: PropTypes.string,
    /** Whether or not to reinitialize the carousel */
    reinitialize: PropTypes.bool,
};
