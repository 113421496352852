import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Flex, Spinner } from '@spothero/ui';
const Auth0Loading = () => (<Box className="Callback" backgroundColor="gray.light" data-testid="auth0-loading">
        {/* @ts-expect-error - Added until we upgrade helmet */}
        <Helmet>
            <body className="standalone-page"/>
        </Helmet>
        <Flex width="100%" height={{ base: 'calc(100vh - 52px)', desktop: 'calc(100vh - 64px)' }} justifyContent="center" alignItems="center">
            <Spinner thickness={8} height={{ base: '4rem', desktop: '6rem' }} width={{ base: '4rem', desktop: '6rem' }}/>
        </Flex>
    </Box>);
export default Auth0Loading;
