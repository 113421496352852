import PropTypes from 'prop-types';
import { OBJECT_COMMON_FACILITY_SUMMARY, OBJECT_COMMON_FACILITY, OBJECT_COMMON_RATE, PROPTYPE_PRICE, PROPTYPE_REDEMPTION_INSTRUCTION, } from './common';
export const PROPTYPE_MONTHLY_RESERVATION_TYPE = PropTypes.oneOf([
    '247',
    'daytime_only',
    'daytime_and_247_weekend',
    'nights_only',
    'nights_and_247_weekend',
    'storage',
    'valet',
]);
export const PROPTYPE_ACCESS_HOURS = PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    days: PropTypes.string,
    type: PropTypes.string,
}));
export const OBJECT_MONTHLY_RATE = {
    ...OBJECT_COMMON_RATE,
    contract: PropTypes.shape({
        cancellationRequirements: PropTypes.shape({
            noticeDays: PropTypes.number.isRequired,
            noticeRequired: PropTypes.bool.isRequired,
        }).isRequired,
        durationMonths: PropTypes.number.isRequired,
        required: PropTypes.bool.isRequired,
        terminationFee: PROPTYPE_PRICE,
    }).isRequired,
    inOutPrivileges: PropTypes.shape({
        allowed: PropTypes.bool.isRequired,
        description: PropTypes.string.isRequired,
        fee: PROPTYPE_PRICE.isRequired,
    }).isRequired,
    postPurchaseInstructions: PropTypes.string,
    redemptionInstructions: PropTypes.shape({
        longTerm: PropTypes.arrayOf(PROPTYPE_REDEMPTION_INSTRUCTION),
        shortTerm: PropTypes.arrayOf(PROPTYPE_REDEMPTION_INSTRUCTION),
    }),
    monthlyReservationType: PROPTYPE_MONTHLY_RESERVATION_TYPE.isRequired,
    startDateOptions: PropTypes.shape({
        restriction: PropTypes.oneOf(['1st_only', '1st_or_15th', 'none'])
            .isRequired,
        choices: PropTypes.arrayOf(PropTypes.shape({
            ends: PropTypes.string.isRequired,
            starts: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    recurrable: PropTypes.bool,
    termsAndConditionsUrl: PropTypes.string,
    parkingPass: PropTypes.shape({
        type: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
    }),
    activationFee: PROPTYPE_PRICE,
    parkingDelayDays: PropTypes.number,
    separateApplicationType: PropTypes.any,
    description: PropTypes.string,
    accessHours: PROPTYPE_ACCESS_HOURS,
    pricingDetails: PropTypes.string,
    // MR - BELOW KEYS ARE COMMENTED as they are in v1, not in v2 and may be needed but not currently
    // billingInfo: PropTypes.any, // Unsure what this actually is as its null in my example,
    // contactEmail: PropTypes.any, // Unsure what this actually is as its null in my example,
    // contactPhoneNumber: PropTypes.any, // Unsure what this actually is as its null in my example,
    // gettingHere: PropTypes.string,
    // introductory: PropTypes.shape({
    //     rate: PropTypes.bool.isRequired,
    //     period: PropTypes.number.isRequired,
    //     afterPeriodPriceOpt: PropTypes.string.isRequired,
    //     afterPeriodPriceAmount: PropTypes.string.isRequired,
    // }),
    // introductoryRates: PropTypes.any, // Unsure what this actually is as its null in my example
    // hidden: PropTypes.bool.isRequired,
    // newMonthlyRate: PropTypes.bool.isRequired,
    // spotheroCityTitle: PropTypes.string.isRequired,
    // displayHoursComment: PropTypes.string.isRequired,
    // oversizeFee: PropTypes.string.isRequired,
    // vehicleSize: PropTypes.string.isRequired,
    // rateType: PropTypes.string.isRequired,
};
export const OBJECT_MONTHLY_FACILITY_SUMMARY = {
    ...OBJECT_COMMON_FACILITY_SUMMARY,
    allMonthlyReservationTypes: PropTypes.arrayOf(PROPTYPE_MONTHLY_RESERVATION_TYPE),
    selectedRate: PropTypes.shape(OBJECT_MONTHLY_RATE),
};
export const PROPTYPE_MONTHLY_FACILITY_SUMMARY = PropTypes.shape(OBJECT_MONTHLY_FACILITY_SUMMARY);
export const OBJECT_MONTHLY_FACILITY = {
    ...OBJECT_COMMON_FACILITY,
    highestPrice: PROPTYPE_PRICE,
    lowestPrice: PROPTYPE_PRICE,
    rates: PropTypes.arrayOf(PropTypes.shape(OBJECT_MONTHLY_RATE)),
    selectedRate: PropTypes.shape(OBJECT_MONTHLY_RATE),
    operatorId: PropTypes.number,
    allowUpdatingReservationAfterEntry: PropTypes.bool,
    oversizeFeesChargedOnsite: PropTypes.bool,
    barcodeType: PropTypes.string,
    commuterCardEligible: PropTypes.bool,
};
