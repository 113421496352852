import { useSelector } from 'react-redux';
export const useDefaultSuggestion = () => {
    const { city: { data: city }, destination: { data: destination }, event: { data: event }, search: { data: { searchType, destinationTitle }, }, searchRequest: { latitude, longitude }, } = useSelector((state) => state);
    let defaultSuggestion;
    switch (searchType) {
        case 'city':
            defaultSuggestion = {
                description: `${city.city}, ${city.state}`,
                location: {
                    latitude: city.latitude,
                    longitude: city.longitude,
                },
            };
            break;
        case 'event':
            defaultSuggestion = {
                description: event.title,
                id: event.id,
            };
            break;
        case 'destination':
        case 'venue':
            defaultSuggestion = {
                description: `${destination.title}, ${destination.city}, ${destination.state}`,
                location: {
                    latitude: destination.latitude,
                    longitude: destination.longitude,
                },
            };
            break;
        case 'address':
            defaultSuggestion = {
                description: destinationTitle,
                location: {
                    latitude: latitude,
                    longitude: longitude,
                },
            };
            break;
        default:
            defaultSuggestion = undefined;
            break;
    }
    return defaultSuggestion;
};
