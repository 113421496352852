import forEach from 'lodash/forEach';
import { v4 as uuidV4 } from 'uuid';
import { getWindow } from 'ssr-window';
import ErrorUtils from 'utils/error-utils';
import CityAPI from 'api/city';
import DestinationAPI from 'api/destination';
import EventsAPI from 'api/events';
import AirportAPI from 'api/airport';
import { initialState as cityInitialState } from 'store/city/city-reducer';
import { initialState as destinationInitialState } from 'store/destination/destination-reducer';
import { initialState as eventInitialState } from 'store/event/event-reducer';
import { Page } from 'utils/page-utils';
const window = getWindow();
export const sanitizeSearchRequestResponse = ({ state, response, }) => {
    const { monthly: isMonthly, airport, page_info: { page_type: pageType, setup }, } = response;
    const mappedData = {};
    const isSearchPage = pageType === Page.SEARCH ||
        pageType === Page.CITY ||
        pageType === Page.DESTINATION ||
        pageType === Page.EVENT;
    const isAirport = (pageType === Page.DESTINATION && setup.destination.is_airport) ||
        airport;
    forEach(state, (value, key) => {
        // tough to reconsile these two types  may be easier when I understand this functions use - expect error locally but not due to tsconfig cascade
        mappedData[key] = response[key] || value;
    });
    return {
        ...mappedData,
        pageType,
        isSearchPage,
        id: pageType === Page.CHECKOUT ||
            pageType === Page.FACILITY ||
            isSearchPage
            ? uuidV4()
            : null,
        airport: !isMonthly && isAirport,
    };
};
export const loadDataSequence = async ({ pageType, setup, isNewCity = false, }) => {
    const setupData = {
        destination: destinationInitialState(),
        event: eventInitialState(),
    };
    const promises = [
        Promise.resolve(),
        Promise.resolve(),
        Promise.resolve(),
        Promise.resolve(), // airport
    ];
    const { city: { slug: citySlug }, } = setup;
    // if the city slug is different than what is currently saved in the store,
    // load the new city information as well
    if (isNewCity) {
        setupData.city = cityInitialState();
        promises[0] = CityAPI.get(citySlug);
    }
    if (pageType === Page.DESTINATION || pageType === Page.EVENT) {
        promises[1] = setup?.destination?.slug
            ? DestinationAPI.getBySlug(String(citySlug), setup.destination.slug)
            : DestinationAPI.get(String(setup.destination?.id));
        if (pageType === Page.EVENT) {
            promises[2] = EventsAPI.getById(setup.event?.id);
        }
    }
    if (pageType === Page.AIRPORT) {
        const { 
        // will be fixed when destination typed - expect error locally but not due to tsconfig cascade
        destination: { slug }, } = setup;
        promises[3] = AirportAPI.getByCode(slug);
    }
    try {
        const [city, destination, event, airport] = await Promise.all(promises);
        if (city && setupData.city) {
            setupData.city.data = city.data.data;
        }
        if (destination && setupData.destination) {
            setupData.destination.data = destination.data.data;
        }
        if (event && setupData.event) {
            setupData.event.data = event.data.data;
        }
        if (airport && setupData.destination) {
            setupData.destination.data = airport.data.data;
        }
        return setupData;
    }
    catch (error) {
        ErrorUtils.sendSentryException(error);
        if (pageType === Page.EVENT) {
            // in case there are any errors loading event data,
            // redirect the page to the destination URL
            // will be fixed when destination typed - expect error locally but not due to tsconfig cascade
            window.location = `/${citySlug}/${setupData.destination.data.relative_url}`;
        }
        throw error;
    }
};
