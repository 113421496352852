import React from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Text, } from '@spothero/ui';
import Price from 'common/price';
function PriceBreakdownItemAccordion(props) {
    return (<Flex justify="space-between" paddingY="2">
            <Box sx={{
            '& .chakra-accordion__item': {
                border: 'none',
            },
            '& button': {
                padding: 0,
            },
            '& button:focus': {
                boxShadow: 'none',
            },
            '& .chakra-accordion__panel': {
                paddingLeft: 0,
                border: 'none',
            },
            '& .chakra-accordion__icon': {
                color: 'black',
            },
            '& .chakra-accordion__button': {
                gridGap: 1,
                gap: 1,
                '&:hover': {
                    backgroundColor: 'transparent', // Remove hover background color
                },
            },
        }}>
                {props.showExpandableDescriptions &&
            props.fullDescription?.length ? (<Accordion allowToggle variant="contained" index={props.openAccordion === props.shortDescription
                ? 0
                : -1} onChange={props.onChange}>
                        <AccordionItem>
                            <AccordionButton>
                                <Text>{props.shortDescription}</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel>
                                <Text fontSize="sm" variant="caption" color="gray.600">
                                    {props.fullDescription}
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>) : (<Text>{props.shortDescription}</Text>)}
            </Box>
            <Box fontSize="base">
                <Price amount={Number(props.value)} currencyType={props.currencyType} showCents/>
            </Box>
        </Flex>);
}
export default PriceBreakdownItemAccordion;
