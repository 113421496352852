import { useEffect, useState } from 'react';
import { isOversizedFeatureEnabled } from 'utils/search-vehicle';
import { useSelector } from 'react-redux';
import { getParkingType } from 'store/selectors/selectors';
export default function useOversizeEnabled() {
    const [enabled, setEnabled] = useState(false);
    const storeState = useSelector((state) => state);
    const parkingType = getParkingType(storeState);
    useEffect(() => {
        setEnabled(isOversizedFeatureEnabled(parkingType));
    }, [parkingType]);
    return enabled;
}
