import UrlUtils from '@spothero/utils/url';
import { formatDateTime } from './format-date-time';
/**
 * Builds a URL for Airport Spot Details
 *
 * @function buildAirportSpotDetailsURL
 * @param {object} params - An object containing different relevant params for building a valid airport spot details URL.
 * @param {string} params.spotId - The unique identifier for this spot
 * @param {string} params.facilitySlug - The human-readable, hypenated string that describes this spot (not necessarily unique)
 * @param {string} params.starts - The start date/time
 * @param {string} params.ends - The end date/time
 * @param {string} params.timezone - The timezone local to this spot
 * @param {string} params.rebookId - The identifier used when a Customer Hero is rebooking a reservation
 * @returns {string} The airport spot details URL.
 */
export const buildAirportSpotDetailsURL = ({ spotId, facilitySlug, starts, ends, timezone, rebookId, }) => {
    // we need the facility slug, because searchParams API call returns the wrong data otherwise
    const relativeUrl = `/airport-parking/${spotId}/${facilitySlug}/`;
    const urlParams = {
        rebook_reservation_id: rebookId,
        starts: formatDateTime(starts, timezone),
        ends: formatDateTime(ends, timezone),
        airport: true,
    };
    return `${relativeUrl}?${UrlUtils.createQueryString(urlParams)}`;
};
