import includes from 'lodash/includes';
const SearchGeocoderUtils = {
    getAddressData(geocodeData) {
        const { address_components: addressComponents, formatted_address: formattedAddress, geometry, id, place_id: placeId, reference, } = geocodeData;
        const data = {
            search_string: formattedAddress,
            latitude: geometry ? geometry.location.lat() : null,
            longitude: geometry ? geometry.location.lng() : null,
            places_id: id,
            places_place_id: placeId,
            places_reference: reference, // eslint-disable-line camelcase
        };
        if (addressComponents) {
            addressComponents.forEach(item => {
                if (includes(item.types, 'street_number')) {
                    if (!('street' in data)) {
                        data.street = item.long_name;
                    }
                    else {
                        data.street = `${item.long_name} ${data.street}`;
                    }
                }
                else if (includes(item.types, 'route')) {
                    if (!('street' in data)) {
                        data.street = item.long_name;
                    }
                    else {
                        data.street = `${data.street} ${item.long_name}`;
                    }
                }
                else if (includes(item.types, 'locality')) {
                    data.city = item.long_name;
                }
                else if (includes(item.types, 'administrative_area_level_1')) {
                    data.state = item.short_name;
                }
                else if (includes(item.types, 'postal_code')) {
                    data.zipCode = item.long_name;
                }
            });
        }
        return data;
    },
};
export default SearchGeocoderUtils;
