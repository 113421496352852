import React from 'react';
import { Box, Flex, Heading, Text } from '@spothero/ui';
const GoogleMapLoadingError = () => {
    return (<Flex justifyContent="center" alignItems="center" height="100%">
            <Box textAlign="center">
                <Heading as="h4">Oh no! We failed to load the map.</Heading>
                <Text variant="body2">
                    Refresh the page or hit the back button so we can try again.
                </Text>
                <Text variant="body2">
                    Call us at <a href="tel:(844) 324-7768">(844) 324-7768</a>{' '}
                    or email{' '}
                    <a href="mailto:support@spothero.com">
                        support@spothero.com
                    </a>{' '}
                    if you need help!
                </Text>
                <Text variant="body2">
                    You can also check our Twitter page for the latest updates
                    on our site status.
                </Text>
            </Box>
        </Flex>);
};
export default GoogleMapLoadingError;
