import dayjs from 'dayjs';
import { getAsDayjsDate } from './dayjs';
import calendar from 'dayjs/plugin/calendar';
/**
 * This file is used for any calendar related dayjs functions by leveraging the calendar plugins
 *
 * @module dayjs-calendar
 */
dayjs.extend(calendar);
/**
 * Returns a formatted transient date for display to the user. If today, will return 'Today', otherwise the formatted date.
 *
 * @static
 * @function getTransientDateDisplayFormat
 * @param {string} date - The date to format.
 * @example
 * getTransientDateDisplayFormat('09/05/2019');
 * @returns {string} - The formatted date for display.
 */
export const getTransientDateDisplayFormat = (date) => {
    const format = 'ddd, MMM D';
    return getAsDayjsDate(date).calendar(null, {
        sameDay: '[Today]',
        nextDay: format,
        nextWeek: format,
        sameElse: format,
    });
};
/**
 * Returns a formatted monthly date for display to the user.
 *
 * @static
 * @function getMonthlyDateDisplayFormat
 * @param {string} date - The date to format.
 * @example
 * DateUtils.getMonthlyDateDisplayFormat('09/05/2019');
 * @returns {string} - The formatted date for display.
 */
export const getMonthlyDateDisplayFormat = (date) => {
    const format = 'MMM D, YYYY';
    return getAsDayjsDate(date).calendar(null, {
        sameDay: format,
        nextDay: format,
        nextWeek: format,
        sameElse: format,
    });
};
export default dayjs;
