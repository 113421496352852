import isNil from 'lodash/isNil';

import {
    Page,
    fetchCity,
    foldWithSearchState,
    isMonthlyPage,
} from 'utils/page-utils';
import EventsAPI from 'api/events';

/**
 * ### parseParams
 * A function that takes in the route parameters and returns the parsed
 * `destinationSlug` and `citySlug`.
 *
 * @param {{city: string, slug: string}} params - The object contianing the route parameters.
 * @returns {{destinationSlug: string, citySlug: string}} - An object containing the `destinationSlug` (with the potential `-parking` string removed) and `citySlug`.
 */
export const parseParams = params => {
    const destinationSlug = params.slug.replace(/-parking$/i, '');
    const {city: citySlug} = params;

    return {
        destinationSlug,
        citySlug,
    };
};

const getInitialState = async ({state, params, query, cookies, route}) => {
    const {citySlug} = parseParams(params);
    const city = await fetchCity(state, citySlug);
    const destination = state.destination;
    const isMonthly = isMonthlyPage(route);
    const isPowerBooking = Boolean(query?.power_booking);
    const pb_source = query?.pb_source;
    const {
        data: {timezone},
    } = city;

    const {
        data: {
            latitude,
            longitude,
            id: destinationId,
            has_upcoming_events: hasEvents,
        },
    } = destination;

    if (isMonthly) {
        query.monthly = isMonthly;

        return foldWithSearchState({
            state: {
                ...state,
                city,
                destination,
            },
            type: Page.DESTINATION,
            latitude,
            longitude,
            timezone,
            query,
            cookies,
        });
    }

    if (isPowerBooking) {
        query.power_booking = isPowerBooking;

        if (pb_source) {
            query.pb_source = pb_source;
        }
    }

    /* ---------------------------------------------------------------- *\
        Returns the state for non-Prismic Pages (old Destination
        Search experience) for a singel event (eid is present within
        the query - `/search?kind=event&id=1` gets translated to
        `/search?eid=1` within Search.getInitialState).
    \* ---------------------------------------------------------------- */
    if (!isNil(query.eid)) {
        const {data: event} = await EventsAPI.getById(query.eid);

        return foldWithSearchState({
            state: {
                ...state,
                city,
                destination,
                event,
            },
            type: Page.EVENT,
            latitude,
            longitude,
            timezone,
            query: {
                ...query,
                starts: event.starts,
                ends: event.ends,
            },
            cookies,
        });
    }

    // Returns the State for non-Prismic Destination ages (old Search experience)
    if (isNil(query.eid)) {
        if (hasEvents) {
            destination.data.events = await EventsAPI.get({destinationId}).then(
                res => res.data.data.results
            );
        }

        return foldWithSearchState({
            state: {
                ...state,
                city,
                destination,
            },
            type: hasEvents ? 'venue' : Page.DESTINATION,
            latitude,
            longitude,
            timezone,
            query,
            cookies,
        });
    }
};

export default getInitialState;
