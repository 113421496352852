import React from 'react';
import PropTypes from 'prop-types';
import {DESTINATION_DATA} from '@/proptypes/index';
import withConfig from '@/config/withConfig';

export const EventSchema = ({
    url,
    starts,
    ends,
    metaName,
    metaDescription,
    destination: {
        latitude,
        longitude,
        street_address: address,
        city,
        state,
        description,
        relative_url: destinationUrl,
        title: destinationTitle,
    },
    city: {relative_url: cityUrl, slug: citySlug},
    siteUrl,
}) => {
    return (
        <>
            <meta itemProp="name" content={metaName} />
            <meta itemProp="startDate" content={starts} />
            <meta itemProp="endDate" content={ends} />
            <meta itemProp="url" content={`${siteUrl}/${url}`} />
            {metaDescription && (
                <span
                    className="hidden"
                    itemProp="description"
                    dangerouslySetInnerHTML={{__html: metaDescription}}
                />
            )}
            <span
                itemProp="location"
                itemScope
                itemType="http://schema.org/Place"
            >
                <meta itemProp="name" content={destinationTitle} />
                <meta
                    itemProp="url"
                    content={`${siteUrl}/${citySlug}/${destinationUrl}`}
                />
                <span
                    className="hidden"
                    itemProp="description"
                    dangerouslySetInnerHTML={{__html: description}}
                />
                <span
                    itemProp="geo"
                    itemScope
                    itemType="http://schema.org/GeoCoordinates"
                >
                    <meta itemProp="latitude" content={latitude} />
                    <meta itemProp="longitude" content={longitude} />
                </span>
                {(address || city || state) && (
                    <span
                        itemProp="address"
                        itemScope
                        itemType="http://schema.org/PostalAddress"
                    >
                        {address && (
                            <meta itemProp="streetAddress" content={address} />
                        )}
                        {city && (
                            <meta itemProp="addressLocality" content={city} />
                        )}
                        {state && (
                            <meta itemProp="addressRegion" content={state} />
                        )}
                    </span>
                )}
                <span
                    itemProp="containedInPlace"
                    itemScope
                    itemType="http://schema.org/City"
                >
                    <meta itemProp="name" content={city} />
                    <meta itemProp="url" content={`${siteUrl}/${cityUrl}`} />
                </span>
            </span>
        </>
    );
};

EventSchema.propTypes = {
    url: PropTypes.string.isRequired,
    starts: PropTypes.string.isRequired,
    ends: PropTypes.string.isRequired,
    metaName: PropTypes.string.isRequired,
    metaDescription: PropTypes.string,
    destination: PropTypes.shape(DESTINATION_DATA).isRequired,
    city: PropTypes.object.isRequired,
    siteUrl: PropTypes.string.isRequired,
};

export default withConfig(['siteUrl'])(EventSchema);
