import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
function trackEventListItemSelected({ source, index, eventId, eventName, }) {
    const properties = {
        source,
        index,
        event_id: eventId,
        event_name: eventName,
    };
    try {
        SegmentUtils.track({
            event: 'Event List Item Selected',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 1,
                    },
                },
            },
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackEventListItemSelected',
        });
    }
}
export default trackEventListItemSelected;
