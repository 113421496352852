import { APIRegistry } from '@spothero/utils/api';
import { formatAirportSearchResponse } from '../utils/formatAirportSpotDetailsResponse';
import { setBackendExperiments } from 'utils/session-storage/backend-experiment';
/**
 * Searches for available airport parking.
 *
 * @function getAirportFacilities
 * @see {@link https://api.spothero.com/v2/docs#operation/searchAirportParking|Documentation}
 * @param {object} params - The parameters to pass to the endpoint.
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @example
 * getAirportFacilities({iata: 'ORD});
 * @returns {Array} The available airport facilities, with data transformation applied.
 */
export function getAirportFacilities(params, formatOptions) {
    const api = APIRegistry.get('craigSearch');
    return api
        .get('search/airport', { oversize: false, ...params })
        .then(({ 
    // @ts-expect-error - not in craig docs
    data: { results, experiment }, }) => {
        setBackendExperiments(experiment);
        return formatAirportSearchResponse(results, formatOptions);
    });
}
