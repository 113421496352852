import { ActionType } from 'redux-promise-middleware';
import { INELIGIBLE_PERMISSIONS } from './sms-permissions-constants';
import { CHECKOUT_UPDATE_SMS_PERMISSION, CHECKOUT_SMS_PERMISSION, RESET_SMS_PERMISSIONS, } from './sms-permissions-actions';
import { CHECKOUT_CLEAR_CUSTOMER } from '../checkout/checkout-actions';
import { USER_LOGOUT } from '../user/user-actions';
export const initialState = () => ({
    isPending: null,
    data: INELIGIBLE_PERMISSIONS,
});
export default function SMSPermissionsReducer(state = initialState(), { type, payload }) {
    switch (type) {
        case `${CHECKOUT_SMS_PERMISSION}_${ActionType.Fulfilled}`:
        case `${CHECKOUT_UPDATE_SMS_PERMISSION}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                isPending: false,
                data: payload,
            };
        }
        case `${CHECKOUT_SMS_PERMISSION}_${ActionType.Pending}`:
        case `${CHECKOUT_UPDATE_SMS_PERMISSION}_${ActionType.Pending}`: {
            return {
                ...state,
                isPending: true,
            };
        }
        case `${CHECKOUT_SMS_PERMISSION}_${ActionType.Rejected}`:
        case `${CHECKOUT_UPDATE_SMS_PERMISSION}_${ActionType.Rejected}`: {
            return {
                ...state,
                isPending: false,
                data: INELIGIBLE_PERMISSIONS,
            };
        }
        case `${USER_LOGOUT}_${ActionType.Fulfilled}`:
        case RESET_SMS_PERMISSIONS:
        case CHECKOUT_CLEAR_CUSTOMER: {
            return {
                ...state,
                data: INELIGIBLE_PERMISSIONS,
            };
        }
        default: {
            return state;
        }
    }
}
