import SegmentUtils from 'utils/segment';
import dayjs from 'utils/dayjs';
import {
    getQueryValue,
    createTopSearchResultsList,
} from '../utils/top-search-results';
import ErrorUtils from 'utils/error-utils';
import {
    getTotalPriceUserPreference,
    TotalPriceSessionPreference,
} from 'utils/total-price-session-utils';

export default function trackSearchHourlyFromMonthlyClicked({
    city,
    destination,
    unfilteredSpotsData,
    searchRequest,
}) {
    const {title: destinationName, airport} = destination;
    const airportName = airport ? airport.airport_name : null;
    const {search_string: searchQuery, starts, pageType} = searchRequest;
    const {display_name: cityName, slug: citySlug} = city;
    const showTotal =
        getTotalPriceUserPreference() === TotalPriceSessionPreference.On;
    const query = getQueryValue({
        pageType,
        cityName,
        destinationName,
        airportName,
        searchQuery,
    });

    const topSearchResultsList = createTopSearchResultsList({
        spots: unfilteredSpotsData,
        airport,
        parkingType: 'monthly',
        isMonthly: true,
    });

    const properties = {
        top_search_results_list: topSearchResultsList,
        query,
        city: citySlug,
        count_top_search_results_list: topSearchResultsList.length,
        search_start_time_utc: dayjs(starts).toISOString(),
        total_price_toggle_on: showTotal,
    };

    try {
        SegmentUtils.track({
            event: 'Search Hourly from Monthly',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 2,
                    },
                },
            },
        });
    } catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage:
                'Segment Event Failed - trackSearchHourlyFromMonthlyClicked',
        });
    }
}
