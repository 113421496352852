import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DOMUtils from '@spothero/utils/dom';
import Price from 'common/price';
import AccessHours from '../access-hours';

export default class RateDetailsModalContent extends Component {
    static propTypes = {
        rate: PropTypes.object.isRequired,
        facility: PropTypes.object.isRequired,
        section: PropTypes.oneOf(['contract', 'in-out', 'intro-rate']),
    };

    componentDidMount() {
        const {section} = this.props;

        if (section) {
            const highlighted = DOMUtils.el(`.${section}`);

            if (highlighted) {
                DOMUtils.addClass(highlighted, 'highlight');
            }
        }
    }

    _renderIntroductoryRate() {
        const {introductory, currency_type: currencyType} = this.props.rate;

        return (
            <div className="intro-rate">
                <h4 className="RateDetailsSubtitle">Intro Rate</h4>
                <p>
                    This intro rate, offered by the parking facility, is good
                    for {introductory.period} months.
                    <br />
                    <strong>
                        After {introductory.period} months,
                        {introductory.after_period_price_opt === 1 ? (
                            introductory.after_period_price_amount === 0 ? (
                                'this rate is subject to change.'
                            ) : (
                                <>
                                    {' '}
                                    your rate will increase to{' '}
                                    <Price
                                        amount={
                                            introductory.after_period_price_amount
                                        }
                                        currencyType={currencyType}
                                    />
                                    .
                                </>
                            )
                        ) : (
                            'you will be charged the standard facility rate.'
                        )}
                    </strong>
                </p>
            </div>
        );
    }

    _renderContractRequired() {
        const {
            rate: {contract, currency_type: currencyType},
        } = this.props;
        const cancellationRequired =
            contract.cancellation && contract.cancellation.required
                ? `If you cancel your contract, a ${contract.cancellation.period}-day notice is required.`
                : null;

        return (
            <div className="contract">
                <h4 className="RateDetailsSubtitle">Contract Requirements</h4>
                <p>
                    <strong>
                        This location requires a {contract.period} month
                        commitment. There is
                        {contract.termination_fee ? (
                            <>
                                {' '}
                                a{' '}
                                <Price
                                    amount={contract.termination_fee}
                                    currencyType={currencyType}
                                />{' '}
                            </>
                        ) : (
                            ' no '
                        )}
                        fee for cancellation.
                    </strong>
                    {cancellationRequired}
                </p>
            </div>
        );
    }

    _renderInOut() {
        const {
            rate: {currency_type: currencyType, in_out: inOut},
        } = this.props;

        if (!inOut) {
            return;
        }

        const inOutAllowed = inOut.allowed;
        let inOutCopy = null;

        switch (inOutAllowed) {
            case 'yes':
                inOutCopy = `Unlimited In/Out privileges.`;
                break;

            case 'no':
                inOutCopy = `In/Out NOT allowed. Once you leave, that's it, my friend.`;
                break;

            case 'yes_fee':
                inOutCopy = (
                    <>
                        There is a{' '}
                        <Price amount={inOut.fee} currencyType={currencyType} />{' '}
                        charge for each in/out.
                    </>
                );
                break;

            case 'yes_limited':
                inOutCopy = `In/outs are limited to ${inOut.limit} per ${inOut.limit_type}.`;
                break;

            case 'yes_limited_fee':
                inOutCopy = (
                    <>
                        In/outs are limited to {inOut.limit} per{' '}
                        {inOut.limit_type}. Additional in/outs are allowed for a{' '}
                        <Price amount={inOut.fee} currencyType={currencyType} />{' '}
                        fee.
                    </>
                );
                break;
        }

        return (
            <div className="in-out">
                <h4 className="RateDetailsSubtitle">
                    In/Out Privileges{' '}
                    {inOutAllowed === 'yes_fee' ||
                    inOutAllowed === 'yes_limited_fee'
                        ? 'with Access Fee'
                        : null}
                </h4>
                {inOutCopy && <p>{inOutCopy}</p>}
            </div>
        );
    }

    render() {
        const {rate, facility} = this.props;
        const {
            title,
            access_hours_formatted: accessHoursFormatted,
            display_hours_comment: displayHoursComment,
            introductory,
            contract,
        } = rate;

        return (
            <div className="RateDetailsModalContent">
                <h2 className="RateDetailsRateTitle">{title}</h2>
                {accessHoursFormatted || displayHoursComment ? (
                    <span>
                        <h4 className="RateDetailsSubtitle">Access Hours</h4>
                        <AccessHours facility={facility} rate={rate} />
                    </span>
                ) : null}
                {introductory && introductory.rate
                    ? this._renderIntroductoryRate()
                    : null}
                {contract && contract.required
                    ? this._renderContractRequired()
                    : null}
                {this._renderInOut()}
            </div>
        );
    }
}
