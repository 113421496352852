import {initialState as searchRequestInitialState} from 'store/search-request/search-request-reducer';
import {v4 as uuidV4} from 'uuid';
import SearchUtils from 'utils/search-utils';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import CityAPI from 'api/city';

export const Page = Object.freeze({
    AIRPORT: 'airport',
    CHECKOUT: 'checkout',
    CITY: 'city',
    DESTINATION: 'destination',
    EVENT: 'event',
    EVENT_PACKAGES_VENUE: 'event_packages_venue',
    EVENT_PACKAGES_SEARCH: 'event_packages_search',
    FACILITY: 'facility',
    HOME: 'home',
    REDIRECT: 'redirect',
    SEARCH: 'search',
    VENUE: 'venue',
    PARKING_PASS: 'parking_pass',
    PROMOTION: 'promotion',
});

export const parseSlug = slug => `${slug || ''}`.replace(/-parking$/, '');

export function foldWithSearchState({
    state,
    type,
    latitude,
    longitude,
    timezone,
    query = {},
}) {
    const nextState = {
        ...state,
        searchRequest: {
            ...searchRequestInitialState,
            monthly: query.monthly === true || query.monthly === 'true',
            powerBooking:
                query.power_booking === true || query.power_booking === 'true',
            powerBookingSource: query.pb_source || null,
            latitude,
            longitude,
            id: uuidV4(),
            pageType: type,
            filterSpot: query.filterSpot,
            partner: query.partner,
            // eslint-disable-next-line camelcase
            operator_id: query.operator_id,
            ...SearchUtils.createTimeWindowParams(type, query, timezone),
        },
    };

    return {
        ...nextState,
        search: {
            data: {
                ...SearchUtils.defaultSearchSettings,
                searchType: type,
                breadcrumbs: SearchUtils.prepareBreadcrumbs(nextState, type),
            },
        },
    };
}

// cityParam can be a slug or ID
export function fetchCity(state, cityParam) {
    const {slug} = state.city.data;
    const citySlug = parseSlug(cityParam);

    // if `state` already has the city with this slug, use that instead of calling the API
    return isNil(slug) || !isEqual(citySlug, slug)
        ? CityAPI.get(citySlug).then(res => res.data)
        : state.city;
}

export function isMonthlyPage(route) {
    // if "monthly" is the second part of the URL then set as a monthly search
    return route?.location?.pathname.split('/')[2] === 'monthly';
}

/**
 * Remove trailing slash from an absolute URL or path
 *
 * @param {string} path or absolute URL
 * @returns {string} path without trailing slash
 */
export function removeTrailingSlash(path) {
    return path.replace(/\/$/, '');
}

/**
 * Get base url without query parameters
 *
 * @param {string} url (window.location.href)
 * @returns {string} Base url without parameters
 */
export function getBaseUrl(url) {
    return url.split('?')[0];
}

/**
 * Formats a url into a canonical url for seo.
 * Removes params and any trailing slash.
 *
 * @param {string} url url as a
 * @returns {*} canonical url without any potential trailing slashes
 */
export function getCanonicalUrl(url) {
    const baseUrl = getBaseUrl(url);
    const canonicalUrl = removeTrailingSlash(baseUrl).toLowerCase();

    return canonicalUrl;
}
