import CityAPI from 'api/city';
import AirportAPI from 'api/airport';
import {Page, foldWithSearchState} from 'utils/page-utils';

const getInitialState = async ({state, params, query}) => {
    const citySlug = params.city;
    const {data: city} = await CityAPI.get(citySlug);
    const iata = params.iata;
    const {data: destination} = await AirportAPI.getByCode(iata);

    const {
        data: {timezone},
    } = city;

    const {
        data: {latitude, longitude},
    } = destination;

    return foldWithSearchState({
        state: {...state, city, destination},
        type: Page.AIRPORT,
        latitude,
        longitude,
        timezone,
        query,
    });
};

export default getInitialState;
