import APIUtils from '@spothero/utils/api';
const CreditCardAPI = {
    // unknown as its a void return
    // https://spothero.com/api/v1/docs/endpoints/#!/Users/delete_users_user_id_credit_cards_card_external_id_delete
    remove({ userId, cardExternalId }) {
        return APIUtils.delete(`/users/${userId}/credit-cards/${cardExternalId}/`, {
            headers: { 'SpotHero-Version': '2022-02-22' },
        });
    },
    // https://spothero.com/api/v1/docs/endpoints/#!/Users/patch_users_user_id_credit_cards_card_external_id_patch
    setAsDefault({ userId, cardExternalId, isDefault = true, }) {
        return APIUtils.patch(`/users/${userId}/credit-cards/${cardExternalId}/`, {
            is_default: isDefault,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'SpotHero-Version': '2022-02-22',
            },
        });
    },
};
export default CreditCardAPI;
