import { APIRegistry } from '@spothero/utils/api';
import SearchTracking from 'utils/search-tracking';
import { formatAirportFacilityV2Response } from '../utils/formatAirportFacilityV2Response';
import ErrorUtils from 'utils/error-utils';
import { getWebExperimentCookie } from 'utils/web-experiment';
import { formatAirportFacility } from '../utils/formatAirportFacility';
import { transformV2SingleFacilityToV1RatesAirport } from 'api/search-v2/airport/format/format-v2-to-v1';
import { setBackendExperiments } from 'utils/session-storage/backend-experiment';
/**
 * Retrieves data for a single airport facility
 *
 * @function getAiportFacilityV2
 * @see {@link https://api.spothero.com/v2/docs#tag/Search/operation/searchAirportFacilityParking|Documentation}
 * @example
 * getAiportFacilityV2(123, {lat: 41.8781, lon: -87.6298});
 */
export const getAiportFacilityV2 = async (facilityId, params) => {
    const api = APIRegistry.get('craigSearch');
    const { sessionUUID, searchUUID, actionUUID, action, } = SearchTracking.getValues();
    const fingerprint = getWebExperimentCookie();
    try {
        const result = await api.get(`search/airport/${facilityId}`, {
            show_unavailable: true,
            session_id: sessionUUID,
            search_id: searchUUID,
            action_id: actionUUID,
            action,
            fingerprint,
            ...params,
        }, {
            headers: {
                'SpotHero-Version': '2024-04-01',
            },
        });
        // @ts-expect-error - not in craig docs
        setBackendExperiments(result.data?.experiment);
        return formatAirportFacilityV2Response(result.data);
    }
    catch (error) {
        ErrorUtils.sendSentryException(error);
        throw error;
    }
};
/**
 * Retrieves data for a single airport facility and then converts to V1 rates shape
 *
 * @function getAiportFacilityV2ThenConvert
 * @see {@link https://api.spothero.com/v2/docs#tag/Search/operation/searchAirportFacilityParking|Documentation}
 * @example
 * getAiportFacilityV2(123, {lat: 41.8781, lon: -87.6298});
 */
export const getAiportFacilityV2ThenConvert = async (facilityId, params) => {
    const api = APIRegistry.get('craigSearch');
    const { sessionUUID, searchUUID, actionUUID, action, } = SearchTracking.getValues();
    const fingerprint = getWebExperimentCookie();
    try {
        const response = await api.get(`search/airport/${facilityId}`, {
            show_unavailable: true,
            session_id: sessionUUID,
            search_id: searchUUID,
            action_id: actionUUID,
            action,
            fingerprint,
            ...params,
        });
        // @ts-expect-error - not in craig docs
        setBackendExperiments(response.data?.experiment);
        const { data: { result, airport: { code }, }, } = response;
        const formattedAirportFacility = formatAirportFacility(result, {
            airportCode: code,
        });
        return transformV2SingleFacilityToV1RatesAirport(formattedAirportFacility);
    }
    catch (error) {
        ErrorUtils.sendSentryException(error);
        throw error;
    }
};
