import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withConfig from '@/config/withConfig';
const FooterCheckout = ({ className, siteUrl, isMonthly, phoneNumberFriendly, monthlyPhoneNumberFriendly, hours, }) => {
    const classes = classNames('Footer', 'FooterCheckout', className);
    return (<div className={classes}>
            <div className="container">
                <div className="FooterCheckout-copyright-contact-container">
                    <span className="FooterCheckout-copyright">
                        SpotHero &copy;{new Date().getFullYear()}
                    </span>
                    <span className="FooterCheckout-phone">
                        {isMonthly
            ? monthlyPhoneNumberFriendly
            : phoneNumberFriendly}
                    </span>
                    <span className="FooterCheckout-hours">{hours}</span>
                </div>
                <div className="FooterCheckout-links">
                    <a href={`${siteUrl}/faq/`}>How It Works</a>
                    <a href={`${siteUrl}/contact/`}>Contact</a>
                    <a href={`${siteUrl}/terms-of-use/`}>
                        Terms &amp; Conditions
                    </a>
                </div>
            </div>
        </div>);
};
const mapStateToProps = (state) => {
    const { searchRequest: { monthly: isMonthly }, city: { data: { phone_number_friendly: phoneNumberFriendly, monthly_phone_number_friendly: monthlyPhoneNumberFriendly, weekday_hours: hours, }, }, } = state;
    return {
        isMonthly,
        phoneNumberFriendly,
        monthlyPhoneNumberFriendly,
        hours,
    };
};
const enhance = compose(withConfig(['siteUrl']), connect(mapStateToProps));
export default enhance(FooterCheckout);
