import { useLayoutEffect } from 'react';
import { SearchPageView } from 'utils/search-page-view';
export const useAdjustRootPadding = (view) => {
    useLayoutEffect(() => {
        const rootElement = document.getElementById('root');
        if (rootElement) {
            rootElement.style.paddingTop =
                view === SearchPageView.MOBILE_MAP ? '0px' : '52px';
        }
    }, [view]);
};
