export const expandedData = [
    {
        primary: {
            list_title: 'About',
        },
        items: [
            {
                link_text: 'How it Works',
                link_path: '/faq',
            },
            {
                link_text: 'About SpotHero',
                link_path: '/about',
            },
            {
                link_text: 'Parking Guarantee',
                link_path: '/about/parking-guarantee',
            },
            {
                link_text: 'Contact Us',
                link_path: '/contact',
            },
            {
                link_text: 'SpotHero for Business',
                link_path: '/business/',
            },
        ],
        id: 'internal_link_list$60963768-82be-4372-b4e7-0b838060b7a8',
        slice_type: 'internal_link_list',
        slice_label: null,
    },
];
export const collapsedData = [
    {
        primary: {
            list_title: null,
        },
        items: [
            {
                link_text: 'How it Works',
                link_path: '/faq',
            },
            {
                link_text: 'Parking Guarantee',
                link_path: '/about/parking-guarantee',
            },
            {
                link_text: 'Contact Us',
                link_path: '/contact',
            },
            {
                link_text: 'SpotHero for Business',
                link_path: '/business/',
            },
        ],
        id: 'internal_link_list$193d06da-8930-42b8-a42e-93e2339de443',
        slice_type: 'internal_link_list',
        slice_label: null,
    },
];
