import React from 'react';
import FormatUtils from 'utils/format';
// This is a jsx-ified version of the function formatRatePrice from src/js/utils/spot.js 🧑‍🍳😙🤌
// TODO refactor all uses of formatRatePrice and deprecate
const DisplayPriceFormatted = ({ price, currencyType, hideCents = false, }) => {
    const { dollars, cents } = FormatUtils.price(Number(price));
    return (<>
            {hideCents ? (FormatUtils.currencySymbol(currencyType)) : (<sup>{FormatUtils.currencySymbol(currencyType)}</sup>)}
            {dollars}
            {cents && !hideCents ? <sup>.{cents}</sup> : ''}
        </>);
};
export default DisplayPriceFormatted;
