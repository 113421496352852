import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import TabGroup from 'common/tab-group';
import {setTerm} from 'store/search-request/search-request-actions';

const hourlyItem = {
    label: 'Hourly',
    value: 'daily',
};

const monthlyItem = {
    label: 'Monthly',
    value: 'monthly',
};

const airportItem = {
    label: 'Airport',
    value: 'airport',
};

const MONTHLY = 'monthly';

function SearchTermToggle({includeAirport, styleOverrides, tabProps = {}}) {
    const dispatch = useDispatch();
    const {
        city: {data: city},
        destination: {data: destination},
        searchRequest: {
            monthly: isMonthly,
            airport: isAirport,
            powerBookingSource: source,
        },
    } = useSelector(state => state);

    const setInitialIndex = () => {
        // if user is not in PB flow
        if (!source || source === null) {
            return !isMonthly && !isAirport
                ? 0
                : isMonthly && !isAirport
                ? 1
                : !isMonthly && isAirport
                ? 2
                : 0;
        } // user in PB flow from monthly_search or monthly_midfunnel
        else if (source.includes(MONTHLY)) {
            return 1;
        } else {
            return 0;
        }
    };

    const [activeItemIndex, setActiveItemIndex] = useState(setInitialIndex());
    const items = [hourlyItem, monthlyItem];

    if (includeAirport) {
        items.push(airportItem);
    }

    const onChange = evt => {
        setActiveItemIndex(evt);

        dispatch(
            setTerm({
                term: items[evt].value,
                city,
                destination,
            })
        );
    };

    return (
        <TabGroup
            className="SearchTermToggle"
            color="secondary"
            items={items}
            onChange={onChange}
            defaultIndex={activeItemIndex}
            styleOverrides={styleOverrides}
            tabProps={tabProps}
        />
    );
}

SearchTermToggle.propTypes = {
    includeAirport: PropTypes.bool,
    styleOverrides: PropTypes.object,
    tabProps: PropTypes.object,
};

export default SearchTermToggle;
