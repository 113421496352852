/* eslint-disable @typescript-eslint/no-use-before-define */
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import { getWindow } from 'ssr-window';
import EnvironmentUtils from '@spothero/utils/environment';
import StorageUtils from '@spothero/utils/storage';
import UrlUtils from '@spothero/utils/url';
import Config from '@/config/index';
import { Page } from 'utils/page-utils';
import { combineDateAndTime, getAsDayjsTime } from './dayjs';
import dayjs from './dayjs-timezone';
import duration from 'dayjs/plugin/duration';
import { SearchPageView } from 'utils/search-page-view';
import { isMobilePortraitView } from 'hooks/use-is-mobile';
import { getRouteParam, paramNameOptions } from 'utils/url-utils';
import { getDestinationCategoryById, VENUE_CATEGORY_ID, } from 'utils/types/category-mapping';
import { SearchControlsVisibility } from 'utils/types/search-controls-visibility';
dayjs.extend(duration);
const window = getWindow();
const isBrowser = EnvironmentUtils.isBrowser();
function roundStartTime(datetime, timezone) {
    const dt = datetime ? dayjs(datetime) : dayjs().tz(timezone);
    return dt.minute() < 30 ? dt.minute(0) : dt.minute(30);
}
export const isStartsURLParamsMissing = () => {
    if (typeof window === 'undefined')
        return false;
    const params = UrlUtils.parseParams(window.location.search);
    return !params.starts;
};
export const createPowerBookingPeriods = ({ starts, ends }) => {
    const periodStarts = starts?.split(',').sort();
    const periodEnds = ends?.split(',').sort();
    if (periodStarts?.length === periodEnds?.length) {
        return periodStarts?.reduce((acc, start, index) => {
            const period = {
                starts: dayjs(start).format(Config.craigApiDateTimeFormat),
                ends: dayjs(periodEnds[index]).format(Config.craigApiDateTimeFormat),
            };
            return acc.concat([period]);
        }, []);
    }
    return [];
};
// Get the default category to be used for current destination (in redux state).
// A destination can have multiple categories but for simplicity on breadcrumbs and Segment tracking we only want to show one category.
// This hook encapsulates the logic to select one default category out of multiple categories.
const getDefaultCategory = (categoryIds, canDestinationHaveEvents) => {
    if (canDestinationHaveEvents) {
        // If it's not category venues then don't show venues, otherwise default to that.
        const eventsId = categoryIds.indexOf(VENUE_CATEGORY_ID) === -1
            ? categoryIds?.[0]
            : VENUE_CATEGORY_ID;
        return getDestinationCategoryById(eventsId);
    }
    // Use first category item as default category
    return getDestinationCategoryById(categoryIds?.[0]);
};
const prepareBreadcrumbs = (initData, searchType) => {
    const { city: { data: city }, destination: { data: destination }, searchRequest, } = initData;
    const { display_name: cityDisplayName, relative_url: relativeUrl, slug: citySlug, state, } = city;
    const cityUrl = `${Config.siteUrl}/city/${relativeUrl}`;
    const searchString = searchRequest ? searchRequest.search_string : null;
    let categoryBreadcrumb = {};
    if (searchType === 'destination' || searchType === 'venue') {
        const { categories: categoryIds, can_have_events: canHaveEvents, } = destination;
        const [destinationCategorySlug, destinationCategoryTitle,] = getDefaultCategory(categoryIds, canHaveEvents);
        categoryBreadcrumb = {
            url: `${Config.siteUrl}/category/${citySlug}/${destinationCategorySlug}-parking`,
            label: destinationCategoryTitle,
        };
    }
    return {
        activeBreadcrumb: {
            url: searchType === 'event'
                ? `${Config.siteUrl}/destination/${citySlug}/${destination.relative_url}`
                : null,
            label: searchType === 'city'
                ? `${cityDisplayName}, ${state}`
                : destination.title
                    ? `${destination.title}${searchType === 'event' ? ' Events' : ''}`
                    : searchString
                        ? searchString
                        : 'Downtown',
        },
        city: {
            url: cityUrl,
            displayName: cityDisplayName,
        },
        categoryBreadcrumb,
    };
};
const prepareStoreData = ({ initData, pageType, currentSearchState = null }) => {
    const { city: { data: city }, destination: { data: destination }, searchRequest, } = initData;
    const { display_name: cityDisplayName, zoom_level: cityZoom, default_map_zoom_level: cityDefaultMapZoom, } = city;
    const monthly = searchRequest ? searchRequest.monthly : false;
    const queryParams = !isBrowser && searchRequest.search_url
        ? UrlUtils.parseParams(searchRequest.search_url)
        : UrlUtils.parseParams(window.location.search);
    const searchString = searchRequest ? searchRequest.search_string : null;
    const overrideIdealZoom = destination.override_ideal_zoom || false;
    const hasDestination = !isEmpty(destination);
    const searchState = currentSearchState
        ? {
            isPending: false,
            data: {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                ...SearchUtils.defaultSearchSettings,
                view: currentSearchState.data.view,
                sortBy: currentSearchState.data.sortBy,
            },
            error: null,
        }
        : {
            isPending: false,
            data: {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                ...SearchUtils.defaultSearchSettings,
                view: SearchPageView.MOBILE_LIST,
                sortBy: 'distance',
            },
            error: null,
        };
    let searchType = searchState.data.searchType;
    switch (pageType) {
        case 'city':
            searchType = 'city';
            break;
        case 'event':
            searchType = 'event';
            break;
        case 'destination':
            if (destination.has_upcoming_events) {
                searchType = 'venue';
            }
            else {
                searchType = 'destination';
            }
            break;
        default:
            searchType = 'address';
    }
    const breadcrumbs = prepareBreadcrumbs(initData, searchType);
    const mobile = isMobilePortraitView();
    const initialState = {
        ...searchState,
        data: {
            ...searchState.data,
            searchType,
            breadcrumbs,
            overrideIdealZoom: searchType === 'city' && cityZoom ? true : overrideIdealZoom,
            zoom: searchType === 'city'
                ? // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    SearchUtils.getCityZoom({
                        city,
                        monthly,
                        mobile,
                    })
                : hasDestination
                    ? overrideIdealZoom &&
                        (destination.desktop_zoom_level ||
                            destination.mobile_zoom_level)
                        ? // eslint-disable-next-line @typescript-eslint/no-use-before-define
                            SearchUtils.getDestinationZoom({
                                destination,
                                monthly,
                                mobile,
                            })
                        : destination.default_map_zoom_level ||
                            cityDefaultMapZoom
                    : cityDefaultMapZoom,
            isDefaultTimes: isNil(queryParams.starts),
            referrer: queryParams.referrer,
            referrerAffiliate: queryParams.sha_affiliate,
            destinationTitle: searchType === 'city'
                ? cityDisplayName
                : destination.title
                    ? destination.title
                    : !isEmpty(searchString)
                        ? searchString
                        : 'Destination',
            kmlMapLayerEnabled: hasDestination
                ? destination.kml_map_layer_enabled
                : false,
            kmlMapLayerURL: hasDestination
                ? destination.kml_map_layer_url
                : null,
            mobilePaginationLimit: Config.mobilePaginationLimit ||
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                SearchUtils.defaultSearchSettings.mobilePaginationLimit,
        },
        searchCenterPostZoomOrPan: { latitude: 0, longitude: 0 },
    };
    return initialState;
};
export const getDefaultSearchPageView = () => {
    // Take the view from the URL if it exists
    const view = getRouteParam(paramNameOptions.VIEW);
    if (view) {
        return view;
    }
    // Use media query to determine view
    if (isMobilePortraitView()) {
        return SearchPageView.MOBILE_LIST;
    }
    return SearchPageView.DESKTOP_LIST;
};
const SearchUtils = {
    SEGMENT_SPOT_META: 'sh-segment-spot-meta',
    defaultSearchSettings: {
        searchType: 'address',
        showNoSpotsFound: false,
        currentIncrement: 0,
        zoom: 14,
        isDefaultTimes: true,
        overrideIdealZoom: false,
        destinationTitle: 'Destination',
        sortBy: 'distance',
        breadcrumbs: null,
        // This value is used by the Navigator.jsx component for client side routing
        view: getDefaultSearchPageView(),
        searchControlsVisibility: SearchControlsVisibility.FULL,
        mobilePaginationLimit: 30,
        vehicle: null,
        searchOversizePopoverVisible: null,
        searchComponentEventView: null,
    },
    prepareBreadcrumbs,
    prepareStoreData,
    updateRoute({ currentUrl, searchParams, adParams }) {
        const { monthly, latitude, longitude, starts, ends, search_string: searchString, eid, operator_id: operatorId, rebook_reservation_id: rebookReservationId, airport, monthlyEnabled, page_info: pageInfo, filterSpot, partner, powerBooking, powerBookingPeriods, powerBookingSource, } = searchParams;
        const hash = window.location.hash ? window.location.hash : '';
        const destination = pageInfo ? pageInfo.setup.destination : null;
        const urlParams = { ...adParams };
        let cleanedUrl = currentUrl.split(/[?#]/)[0];
        if (!includes(cleanedUrl, 'parking-near-me')) {
            if (includes(cleanedUrl, '-parking')) {
                if ((isNil(destination) && isNil(monthlyEnabled)) ||
                    monthlyEnabled ||
                    (destination && destination.monthly_enabled)) {
                    if (monthly) {
                        if (!includes(cleanedUrl, '-monthly-parking')) {
                            cleanedUrl = cleanedUrl.replace('-parking', '-monthly-parking');
                        }
                    }
                    else {
                        cleanedUrl = cleanedUrl.replace('-monthly-parking', '-parking');
                    }
                }
            }
            else {
                urlParams.latitude = latitude;
                urlParams.longitude = longitude;
                if (searchString) {
                    urlParams.search_string = searchString; // eslint-disable-line camelcase
                }
                if (monthly) {
                    urlParams.monthly = monthly;
                }
            }
        }
        urlParams.filterSpot = filterSpot;
        urlParams.partner = partner;
        urlParams.starts = starts;
        if (!monthly) {
            urlParams.ends = ends;
        }
        if (((destination && destination.is_airport) || airport) &&
            starts &&
            ends &&
            !monthly) {
            urlParams.airport = airport;
        }
        if (monthly) {
            delete urlParams.airport;
        }
        if (powerBooking) {
            delete urlParams.airport;
            delete urlParams.monthly;
            powerBookingPeriods.forEach(({ starts: periodStarts, ends: periodEnds }) => {
                urlParams.starts = periodStarts;
                urlParams.ends = periodEnds;
            });
            // eslint-disable-next-line camelcase
            urlParams.pb_source = powerBookingSource;
        }
        if (eid) {
            if (cleanedUrl.endsWith('-parking')) {
                urlParams.eid = eid;
            }
        }
        if (rebookReservationId) {
            urlParams.rebook_reservation_id = rebookReservationId; // eslint-disable-line camelcase
        }
        if (operatorId) {
            urlParams.operator_id = operatorId; // eslint-disable-line camelcase
        }
        return `${cleanedUrl}${size(urlParams)
            ? `?${UrlUtils.createQueryString(urlParams).replace(/%3A%3A/g, '::')}${hash}`
            : ''}`;
    },
    // Add current search page to session storage. For use by checkout when sending users back to search (ex: no-inventory errors)
    setPreviousSearch() {
        if (!isBrowser) {
            return;
        }
        const params = UrlUtils.parseParams(window.location.search);
        // Strip spot-id from params, so if come back to search, spot details isn't open
        delete params['spot-id'];
        const newSearch = UrlUtils.createQueryString(params);
        StorageUtils.set('previous-search', {
            url: `${window.location.pathname}?${newSearch}`,
        }, 'session');
    },
    getCityZoom({ city, monthly = false, mobile = false }) {
        if (mobile && monthly && city.monthly_mobile_zoom_level) {
            return city.monthly_mobile_zoom_level;
        }
        else if (mobile && city.mobile_zoom_level) {
            return city.mobile_zoom_level;
        }
        else if (monthly && city.monthly_zoom_level) {
            return city.monthly_zoom_level;
        }
        else {
            return city.zoom_level;
        }
    },
    getDestinationZoom({ destination, monthly = false, mobile = false, }) {
        if (mobile && monthly && destination.monthly_mobile_zoom_level) {
            return destination.monthly_mobile_zoom_level;
        }
        else if (mobile && destination.mobile_zoom_level) {
            return destination.mobile_zoom_level;
        }
        else if (monthly && destination.monthly_desktop_zoom_level) {
            return destination.monthly_desktop_zoom_level;
        }
        else {
            return destination.desktop_zoom_level;
        }
    },
    getReferrerDetails({ referrer = '', previousRental = false }) {
        let fromScreen = '';
        switch (referrer) {
            case 'favorites':
                fromScreen = 'favorite';
                break;
            case 'list':
                fromScreen = 'list';
                break;
            case 'pin':
                fromScreen = 'map';
                break;
            case 'modal':
                fromScreen = 'product details';
                break;
            case 'widget':
                fromScreen = 'widget';
                break;
            case 'apple maps':
                fromScreen = 'apple maps';
                break;
            default:
                fromScreen = 'checkout';
                break;
        }
        if (previousRental) {
            fromScreen = 'buy this spot again';
        }
        return fromScreen;
    },
    createTimeWindowParams(page = '', 
    // eslint-disable-next-line camelcase
    query = { starts: '', ends: '', power_booking: false }, timezone = '') {
        const { starts: startsParam, ends: endsParam, power_booking: isPowerBooking, } = query;
        if (startsParam && endsParam) {
            return isPowerBooking
                ? {
                    powerBookingPeriods: createPowerBookingPeriods({
                        starts: startsParam,
                        ends: endsParam,
                    }),
                    starts: startsParam?.split(',')[0],
                    ends: endsParam?.split(',')[0],
                }
                : { starts: startsParam, ends: endsParam };
        }
        const starts = roundStartTime(startsParam, timezone);
        const ends = dayjs(starts).add(dayjs.duration(3, 'hours'));
        const airportUpdatedStartDate = dayjs(starts).add(dayjs.duration(1, 'days'));
        const airportUpdatedEndDate = dayjs(starts).add(dayjs.duration(5, 'days'));
        const startTime = '12:00 PM';
        const endTime = '12:00 PM';
        const airportStartDate = airportUpdatedStartDate;
        const airportEndDate = airportUpdatedEndDate;
        if (page === Page.AIRPORT) {
            return {
                starts: combineDateAndTime(airportStartDate, getAsDayjsTime(startTime)).format(Config.apiDateTimeFormat),
                ends: combineDateAndTime(airportEndDate, getAsDayjsTime(endTime)).format(Config.apiDateTimeFormat),
            };
        }
        else if (isPowerBooking) {
            return {
                powerBookingPeriods: createPowerBookingPeriods({
                    starts: starts.format(Config.apiDateTimeFormat),
                    ends: ends.format(Config.apiDateTimeFormat),
                }),
                starts: starts.format(Config.apiDateTimeFormat),
                ends: ends.format(Config.apiDateTimeFormat),
            };
        }
        else {
            return {
                starts: starts.format(Config.apiDateTimeFormat),
                ends: ends.format(Config.apiDateTimeFormat),
            };
        }
    },
    isStartsURLParamsMissing,
};
export default SearchUtils;
