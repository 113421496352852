import {ActionType} from 'redux-promise-middleware';
import {
    DESTINATION_LOAD_DATA,
    DESTINATION_LOAD_EVENTS,
    DESTINATION_UPDATE,
    DESTINATION_RESET,
    DESTINATION_GET_AIRPORT_SPOTS,
} from './destination-actions';
import {ROUTER_DATA_LOAD_FULFILLED} from '../router/router-actions';
import SpotUtils from 'utils/spot';
import {SPOT_SORT_OPTIONS} from 'utils/spot-sort';

const MINIMUM_RATING_COUNT = 10;

export const initialState = () => ({
    isPending: false,
    data: {},
    error: null,
});

export default function destination(state = initialState(), {type, payload}) {
    switch (type) {
        case `${DESTINATION_LOAD_DATA}_${ActionType.Pending}`: {
            return {
                ...state,
                isPending: true,
                error: null,
            };
        }

        case `${DESTINATION_LOAD_DATA}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                isPending: false,
                data: {
                    ...state.data,
                    ...payload.data.data,
                },
            };
        }

        case `${DESTINATION_LOAD_DATA}_${ActionType.Rejected}`: {
            return {
                ...state,
                error: 'Could not load destination data.',
            };
        }

        case `${DESTINATION_LOAD_EVENTS}_${ActionType.Pending}`: {
            return {
                ...state,
                isPending: true,
                error: null,
            };
        }

        case `${DESTINATION_LOAD_EVENTS}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                isPending: false,
                data: {
                    ...state.data,
                    events: payload.data.data.results,
                },
            };
        }

        case `${DESTINATION_LOAD_EVENTS}_${ActionType.Rejected}`: {
            return {
                ...state,
                error: 'Could not load events.',
            };
        }

        case DESTINATION_UPDATE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...payload,
                },
            };
        }

        case DESTINATION_RESET: {
            return initialState();
        }

        case ROUTER_DATA_LOAD_FULFILLED: {
            const {destination: cleanedDestinationState} = payload;

            if (cleanedDestinationState) {
                return cleanedDestinationState;
            } else {
                return state;
            }
        }

        case `${DESTINATION_GET_AIRPORT_SPOTS}_${ActionType.Fulfilled}`: {
            // strip out all unnecessary data
            const allAirportSpots = payload.results;
            const sortedAirportSpots = SpotUtils.sortByAttributeV2(
                allAirportSpots,
                SPOT_SORT_OPTIONS.RATING
            );
            const airportSpots = sortedAirportSpots.reduce(
                (firstSixReviewedSpots, spotData) => {
                    if (
                        firstSixReviewedSpots.length < 6 &&
                        spotData.rating.count >= MINIMUM_RATING_COUNT
                    ) {
                        const {
                            spotId,
                            logo,
                            title,
                            lowestDailyRate,
                            addresses,
                            rating,
                            hoursOfOperation,
                        } = spotData;

                        const {
                            streetAddress,
                            city,
                            state: spotState,
                            postalCode,
                        } = addresses[0];

                        firstSixReviewedSpots.push({
                            spotId,
                            logo,
                            title,
                            lowestDailyRate,
                            streetAddress,
                            city,
                            state: spotState,
                            postalCode,
                            rating,
                            hoursOfOperation,
                        });
                    }

                    return firstSixReviewedSpots;
                },
                []
            );

            return {
                ...state,
                airportSpots,
            };
        }

        default:
            return state;
    }
}
