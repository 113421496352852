import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@spothero/ui';
import Image from 'common/image';
import AppStoreUtils from 'utils/app-store';
class AppStoreBadges extends Component {
    constructor() {
        super(...arguments);
        this._onAppStoreLinkClicked = () => {
            AppStoreUtils.appStoreLinkClicked(this._generateAppStoreArgs('ios'));
        };
        this._onPlayStoreLinkClicked = () => {
            AppStoreUtils.appStoreLinkClicked(this._generateAppStoreArgs('android'));
        };
    }
    _generateAppStoreArgs(device) {
        const { email } = this.props;
        const args = {
            device,
            channel: 'prepurchase',
        };
        if (email) {
            args.email = email;
        }
        return args;
    }
    render() {
        const { className, width, height } = this.props;
        const classes = classNames('AppStoreBadges', className);
        return (<div className={classes}>
                <Button borderRadius={0} padding={0} border="none" backgroundColor="transparent" onClick={this._onAppStoreLinkClicked} marginRight={3.5}>
                    <Image cloudinaryImageId="logos/app-store-badge" width={width} height={height} lazyLoad/>
                </Button>
                <Button borderRadius={0} padding={0} border="none" backgroundColor="transparent" onClick={this._onPlayStoreLinkClicked}>
                    <Image cloudinaryImageId="logos/google-play-badge" width={width} height={height} lazyLoad/>
                </Button>
            </div>);
    }
}
AppStoreBadges.propTypes = {
    className: PropTypes.string,
    email: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};
AppStoreBadges.defaultProps = {
    width: 135,
    height: 40,
};
export default AppStoreBadges;
