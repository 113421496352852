import React, { forwardRef } from 'react';
import AmenityUtils from '@spothero/utils/amenity';
import { Flex, Icon, Text } from '@spothero/ui';
import { CircleSlash as IconNo } from '@spothero/icons';
const AmenitiesInOutSummary = forwardRef(({ hasInOutAmenity, iconStyles, spanStyles, ...styleProps }, ref) => {
    const mappings = {
        true: {
            color: 'green.800',
            icon: AmenityUtils.getIcon('in-out'),
            fontWeight: 'semibold',
            text: 'In & Out Allowed',
        },
        false: {
            color: 'gray.dark',
            icon: IconNo,
            fontWeight: 'normal',
            text: 'No In & Out',
        },
    };
    const { color, icon, fontWeight, text } = mappings[`${hasInOutAmenity}`];
    return (<Flex {...styleProps} ref={ref}>
                <Icon as={icon} h={5} w={5} color={color} paddingRight={1} {...iconStyles}/>
                <Text as="span" variant="body2" color={color} fontWeight={fontWeight} {...spanStyles}>
                    {text}
                </Text>
            </Flex>);
});
export default AmenitiesInOutSummary;
