import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import React, {Component, forwardRef} from 'react';
import PropTypes from 'prop-types';
import Config from './index';

export default mapConfigToProps => {
    if (
        mapConfigToProps &&
        !isArray(mapConfigToProps) &&
        !isFunction(mapConfigToProps)
    ) {
        throw new Error(
            'The `mapConfigToProps` param should either be an array of strings that are keys for the values requested or a function that takes a config param and pulls the applicable values out.'
        );
    }

    return function wrapper(EnhancedComponent) {
        class WithConfig extends Component {
            static displayName = `WithConfig(${
                EnhancedComponent.displayName ||
                EnhancedComponent.name ||
                'Component'
            })`;
            static propTypes = {
                forwardedRef: PropTypes.oneOfType([
                    PropTypes.func,
                    PropTypes.shape({current: PropTypes.any}),
                ]),
            };

            constructor(props) {
                super(props);

                this._configProps = omit(Config, ['set']);
            }

            render() {
                const {forwardedRef, ...remainingProps} = this.props;
                const allProps = {
                    ...remainingProps,
                    ...(isFunction(mapConfigToProps)
                        ? mapConfigToProps(this._configProps)
                        : mapConfigToProps
                        ? pick(this._configProps, mapConfigToProps)
                        : this._configProps),
                };

                return <EnhancedComponent ref={forwardedRef} {...allProps} />;
            }
        }

        return forwardRef((props, ref) => {
            // eslint-disable-line react/no-multi-comp
            return <WithConfig forwardedRef={ref} {...props} />;
        });
    };
};
