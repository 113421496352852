import { Box, Flex, useBreakpointValue } from '@spothero/ui';
import React from 'react';
import { SearchComponentStoreProvider, SearchComponentMobileToggle, } from '@spothero/search-component';
import { FindParkingSpotsButton } from 'pages/search/components/FindParkingSpotsButton';
import { useGoogleMapsLibraries } from 'common/google-map-context';
import { PoweredByGoogle } from '@spothero/ui-backlog';
import useConfig from 'hooks/use-config';
import { useDispatch, useSelector } from 'react-redux';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import { SearchPageView } from 'utils/search-page-view';
import MobileViewToggle from './mobile-view-toggle';
import { VenueEventSEMEdit } from '../events/landing/VenueEventLandingEdit';
import { FilterSelection } from './filters/FilterSelection';
import { NotAModal } from './not-a-modal/NotAModal';
import { useShowEventsData } from 'pages/search/hooks/use-show-events-data';
import { setSearchComponentIsOpen } from 'store/search/search-actions';
import { useDefaultSuggestion } from 'pages/search/hooks/use-default-suggestion';
import { mapReduxSearchStateToSearchComponentState } from 'pages/search/utils/state-mapping-utils';
// eslint-disable-next-line no-empty-function
const handler = () => { };
const spotListMobileListViewStyles = {
    marginTop: '52px', // leave space for header
};
const ToggleWrapper = ({ onOpen }) => {
    return (<Flex paddingX="4">
            <SearchComponentMobileToggle onClick={onOpen}/>
            <MobileViewToggle />
        </Flex>);
};
export const SearchComponentContainer = () => {
    const { showSEMEvent } = useShowEventsData();
    const dispatch = useDispatch();
    const onOpen = () => dispatch(setSearchComponentIsOpen(true));
    const isOpen = useSelector((state) => state.search.data.searchComponentIsOpen);
    const { googleMapsLoaded, googleMapsLibraries } = useGoogleMapsLibraries();
    const { siteUrl } = useConfig();
    const isMobile = useBreakpointValue({ base: true, tablet: false }, { ssr: false });
    const defaultSuggestion = useDefaultSuggestion();
    const reduxState = useReduxSearchState();
    const searchComponentState = mapReduxSearchStateToSearchComponentState({
        parkingType: reduxState.parkingType,
        starts: reduxState.startsDate,
        ends: reduxState.endsDate,
        powerBookingSource: reduxState.powerBookingSource,
        powerBookingPeriods: reduxState.powerBookingPeriods,
    });
    const clientRendered = {
        googleMapsLibraries,
        searchEvents: [],
        handleOnChangeForEvents: handler,
        isMobile,
        timezone: reduxState.timezone,
    };
    const providerProps = {
        siteUrl,
        eventDestinationsHash: {},
        locationBias: {},
        airports: reduxState.airports,
        parkingType: searchComponentState.parkingType,
        parkingSubType: searchComponentState.parkingSubType,
        startDate: searchComponentState.startDate,
        endDate: searchComponentState.endDate,
        monthlyStartDate: searchComponentState.monthlyStartDate,
        dates: searchComponentState.powerBookingDates,
        startTime: searchComponentState.powerBookingStartTime,
        endTime: searchComponentState.powerBookingEndTime,
        defaultSuggestion,
        trackEventListItemSelected: handler,
        trackDateEntered: handler,
        trackSearchSuggestionSelected: handler,
        trackErrorMessageDisplayed: handler,
        trackFindParkingNearMeClicked: handler,
        trackSwitchedVerticalTabs: handler,
        trackPowerBookingOpened: handler,
        PoweredByGoogle,
        dateTimePickerButtonLabel: 'Submit',
        FindParkingSpotsButton,
    };
    if (!googleMapsLoaded) {
        return <div />;
    }
    return (<Flex justifyContent="center" backgroundColor="white" sx={reduxState.view === SearchPageView.MOBILE_LIST
            ? spotListMobileListViewStyles
            : {}} width="100%" position="relative">
            <Box flexGrow={1} overflow="hidden" zIndex={isOpen ? 'layer3' : 0} // 2 more than css - .Search-map-view .SpotList, .ResponsiveSearchLayout-zone-2 .SpotList
     backgroundColor="white" paddingY={4}>
                {showSEMEvent ? (<VenueEventSEMEdit />) : (<SearchComponentStoreProvider locationId={undefined} locationKind={undefined} clientRendered={clientRendered} {...providerProps}>
                        {!isOpen ? (<Box>
                                <ToggleWrapper onOpen={onOpen}/>
                                <FilterSelection />
                            </Box>) : (<NotAModal />)}
                    </SearchComponentStoreProvider>)}
            </Box>
            {isOpen && (<Box position="absolute" backgroundColor="navy.900" opacity=".3" height="100vh" width="100vw" top="0" bottom="0" left="0" right="0" zIndex="layer2" // 1 more than css - .Search-map-view .SpotList, .ResponsiveSearchLayout-zone-2 .SpotList
        />)}
        </Flex>);
};
