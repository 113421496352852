import isEmpty from 'lodash/isEmpty';
import {getWindow} from 'ssr-window';
import DOMUtils from '@spothero/utils/dom';
import Config from '@/config/index';
// TODO MR - leaving due to use of stripe elements on window

const window = getWindow();

const StripeUtils = {
    defaultErrorMsg:
        'There was an issue processing your request.  Please try again and/or contact customer support.',

    isLoading: false,

    cancelStripeResponse: false,

    stripeInstance: null,

    stripeResponseTimeout: null,

    stripeScript: null,

    async init() {
        if (!this.stripeInstance && !this.isLoading) {
            try {
                this.isLoading = true;

                this.stripeScript = DOMUtils.loadScript({
                    src: 'https://js.stripe.com/v3/',
                });

                await this.stripeScript;

                this.stripeInstance = window.Stripe(Config.stripePublicApiKey);
                this.isLoading = false;

                window.StripeElements = this.stripeInstance.elements();
            } catch (error) {
                this.isLoading = false;

                throw error;
            }
        }

        return this.stripeScript;
    },

    async createToken({isAdmin, onSuccess = null, onError = null, name}) {
        if (!this.stripeInstance || isEmpty(window.SHStripeCardElement)) {
            try {
                await this.init();
            } catch (error) {
                if (onError) {
                    onError(this.defaultErrorMsg);
                }

                return;
            }
        }

        // Set a timeout for 15 seconds so that if Stripe token creation takes longer than that we just error out
        this.stripeResponseTimeout = setTimeout(() => {
            this.cancelStripeResponse = true;

            const msg = isAdmin
                ? 'The Stripe API timed out. Please refresh the page and try again.'
                : 'An error occurred while reserving your parking spot. Please refresh the page and try again.';

            if (onError) {
                onError(msg);
            }
        }, 15000);

        // Create the actual Stripe token asynchronously
        const {token, error} = await this.stripeInstance.createToken(
            window.SHStripeCardElement,
            {
                name,
            }
        );

        // If the createToken call took over 15 seconds and the error was surfaced to the user using the timeout above,
        // don't ever proceed further in this code and return out of this method so that there is no jank UX if they've
        // moved on to doing other things or fixing errors and all of a sudden it looks like payment went through
        if (this.cancelStripeResponse) {
            this.cancelStripeResponse = false;

            return;
        }

        // Stripe token creation API call succeeded, so either we have a token now or the token creation came back with an error
        if (error) {
            clearTimeout(this.stripeResponseTimeout);

            if (onError) {
                onError(error.message, error.code);
            }

            return {
                success: false,
                tokenId: null,
                error: error.message,
                errorType: error.type,
            };
        } else {
            clearTimeout(this.stripeResponseTimeout);
            if (onSuccess) {
                onSuccess(token.id);
            }

            return {
                success: true,
                tokenId: token.id,
                error: null,
                errorType: null,
            };
        }
    },

    // https://stripe.com/docs/stripe-js/reference#stripe-payment-request
    async createPaymentRequest(paymentRequestOptions) {
        if (!this.stripeInstance) {
            try {
                await this.init();
            } catch (error) {
                return null;
            }
        }

        return this.stripeInstance?.paymentRequest({
            ...paymentRequestOptions,
            requestPayerEmail: true,
            requestPayerName: true,
        });
    },
};

export default StripeUtils;
