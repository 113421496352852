/**
 * Formats data for a V2 address types to V1 address type
 *
 * @function formatAddressTypes
 * @param {Array} types - facility address types
 * @returns {Array} V1 response address types.
 */
export const formatAddressTypes = (types = []) => {
    return types?.map(type => {
        if (type === 'walking_distance') {
            return 'walking-distance';
        }
        else if (type === 'default_vehicle_entrance') {
            return 'default-vehicle-entrance';
        }
        else {
            return type;
        }
    });
};
