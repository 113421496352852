import SegmentUtils from 'utils/segment';
export default function trackOpenedSpotDetails({ referrer, } = {}) {
    const properties = {
        ...(referrer ? { referrer } : {}),
    };
    SegmentUtils.track({
        event: 'opened_spot_details',
        properties,
        additionalOptions: {
            context: {
                protocols: {
                    event_version: 1,
                },
            },
        },
    });
}
