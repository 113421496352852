import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Link from 'router/Link';
import {SmartphoneIcon, AccountIcon} from '../icons';
import UserUtils from 'utils/user-utils';
import {Icon, Box, List, ListItem} from '@spothero/ui';
import {withRouter} from 'react-router-dom';

const blueHeaderLinkStyling = {
    position: 'relative',
    paddingX: 2.5,
    height: 16,
    color: 'white',
    fontWeight: 'semibold',
    fontSize: 'base',
    margin: '0',
    sx: {
        '&:hover': {
            color: 'primary.200',
        },
        '&:focus:not(:focus-visible)': {
            outline: 0,
            boxShadow: 'none',
        },
    },
    display: 'flex',
    alignItems: 'center',
};

const whiteHeaderLinkStyling = {
    ...blueHeaderLinkStyling,
    color: 'black',
    sx: {
        ...blueHeaderLinkStyling.sx,
        '&:hover': {
            color: 'gray.500',
        },
    },
};

const hoverStyling = {
    '& > .NavigationExpanded-secondaryMenu': {
        visibility: 'visible',
        opacity: 1,
        transition: 'opacity .2s cubic-bezier(.25,.46,.45,.94)',
    },
};

const liStyling = {
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: '2.5',
    sx: {
        '&:first-child': {
            marginLeft: 0,
        },
    },
};

class NavigationExpanded extends PureComponent {
    static propTypes = {
        isWhiteHeader: PropTypes.bool,
        forceAnchors: PropTypes.bool,
        isProduction: PropTypes.bool,
        user: PropTypes.object,
        items: PropTypes.arrayOf(PropTypes.object).isRequired,
        onItemSelected: PropTypes.func,
        history: PropTypes.object,
    };
    static defaultProps = {
        isWhiteHeader: false,
    };

    _renderLinkItem({item, key}) {
        const {link_text: LinkText, link_path: linkPath} = item;

        return (
            <Link
                key={key}
                to={linkPath}
                forceAnchor
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                fontSize="base"
                display="block"
                paddingY={2}
                color="gray.900"
                _hover={{
                    color: 'gray.600',
                }}
                data-testid={`header-menu-secondary-${linkPath}`}
            >
                {LinkText}
            </Link>
        );
    }

    render() {
        const {
            user: {isAdmin, status},
            items,
            history,
            isWhiteHeader,
        } = this.props;

        const isLoggedIn = status === UserUtils.AUTH_STATE.USER;
        const currentURL = `${history.location.pathname}${history.location.search}`;
        const linkStyling = isWhiteHeader
            ? whiteHeaderLinkStyling
            : blueHeaderLinkStyling;

        return (
            <Box
                as="nav"
                role="navigation"
                display={{base: 'none', desktop: 'block'}}
                position="absolute"
                right={{base: '2.5'}}
                left="auto"
                top="0"
                height="16"
                data-testid={`header-navigation`}
            >
                <List>
                    {items.map((item, i) => {
                        return (
                            <ListItem {...liStyling} key={i}>
                                <Box
                                    _focusWithin={hoverStyling}
                                    _hover={hoverStyling}
                                    position="relative"
                                >
                                    <Link
                                        to={item.items[0].link_path}
                                        forceAnchor
                                        data-testid={`header-menu-${item.primary.list_title}`}
                                        {...linkStyling}
                                    >
                                        {item.primary.list_title}
                                    </Link>
                                    {item.items && (
                                        <Box
                                            className="NavigationExpanded-secondaryMenu"
                                            paddingX={6}
                                            paddingY={5}
                                            position="absolute"
                                            top={16}
                                            left="calc(50% - 0.25rem)"
                                            transform="translateX(-50%)"
                                            background="white"
                                            borderRadius="0.5rem"
                                            boxShadow="0.125rem .25rem .5rem 0 rgb(33 33 33 / 10%)"
                                            visibility="hidden"
                                            opacity="0"
                                            width="max-content"
                                            transition="opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)"
                                            textAlign="left"
                                            _before={{
                                                content: '""',
                                                position: 'absolute',
                                                top: '-10px',
                                                left: '50%',
                                                width: 0,
                                                height: 0,
                                                marginLeft: '-6px',
                                                borderColor:
                                                    'transparent transparent #fff',
                                                borderStyle: 'solid',
                                                borderWidth:
                                                    '0 0.75rem 0.625rem',
                                            }}
                                        >
                                            {item.items.map((ddItem, j) => {
                                                return this._renderLinkItem({
                                                    key: j,
                                                    item: ddItem,
                                                });
                                            })}
                                        </Box>
                                    )}
                                </Box>
                            </ListItem>
                        );
                    })}
                    <ListItem {...liStyling}>
                        <Link
                            to="/get-spothero-app"
                            forceAnchor
                            data-testid={`header-menu-get-app`}
                            {...linkStyling}
                        >
                            <Icon
                                alt="Smartphone Icon"
                                fill="currentColor"
                                height={6}
                                width={6}
                                marginRight={0.5}
                                data-testid={`header-menu-get-app-icon`}
                            >
                                <SmartphoneIcon />
                            </Icon>
                            Get the App
                        </Link>
                    </ListItem>

                    <ListItem {...liStyling}>
                        {isLoggedIn ? (
                            <Link
                                to="/account-settings"
                                forceAnchor
                                {...linkStyling}
                            >
                                <Icon
                                    alt="Account Icon"
                                    fill="currentColor"
                                    height={6}
                                    width={6}
                                    marginRight={1}
                                    data-testid={`login-status-link-logged-in`}
                                >
                                    <AccountIcon />
                                </Icon>
                                My Account
                            </Link>
                        ) : (
                            <Link
                                to={`/auth/restricted-login?next=${encodeURIComponent(
                                    currentURL
                                )}`}
                                data-testid={`login-status-link`}
                                forceAnchor
                                {...linkStyling}
                            >
                                <Icon
                                    alt="Account Icon"
                                    fill="currentColor"
                                    height={6}
                                    width={6}
                                    marginRight={0.5}
                                >
                                    <AccountIcon />
                                </Icon>
                                Log In or Sign Up
                            </Link>
                        )}
                    </ListItem>
                    {isAdmin && (
                        <ListItem {...liStyling}>
                            <Link
                                to="/admin"
                                forceAnchor
                                paddingRight="0"
                                color="yellow.default"
                                position="relative"
                                display="inline-block"
                                paddingX="2.5"
                                height="16"
                                lineHeight="4rem"
                                fontWeight="semibold"
                                margin="0"
                                data-testid={`header-menu-admin`}
                            >
                                Admin
                            </Link>
                        </ListItem>
                    )}
                </List>
            </Box>
        );
    }
}

export default withRouter(NavigationExpanded);
