import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShowTotal } from 'store/filters/filters-actions';
import totalPriceChanged from 'segment/events/search/total-price-changed';
import { useShowTotal } from 'store/selectors/hooks/useShowTotal';
import { FilterCheckboxBase } from '../../../filter-bases';
import { persistTotalPriceSessionPreference } from 'utils/total-price-session-utils';
export default function TotalToggle() {
    const dispatch = useDispatch();
    const state = useSelector((s) => s);
    const showTotal = useShowTotal();
    const [isClient, setIsClient] = useState(false);
    const handleClick = () => {
        dispatch(toggleShowTotal(!showTotal));
        persistTotalPriceSessionPreference(!showTotal);
        totalPriceChanged(state);
    };
    useEffect(() => {
        setIsClient(true);
    }, []);
    return (isClient && (<FilterCheckboxBase testid={`QuickFilters-showTotal-${showTotal}`} onClick={handleClick} isActive={showTotal}>
                Total Price
            </FilterCheckboxBase>));
}
