import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {PulseLoader} from '@spothero/ui-backlog';

const SHLoader = ({className, additionalMarkup}) => {
    const classes = classNames('SHLoader', className);

    return (
        <div data-testid="SHLoader" className={classes}>
            <PulseLoader
                className="SHLoader-loader"
                size={64}
                borderWidth={3}
            />
            {additionalMarkup}
        </div>
    );
};

SHLoader.propTypes = {
    className: PropTypes.string,
    additionalMarkup: PropTypes.node,
};

export default SHLoader;
