export const reduxLoggerMiddleware = ({getState}) => next => action => {
    const {type, ...restAction} = action;

    /* eslint-disable no-console */
    console.groupCollapsed(type);
    console.log(restAction);
    next(action);
    console.log(getState());
    console.groupEnd();
    /* eslint-enable no-console */

    return;
};
