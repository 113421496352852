import React from 'react';

const SpotDetailsPlaceholder = () => {
    return (
        <div className="SpotDetailsPlaceholder">
            <div className="SpotDetailsPlaceholder-info" />
            <div className="SpotDetailsPlaceholder-info" />
            <div className="SpotDetailsPlaceholder-info" />
        </div>
    );
};

export default SpotDetailsPlaceholder;
