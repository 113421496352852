export const paramNameOptions = {
    VIEW: 'view',
    MONTHLY: 'monthly',
    SPOTID: 'spot-id',
};
export function updateRouteParam(paramName, paramValue) {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set(paramName, paramValue);
    window.history.replaceState({}, '', newUrl.href);
}
export function getRouteParam(paramName) {
    if (typeof window === 'undefined') {
        return null;
    }
    const newUrl = new URL(window.location.href);
    return newUrl.searchParams.get(paramName);
}
