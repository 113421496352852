import { APIRegistry } from '@spothero/utils/api';
import { formatMonthlySearchResponse } from '../utils/formatMonthlySearchResponse';
import { setBackendExperiments } from 'utils/session-storage/backend-experiment';
/**
 * Searches for available monthly parking.
 *
 * @function getMonthlyFacilities
 * @see {@link https://api.spothero.com/v2/docs#operation/searchMonthlyParking|Documentation}
 * @param {object} params - The parameters to pass to the endpoint.
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @example
 * getMonthlyFacilities({lat: 41.8781, lon: -87.6298});
 * @returns {Array} The available monthly facilities, with data transformation applied.
 */
export function getMonthlyFacilities(params, formatOptions) {
    const api = APIRegistry.get('craigSearch');
    return api
        .get('search/monthly', {
        oversize: false,
        page_size: 25,
        include_walking_distance: true,
        ...params,
    })
        .then(({ 
    // @ts-expect-error - not in craig docs
    data: { results, '@next': loadMoreSpotsUrl, experiment }, }) => {
        setBackendExperiments(experiment);
        return {
            loadMoreSpotsUrl,
            results: formatMonthlySearchResponse(results, formatOptions),
        };
    });
}
/**
 * Load more available monthly parking after initial search.
 * Uses the @next URL returned by search api to load next set of spots.
 *
 * @function getMonthlyFacilitiesLoadMoreSpots
 * @see {@link https://api.spothero.com/v2/docs#operation/searchMonthlyParking|Documentation}
 * @param {object} params - The parameters to pass to the endpoint.
 * @param {string} params.loadMoreSpotsUrl - The @next URL returned by search api to load next set of spots.
 * @param {object} [formatOptions] - Additional options that don't get passed to the endpoint but are used for data transformation
 * @example
 * getMonthlyFacilitiesLoadMoreSpots({loadMoreSpotsUrl: 'https://api.staging.spothero.com/v2/search/...'});
 * @returns {Array} The available monthly facilities, with data transformation applied.
 */
export function getMonthlyFacilitiesLoadMoreSpots({ loadMoreSpotsUrl }, formatOptions) {
    const api = APIRegistry.get('craigSearch');
    return api.get(loadMoreSpotsUrl).then(({ 
    // @ts-expect-error - not in craig docs
    data: { results, '@next': nextLoadMoreSpotsUrl, experiment }, }) => {
        setBackendExperiments(experiment);
        return {
            loadMoreSpotsUrl: nextLoadMoreSpotsUrl,
            results: formatMonthlySearchResponse(results, formatOptions),
        };
    });
}
