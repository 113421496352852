import React from 'react';
import { connect } from 'react-redux';
import { Panel } from '@spothero/ui-backlog';
import dayjs from 'utils/dayjs-duration';
import useConfig from 'hooks/use-config';
export const FAQPanels = ({ cancellationAllowed, canCancelHPEReservation = false, eventCancellationMinutes = 0, isMonthly, phoneNumber, phoneNumberFriendly, monthlyPhoneNumber, monthlyPhoneNumberFriendly, hours, }) => {
    const { siteUrl } = useConfig();
    const phoneText = isMonthly
        ? monthlyPhoneNumberFriendly
        : phoneNumberFriendly;
    const phoneHref = isMonthly ? monthlyPhoneNumber : phoneNumber;
    const getCancellationText = () => {
        // is this a high profile event and can the user cancel?
        if (eventCancellationMinutes > 0 && canCancelHPEReservation) {
            return `This reservation can be cancelled up to ${dayjs
                .duration(eventCancellationMinutes, 'minutes')
                .asHours()} hours before the reservation start time `;
        }
        else {
            return 'At this facility, you have up until the minute your reservation begins to cancel your parking ';
        }
    };
    const canCancel = cancellationAllowed !== 'no' && eventCancellationMinutes > 0
        ? canCancelHPEReservation
        : cancellationAllowed !== 'no';
    return (<div className="FAQPanels">
            {canCancel && (<Panel title="Free Cancellation Policy" showTitleToggle>
                    <p>
                        {getCancellationText()}
                        for a full refund. You can cancel your reservation on
                        the SpotHero website or app.
                    </p>
                    <p>
                        If you&apos;re having trouble with your reservation and
                        it&apos;s after the start time, please reach out to our
                        Customer Heroes who are more than happy to help rectify
                        any situation!
                    </p>
                </Panel>)}
            <Panel title="365-Day Customer Support" showTitleToggle>
                <p>
                    SpotHero has your back. If you have any issues while
                    parking, please call our Customer Heroes immediately at{' '}
                    <a className="support-phone" href={`tel:${phoneHref}`}>
                        {phoneText}
                    </a>
                    . We&apos;re here 365 days a year,{' '}
                    <span className="support-hours">{hours}</span>.
                </p>
                <p>
                    For non-urgent issues shoot us an email at{' '}
                    <a href="mailto:support@spothero.com">
                        support@spothero.com
                    </a>
                    . We&apos;ll get back you within 24 hours.
                </p>
            </Panel>
            <Panel title="Guaranteed Parking by SpotHero" showTitleToggle>
                <p>
                    When you park and pay with SpotHero, we guarantee you will
                    have a spot to park in at the price you paid or your money
                    back.
                </p>
                <p>
                    If you need help with your reservation, please contact us,
                    and we&apos;ll do our best to make it right. Our world-class
                    Customer Hero team is available 7 days a week, 365 days a
                    year.
                </p>
                <p>
                    For specifics, please refer to the{' '}
                    <a href={`${siteUrl}/about/parking-guarantee`}>
                        SpotHero Parking Guarantee
                    </a>
                </p>
            </Panel>
        </div>);
};
const mapStateToProps = state => {
    const { searchRequest: { monthly: isMonthly }, city: { data: { phone_number: phoneNumber, phone_number_friendly: phoneNumberFriendly, monthly_phone_number: monthlyPhoneNumber, monthly_phone_number_friendly: monthlyPhoneNumberFriendly, weekday_hours: hours, }, }, } = state;
    return {
        isMonthly,
        phoneNumber,
        phoneNumberFriendly,
        monthlyPhoneNumber,
        monthlyPhoneNumberFriendly,
        hours,
    };
};
export default connect(mapStateToProps)(FAQPanels);
