import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, TabList, Tabs } from '@spothero/ui';
const tabButtonStyleProps = {
    fontWeight: 'semibold',
    fontSize: 'xs',
    paddingX: 0,
    paddingTop: 0,
    paddingBottom: 2,
    marginRight: 4,
    _selected: {
        borderBottom: '2px',
        borderBottomColor: 'green.700',
        height: 'auto',
        paddingBottom: 2,
        color: 'black',
    },
};
const tabListStyleProps = {
    borderBottom: '1px',
    borderBottomColor: 'gray.200',
};
const TabGroup = ({ items, defaultIndex = 0, onChange, styleOverrides, showTabPanels = false, renderPanels, tabProps, }) => {
    return (<Box sx={styleOverrides}>
            <Tabs data-testid="tabsContainer" defaultIndex={defaultIndex} onChange={onChange} {...tabProps}>
                <TabList data-testid="tablist" {...tabListStyleProps}>
                    {items.map(item => {
            const { label, value } = item;
            return (<Tab key={value} data-testid="singleTabItem" {...tabButtonStyleProps}>
                                {label}
                            </Tab>);
        })}
                </TabList>
                {showTabPanels && renderPanels()}
            </Tabs>
        </Box>);
};
TabGroup.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    defaultIndex: PropTypes.number,
    onChange: PropTypes.func,
    styleOverrides: PropTypes.object,
    showTabPanels: PropTypes.bool,
    renderPanels: PropTypes.func,
    tabProps: PropTypes.object,
};
export default TabGroup;
