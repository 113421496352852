import get from 'lodash/get';
import size from 'lodash/size';
import dayjs from 'utils/dayjs';
import SearchTracking from 'utils/search-tracking';
import SegmentUtils from 'utils/segment';
import UserUtils from 'utils/user-utils';
import ErrorUtils from 'utils/error-utils';
import getParkingType from '../utils/parking-type';
import getUserLocation from '../utils/user-location';

export default function trackAvailabilityCheck({
    searchRequest,
    city,
    destination = null,
    event = null,
    spot,
    userStatus,
    screen,
    actionType,
    isScan2Pay = false,
}) {
    try {
        const {
            monthly: isMonthly,
            search_string: query,
            starts,
            ends,
        } = searchRequest;
        const {slug: citySlug, state} = city;
        const {
            facility: {
                parking_spot_id: spotId,
                airport,
                title,
                operator_id: operatorId,
            },
            selectedRate,
            available_transient_inventory,
            available_monthly_inventory,
        } = spot;
        const isDestination = size(destination);
        const isEvent = size(event);
        const placeId = isDestination
            ? get(destination, 'google_places.0.google_places_place_id')
            : null;
        const searchStart = dayjs(starts).toISOString();
        const searchEnd = isMonthly ? null : dayjs(ends).toISOString();
        const availableSpaces =
            available_transient_inventory || available_monthly_inventory || 0;
        const {
            searchUUID,
            sessionUUID,
            action: trackingAction,
        } = SearchTracking.getValues();
        const parkingType = getParkingType({
            isMonthly,
            isAirport: airport,
            isEvent,
        });
        const properties = {
            search_query: query, // MARKED FOR DEPRECATION: query will be used moving forward
            search_start_time: searchStart, // MARKED FOR DEPRECATION: search_start_time_utc will be used moving forward
            search_end_time: searchEnd, // MARKED FOR DEPRECATION: search_end_time_utc will be used moving forward
            facility_id: spotId, // MARKED FOR DEPRECATION: parking_spot_id will be used moving forward
            action_type: actionType ? actionType : trackingAction,
            available_spaces: availableSpaces,
            ...(airport && {airport_code: airport.details.code}),
            city: citySlug,
            current_screen_name: screen,
            ...(isDestination && {
                destination_id: destination.id,
                ...(placeId && {google_places_place_id: placeId}),
            }),
            ...(isEvent && {
                event_id: event.id,
                ...(event.title && {event_name: event.title}),
            }),
            operator_id: operatorId,
            parking_spot_id: spotId,
            parking_spot_name: title,
            parking_type: parkingType,
            ...(selectedRate && {
                products: [
                    {
                        category: parkingType,
                        name: title,
                        price: selectedRate.price,
                        product_id: String(spotId),
                        quantity: 1,
                    },
                ],
            }),
            ...(query && {query}),
            ...(searchEnd && {search_end_time_utc: searchEnd}),
            search_id: searchUUID,
            search_start_time_utc: searchStart,
            session_id: sessionUUID,
            state,
            ...getUserLocation(),
            user_logged_in: userStatus === UserUtils.AUTH_STATE.USER,
            is_scan2pay: isScan2Pay,
        };

        SegmentUtils.track({
            event: 'Availability Check',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 2,
                    },
                },
            },
        });
    } catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackAvailabilityCheck',
        });
    }
}
