import React from 'react';
import { Box } from '@spothero/ui';
import SpotListSorter from 'common/search-sort-controls';
import { useSelector } from 'react-redux';
import { getParkingType } from 'store/selectors/selectors';
import { SPOT_SORT_OPTIONS } from 'utils/spot-sort';
import { ViewUpcomingEventsList } from './ViewUpcomingEventsList';
// Ideally we shift to this being the home of everything in between search component and the actual list
export const PreSpots = ({ showSpotListSorter, onChangeSort, showMobileToggle, VerticalPromo, }) => {
    const parkingType = useSelector(getParkingType);
    const isEventOrTransientOrPowerBookingParking = parkingType === 'event' ||
        parkingType === 'transient' ||
        parkingType === 'power_booking';
    const sortSelectItems = [
        { value: SPOT_SORT_OPTIONS.DISTANCE, label: 'Sort by Distance' },
        { value: SPOT_SORT_OPTIONS.PRICE, label: 'Sort by Price' },
    ];
    if (isEventOrTransientOrPowerBookingParking) {
        sortSelectItems.unshift({
            value: SPOT_SORT_OPTIONS.RELEVANCE,
            label: 'Sort by Relevance',
        });
    }
    const sortBy = useSelector((state) => state.search.data.sortBy);
    return (<Box data-testid="preSpotList">
            <ViewUpcomingEventsList />
            {showSpotListSorter ? (<Box sx={{
                '.SpotListSorter': {
                    marginTop: 2,
                    marginBottom: 3,
                    padding: 0,
                },
            }}>
                    <SpotListSorter sortBy={sortBy} 
        // @ts-expect-error - to fix
        onChangeSort={({ value }) => onChangeSort(value)} sortSelectItems={sortSelectItems} showMobileToggle={showMobileToggle}/>
                </Box>) : null}
            {VerticalPromo}
        </Box>);
};
