import dayjs from 'utils/dayjs';
import { ActionType } from 'redux-promise-middleware';
import Config from '@/config/index';
import CheckoutUtils from 'utils/checkout-utils';
import { CHECKOUT_FETCH_RATES } from '../checkout/checkout-actions';
import { ROUTER_DATA_LOAD_FULFILLED } from '../router/router-actions';
import { SPOT_UPDATE_SELECTED } from '../spot/spot-actions';
import { SEARCH_REQUEST_UPDATE_DATA, SEARCH_REQUEST_SET_TERM, SEARCH_REQUEST_SET_LOCATION, SEARCH_REQUEST_UPDATE_TIMES, } from './search-request-actions';
/* eslint-disable camelcase */
export const initialState = {
    id: null,
    monthly: false,
    powerBooking: false,
    airport: false,
    event: false,
    starts: null,
    powerBookingPeriods: [],
    ends: null,
    latitude: null,
    longitude: null,
    segment_id: null,
    distance__lt: 1609,
    distance__gt: null,
    search_string: null,
    search_url: null,
    original_search_string: null,
    users_latitude: null,
    users_longitude: null,
    ideal_search_distance: 0,
    rebook_reservation_id: null,
    eid: null,
    operator_id: null,
    pageType: null,
    isSearchPage: false,
    referrer: null,
    filterSpot: null,
    partner: null,
    powerBookingSource: null,
};
/* eslint-enable camelcase */
export default function searchRequest(state = { ...initialState }, { type, payload, meta }) {
    switch (type) {
        case ROUTER_DATA_LOAD_FULFILLED: {
            return {
                ...state,
                ...payload.searchRequest,
            };
        }
        case SEARCH_REQUEST_SET_TERM: {
            const { transient, monthly, airport, event, newRate, powerBooking, } = payload;
            const { starts, ends } = state;
            let newStarts = starts;
            let newEnds = ends
                ? ends
                : dayjs(starts)
                    .add(3, 'hours')
                    .format(Config.apiDateTimeFormat);
            if (monthly) {
                if (newRate) {
                    // can't pass current state into getMonthlyStart since state hasn't been fully updated
                    newStarts = CheckoutUtils.getMonthlyStart({
                        monthly,
                        starts: String(starts),
                    }, newRate);
                }
                newEnds = null;
            }
            else if (airport) {
                newEnds = ends;
            }
            return {
                ...state,
                monthly: Boolean(monthly),
                transient: Boolean(transient),
                starts: newStarts,
                ends: newEnds,
                airport: Boolean(airport),
                event: Boolean(event),
                powerBooking: Boolean(powerBooking),
            };
        }
        case SEARCH_REQUEST_UPDATE_DATA:
        case SEARCH_REQUEST_UPDATE_TIMES: {
            return {
                ...state,
                ...payload,
            };
        }
        case SEARCH_REQUEST_SET_LOCATION: {
            const { latitude, longitude } = payload;
            return {
                ...state,
                latitude,
                longitude,
            };
        }
        case SPOT_UPDATE_SELECTED: {
            return {
                ...state,
                referrer: payload.referrer,
            };
        }
        case `${CHECKOUT_FETCH_RATES}_${ActionType.Pending}`: {
            const { starts, ends } = meta;
            return {
                ...state,
                starts,
                ends,
            };
        }
        case `${CHECKOUT_FETCH_RATES}_${ActionType.Fulfilled}`: {
            const { monthly: isMonthly, rid } = meta;
            const spot = payload.data.data;
            const newSelectedRate = CheckoutUtils.selectRate({
                spot,
                isMonthly,
                rid,
            });
            return {
                ...state,
                starts: isMonthly
                    ? CheckoutUtils.getMonthlyStart(state, newSelectedRate)
                    : state.starts,
                ends: isMonthly ? null : state.ends,
            };
        }
        default:
            return state;
    }
}
