import React, { useEffect, useState, useCallback, createContext, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Auth0ServiceInterceptor from './auth0-service-interceptor';
import APIUtils, { APIRegistry } from '@spothero/utils/api';
import UserAPI from 'api/user';
import UserUtils from 'utils/user-utils';
import { userUpdate } from 'store/user/user-actions';
import ErrorUtils from 'utils/error-utils';
import AuthenticationUtils from 'utils/authentication';
import useConfig from 'hooks/use-config';
const defaultState = { isReady: false };
export const Auth0APISetupContext = createContext(defaultState);
export const Auth0APISetupProvider = ({ children, onUserUpdate, }) => {
    const { isAuthenticated, isLoading, getAccessTokenSilently, error, } = useAuth0();
    const [context, setContext] = useState(defaultState);
    const { siteUrl } = useConfig();
    const getUserData = useCallback(async () => {
        // first check if the user is logged in
        const isUserAuthenticated = await UserAPI.isAuthenticated();
        // if so, fetch their user profile data
        if (isUserAuthenticated) {
            const userData = await UserAPI.getUser();
            // userData gets normalized with defaults filling in the gap during getUser()
            // however status never gets set
            const normalizedUserData = UserUtils.normalize({
                ...userData,
                status: UserUtils.AUTH_STATE.USER,
            }, {
                resetToDefaults: true,
            });
            onUserUpdate(normalizedUserData);
        }
    }, [onUserUpdate]);
    // handles setting the auth0 token for the Auth0Service
    useEffect(() => {
        if (isAuthenticated) {
            Auth0ServiceInterceptor.setTokenGenerator(getAccessTokenSilently)
                .then(async () => {
                // All user-related APIs are in APIUtils (default APIRegistry)
                await Auth0ServiceInterceptor.attachInterceptorWithToken(APIUtils);
                // V1/Monolith rate search APIs return slightly different data for admins
                await Auth0ServiceInterceptor.attachInterceptorWithToken(APIRegistry.get('ratesSearch'));
                // Monolith view APIs, the base path differs from `APIUtils`
                await Auth0ServiceInterceptor.attachInterceptorWithToken(APIRegistry.get('auth'));
                // Attach to all V2 search calls to customize experience
                await Auth0ServiceInterceptor.attachInterceptorWithToken(APIRegistry.get('craigSearch'));
                await AuthenticationUtils.configureLoginCookies();
                setContext({ isReady: true });
                // use auth0 access token to retrieve user data and put in store
                await getUserData();
            })
                .catch(err => {
                ErrorUtils.sendSentryException(err);
            });
        }
        else if (!isLoading) {
            // set isLoading for user so we can tell if the user status is known as opposed to the default status of logged out
            // in the future the default user status should be unknown or undefined instead of logged out
            onUserUpdate({});
        }
    }, [
        isAuthenticated,
        getAccessTokenSilently,
        getUserData,
        onUserUpdate,
        isLoading,
        siteUrl,
        error,
    ]);
    return (<Auth0APISetupContext.Provider value={context}>
            {children}
        </Auth0APISetupContext.Provider>);
};
Auth0APISetupProvider.propTypes = {
    children: PropTypes.node.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
};
const mapDispatchToProps = {
    onUserUpdate: userUpdate,
};
export default connect(null, mapDispatchToProps)(Auth0APISetupProvider);
