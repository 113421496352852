import {Box, Text, Icon} from '@spothero/ui';
import IconFilledExclamationCircle from '@spothero/icons/exclamation-circle-filled';
import React from 'react';
import PropTypes from 'prop-types';

const AvailableSpotsBanner = ({availableSpots}) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            marginTop={-4}
            marginBottom={4}
            marginX={-4}
            padding={4}
            backgroundColor="yellow.100"
        >
            <Box width={5} height={5}>
                <Icon
                    as={IconFilledExclamationCircle}
                    color="yellow.500"
                    width={6}
                    height={6}
                />
            </Box>
            <Box marginLeft={4}>
                <Text fontSize="sm" fontWeight="semibold" as="strong">
                    We suggest you book now.
                </Text>
                <Box>
                    We only have
                    <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        as="strong"
                        paddingX={1}
                    >
                        {availableSpots}{' '}
                        {availableSpots === 1 ? 'spot' : 'spots'}
                    </Text>
                    remaining here during the times you selected.
                </Box>
            </Box>
        </Box>
    );
};

AvailableSpotsBanner.propTypes = {
    availableSpots: PropTypes.number,
};

export default AvailableSpotsBanner;
