import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import {compose} from 'redux';
import classNames from 'classnames';
import {Button} from '@spothero/ui';
import SpotListItemBase from 'common/spot-card-base/v2';
import FacilitySummary from 'common/facility-summary/v2';
import {push} from 'store/router/router-actions';
import {PROPTYPE_FACILITY_SUMMARY} from '@/proptypes/index';
import {routeToSpotDetails} from 'router/router-utils';
import withConfig from '@/config/withConfig';
import {setSpotDetailReferrer} from 'store/spot/spot-actions';
import DynamicSpotListItemButton from '../spot-card-dynamic-CTA-button';
import SpotCardAvailableSpotsBadge from '../spot-card-available-spots-badge';
import {showSpotsLeft} from 'utils/spot-left';
import {FACILITY_SUMMARY_VIEW_TYPES} from 'common/facility-summary/utils';
import {useLabel} from 'hooks/spot/price/use-label';

export const SpotListItemMonthly = ({
    spot,
    isActive,
    isAdmin,
    isRebookingReservation,
    history,
    pushTo,
    setSpotDetailReferrerTo,
    isPowerBooking,
}) => {
    const {highlightedSpotId} = useSelector(state => state.spots);
    const {spotId, availability, selectedRate, facilityType} = spot;
    const isHighlighted = `${highlightedSpotId}` === `${spotId}`;
    const {available, availableSpaces} = availability;
    const containerClasses = classNames(
        {'SpotListItem-active': isActive},
        {'SpotListItem-hovered': isHighlighted},
        {unavailable: !available}
    );

    const priceLabel = useLabel();

    const onShowDetailsClick = evt => {
        evt.preventDefault();

        const {location} = history;

        setSpotDetailReferrerTo('list');
        routeToSpotDetails({
            method: pushTo,
            location,
            spotId,
            historyState: {referrer: 'list'},
        });
    };

    const handleBookSpotClick = evt => {
        evt.preventDefault();
        evt.stopPropagation();

        if (!selectedRate) {
            return null;
        }

        const {fullUrl} = selectedRate;

        pushTo(fullUrl);
    };

    const isShowSpotsLeftVisible = showSpotsLeft({
        available,
        availableSpaces,
        facilityType,
    });

    const getCTAs = () => {
        return isPowerBooking ? (
            <>
                {isShowSpotsLeftVisible ? (
                    <SpotCardAvailableSpotsBadge
                        availableSpots={availableSpaces}
                    />
                ) : null}
                <Button
                    variant="tertiary"
                    className="show-details hide-on-mobile"
                    onClick={onShowDetailsClick}
                >
                    Details
                </Button>
                <DynamicSpotListItemButton
                    isAdmin={isAdmin}
                    available={available}
                    isRebookingReservation={isRebookingReservation}
                    isHovered={isHighlighted}
                    onClick={handleBookSpotClick}
                />
            </>
        ) : (
            <DynamicSpotListItemButton
                isAdmin={isAdmin}
                available={available}
                isRebookingReservation={isRebookingReservation}
                isHovered={isHighlighted}
                onClick={onShowDetailsClick}
                isMonthly
            />
        );
    };

    return (
        <SpotListItemBase
            spotId={spotId}
            additionalClasses={containerClasses}
            onClick={onShowDetailsClick}
            MainContent={
                <FacilitySummary
                    spot={spot}
                    priceLabel={priceLabel}
                    isAdmin={isAdmin}
                    isPowerBooking={isPowerBooking}
                    CTAs={getCTAs()}
                    view={FACILITY_SUMMARY_VIEW_TYPES.SPOT_LIST}
                />
            }
        />
    );
};

SpotListItemMonthly.propTypes = {
    spot: PROPTYPE_FACILITY_SUMMARY,
    isActive: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isRebookingReservation: PropTypes.bool,
    history: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
        }),
    }),
    pushTo: PropTypes.func,
    setSpotDetailReferrerTo: PropTypes.func,
    isPowerBooking: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        searchRequest: {powerBooking: isPowerBooking},
    } = state;

    return {
        isPowerBooking,
    };
};

const mapDispatchToProps = {
    pushTo: push,
    setSpotDetailReferrerTo: setSpotDetailReferrer,
};

const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default withConfig(['isMobile'])(enhance(SpotListItemMonthly));
