import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'moment-duration-format';
import 'scrollingelement';
import 'transitionEnd';
import {loadableReady} from '@loadable/component';
import isNil from 'lodash/isNil';
import React from 'react';
import {hydrateRoot} from 'react-dom/client';
import {Provider as ReduxProvider} from 'react-redux';
import {Router} from 'react-router-dom';
import APIUtils from '@spothero/utils/api';
import createStore, {getHistory} from 'store/store';
import SegmentUtils from 'utils/segment';
import startup from './startup';
import App from './App';
import {HelmetProvider} from 'react-helmet-async';
import {ThemeProvider} from '@spothero/ui';
import * as customTheme from 'theme';
import AuthProvider from './auth/AuthProvider';
import OptimizelyProvider from './plugins/optimizely/components/SHOptimizelyProvider';
import trackExperimentViewed from 'segment/events/experiment-viewed';

const appData = window.__INITIAL_DATA__; // eslint-disable-line no-underscore-dangle
const {
    configData,
    initialState,
    destination,
    pageType,
    optimizelyDatafile,
    optimizelyUserId,
    optimizelyAttributes,
    ttfrTimer,
    helmetContext,
} = appData;
const {
    searchRequest: {isSearchPage, starts},
    user: {data: user},
} = initialState;

// Fix for retries resulting from a relative base path.
// This axios PR contains a likely fix and was merged in Sep 2019: https://github.com/axios/axios/pull/2391
// We can remove this line after a new version of axios is published to npm
configData.spotHeroAPIBasePath = `${configData.siteUrl}${configData.spotHeroAPIBasePath}`;

APIUtils.init({
    baseUrl: configData.spotHeroAPIBasePath,
    additionalHeaders: {
        Authorization: null,
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-spothero-spa': 'consumer-web', // Used by DevTools to track web application traffic
        'SpotHero-Version': '2019-08-13',
    },
});

if (isSearchPage && !isNil(starts)) {
    // every search page that has a map would also have a starts date set,
    // whereas pages like airport search that is a landing page wouldn't have starts set
    SegmentUtils.startTimeToFirstResultTimer({
        isInitialLoad: true,
        timerAddition: ttfrTimer,
    });
}

const render = async () => {
    const isBrowser = typeof window !== 'undefined';
    const history = getHistory();

    await startup({
        configData,
        isBrowser: true,
        optimizelyData: optimizelyDatafile,
        pageType,
        destination,
        user,
    });

    const container = document.getElementById('root');
    const environment = configData?.isProduction ? 'production' : 'staging';
    const handleOptimizelyDecision = ({variationKey, flagKey}) => {
        trackExperimentViewed({
            variationId: variationKey,
            variationName: variationKey,
            experimentId: flagKey,
            experimentName: flagKey,
        });
    };

    const optimizelyDatafileToUse =
        optimizelyDatafile || (isBrowser ? window?.optimizelyDatafile : null);

    await loadableReady(() => {
        hydrateRoot(
            container,
            <HelmetProvider context={helmetContext}>
                <ReduxProvider store={createStore(initialState)}>
                    <Router history={history}>
                        <AuthProvider>
                            <ThemeProvider theme={{...customTheme}}>
                                <OptimizelyProvider
                                    optimizelyDataFile={optimizelyDatafileToUse}
                                    environment={environment}
                                    optimizelyUserId={optimizelyUserId}
                                    optimizelyAttributes={optimizelyAttributes}
                                    config={configData}
                                    onDecision={handleOptimizelyDecision}
                                >
                                    <App {...appData} />
                                </OptimizelyProvider>
                            </ThemeProvider>
                        </AuthProvider>
                    </Router>
                </ReduxProvider>
            </HelmetProvider>
        );
    });

    delete window.__INITIAL_DATA__; // eslint-disable-line no-underscore-dangle
};

render();
