import React from 'react';
import { Box, Text, Flex, Icon, Button } from '@spothero/ui';
import { InfoCircleFilled as IconInfo } from '@spothero/icons';
import ReservationDuration from 'common/spot-details/reservation-duration';
import { Price } from 'common/facility-summary/price';
const SingleRate = ({ title, onInfoButtonClick, onPriceLabelClick, priceLabel, price, starts, ends, differentDurations, showTotal = false, }) => {
    return (<Box width="100%" height="100%" data-testid={`single-rate-${title}`}>
            <Flex>
                <Flex>
                    <Text fontSize="sm" fontWeight="semibold" paddingBottom="1" alignItems="center" maxWidth={onInfoButtonClick ? '200px' : 'none'}>
                        {title}
                    </Text>
                    {onInfoButtonClick && (<Button onClick={onInfoButtonClick} variant="tertiary" data-testid={`single-rate-button-${title}`}>
                            <Icon marginLeft={2} height="auto" as={IconInfo}/>
                        </Button>)}
                </Flex>
                <Box flexGrow={1} sx={{
            sup: { top: '-0.3em', fontSize: '70%' },
            '& .FacilitySummary-price-container': {
                flexDirection: showTotal ? 'column' : 'row',
                justifyContent: 'flex-end',
                ' & .chakra-text': {
                    color: 'text.primary.light',
                    fontSize: 'base',
                },
            },
            '& .FacilitySummary-FormattedPrice': {
                fontSize: 'base',
                fontWeight: 'semibold',
            },
        }} data-testid="SingleRate-cost-inout">
                    <Price price={price} priceLabel={priceLabel} onShowPriceBreakdown={onPriceLabelClick}/>
                </Box>
            </Flex>
            {differentDurations && (<ReservationDuration starts={starts} ends={ends} marginTop="1"/>)}
        </Box>);
};
export default SingleRate;
