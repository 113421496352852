import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Icon } from '@spothero/ui';
import IconFilter from '@spothero/icons/slider';
export const CountOrIcon = () => {
    const { activeAmenities } = useSelector((state) => state.filters);
    const count = activeAmenities.length;
    return count ? (<Flex minWidth="4" height="4" borderRadius={count.toString().length > 1 ? '6px' : '100%'} color="white" backgroundColor="black" justifyContent="center" alignItems="center">
            {count}
        </Flex>) : (<Icon as={IconFilter} width="4" height="4"/>);
};
