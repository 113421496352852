import includes from 'lodash/includes';
import find from 'lodash/find';
import dayjs from 'utils/dayjs';
import {ActionType} from 'redux-promise-middleware';
import {v4 as uuidV4} from 'uuid';
import FormatUtils from 'utils/format';
import UserUtils from 'utils/user-utils';
import SpotUtils from 'utils/spot';
import ReservationUtils from 'utils/reservation-utils';
import Config from '@/config/index';
import {
    CHECKOUT_FETCH_RATES,
    CHECKOUT_SHOW_CHANGE_DATE_TIME,
    CHECKOUT_HIDE_CHANGE_DATE_TIME,
    CHECKOUT_HIDE_RECOMMENDED_SPOTS_DETAILS,
    CHECKOUT_UPDATE_DATA,
    CHECKOUT_USER_CHANGE,
    CHECKOUT_SHOW_RATE_DETAILS,
    CHECKOUT_HIDE_RATE_DETAILS,
    CHECKOUT_TOGGLE_RATE_ONLINE_COMMUTER_DETAILS,
    CHECKOUT_TOGGLE_TIME_EXTENDED_DETAILS,
    CHECKOUT_HIDE_REDUNDANT_MONTHLY_DETAILS,
    CHECKOUT_PAYMENT_TOKEN_REQUESTED,
    CHECKOUT_PAYMENT_ERROR,
    CHECKOUT_ERROR,
    CHECKOUT_ON_PURCHASE,
    CHECKOUT_PURCHASE_ERROR,
    CHECKOUT_CLEAR_CUSTOMER,
    CHECKOUT_INIT_USER_DATA,
    CHECKOUT_SHOW_NO_INVENTORY,
    CHECKOUT_FETCH_UPSELL_RATES,
    CHECKOUT_SWAP_UPSELL_RATE,
    CHECKOUT_SET_EXTENSION_UPSELL_SELECTED,
    CHECKOUT_TOGGLE_ADDON,
    CHECKOUT_FETCH_RENTAL_ADDONS,
    CHECKOUT_SET_VEHICLE_SIZE_EXCEEDED,
    CHECKOUT_OPERATOR_INFO,
} from './checkout-actions';
import {DEFAULT_ERROR_SUBTITLE} from './checkout-constants';
import {SPOT_CHANGE_MULTI_RATE} from '../spot/spot-actions';
import {USER_UPDATE, USER_LOGOUT} from '../user/user-actions';
import {ROUTER_DATA_LOAD_FULFILLED} from '../router/router-actions';
import {RESET_SMS_PERMISSIONS} from 'store/sms-permissions/sms-permissions-actions';

let spotData;

const defaultHeroControls = () => ({
    paymentRequired: true,
    paymentRequiredNoCharge: false,
    purchaseForCustomer: false,
    purchaseForCustomerDescription: '',
    initialReservationId: null,
    purchaseOnBehalfOfCustomer: false,
    rebookReservationId: null,
    isRebookingReservation: false,
});

const getMonthlyDependentSettings = isMonthly => {
    const supportedFeeTypes =
        spotData.facility && spotData.facility.supported_fee_types
            ? spotData.facility.supported_fee_types
            : null;

    const hasOversizeFee =
        // TODO: Once Monthly Checkout on search v2, remove top condition VERT-542
        // Clean up during feature flag USE_MONTHLY_V2_SEARCH_FACILITY_FOR_CHECKOUT clean up

        (spotData.facility &&
            spotData.facility.vehicle_size === 'oversized_with_fee' &&
            spotData.facility.oversize_vehicle_type === null) ||
        (spotData.facility && spotData.facility.oversize_fees_charged_onsite) ||
        (supportedFeeTypes && includes(supportedFeeTypes, 'oversize_fee')) ||
        spotData?.facilityVehicle?.unknownOnsiteFee ||
        spotData?.facilityVehicle?.onsiteFee;

    return {
        licensePlateRequired: isMonthly
            ? true
            : spotData.license_plate_required,
        phoneNumberRequired: isMonthly ? true : spotData.phone_number_required,
        smsParkingPass: false,
        vehicleSelectPresent: isMonthly ? false : Boolean(hasOversizeFee),
        vehicleSelectRequired: isMonthly ? false : Boolean(hasOversizeFee),
    };
};

const getCreditCardsForCheckout = (creditCards = []) => {
    const defaultCard = creditCards.length
        ? find(creditCards, {isDefault: true}) || creditCards[0] // if user has cards but no default is set
        : null;

    return {
        selectedCreditCard: defaultCard ? defaultCard.externalId : 'new',
        isNewCreditCard: !creditCards.length,
    };
};

const getLicensePlatesForCheckout = (licensePlates = []) => {
    const defaultPlate = licensePlates.length
        ? find(licensePlates, {isDefault: true})
        : null;

    return {
        selectedLicensePlate: defaultPlate ? defaultPlate.licensePlate : null,
        newLicensePlate: !defaultPlate,
    };
};

const getVehicleForCheckout = ({
    vehicleProfileId,
    vehicles = [],
    overrideVehicle = null,
}) => {
    const hasVehicles = vehicles && vehicles.length;
    const defaultVehicle =
        vehicleProfileId && hasVehicles && find(vehicles, {vehicleProfileId})
            ? find(vehicles, {vehicleProfileId})
            : hasVehicles
            ? find(vehicles, {isDefault: true}) || vehicles[0]
            : {
                  vehicleProfileId: null,
                  isUnknown: false,
              };

    return {
        vehicleProfileId: overrideVehicle?.vehicle_profile_id
            ? overrideVehicle?.vehicle_profile_id
            : overrideVehicle
            ? null
            : defaultVehicle.vehicleProfileId,
        vehicleInfoId: overrideVehicle?.id || defaultVehicle.vehicleInfoId,
        isVehicleUnknown:
            overrideVehicle?.id === 0
                ? true
                : overrideVehicle
                ? false
                : defaultVehicle.isUnknown || false,
    };
};

export const getUserSettings = ({
    vehicleProfileId = null,
    userData: {creditCards, licensePlates, vehicles},
    overrideVehicle = null,
}) => ({
    ...(creditCards && getCreditCardsForCheckout(creditCards)),
    ...(licensePlates && getLicensePlatesForCheckout(licensePlates)),
    ...(vehicles &&
        getVehicleForCheckout({
            vehicleProfileId,
            vehicles,
            overrideVehicle,
        })),
});

export const initialState = (stateOverrides = null) => ({
    ...defaultHeroControls(),
    // rateSessionId resets every time a rate load is attempted
    rateSessionId: null,
    vehicleSelectPresent: false,
    vehicleSelectRequired: false,
    vehicleProfileId: null,
    vehicleInfoId: null,
    vehicleColor: null,
    supportsOversizeFeeType: false,
    isVehicleUnknown: false,
    saveVehicleAsDefault: false,
    selectedLicensePlate: null,
    licensePlateState: null,
    licensePlateUnknown: null,
    newLicensePlate: true,
    selectedCreditCard: 'new',
    isNewCreditCard: true,
    incomingPromoCode: null,
    promoCode: null,
    adminUser: {},
    currencyType: UserUtils.CURRENCY_TYPES.USD,
    currencySymbol: FormatUtils.currencySymbol(UserUtils.CURRENCY_TYPES.USD),
    affiliate: null,
    licensePlateRequired: false,
    phoneNumberRequired: false,
    contractFullName: null,
    smsParkingPass: false,
    referralSource: '',
    forceRedundantMonthly: false,
    previousRental: false,
    reservationDetailsSkipped: false,
    reservationDetailsEdit: true,
    rid: null,
    isFetchingRates: false,
    highlightAccountPanel: false,
    isPurchasing: false,
    isStaleStart: false,
    showRateDetailsModal: false,
    rateDetailsModalSection: null,
    showOnlineCommuterRateModal: false,
    showChangeDateTimeModal: false,
    changeDateTimeModalTitle: null,
    changeDateTimeModalIntroMessage: null,
    changeDateTimeModalButtonText: null,
    showWhyTimeExtendedModal: false,
    showRecommendedSpotsModal: false,
    noInventoryError: null,
    showRedundantMonthlyReservationModal: false,
    redundantMonthlyReservationModalDate: null,
    redundantMonthlyReservationModalParkingPassURL: null,
    recommendedSpotsUnavailableReason: 'Rate unavailable.',
    checkoutError: null,
    checkoutStartTime: dayjs(),
    changedDateTimeCount: 0,
    isPurchasingWithPaymentRequest: false,
    isScan2payFacility: false,
    isScan2payLanding: false,
    extensionUpsellSelected: null,
    isFetchingExtensionUpsell: false,
    extensionUpsellRateInfo: {},
    selectedAddons: [],
    rentalAddons: [],
    facilityVehicle: null,
    vehicleSizeExceeded: false,
    operatorInfo: null,
    ...stateOverrides,
});

export default function checkout(
    state = initialState(),
    {type, payload, meta}
) {
    switch (type) {
        case `${CHECKOUT_FETCH_RATES}_${ActionType.Pending}`: {
            const newState = {
                ...state,
                isFetchingRates: true,
                isStaleStart: false,
                showChangeDateTimeModal: false,
                checkoutError: null,
                extensionUpsellRateInfo: {},
            };

            if (meta.dateTimeChanged) {
                newState.changedDateTimeCount = state.changedDateTimeCount + 1;
            }

            return newState;
        }

        case `${CHECKOUT_FETCH_RATES}_${ActionType.Fulfilled}`: {
            spotData = payload.data.data;
            const {
                facility: {
                    supported_fee_types: supportedFeeTypes, // eslint-disable-line camelcase
                },
                currency_type: currencyType,
                facilityVehicle,
            } = spotData;

            const {
                monthly,
                airport,
                rid,
                previousRental,
                starts,
                ends,
                timezone,
                powerBooking,
                powerBookingSource,
            } = meta;

            const newStateSpot = SpotUtils.prepare({
                spot: spotData,
                rid,
                searchRequest: {
                    monthly,
                    airport,
                    starts,
                    ends,
                    powerBooking,
                    powerBookingSource,
                },
                timezone,
            });

            const unavailableReason = monthly
                ? newStateSpot.monthly_rates[0]?.unavailable?.unavailable_reason
                : newStateSpot?.availability?.unavailableReasons?.[0];
            const vehicleSizeExceeded = ReservationUtils.VEHICLE_SIZE_EXCEEDED_REASONS.includes(
                unavailableReason
            );

            const newStateData = {
                ...state,
                ...getMonthlyDependentSettings(monthly),
                rateSessionId: uuidV4(),
                currencySymbol: FormatUtils.currencySymbol(currencyType),
                currencyType: currencyType?.toUpperCase() || 'USD',
                isFetchingRates: false,
                checkoutError: null,
                recommendedSpotsUnavailableReason: 'Rate unavailable.',
                previousRental: previousRental || false,
                vehicleProfileId:
                    state.spot && state.spot.spotId !== spotData.parking_spot_id
                        ? null
                        : state.vehicleProfileId,
                rid,
                spot: newStateSpot,
                supportsOversizeFeeType:
                    supportedFeeTypes &&
                    includes(supportedFeeTypes, 'oversize_fee'),
                facilityVehicle,
                vehicleSizeExceeded,
            };

            if (!newStateData.spot.selectedRate) {
                const reasonType = monthly ? 'Monthly' : 'Transient';

                if (unavailableReason) {
                    newStateData.recommendedSpotsUnavailableReason = `${reasonType} Unavailable Reason: ${unavailableReason}`;
                }

                if (
                    !previousRental &&
                    !newStateData.isStaleStart &&
                    !newStateData.isScan2payFacility // this prevents the user from getting stuck in a modal loop and makes sure the modal closes after clicking apply
                ) {
                    newStateData.showChangeDateTimeModal = !vehicleSizeExceeded;
                    newStateData.changeDateTimeModalIntroMessage =
                        'Select your reservation start and end so we can lock in your low rate – up to 50% off street parking.';
                }
            }

            return newStateData;
        }

        case `${CHECKOUT_FETCH_RATES}_${ActionType.Rejected}`: {
            return {
                ...state,
                rateSessionId: uuidV4(),
                isFetchingRates: false,
                checkoutError: {
                    subtitle:
                        payload?.data?.data?.errors?.[0]?.title ||
                        DEFAULT_ERROR_SUBTITLE,
                    errors: payload?.data?.data?.errors?.[0]?.messages || [''],
                    codes: [payload?.data?.data?.errors?.[0]?.code],
                },
            };
        }

        case `${CHECKOUT_FETCH_UPSELL_RATES}_${ActionType.Pending}`: {
            return {
                ...state,
                extensionUpsellRateInfo: {},
                // This is necessary so that users don't see a flash of one rate then another if upsell selected,
                // but needs to fetch new rates, like on vehicle change
                isFetchingRates: state.extensionUpsellSelected !== null,
                isFetchingExtensionUpsell: true,
            };
        }
        case `${CHECKOUT_FETCH_UPSELL_RATES}_${ActionType.Rejected}`: {
            return {
                ...state,
                isFetchingRates: false,
                isFetchingExtensionUpsell: false,
            };
        }

        case `${CHECKOUT_FETCH_UPSELL_RATES}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                isFetchingRates: false,
                isFetchingExtensionUpsell: false,
                extensionUpsellRateInfo: payload.extensionUpsellRateInfo,
            };
        }

        case CHECKOUT_SHOW_CHANGE_DATE_TIME: {
            return {
                ...state,
                showChangeDateTimeModal: true,
                isStaleStart: payload.isStaleStart,
                changeDateTimeModalTitle: payload.title,
                changeDateTimeModalIntroMessage: payload.message,
                changeDateTimeModalButtonText: payload.buttonText,
            };
        }

        case CHECKOUT_HIDE_CHANGE_DATE_TIME: {
            return {
                ...state,
                showChangeDateTimeModal: false,
                showRecommendedSpotsModal: payload.showRecommendedSpots,
                changeDateTimeModalButtonText: null,
                isScan2payLanding: false,
            };
        }

        case CHECKOUT_HIDE_RECOMMENDED_SPOTS_DETAILS: {
            return {
                ...state,
                showRecommendedSpotsModal: false,
                showChangeDateTimeModal: payload.showChangeDateTimeModal,
                changeDateTimeModalIntroMessage:
                    'Just FYI, the price or availability might change if you choose different options here.',
                changeDateTimeModalButtonText: null,
            };
        }

        case `${CHECKOUT_UPDATE_DATA}_FULFILLED`:
        case CHECKOUT_UPDATE_DATA: {
            return {
                ...state,
                ...payload,
            };
        }

        case CHECKOUT_USER_CHANGE: {
            const {
                user,
                userNormalized,
                extraReservationItems,
                overrideVehicle,
            } = payload;

            if (!user || user.status === UserUtils.AUTH_STATE.LOGGED_OUT) {
                return {
                    ...state,
                    selectedCreditCard: 'new',
                    isNewCreditCard: true,
                    selectedLicensePlate: null,
                    licensePlateState: null,
                    licensePlateUnknown: null,
                    newLicensePlate: true,
                    paymentRequired: true,
                    paymentRequiredNoCharge: false,
                    promoCode: null,
                    vehicleProfileId: null,
                    vehicleColor: null,
                    isVehicleUnknown: false,
                    smsParkingPass: false,
                    reservationDetailsSkipped: false,
                    reservationDetailsEdit: false,
                    phone: null,
                    smsPermissions: null,
                    ...extraReservationItems,
                };
            } else if (user.status === UserUtils.AUTH_STATE.USER) {
                const userData = !userNormalized
                    ? UserUtils.normalize(user)
                    : user;
                const {vehicleProfileId} = state;

                return {
                    ...state,
                    ...getUserSettings({
                        vehicleProfileId,
                        userData,
                        overrideVehicle,
                    }),
                    unknownLicensePlate: false,
                    reservationDetailsSkipped: false,
                    reservationDetailsEdit: true,
                    smsParkingPass: false,
                    phone: null,
                    smsPermissions: null,
                    ...extraReservationItems,
                };
            } else if (user.status === UserUtils.AUTH_STATE.GUEST) {
                return {
                    ...state,
                    smsParkingPass: user.smsParkingPass,
                    reservationDetailsSkipped: false,
                    reservationDetailsEdit: true,
                    vehicleProfileId: null,
                    vehicleColor: null,
                    isVehicleUnknown: false,
                    selectedLicensePlate: null,
                    licensePlateState: null,
                    licensePlateUnknown: null,
                    phone: null,
                    smsPermissions: null,
                    ...extraReservationItems,
                };
            }

            return {
                ...state,
                ...extraReservationItems,
            };
        }

        case USER_UPDATE:
        case CHECKOUT_INIT_USER_DATA: {
            const {vehicleProfileId} = state;

            return {
                ...state,
                ...getUserSettings({
                    vehicleProfileId,
                    userData: payload,
                    overrideVehicle: payload.overrideVehicle,
                }),
            };
        }

        case CHECKOUT_SHOW_RATE_DETAILS: {
            return {
                ...state,
                showRateDetailsModal: true,
                rateDetailsModalSection: payload.section,
            };
        }

        case CHECKOUT_HIDE_RATE_DETAILS: {
            return {
                ...state,
                showRateDetailsModal: false,
                rateDetailsModalSection: null,
            };
        }

        case CHECKOUT_TOGGLE_RATE_ONLINE_COMMUTER_DETAILS: {
            return {
                ...state,
                showOnlineCommuterRateModal: !state.showOnlineCommuterRateModal,
            };
        }

        case CHECKOUT_TOGGLE_TIME_EXTENDED_DETAILS: {
            return {
                ...state,
                showWhyTimeExtendedModal: !state.showWhyTimeExtendedModal,
            };
        }

        case CHECKOUT_SHOW_NO_INVENTORY: {
            return {
                ...state,
                noInventoryError: payload,
            };
        }

        case CHECKOUT_HIDE_REDUNDANT_MONTHLY_DETAILS: {
            return {
                ...state,
                showRedundantMonthlyReservationModal: false,
            };
        }

        case CHECKOUT_PAYMENT_TOKEN_REQUESTED: {
            return {
                ...state,
                isPurchasing: true,
                checkoutError: null,
            };
        }

        case CHECKOUT_PAYMENT_ERROR:
        case CHECKOUT_ERROR: {
            return {
                ...state,
                isPurchasing: false,
                checkoutError: {
                    subtitle: `Correct the following mistakes then press Pay & Reserve again. Don't worry, you won't be charged twice.`,
                    errors: payload.errors,
                    codes: [''],
                },
            };
        }

        case `${CHECKOUT_ON_PURCHASE}_${ActionType.Pending}`: {
            const isPurchasingWithPaymentRequest = meta
                ? meta.isPurchasingWithPaymentRequest
                : false;

            return {
                ...state,
                isPurchasing: true,
                redundantMonthlyReservationModalDate: null,
                redundantMonthlyReservationModalParkingPassURL: null,
                showRedundantMonthlyReservationModal: false,
                checkoutError: null,
                isPurchasingWithPaymentRequest,
            };
        }

        case `${CHECKOUT_ON_PURCHASE}_${ActionType.Rejected}`: {
            return {
                ...state,
                isPurchasing: false,
                checkoutError: null,
            };
        }

        case `${CHECKOUT_ON_PURCHASE}_${ActionType.Fulfilled}`: {
            const newConfirmationEnabled = !Config.isDev;

            return {
                ...state,
                isPurchasing: state.isPurchasing && newConfirmationEnabled,
                isPurchasingWithPaymentRequest:
                    state.isPurchasingWithPaymentRequest &&
                    newConfirmationEnabled,
            };
        }

        case CHECKOUT_PURCHASE_ERROR: {
            return {
                ...state,
                isPurchasing: false,
                forceRedundantMonthly: false,
                ...payload,
            };
        }

        case SPOT_CHANGE_MULTI_RATE: {
            return {
                ...state,
                extensionUpsellSelected: null,
                selectedAddons: [],
                rid: payload.rid,
            };
        }

        case CHECKOUT_TOGGLE_ADDON: {
            if (
                state.selectedAddons.find(
                    addon => addon.type === payload.addonType
                )
            ) {
                return {
                    ...state,
                    selectedAddons: state.selectedAddons.filter(
                        addon => addon.type !== payload.addonType
                    ),
                };
            }

            return {
                ...state,
                selectedAddons: [...state.selectedAddons, payload.addon],
            };
        }

        case CHECKOUT_SWAP_UPSELL_RATE: {
            return {
                ...state,
                rid: payload.rate.rule_group_id,
                spot: {...state.spot, selectedRate: payload.rate},
            };
        }

        case CHECKOUT_SET_EXTENSION_UPSELL_SELECTED: {
            return {
                ...state,
                extensionUpsellSelected:
                    payload.selected === state.extensionUpsellSelected
                        ? null
                        : payload.selected,
            };
        }

        case `${CHECKOUT_FETCH_RENTAL_ADDONS}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                rentalAddons: payload?.rentalAddons || [],
            };
        }
        case `${CHECKOUT_FETCH_RENTAL_ADDONS}_${ActionType.Rejected}`: {
            return {
                ...state,
                rentalAddons: [],
            };
        }
        case `${CHECKOUT_OPERATOR_INFO}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                operatorInfo: payload?.operatorInfo || null,
            };
        }
        case `${CHECKOUT_OPERATOR_INFO}_${ActionType.Rejected}`: {
            return {
                ...state,
                operatorInfo: null,
            };
        }

        case `${USER_LOGOUT}_${ActionType.Fulfilled}`:
        case CHECKOUT_CLEAR_CUSTOMER: {
            return {
                ...state,
                ...defaultHeroControls(),
                promoCode: null,
                adminUser: initialState().adminUser,
                noInventoryError: null,
                extensionUpsellSelected: null,
                extensionUpsellRateInfo: {},
                selectedAddons: [],
            };
        }

        case ROUTER_DATA_LOAD_FULFILLED: {
            const {
                checkout: {rid},
            } = payload;

            return {
                ...state,
                rid,
            };
        }

        case RESET_SMS_PERMISSIONS:
            return {
                ...state,
                smsPermissions: null,
            };

        case CHECKOUT_SET_VEHICLE_SIZE_EXCEEDED:
            return {
                ...state,
                vehicleSizeExceeded: payload.vehicleSizeExceeded,
            };

        default:
            return state;
    }
}
