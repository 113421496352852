import PropTypes from 'prop-types';
import { OBJECT_TRANSIENT_FACILITY, OBJECT_COMMON_FACILITY_SUMMARY, PROPTYPE_PRICE, AMENITY_SLUGS_V1, } from './search-v2';
export const PROPTYPE_EVENT_PACKAGE_VENUE = PropTypes.shape({
    id: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    timezone: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
});
export const PROPTYPE_EVENT_PACKAGE_EVENT = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    starts: PropTypes.string.isRequired,
    ends: PropTypes.string.isRequired,
    parkingWindow: PropTypes.shape({
        starts: PropTypes.string.isRequired,
        ends: PropTypes.string.isRequired,
    }).isRequired,
});
export const PROPTYPE_EVENT_PACKAGE = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    eventCount: PropTypes.number,
    events: PropTypes.arrayOf(PROPTYPE_EVENT_PACKAGE_EVENT),
});
/*
    The rate information for event package facilities differs from the rate
    information that is used on transient/monthly/airport verticals;
    some properties like start, ends, and rate id do not apply.
*/
export const PROPTYPE_EVENT_PACKAGE_BASE_RATE = PropTypes.shape({
    totalPrice: PROPTYPE_PRICE.isRequired,
    advertisedPrice: PROPTYPE_PRICE.isRequired,
    fullUrl: PropTypes.string.isRequired,
    amenities: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        type: PropTypes.oneOf(AMENITY_SLUGS_V1).isRequired,
    }).isRequired).isRequired,
});
export const PROPTYPE_EVENT_PACKAGE_FACILITY_SUMMARY = PropTypes.shape({
    ...OBJECT_COMMON_FACILITY_SUMMARY,
    selectedRate: PROPTYPE_EVENT_PACKAGE_BASE_RATE,
});
export const PROPTYPE_EVENT_PACKAGE_FACILITY = PropTypes.shape({
    ...OBJECT_TRANSIENT_FACILITY,
    eventPackage: PROPTYPE_EVENT_PACKAGE,
});
