import React from 'react';
import { Heading, Text, Box, Icon, useBreakpointValue } from '@spothero/ui';
import IconInfo from '@spothero/icons/info-circle-filled';
import DOMPurify from 'dompurify';
const PricingDetailsMonthly = ({ pricingDetails, isRecurring, }) => {
    const isMobile = useBreakpointValue({ base: true, tablet: false });
    if (!pricingDetails) {
        return null;
    }
    const sanitizedPricingDetails = DOMPurify.sanitize(pricingDetails);
    return (<Box mx={isMobile ? 4 : 0}>
            <Heading fontSize="base">Pricing Details</Heading>
            {!isRecurring && (<Box width="100%" backgroundColor="background.gray" height={10} mt={3} display="flex" alignItems="center" data-testid="PricingDetailsMonthly-info-text">
                    <Icon as={IconInfo} alt="Circle Info Icon" fill="text.secondary.light" height="18px" width="18px" ml={2} data-testid="PricingDetailsMonthly-info-icon"/>
                    <Text fontWeight="normal" fontSize="sm" ml={2}>
                        This rate may change after the first month
                    </Text>
                </Box>)}
            <Box as="div" fontWeight="normal" fontSize="sm" mt={3} data-testid="PricingDetailsMonthly-text" dangerouslySetInnerHTML={{
            __html: sanitizedPricingDetails,
        }}/>
        </Box>);
};
export default PricingDetailsMonthly;
