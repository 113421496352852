import UrlUtils from '@spothero/utils/url';
/**
 * Get ID of Spot in the Spot Details Panel.
 * We use the URL spot-id param to get Spot ID as Redux state sometimes is out of sync.
 */
export const getSelectedSpotId = () => {
    const urlParams = typeof window !== 'undefined' &&
        UrlUtils.parseParams(window.location.search);
    const urlParamSpotId = urlParams && urlParams['spot-id'];
    return `${urlParamSpotId || ''}`;
};
/**
 * Get details of Spot in the Spot Details Panel.
 */
export const getSelectedSpot = (spots) => {
    const spotId = getSelectedSpotId();
    if (!spotId.length || !spots.length) {
        return null;
    }
    return spots.find(s => String(s.spotId) === spotId);
};
export const getRankOfSpot = (spots) => {
    const spotId = getSelectedSpotId();
    if (!spotId.length || !spots.length) {
        return;
    }
    return String(spots.findIndex(s => String(s.spotId) === spotId));
};
/**
 * Returns distance of Spot in the Spot Details Panel.
 */
export const getSelectedSpotDistance = (spot) => {
    const { linearMeters, walkingInMiles, durationSeconds, walkingMeters } = spot?.distance || {};
    return {
        ...(linearMeters ? { distance: linearMeters } : {}),
        ...(walkingMeters ? { walkingMeters } : {}),
        // TODO: remove walkingInMiles as it can be computed from walkingMeters
        ...(walkingInMiles ? { distanceInMiles: walkingInMiles } : {}),
        ...(durationSeconds ? { walkingDuration: durationSeconds } : {}),
    };
};
