import React from 'react';
import { Radio, Flex } from '@spothero/ui';
const BorderedRadio = ({ children, isSelected, isParent, value, radioStyles = {}, }) => {
    return (<Flex borderRadius="md" borderWidth="1px" borderColor={isParent
            ? 'transparent'
            : isSelected
                ? 'primary.default'
                : 'gray.200'}>
            <Radio width="100%" height={12} paddingX={4} alignItems="center" value={value} {...radioStyles}>
                {children}
            </Radio>
        </Flex>);
};
export default BorderedRadio;
