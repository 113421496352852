import React from 'react';
import { Box, Icon, Button, Loader, Flex } from '@spothero/ui';
import { ChevronRight } from '@spothero/icons';
const hoverFocusStyle = {
    color: 'text.primary.light',
    borderRadius: 'md',
    backgroundColor: 'gray.50',
};
export const GenericListItem = (props) => {
    const { handleClick, eventUrl, isLoaderShowing, asAnchor = true, children, textTransform = 'capitalize', sx = {}, } = props;
    return (<Button onClick={handleClick} variant="tertiary" width="100%" textAlign="left" color="text.primary.light" data-testid={props['data-testid']} asAnchor={asAnchor} display="flex" justifyContent="space-between" href={eventUrl} paddingX={'px'} // Removes jitter when border added for hover & focus
     paddingY={3.5} _hover={{ desktop: hoverFocusStyle, base: {} }} _focus={hoverFocusStyle} padding={'pickle'} // sending bad css to over write the unset
     textTransform={textTransform} sx={{
            // create a bottom border that spans the width of the button except for the border radius
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: { base: 0, mobileXL: 2 },
                right: 2,
                height: '1px',
                backgroundColor: 'gray.50',
            },
            ...sx,
        }}>
            {children}
            <Box paddingRight="2" flexGrow={{ base: '1', mobileXL: '0' }} display="flex" justifyContent="flex-end" alignItems="start" gap={0}>
                {isLoaderShowing ? (<Flex alignSelf="center" alignItems="center" justifyContent="center" width={2} height={2}>
                        <Loader size={4} thickness={10} color="primary.default"/>
                    </Flex>) : (<Icon color={{ base: 'black', mobileXL: 'primary.default' }} marginTop={0.5} width="4.5" height="4.5" as={ChevronRight}/>)}
            </Box>
        </Button>);
};
