import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
/**
 * Tracks an error message displayed event in Segment
 *
 * @param {string} message - error message (property: error message)
 * @param {string | null} action - error action
 * @param {string | null} screen - error screen
 * @param {string | null} errorMessage - error message (property: error_message)
 * @param {string | null} errorHeader - error header
 * @param {string | null} currentScreenName - current screen name
 * @param {string | null} city - city slug
 * @param {string | null} pageType - page type
 * @param {string | null} parkingType - parking type
 * @param {string | null} query - query
 * @param {string | null} searchId - search id (searchUUID)
 */
export default function trackErrorMessageDisplayed({ message, action = null, screen = null, errorMessage = null, errorHeader = null, currentScreenName = null, city = null, pageType = null, parkingType = null, query = null, searchId = null, }) {
    /**
     * Converts a key and value to an object if value is truthy
     *
     * @param {string} key - key to use for object
     * @param {string|null|undefined} value - value to use for object
     * @returns {{}|{}} - empty object or object with key and value
     */
    const toObjectWhenTruthy = (key, value) => (value ? { [key]: value } : {});
    try {
        /* eslint-disable camelcase */
        SegmentUtils.track({
            event: 'Error Message Displayed',
            properties: {
                'error message': message,
                ...toObjectWhenTruthy('error_message', errorMessage),
                ...toObjectWhenTruthy('error action', action),
                ...toObjectWhenTruthy('error screen', screen),
                ...toObjectWhenTruthy('error_header', errorHeader),
                ...toObjectWhenTruthy('current_screen_name', currentScreenName),
                ...toObjectWhenTruthy('city', city),
                ...toObjectWhenTruthy('page_type', pageType),
                ...toObjectWhenTruthy('parking_type', parkingType),
                ...toObjectWhenTruthy('query', query),
                ...toObjectWhenTruthy('search_id', searchId),
            },
        });
        /* eslint-enable camelcase */
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackErrorMessageDisplayed',
        });
    }
}
