import {Flex} from '@spothero/ui';
import ReservationDuration from 'common/spot-details/reservation-duration';
import AmenitiesInOutSummary from 'common/spot-details/amenities-inout-summary';
import React from 'react';
import PropTypes from 'prop-types';

const InOutReservationSummary = ({starts, ends, hasInOutAmenity}) => {
    return (
        <Flex
            alignItems="center"
            paddingTop={1}
            flexWrap="wrap"
            data-testid="InOutReservationSummary"
        >
            <ReservationDuration starts={starts} ends={ends} marginRight={4} />
            <AmenitiesInOutSummary hasInOutAmenity={hasInOutAmenity} />
        </Flex>
    );
};

InOutReservationSummary.propTypes = {
    starts: PropTypes.string,
    ends: PropTypes.string,
    hasInOutAmenity: PropTypes.bool,
};

export default InOutReservationSummary;
