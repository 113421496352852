import FETimeUtils from '@spothero/utils/time';
import DateUtils from 'utils/date';
import dayjs from 'utils/dayjs-timezone';
// eslint-disable-next-line prettier/prettier
import { isDayjs } from 'dayjs';
import dayjsduration from 'utils/dayjs-duration';
dayjs.extend(dayjsduration);
export const isMoreThan24Hours = ({ starts, ends }) => dayjs(ends).diff(dayjs(starts), 'minutes') / 60 >= 24;
/**
 * Utilities for working with times.
 *
 * @module TimeUtils
 */
const TimeUtils = {
    /**
     *
     * @param {string} timezone - The target city timezone string value i.e. "America/New_York".
     * @param {Dayjs} nowOverride - Override the now for unit testing
     */
    getMinimumStartDateTimeForTimezone(timezone, nowOverride = null) {
        const now = (nowOverride || dayjs()).tz(timezone);
        // Reference: https://github.com/spothero/SpotHero-Django/blob/59d50b593c7f178f8f18853832ffc440bd498f69/availability/services/search.py#L617
        // The time is in 30 minute intervals i.e. 12:00, 12:30, 1:00, 1:30, etc.
        const roundedMinutes = now.minute() < 30 ? 0 : 30;
        return now.set('minute', roundedMinutes)
            .set('second', 0)
            .set('millisecond', 0);
    },
    /**
     * Check if time is more than 30 minutes in the past and return updated time if not.
     *
     * @static
     * @function checkStaleStart
     * @param {object} starts - The start date/time as a dayjs instance.  Expected to be local time not timezoned yet
     * @param {string} currentCityTimezone - The target city timezone string value i.e. "America/New_York".
     * @example
     * const {
     *     newStart,
     *     isNewStart
     * } = TimeUtils.checkStaleStart(dayjs(), 'America/New_York');
     * @returns {object} - Returns the newStart (a dayjs instance) and a boolean isNewStart.
     */
    checkStaleStart(starts, currentCityTimezone) {
        // Recursive calls to ensure data state
        if (!currentCityTimezone) {
            return this.checkStaleStart(starts, DateUtils.getTimeZone());
        }
        if (!isDayjs(starts)) {
            return this.checkStaleStart(dayjs(starts), currentCityTimezone);
        }
        const now = dayjs();
        const nowLocalizedTimezone = now.tz(currentCityTimezone);
        const startDateTimezone = starts.tz(currentCityTimezone, true);
        if (startDateTimezone.isBefore(nowLocalizedTimezone.subtract(30, 'minutes'))) {
            const nowMinute = nowLocalizedTimezone.minute();
            if (nowMinute > 30) {
                return {
                    isNewStart: true,
                    newStart: nowLocalizedTimezone.add((30 - nowMinute) % 30, 'minutes'),
                };
            }
            else {
                return {
                    isNewStart: true,
                    newStart: now,
                };
            }
        }
        return {
            isNewStart: false,
            newStart: starts,
        };
    },
    /**
     * Outputs SpotHero standard formatted time duration string from milliseconds
     *
     * @param {number} duration - duration to prettify
     * @returns {string} SpotHero standard formatted time duration string (i.e. "3 days, 12 hours, 30 minutes")
     */
    prettyDuration(duration) {
        return dayjs
            .duration(duration)
            .format('D [days], H [hours], m [minutes]')
            .replace(/\b0\b days, /, '')
            .replace(', 0 hours', '')
            .replace('0 hours, ', '')
            .replace(', 0 minutes', '')
            .replace('0 minutes', '')
            .replace('undefined days', '')
            .replace(', undefined hours', '')
            .replace(', undefined minutes', '')
            .replace('$1 days', '1 day')
            .replace('$1 hours', '1 hour')
            .replace('$1 minutes', '1 minute');
    },
    /**
     * Returns whether or not time has been extended, as well as the number of hours extended.
     *
     * @static
     * @function getTimeExtension
     * @param {object} data - The data to pass to the function.
     * @param {object} data.requestedStartDateTimeObj - The requested start date as a moment or dayjs instance.
     * @param {object} data.requestedEndDateTimeObj - The requested end date as a moment or dayjs instance.
     * @param {object} data.actualStartDateTimeObj - The actual start date as a moment or dayjs instance.
     * @param {object} data.actualEndDateTimeObj - The actual start date as a moment or dayjs instance.
     * @param {boolean} data.isMonthly - Is startDateTimeObj a monthly datetime?
     * @example
     * TimeUtils.getTimeExtension({
     *     requestedStartDateTimeObj: moment(),
     *     requestedEndDateTimeObj: moment().add(7, 'hours').add(23, 'minutes'),
     *     actualStartDateTimeObj: moment(),
     *     actualEndDateTimeObj: moment().add(9, 'hours').add(23, 'minutes'),
     *     isMonthly: false,
     * });
     * @returns {object} - This contains isExtended (whether or not time has been extended), and hoursExtended (the number of hours extended)
     */
    getTimeExtension({ requestedStartDateTimeObj, requestedEndDateTimeObj, actualStartDateTimeObj, actualEndDateTimeObj, isMonthly, }) {
        if (isMonthly) {
            return {
                isExtended: false,
                formattedDuration: null,
            };
        }
        else {
            const isExtended = requestedStartDateTimeObj.isAfter(actualStartDateTimeObj) ||
                actualEndDateTimeObj.isAfter(requestedEndDateTimeObj);
            const startsDuration = dayjs.duration(requestedStartDateTimeObj.diff(actualStartDateTimeObj));
            const endsDuration = dayjs.duration(actualEndDateTimeObj.diff(requestedEndDateTimeObj));
            const formattedDuration = this.prettyDuration(startsDuration.add(endsDuration));
            return {
                isExtended,
                formattedDuration,
            };
        }
    },
    calculateDefaultStarts(now) {
        if (!now) {
            return null;
        }
        return now.get('minute') < 30
            ? now.set('minute', 0).set('second', 0)
            : now.set('minute', 30).set('second', 0);
    },
    calculateDefaultEnds(start) {
        if (!start) {
            return null;
        }
        return start.add(3, 'hours');
    },
    calculateDefaultAirportStarts(now) {
        if (!now) {
            return null;
        }
        return now.add(1, 'days').hour(12).minute(0);
    },
    calculateDefaultAirportEnds(start) {
        if (!start) {
            return null;
        }
        return start.add(4, 'days');
    },
    FULL_DATETIME_STRING: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    V1_API_TIMESTAMP_FORMAT: 'YYYY-MM-DDTHH:mm',
    V2_API_TIMESTAMP_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
};
export default {
    ...FETimeUtils,
    ...TimeUtils,
};
