import React from 'react';
import classNames from 'classnames';
import Image from 'common/image';
import withConfig from '@/config/withConfig';
import StarRatingWithCategory from 'common/star-rating-with-category';
import WalkingDistanceDisplay from 'common/spot-details/walking-distance';
import { Box, Flex, Heading, Text, Button } from '@spothero/ui';
import VisualFlag from '../visual-flag';
import { Price } from '../price/Price';
import { multidayWODiscountGenerator } from 'utils/multiday-utils';
import { getSpotheroOversizeFee } from 'utils/search-vehicle';
import { usePrice } from 'hooks/spot/price/use-price';
import { useLabel } from 'hooks/spot/price/use-label';
import { useDispatch } from 'react-redux';
import { setPriceBreakdownModalSpotId } from 'store/search/search-actions';
import { useShowTotal } from 'store/selectors/hooks/useShowTotal';
const FacilitySummary = ({ isAdmin, showAdminLink, spot, priceLabel: propLabel, showFullTitle = false, showPrice = true, showImages = true, showFlair = true, appleMapsPartner, CTAs, isShowSpotsLeftVisible, imageWidth = 192, imageHeight = 192, staticBaseUrl, isPowerBooking, view, }) => {
    const dispatch = useDispatch();
    const { spotId, title, distance, rating: { average: averageRating, count: numberOfReviews }, selectedRate, availability, visualFlags = [], images, hasMultipleRates, } = spot;
    const { available, unavailableReasons } = availability;
    const priceObject = usePrice(spot);
    const priceLabel = useLabel(propLabel);
    // Below two lines ensure oversized spots have total with a star.  Can't be in useLabel due to lack of spot awareness
    const { isOversize } = getSpotheroOversizeFee(spot);
    const labelOversizeIncluded = isOversize ? 'Total*' : priceLabel;
    const showTotal = useShowTotal();
    const oversizeFee = getSpotheroOversizeFee(spot);
    const classes = classNames('FacilitySummary');
    const titleClasses = classNames('FacilitySummary-title');
    const imgProps = {
        width: imageWidth,
        height: imageHeight,
        alt: `Photo of ${title}`,
        lazyLoad: true,
        ...(images?.[0]
            ? { cloudinaryTemplate: images[0].urlTemplate }
            : { src: `${staticBaseUrl}img/missing-image.png` }),
    };
    const powerBookingClasses = classNames({
        'apply-fixed-width': isPowerBooking,
        Oversize: oversizeFee.isOversize,
    });
    const priceWithoutDiscount = multidayWODiscountGenerator(selectedRate?.priceBreakdown, showTotal);
    const handleOnShowPriceBreakdown = () => {
        dispatch(setPriceBreakdownModalSpotId(spotId));
    };
    return (<Flex flexFlow="column" height="100%" data-testid={`FacilitySummary-${encodeURIComponent(title)}`}>
            {showFlair &&
            visualFlags.map((flag, i) => (<VisualFlag key={`visualfalg:${spotId}:${i}`} {...flag} view={view}/>))}
            <Flex justifyContent="space-between" position="relative" padding="16px" height="100%" className={classes} data-testid="FacilitySummary-Images">
                {showImages && (<Box height={{ base: '80px', tablet: '96px' }} width={{ base: '80px', tablet: '96px' }} marginRight="16px" cursor="pointer" flexShrink="0" sx={{
                img: {
                    borderRadius: '8px',
                },
            }} data-testid="images-wrapper">
                        <Image {...imgProps}/>
                    </Box>)}
                <Flex flexGrow="1" minWidth="0" minHeight={isShowSpotsLeftVisible && { base: 32, tablet: 28 }} flexFlow="column">
                    <Flex>
                        <Box flexGrow="1" minWidth="0" color="gray.900">
                            <Heading as="h2" marginBottom="0">
                                <Text as="span" className={titleClasses} fontWeight="semibold" minHeight="auto" lineHeight="1.2" display="block" fontSize="sm" overflow={showFullTitle ? 'visible' : 'hidden'} textOverflow={showFullTitle ? 'initial' : 'ellipsis'} whiteSpace={showFullTitle ? 'normal' : ''} padding={showPrice ? '0' : ''} data-testid="FacilitySummary-title">
                                    {title}
                                </Text>
                            </Heading>
                            {isAdmin && showAdminLink && (<Box fontSize="sm" display={{
                base: 'none',
                tablet: 'inline-block',
            }} marginLeft="8px">
                                    <Button variant="tertiary" fontSize="sm" as="a" href={`/admin/spots/${spotId}`}>
                                        Facility Admin
                                    </Button>
                                </Box>)}
                            <StarRatingWithCategory numberOfReviews={numberOfReviews} starRating={averageRating}/>
                            {distance && !appleMapsPartner && (<WalkingDistanceDisplay linearMeters={distance.linearMeters} walkingMeters={distance.walkingMeters} walkingDuration={distance.durationSeconds}/>)}
                            {isAdmin && !available && (<Text as="span" fontSize="sm" display="block">
                                    <sup>*</sup> {unavailableReasons.join('; ')}
                                </Text>)}
                        </Box>
                        {showPrice &&
            available &&
            (oversizeFee.isOversize ? (<Box minWidth={{ base: '88px', tablet: '120px' }}>
                                    <Price oversizeFee={oversizeFee} priceWithoutDiscount={priceWithoutDiscount} price={priceObject} powerBookingClasses={powerBookingClasses} priceLabel={labelOversizeIncluded} hasMultipleRates={hasMultipleRates} onShowPriceBreakdown={handleOnShowPriceBreakdown}/>
                                </Box>) : (<Price priceWithoutDiscount={priceWithoutDiscount} price={priceObject} powerBookingClasses={powerBookingClasses} priceLabel={priceLabel} hasMultipleRates={hasMultipleRates} onShowPriceBreakdown={handleOnShowPriceBreakdown}/>))}
                    </Flex>
                    <Box flexGrow="1" minHeight={2}/>
                    <Box className="controls" data-testid="FacilitySummary-CTA">
                        {CTAs}
                    </Box>
                </Flex>
            </Flex>
        </Flex>);
};
export default withConfig(['staticBaseUrl'])(FacilitySummary);
