export const INELIGIBLE_PERMISSIONS = {
    eligible: false,
    marketingOptIn: false,
    transactionOptIn: false,
    id: null,
    phoneNumber: '',
    ineligibilityReason: null,
};
export const INELIGIBILITY_REASONS = {
    LANDLINE: 'This number is not eligible for texts because it is not a mobile phone number.',
    NON_US: 'This number is not eligible for texts from SpotHero because it is registered outside of the United States. We are working hard to expand our SMS program and hope to send texts to Canadian numbers soon!',
    GENERIC: "This number isn't eligible for notifications. Double-check the number you entered and try again.",
};
export const INELIGIBILITY_ERROR_CODES = {
    NON_US: 'sms-permission-not-support-canadian-phone-number',
    LANDLINE: 'sms-permission-phone-number-ineligible',
};
