import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalContent} from '@spothero/ui-backlog';
import RateDetailsModalContent from './RateDetailsModalContent';

const RateDetailsModal = ({
    spot: {selectedRate, facility},
    section,
    onHidden,
}) => {
    return (
        <Modal
            className="RateDetailsModal"
            title="Rate Details"
            onHidden={onHidden}
        >
            <ModalContent className="RateDetails">
                <RateDetailsModalContent
                    rate={selectedRate}
                    facility={facility}
                    section={section}
                />
            </ModalContent>
        </Modal>
    );
};

RateDetailsModal.propTypes = {
    spot: PropTypes.object.isRequired,
    section: PropTypes.string,
    onHidden: PropTypes.func.isRequired,
};

export default RateDetailsModal;
