import PropTypes from 'prop-types';
import { OBJECT_COMMON_FACILITY_SUMMARY, OBJECT_COMMON_FACILITY, OBJECT_COMMON_RATE, PROPTYPE_REDEMPTION_INSTRUCTION, PROPTYPE_PRICE, PROPTYPE_CLOUDINARY_IMAGE, PROPTYPE_HOURS_OF_OPERATION, } from './common';
export const AIRPORT_TRANSPORTATION_TYPES = Object.freeze({
    SCHEDULED_SHUTTLE: 'scheduled-shuttle',
    TAXI: 'taxi',
    CURBSIDE_VALET: 'curbside-valet',
    ON_DEMAND_SHUTTLE: 'on-demand-shuttle',
    PUBLIC_TRANSIT: 'public-transit',
});
export const AIRPORT_TRANSPORTATION_TYPES_V2 = Object.freeze({
    SCHEDULED_SHUTTLE: 'scheduled_shuttle',
    TAXI: 'taxi',
    CURBSIDE_VALET: 'curbside_valet',
    ON_DEMAND_SHUTTLE: 'on_demand_shuttle',
    PUBLIC_TRANSIT: 'public_transit',
});
export const PROPTYPE_AIRPORT_TRANSPORTATION_TYPE = PropTypes.oneOf(Object.values(AIRPORT_TRANSPORTATION_TYPES));
export const PROPTYPE_AIRPORT_TRANSPORTATION = PropTypes.shape({
    type: PROPTYPE_AIRPORT_TRANSPORTATION_TYPE,
    contactPhoneNumber: PropTypes.string,
    schedule: PropTypes.shape({
        fastFrequency: PropTypes.number,
        slowFrequency: PropTypes.number,
        duration: PropTypes.number,
    }),
    images: PropTypes.arrayOf(PROPTYPE_CLOUDINARY_IMAGE),
    hoursOfOperation: PROPTYPE_HOURS_OF_OPERATION,
});
const EXTRA_AIRPORT_PROPS = {
    logo: PROPTYPE_CLOUDINARY_IMAGE,
    lowestDailyRate: PROPTYPE_PRICE,
    tag: PropTypes.string,
    comparisons: PropTypes.shape({
        airportPercentLowerPrice: PropTypes.number,
    }),
    cancellationAllowed: PropTypes.bool.isRequired,
    transportation: PROPTYPE_AIRPORT_TRANSPORTATION,
    isCoveredParking: PropTypes.bool.isRequired,
    isSelfParking: PropTypes.bool.isRequired,
    isValetParking: PropTypes.bool.isRequired,
    // spots may come back without rates, so URLs can be constructed from and set on the facility data
    fullUrl: PropTypes.string.isRequired,
    spotDetailsUrl: PropTypes.string.isRequired,
    airportCode: PropTypes.string,
};
export const OBJECT_AIRPORT_RATE = {
    ...OBJECT_COMMON_RATE,
    spotDetailsUrl: PropTypes.string.isRequired,
};
export const PROPTYPE_AIRPORT_RATE = PropTypes.shape(OBJECT_AIRPORT_RATE);
export const OBJECT_AIRPORT_FACILITY_SUMMARY = {
    ...OBJECT_COMMON_FACILITY_SUMMARY,
    ...EXTRA_AIRPORT_PROPS,
    selectedRate: PROPTYPE_AIRPORT_RATE,
    hoursOfOperation: PROPTYPE_HOURS_OF_OPERATION,
};
export const PROPTYPE_AIRPORT_FACILITY_SUMMARY = PropTypes.shape(OBJECT_AIRPORT_FACILITY_SUMMARY);
export const OBJECT_AIRPORT_FACILITY = {
    ...OBJECT_COMMON_FACILITY,
    ...EXTRA_AIRPORT_PROPS,
    redemptionInstructions: PropTypes.shape({
        arrival: PropTypes.arrayOf(PROPTYPE_REDEMPTION_INSTRUCTION),
        departure: PropTypes.arrayOf(PROPTYPE_REDEMPTION_INSTRUCTION),
    }),
    rates: PropTypes.arrayOf(PropTypes.shape(OBJECT_AIRPORT_RATE)),
    selectedRate: PropTypes.shape(OBJECT_AIRPORT_RATE),
};
export const PROPTYPE_AIRPORT_FACILITY = PropTypes.shape(OBJECT_AIRPORT_FACILITY);
