import React from 'react';
import { Box } from '@spothero/ui';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import SpotListItemTransient from 'pages/search-transient-event-monthly/components/spot-card-transient';
import SpotListItemMonthly from 'pages/search-transient-event-monthly/components/spot-card-monthly';
import SpotListComponent from 'common/spot-list';
import { useOnShowDetailsClick } from 'pages/search/hooks/use-on-show-details-click';
import { SearchPageView } from 'utils/search-page-view';
const spotListMobileListViewStyles = {
    overflowY: 'auto',
    height: 'calc(100vh - 90px - 52px)', // 90px is search component height, 52px is header height
};
export const SpotList = ({ loadMoreSpots }) => {
    const { spotsV2: spots, selectedSpotId, isPending, parkingType, view, } = useReduxSearchState();
    const onShowDetailsClick = useOnShowDetailsClick();
    // TODO: Handle event spots
    const SpotListItem = parkingType === 'monthly' ? SpotListItemMonthly : SpotListItemTransient;
    const isSpotDetailsVisibleOnMobileMap = view === SearchPageView.MOBILE_MAP;
    return (<Box backgroundColor="gray.50" sx={{
            '& > .SpotList': {
                // This is to ensure the spot list is NOT above the modal
                zIndex: isSpotDetailsVisibleOnMobileMap
                    ? 'layer3'
                    : 'unset',
                ...(view === SearchPageView.MOBILE_LIST
                    ? spotListMobileListViewStyles
                    : {}),
            },
        }}>
            <SpotListComponent spots={spots} selectedSpotId={selectedSpotId} onShowDetailsClick={onShowDetailsClick} showListLoader={isPending} showLoadingComplete={isPending} SpotListItem={SpotListItem} loadNextPage={loadMoreSpots} showSpotListSorter showMobileToggle={false}/>
        </Box>);
};
