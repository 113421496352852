import FEDateUtils from '@spothero/utils/date';
import dayjs from './dayjs';
export const HUMAN_DATE_FORMAT = 'MMM D';
export const HUMAN_DATE_MONTHLY_FORMAT = 'MMM D, YYYY';
export const HUMAN_TIME_FORMAT = 'h:mma';
export const HUMAN_TIME_FORMAT_REDESIGN = 'h:mm A';
/**
 * Utilities for working with dates.
 *
 * @module DateUtils
 */
const DateUtils = {
    /**
     * Returns human readable start and end datetime strings from moment objects.
     *
     * @deprecated unused function, worth deleting
     * @static
     * @function getHumanDateTimesAsStrings
     * @param {object} data - The data to pass to the function.
     * @param {object} data.startDateTimeObj - The date as a moment instance.
     * @param {object} data.endDateTimeObj - The time as a moment instance.
     * @param {boolean} data.isMonthly - Is startDateTimeObj a monthly datetime?
     * @example
     * DateUtils.getHumanDateTimesAsStrings({
     *     startDateTimeObj: moment(),
     *     endDateTimeObj: moment().add(7, 'hours').add(23, 'minutes')
     * });
     * @returns {object} - Human readable strings returned for startDate, startTime, endDate and endTime
     */
    getHumanDateTimesAsStrings({ startDateTimeObj, endDateTimeObj, isMonthly, }) {
        if (!dayjs.isDayjs(startDateTimeObj) ||
            (!isMonthly && !dayjs.isDayjs(endDateTimeObj))) {
            throw new Error('The datetimes supplied are not an instance of Dayjs.');
        }
        let startDateString = null;
        let startTimeString = null;
        let endDateString = null;
        let endTimeString = null;
        if (startDateTimeObj) {
            if (dayjs()
                .startOf('day')
                .diff(startDateTimeObj.clone().startOf('day')) === 0) {
                startDateString = 'Today';
                startTimeString = !isMonthly
                    ? startDateTimeObj.format(HUMAN_TIME_FORMAT)
                    : '';
            }
            else {
                if (isMonthly) {
                    startDateString = startDateTimeObj.format(HUMAN_DATE_MONTHLY_FORMAT);
                }
                else {
                    startDateString = startDateTimeObj.format(HUMAN_DATE_FORMAT);
                    startTimeString = startDateTimeObj.format(HUMAN_TIME_FORMAT);
                }
            }
        }
        if (!isMonthly && endDateTimeObj) {
            if (startDateTimeObj
                .clone()
                .startOf('day')
                .diff(endDateTimeObj.clone().startOf('day')) === 0) {
                if (dayjs()
                    .startOf('day')
                    .diff(endDateTimeObj.clone().startOf('day')) === 0) {
                    endDateString = 'Today';
                    endTimeString = endDateTimeObj.format(HUMAN_TIME_FORMAT);
                }
                else {
                    endDateString = endDateTimeObj.format(HUMAN_DATE_FORMAT);
                    endTimeString = endDateTimeObj.format(HUMAN_TIME_FORMAT);
                }
            }
            else {
                if (dayjs()
                    .clone()
                    .startOf('day')
                    .diff(endDateTimeObj.clone().startOf('day'), 'days') ===
                    -1) {
                    endDateString = 'Tmrw';
                    endTimeString = endDateTimeObj.format(HUMAN_TIME_FORMAT);
                }
                else {
                    endDateString = endDateTimeObj.format(HUMAN_DATE_FORMAT);
                    endTimeString = endDateTimeObj.format(HUMAN_TIME_FORMAT);
                }
            }
        }
        return {
            startDate: startDateString,
            startTime: startTimeString,
            endDate: endDateString,
            endTime: endTimeString,
        };
    },
    /**
     * Returns summarized, formatted date and time string from moment or dayjs objects.
     * NOTE: This function translates 1 to 1 to dayjs and was not refactored during our migration
     *
     * @static
     * @function getDateTimeSummary
     * @param {object} data - The data to pass to the function.
     * @param {object} data.startDateTimeObj - The start date as a moment or dayjs instance.
     * @param {object} data.endDateTimeObj - The end date as a moment or dayjs instance.
     * @param {boolean} data.isMonthly - Is startDateTimeObj a monthly datetime?
     * @example
     * DateUtils.getDateTimeSummary({
     *     startDateTimeObj: moment(),
     *     endDateTimeObj: moment().add(7, 'hours').add(23, 'minutes'),
     *     isMonthly: false
     * });
     * @returns {string} - Single string representing this date time summary
     */
    getDateTimeSummary({ startDateTimeObj, endDateTimeObj, isMonthly, }) {
        const reservationStartsToday = startDateTimeObj.isSame(Date.now(), 'day');
        const reservationStartsEndsSameDay = endDateTimeObj.isSame(startDateTimeObj, 'day');
        if (isMonthly) {
            return startDateTimeObj.format(HUMAN_DATE_MONTHLY_FORMAT);
        }
        else {
            const startDate = startDateTimeObj.format(HUMAN_DATE_FORMAT);
            const startTime = startDateTimeObj.format(HUMAN_TIME_FORMAT_REDESIGN);
            const endDate = endDateTimeObj.format(HUMAN_DATE_FORMAT);
            const endTime = endDateTimeObj.format(HUMAN_TIME_FORMAT_REDESIGN);
            const startString = reservationStartsToday
                ? `Today ${startTime}`
                : `${startDate}, ${startTime}`;
            const endString = reservationStartsEndsSameDay
                ? `${endTime}`
                : `${endDate}, ${endTime}`;
            return `${startString} - ${endString}`;
        }
    },
};
export default {
    ...FEDateUtils,
    ...DateUtils,
};
