import { default as React, useMemo, useRef } from 'react';
import { OptimizelyProvider, createInstance, setLogLevel, enums, OptimizelyDecideOption, } from '@optimizely/react-sdk';
import { customErrorHandler } from '../utils/error-handler';
import EnvironmentUtils from '@spothero/utils/environment';
import useIsMobile from 'hooks/use-is-mobile';
import { useSelector } from 'react-redux';
import { getParkingType } from 'store/selectors/selectors';
export default function SHOptimizelyProvider({ children, optimizelyDataFile, environment, optimizelyUserId, optimizelyAttributes, config, onDecision: onDecisionCallback, }) {
    const isMobileDevice = useIsMobile();
    const optimizelyClient = useRef(null);
    const isBrowser = typeof window !== 'undefined';
    const userData = useSelector(({ user }) => user.data);
    const rootState = useSelector((state) => state);
    const parkingType = getParkingType(rootState);
    const user = useMemo(() => {
        if (!isBrowser) {
            return {
                id: optimizelyUserId,
                attributes: optimizelyAttributes || {},
            };
        }
        const attributes = {
            parkingType,
            client: isMobileDevice ? 'mobile-web' : 'web',
            environment: environment === 'production' ? 'production' : 'staging',
            email: userData?.email || null,
            staff: Boolean(userData?.isAdmin).toString(),
            shUserId: userData?.id || null,
            isBrowser,
            pageUrl: window?.location?.href,
            pagePath: window?.location?.pathname,
            pageReferrer: window?.document?.referrer || null,
            userAgent: window?.navigator?.userAgent,
            browser: EnvironmentUtils.getBrowserCategory(),
            ...(config?.clientIPAddress && {
                ip: config?.clientIPAddress,
            }),
            monthly: parkingType === 'monthly',
        };
        return {
            id: optimizelyUserId,
            attributes,
        };
    }, [
        config?.clientIPAddress,
        environment,
        isBrowser,
        isMobileDevice,
        optimizelyAttributes,
        optimizelyUserId,
        userData?.isAdmin,
        userData?.email,
        userData?.id,
        parkingType,
    ]);
    if (!optimizelyClient.current) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const onDecision = ({ type, userId, attributes, decisionInfo }) => {
            const { decisionEventDispatched, flagKey, variationKey, } = decisionInfo;
            if (environment !== 'production') {
                console.info(`Optimizely Decision: User [${userId}] Feature [${flagKey}] Variation: [${variationKey}]`);
            }
            if (isBrowser &&
                type === 'flag' &&
                decisionEventDispatched &&
                ['on', 'off'].includes(variationKey) === false // Ignore on/off variations
            ) {
                onDecisionCallback({
                    variationKey,
                    flagKey,
                });
            }
        };
        // To prevent double impressions, we disable the decision event on the server side.
        const defaultDecideOptions = isBrowser
            ? []
            : [OptimizelyDecideOption.DISABLE_DECISION_EVENT];
        optimizelyClient.current = createInstance({
            datafile: optimizelyDataFile,
            errorHandler: customErrorHandler,
            defaultDecideOptions,
        });
        setLogLevel(environment === 'production'
            ? enums.LOG_LEVEL.ERROR
            : enums.LOG_LEVEL.DEBUG);
        optimizelyClient.current.notificationCenter.addNotificationListener(enums.NOTIFICATION_TYPES.DECISION, onDecision);
    }
    return (<OptimizelyProvider optimizely={optimizelyClient.current} user={user} isServerSide={!isBrowser}>
            {children}
        </OptimizelyProvider>);
}
