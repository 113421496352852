import createPopupInstance from './Popup';
import { convertRatingToSlug } from 'store/filters/rating-filter-utils';
export const DEFAULT_ZOOM_LEVEL = 14;
export const HIGHEST_ALLOWED_ZOOM_LEVEL = 19; // This is the most zoomed in
export const LOWEST_ALLOWED_ZOOM_LEVEL = 4; // This is the most zoomed out
export const INFO_WINDOW_DOM_ID = 'sh-google-map-infowindow';
const mapOptions = {
    maxZoom: HIGHEST_ALLOWED_ZOOM_LEVEL,
    minZoom: LOWEST_ALLOWED_ZOOM_LEVEL,
    clickableIcons: false,
    disableDefaultUI: true,
    /* Google Maps documentation is somewhat lacking in explanation of default gestureHandling behavior.
      The blog linked below describes the gestureHandling "auto" value in a little more detail:
      it can change gestureHandling to cooperative or greedy depending on whether the map
      is in an iframe of the page is considered scrollable.
      https://developers.google.com/maps/documentation/javascript/interaction#gesturehandling_greedy
      https://cloud.google.com/blog/products/maps-platform/smart-scrolling-comes-to-mobile-web-maps
  */
    gestureHandling: 'greedy',
};
const mapTypeToCssClass = {
    disabled: 'disabled',
    medium: 'default',
    recent: 'viewed',
};
export const getMapsLibrary = () => typeof window !== 'undefined' && window.google && window.google.maps
    ? window.google.maps
    : null;
/**
 * Initializes a Google Map instance with the specified options and callbacks.
 *
 * @param {object} options - The options for the map.
 * @param {number} options.zoom - The initial zoom level of the map.
 * @param {object} options.center - The initial center coordinates of the map.
 * @param {object} options.maps - The Google Maps API object.
 * @param {Function} options.idleCallback - The callback to be called when the map becomes idle.
 * @param {Function} options.tilesLoadedCallback - The callback to be called when the map tiles are loaded.
 * @param {Function} options.onDragStart - The callback to be called when the user starts dragging the map.
 * @param {Function} options.onDragEnd - The callback to be called when the user stops dragging the map.
 * @param {Function} options.onZoomChanged - The callback to be called when the user zooms in or out of the map.
 * @param {HTMLElement} options.container - The HTML element that will contain the map.
 * @returns {object} The Google Map instance.
 */
export const initializeMap = ({ googleMapsId, zoom, center, maps, idleCallback, tilesLoadedCallback, onDragStart, onDragEnd, onZoomChanged, container, onMapClick, }) => {
    const map = new maps.Map(container, {
        ...mapOptions,
        zoom,
        center,
        mapTypeId: maps.MapTypeId.ROADMAP,
        mapId: googleMapsId,
    });
    maps.event.addListenerOnce(map, 'idle', idleCallback);
    maps.event.addListenerOnce(map, 'tilesloaded', () => {
        maps.event.addListener(map, 'dragstart', onDragStart);
        maps.event.addListener(map, 'dragend', onDragEnd);
        maps.event.addListener(map, 'zoom_changed', onZoomChanged);
        tilesLoadedCallback();
    });
    maps.event.addListener(map, 'click', onMapClick);
    return map;
};
/**
 * Cleans up a Google Map instance by removing all event listeners and removing the onSpotHover listener from the document.
 *
 * @param {object} options - The options for the map cleanup.
 * @param {object} options.map - The Google Map instance to be cleaned up.
 * @param {object} options.maps - The Google Maps API object.
 */
export const cleanupMap = ({ map, maps, }) => {
    maps.event.clearInstanceListeners(map);
};
/**
 * Adds a marker to a Google Map instance with the specified options and callbacks.
 *
 * @param {object} options - The options for the marker.
 * @param {object} options.spot - The spot to add the marker for.
 * @param {object} options.maps - The Google Maps API object.
 * @param {object} options.map - The Google Map instance to add the marker to.
 * @param {Function} options.onClick - The callback to be called when the marker is clicked.
 * @returns {object} The Google Maps Marker instance.
 */
export const addMarker = ({ spot, maps, map, onClick: onClickCallback, onMouseEnter, onMouseLeave, onShowPopup, isMobile, isHighlighted, ratingFilters, }) => {
    const { spotId, latitude, longitude, title, markerLabelText, markerType, isFlagged, } = spot;
    const markerDiv = document.createElement('div');
    markerDiv.setAttribute('data-testid', `${spotId}-${encodeURIComponent(title)}`);
    markerDiv.setAttribute('data-price', markerLabelText);
    markerDiv.classList.add('SHMapPin');
    markerDiv.classList.add(mapTypeToCssClass[markerType]);
    markerDiv.textContent = markerLabelText;
    const position = new maps.LatLng(latitude, longitude);
    const marker = new maps.marker.AdvancedMarkerElement({
        position,
        map,
        content: markerDiv,
        title,
    });
    // update marker styles
    updateMarker({
        marker,
        spot,
        isMobile,
        map,
        ratingFilters,
    });
    marker.addListener('click', () => {
        if (isMobile) {
            onClickCallback(spot);
        }
        else {
            const popup = initializePopup({
                maps,
                map,
                position,
            });
            onShowPopup(popup);
            onClickCallback(spot);
        }
    });
    marker.content.addEventListener('mouseenter', () => {
        // Only call onMouseEnter if the marker is not already highlighted.
        if (!isHighlighted) {
            onMouseEnter(spotId);
        }
    });
    marker.content.addEventListener('mouseleave', () => {
        onMouseLeave();
    });
    return marker;
};
export const updateMarker = ({ marker, spot, isMobile, map, ratingFilters, }) => {
    const { markerType, isHighlighted, isPreviewed } = spot;
    const markerDiv = marker.content;
    const ratingSlug = convertRatingToSlug(spot?.rating);
    if (markerDiv) {
        marker.zIndex = isHighlighted || isPreviewed ? 1 : null;
        markerDiv.textContent = spot.markerLabelText;
        markerDiv.classList.toggle('previewed', isPreviewed);
        markerDiv.classList.toggle('highlighted', isHighlighted);
        markerDiv.classList.add(mapTypeToCssClass[markerType]);
        // Adds color to map marker based on rating
        markerDiv.classList.toggle(ratingSlug, ratingFilters.includes(ratingSlug));
    }
    if (!isMobile) {
        marker?.setMap(isPreviewed ? null : map);
    }
};
/**
 * Removes a marker from a Google Map instance.
 *
 * @param {object} options - The options for the marker removal.
 * @param {object} options.marker - The Google Maps Marker instance to remove.
 * @param {object} options.maps - The Google Maps API object.
 */
export const removeMarker = ({ marker, maps }) => {
    if (marker) {
        marker.setMap(null);
        maps?.event.clearInstanceListeners(marker);
    }
};
/**
 * Gets the bounds of a circle with the specified distance and center coordinates.
 *
 * @param {object} options - The options for the circle bounds.
 * @param {number} options.distance - The distance of the circle.
 * @param {object} options.center - The center coordinates of the circle.
 * @param {object} options.maps - The Google Maps API object.
 * @returns {object} The bounds of the circle.
 */
export const getBounds = ({ distance, center, maps }) => {
    const circle = new maps.Circle({
        radius: distance,
        center,
    });
    return circle.getBounds();
};
/**
 * Creates a new marker and LatLng object with the specified latitude, longitude, title, and Google Maps API objects.
 *
 * @param {object} options - The options for the new marker and LatLng object.
 * @param {number} options.lat - The latitude of the new marker and LatLng object.
 * @param {number} options.lng - The longitude of the new marker and LatLng object.
 * @param {string} options.title - The title of the new marker.
 * @param {object} options.maps - The Google Maps API object.
 * @param {object} options.map - The Google Map instance to add the marker to.
 * @returns {object} An object containing the new marker and LatLng object.
 */
export const newMarkerAndLatLng = ({ lat, lng, title, maps, map }) => {
    const position = { lat, lng };
    return {
        position,
        marker: new maps.marker.AdvancedMarkerElement({
            position,
            map,
            title,
        }),
    };
};
/**
 * Fits the Google Map instance to the specified distance, center, and bounds.
 *
 * @param {object} options - The options for fitting the map to the markers.
 * @param {number} options.distance - The distance to fit the map to.
 * @param {object} options.center - The center coordinates of the map.
 * @param {object} options.map - The Google Map instance to fit to the markers.
 * @param {object} options.maps - The Google Maps API object.
 * @param {Function} options.idleCallback - The callback to be called when the map becomes idle.
 */
export const fitMapToMarkers = ({ distance, center, map, maps, idleCallback, }) => {
    const bounds = getBounds({
        distance,
        maps,
        center,
    });
    // fitBounds isn't always a "perfect" fit to given bounds.
    // it will find the nearest zoom level that shows the bounds within the viewport.
    map.fitBounds(bounds);
    maps.event.addListenerOnce(map, 'idle', idleCallback);
};
export const getMapOptions = ({ map, center, }) => {
    const zoom = map.getZoom();
    const mapCenter = map.getCenter();
    const mapBounds = map.getBounds();
    const ne = mapBounds.getNorthEast();
    const sw = mapBounds.getSouthWest();
    const isDestinationOutOfBounds = !mapBounds.contains(center);
    return {
        zoom,
        mapCenter: {
            latitude: mapCenter.lat(),
            longitude: mapCenter.lng(),
        },
        mapBounds: {
            northEast: {
                latitude: ne.lat(),
                longitude: ne.lng(),
            },
            southWest: {
                latitude: sw.lat(),
                longitude: sw.lng(),
            },
        },
        isDestinationOutOfBounds,
    };
};
/**
 * Returns the Google Map instance to the specified destination coordinates.
 *
 * @param {object} options - The options for returning the map to the destination.
 * @param {object} options.map - The Google Map instance to return to the destination.
 * @param {object} options.maps - The Google Maps API object.
 * @param {object} options.center - The center coordinates of the destination.
 * @param {Function} options.onPan - The callback to be called when the map is panned to the destination.
 */
export const returnMapToDestination = ({ map, maps, center, onPan, }) => {
    map.panTo(center);
    // there is no way to know when a panTo finishes except that the map goes into idle mode
    maps.event.addListenerOnce(map, 'idle', onPan);
};
/**
 * Sets the zoom level of the Google Map instance.
 *
 * @param {object} options - The options for setting the zoom level.
 * @param {object} options.map - The Google Map instance to set the zoom level for.
 * @param {number} options.zoom - The zoom level to set.
 */
export const setZoom = ({ map, zoom }) => {
    map.setZoom(zoom);
};
/**
 * Adds a KML layer to the Google Map instance with the specified URL.
 *
 * @param {object} options - The options for adding the KML layer.
 * @param {string} options.kmlUrl - The URL of the KML layer to add.
 * @param {object} options.map - The Google Map instance to add the KML layer to.
 * @param {object} options.maps - The Google Maps API object.
 * @returns {object} The Google Maps KmlLayer instance.
 */
export const addKMLLayer = ({ kmlUrl, map, maps, }) => {
    return new maps.KmlLayer({
        url: kmlUrl,
        map,
        preserveViewport: true,
    });
};
/**
 * Hides a marker from the Google Map instance.
 * @param marker - The Google Maps Marker to hide.
 */
export const hideMarker = ({ marker, }) => {
    marker?.setMap(null);
};
/**
 * Shows a marker on the Google Map instance.
 * @param marker - The Google Maps Marker to show.
 * @param map - The Google Map instance to show the marker on.
 */
export const showMarker = ({ marker, map, }) => {
    marker?.setMap(map);
};
export const panTo = ({ map, position, }) => {
    map.panTo(position);
};
export const initializePopup = ({ maps, map, position, }) => {
    // Create a div element with ID that will be used as a container for the popup.
    // GoogleMapInfoWindow will render its content inside this div.
    const div = document.createElement('div');
    div.setAttribute('id', INFO_WINDOW_DOM_ID);
    const popup = createPopupInstance({
        maps,
        map,
        position,
        content: div,
    });
    popup.setMap(map);
    return popup;
};
