import { useSelector } from 'react-redux';
import { useShowTotal } from 'store/selectors/hooks/useShowTotal';
/**
 * getPrice is how it returns the right key based on which type of search you are doing
 */
export const getPrice = ({ spot, isPowerBooking, showTotal, }) => {
    const selectedRate = spot?.selectedRate || {};
    if (isPowerBooking)
        return showTotal ? selectedRate?.PBTotalPrice : selectedRate?.subTotal;
    if (showTotal)
        return selectedRate?.totalPrice;
    if (selectedRate.newMonthlyRate) {
        return {
            value: parseInt(selectedRate.display_price, 10),
            currencyCode: selectedRate.currency_type,
        };
    }
    return selectedRate?.advertisedPrice;
};
/**
 * This hook is to find what the price object is for any given spot.
 * Idea is you pass the spot into the hook and it does the logic to find if its powerbooking/showTotal/advertised
 * Otherwise it would get the spot from state
 */
export const usePrice = (spot) => {
    // Multiple useSelectors based on this finding - https://stackoverflow.com/a/63277926
    const detailSpot = useSelector((state) => state.spot.data);
    const isPowerBooking = useSelector((state) => state.searchRequest.powerBooking);
    const showTotal = useShowTotal();
    const spotToCheck = spot ? spot : detailSpot;
    return (getPrice({ spot: spotToCheck, isPowerBooking, showTotal }) || {
        value: 0,
        currencyCode: 'USD',
    });
};
