import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { ErrorBoundary, NotificationContainer } from '@spothero/ui-backlog';
import { clearNotification } from 'store/notification/notification-actions';
import ApplicationMeta from 'seo/application-meta';
import GDPRModal from 'common/gdpr-modal';
import HeaderDesktop from 'common/header/desktop';
import HeaderMobile from 'common/header/mobile';
import HeaderCheckout from 'common/header/checkout';
import Footer from 'common/footer/default';
import FooterCheckout from 'common/footer/checkout';
import Navigator from './Navigator';
import '../sass/main.scss';
import { Box } from '@spothero/ui';
import { Page } from 'utils/page-utils';
import useConfig from 'hooks/use-config';
import { getDocument } from 'ssr-window';
import { SearchPageView } from 'utils/search-page-view';
import useIsMobile from 'hooks/use-is-mobile';
import { useShakeTheApp } from 'hooks/plugins/use-shake-the-app';
const headerTopClear = { top: 0 };
const App = ({ serverContext }) => {
    const document = getDocument();
    const { citySlugs, cloudfrontViewerCountryCode } = useConfig();
    const [showGDPRModal, setShowGDPRModal] = useState(false);
    const [userCountryCode, setUserCountryCode] = useState(null);
    const [showHeader, setShowHeader] = useState(true);
    const storeState = useSelector((state) => state);
    const isMobile = useIsMobile();
    useShakeTheApp();
    const { destination: { data: destination }, notification, searchRequest: { pageType, monthly: isMonthly, powerBooking: isPowerBooking, partner, }, search: { data: { view }, }, user: { data: user }, } = storeState;
    const { email } = user;
    const dispatch = useDispatch();
    const clearNotificationDispatched = () => dispatch(clearNotification);
    const pathname = document.location.pathname;
    useEffect(() => {
        const isForeignUser = cloudfrontViewerCountryCode &&
            cloudfrontViewerCountryCode !== 'US' &&
            cloudfrontViewerCountryCode !== 'CA';
        if (isForeignUser) {
            setShowGDPRModal(true);
            setUserCountryCode(cloudfrontViewerCountryCode);
        }
    }, [cloudfrontViewerCountryCode]);
    useEffect(() => {
        const isSearchPage = pathname === '/search';
        const appleMapsPartner = partner === 'apple' || partner === 'appleTest';
        setShowHeader(!isSearchPage
            ? true
            : isMobile
                ? view === SearchPageView.MOBILE_LIST || appleMapsPartner
                : true);
    }, [pathname, view, partner, isMobile]);
    const hideGDPRModal = () => {
        setShowGDPRModal(false);
    };
    const isCheckout = pageType === Page.CHECKOUT;
    const HeaderComponent = isCheckout
        ? HeaderCheckout
        : isMobile
            ? HeaderMobile
            : HeaderDesktop;
    const FooterComponent = isCheckout ? FooterCheckout : Footer;
    const appStyling = {
        '.main': showHeader ? undefined : headerTopClear,
        '.SpotListSearchControlsCondensed': {
            '.Search-map-view': showHeader ? undefined : headerTopClear,
            top: showHeader ? undefined : 0,
        },
        '.SpotDetailsReservationSummary-sticky.showing': {
            transform: showHeader ? undefined : 'translateY(0%)',
        },
        '.SpotDetailsResponsive .CheckoutSectionStickyResponsive.visible': {
            transform: showHeader ? undefined : 'translateY(0%)',
        },
    };
    return (<ErrorBoundary>
            <ApplicationMeta />
            {isMobile ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Helmet>
                    <>
                        <meta httpEquiv="cleartype" content="on"/>
                        <meta name="viewport" content="width=device-width, initial-scale=1, minimal-ui"/>
                        <meta name="HandheldFriendly" content="True"/>
                        <meta name="MobileOptimized" content="320"/>
                        <meta name="mobile-web-app-capable" content="yes"/>
                        <meta name="apple-mobile-web-app-capable" content="yes"/>
                        <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
                        <meta name="apple-mobile-web-app-title" content="SpotHero"/>
                        <meta name="theme-color" content="#0082ff"/>
                    </>
                </Helmet>) : (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Helmet>
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
                </Helmet>)}
            <Box as="div" className="App" sx={appStyling} key="App">
                <NotificationContainer notification={notification} onQueueEmpty={clearNotificationDispatched}/>
                {showHeader && <HeaderComponent />}
                <Navigator extraProps={{
            pageType,
            configData: { isMobile, citySlugs },
            isMonthly,
            destination,
            serverContext,
            isPowerBooking,
        }}/>
                <FooterComponent email={String(email)}/>
                {showGDPRModal && (<GDPRModal onHidden={hideGDPRModal} countryCode={userCountryCode}/>)}
            </Box>
        </ErrorBoundary>);
};
export default App;
