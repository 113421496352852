import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getWindow} from 'ssr-window';
import {Button, Flex} from '@spothero/ui';

const window = getWindow();

export default class SearchActions extends Component {
    static propTypes = {
        showCancel: PropTypes.bool,
        isSearching: PropTypes.bool,
        isValidStart: PropTypes.bool,
        searchButtonText: PropTypes.string,
        addKeyListeners: PropTypes.bool,
        onSearchClick: PropTypes.func.isRequired,
        onCancelClick: PropTypes.func,
        pageType: PropTypes.string,
    };
    static defaultProps = {
        searchButtonText: 'Search',
    };

    componentDidMount() {
        this._manageKeyListeners();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.addKeyListeners !== this.props.addKeyListeners) {
            this._manageKeyListeners();
        }
    }

    componentWillUnmount() {
        this._manageKeyListeners(true);
    }

    // Only respond to [keydown] events on either BODY or search input field
    // This is to prevent bugs like https://spothero.atlassian.net/browse/CONWEB-1179
    // Enter keydown behaviours:
    // 1. User typed a destination in GooglePlacesSearchInput input and has selected an item from the auto-complete.
    //    After auto-complete selection pressing Enter on BODY should trigger onSearchClick.
    // 2. User is focused in empty Search input. Pressing Enter should trigger onSearchClick.
    // Escape keydown behaviour:
    // 1. Trigger onCancelClick where provided
    _onKeyDown = evt => {
        if (!(evt.target.tagName === 'BODY' || evt.target.name === 'search')) {
            return;
        }

        const {onSearchClick, onCancelClick} = this.props;

        if (evt.which === 13) {
            // enter
            evt.preventDefault();

            onSearchClick();
        } else if (evt.which === 27) {
            // escape
            evt.preventDefault();

            if (onCancelClick) {
                onCancelClick();
            }
        }
    };

    _manageKeyListeners(forceRemove) {
        const {addKeyListeners} = this.props;

        if (forceRemove || !addKeyListeners) {
            // remove key listeners for update and cancel buttons
            window.removeEventListener('keydown', this._onKeyDown);
        } else if (addKeyListeners) {
            // add key listeners for update and cancel buttons
            window.addEventListener('keydown', this._onKeyDown);
        }
    }

    render() {
        const {
            isSearching,
            isValidStart,
            onCancelClick,
            onSearchClick,
            pageType,
            searchButtonText,
            showCancel,
        } = this.props;

        return (
            <Flex className="SearchActions" flexDirection="column">
                <Button
                    className="SearchActions-submit"
                    data-testid="SearchActions-submit-button"
                    isLoading={isSearching}
                    disabled={!isValidStart || isSearching}
                    onClick={onSearchClick}
                    variant={pageType === 'home' ? 'primaryOnDark' : 'primary'}
                    maxWidth={pageType === 'home' ? 40 : 'none'}
                    width={{base: '100%', tablet: 64}}
                    alignSelf="center"
                >
                    <span>{searchButtonText}</span>
                </Button>
                {showCancel && (
                    <Button
                        variant="tertiary"
                        disabled={isSearching}
                        onClick={onCancelClick}
                        marginTop={4}
                        data-testid="SearchActions-cancel-button"
                    >
                        Cancel
                    </Button>
                )}
            </Flex>
        );
    }
}
