import { NOTIFY, CLEAR_NOTIFICATION } from './notification-actions';
export default function notification(state = null, { type, payload }) {
    switch (type) {
        case NOTIFY: {
            return {
                ...state,
                ...payload,
            };
        }
        case CLEAR_NOTIFICATION: {
            return {};
        }
        default:
            return state;
    }
}
