import { filterSearchResponse } from '../../utils/filter-search-response';
import { formatTransientFacility } from './formatTransientFacility';
/**
 * Formats data for multiple transient facilities
 *
 * @function formatTransientSearchResponse
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientParking|Documentation}
 * @param {object} searchResults - Facility data returned from the search API for a multiple facilities
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @returns {Array} The array of transient facilities, with data transformation applied.
 */
export function formatTransientSearchResponse(searchResults, formatOptions = {}) {
    let formattedResults = searchResults.map(result => formatTransientFacility(result, {
        ...formatOptions,
        isFacilitySummary: true,
    }));
    if (formatOptions.filterSpot) {
        formattedResults = filterSearchResponse(formattedResults, formatOptions.filterSpot);
    }
    return formattedResults;
}
/**
 * Formats data for a single transient facility
 *
 * @function formatTransientSpotDetailsResponse
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientFacilityParking|Documentation}
 * @param {object} facilityData - All the data returned from the search API for a single facility
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @returns {object} The transient facility, with data transformation applied.
 */
export function formatTransientSpotDetailsResponse(facilityData, formatOptions) {
    return formatTransientFacility(facilityData, {
        ...formatOptions,
        isFacilitySummary: false,
    });
}
