import SearchTracking from 'utils/search-tracking';
import SegmentUtils from 'utils/segment';
import { createTopSearchResultsList } from 'segment/utils/top-search-results';
import { getParkingType, getSearchFilters, getSortBy, } from 'store/selectors/selectors';
import ErrorUtils from 'utils/error-utils';
import { getTotalPriceUserPreference, TotalPriceSessionPreference, } from 'utils/total-price-session-utils';
export default function totalPriceChanged(state) {
    try {
        const { spots: { data: spots }, destination: { data: destination }, } = state;
        const sortBy = getSortBy(state);
        const airport = destination?.airport || null;
        const { searchUUID, actionUUID, sessionUUID, } = SearchTracking.getValues();
        const parkingType = getParkingType(state);
        const filters = getSearchFilters(state);
        const showTotal = getTotalPriceUserPreference() === TotalPriceSessionPreference.On;
        const properties = {
            action_id: actionUUID,
            search_id: searchUUID,
            session_id: sessionUUID,
            total_price_toggle_on: showTotal,
            top_search_results_list: createTopSearchResultsList({
                spots,
                airport,
                sortBy,
                parkingType,
                isMonthly: parkingType === 'monthly',
                filters,
            }),
        };
        SegmentUtils.track({
            event: 'Total Price Changed',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 1,
                    },
                },
            },
        });
    }
    catch (error) {
        // We want to log code failures here, not network failures
        if (error instanceof Error) {
            ErrorUtils.sendSentryException(error);
        }
    }
}
