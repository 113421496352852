import PropTypes from 'prop-types';
export const CITY_INFO = PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    phoneNumber: PropTypes.string,
    phoneNumberFriendly: PropTypes.string,
    monthlyPhoneNumber: PropTypes.string,
    monthlyPhoneNumberFriendly: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    supportEmail: PropTypes.string,
    zipCode: PropTypes.string,
    weekdayHours: PropTypes.string,
    weekendHours: PropTypes.string,
});
