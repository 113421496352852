import { useSelector } from 'react-redux';
import { DEFAULT_RATING, DEFAULT_USER_RATING_COUNT } from 'api/app-rating';
const useAppstoreRating = ({ ratingText = 'iOS App Store' } = {}) => {
    const appRating = useSelector((state) => state.appRating.data);
    const { rating, ratingCount } = appRating || {
        rating: DEFAULT_RATING,
        ratingCount: DEFAULT_USER_RATING_COUNT,
    };
    const text = `${rating}/5 ${ratingText}`;
    return {
        rating,
        text,
        ratingCount,
    };
};
export default useAppstoreRating;
