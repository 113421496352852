/* eslint-disable camelcase */
import escape from 'lodash/escape';
import isNil from 'lodash/isNil';
import dayjs from 'utils/dayjs';
import EnvironmentUtils from '@spothero/utils/environment';
import Config from '@/config/index';
import FormatUtils from 'utils/format';
import ErrorUtils from 'utils/error-utils';
/**
 * Branch Utils
 *
 * @module utils/branch
 * @param {object} branch - Object from branch to run their functions
 * @returns {object} - Object of functions
 */
export const BranchUtils = (branch) => ({
    /**
     * Init branch functions
     *
     * @param {string} key - Branch access key
     * @param {Function} cb - callback for post branch init
     * @returns {void}
     */
    init(key, cb) {
        if (!branch) {
            return;
        }
        branch.init(key, cb);
    },
    /**
     * Generates deep link parameters for search
     */
    generateDeepLinkParams({ latitude, longitude, searchString = null, place = null, starts = null, ends = null, }) {
        let deepLinkParams = `?latitude=${latitude}&longitude=${longitude}`;
        if (searchString) {
            deepLinkParams = `${deepLinkParams}&search_string=${escape(searchString)}`;
        }
        if (starts && ends) {
            const newStarts = !dayjs.isDayjs(starts)
                ? dayjs(starts).format(Config.apiDateTimeFormat)
                : starts.format(Config.apiDateTimeFormat);
            const newEnds = !dayjs.isDayjs(ends)
                ? dayjs(ends).format(Config.apiDateTimeFormat)
                : ends.format(Config.apiDateTimeFormat);
            deepLinkParams = `${deepLinkParams}&starts=${newStarts}&ends=${newEnds}`;
        }
        if (place) {
            const { google_places_place_id: placesPlaceId, google_places_id: placesId, google_places_name: placesName, google_places_reference: placesReference, } = place;
            if (!isNil(placesPlaceId)) {
                deepLinkParams = `${deepLinkParams}&places_place_id=${placesPlaceId}`;
            }
            if (!isNil(placesId)) {
                deepLinkParams = `${deepLinkParams}&places_id=${placesId}`;
            }
            if (!isNil(placesName)) {
                deepLinkParams = `${deepLinkParams}&places_name=${placesName}`;
            }
            if (!isNil(placesReference)) {
                deepLinkParams = `${deepLinkParams}&places_reference=${placesReference}`;
            }
        }
        return deepLinkParams;
    },
    /**
     * Generates deep link for search
     */
    generateDeepLink({ latitude, longitude, searchString = null, place = null, starts = null, ends = null, }) {
        return `${Config.siteUrl}/search${this.generateDeepLinkParams({
            latitude,
            longitude,
            searchString,
            place,
            starts,
            ends,
        })}`;
    },
    /**
     * Link using branch
     *
     * @param {object} data - data to go to branch
     * @param {Function} callback - call back to call after branch links
     * @returns {void}
     */
    link(data, callback) {
        if (!branch) {
            return;
        }
        branch.link(data, callback);
    },
    /**
     * Set view data using branch
     *
     * @param {object} data - data to go to branch
     * @returns {void}
     */
    setViewData(data) {
        if (!branch) {
            return;
        }
        branch.setBranchViewData(data);
    },
    /**
     * Track commerce event using branch
     *
     * @param {object} param - top level param
     * @param {string} param.transactionId - ID of transaction
     * @param {string} param.price - price
     * @param {string} param.currency - currency used
     * @returns {void}
     */
    trackCommerceEvent({ transactionId, price, currency, }) {
        if (!branch) {
            return;
        }
        const { displayPrice } = FormatUtils.price(Number(price));
        /* eslint-disable camelcase */
        const eventAndCustomData = {
            transaction_id: String(transactionId),
            currency: currency.toUpperCase(),
            revenue: displayPrice,
        };
        branch.logEvent('PURCHASE', eventAndCustomData, [], null, error => {
            if (error) {
                // @ts-expect-error - Definitely typed doesn't address if initialization fails
                const isBranchError = error.message?.includes('Branch SDK initialization failed');
                if (!isBranchError) {
                    ErrorUtils.sendSentryMessage({
                        error,
                        customErrorMessage: 'Branch.io: Error tracking commerce event',
                    });
                }
            }
        });
        /* eslint-enable camelcase */
    },
});
export default BranchUtils(EnvironmentUtils.isBrowser() ? require('branch-sdk') : null);
