import React from 'react';
import { Flex, Text, Icon } from '@spothero/ui';
import IconStar from '@spothero/icons/star-filled';
/*
Logic for displaying star rating with category:
Amount of reviews displayed as rounded abbreviated number.
If there are less than 1,000 ratings then show full number (e.g. 999)
If there are more than 1,000 but less than 10,000 show in the format of X.Xk
e.g. 1,234 = 1.2k
e.g. 9,352 = 9.3k
The first digit represents the thousands, the second digit represents the hundreds.
Round down, not up, to the nearest hundred.
If there are 10,000 or more, show in the format of XXk
e.g. 10,001 = 10k
e.g. 52,792 = 52k
Round down, not up, to the nearest thousand.
 */
export const getNumberOfReviews = (numberOfReviews) => {
    if (numberOfReviews < 1000) {
        return numberOfReviews;
    }
    if (numberOfReviews < 10000) {
        return `${(numberOfReviews / 1000).toFixed(1)}k`;
    }
    return `${Math.floor(numberOfReviews / 1000)}k`;
};
const StarRatingWithCategory = ({ numberOfReviews, starRating, }) => {
    if (numberOfReviews < 10) {
        return <div className="StarRatingWithCategory"/>;
    }
    return (<Flex data-testid="StarRatingWithCategory" marginTop={1} alignItems="center">
            <Icon as={IconStar} color="yellow.400" marginRight={0.5}/>
            <Text as="span" color="black" paddingRight={1} fontWeight="semibold" fontSize="sm" data-testid="StarRatingWithCategory-StarRating">
                {starRating.toFixed(1)}
            </Text>
            <Text as="span" color="gray.dark" fontSize="sm" data-testid="StarRatingWithCategory-Reviews">
                ({getNumberOfReviews(numberOfReviews)})
            </Text>
        </Flex>);
};
StarRatingWithCategory.defaultProps = {
    numberOfReviews: 0,
    starRating: 0,
};
export default StarRatingWithCategory;
