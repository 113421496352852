import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';

export default function trackAppleMapsSetTimesClicked({
    facility,
    editedStartTime,
    editedEndTime,
}) {
    try {
        SegmentUtils.track({
            event: 'Apple Maps Set Times Clicked',
            properties: {
                facility_id: facility?.id || null,
                edited_start_time: editedStartTime,
                edited_end_time: editedEndTime,
            },
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 1,
                    },
                },
            },
        });
    } catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage:
                'Segment Event Failed - trackAppleMapsSetTimesClicked',
        });
    }
}
