import StorageUtils from '@spothero/utils/storage';
import buildVehicleDetails from './build-vehicle-details';
const buildCommonRentalDetails = ({ checkoutData, searchUUID, actionUUID, rentalSourceReferrer, source, licensePlateRequired = false, }) => {
    const { user, spot, licensePlateRequired: checkoutLPRequired, selectedLicensePlate, affiliate, referralSource, smsParkingPass, } = checkoutData;
    const phoneNumberCleaned = user.phoneNumber
        ? user.phoneNumber.replace(/\D/g, '')
        : null;
    const facilityId = spot?.spotId;
    const eventPackageId = spot?.event_package?.id;
    const rentalDetails = {
        facility: facilityId,
        search_id: searchUUID,
        action_id: actionUUID,
        ...buildVehicleDetails({
            checkoutData,
            licensePlateRequired: licensePlateRequired ||
                checkoutLPRequired ||
                Boolean(eventPackageId && selectedLicensePlate),
            skipUnknownForSavedVehicles: Boolean(eventPackageId),
        }),
    };
    // phone number
    if (user.phoneNumber && !phoneNumberCleaned) {
        rentalDetails.phone_number = user.phoneNumber;
    }
    else if (phoneNumberCleaned) {
        rentalDetails.phone_number = phoneNumberCleaned;
    }
    if (affiliate) {
        rentalDetails.affiliate = affiliate;
    }
    if (referralSource) {
        rentalDetails.referral_source = referralSource;
    }
    // SMS Parking Pass
    if (smsParkingPass && phoneNumberCleaned) {
        rentalDetails.sms_parking_pass = true;
    }
    if (source) {
        rentalDetails.rental_source_title = source;
    }
    if (rentalSourceReferrer) {
        rentalDetails.rental_source_referrer = rentalSourceReferrer;
    }
    else {
        const referrerCookie = StorageUtils.get('sh-referrer', 'cookie');
        if (referrerCookie) {
            rentalDetails.rental_source_referrer = referrerCookie;
        }
    }
    return rentalDetails;
};
export default buildCommonRentalDetails;
