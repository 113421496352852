import { useSelector } from 'react-redux';
import { useShowTotal } from 'store/selectors/hooks/useShowTotal';
/* eslint-enable prettier/prettier */
/**
 * This hook is decide what price label to display - passed in string serves as an override
 * Built originally for transient, event primarily but extended to all types
 */
export const useLabel = (priceLabel, isMapPopup = false) => {
    // Multiple useSelectors based on this finding - https://stackoverflow.com/a/63277926
    const showTotal = useShowTotal();
    const { powerBooking, powerBookingPeriods, monthly, pageType } = useSelector((state) => state.searchRequest);
    const { data: { events }, } = useSelector((state) => state.eventPackage);
    let lengthString = '';
    const isAirport = pageType === 'airport'; // string compare boo
    const isEventPackages = pageType === 'event_package' || pageType === 'event_packages_search'; // including search because unsure how it becomes one or the other
    if (priceLabel)
        return priceLabel;
    if (monthly) {
        return showTotal ? 'Total' : '/month';
    }
    // Below if added to support mapPopup
    if (isEventPackages) {
        if (showTotal) {
            lengthString = `${events.length} event `;
        }
        else {
            return '/event';
        }
    }
    if (powerBooking && !isMapPopup) {
        lengthString = `${powerBookingPeriods.length} Day `;
    }
    if (isAirport) {
        return showTotal ? 'Total' : 'Per Day';
    }
    return `${lengthString}${showTotal ? 'Total' : 'Subtotal'}`;
};
