import { updateFilters } from 'store/filters/filters-actions';
import { trackFilterChange } from './trackfilterChange';
export const updateAmenityChecked = ({ activeAmenities, isChecked, amenity, isQuickFilter, showModal, dispatch, storeState, }) => {
    let newActiveAmenities = [...activeAmenities];
    if (isChecked) {
        newActiveAmenities.push(amenity);
    }
    else {
        newActiveAmenities = newActiveAmenities.filter(item => item !== amenity);
    }
    const changedFilters = {
        activeAmenities: newActiveAmenities,
        viaQuickFilter: isQuickFilter,
    };
    const isTrackChange = isQuickFilter && !showModal;
    dispatch(updateFilters(changedFilters));
    if (isTrackChange) {
        trackFilterChange(storeState, changedFilters);
    }
};
