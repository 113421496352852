import dayjs from 'utils/dayjs-timezone';
import { FACILITY_TYPES } from 'utils/types/facility';
const buildTransientDetails = ({ selectedRate, eventId, timezone, }) => {
    const rentalDetails = {
        starts: dayjs.tz(selectedRate.starts, timezone || '').format(),
        ends: dayjs.tz(selectedRate.ends, timezone || '').format(),
        rule_group_id: selectedRate.rule_group_id,
    };
    if (eventId) {
        rentalDetails.event = eventId;
    }
    if (selectedRate.rule_group_id === FACILITY_TYPES.RATE_EXCEPTIONS.EVENT_RATE) {
        delete rentalDetails.rule_group_id;
    }
    return rentalDetails;
};
export default buildTransientDetails;
