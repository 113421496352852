import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPriceBreakdownModalSpotId } from 'store/search/search-actions';
import { getSpotById } from 'store/spots/spots-selectors';
import { sortMultiDayItems } from 'utils/multiday-utils';
import PriceBreakdownItemAccordion from './PriceBreakdownItemAccordion';
import { Box, Divider, Flex, Text, Modal, ModalBody, ModalHeader, } from '@spothero/ui';
import Price from 'common/price';
import { useShowTotal } from 'store/selectors/hooks/useShowTotal';
export default function PriceBreakdownModal({ spotId, rate, }) {
    const showModal = Boolean(spotId || rate);
    const dispatch = useDispatch();
    const rootState = useSelector((state) => state);
    const spot = spotId ? getSpotById(spotId)(rootState) : null;
    const { searchRequest: { powerBooking: isPowerBooking }, } = useSelector((state) => state);
    const showTotal = useShowTotal();
    // only one accordion item can be open at a time
    const [openAccordion, setOpenAccordion] = React.useState(null);
    const selectedRate = rate || spot?.selectedRate || null;
    if (!selectedRate ||
        !selectedRate.priceBreakdown ||
        !selectedRate.priceBreakdown.length) {
        return null;
    }
    const priceBreakdownItems = (isPowerBooking
        ? selectedRate.powerBookingPriceBreakdown
        : selectedRate.priceBreakdown) || [];
    const totalPrice = isPowerBooking
        ? selectedRate.PBTotalPrice
        : selectedRate.totalPrice;
    const onHidden = () => {
        dispatch(resetPriceBreakdownModalSpotId());
    };
    const handleAccordionChange = (shortDescription) => {
        if (openAccordion === shortDescription) {
            setOpenAccordion(null);
        }
        else {
            setOpenAccordion(shortDescription);
        }
    };
    const sortedPriceBreakdowns = sortMultiDayItems(priceBreakdownItems);
    return (<Modal title="Price Breakdown" size="sm" isOpen={showModal} onClose={onHidden} closeOnOverlayClick={false} hasHeader hasFooter scrollBehavior="inside" contentStyling={{
            minHeight: '220px',
        }}>
            <ModalHeader textAlign="left" fontSize="xl">
                Price Breakdown
            </ModalHeader>
            <ModalBody data-testid="PriceBreakdownModal-Content">
                <Flex flexGrow={1} flexDirection="column">
                    {sortedPriceBreakdowns.map(({ price, shortDescription, fullDescription }, index) => (<Box key={`item${index}`}>
                                <PriceBreakdownItemAccordion showExpandableDescriptions={showTotal} fullDescription={fullDescription} openAccordion={openAccordion} shortDescription={shortDescription} onChange={() => handleAccordionChange(shortDescription)} value={price.value} currencyType={price.currencyCode}/>
                            </Box>))}
                    <Divider paddingTop={2}/>
                    <Flex justify="space-between" paddingTop={2}>
                        <Text fontWeight="semibold">Total</Text>
                        <Box fontSize="base" fontWeight="semibold">
                            <Price amount={totalPrice.value} currencyType={totalPrice.currencyCode} showCents/>
                        </Box>
                    </Flex>
                </Flex>
            </ModalBody>
        </Modal>);
}
