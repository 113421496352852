import React from 'react';
import { Box, Flex, Text } from '@spothero/ui';
import { useSelector } from 'react-redux';
import { getParkingType } from 'store/selectors/selectors';
export const EventDate = ({ month, date }) => {
    const parkingType = useSelector(getParkingType);
    return (<Box className="event-date" // Used for hover state
     minWidth="36px" width="36px" height="38px" borderWidth="1px" borderColor="black" rounded="md" overflow="hidden" display="flex" flexDirection="column" transform={parkingType === 'event' ? undefined : 'scale(0.85)'}>
            {/* Month section */}
            <Box bg="primary.default" color="white" fontSize="xs" fontWeight="semibold" px={1} py={0.5} textAlign="center" textTransform="uppercase">
                {month}
            </Box>
            {/* Day section */}
            <Flex flexGrow={1} align="center" justify="center" backgroundColor="white" color="black" fontWeight="bold" fontSize="base">
                {date}
            </Flex>
        </Box>);
};
export const EventInfo = ({ topText, bottomText, showDate, isSelected, }) => {
    const parkingType = useSelector(getParkingType);
    return (<Box paddingLeft={!showDate && { tablet: '8', base: '4' }} width="100%" overflow="hidden" textDecoration="none" whiteSpace="nowrap" textOverflow="ellipsis">
            <Text width={{
            base: '100%',
            mobileXL: parkingType === 'event' ? '260px' : '100%',
        }} overflow="hidden" textDecoration="none" whiteSpace="nowrap" textOverflow="ellipsis" color="inherit" fontSize={{ base: 'xs', mobileXL: 'sm' }} fontWeight="medium">
                {topText}
            </Text>
            <Text className="autocomplete-venue" // Used for hover state
     fontSize="xs" fontWeight="medium" color={isSelected ? 'inherit' : 'text.secondary.light'} width="100%" overflow="hidden" textDecoration="none" whiteSpace="nowrap" textOverflow="ellipsis">
                {bottomText}
            </Text>
        </Box>);
};
