import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalContent} from '@spothero/ui-backlog';
import OnlineCommuterRateModalContent from './OnlineCommuterRateModalContent';

const OnlineCommuterRateModal = ({
    title,
    description,
    hideContentTitle,
    onHidden,
}) => {
    return (
        <Modal
            title={title}
            className="OnlineCommuterRateModal"
            onHidden={onHidden}
        >
            <ModalContent>
                <OnlineCommuterRateModalContent
                    description={description}
                    hideTitle={hideContentTitle}
                />
            </ModalContent>
        </Modal>
    );
};

OnlineCommuterRateModal.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
    hideContentTitle: PropTypes.bool,
    onHidden: PropTypes.func.isRequired,
};

export default OnlineCommuterRateModal;
