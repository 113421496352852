import { AIRPORT_TRANSPORTATION_TYPES_V2 } from '@/proptypes/search-v2';
/**
 * Formats data for a V2 shuttle transportation type to V1 shuttle transportation type
 *
 * @function formatTransportationType
 * @param {string} type - transportation type
 * @returns {string} V1 response transportation type.
 */
export const formatTransportationType = (type = '') => {
    switch (type) {
        case AIRPORT_TRANSPORTATION_TYPES_V2.SCHEDULED_SHUTTLE:
            return 'scheduled';
        case AIRPORT_TRANSPORTATION_TYPES_V2.CURBSIDE_VALET:
            return 'curbside-valet';
        case AIRPORT_TRANSPORTATION_TYPES_V2.TAXI:
            return 'taxi';
        case AIRPORT_TRANSPORTATION_TYPES_V2.ON_DEMAND_SHUTTLE:
            return 'on-demand';
        case AIRPORT_TRANSPORTATION_TYPES_V2.PUBLIC_TRANSIT:
            return 'public-transit';
        default:
            return type;
    }
};
