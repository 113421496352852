import each from 'lodash/each';
import isArray from 'lodash/isArray';
import size from 'lodash/size';
import { getWindow } from 'ssr-window';
import StorageUtils from '@spothero/utils/storage';
import { persistEmailHash } from 'utils/analytics';
const window = getWindow();
// Each SPA page component (ie WithSearch, Checkout) contains a virtual page view call
// we only want these page views to get through after the initial page load
// initial page load page views (for SPA and non-SPA) are handled by the initial GA script load via GTM
let isInitialPageView = true;
const pushToDataLayer = data => {
    const isPageView = data.event && data.event === 'pageview';
    const isSearchPageView = isPageView && data.page === 'search';
    if (!window.dataLayer) {
        window.dataLayer = [];
    }
    if (!isPageView || !isInitialPageView || isSearchPageView) {
        window.dataLayer.push(data);
    }
    if (isPageView) {
        isInitialPageView = false;
    }
};
/**
 * Utilities for working with Google Tag Manager.
 *
 * @module GTMUtils
 */
const GTMUtils = {
    /**
     * Pushes data to the GTM dataLayer.
     *
     * @static
     * @function push
     * @param {Array.<object> | object} [data={}] - Arbitrary object (or array of objects) to send to the dataLayer.
     * @example
     * GTMUtils.push({
     *     event: 'GAEvent',
     *     foo: 'bar'
     * });
     * @returns {void}
     */
    push(data = {}) {
        if (!size(data)) {
            throw new Error('At least one data object is required.');
        }
        if (isArray(data)) {
            each(data, dataLayerObj => {
                pushToDataLayer(dataLayerObj);
            });
        }
        else {
            pushToDataLayer(data);
        }
    },
    /**
     * If called with a user, pushes a log in event to GTM
     * If called without a user, pushes a log out event to GTM
     *
     * @static
     * @function identify
     * @param {object} user - The current user
     * @returns {void}
     */
    identify(user = null) {
        if (user) {
            if (!user.id) {
                return;
            }
            const variations = StorageUtils.get('sh-experiment-variations', 'cookie');
            persistEmailHash(user.email);
            this.push({
                event: 'userLoggedIn',
                userId: String(user.id),
                userEmail: user.email,
                facebookOAuth: String(!user.hasUsablePassword),
            });
            this.push({
                event: 'experimentVariationsUpdated',
                experimentVariations: JSON.stringify(variations),
            });
        }
        else {
            this.push({
                event: 'userLoggedOut',
                /* eslint-disable no-undefined */
                userId: undefined,
                userEmailMD5: undefined,
                userEmail: undefined,
                facebookOAuth: undefined,
                /* eslint-enable no-undefined */
            });
        }
    },
};
export default GTMUtils;
