import React from 'react';
import { Flex } from '@spothero/ui';
import FiltersModal from 'common/search-filters-controls/filters-modal';
import { useSelector } from 'react-redux';
import { getAmenities, getRatings } from 'store/filters/filters-selectors';
import { getSpotsV2 } from 'store/spots/spots-selectors';
import { FilterButtonBase } from './filter-bases';
import { CountOrIcon } from './count-or-icon/CountOrIcon';
import { MonthlyAvailability } from './modules/monthly/availability/MonthlyAvailability';
import { useFilterModal } from './utils/modalToggle';
import { FiltersHourly } from './modules/transient/FiltersHourly';
export const FilterSelection = () => {
    const { showModal, onModalToggle, onFilterChange, onClearFilters, } = useFilterModal();
    const amenities = useSelector((state) => getAmenities(state));
    const ratings = useSelector((state) => getRatings(state));
    const numSpots = useSelector((state) => getSpotsV2(state).length);
    const numActiveFilters = useSelector((state) => state.filters.numActive);
    const activeAmenities = useSelector((state) => state.filters.activeAmenities);
    const activeRatingTypes = useSelector((state) => state.filters.activeRatingTypes);
    const isMonthly = useSelector((state) => state.searchRequest.monthly);
    return (<Flex flexGrow={1} gap={2} marginTop="4" width="100%" overflow="auto" paddingX="4">
            <FilterButtonBase onClick={onModalToggle} testid="QuickFilters-filter-condensed">
                <CountOrIcon />
                Filters
            </FilterButtonBase>
            {isMonthly ? <MonthlyAvailability /> : <FiltersHourly />}
            <FiltersModal numSpots={numSpots} amenities={amenities} activeAmenities={activeAmenities} numActiveFilters={numActiveFilters} onChange={onFilterChange} onClear={onClearFilters} onHidden={onModalToggle} isOpen={showModal} allRatings={ratings} activeRatingTypes={activeRatingTypes}/>
        </Flex>);
};
