import * as mapUtils from './map-utils';
import throttle from 'lodash/throttle';
import trackPinPreviewClicked from 'segment/events/search-pin-preview-clicked';
import { isMobilePortraitView } from 'hooks/use-is-mobile';
import { convertRatingToSlug } from 'store/filters/rating-filter-utils';
// Note: The googleMapWidget function uses function declarations instead of arrow functions so that the functions
// are hoisted and can be used before they are defined to improve readability.
/* eslint-disable @typescript-eslint/no-use-before-define */
function enhanceSpotMarkers(spots, highlightedSpotId, previewedSpotId) {
    if (!spots)
        return [];
    return spots.map((spot, index) => {
        return {
            ...spot,
            isHighlighted: `${highlightedSpotId}` === `${spot.spotId}`,
            isPreviewed: `${previewedSpotId}` === `${spot.spotId}`,
            rank: String(index),
        };
    });
}
/**
 * Creates a new GoogleMapWidget instance with the specified options.
 *
 * Note: All the [set*] functions are to be used only by the parent React component.
 * Do NOT use them in the GoogleMapWidget instance as they will not update the state in the parent.
 * Use [on*] callbacks to update the state in the parent.
 */
const googleMapWidget = ({ container, googleMapsId, centerLatLng: _centerLatLng, centerTitle: _centerTitle, kmlMapLayerEnabled, kmlMapLayerURL, searchRequestId: _searchRequestId, zoom: _zoom, onPan: onPanCallback, onZoom, onGoogleMapLoaded, showAllSpots, isMobile: _isMobile, highlightedSpotId: _highlightedSpotId, previewedSpotId: _previewedSpotId, onSpotHighlighted, onSpotPreviewed, ratingFilters: _ratingFilters, }) => {
    const maps = mapUtils.getMapsLibrary();
    const markers = {};
    const zoomThrottle = throttle(onZoomChanged, 1000);
    let highlightedSpotId = _highlightedSpotId;
    let previewedSpotId = _previewedSpotId;
    let centerLatLng = _centerLatLng;
    let centerMarker = null;
    let centerTitle = _centerTitle;
    let isDraggingTimeout = null;
    let isDragging = false;
    let popup = null;
    let kmlLayer = null;
    let map = null;
    let needsFitToBounds = true;
    let searchRequestId = _searchRequestId;
    let zoom = _zoom;
    let prevZoom = null;
    let isMobile = _isMobile;
    let spots = [];
    let ratingFilters = _ratingFilters;
    const prevRatingFilters = _ratingFilters;
    /**
     * Initializes the Google Map instance.
     */
    function init() {
        container.innerHTML = '';
        map = mapUtils.initializeMap({
            container,
            googleMapsId,
            maps,
            zoom,
            center: centerLatLng,
            idleCallback: () => {
                onGoogleMapLoaded(returnMapToDestination);
            },
            tilesLoadedCallback: () => {
                if (spots.length > 0) {
                    fitMapToMarkers();
                }
            },
            onDragStart,
            onDragEnd,
            onZoomChanged: zoomThrottle,
            onMapClick: () => {
                closePopup();
            },
        });
        if (kmlMapLayerEnabled && kmlMapLayerURL) {
            addKMLLayer(kmlMapLayerURL);
        }
        // add center marker
        const { marker } = mapUtils.newMarkerAndLatLng({
            lat: centerLatLng.lat,
            lng: centerLatLng.lng,
            title: centerTitle,
            maps,
            map,
        });
        centerMarker = marker;
        // Event listener to support SpotListNoResults
        window.addEventListener('sh-search-map-return-to-center', () => {
            returnMapToDestination();
        });
    }
    /**
     * Returns the map to the destination center and zoom level.
     */
    function returnMapToDestination() {
        needsFitToBounds = true;
        mapUtils.returnMapToDestination({
            map,
            maps,
            center: centerLatLng,
            onPan,
        });
    }
    /**
     * Callback function to be called when the map is panned.
     */
    function onPan() {
        const options = mapUtils.getMapOptions({
            map,
            center: centerLatLng,
        });
        onPanCallback(options);
    }
    /**
     * Sets the center coordinates and title of the map.
     *
     * @param {number} lat - The latitude of the new center coordinates.
     * @param {number} lng - The longitude of the new center coordinates.
     * @param {string} title - The new title of the center marker.
     */
    function setCenterLatLngAndTitle(lat, lng, title) {
        if (centerLatLng.lat === lat &&
            centerLatLng.lng === lng &&
            centerTitle === title) {
            return;
        }
        const { position, marker } = mapUtils.newMarkerAndLatLng({
            lat,
            lng,
            title,
            maps,
            map,
        });
        centerLatLng = position;
        centerTitle = title;
        // clear old marker
        if (centerMarker) {
            mapUtils.hideMarker({ marker: centerMarker });
        }
        centerMarker = marker;
    }
    /**
     * Sets the zoom level of the map.
     *
     * @param {number} newZoom - The new zoom level of the map.
     */
    function setZoom(newZoom) {
        if (zoom !== newZoom) {
            prevZoom = zoom;
            zoom = newZoom;
            closePopup();
            mapUtils.setZoom({ zoom, map });
        }
    }
    /**
     * Sets the ID of the search request.
     *
     * @param {string} newSearchRequestId - The new ID of the search request.
     * @returns {boolean} Whether the search request ID was changed.
     */
    function setSearchRequestId(newSearchRequestId) {
        if (searchRequestId !== newSearchRequestId) {
            searchRequestId = newSearchRequestId;
            needsFitToBounds = true;
            return true;
        }
        return false;
    }
    /**
     * Refreshes the spots displayed on the map with the specified new spots.
     *
     * @param {Array} newSpots - The new spots to display on the map.
     * @param {boolean} shouldAdjustMarkers - Whether the markers should be adjusted to fit the map bounds.
     * @returns {void}
     */
    function refreshSpots(newSpots, shouldAdjustMarkers = true) {
        const previousSpots = spots;
        const newEnhancedSpots = enhanceSpotMarkers(newSpots, highlightedSpotId, previewedSpotId);
        const { removed, added, updated } = getSpotChanges(previousSpots, newEnhancedSpots, isMobile);
        const areSpotsChanged = removed.length > 0 || added.length > 0 || updated.length > 0;
        if (areSpotsChanged) {
            // first remove the markers
            removed.forEach(({ spotId }) => {
                removeSpotMarker(spotId);
            });
            // then add the markers
            added.forEach(spot => {
                addSpotMarker(spot);
            });
            // then refresh the updated markers
            updated.forEach(spot => {
                updateSpotMarker(spot);
            });
            spots = newEnhancedSpots;
            if (shouldAdjustMarkers && needsFitToBounds) {
                fitMapToMarkers();
            }
        }
    }
    /**
     * Adds a marker for the specified spot to the map.
     *
     * @param {object} spot - The spot to add a marker for.
     * @returns {object} - The marker that was added.
     */
    function addSpotMarker(spot) {
        const { spotId, isHighlighted } = spot;
        if (markers[spotId]) {
            return;
        }
        const handleOnMouseEnter = (hoveredSpotId) => {
            // Desktop Only
            if (!isMobile) {
                onSpotHighlighted({ spotId: hoveredSpotId });
            }
        };
        const handleOnMouseLeave = () => {
            // Desktop Only
            if (!isMobile) {
                onSpotHighlighted({ spotId: '' });
            }
        };
        markers[spotId] = mapUtils.addMarker({
            spot,
            map,
            maps,
            isMobile,
            isHighlighted,
            onMouseEnter: handleOnMouseEnter,
            onMouseLeave: handleOnMouseLeave,
            onClick: onMarkerClicked,
            onShowPopup: newPopup => {
                if (popup) {
                    closePopup();
                }
                popup = newPopup;
            },
            ratingFilters,
        });
    }
    /**
     * Updates the marker for the specified spot on the map.
     *
     * @param {SpotMarkerEnhanced} spot - The spot to update .
     */
    function updateSpotMarker(spot) {
        const marker = markers[spot.spotId];
        if (marker) {
            mapUtils.updateMarker({
                marker,
                spot,
                isMobile,
                map,
                ratingFilters,
            });
        }
    }
    /*
     * Handle click on a marker.
     * @param {Spot} spot - The spot.
     */
    function onMarkerClicked(spot) {
        const spotId = spot?.spotId || '';
        const rank = spot?.rank;
        onSpotPreviewed({ spotId });
        trackPinPreviewClicked({
            spotId,
            rank,
        });
    }
    /**
     * Removes the marker for the specified spot from the map.
     *
     * @param {string} spotId - The ID of the spot to remove the marker for.
     */
    function removeSpotMarker(spotId) {
        const marker = markers[spotId];
        if (marker) {
            mapUtils.removeMarker({ marker, maps });
            delete markers[spotId];
        }
    }
    /**
     * Removes all spot markers from the map.
     */
    function removeAllMarkers() {
        for (const spotId of Object.keys(markers)) {
            removeSpotMarker(spotId);
        }
    }
    /**
     * Callback function to be called when the user starts dragging the map.
     */
    function onDragStart() {
        isDragging = true;
        if (isDraggingTimeout) {
            clearTimeout(isDraggingTimeout);
            isDraggingTimeout = null;
        }
    }
    /**
     * Callback function to be called when the user stops dragging the map.
     */
    function onDragEnd() {
        if (isDragging) {
            isDraggingTimeout = setTimeout(() => {
                isDragging = false;
                closePopup();
                onPan();
            }, 500);
        }
    }
    /**
     * Fits the map to the markers displayed on it.
     */
    function fitMapToMarkers() {
        needsFitToBounds = false;
        if (spots.length > 0) {
            const distance = getDistanceOfTheSpotsFromDestination();
            mapUtils.fitMapToMarkers({
                distance,
                center: centerLatLng,
                maps,
                map,
                idleCallback: () => {
                    onZoomChanged();
                },
            });
        }
    }
    /**
     * Callback function to be called when the user zooms in or out of the map.
     */
    function onZoomChanged() {
        const options = mapUtils.getMapOptions({
            map,
            center: centerLatLng,
        });
        // the 'zoom_changed' event gets fired in every zoom instance, so we have to determine what to do with zoom levels in parent
        onZoom({
            ...options,
            prevZoom,
        });
    }
    /**
     * Cleans up the Google Map instance by removing all event listeners and removing the onSpotHover listener from the document.
     */
    function cleanup() {
        if (maps && map) {
            removeAllMarkers();
            mapUtils.cleanupMap({
                map,
                maps,
            });
            map = null;
            popup = null;
            centerMarker = null;
            spots = [];
        }
        if (isDraggingTimeout) {
            clearTimeout(isDraggingTimeout);
        }
        if (zoomThrottle) {
            zoomThrottle.cancel();
        }
    }
    /**
     * Gets the changes in spots between the previous and new spots.
     *
     * @param {Array} newSpots - The new spots to compare with the previous spots.
     * @param {Array} prevSpots - The previous spots to compare with the new spots.
     * @returns {object} An object containing the removed and updated spots.
     */
    function getSpotChanges(prevSpots, newSpots, isMobileView) {
        // removed are spots that are in prevSpots but not in newSpots or whose isPreviewed property has changed
        const removed = prevSpots.filter(prevSpot => newSpots.every(newSpot => newSpot.spotId !== prevSpot.spotId));
        // newly added are spots that are in newSpots but not in prevSpots
        const added = newSpots.filter(newSpot => prevSpots.every(prevSpot => prevSpot.spotId !== newSpot.spotId));
        // on mobile (map) we only care about preview
        // but on desktop we have to account for both highlight (hover) and preview
        const mobilePredicate = (a, b) => a.isPreviewed !== b.isPreviewed;
        const desktopPredicate = (a, b) => a.isHighlighted !== b.isHighlighted ||
            a.isPreviewed !== b.isPreviewed;
        const predicate = isMobileView ? mobilePredicate : desktopPredicate;
        // updated are spots that are in both prevSpots and newSpots but have different properties
        const updated = newSpots.filter(newSpot => prevSpots.some(prevSpot => newSpot.spotId === prevSpot.spotId &&
            (newSpot.markerLabelText !== prevSpot.markerLabelText ||
                newSpot.recentlyViewed !== prevSpot.recentlyViewed ||
                newSpot.showRatingColor !== prevSpot.showRatingColor ||
                predicate(newSpot, prevSpot))));
        return { removed, added, updated };
    }
    /**
     * Gets the distance from the destination to the spot that contains ~50% of spots.
     *
     * @returns {number} The distance from the destination to the spot that contains ~50% of spots.
     */
    function getDistanceOfTheSpotsFromDestination() {
        const sortedByDistance = spots.sort((a, b) => a.distanceFromDestination - b.distanceFromDestination);
        // get the distance from the destination
        // to the spot that contains ~50% of spots
        // or 90% of spots when showAllSpots flag is set
        const spotsToShow = showAllSpots ? 0.9 : 0.5;
        const index = Math.floor(spots.length * spotsToShow);
        // only scale the distance on desktop
        if (!isMobilePortraitView()) {
            return sortedByDistance[index].distanceFromDestination * 1.5; // scale slightly to avoid clipping in the header. 1.5 is a magic number
        }
        return sortedByDistance[index].distanceFromDestination;
    }
    /**
     * Adds a KML layer to the map.
     *
     * @param {string} kmlUrl - The URL of the KML file to add to the map.
     */
    function addKMLLayer(kmlUrl) {
        if (!kmlLayer) {
            kmlLayer = mapUtils.addKMLLayer({
                map,
                maps,
                kmlUrl,
            });
        }
    }
    /**
     * Closes the popup if it is open.
     */
    function closePopup() {
        if (popup) {
            popup?.setMap(null);
            popup = null;
            // Notify parent React component to clear the highlighted spot
            // On the next React render the highlighted spot is cleared and shown as normal
            onSpotHighlighted({ spotId: '' });
            onSpotPreviewed({ spotId: '' });
        }
    }
    /**
     * Updates the internal highlightedSpotId state to match with the parent React component.
     *
     * @param {string} spotId - The ID of the spot to highlight.
     */
    function setHighlightedSpotId(spotId) {
        highlightedSpotId = spotId;
    }
    /**
     * Updates the internal isMobile state to match with the parent React component.
     *
     * @param {boolean} newIsMobileValue - The new value of isMobile.
     */
    function setIsMobile(newIsMobileValue) {
        isMobile = newIsMobileValue;
    }
    /**
     * Updates the internal previewedSpotId state to match with the parent React component.
     *
     * @param {string} spotId - The ID of the spot to preview.
     */
    function setPreviewedSpotId(spotId) {
        previewedSpotId = spotId;
    }
    /**
     * Pans the map to the spot with the specified ID.
     *
     * @param {string} spotId - The ID of the spot to pan to.
     */
    function panTo(spotId) {
        const spot = spots.find(s => s.spotId === spotId);
        if (spot) {
            mapUtils.panTo({
                map,
                position: {
                    lat: spot.latitude,
                    lng: spot.longitude,
                },
            });
        }
    }
    function setRatingFilters(activeRatingTypes) {
        if (prevRatingFilters !== activeRatingTypes) {
            ratingFilters = activeRatingTypes || [];
            spots.forEach(spot => {
                spot.showRatingColor = ratingFilters.includes(convertRatingToSlug(spot?.rating));
            });
        }
    }
    init();
    return {
        addKMLLayer,
        cleanup,
        getSpotChanges,
        refreshSpots,
        panTo,
        setCenterLatLngAndTitle,
        setSearchRequestId,
        setHighlightedSpotId,
        setPreviewedSpotId,
        setIsMobile,
        setZoom,
        closePopup,
        setRatingFilters,
    };
};
export default googleMapWidget;
/* eslint-enable @typescript-eslint/no-use-before-define */
