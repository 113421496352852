import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleMapsLibraries } from 'common/google-map-context/GoogleMapContext';
import { setRecentlyViewed } from 'store/search/search-actions';
import useConfig from 'hooks/use-config';
import googleMapWidget from './google-map-widget';
import GoogleMapPopup from './GoogleMapPopup';
import useIsMobile from 'hooks/use-is-mobile';
import { setHighlightedSpotId, setMobileMapViewInteractionTrigger, setPreviewedSpotId, } from 'store/spots/spots-actions';
import { SearchPageView } from 'utils/search-page-view';
/**
 * A component that displays a Google Map with markers for spots.
 */
const SHGoogleMap = ({ isAdmin, destination, kmlMapLayerEnabled, kmlMapLayerURL, spots, searchRequestId, zoom, onGoogleMapLoaded, onMarkerClick: onSpotDetailCallback, onPan: onPanProp, onZoom, showAllSpots = false, }) => {
    const prevPreviewedSpotId = useRef('');
    const isMobile = useIsMobile();
    const { highlightedSpotId, previewedSpotId, mobileMapViewInteractionTrigger, } = useSelector((state) => state.spots);
    const { view: searchPageView } = useSelector((state) => state.search.data);
    const isSpotDetailOpen = useSelector((state) => Boolean(state.spots.selectedSpotId));
    const { filterId } = useSelector((state) => state.filters);
    const { activeRatingTypes } = useSelector((state) => state.filters);
    const [prevFilterId, setPrevFilterId] = useState(filterId);
    const dispatch = useDispatch();
    const { googleMapsId } = useConfig();
    const { googleMapsLoaded } = useGoogleMapsLibraries();
    const { title: destinationTitle } = destination;
    const mapWidgetRef = useRef(null);
    const mapContainerRef = useRef(null);
    const centerLatLng = useMemo(() => ({
        lat: destination.position.latitude,
        lng: destination.position.longitude,
    }), [destination.position.latitude, destination.position.longitude]);
    const onSpotDetail = useCallback(({ spotId, markerType }) => {
        if (!isAdmin && markerType === 'disabled') {
            return;
        }
        onSpotDetailCallback(spotId);
    }, [isAdmin, onSpotDetailCallback]);
    const onSpotHighlighted = useCallback(({ spotId }) => {
        dispatch(setHighlightedSpotId({ spotId }));
    }, [dispatch]);
    const onSpotPreviewed = useCallback(({ spotId }) => {
        if (spotId) {
            dispatch(setRecentlyViewed(spotId));
        }
        dispatch(setMobileMapViewInteractionTrigger({ trigger: 'click' }));
        dispatch(setPreviewedSpotId({ spotId }));
    }, [dispatch]);
    const handleOnPan = useCallback((options) => {
        dispatch(setMobileMapViewInteractionTrigger({ trigger: 'pan' }));
        if (onPanProp) {
            onPanProp(options);
        }
    }, [dispatch, onPanProp]);
    const refreshMap = useCallback(() => {
        const mapWidget = mapWidgetRef.current;
        const hasSearchRequestIdChanged = mapWidget.setSearchRequestId(searchRequestId);
        const hasFilterIdChanged = prevFilterId !== filterId;
        if (hasFilterIdChanged) {
            setPrevFilterId(filterId);
        }
        mapWidget.setCenterLatLngAndTitle(centerLatLng.lat, centerLatLng.lng, destinationTitle);
        mapWidget.setIsMobile(isMobile);
        mapWidget.setHighlightedSpotId(highlightedSpotId);
        mapWidget.setPreviewedSpotId(previewedSpotId);
        mapWidget.refreshSpots(spots);
        mapWidget.setZoom(zoom);
        mapWidget.setRatingFilters(activeRatingTypes);
        // If Spot Details is open then close info window as the user can view details of any spot from the spot list
        // and the info window would not be in sync with the selected spot.
        // Also close info window if the search request ID has changed.
        if (isSpotDetailOpen ||
            hasSearchRequestIdChanged ||
            hasFilterIdChanged) {
            mapWidget.closePopup();
        }
        // KML layer feature is likely obsolete.
        // Migrated code from the SHGoogleMap.jsx Class component.
        if (kmlMapLayerEnabled) {
            mapWidget.addKMLLayer(kmlMapLayerURL);
        }
    }, [
        searchRequestId,
        prevFilterId,
        filterId,
        centerLatLng.lat,
        centerLatLng.lng,
        destinationTitle,
        isMobile,
        highlightedSpotId,
        previewedSpotId,
        spots,
        zoom,
        isSpotDetailOpen,
        kmlMapLayerEnabled,
        kmlMapLayerURL,
        activeRatingTypes,
    ]);
    // initialize Google Map Widget
    useEffect(() => {
        if (googleMapsLoaded &&
            !mapWidgetRef.current &&
            mapContainerRef.current) {
            mapWidgetRef.current = googleMapWidget({
                container: mapContainerRef.current,
                googleMapsId,
                zoom,
                centerLatLng,
                centerTitle: destinationTitle,
                kmlMapLayerEnabled,
                kmlMapLayerURL,
                showAllSpots,
                isMobile,
                highlightedSpotId,
                previewedSpotId,
                onPan: handleOnPan,
                onSpotDetail,
                onZoom,
                onGoogleMapLoaded,
                onSpotHighlighted,
                onSpotPreviewed,
                ratingFilters: activeRatingTypes,
            });
        }
        if (mapWidgetRef.current) {
            refreshMap();
        }
    }, [
        googleMapsLoaded,
        googleMapsId,
        centerLatLng,
        destinationTitle,
        kmlMapLayerEnabled,
        kmlMapLayerURL,
        onGoogleMapLoaded,
        onSpotDetail,
        onZoom,
        zoom,
        showAllSpots,
        onSpotPreviewed,
        refreshMap,
        highlightedSpotId,
        isMobile,
        onSpotHighlighted,
        handleOnPan,
        previewedSpotId,
        activeRatingTypes,
    ]);
    // unmount cleanup
    useEffect(() => {
        return () => {
            mapWidgetRef.current?.cleanup();
        };
    }, []);
    useEffect(() => {
        if (!isMobile)
            return; // This effect is only for mobile
        if (!mapWidgetRef.current)
            return; // Map widget is not initialized yet
        // when user pans the map we trigger a new search.
        // sometimes the previewed spot is removed during the search
        // so, we need to clear the previewed spot id
        if (previewedSpotId && !spots.some(s => s.spotId === previewedSpotId)) {
            dispatch(setPreviewedSpotId({ spotId: '' }));
            // As the previewed spot id has changed, we stop future execution of this effect
            return;
        }
        // handle previewed spot id changes
        if (previewedSpotId &&
            prevPreviewedSpotId.current !== previewedSpotId) {
            // when user has clicked on a spot map pin or has panned the map
            // then scroll the spot list to the previewed spot
            if (mobileMapViewInteractionTrigger === 'click' ||
                mobileMapViewInteractionTrigger === 'pan') {
                const elem = window.document.querySelector(`[data-spot="${previewedSpotId}"]`);
                if (elem) {
                    elem.scrollIntoView({
                        behavior: 'auto',
                        block: 'center',
                        inline: 'center',
                    });
                }
            }
            // when user has scrolled the spot list
            // then pan the map to show the spot card pin
            else if (mobileMapViewInteractionTrigger === 'scroll') {
                mapWidgetRef.current.panTo(previewedSpotId);
            }
        }
    }, [
        previewedSpotId,
        isMobile,
        mobileMapViewInteractionTrigger,
        spots,
        dispatch,
    ]);
    return (<>
            {!isMobile && searchPageView === SearchPageView.DESKTOP_LIST && (<GoogleMapPopup spot={previewedSpotId
                ? (spots || []).find(s => s.spotId === previewedSpotId)
                : null} onClick={onSpotDetail} onClose={() => mapWidgetRef.current?.closePopup()}/>)}

            <div ref={mapContainerRef} className="SHGoogleMap"/>
        </>);
};
export default SHGoogleMap;
