import React, { useEffect } from 'react';
import classNames from 'classnames';
import IconChevronDown from '@spothero/icons/chevron-down';
import IconFacebookCircle from '@spothero/icons/facebook-circle';
import IconInstagramCircle from '@spothero/icons/instagram-circle';
import IconTwitterCircle from '@spothero/icons/twitter-circle';
import IconYoutubeCircle from '@spothero/icons/youtube-circle';
import IconSpothero from '@spothero/icons/spothero';
import AppStoreBadges from 'common/app-store-badges';
import Link from 'router/Link';
import { Box, Container, ListItem, List, Text, Checkbox, Icon, } from '@spothero/ui';
import { footerData as footerSections } from '../constants';
import { useHistory } from 'react-router';
import { OPTIMIZELY_VARIATION_REFRESHED, useFeatureVariation, } from 'plugins/optimizely/hooks/use-feature-variation';
import { IMMEDIATE_FEATURES } from 'utils/experiment';
const footerColumn = {
    width: { base: '80', desktop: 'auto' },
    flex: { base: 'none', desktop: '0 0 auto' },
    maxWidth: '100%',
    margin: '0 auto',
};
const containerStyle = {
    paddingLeft: { base: '2.5', desktop: 0 },
    paddingRight: { base: '2.5', desktop: 0 },
    maxWidth: { desktop: '82rem' },
    display: { desktop: 'flex' },
    justifyContent: { desktop: 'space-between' },
};
const socialIconStyles = {
    fill: t => t.colors.black,
    width: '6',
    height: '6',
};
const socials = [
    {
        Component: IconFacebookCircle,
        href: 'https://www.facebook.com/SpotHero',
        key: 'fb',
    },
    {
        Component: IconInstagramCircle,
        href: 'https://instagram.com/spothero/',
        key: 'ig',
    },
    {
        Component: IconTwitterCircle,
        href: 'https://twitter.com/SpotHero',
        key: 'tw',
    },
    {
        Component: IconYoutubeCircle,
        href: 'https://www.youtube.com/channel/UC6OwKRrIUj3dgVKWb2Z5MyQ',
        key: 'yt',
    },
];
const Footer = ({ className, email }) => {
    const isSearchRevampRefreshedVariation = useFeatureVariation(IMMEDIATE_FEATURES.ACQUISITION_MOBILE_WEB_SEARCH_REVAMP) === OPTIMIZELY_VARIATION_REFRESHED;
    const [isVisible, setIsVisible] = React.useState(true);
    const history = useHistory();
    const [checkedState, setCheckedState] = React.useState([]);
    const classes = classNames('Footer', className);
    const handleToggleMenu = (index) => {
        setCheckedState(prevState => {
            const newState = [...prevState];
            newState[index] = !checkedState[index];
            return newState;
        });
    };
    useEffect(() => {
        if (isSearchRevampRefreshedVariation &&
            ['/search', '/parking-near-me', '/monthly-parking-near-me'].some(entry => entry === history.location.pathname)) {
            setIsVisible(false);
        }
    }, [history.location.pathname, isSearchRevampRefreshedVariation]);
    return (isVisible && (<Box className={classes} data-testid="Footer">
                <Container {...containerStyle}>
                    <Box marginRight={{ desktop: '12', base: '5' }} data-testid="FooterBrandSection" {...footerColumn}>
                        <Box textAlign="center" marginBottom="1rem" marginTop="-1rem">
                            <Link to="/" forceAnchor display="inline-block" verticalAlign="middle" color="brandBlue" width="24" height="24">
                                <IconSpothero />
                            </Link>
                            <Box display={{
            base: 'none',
            desktop: 'inline-block',
        }} verticalAlign="middle" marginLeft="6" paddingLeft="6" textAlign="left" borderLeft={t => `.1rem solid ${t.colors.gray['600']}`}>
                                <Text lineHeight="1" fontSize="4xl">
                                    75M+
                                    <Text fontSize="base" display="block" marginTop="2" letterSpacing="0.125rem" as="span">
                                        Cars Parked
                                    </Text>
                                </Text>
                            </Box>
                        </Box>
                        <Box>
                            <AppStoreBadges email={email}/>
                        </Box>
                    </Box>
                    <Box {...footerColumn} flex={{ desktop: '1 1 auto' }} data-testid="FooterLinksSection">
                        {footerSections
            ?.filter(el => el.slice_type === 'internal_link_list')
            ?.map((column, i) => {
            const { items, primary: { list_title: listTitle }, } = column;
            return (<Box key={`Footer-link-column-${i}`} data-testid={`FooterLinksSection-accordion-item-${listTitle}`} width={{ desktop: '25%' }} marginTop={{ base: '8', desktop: 0 }} display={{
                    base: 'block',
                    desktop: 'inline-block',
                }} verticalAlign={{
                    desktop: 'top',
                }} sx={{
                    '.chakra-checkbox__control': {
                        display: 'none',
                    },
                    '.chakra-checkbox__label': {
                        display: 'block',
                        position: 'relative',
                        margin: 0,
                    },
                }}>
                                        <Checkbox id={`Footer-links-${i}`} type="checkbox" onChange={() => handleToggleMenu(i)} width="full" display="block" fontWeight="semibold" cursor={{
                    base: 'pointer',
                    desktop: 'default',
                }}>
                                            {listTitle.toUpperCase()}
                                            <Icon as={IconChevronDown} transform={checkedState[i]
                    ? 'rotate(180deg) translateY(8px)'
                    : 'translateY(-50%)'} fill={t => t.colors.black} width="4" height="4" position="absolute" top="50%" left="auto" right="0" display={{
                    base: 'block',
                    desktop: 'none',
                }}/>
                                        </Checkbox>

                                        <List maxH={{
                    base: checkedState[i]
                        ? '62.5rem'
                        : '0',
                    desktop: 'none',
                }} styleType="none" overflow="hidden" transition="max-height .2s cubic-bezier(.25,.46,.45,.94)">
                                            {items.map((link, j) => (<ListItem key={j} marginTop="5" marginBottom="px" fontSize="xs">
                                                    <Link to={link.link_path} forceAnchor color="black" fontWeight="normal" _hover={{
                        color: 'black',
                    }}>
                                                        {link.link_text}
                                                    </Link>
                                                </ListItem>))}
                                        </List>
                                    </Box>);
        })}
                    </Box>
                    <Box mt={{ base: '14', desktop: '0' }} align="center">
                        <List styleType="none">
                            {socials.map(({ key, href, Component }) => (<ListItem key={key} display="inline-block" verticalAlign="top" marginLeft="1.5" marginRight="1.5">
                                    <Link to={href} isExternal forceAnchor rel="noopener noreferrer">
                                        <Icon as={Component} {...socialIconStyles}/>
                                    </Link>
                                </ListItem>))}
                        </List>
                    </Box>
                </Container>
                <Container marginTop={{ base: '6', desktop: '16' }} marginBottom="6" color="gray.600" align="center" data-testid="FooterCopyrightAndCookiePreferenceSection" {...containerStyle}>
                    <Text width={{ desktop: 'full' }} align={{ desktop: 'right' }}>
                        <small>
                            SpotHero &copy;{new Date().getFullYear()}. All
                            Rights Reserved.
                        </small>
                    </Text>
                </Container>
            </Box>));
};
export default Footer;
