import { useCallback } from 'react';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import StorageUtils from '@spothero/utils/storage';
import SearchUtils from 'utils/search-utils';
export const useOnShowDetailsClick = () => {
    const { spotsV2: spots, sortBy } = useReduxSearchState();
    return useCallback((spotId) => {
        StorageUtils.set(SearchUtils.SEGMENT_SPOT_META, {
            rank: spots.findIndex((s) => s.spotId === `${spotId}`) +
                1,
            sortBy,
        });
    }, [spots, sortBy]);
};
