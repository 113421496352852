import isNull from 'lodash/isNull';
import {ActionType} from 'redux-promise-middleware';
import {getWindow} from 'ssr-window';
import UrlUtils from '@spothero/utils/url';
import {CHECKOUT_FETCH_RATES} from '../checkout/checkout-actions';
import CheckoutUtils from 'utils/checkout-utils';
import {replace} from '../router/router-actions';
import SearchUtils from 'utils/search-utils';
import {
    SEARCH_REQUEST_SET_TERM,
    SEARCH_REQUEST_UPDATE_TIMES,
    SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES,
} from '../search-request/search-request-actions';
import {SPOT_CHANGE_MULTI_RATE} from '../spot/spot-actions';
import {matchPathToRoute} from '../../router/routes';

const window = getWindow();

export const searchRequestMiddleware = ({
    dispatch,
    getState,
}) => next => action => {
    switch (action.type) {
        case `${CHECKOUT_FETCH_RATES}_${ActionType.Fulfilled}`: {
            const {
                searchRequest,
                checkout: {rid},
                spot: {selected},
            } = getState();
            const isEventPackage = Boolean(selected?.event_package?.id);
            const searchParams = {
                rid,
                ...searchRequest,
                ...action.meta,
                isEventPackage,
            };
            const newUrl = CheckoutUtils.updateRoute({
                currentUrl: action.meta.newUrl
                    ? action.meta.newUrl // RecommendedSpotsModal passes a new URL when a different spot is clicked so replace the url with this one
                    : window.location.pathname,
                searchParams,
            });

            if (window.location.pathname !== '/confirmation') {
                dispatch(replace(newUrl));
            }

            break;
        }

        case SPOT_CHANGE_MULTI_RATE: {
            const {
                spot: {selected},
            } = getState();
            const isEventPackage = Boolean(selected?.event_package?.id);
            const searchParams = {
                isEventPackage,
                ...getState().searchRequest,
                rid: action.payload.rid,
            };
            const newUrl = CheckoutUtils.updateRoute({
                currentUrl: window.location.pathname,
                searchParams,
            });

            dispatch(replace(newUrl));

            break;
        }

        case SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES: {
            const {searchRequest} = getState();
            const {pathname, search} = window.location;
            const updatedSearchRequest = {...searchRequest, ...action.payload};
            const query = UrlUtils.parseParams(search);

            delete query.monthly;
            delete query.airport;
            // eslint-disable-next-line camelcase
            query.pb_source = action.payload.powerBookingSource || null;

            // eslint-disable-next-line camelcase
            query.power_booking = true;

            query.starts = [];
            query.ends = [];

            updatedSearchRequest.powerBookingPeriods?.forEach(
                ({starts: periodStarts, ends: periodEnds}) => {
                    query.starts.push(periodStarts);
                    query.ends.push(periodEnds);
                }
            );

            delete query['spot-id'];

            dispatch(
                replace(`${pathname}?${UrlUtils.createQueryString(query)}`)
            );

            break;
        }

        case SEARCH_REQUEST_SET_TERM:
        case SEARCH_REQUEST_UPDATE_TIMES: {
            const {
                searchRequest,
                destination: {
                    data: {airport, monthly_enabled: monthlyEnabled = null},
                },
            } = getState();
            const {pathname, search} = window.location;
            const route = matchPathToRoute(pathname);
            const updatedSearchRequest = {...searchRequest, ...action.payload};

            // Only update route for 'old' URL routes (i.e. catch-all)
            if (isNull(route) || route.path === '/:page/:section?') {
                const newUrl = SearchUtils.updateRoute({
                    currentUrl: window.location.pathname,
                    searchParams: {
                        ...updatedSearchRequest,
                        monthlyEnabled,
                        airport: Boolean(airport),
                    },
                });

                // Only update URL if NOT a parking URL
                if (
                    !['/parking-near-me', '/monthly-parking-near-me'].includes(
                        window.location.pathname
                    )
                ) {
                    dispatch(replace(newUrl));
                    break;
                }
            } else if (route.path === '/search') {
                const {
                    type,
                    payload: {
                        monthly: isMonthly,
                        filterSpot,
                        powerBooking: isPowerBooking,
                        hideAirportDateTimeModal = false,
                    },
                } = action;
                const query = UrlUtils.parseParams(search);

                if (type === SEARCH_REQUEST_UPDATE_TIMES) {
                    query.starts = updatedSearchRequest.starts;
                    query.filterSpot = filterSpot;

                    if (!isMonthly && !query.monthly) {
                        query.ends = updatedSearchRequest.ends;
                    }

                    if (airport && hideAirportDateTimeModal) {
                        // eslint-disable-next-line camelcase
                        query.hide_modal = hideAirportDateTimeModal;
                    }

                    dispatch(
                        replace(
                            `${pathname}?${UrlUtils.createQueryString(query)}`,
                            {
                                reload: false,
                            }
                        )
                    );

                    break;
                }

                // if changed to/from monthly search, update URL query accordingly
                if (
                    type === SEARCH_REQUEST_SET_TERM &&
                    query.monthly !== isMonthly
                ) {
                    if (query.monthly && !isMonthly) {
                        delete query.monthly;
                        query.ends = updatedSearchRequest.ends;
                    }
                    if (isMonthly) {
                        query.monthly = true;
                    }

                    // if users navigates to hourly tab from power_booking, reset start and end query params
                    if (query.power_booking && !isPowerBooking) {
                        delete query.power_booking;

                        query.starts = updatedSearchRequest.starts;
                        query.ends = updatedSearchRequest.ends;
                        delete query.pb_source;
                    }

                    // if spot-id is present in query params, remove it when switching terms and just run a new search
                    delete query['spot-id'];

                    dispatch(
                        replace(
                            `${pathname}?${UrlUtils.createQueryString(query)}`
                        )
                    );

                    break;
                }
            }

            break;
        }
    }

    return next(action);
};
