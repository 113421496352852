import SegmentUtils from 'utils/segment';
export default function trackSearchSelectVehicle(vehicle) {
    if (!vehicle || !vehicle.id)
        return;
    const properties = {
        vehicle_make: vehicle.make,
        vehicle_model: vehicle.model,
    };
    SegmentUtils.track({
        event: 'Select Vehicle in Search',
        properties,
        additionalOptions: {
            context: {
                protocols: {
                    event_version: 1,
                },
            },
        },
    });
}
