import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';

export default function trackAppleMapsSpotCardClicked({
    facility,
    clickedFacilityId,
    areaClicked,
}) {
    try {
        SegmentUtils.track({
            event: 'Apple Maps Spot Card Clicked',
            properties: {
                facility_id: facility?.id || null,
                clicked_facility_id:
                    typeof clickedFacilityId === 'string'
                        ? parseInt(clickedFacilityId, 10)
                        : clickedFacilityId,
                area_clicked: areaClicked,
            },
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 1,
                    },
                },
            },
        });
    } catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage:
                'Segment Event Failed - trackAppleMapsSpotCardClicked',
        });
    }
}
