import PropTypes from 'prop-types';
import { OBJECT_COMMON_FACILITY_SUMMARY, OBJECT_COMMON_FACILITY, OBJECT_COMMON_RATE, PROPTYPE_REDEMPTION_INSTRUCTION, PROPTYPE_CANCELLATION, } from './common';
export const OBJECT_TRANSIENT_RATE = {
    ...OBJECT_COMMON_RATE,
    isAutoExtended: PropTypes.bool.isRequired,
    parkingPass: PropTypes.shape({
        displayName: PropTypes.string,
        typeof: PropTypes.string,
    }),
    title: PropTypes.string,
    eventId: PropTypes.string,
    earlyBird: PropTypes.shape({
        description: PropTypes.string,
        enterPeriod: PropTypes.shape({
            starts: PropTypes.string,
            ends: PropTypes.string,
        }),
    }),
    redemptionType: PropTypes.string,
    rateType: PropTypes.string,
    additionalRateDetails: PropTypes.object,
    cancellationThresholdMinutes: PropTypes.number,
    expressCheckoutUrl: PropTypes.string,
};
export const PROPTYPE_TRANSIENT_RATE = PropTypes.shape(OBJECT_TRANSIENT_RATE);
export const OBJECT_TRANSIENT_FACILITY_SUMMARY = {
    ...OBJECT_COMMON_FACILITY_SUMMARY,
    cancellation: PROPTYPE_CANCELLATION,
    slug: PropTypes.string,
    selectedRate: PROPTYPE_TRANSIENT_RATE,
    hasMultipleRates: PropTypes.bool,
    vehicle: PropTypes.shape({
        onsiteFee: PropTypes.number,
        oversizePolicyId: PropTypes.number,
        oversizeType: PropTypes.string,
        unknownOnsiteFee: PropTypes.bool,
    }),
};
export const PROPTYPE_TRANSIENT_FACILITY_SUMMARY = PropTypes.shape(OBJECT_TRANSIENT_FACILITY_SUMMARY);
export const OBJECT_TRANSIENT_FACILITY = {
    ...OBJECT_COMMON_FACILITY,
    redemptionInstructions: PropTypes.shape({
        driveUp: PropTypes.arrayOf(PROPTYPE_REDEMPTION_INSTRUCTION),
    }),
    vehicle: PropTypes.shape({
        onsiteFee: PropTypes.number,
        oversizePolicyId: PropTypes.number,
        oversizeType: PropTypes.string,
        unknownOnsiteFee: PropTypes.bool,
    }),
};
export const PROPTYPE_TRANSIENT_FACILITY = PropTypes.shape(OBJECT_TRANSIENT_FACILITY);
