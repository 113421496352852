import { ROUTER_DATA_LOAD_FULFILLED } from '../router/router-actions';
export const initialState = () => ({ data: null });
export default function page(state = initialState(), // unknown as I'm not totally sure whats going on here
{ type, payload }) {
    switch (type) {
        case ROUTER_DATA_LOAD_FULFILLED: {
            return payload.page || initialState();
        }
        default:
            return state;
    }
}
