import { useEffect } from 'react';
import { initializeFirebaseApp } from 'api/firebase-remote-config';
import useConfig from 'hooks/use-config';
export const useFirebase = () => {
    const { firebaseAppId, firebasePublicKey, firebaseProjectId } = useConfig();
    useEffect(() => {
        initializeFirebaseApp({
            firebaseAppId,
            firebasePublicKey,
            firebaseProjectId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
