import PropTypes from 'prop-types';
import { AmenityTypes } from 'api/search-v2/utils';
/*
See ./index.js for notes on naming conventions
*/
// Only rely on the V1 slugs so filter selections can transfer across search experiences.
// V2-CLEANUP: We can update these to the V2 slugs if we want, after all search experiences are on V2.
export const AMENITY_SLUGS_V1 = Object.values(AmenityTypes);
export const PROPTYPE_AMENITY = PropTypes.shape({
    description: PropTypes.string.isRequired,
    type: PropTypes.oneOf(AMENITY_SLUGS_V1).isRequired,
});
export const PROPTYPE_PRICE = PropTypes.shape({
    currencyCode: PropTypes.oneOf(['CAD', 'USD']).isRequired,
    value: PropTypes.number.isRequired,
});
export const PROPTYPE_AVAILABILITY = PropTypes.shape({
    available: PropTypes.bool.isRequired,
    unavailableReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
});
const OBJECT_PRICE_BREAKDOWN = {
    fullDescription: PropTypes.string,
    price: PROPTYPE_PRICE.isRequired,
    shortDescription: PropTypes.string.isRequired,
    // TODO: should this be an enum? question out to BE
    type: PropTypes.string.isRequired,
    // TS didn't like me me being self referencial, so I just doubled it up
    priceBreakdown: PropTypes.arrayOf(PropTypes.shape({
        fullDescription: PropTypes.string,
        price: PROPTYPE_PRICE.isRequired,
        shortDescription: PropTypes.string.isRequired,
        // TODO: should this be an enum? question out to BE
        type: PropTypes.string.isRequired,
    })),
};
export const PROPTYPE_PRICE_BREAKDOWN = PropTypes.shape({
    ...OBJECT_PRICE_BREAKDOWN,
});
// Common details provided as part of any rate for a given facility
// Different verticals will have slightly different requirements;
// {VERTICAL}_RATE may extend this definition with vertical-specific fields
// or implement a unique definition
export const OBJECT_COMMON_RATE = {
    rateId: PropTypes.string.isRequired,
    starts: PropTypes.string.isRequired,
    ends: PropTypes.string.isRequired,
    priceBreakdown: PropTypes.arrayOf(PROPTYPE_PRICE_BREAKDOWN).isRequired,
    totalPrice: PROPTYPE_PRICE.isRequired,
    fullUrl: PropTypes.string.isRequired,
    advertisedPrice: PROPTYPE_PRICE.isRequired,
    amenities: PropTypes.arrayOf(PROPTYPE_AMENITY).isRequired,
    redemptionType: PropTypes.oneOf([
        'self',
        'valet',
        'self_valet_assist',
        'premium_valet',
    ]).isRequired,
};
