import { useEffect, useState } from 'react';
import { INFO_WINDOW_DOM_ID } from 'common/search-results-map/google-map/map-utils';
/**
 * This React custom hook returns a boolean that indicates whether the DOM is ready with element INFO_WINDOW_DOM_ID.
 */
function useDOMReady(spot) {
    const [isReady, setIsReady] = useState(false);
    const [container, setContainer] = useState(null);
    useEffect(() => {
        if (!spot) {
            // reset state
            setIsReady(false);
            setContainer(null);
        }
        else {
            const interval = setInterval(() => {
                const element = document.getElementById(INFO_WINDOW_DOM_ID);
                if (element) {
                    setContainer(element);
                    setIsReady(true);
                    clearInterval(interval);
                }
            }, 20);
            return () => {
                clearInterval(interval);
            };
        }
    }, [spot]);
    return { isReady, container };
}
export default useDOMReady;
