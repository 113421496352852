import IconFilledExclamationCircle from '@spothero/icons/exclamation-circle-filled';
import {Badge, Icon, useBreakpointValue} from '@spothero/ui';
import React from 'react';
import PropTypes from 'prop-types';

const SpotCardAvailableSpotsBadge = ({availableSpots}) => {
    const isMobile = useBreakpointValue({base: true, tablet: false});
    const spotsString = availableSpots === 1 ? 'spot' : 'spots';
    const message = isMobile ? 'left' : `${spotsString} left`;

    return (
        <Badge
            variant="warningLight"
            alignSelf="center"
            display="flex"
            alignItems="center"
            marginRight={{
                base: '12px',
                tablet: 'auto',
            }}
            fontWeight="semibold"
            paddingRight={2}
            borderRadius={t => t.sizes['2']}
        >
            <Icon
                as={IconFilledExclamationCircle}
                color="yellow.500"
                width={6}
                height={6}
                marginY={1}
                marginRight={1}
                marginLeft={0}
            />
            {availableSpots} {message}
        </Badge>
    );
};

SpotCardAvailableSpotsBadge.propTypes = {
    availableSpots: PropTypes.number,
};

export default SpotCardAvailableSpotsBadge;
