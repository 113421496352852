import PropTypes from 'prop-types';
/*
See ./index.js for notes on naming convention
*/
export const PROPTYPE_SPOT_ID = PropTypes.string;
export const PROPTYPE_SPOT_TITLE = PropTypes.string;
export const PROPTYPE_FACILITY_TYPE = PropTypes.oneOf([
    'garage',
    'lot',
    'personal',
    'under_el',
    'valet_stand',
    'unknown',
]);
export const PROPTYPE_PARKING_TYPE = PropTypes.oneOf([
    'airport',
    'monthly',
    'transient',
]);
export const PROPTYPE_CLOUDINARY_IMAGE = PropTypes.shape({
    altText: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    urlTemplate: PropTypes.string,
});
export const PROPTYPE_STAR_RATING = PropTypes.shape({
    average: PropTypes.number,
    count: PropTypes.number.isRequired,
});
export const PROPTYPE_ADDRESS = PropTypes.shape({
    id: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    postalCode: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    streetAddress: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
});
export const PROPTYPE_DISTANCE = PropTypes.shape({
    linearMeters: PropTypes.number.isRequired,
    walkingDistanceInMiles: PropTypes.number,
    walkingDurationInSeconds: PropTypes.number,
    drivingDistanceInMiles: PropTypes.number,
    drivingDurationInSeconds: PropTypes.number,
});
export const PROPTYPE_CANCELLATION = PropTypes.shape({
    allowedByCustomer: PropTypes.bool.isRequired,
    allowedBySpotheroCustomerService: PropTypes.bool.isRequired,
    minutes: PropTypes.number,
});
export const proptypeRedemptionInstruction = {
    id: PropTypes.string.isRequired,
    illustration: PROPTYPE_CLOUDINARY_IMAGE,
    text: PropTypes.string.isRequired,
};
export const PROPTYPE_REDEMPTION_INSTRUCTION = PropTypes.shape(proptypeRedemptionInstruction);
const PROPTYPE_DAY_OF_WEEK = PropTypes.oneOf([
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun',
]);
export const PROPTYPE_HOURS_OF_OPERATION = PropTypes.shape({
    alwaysOpen: PropTypes.bool.isRequired,
    periods: PropTypes.arrayOf(PropTypes.shape({
        hoursType: PropTypes.oneOf(['open', 'closed']).isRequired,
        startDayOfWeek: PROPTYPE_DAY_OF_WEEK.isRequired,
        endDayOfWeek: PROPTYPE_DAY_OF_WEEK.isRequired,
        startTime: PropTypes.string.isRequired,
        endTime: PropTypes.string.isRequired, // HH:MM:SS
    }).isRequired).isRequired,
});
export const PROPTYPE_VISUAL_FLAGS = PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
});
