import APIUtils from '@spothero/utils/api';
const include = 'default_image,can_have_events,has_upcoming_events,google_places,deep_links,parent_url,meta_title,destination,monthly_relative_url,desktop_zoom_level,mobile_zoom_level,monthly_desktop_zoom_level,monthly_mobile_zoom_level,default_map_zoom_level,airport,hide_event_modal';
// Temporary fix to double the timeout until BE apis are optimized
const timeout = 10000;
const DestinationAPI = {
    get(id) {
        return APIUtils.get(`destinations/${id}/`, { include }, { timeout });
    },
    // https://spothero.com/api/v1/docs/endpoints/#!/Destinations/get_cities_city_slug_destinations_dest_slug
    getBySlug(city, destination) {
        return APIUtils.get(`cities/${city}/destinations/${destination}/`, {
            include,
        }, { timeout });
    },
};
export default DestinationAPI;
