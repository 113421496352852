import { APIRegistry } from '@spothero/utils/api';
import ObjectUtils from '@spothero/utils/object';
import ErrorUtils from 'utils/error-utils';
import { filterSearchResponse } from 'api/search-v2/utils/filter-search-response';
import { transformEventPackageSearchResults } from './transformers';
import { setBackendExperiments } from 'utils/session-storage/backend-experiment';
/**
 * Get facilities by package id using v2 endpoint
 *
 * @function getFacilitiesByPackageV2
 * @see {@link https://api.spothero.com/v2/docs#tag/Search/operation/searchEventPackageParking|Documentation}
 */
export const getFacilitiesByPackageV2 = async (id, params, formatOptions) => {
    const api = APIRegistry.get('craigSearch');
    try {
        const response = await api.get(`search/event-packages/${id}`, params);
        try {
            setBackendExperiments(response.data.experiment);
            const packageData = ObjectUtils.camelizeKeys(response.data);
            const { results } = packageData;
            let transformedResults = results.map(result => transformEventPackageSearchResults({
                eventPackageResult: result,
                eventPackageId: id,
            }));
            if (formatOptions?.filterSpot) {
                transformedResults = filterSearchResponse(transformedResults, formatOptions.filterSpot);
            }
            return transformedResults;
        }
        catch (error) {
            return Promise.reject(new Error('Response shape unexpected.'));
        }
    }
    catch (err) {
        return ErrorUtils.transformAndReject(err);
    }
};
