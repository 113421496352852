import CheckoutUtils from 'utils/checkout-utils';
import UserUtils from 'utils/user-utils';
const buildPayment = ({ user, selectedCreditCard, paypal, purchaseForCustomer, paymentRequired, paymentOverlay, stripePaymentType, stripeToken, setNewCardAsDefault, promoCode, saveCardOverride = false, usePromoEnabled = true, useCreditEnabled = true, purchaseForCustomerDescription, initialReservationId, }) => {
    const isUserSavingNewCard = user.status === UserUtils.AUTH_STATE.USER &&
        (selectedCreditCard === 'new' || paypal) &&
        !purchaseForCustomer &&
        paymentRequired;
    const isPaymentRequired = selectedCreditCard === 'admin' ? false : paymentRequired;
    let finalStripeToken = paypal ? null : stripeToken;
    if (paymentOverlay) {
        // purchase was made using the Apple/Google Pay payment overlay
        finalStripeToken = paymentOverlay.token.id;
    }
    const cardInfo = {};
    if (finalStripeToken) {
        cardInfo.stripe_token = finalStripeToken;
        if (stripePaymentType && stripePaymentType !== 'cc') {
            cardInfo.stripe_card_type = stripePaymentType;
            // Android/Google Pay requires these two fields on the BE for whatever reason...
            if (stripePaymentType ===
                CheckoutUtils.PAYMENT_REQUEST_TYPES.GOOGLE_PAY) {
                cardInfo.google_instant_user_email = user.email;
                cardInfo.google_instant_card_info = 'Google Pay Card';
            }
        }
        else {
            cardInfo.default_card =
                user.status === UserUtils.AUTH_STATE.USER && user.justCreated
                    ? true
                    : setNewCardAsDefault;
        }
    }
    else if (paypal) {
        cardInfo.braintree_payment_method_nonce = paypal.nonce;
        cardInfo.paypal_email = paypal.email;
        cardInfo.payment_processor = 'braintree';
    }
    else if (isPaymentRequired) {
        cardInfo.card_external_id = selectedCreditCard;
    }
    // save new card
    if (isUserSavingNewCard || saveCardOverride) {
        cardInfo.save_card = true;
    }
    const heroAttributes = {};
    if (purchaseForCustomer) {
        heroAttributes.purchase_for_customer = true;
        heroAttributes.purchase_for_customer_description = purchaseForCustomerDescription;
        if (initialReservationId) {
            heroAttributes.purchase_for_customer_reference_id = initialReservationId;
        }
    }
    return {
        ...heroAttributes,
        ...(promoCode && usePromoEnabled && { promocode: promoCode.code }),
        use_spothero_credit: user.status === UserUtils.AUTH_STATE.USER && useCreditEnabled,
        cards: [cardInfo],
    };
};
export default buildPayment;
