import { handleHours } from 'api/search-v2/utils/format-v2-to-v1-common';
/**
 * formats v2 hours of operation to V1
 * No tests written as of 8-28
 *
 * @function formatHoursOfOperationToV1
 */
export const formatHoursOfOperationToV1 = (hoursOfOperation = {}, addHoursType = false) => {
    const formattedHoursOfOperation = {
        periods: hoursOfOperation?.periods?.length
            ? hoursOfOperation?.periods?.map(period => ({
                startDayOfWeek: period?.first_day,
                endDayOfWeek: period?.last_day,
                startTime: period?.start_time,
                endTime: period?.end_time,
                ...(addHoursType && { hoursType: period?.hours_type || '' }),
            }))
            : [],
        text: hoursOfOperation?.text,
        alwaysOpen: hoursOfOperation?.always_open,
    };
    return handleHours(formattedHoursOfOperation);
};
