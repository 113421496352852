import APIUtils, { APIRegistry } from '@spothero/utils/api';
import UserUtils from 'utils/user-utils';
const AuthenticationAPI = {
    // DOCS - https://spothero.com/api/v1/docs/endpoints/#!/Users/post_users_login
    login(requestData) {
        return APIUtils.post('users/login/', requestData, {
            headers: { 'SpotHero-Version': '2022-02-22' },
        })
            .then(payload => payload.data.data)
            .then(UserUtils.formatPayload); // TS-migration, not tackling utils yet but so calling this as typed
    },
    // DOCS - https://spothero.com/api/v1/docs/endpoints/#!/Users/post_users_logout
    logout() {
        // TS-migration - response doesn't super matter unless it fails but also only local so I dont super care
        return APIUtils.post('users/logout/');
    },
    // TS-migration - unknown return due to being void
    setCookie() {
        return APIRegistry.get('auth').post('/auth/set-cookie/');
    },
};
export default AuthenticationAPI;
