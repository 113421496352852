/* eslint-disable jsdoc/require-property-description */

import ErrorUtils from 'utils/error-utils';
import {
    handleCancellation,
    handleIllustrations,
    handleHours,
    formatRedemptionInstructions,
} from 'api/search-v2/utils/format-v2-to-v1-common';
import {
    RateMonthly,
    Availability,
    SingleFacilityResponseMonthly,
} from '../../types';
import {formatAccessHours} from 'utils/spot-format-access-hours';
import FormatUtils from 'utils/format';
import {FACILITY_TYPES} from 'utils/types/facility';
const {currencySymbol} = FormatUtils;
import Config from '@/config/index';
import {formatHoursOfOperation} from 'api/search-v2/utils';

/**
 * Transform just rate array from v2 to v1 format
 *
 * @todo APIv1 data schema
 * @param {object} arg - Top Level Param
 * @param {RateMonthly} arg.rate - Single Rate
 * @param {Availability} arg.availability - Availability information as needed
 * @param {string[]} arg.restrictions - Availability information as needed
 * @returns {any} - V1 Formatted Rate
 */
export const transformRateV2ToRateV1Monthly = (arg = {}) => {
    const {rate = {}, availability = {}, restrictions = []} = arg;
    const unavailableReasons = availability?.unavailableReasons || [];
    const priceBreakdown = rate?.priceBreakdown || [];
    const amenities = rate?.amenities || [];
    const parsedRate = parseInt(rate?.rateId, 10);
    const {
        starts,
        ends,
        contract,
        monthlyReservationType,
        postPurchaseInstructions,
        inOutPrivileges,
        redemptionInstructions,
        recurrable,
        description,
        termsAndConditionsUrl,
        parkingPass,
        activationFee,
        parkingDelayDays,
        separateApplicationType,
        gettingHere, // 7-17-23 NOT IN V2 CURRENTLY - Getting there component
        introductory = {}, // 7-17-23 NOT IN V2 CURRENTLY - Rate details
        introductoryRates, // 7-17-23 NOT IN V2 CURRENTLY // not used
        newMonthlyRate, // 7-17-23 NOT IN V2 CURRENTLY - things you should know
        spotheroCityTitle,
        displayHoursComment, // 7-17-23 NOT IN V2 CURRENTLY - Believe not needed
        oversizeFee, // 7-17-23 NOT IN V2 CURRENTLY- Believe not needed details
        vehicleSize, // 7-17-23 NOT IN V2 CURRENTLY- Believe not needed details
        rateType, // 7-17-23 NOT IN V2 CURRENTLY- Believe not needed details
        accessHours,
        hidden,
        fullUrl,
        totalPrice,
        title,
        advertisedPrice,
        redemptionType,
        startDateOptions,
        pricingDetails,
    } = rate;
    const {required, durationMonths, terminationFee, cancellationRequirements} =
        contract || {};

    const hoursOfOperations = formatHoursOfOperation(accessHours);
    const access_hours_child_unfinished = handleHours(hoursOfOperations);
    const access_hours_child = formatAccessHours(access_hours_child_unfinished);
    const {
        rate: introRate,
        period,
        afterPeriodPriceOpt,
        afterPeriodPriceAmount,
    } = introductory;

    return {
        rule_type: FACILITY_TYPES.RULE_TYPES.MONTHLY,
        title,
        rule_id: parsedRate,
        fullRule: parsedRate,
        price: totalPrice?.value,
        display_price: advertisedPrice?.value,
        unavailable: !availability?.available,
        unavailable_reason: unavailableReasons[0] || '',
        starts,
        ends,
        fullUrl,
        url: `${Config.siteUrl}${fullUrl}`,
        currency_type: advertisedPrice?.currencyCode?.toLowerCase(),
        currency_symbol: currencySymbol(
            advertisedPrice?.currencyCode?.toLowerCase()
        ),
        // Many components rely on the V2 data format.
        // Therefore, we are tacking on some V2 props like: advertisedPrice, totalPrice, priceBreakdown
        advertisedPrice,
        totalPrice,
        priceBreakdown,
        price_breakdown: {
            items: priceBreakdown.map(
                ({price, type, shortDescription, fullDescription}) => ({
                    price: price.value,
                    type,
                    short_description: shortDescription,
                    full_description: fullDescription,
                })
            ),
            currency: totalPrice?.currencyCode,
            total_price: totalPrice?.value,
        },
        amenities: amenities.map(({type, displayName}, index) => ({
            slug: type,
            name: displayName,
            sort_order: index,
            visible: true,
            icon_url: '',
        })),
        amenities_full: amenities.map(({type, displayName}, index) => ({
            slug: type,
            name: displayName,
            sort_order: index,
            visible: true,
            icon_url: '',
        })), // Just duped - not super concerned
        parking_type: redemptionType,
        hidden, // hydrate down stream
        contract: {
            required,
            period: durationMonths,
            termination_fee: terminationFee?.value,
            cancellation: {
                required: cancellationRequirements?.required,
                period: cancellationRequirements?.durationMonths,
            },
        },
        post_purchase_instructions: postPurchaseInstructions,
        restrictions,
        start_date_restrictions: startDateOptions?.restriction,
        reservation_type: monthlyReservationType,
        reservation_dates: startDateOptions?.choices?.map(
            ({starts: startAlias, ends: endAlias}) => ({
                starts: startAlias,
                ends: endAlias,
            })
        ),
        in_out: {
            allowed: inOutPrivileges?.allowed,
            fee: inOutPrivileges?.fee?.value,
            limit: 1, // 7-17-23 NOT IN V2 CURRENTLY
            limit_type: 'day', // 7-17-23 NOT IN V2 CURRENTLY
        },
        redemption_instructions: redemptionInstructions?.shortTerm?.map(
            formatRedemptionInstructions
        ),
        redemption_instructions_long_term: redemptionInstructions?.longTerm?.map(
            formatRedemptionInstructions
        ),
        recurrable,
        description, // think this one is only one used
        terms_and_conditions: termsAndConditionsUrl,
        parking_pass_type: parkingPass?.type,
        activation_fee: activationFee?.value,
        parking_delay_days: parkingDelayDays,
        separate_application_type: separateApplicationType,
        access_hours: access_hours_child,
        newMonthlyRate,
        spothero_city_title: spotheroCityTitle,
        display_hours_comment: displayHoursComment,
        oversize_fee: oversizeFee,
        new_description: description,
        getting_here: gettingHere,
        introductory: {
            rate: introRate,
            period,
            after_period_price_opt: afterPeriodPriceOpt,
            after_period_price_amount: afterPeriodPriceAmount,
        },
        introductory_rates: introductoryRates,
        vehicle_size: vehicleSize,
        rate_type: rateType,
        pricingDetails: pricingDetails,
    };
};

/**
 * This is to format V2 Facility response to V1 Rates Response
 *
 * @param {SingleFacilityResponseMonthly} v2Response - This is the response from the API
 * @returns {*} - This is the transformed
 */
export const transformV2SingleFacilityToV1RatesMonthly = (v2Response = {}) => {
    const {
        spotId,
        title,
        distance,
        availability,
        requirements,
        selectedRate,
        rating,
        addresses = [],
        images = [],
        rates = [],
        restrictions,
        hoursOfOperation,
        navigationTip,
        facilityType,
        visualFlags,
        allAmenities,
        parkingTypes,
        redemptionInstructions,
        operatorDisplayName,
        supportedFeeTypes,
        cancellation,
        highestPrice,
        lowestPrice,
        slug,
        status,
        operatorId,
        allowUpdatingReservationAfterEntry,
        oversizeFeesChargedOnsite,
        barcodeType,
        commuterCardEligible,
    } = v2Response;

    const primeAddress = addresses[0] || {};
    const currencyCode = selectedRate?.advertisedPrice?.currencyCode?.toLowerCase();
    const hours_of_operation = handleHours(hoursOfOperation);
    const access_hours = formatAccessHours(hours_of_operation);
    const topUrl = `${Config.siteUrl}/checkout/${spotId}/${slug}/`;
    const url = selectedRate?.fullUrl;

    try {
        const valueToReturn = {
            parking_spot_id: spotId?.toString(),
            title,
            distance,
            latitude: primeAddress?.latitude,
            longitude: primeAddress?.longitude,
            lowest_price: 0,
            highest_price: 0,
            lowest_monthly_price: lowestPrice?.value,
            highest_monthly_price: highestPrice?.value,

            default_image_url: images[0]?.url,
            hourly_rates: [], // Not used monthly
            monthly_rates: rates.map(rate =>
                transformRateV2ToRateV1Monthly({
                    rate,
                    availability,
                    redemptionInstructions,
                    restrictions,
                })
            ),
            bulk_event_rates: [], // Not used for monthly
            bulkPowerBookingRates: [], // Not used for monthly
            license_plate_required: requirements?.licensePlate,
            phone_number_required: requirements?.phoneNumber,
            timezone: primeAddress?.timeZone,
            spot_url: topUrl,
            wd_latitude: primeAddress?.latitude,
            wd_longitude: primeAddress?.longitude,
            currency_type: currencyCode,
            currency_symbol: currencySymbol(currencyCode),
            rating_info: {
                number_of_ratings: rating?.count,
                star_rating: rating?.average,
            },
            facility: {
                id: spotId,
                parking_spot_id: spotId,
                title,
                description: '', // No longer used
                status,
                slug: '', // No longer used
                street_address: primeAddress.streetAddress,
                city: primeAddress.city,
                state: primeAddress.state,
                zipcode: primeAddress.postalCode,
                latitude: primeAddress.latitude,
                longitude: primeAddress.longitude,
                country: primeAddress.country,
                company_id: null, // No longer used
                timezone: primeAddress.timeZone,
                display_price_on_receipt: false, // No longer used
                height_restriction: null, // No longer used
                height_restriction_description: '', // No longer used - moved to restrictions array
                barcode_type: barcodeType,
                post_purchase_instructions: '', // perfer redemptionInstructions
                restrictions,
                hours_of_operation,
                getting_here: navigationTip,
                addresses: addresses.map(address => ({
                    ...address,
                    street_address: address.streetAddress,
                })),
                redemption_instructions: [], // Dont use facility redemption, use rate one
                mobile_enabled: true, // Not used, removable
                support_description: '', // Not used, removable
                facility_type: {
                    slug: facilityType,
                    display_name: facilityType, // just show slug for now as unused
                },
                cancellation_allowed: handleCancellation(cancellation),
                cancellation_minutes: cancellation?.minutes,
                is_scan_to_pay: false, // Unused on monthly, check on scan2pay
                visual_flags: visualFlags,
                operator_display_name: operatorDisplayName,
                amenities_full: allAmenities.map(
                    ({type, displayName}, index) => ({
                        slug: type,
                        name: displayName,
                        sort_order: index,
                        visible: true,
                        icon_url: '', // Not in the amenity array, removable?
                    })
                ),
                images: images.map((image, i) => {
                    const {url: imageUrl, urlTemplate} = image;
                    const [, version, id] = handleIllustrations(imageUrl);

                    return {
                        id,
                        version,
                        center_x: 375, // not in new response, removable?
                        center_y: 250, // not in new response, removable?
                        order: i,
                        url_template: urlTemplate,
                    };
                }),
                facility_url: topUrl,
                rating_info: {
                    number_of_ratings: rating.count,
                    star_rating: rating.average,
                },
                vehicle_size: 'not_applicable',
                transient_available: parkingTypes.includes('transient'),
                monthly_available: parkingTypes.includes('monthly'),
                supported_fee_types: supportedFeeTypes,
                operator_id: operatorId,
                online_commuter_rate: false,
                online_commuter_rate_description: '',
                online_commuter_rate_enter_start: null,
                online_commuter_rate_enter_end: null,
                access_hours_formatted: access_hours,
                freePark: false,
                parking_type: selectedRate?.redemptionType,
                parking_pass_type: selectedRate?.parkingPass?.type,
                allowUpdatingReservationAfterEntry: allowUpdatingReservationAfterEntry,
                oversize_vehicle_type: null,
                oversize_description: null,
                oversize_fees_charged_onsite: oversizeFeesChargedOnsite,
            },
            spotId,
            event_package: {},
            isFavorite: false,
            hasTravelDistanceUpdated: false, // downstream used
            hidden: false, // downstream used
            selectedRate: transformRateV2ToRateV1Monthly({
                selectedRate,
                availability,
                redemptionInstructions,
                restrictions,
            }),
            url,
            available_monthly_inventory: null,
            commuter_card_eligible: commuterCardEligible,
            publish_to_mobile: true, // Not present in v2 response
            availablility: availability,
        };

        return valueToReturn;
    } catch (e) {
        console.error('transformV2SingleFacilityToV1RatesTransient ERROR', e);
        ErrorUtils.sendSentryMessage({
            error: e,
            customErrorMessage:
                'transformV2SingleFacilityToV1RatesTransient ERROR',
        });
    }

    return {};
};
