import dayjs from 'utils/dayjs-timezone';
import Config from '@/config/index';
const buildMonthlyDetails = ({ monthlyStarts, contractFullName, vehicleColor, forceRedundantMonthly, selectedRate, timezone, }) => {
    const rateInfo = {
        monthly_start_date: dayjs
            .tz(monthlyStarts, timezone)
            .format(Config.apiDateFormat),
        rule: selectedRate.fullRule,
    };
    if (contractFullName) {
        rateInfo.contract_full_name = contractFullName;
    }
    if (vehicleColor) {
        rateInfo.vehicle_color = vehicleColor;
    }
    rateInfo.accept_terms_and_conditions = true;
    // Force redundant non-recurring monthly reservation?
    if (forceRedundantMonthly) {
        rateInfo.force_redundant_monthly = true;
    }
    return rateInfo;
};
export default buildMonthlyDetails;
