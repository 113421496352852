import { APIRegistry } from '@spothero/utils/api';
import ErrorUtils from 'utils/error-utils';
import { transformEventPackageFacilityDetail, transformV2toV1EventPackageDetailsResponse, } from './transformers';
import SearchTracking from 'utils/search-tracking';
import { getWebExperimentCookie } from 'utils/web-experiment';
import { setBackendExperiments } from 'utils/session-storage/backend-experiment';
/**
 * Get data for a given facility when its part of a given event package using v2 endpoint
 *
 * @see {@link https://api.spothero.com/v2/docs#tag/Search/operation/searchEventPackageFacilityParking|Documentation}
 */
export const getEventPackageFacilityDetailV2 = async ({ eventPackageId, facilityId, params = {}, 
// Spot Details uses different transformed results whereas
// Checkout uses raw v1 response and has its own transformation
// Ideal if both the pages uses similar transformed object.
returnRawV1Data = false, }) => {
    const api = APIRegistry.get('craigSearch');
    const { sessionUUID, searchUUID, actionUUID } = SearchTracking.getValues();
    const fingerprint = getWebExperimentCookie();
    try {
        const response = await api.get(`search/event-packages/${eventPackageId}/${facilityId}`, {
            session_id: sessionUUID,
            search_id: searchUUID,
            action_id: actionUUID,
            fingerprint,
            ...params,
        }, {
            headers: {
                'SpotHero-Version': '2024-04-01',
            },
        });
        setBackendExperiments(response.data.experiment);
        if (returnRawV1Data) {
            return transformV2toV1EventPackageDetailsResponse(response?.data);
        }
        try {
            return transformEventPackageFacilityDetail({
                result: response?.data,
            });
        }
        catch (error) {
            return Promise.reject(new Error('Response shape unexpected.'));
        }
    }
    catch (err) {
        return ErrorUtils.transformAndReject(err);
    }
};
