import dayjs from './dayjs-timezone';
import Config from '@/config/index';
/**
 * This function checks for the local time offset being present on the date time string to figure out if you need to use it on the .tz function for dayjs
 * Regex in function validates if a offset is present to decide if its needed or not.
 *
 * @function offsetPresent
 * @param {string} string - A date time string that is ISO-8601 compliant.
 * @returns {boolean} The formatted date time string that the rest of the application expects.
 */
export const offsetPresent = (string) => {
    const bool = /(Z|[+-](\d{2}:?\d{2}$)|([+-]\d{2}$))/.test(string);
    return bool;
};
/**
 * Date/time strings are returned from V2 Search in a different format from V1.
 * (They contain seconds and have time zones applied.)
 * This formats a date time string in the context of a provided time zone
 * so we can limit the downstream effects inside this application.
 *
 * @function formatDateTime
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientParking|Documentation}
 * @param {string} dateTimeString - A date time string that is ISO-8601 compliant.
 * @param {string} timezone - A time zone string, e.g. America/Chicago.
 * @param {string} outputFormat - Format for the output; defaults to YYYY-MM-DDTHH:mm.
 * @returns {string} The formatted date time string that the rest of the application expects.
 */
export const formatDateTime = (dateTimeString, timezone, // tests were passing in undefined timezone there had to be a case handle for it
outputFormat = Config.apiDateTimeFormat) => {
    if (timezone) {
        return dayjs(dateTimeString)
            .tz(timezone, !offsetPresent(dateTimeString))
            .format(outputFormat);
    }
    else {
        return dayjs(dateTimeString)
            .format(outputFormat);
    }
};
