import { OptimizelyDecideOption, useDecision } from '@optimizely/react-sdk';
export const OPTIMIZELY_FEATURE_ON = 'on';
export const OPTIMIZELY_FEATURE_OFF = 'off';
export const OPTIMIZELY_VARIATION_ORIGINAL = 'original';
export const OPTIMIZELY_VARIATION_REFRESHED = 'refreshed';
export function useFeatureVariation(featureKey, { generateImpression } = {
    generateImpression: false,
}) {
    const decideOptions = [
        OptimizelyDecideOption.ENABLED_FLAGS_ONLY,
    ];
    if (!generateImpression) {
        decideOptions.push(OptimizelyDecideOption.DISABLE_DECISION_EVENT);
    }
    const [decision] = useDecision(featureKey, {
        decideOptions,
    });
    return decision.enabled ? decision.variationKey : OPTIMIZELY_FEATURE_OFF;
}
/**
 * Returns the feature variation for the given feature key.
 * This function is for use in Class components.
 */
export const getFeatureVariation = (optimizely, featureKey, { generateImpression } = {
    generateImpression: false,
}) => {
    if (typeof window === 'undefined') {
        return OPTIMIZELY_FEATURE_OFF;
    }
    const decideOptions = [
        OptimizelyDecideOption.ENABLED_FLAGS_ONLY,
    ];
    if (!generateImpression) {
        decideOptions.push(OptimizelyDecideOption.DISABLE_DECISION_EVENT);
    }
    const decision = optimizely.decide(featureKey, decideOptions);
    if (decision.enabled) {
        return decision.variationKey;
    }
    return OPTIMIZELY_FEATURE_OFF;
};
