import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalContent} from '@spothero/ui-backlog';
import {Button} from '@spothero/ui';

// MR - Used one spot - move to colocate.  Move?

const GDPRModal = ({onHidden, countryCode}) => {
    const location = countryCode
        ? `country code ${countryCode}`
        : 'your country';

    return (
        <Modal
            className="GDPRModal"
            onHidden={onHidden}
            closeOnShimClick={false}
            showClose={false}
            animateOnShow={false}
        >
            <ModalContent>
                <h3>SpotHero is not currently available in {location}.</h3>
                <p>
                    We don&apos;t have parking in your area, but we do have over
                    5,000 garages across the US and Canada if you&apos;re ever
                    in need of a spot!
                </p>
                <Button
                    marginTop={4}
                    fontSize="sm"
                    variant="primary"
                    onClick={onHidden}
                >
                    Continue Anyway
                </Button>
            </ModalContent>
        </Modal>
    );
};

GDPRModal.propTypes = {
    onHidden: PropTypes.func.isRequired,
    countryCode: PropTypes.string,
};

export default GDPRModal;
