import {v4 as uuidV4} from 'uuid';
import {getEvents} from 'api/event-packages';
import {Page} from 'utils/page-utils';
import {initialState as searchRequestInitialState} from 'store/search-request/search-request-reducer';
import {initialState as spotsInitialState} from 'store/spots/spots-reducer';

const getInitialState = async ({
    state,
    route: {
        location: {search},
    },
}) => {
    // get event package ID and selected spot ID (if it exists) from URL
    const urlParams = new URLSearchParams(search);
    const eventPackageId = urlParams.get('id');
    const querySpotId = urlParams.get('spot-id');
    const filterSpot = urlParams.get('filterSpot');
    // use event package ID to find event packages at this venue
    const {destination, eventPackage} = await getEvents(eventPackageId);
    const {
        latitude,
        longitude,
        state: destinationState,
        countryCode,
    } = destination;
    // city slice (state, country_code) is used by the Total Price toggle hooks
    const city = {
        data: {
            state: destinationState,
            country_code: countryCode,
        },
    };

    return {
        ...state,
        city,
        spots: {
            ...spotsInitialState,
            selectedSpotId: querySpotId,
        },
        searchRequest: {
            ...searchRequestInitialState,
            latitude,
            longitude,
            id: uuidV4(),
            pageType: Page.EVENT_PACKAGES_SEARCH,
            filterSpot,
        },
        eventPackage: {
            data: {
                ...eventPackage,
                venue: destination,
            },
        },
    };
};

export default getInitialState;
