import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Loader } from '@spothero/ui-backlog';
import { Box, Button, Flex, Alert, Text } from '@spothero/ui';
import IconExclamationTriangle from '@spothero/icons/exclamation-triangle-filled';
import { Price } from 'common/facility-summary/price';
const SpotDetailsReservationSummary = ({ reservationLabel, priceLabel, isMobile, isLoadingRates, displayPrice, currencyType, reservationDateTimeFormatted, isReservationExtended, showSpotUnavailable, onBookSpotClick, isSticky, isShowing, showConfirmationButton, showEditTimesButton, onEditButtonClick, showPrice, inOutReservationSummary, isNonRefundable, reservationExtensionEnabled, priceWithoutDiscount, onShowRateDetailsClick, }) => {
    const containerClasses = classNames([
        'SpotDetailsReservationSummary',
        { 'SpotDetailsReservationSummary-sticky': isSticky },
        { showing: isSticky && isShowing },
    ]);
    return (<div data-testid="SpotDetailsReservationSummary-Container" className={containerClasses}>
            <div className="SpotDetailsReservationSummary-reservation-and-price">
                <div className="SpotDetailsReservationSummary-reservation">
                    <Flex marginBottom="3px" alignItems="baseline">
                        <h3 className="SpotDetailsReservationSummary-reservation-label">
                            {reservationLabel}
                        </h3>
                        {showEditTimesButton && (<Button marginLeft={2} fontSize="sm" variant="tertiary" fontFamily="body" onClick={onEditButtonClick}>
                                Change
                            </Button>)}
                    </Flex>
                    <Box as="span" className="SpotDetailsReservationSummary-reservation-date-times">
                        {reservationDateTimeFormatted}{' '}
                        {inOutReservationSummary && inOutReservationSummary()}
                        {isLoadingRates && (<Loader data-testid="SpotDetailsReservationSummary-Loader" size={isMobile ? 14 : 16} borderWidth={3}/>)}
                    </Box>
                </div>
                {(showPrice || showSpotUnavailable) && (<Price parentHTMLOptionsOverride={{
                className: 'SpotDetailsReservationSummary-price-container',
                'data-testid': 'SpotDetailsReservationSummary-price-container',
            }} price={{
                value: displayPrice,
                currencyCode: currencyType,
            }} priceLabel={priceLabel} priceWithoutDiscount={priceWithoutDiscount} variant="lg" onShowPriceBreakdown={onShowRateDetailsClick}/>)}
            </div>
            {!isSticky && isReservationExtended && (<small className="SpotDetailsReservationSummary-auto-extension">
                    Your reservation was extended at no extra charge!
                </small>)}
            {!isSticky && isNonRefundable && (<Box background="gray.light" display="flex" borderRadius={1} py={2} px={3} mt={3}>
                    <IconExclamationTriangle color="#537495" width="1.5rem"/>
                    <Box pl={2}>
                        <Text fontSize="sm" fontWeight="semibold">
                            Cancellation Restriction
                        </Text>
                        <Text fontSize="sm" fontWeight="normal">
                            This reservation is non-refundable.
                        </Text>
                    </Box>
                </Box>)}
            {reservationExtensionEnabled === false && (<Alert status="neutral" title="Restrictions Apply" description="Shortening or extending your reservation is not allowed at this facility once booked." showIcon icon={IconExclamationTriangle} marginTop={3}/>)}
            {showSpotUnavailable ? (<Alert marginTop={1.5} marginBottom={2.5} status="error" description="This spot is not available at the selected times. Please
                    adjust your times."/>) : (showConfirmationButton && (<Button variant="primary" width={{ base: 'full', tablet: 64 }} className="SpotDetailsReservationSummary-book-now Button-primary-booking-flow" onClick={onBookSpotClick} data-testid="spot-details-reservation-summary-book-now">
                        Book Now
                    </Button>))}
        </div>);
};
SpotDetailsReservationSummary.propTypes = {
    reservationLabel: PropTypes.string,
    priceLabel: PropTypes.string || PropTypes.object,
    isMobile: PropTypes.bool,
    isLoadingRates: PropTypes.bool,
    displayPrice: PropTypes.number,
    currencyType: PropTypes.string,
    reservationDateTimeFormatted: PropTypes.string || PropTypes.object,
    isReservationExtended: PropTypes.bool,
    showSpotUnavailable: PropTypes.bool,
    onBookSpotClick: PropTypes.func.isRequired,
    isSticky: PropTypes.bool,
    isShowing: PropTypes.bool,
    showConfirmationButton: PropTypes.bool,
    showEditTimesButton: PropTypes.bool,
    onEditButtonClick: PropTypes.func,
    showPrice: PropTypes.bool,
    inOutReservationSummary: PropTypes.func,
    reservationExtensionEnabled: PropTypes.bool,
    isNonRefundable: PropTypes.bool,
    priceWithoutDiscount: PropTypes.object,
};
SpotDetailsReservationSummary.defaultProps = {
    reservationLabel: 'Parking Reservation',
    priceLabel: '',
    isReservationExtended: false,
    showConfirmationButton: true,
    showEditTimesButton: false,
    showPrice: true,
    isNonRefundable: false,
    priceWithoutDiscount: {},
};
export default SpotDetailsReservationSummary;
