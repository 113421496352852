export const ROUTER_HISTORY_CALLED = 'ROUTER_HISTORY_CALLED';
export const ROUTER_DATA_LOAD_FULFILLED = 'ROUTER_DATA_LOAD_FULFILLED';
const updateLocation = (method) => (url, state = {}) => ({
    type: ROUTER_HISTORY_CALLED,
    payload: {
        method,
        url,
        state,
    },
});
export const push = updateLocation('push');
export const replace = updateLocation('replace');
export const pop = updateLocation('pop');
export const go = updateLocation('go');
export const goBack = updateLocation('goBack');
export const goForward = updateLocation('goForward');
export const dataLoadFulfilled = (cleanedData) => ({
    type: ROUTER_DATA_LOAD_FULFILLED,
    payload: cleanedData,
});
