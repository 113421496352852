import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Accordion,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    Box,
    Text,
    Flex,
} from '@spothero/ui';
import {connect} from 'react-redux';

export const SpotDetailsMonthlyFooterPanel = ({
    sectionTitle,
    instructions,
    monthlyPhoneNumber,
    monthlyPhoneNumberFriendly,
    hours,
    className,
}) => {
    return (
        <Box marginBottom="14" marginTop="9" className={className}>
            <Accordion allowToggle allowMultiple>
                {instructions && Boolean(instructions.length) && (
                    <AccordionItem>
                        <AccordionButton
                            _focus={{boxShadow: 'none'}}
                            _hover={{boxShadow: 'outline'}}
                        >
                            <Flex width="100%" justifyContent="space-between">
                                <Text fontSize="sm" fontWeight="semibold">
                                    {sectionTitle}
                                </Text>
                                <AccordionIcon />
                            </Flex>
                        </AccordionButton>
                        <AccordionPanel className="HowToRedeem">
                            <Box>
                                <ol>
                                    {instructions.map((instruction, i) => {
                                        const classes = classNames(
                                            {
                                                'no-illustration': !instruction.illustration_id,
                                            },
                                            {
                                                'no-number':
                                                    instructions.length < 2,
                                            }
                                        );

                                        return (
                                            <li className={classes} key={i}>
                                                <div className="RedemptionInstruction">
                                                    <div className="RedemptionInstructionRow">
                                                        <div className="RedemptionInstructionText">
                                                            <span
                                                                className="RedemptionInstructionText-content"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: instruction.text.replace(
                                                                        '\n',
                                                                        '<br>'
                                                                    ),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ol>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                )}
                <AccordionItem>
                    <Box>
                        <AccordionButton
                            _focus={{boxShadow: 'none'}}
                            _hover={{boxShadow: 'outline'}}
                        >
                            <Flex width="100%" justifyContent="space-between">
                                <Text fontSize="sm" fontWeight="semibold">
                                    365-Day Customer Support
                                </Text>
                                <AccordionIcon />
                            </Flex>
                        </AccordionButton>
                    </Box>
                    <AccordionPanel>
                        <Box paddingTop="5">
                            <Text marginBottom="2.5" fontSize="sm">
                                SpotHero has your back. If you have any issues
                                while parking, please call our Customer Heroes
                                immediately at{' '}
                                <a href={`tel:${monthlyPhoneNumber}`}>
                                    {monthlyPhoneNumberFriendly}
                                </a>
                                . We&apos;re here 365 days a year, {hours}.
                            </Text>
                            <Text fontSize="sm" marginBottom="2.5">
                                For non-urgent issues shoot us an email at{' '}
                                <a href="mailto:support@spothero.com">
                                    support@spothero.com
                                </a>
                                . We&apos;ll get back you within 24 hours.
                            </Text>
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    );
};

SpotDetailsMonthlyFooterPanel.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    instructions: PropTypes.arrayOf(
        PropTypes.shape({
            illustration_id: PropTypes.string, // eslint-disable-line camelcase
            illustration_version: PropTypes.string, // eslint-disable-line camelcase
            text: PropTypes.string,
            id: PropTypes.number,
            position: PropTypes.number,
        })
    ).isRequired,
    monthlyPhoneNumber: PropTypes.string.isRequired,
    monthlyPhoneNumberFriendly: PropTypes.string.isRequired,
    hours: PropTypes.string.isRequired,
    className: PropTypes.string,
};

const mapStateToProps = state => {
    const {
        city: {
            data: {
                monthly_phone_number: monthlyPhoneNumber,
                monthly_phone_number_friendly: monthlyPhoneNumberFriendly,
                weekday_hours: hours,
            },
        },
    } = state;

    return {
        monthlyPhoneNumber,
        monthlyPhoneNumberFriendly,
        hours,
    };
};

export default connect(mapStateToProps)(SpotDetailsMonthlyFooterPanel);
