import {fetchCleanedData} from 'router/router-utils';
import {Page} from 'utils/page-utils';

const getInitialState = async ({state: initialState, route}) => {
    const state = await fetchCleanedData({
        state: initialState,
        route,
    });

    state.searchRequest.pageType = Page.FACILITY;

    return state;
};

export default getInitialState;
