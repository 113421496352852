import pick from 'lodash/pick';
import APIUtils from '@spothero/utils/api';
import UrlUtils from '@spothero/utils/url';
import CheckoutUtils from 'utils/checkout-utils';
import ErrorUtils from 'utils/error-utils';
import SearchUtils from 'utils/search-utils';
/**
 * Object containing all Search V1 API methods
 *
 * @property {cleanParams} cleanParams - Clean params for search
 * @module api/search
 */
const SearchAPI = {
    /**
     * Sets a value in the selected storage type.
     *
     * @static
     * @function cleanParams
     * @param {object} data - The data to pass to the endpoint.
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Search_Params/get_search_params|Documentation}
     * @example
     * SearchAPI.cleanParams({latitude: 41.8808377, longitude: -87.6242212, ...etc});
     * SearchAPI.cleanParams({url: 'https://spothero.com/san-jose-parking/60-delmas-ave?starts=2018-02-12T10%3A00%26ends=2018-02-12T13%3A00%26rid=3058'});
     * @returns {object} The response data.
     */
    cleanParams(data) {
        const urlParams = UrlUtils.parseParams(data.url);
        const adParams = pick(urlParams, [
            'gclid',
            'referrer',
            'sha_affiliate',
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_term',
            'utm_content',
        ]);
        return APIUtils.get('/search-params/', data)
            .then(response => {
            const responseData = response.data.data;
            const PageUtils = responseData.page_info.page_type === 'checkout'
                ? CheckoutUtils
                : SearchUtils;
            response.data.data.search_url = PageUtils.updateRoute({
                currentUrl: responseData.search_url,
                searchParams: responseData,
                adParams,
            });
            return Promise.resolve(response);
        })
            .catch(err => ErrorUtils.transformAndReject(err));
    },
};
export default SearchAPI;
