import dayjs from 'utils/dayjs-timezone';
import Config from 'config/index';
import { PaymentOverlayCompletionStatuses, } from '../types';
import ErrorUtils from 'utils/error-utils';
export const handlePowerBookingError = ({ powerBookingErrors, allReservationsFailed, paymentOverlay, onError, }) => {
    if (powerBookingErrors?.length) {
        powerBookingErrors?.forEach(error => {
            // We want to log code failures here, not network failures
            // Send sentry error for every reservation failed. This is to log error code which might be different for each reservation
            ErrorUtils.sendSentryMessage({
                error,
                customErrorMessage: 'Reservation Failed of type Power Booking',
            });
        });
        // If all reservations failed, then dispatch checkout failed event
        if (allReservationsFailed) {
            const defaultError = [
                {
                    message: 'An error occurred while reserving your parking spot. Please refresh the page and try again.',
                },
            ];
            // show error in the Apple/Google Pay payment overlay so that it doesn't look like it keeps trying to process
            if (paymentOverlay) {
                paymentOverlay.complete(PaymentOverlayCompletionStatuses.FAIL);
            }
            onError(defaultError, false);
        }
    }
};
const handlePowerBookingResponse = ({ setFailedPeriods, purchases, spot, rentalItems, onError, paymentOverlay, }) => {
    const powerBookingReservationsIds = [];
    const failedPowerBookingPeriods = [];
    let formattedFailedPeriods = [];
    const powerBookingErrors = [];
    let powerBookingReservationDetail = null;
    // Reset failed periods when users are navigating using back button
    setFailedPeriods(failedPowerBookingPeriods);
    const rentalPurchases = purchases?.filter(item => item?.type === 'rental');
    rentalPurchases?.forEach(purchaseItem => {
        // collect the rentalIds when success for segment
        if (purchaseItem?.status === 'success') {
            powerBookingReservationsIds.push(purchaseItem?.detail?.rental_id);
            // This is to set a default reservation detail when calling onSuccess
            // This is not used for power booking but for backward compatability, this is set
            if (!powerBookingReservationDetail) {
                powerBookingReservationDetail = purchaseItem?.detail;
            }
        }
        // collect the failed periods for segment and to render the failed periods in confirmation/receipt page
        if (purchaseItem?.status === 'failed') {
            // failed periods are retrieved from request by comparing request rental key and order purchase key
            const failedRental = rentalItems.find(({ key }) => key === purchaseItem?.key);
            const failedStart = failedRental?.item_context?.starts;
            const failedEnd = failedRental?.item_context?.ends;
            failedPowerBookingPeriods.push({
                starts: dayjs(failedStart)
                    .tz(spot?.facility?.timezone)
                    .format(Config.apiDateTimeFormat),
                ends: dayjs(failedEnd)
                    .tz(spot?.facility?.timezone)
                    .format(Config.apiDateTimeFormat),
            });
            // collect the error code for segment
            if (purchaseItem?.error_code) {
                powerBookingErrors.push(purchaseItem?.error_code);
            }
        }
    });
    // This is set to show the failed power booking periods in the user flow from checkout to confirmation
    // This will not be shown when user refreshes the page as the state will be lost in old confirmation page only.
    // The new receipt page retrieves this data from query param
    // Format the failed periods to set in the query param
    if (failedPowerBookingPeriods.length) {
        setFailedPeriods(failedPowerBookingPeriods);
        formattedFailedPeriods = failedPowerBookingPeriods.map(failedPeriod => `${failedPeriod.starts};${failedPeriod.ends}`);
    }
    handlePowerBookingError({
        powerBookingErrors,
        allReservationsFailed: powerBookingErrors?.length === rentalItems?.length,
        paymentOverlay,
        onError,
    });
    return {
        powerBookingReservationsIds,
        failedPowerBookingPeriods,
        formattedFailedPeriods,
        powerBookingReservationDetail,
    };
};
export default handlePowerBookingResponse;
