import pick from 'lodash/pick';
import ObjectUtils from '@spothero/utils/object';
import { buildImageTemplate, buildUniqueRateAmenities, formatDistance, formatHoursOfOperation, } from '../../utils';
import { OBJECT_TRANSIENT_FACILITY_SUMMARY, OBJECT_TRANSIENT_FACILITY, } from '@/proptypes/index';
import { formatTransientRate } from './formatTransientRate';
/**
 * Formats data for a single transient facility
 *
 * @function formatTransientFacility
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientFacilityParking|Documentation}
 */
export function formatTransientFacility(data, formatOptions = {}) {
    const { userRequestedStarts, userRequestedEnds, eventId, timezone, rebookId, isFacilitySummary, } = formatOptions;
    const dataFormat = isFacilitySummary
        ? OBJECT_TRANSIENT_FACILITY_SUMMARY
        : OBJECT_TRANSIENT_FACILITY;
    const facilityData = ObjectUtils.camelizeKeys(data);
    const { availability, distance, facility: { common, transient }, rates: ratesResponse, vehicle, } = facilityData;
    const { id: spotId, slug: facilitySlug, hoursOfOperation } = common;
    // only keep properties that have corresponding transient rate prop types
    const rates = ratesResponse.map(({ quote, transient: additionalRateDetails }) => formatTransientRate({
        spotId,
        facilitySlug,
        quote,
        additionalRateDetails,
        userRequestedStarts,
        userRequestedEnds,
        eventId,
        timezone,
        rebookId,
    }));
    // Opted to Alias for ease of testing
    const prePick = {
        isV2: true,
        ...common,
        ...transient,
        images: common.images.map(buildImageTemplate),
        spotId,
        availability,
        distance: formatDistance(distance),
        rates,
        selectedRate: rates[0],
        allAmenities: buildUniqueRateAmenities(rates),
        hoursOfOperation: formatHoursOfOperation(hoursOfOperation),
        hasMultipleRates: rates.length > 1,
        visualFlags: common.visualFlags,
        vehicle,
    };
    // only keep properties that have corresponding transient facility prop types
    return pick(prePick, Object.keys(dataFormat));
}
