export const FACILITY_TYPES = {
    CANCELLATION_ALLOWED_ENUM: {
        NOT_ALLOWED: 'no',
        CUSTOMER: 'customer',
        HERO: 'hero',
    },
    RULE_TYPES: {
        MULTIRATE: 'multirate',
        MONTHLY: 'monthly',
    },
    RATE_EXCEPTIONS: {
        EVENT_RATE: 'event-rate',
    },
    RATE_TYPES: {
        FLEX: 'matrix_flex_rates',
        IQ: 'matrix_iq',
        STANDARD: 'banded',
    },
};
export const FacilityCountries = {
    US: 'US',
    CA: 'CA',
};
