/**
 * Adds In/out restriction to the facility restrictions based on amenities
 *
 * @function formatFacilityRestrictions
 * @param {Array} amenities - rates amenities
 * @param {Array} restrictions - facility restrictions
 * @returns {Array} facility restrictions based on In/out amenity.
 */
export const formatFacilityRestrictions = (amenities = [], restrictions = []) => {
    const IN_OUT_RESTRICTION_TEXT = 'This facility does NOT allow in/out privileges. You CANNOT enter & exit more than once.';
    const inOutRestrictionAllowed = amenities?.some(amenity => amenity?.type === 'in_out');
    const facilityRestrictions = !inOutRestrictionAllowed
        ? [IN_OUT_RESTRICTION_TEXT, ...restrictions]
        : restrictions;
    return facilityRestrictions;
};
