export const STRIPE_LOADED = 'STRIPE_LOADED';
export const RESET_STRIPE = 'RESET_STRIPE';
export const SET_PAYMENT_FLOW = 'SET_PAYMENT_FLOW';
// This allows for multiple payment flows while sharing a single paymentRequest object with a callback.
// In individual StripePaymentRequestButton, can set paymentFlow on click and then check payment flow in callback passed to PaymentRequestSetup.
export const PAYMENT_FLOWS = {
    STANDARD: 'standard',
};
export const stripeLoaded = ({ isGooglePayAvailable, isApplePayAvailable, paymentRequest, }) => {
    return {
        type: STRIPE_LOADED,
        payload: {
            isGooglePayAvailable,
            isApplePayAvailable,
            paymentRequest,
        },
    };
};
export const setPaymentFlow = (paymentFlow) => {
    return {
        type: SET_PAYMENT_FLOW,
        payload: paymentFlow,
    };
};
export const resetStripe = () => {
    return {
        type: RESET_STRIPE,
    };
};
