import {APIRegistry} from '@spothero/utils/api';
import {formatBulkSearchTransientResponse} from '../power-booking-utils';
import { setBackendExperiments } from 'utils/session-storage/backend-experiment';

/**
 * Searches for available bulk search transient parking.
 *
 * @function getBulkSearchTransientFacilities
 * @see {@link https://api.staging.spothero.com/v2/docs#tag/Search/operation/searchBulkTransientParking|Documentation}
 * @param {object} requestData - The parameters to pass to the endpoint.
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @example
 * getBulkSearchTransientFacilities({iata: 'ORD});
 * @returns {Array} The available airport facilities, with data transformation applied.
 */
export function getBulkSearchTransientFacilities(
    requestData,
    params,
    formatOptions
) {
    const api = APIRegistry.get('craigSearch');
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
        params,
    };

    const postBody = JSON.stringify(requestData);

    return api
        .post('search/bulk/transient', postBody, config, false)
        .then(({data: {results, '@next': loadMoreSpotsUrl, experiment}}) => {
            setBackendExperiments(experiment);
            return {
                loadMoreSpotsUrl,
                results: formatBulkSearchTransientResponse(
                    results,
                    formatOptions,
                    requestData.periods
                ),
            }
        });
}

/**
 * Load more available transient parking after initial bulk search for transient parking.
 * Uses the @next URL returned by search api to load next set of spots.
 *
 * @function getBulkSearchTransientFacilitiesLoadMoreSpots
 * @see {@link https://api.staging.spothero.com/v2/docs#tag/Search/operation/searchBulkTransientParking|Documentation}
 * @param {object} params - The parameters to pass to the endpoint.
 * @param {string} params.loadMoreSpotsUrl - The @next URL returned by search api to load next set of spots.
 * @param {object} [formatOptions] - Additional options that don't get passed to the endpoint but are used for data transformation
 * @example
 * getTransientFacilitiesLoadMoreSpots({loadMoreSpotsUrl: 'https://api.staging.spothero.com/v2/search/transie…w_unavailable=true&starts=2021-07-23T08%3A30%3A00'});
 * @returns {Array} The available transient facilities, with data transformation applied.
 */
export function getBulkSearchTransientFacilitiesLoadMoreSpots(
    {loadMoreSpotsUrl},
    requestData,
    formatOptions
) {
    const api = APIRegistry.get('craigSearch');

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const postBody = JSON.stringify(requestData);

    return api
        .post(loadMoreSpotsUrl, postBody, config, false)
        .then(({data: {results, '@next': nextLoadMoreSpotsUrl, experiment}}) => {
            setBackendExperiments(experiment);
            return {
                loadMoreSpotsUrl: nextLoadMoreSpotsUrl,
                results: formatBulkSearchTransientResponse(
                    results,
                    formatOptions,
                    requestData.periods
                ),
            }
        });
}
