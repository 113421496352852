import StorageUtils from '@spothero/utils/storage';
import FormatUtils from 'utils/format';
import dayjs from 'utils/dayjs-duration';
const VEHICLE_STORAGE_KEY = 'search_vehicle_info';
const VEHICLE_STORAGE_TYPE = 'session';
const POPOVER_STORAGE_KEY = 'search_vehicle_popover_visible';
const POPOVER_STORAGE_TYPE = 'session';
// eslint-disable-next-line no-shadow
export var OversizeType;
(function (OversizeType) {
    OversizeType["ChargedBySpotHero"] = "ChargedBySpotHero";
    OversizeType["ChargedOnsiteKnown"] = "ChargedOnsiteKnown";
    OversizeType["ChargedOnsiteUnknown"] = "ChargedOnsiteUnknown";
})(OversizeType || (OversizeType = {}));
export const getSearchVehicleFromSession = () => {
    const data = StorageUtils.get(VEHICLE_STORAGE_KEY, VEHICLE_STORAGE_TYPE);
    if (data && 'license_plate' in data) {
        data.licensePlate = data.license_plate;
        delete data.license_plate;
        data.vehicleProfileId = data.vehicle_profile_id;
        delete data.vehicle_profile_id;
        return data;
    }
    return data;
};
/**
 * Check if the search vehicle (key) is in session storage.
 * When a user manually clears the search vehicle selection, we store null value in the session key.
 * The getSearchVehicleFromSession returns false when the session key exists or the value is null, making it difficult to differentiate between the two.
 * This function provides us a way to detect if the user has made the vehicle selection or not.
 */
export const isSearchVehicleInSessionStorage = () => {
    if (typeof sessionStorage === 'undefined') {
        return false;
    }
    for (let i = 0; i < sessionStorage.length; i++) {
        if (sessionStorage.key(i) === VEHICLE_STORAGE_KEY) {
            return true; // Key exists
        }
    }
    return false; // Key does not exist
};
export const persistSearchVehicle = (vehicle) => {
    const data = vehicle
        ? {
            ...vehicle,
        }
        : null;
    if (data && 'licensePlate' in data) {
        data.license_plate = data.licensePlate;
        delete data.licensePlate;
        data.vehicle_profile_id = data.vehicleProfileId;
        delete data.vehicleProfileId;
    }
    StorageUtils.set(VEHICLE_STORAGE_KEY, data, VEHICLE_STORAGE_TYPE);
};
export const persistSearchOversizePopoverVisible = (value) => StorageUtils.set(POPOVER_STORAGE_KEY, value, POPOVER_STORAGE_TYPE);
export const getSearchOversizePopoverVisibleFromSession = () => StorageUtils.get(POPOVER_STORAGE_KEY, POPOVER_STORAGE_TYPE);
export const isOversizedFeatureEnabled = (parkingType) => {
    return parkingType === 'transient' || parkingType === 'event';
};
export const getSpotheroOversizeFee = (spot) => {
    const { selectedRate } = spot;
    const { starts, ends } = selectedRate || {};
    const flatFeePriceBreakdown = spot.selectedRate?.priceBreakdown?.find(item => item.type === 'oversize_vehicle_fee_flat');
    const onsiteFee = spot.vehicle?.onsiteFee;
    const unknownOnsiteFee = spot.vehicle?.unknownOnsiteFee;
    const price = flatFeePriceBreakdown?.price || onsiteFee || null;
    const isOversize = Boolean(price) || unknownOnsiteFee;
    const result = {
        isOversize,
        dollars: null,
        type: null,
        currencySymbol: null,
    };
    if (!isOversize) {
        return result;
    }
    if (unknownOnsiteFee) {
        result.type = OversizeType.ChargedOnsiteUnknown;
        return result;
    }
    result.currencySymbol = FormatUtils.currencySymbol(price.currencyCode);
    const { dollars } = FormatUtils.price(price.value);
    if (flatFeePriceBreakdown?.price) {
        result.type = OversizeType.ChargedBySpotHero;
        result.dollars = dollars;
    }
    else {
        // The onsite fee is not collected by SpotHero. It's payed directly to the parking facility.
        // We calculate the onsite fee by multiplying the daily rate by the duration of the reservation.
        // This amount is shown to user for display purposes only. It's not included in the total price.
        const dollarsNumber = parseInt(dollars, 10);
        if (isNaN(dollarsNumber)) {
            return result;
        }
        // round up duration to the nearest day
        // We are using minutes as our minimum duration is 30 minutes
        const durationInDays = Math.ceil(dayjs(ends).diff(dayjs(starts), 'minutes') / (24 * 60));
        const totalDollars = dollarsNumber * durationInDays;
        result.dollars = totalDollars;
        result.type = OversizeType.ChargedOnsiteKnown;
    }
    return result;
};
export const isSpotOversize = (spot) => {
    const flatFeePriceBreakdown = spot.selectedRate?.priceBreakdown?.find(item => item.type === 'oversize_vehicle_fee_flat');
    const onsiteFee = spot.vehicle?.onsiteFee;
    const unknownOnsiteFee = spot.vehicle?.unknownOnsiteFee;
    const price = flatFeePriceBreakdown?.price || onsiteFee || null;
    return Boolean(price) || unknownOnsiteFee;
};
