import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toggleShowTotal } from 'store/filters/filters-actions';
import { getTotalPriceUserPreference, persistTotalPriceSessionPreference, TotalPriceSessionPreference, } from 'utils/total-price-session-utils';
import { useStatesWithFeeIncluded } from './use-states-with-fee-included';
export const useSyncTotalPriceToggleOnSearch = () => {
    const totalPricePreferenceFromSession = getTotalPriceUserPreference();
    const dispatch = useDispatch();
    const searchState = useSelector((state) => state.city.data.state);
    const searchCountry = useSelector((state) => state.city.data.country_code);
    const statesWithFeeIncluded = useStatesWithFeeIncluded();
    const showTotal = useSelector((state) => state.filters.showTotal);
    // Sync the show total state with the states with fee included
    useEffect(() => {
        if (statesWithFeeIncluded &&
            totalPricePreferenceFromSession ===
                TotalPriceSessionPreference.NotSelected) {
            const matchCountry = statesWithFeeIncluded.find(s => s.name === searchCountry);
            const shouldShowTotal = matchCountry &&
                (!matchCountry.states ||
                    matchCountry.states.includes(searchState));
            if (shouldShowTotal) {
                dispatch(toggleShowTotal(shouldShowTotal));
                persistTotalPriceSessionPreference(shouldShowTotal);
            }
        }
    }, [
        dispatch,
        searchCountry,
        searchState,
        statesWithFeeIncluded,
        showTotal,
        totalPricePreferenceFromSession,
    ]);
};
