import React, { useEffect, useState } from 'react';
import { getRouteParam, paramNameOptions } from 'utils/url-utils';
import useIsMobile from 'hooks/use-is-mobile';
import { useDispatch } from 'react-redux';
import { SearchPageView } from 'utils/search-page-view';
import { setSearchPageView } from 'store/search/search-actions';
const useSearchViewParam = () => {
    const [loading, setLoading] = useState(true);
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    useEffect(() => {
        const isDesktop = !isMobile;
        const viewInURL = getRouteParam(paramNameOptions.VIEW);
        if (viewInURL) {
            if (isDesktop) {
                dispatch(setSearchPageView({
                    view: SearchPageView.DESKTOP_LIST,
                    isTrackingEnabled: false,
                }));
            }
            else if (isMobile &&
                [
                    SearchPageView.MOBILE_MAP,
                    SearchPageView.MOBILE_LIST,
                ].includes(viewInURL)) {
                dispatch(setSearchPageView({
                    view: viewInURL,
                    isTrackingEnabled: false,
                }));
            }
            else {
                dispatch(setSearchPageView({
                    view: SearchPageView.MOBILE_LIST,
                    isTrackingEnabled: false,
                }));
            }
        }
        else {
            dispatch(setSearchPageView({
                view: isMobile
                    ? SearchPageView.MOBILE_LIST
                    : SearchPageView.DESKTOP_LIST,
                isTrackingEnabled: false,
            }));
        }
        setLoading(false);
    }, [dispatch, isMobile]);
    return loading;
};
export const withSearchViewParam = (Component) => {
    const WrappedComponent = (props) => {
        useSearchViewParam();
        return <Component {...props}/>;
    };
    return WrappedComponent;
};
export default useSearchViewParam;
