import {APIRegistry} from '@spothero/utils/api';
import Config from '@/config/index';
import SegmentUtils from 'utils/segment';
import dayjs from 'utils/dayjs-timezone';
import {getTransientFacility} from './search-v2/transient/spot/getTransientFacility';
import {getAiportFacilityV2} from './search-v2/airport/spot/getAirportFacility';
import TimeUtils from 'utils/time';
import {formatTransientSpotDetailsResponse} from './search-v2/transient/utils/formatTransientSearchResponse';
import {formatMonthlySpotDetailsResponse} from './search-v2/monthly/utils/formatMonthlySpotDetailsResponse';
import {transformV2SingleFacilityToV1RatesTransient} from './search-v2/transient/format/format-v2-to-v1';
import {transformV2SingleFacilityToV1RatesMonthly} from './search-v2/monthly/format/format-v2-to-v1';
import {getBulkSearchTransientFacility} from './search-v2/power-booking/spot/getBulkSearchTransientFacility';
import {getEventPackageFacilityDetailV2} from './search-v2/event-packages/get-facility-detail';
import {getMonthlyFacility} from './search-v2/monthly/spot/getMonthlyFacility';

/**
 * Object containing all Facility API methods
 *
 * @property {getRecommendedSpots} getRecommendedSpots - gets spots nearby a facility when its no longer available
 * @property {getRates} getRates - get rate for specific booking
 * @module api/facility
 */
const FacilityAPI = {
    /**
     *
     * @function getRecommendedSpots
     * @param {object} recommendedSpotsParams - Parameters to pass to search endpoint
     * @param {boolean} isMonthly - Whether the endpoint should be transient or monthly (this is not used for airport)
     * @returns {Promise<object>} - API response
     * @see {@link https://api.staging.spothero.com/v2/docs#tag/Search/operation/searchTransientFacilityParking|Documentation}
     */
    getRecommendedSpots({recommendedSpotsParams, isMonthly = false}) {
        const api = APIRegistry.get('craigSearch');
        const ratesUrl = isMonthly ? 'search/monthly' : 'search/transient';
        const spotDetailsFormatter = isMonthly
            ? formatMonthlySpotDetailsResponse
            : formatTransientSpotDetailsResponse;
        const facilityFormatter = isMonthly
            ? transformV2SingleFacilityToV1RatesMonthly
            : transformV2SingleFacilityToV1RatesTransient;

        return api
            .get(ratesUrl, recommendedSpotsParams)
            .then(({data: {results}}) => {
                return results
                    .map(result => {
                        return spotDetailsFormatter(result, {});
                    })
                    .map(formattedResult => facilityFormatter(formattedResult));
            });
    },

    // TODO MR - This seems like it could be streamlined by vertical - its doing a lot of things now
    /**
     *
     * @function getRates
     * @param {object} param - Top Level param
     * @param {string} param.spotId - Spot Id
     * @param {object} param.starts - isotime string for start
     * @param {object} param.ends - isotime string for end
     * @param {string} param.eventId - Event Id
     * @param {boolean} param.monthly - isMonthly
     * @param {boolean} param.airport - isAirport
     * @param {string} param.eventPackageId - if event package involved add id
     * @param {boolean} param.includeFacility - Include facility boolean
     * @param {string} param.vehicleInfoId - Primarily used if oversized
     * @returns {Promise<object>} - API response
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Facilities/get_facilities_facility_id_rates|Documentation}
     */
    async getRates({
        spotId,
        starts,
        ends,
        eventId = null,
        monthly = false,
        airport = false,
        eventPackageId = null,
        includeFacility = true,
        vehicleInfoId = null,
        asV2 = false,
    }) {
        const searchAPIUtils = APIRegistry.get('ratesSearch');
        const includes = monthly ? 'monthly' : 'transient';
        const allIncludes = includeFacility
            ? `${includes},${Config.facilityIncludes}`
            : includes;
        const segmentId = SegmentUtils.getAnonymousId();
        const data = {
            starts,
            include: allIncludes,
            ...(segmentId && {
                segment_id: segmentId,
                mixpanel_id: segmentId,
            }),
            ...(ends && {ends}),
            ...(vehicleInfoId && {vehicle_info_id: vehicleInfoId}),
            ...(eventId && {eid: eventId}),
        };
        let ratesUrl = `facilities/${spotId}/rates/`;

        // Needs to be simplified into a single getRates function
        if (eventPackageId) {
            ratesUrl = `facilities/${spotId}/rates/event-packages/${eventPackageId}/`;
        } else if (monthly) {
            ratesUrl += 'monthly/';
        } else if (airport) {
            ratesUrl += 'airport/';
        }

        if (eventPackageId) {
            const result = await getEventPackageFacilityDetailV2({
                eventPackageId,
                facilityId: spotId,
                returnRawV1Data: !asV2,
                params: {
                    ...(vehicleInfoId && {vehicle_info_id: vehicleInfoId}),
                },
            });

            return {data: result};
        }

        if (monthly) {
            const response = await getMonthlyFacility(
                spotId,
                {
                    starts: dayjs(starts).format(
                        TimeUtils.V2_API_TIMESTAMP_FORMAT
                    ),
                    ends: dayjs(ends).format(TimeUtils.V2_API_TIMESTAMP_FORMAT),
                    eventId,
                },
                asV2
            );

            return {
                data: {
                    data: response,
                },
            };
        }

        if (airport) {
            const params = {
                starts: dayjs(starts).format('YYYY-MM-DDTHH:mm:ss'),
                ends: dayjs(ends).format('YYYY-MM-DDTHH:mm:ss'),
                ...(vehicleInfoId && {vehicle_info_id: vehicleInfoId}),
            };

            const result = await getAiportFacilityV2(spotId, params);

            return result;
        } else {
            return searchAPIUtils.get(ratesUrl, data);
        }
    },
    /**
     *
     * @function getPowerBookingRates
     * @param {object} powerBookingPeriods - Array of start and end periods of bulk reservation
     * @returns {Promise<object>} - API response
     */
    async getPowerBookingRates({
        facilityId,
        powerBookingPeriods,
        powerBookingSource,
        vehicleInfoId,
        asV2 = false,
    }) {
        const response = await getBulkSearchTransientFacility(
            facilityId,
            {
                powerBookingPeriods,
                powerBookingSource,
                vehicleInfoId,
            },
            asV2
        );

        return {
            data: {
                data: response,
            },
        };
    },
    /**
     *
     * @function getV2Rates
     * @param {object} param - Top Level param
     * @param {string} param.spotId - Spot Id
     * @param {object} param.starts - isotime string for start
     * @param {object} param.ends - isotime string for end
     * @param {string} param.eventId - Event Id
     * @param {boolean} param.monthly - isMonthly
     * @param {boolean} param.airport - isAirport
     * @param {string} param.eventPackageId - if event package involved add id
     * @param {boolean} param.includeFacility - Include facility boolean
     * @param {string} param.vehicleInfoId - Primarily used if oversized
     * @param {boolean} param.powerBooking - If reservation is of type powerBooking
     * @param {boolean} param.powerBookingPeriods - Array of selected start and end dateTime for Power booking
     * @param {boolean} param.powerBookingSource - From which page source user entered power booking
     * @returns {Promise<object>} - API response
     */
    async getV2Rates({
        spotId,
        starts,
        ends,
        eventId = null,
        monthly = false,
        airport = false,
        eventPackageId = null,
        includeFacility = true,
        vehicleInfoId = null,
        powerBooking = false,
        powerBookingPeriods = [],
        powerBookingSource = null,
        asV2 = false,
    }) {
        if (eventPackageId || monthly || airport) {
            return this.getRates({
                spotId,
                starts,
                ends,
                eventId,
                monthly,
                airport,
                eventPackageId,
                includeFacility,
                vehicleInfoId,
                asV2,
            });
        }

        if (powerBooking) {
            return this.getPowerBookingRates({
                facilityId: spotId,
                powerBookingPeriods,
                powerBookingSource,
                vehicleInfoId,
                asV2,
            });
        }

        const response = await getTransientFacility(
            spotId,
            {
                starts: dayjs(starts).format(TimeUtils.V2_API_TIMESTAMP_FORMAT),
                ends: dayjs(ends).format(TimeUtils.V2_API_TIMESTAMP_FORMAT),
                eventId,
                vehicle_info_id: vehicleInfoId,
            },
            asV2
        );

        return {
            data: {
                data: response,
            },
        };
    },
};

export default FacilityAPI;
