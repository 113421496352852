import { handleIllustrations } from 'api/search-v2/utils/format-v2-to-v1-common';
/**
 * formats v2 redemption instructions to V1
 *
 * @function formatAirportRedemptionInstructions
 * @param {Array} instructions - rates amenities
 * @param {boolean} addHoursType - flag to add hoursType to period array
 * @returns {object} v1 transformed response of hours of operation.
 */
export const formatAirportRedemptionInstructions = (instructions = []) => {
    return instructions.map(instruction => {
        const illustrationValues = instruction?.illustration?.url
            ? handleIllustrations(instruction?.illustration?.url) || []
            : [];
        return {
            illustration_id: illustrationValues[2],
            illustration_version: illustrationValues[1],
            id: parseInt(instruction?.id, 10),
            text: instruction?.text,
        };
    });
};
