import StorageUtils from '@spothero/utils/storage';
import { v4 as uuidV4 } from 'uuid';
// We are going to assume this is only used on the browser for this.
// SSR may need to use this at some point but we can discuss this at the future
const COOKIES = {
    EXPERIMENTID: 'sh-web-experiment-id',
};
// Storage utils set does JSON.stringify which results in a double wrapper
export const getWebExperimentCookie = () => {
    const shExperimentCookie = StorageUtils.get(COOKIES.EXPERIMENTID, 'cookie');
    if (!shExperimentCookie || typeof shExperimentCookie !== 'string') {
        // generate cookie
        const newCookie = uuidV4();
        // Set cookies are set in days
        StorageUtils.set(COOKIES.EXPERIMENTID, newCookie, 'cookie', 365);
        // return new cookie
        return newCookie.replaceAll('^"|"$', '');
    }
    return shExperimentCookie.replaceAll('^"|"$', '');
};
