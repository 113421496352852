import IconStar from '@spothero/icons/star-filled';
export const NO_RATING = 'no-rating';
export const VIEW_RATINGS = 'view-ratings';
/** This object is list of all possible rating categories for facilities. */
export const initialRatingTypes = {
    'no-rating': {
        slug: 'no-rating',
        name: 'No Rating',
        rating: null,
        count: 0,
        active: false,
    },
    'poor-rating': {
        slug: 'poor-rating',
        name: 'Poor',
        rating: 0,
        count: 0,
        active: false,
    },
    'disappointing-rating': {
        slug: 'disappointing-rating',
        name: 'Disappointing',
        rating: 1,
        count: 0,
        active: false,
    },
    'okay-rating': {
        slug: 'okay-rating',
        name: 'Okay',
        rating: 2,
        count: 0,
        active: false,
    },
    'good-rating': {
        slug: 'good-rating',
        name: 'Good',
        rating: 3,
        count: 0,
        active: false,
    },
    'excellent-rating': {
        slug: 'excellent-rating',
        name: 'Excellent',
        rating: 4,
        count: 0,
        active: false,
    },
};
export const ratingstoSlug = {
    0: 'poor-rating',
    1: 'disappointing-rating',
    2: 'okay-rating',
    3: 'good-rating',
    4: 'excellent-rating',
    5: 'excellent-rating',
};
export const convertRatingToSlug = (rating) => {
    if (!rating) {
        return NO_RATING;
    }
    else {
        return rating.count !== 0
            ? ratingstoSlug[Math.floor(rating.average)]
            : NO_RATING;
    }
};
export const getRatingIcons = (slug) => {
    if (slug === 'no-rating') {
        return null;
    }
    else if (slug in initialRatingTypes || slug === 'view-ratings') {
        return IconStar;
    }
    return null;
};
export const ratingSlugToColor = (slug) => {
    switch (slug) {
        case 'poor-rating':
            return 'red.default';
        case 'disappointing-rating':
            return 'yellow.default';
        case 'okay-rating':
            return 'gray.400';
        case 'good-rating':
            return 'primary.default';
        case 'excellent-rating':
            return 'green.default';
        default:
            return 'black';
    }
};
