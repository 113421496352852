export const VENUE_CATEGORY_ID = 2;
export const CATEGORY_NAMES = new Map([
    [84, ['travel', 'Travel']],
    [83, ['zip-codes', 'Zip Codes']],
    [82, ['addresses', 'Addresses']],
    [49, ['parking-spots', 'Parking Spots']],
    [48, ['intersections', 'Intersections']],
    [47, ['condo-buildings', 'Condo Buildings']],
    [14, ['fitness', 'Fitness']],
    [13, ['businesses', 'Businesses']],
    [10, ['festival', 'Events']],
    [5, ['hotel', 'Hotels']],
    [12, ['boroughs', 'Boroughs']],
    [1, ['neighborhood', 'Neighborhoods']],
    [7, ['points-of-interest', 'Points of Interest']],
    [3, ['landmark', 'Landmarks']],
    [2, ['theaters-and-venues', 'Venues']],
    [9, ['stadium', 'Stadiums']],
    [6, ['restaurant', 'Restaurants']],
    [8, ['university-parking', 'Universities and Schools']],
    [11, ['office-buildings', 'Office Buildings']],
    [4, ['airport', 'Airports']],
]);
/**
 * @typedef {object} DestinationCategory
 * @property {string} slug - Destination category slug
 * @property {string} title - Destination category title
 */
/**
 * Find DestinationCategory for a categoryId
 *
 * @param {string|number} categoryId Category ID
 * @returns {DestinationCategory} Destination Category tuple [slug, title] or a tuple with empty strings
 */
export const getDestinationCategoryById = (categoryId) => {
    return CATEGORY_NAMES.get(categoryId) || ['', ''];
};
