import SegmentUtils from 'utils/segment';

export default function trackShowMobileSearchListView() {
    const properties = {};

    SegmentUtils.track({
        event: 'Show Mobile Search List View',
        properties,
        additionalOptions: {
            context: {
                protocols: {
                    event_version: 1,
                },
            },
        },
    });
}
