import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import withConfig from '@/config/withConfig';
import SearchTracking, { SEARCH_TRACKING_ACTIONS } from 'utils/search-tracking';
import { resetFilters } from 'store/filters/filters-actions';
import { panMap, setZoom } from 'store/search/search-actions';
import { push, replace } from 'store/router/router-actions';
import { routeToSpotDetails, routeToSearch } from 'router/router-utils';
import SpotMap from '../spot-map';
import { Button, Checkbox, Icon, Text } from '@spothero/ui';
import { motion } from 'framer-motion';
import { Refresh } from '@spothero/icons';
import trackSearchAsIMoveButtonClicked from 'segment/events/search-as-i-move-button-clicked';
import { setSpotDetailReferrer } from 'store/spot/spot-actions';
import GoogleMapLoadingError from 'common/search-results-map/google-map/GoogleMapLoadingError';
const MotionButton = motion(Button);
/**
 * SearchMap is the outermost "layer" of the map components (SearchMap, SpotMap, SHGoogleMap).
 *
 * This component is responsible for:
 * - Passing down high-level data (spots, destination).
 * - Determining what to do actually do with a zoom, pan, or marker click.
 * (e.g. call search, pass data to analytics)
 */
const SearchMap = (props) => {
    const { isMobile, spots, isFiltered, destination, findParkingNearMe, resetFiltersClick, isAdmin, recentlyViewedSpots, googleMapError, onShowDetailsClick, setSpotDetailReferrerTo, pushTo, sendMapZoomAnalytics, loadSpots, sendMapPanAnalytics, } = props;
    const { searchUUID } = SearchTracking.getValues();
    const [searchOnMove, setSearchOnMove] = useState(true);
    const searchOnMoveRef = useRef(true);
    const [showReloadSpots, setShowReloadSpots] = useState(false);
    const showReloadSpotsRef = useRef(false);
    const mapCenterRef = useRef({});
    const onSpotDetail = (spotId) => {
        const { history: { location }, } = props;
        onShowDetailsClick(spotId);
        setSpotDetailReferrerTo('pin');
        routeToSpotDetails({
            method: pushTo,
            location,
            spotId,
            historyState: { referrer: 'pin' },
        });
    };
    const onZoom = ({ zoom, prevZoom, mapCenter, mapBounds }) => {
        sendMapZoomAnalytics({
            level: zoom,
            previousLevel: prevZoom,
            mapCenter,
            mapBounds,
        });
        if (zoom < prevZoom && searchOnMoveRef.current) {
            loadSpots({
                searchTrackingAction: SEARCH_TRACKING_ACTIONS.ZOOM,
                mapCenter,
                mapBounds,
            });
        }
    };
    const onPan = ({ mapCenter, mapBounds }) => {
        sendMapPanAnalytics({
            mapCenter,
            mapBounds,
        });
        if (searchOnMoveRef.current) {
            loadSpots({
                searchTrackingAction: SEARCH_TRACKING_ACTIONS.PAN,
                mapCenter,
                mapBounds,
            });
        }
        else {
            mapCenterRef.current = mapCenter;
            showReloadSpotsRef.current = true;
            setShowReloadSpots(true);
        }
    };
    const onMoveClick = () => {
        trackSearchAsIMoveButtonClicked({
            actionType: searchOnMove
                ? 'SEARCH_AS_I_MOVE_OFF'
                : 'SEARCH_AS_I_MOVE_ON',
        });
        searchOnMoveRef.current = !searchOnMove;
        setSearchOnMove(!searchOnMove);
    };
    const reloadSpotsClick = () => {
        const { latitude, longitude } = mapCenterRef.current;
        const { ends, starts } = props;
        trackSearchAsIMoveButtonClicked({ actionType: 'SEARCH_THIS_AREA_CLICK' });
        routeToSearch({
            method: pushTo,
            queryParams: {
                latitude,
                longitude,
                ends,
                starts,
            },
        });
    };
    if (googleMapError) {
        return <GoogleMapLoadingError />;
    }
    return (<div className="SearchMap">
            <MotionButton position="absolute" display={{ base: 'none', desktop: 'block' }} left="0" right="0" margin="auto" zIndex="layer3" marginTop="4" width="fit-content" py="2" px="8" variant={showReloadSpots ? 'primary' : 'secondary'} height="40px" 
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    transition="background-color 2s ease" sx={{
            '.chakra-button__icon': {
                verticalAlign: 'middle',
                height: '100%',
            },
        }} layout leftIcon={showReloadSpots ? (<Icon as={Refresh} width="6" height="6"/>) : (<Checkbox isChecked={searchOnMove} pointerEvents="none"/>)} onClick={showReloadSpots ? reloadSpotsClick : onMoveClick}>
                <Text as="span" variant="body" fontSize="sm" fontWeight="semibold" verticalAlign="middle" textTransform="none">
                    {showReloadSpots
            ? 'Search this area'
            : 'Search as I move the map'}
                </Text>
            </MotionButton>
            <SpotMap isAdmin={isAdmin} isNoResultsModalEnabled={isMobile} searchRequestId={searchUUID} isFiltered={isFiltered} onPan={onPan} onZoom={onZoom} onMarkerClick={onSpotDetail} destination={destination} spots={spots} recentlyViewedSpots={recentlyViewedSpots} resetFiltersClick={resetFiltersClick} findParkingNearMe={findParkingNearMe}/>
        </div>);
};
const mapStateToProps = state => {
    const { search: { data: { currentIncrement, requestIncrements, zoom, overrideIdealZoom, destinationTitle, kmlMapLayerEnabled, kmlMapLayerURL, searchType, }, }, searchRequest: { starts, ends, monthly, latitude, longitude, search_string: searchString, ideal_search_distance: idealDistance, }, filters: { numActive }, user: { data: { isAdmin }, }, search: { recentlyViewedSpots }, } = state;
    return {
        isAdmin,
        starts,
        ends,
        isMonthly: monthly,
        isFiltered: numActive > 0,
        searchString,
        latitude,
        longitude,
        destination: {
            title: destinationTitle,
            position: { latitude, longitude },
        },
        zoom,
        idealDistance,
        overrideIdealZoom,
        mapLoadingIncrement: currentIncrement,
        requestIncrements,
        kmlMapLayerEnabled,
        kmlMapLayerURL,
        searchType,
        recentlyViewedSpots,
    };
};
const mapDispatchToProps = {
    resetFiltersClick: resetFilters,
    sendMapZoomAnalytics: setZoom,
    sendMapPanAnalytics: panMap,
    pushTo: push,
    routeReplace: replace,
    setSpotDetailReferrerTo: setSpotDetailReferrer,
};
const enhance = compose(withConfig(['isMobile', 'staticBaseUrl']), withRouter, connect(mapStateToProps, mapDispatchToProps));
export default enhance(SearchMap);
