import dayjs from 'utils/dayjs-duration';
import {ActionType} from 'redux-promise-middleware';
import {getDocument} from 'ssr-window';
import StorageUtils from '@spothero/utils/storage';
import SearchUtils from 'utils/search-utils';
import GoogleRemarketingUtils from 'utils/google-remarketing';
import GTMUtils from 'utils/gtm';
import SearchTracking from 'utils/search-tracking';
import SegmentUtils from 'utils/segment';
import SpotUtils from 'utils/spot';
import UserUtils from 'utils/user-utils';
import {USER_LOGIN, USER_LOGOUT, USER_UPDATE} from 'store/user/user-actions';
import {CHECKOUT_FETCH_RATES} from 'store/checkout/checkout-actions';
import {SEARCH_PAN_MAP, SEARCH_SET_ZOOM} from 'store/search/search-actions';
import {
    SEARCH_REQUEST_SET_TERM,
    SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES,
} from 'store/search-request/search-request-actions';
import {SPOTS_GET_TRAVEL_DISTANCES} from 'store/spots/spots-actions';
import {
    SPOTS_GET_TRANSIENT_V2,
    SPOTS_GET_MONTHLY_V2,
    SPOTS_GET_AIRPORT_V2,
    SPOTS_GET_BULK_TRANSIENT_V2,
} from 'store/spots/spots-actions-v2';
import {SPOTS_GET_EVENT_PACKAGE} from 'store/spots/spots-event-packages-actions';
import trackMapAdjusted from 'segment/events/map-adjusted';
import trackProductsSearched from 'segment/events/products-searched';
import trackSearchResultsList from 'segment/events/search-results-list';
import getParkingType from 'segment/utils/parking-type';
import {getBackendExperiments} from 'utils/session-storage/backend-experiment';

const document = getDocument();
const ANALYTICS_SEARCH_UUID_COOKIE = 'sh-analytics-search-uuid';

export const analyticsMiddleware = store => next => action => {
    /* A word of caution here;
    Any sort of mutations to the state object here can be passed into other pieces of middleware.
    This creates problems when sorting, or filtering out spots, etc. Opted not to use a deep clone
    of the the provied state object to avoid the potential performance hit since the analytics
    middleware fires anytime a Redux action is fired, and lodash's `cloneDeep()` method is fairly
    expensive computation to run repeatedly.
    */
    const {type, payload, meta} = action;

    switch (type) {
        case `${USER_LOGIN}_${ActionType.Fulfilled}`:
        case USER_UPDATE: {
            const storedUser = store.getState().user.data;
            const newUser = payload?.id && storedUser.id !== payload?.id;

            if (newUser) {
                UserUtils.identify(payload);
            }

            break;
        }

        case `${USER_LOGOUT}_${ActionType.Fulfilled}`: {
            StorageUtils.remove('sh-session-uuid', 'cookie');
            UserUtils.identify(null);

            break;
        }

        case `${SPOTS_GET_TRANSIENT_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_MONTHLY_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_AIRPORT_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_BULK_TRANSIENT_V2}_${ActionType.Fulfilled}`:
        case `${SPOTS_GET_EVENT_PACKAGE}_${ActionType.Fulfilled}`: {
            const {
                city: {data: city},
                destination: {data: destination},
                event: {data: event},
                eventPackage: {data: eventPackage},
                search: {
                    data: {destinationTitle, sortBy, breadcrumbs, vehicle},
                },
                searchRequest,
                filters,
                user: {data: isAdmin},
            } = store.getState();
            const backendExperiments = getBackendExperiments();
            const {activeAmenities} = filters;
            const isV2Search =
                type === `${SPOTS_GET_TRANSIENT_V2}_${ActionType.Fulfilled}` ||
                type === `${SPOTS_GET_MONTHLY_V2}_${ActionType.Fulfilled}` ||
                type === `${SPOTS_GET_AIRPORT_V2}_${ActionType.Fulfilled}` ||
                type ===
                    `${SPOTS_GET_BULK_TRANSIENT_V2}_${ActionType.Fulfilled}`;

            const analyticsSearchUUIDCookie = StorageUtils.get(
                ANALYTICS_SEARCH_UUID_COOKIE,
                'cookie'
            );
            const {searchUUID} = SearchTracking.getValues();

            if (analyticsSearchUUIDCookie !== searchUUID) {
                trackProductsSearched({
                    city,
                    destination,
                    event,
                    eventPackage,
                    destinationTitle,
                    searchRequest,
                    activeAmenities,
                    filters,
                    spots: payload.results,
                    searchAPIVersion: isV2Search ? 2 : 1,
                    sortBy,
                    isAdmin,
                    breadcrumbs,
                    vehicle,
                    backendExperiments,
                });

                StorageUtils.set(
                    ANALYTICS_SEARCH_UUID_COOKIE,
                    searchUUID,
                    'cookie'
                );
            }

            break;
        }

        case `${SPOTS_GET_TRAVEL_DISTANCES}_${ActionType.Fulfilled}`: {
            // mobile and airport track this as part of Products Searched above so no need to call it here
            if (!meta.ignoreSegment) {
                const {
                    search: {
                        data: {sortBy},
                    },
                    searchRequest: {monthly: isMonthly},
                    filters,
                } = store.getState();

                // This is a temporary event until we move to search v2 and use one API call.
                // It's a stop gap to collect data for Project Apollo that will eventually get
                // combined with the Products Searched event above when that change is made.
                trackSearchResultsList({
                    spots: action.payload[0],
                    filters,
                    sortBy,
                    isMonthly,
                });
            }

            break;
        }

        case SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES:
        case SEARCH_REQUEST_SET_TERM: {
            const {airport, monthly, event, powerBooking} = payload;
            const termString = airport
                ? 'airport'
                : monthly
                ? 'monthly'
                : event
                ? 'event'
                : powerBooking
                ? 'power_booking'
                : 'transient';

            GoogleRemarketingUtils.track({
                data: {
                    /* eslint-disable camelcase */
                    dynx_itemid: 's_NA',
                    dynx_itemid2: termString,
                    dynx_pagetype: 'searchresults',
                    /* eslint-enable camelcase */
                },
            });

            break;
        }

        case `${CHECKOUT_FETCH_RATES}_${ActionType.Fulfilled}`: {
            const {
                payload: {
                    data: {data},
                },
                meta: {monthly, spotId, eventId},
            } = action;
            const {
                checkout: {previousRental},
                city: {
                    data: {timezone},
                },
                searchRequest: {
                    starts,
                    ends,
                    airport,
                    referrer,
                    pageType,
                    powerBooking,
                    powerBookingSource,
                    powerBookingPeriods,
                },
                spot: {selected: selectedSpot},
                search: {
                    data: {referrerAffiliate},
                },
            } = store.getState();
            const isAirportSpotDetail = pageType === 'facility' && airport;
            const newRate = SpotUtils.prepare({
                spot: {
                    ...selectedSpot,
                    ...data,
                },
                searchRequest: {
                    monthly,
                    powerBooking,
                    powerBookingSource,
                    powerBookingPeriods,
                },
                timezone,
            }).selectedRate;
            const newPrice = newRate ? (newRate.price / 100).toString() : 'N/A';
            const newCurrencyType = newRate
                ? newRate.currency_type
                : UserUtils.CURRENCY_TYPES.USD.toLowerCase();
            const startsTime =
                newRate && newRate.starts
                    ? dayjs(newRate.starts)
                    : dayjs(starts);
            const endsTime =
                newRate && newRate.ends
                    ? dayjs(newRate.ends)
                    : monthly
                    ? startsTime.clone().add(1, 'month')
                    : dayjs(ends);
            const id = selectedSpot ? selectedSpot.spotId : spotId;

            const rentalType = getParkingType({
                isMonthly: Boolean(monthly),
                isAirport: Boolean(airport),
                isEvent: Boolean(eventId),
                isEventPackage: false,
            });

            const googleRemarketingTrackingData = {
                /* eslint-disable camelcase */
                dynx_itemid: `f_${id}`,
                dynx_itemid2: rentalType,
                dynx_pagetype: 'conversionintent',
                /* eslint-enable camelcase */
            };
            const fromScreen = SearchUtils.getReferrerDetails({
                referrer,
                previousRental,
            });

            if (newRate) {
                // eslint-disable-next-line camelcase
                googleRemarketingTrackingData.dynx_totalvalue =
                    newRate.price / 100;
            }

            if (!isAirportSpotDetail) {
                GoogleRemarketingUtils.track({
                    data: googleRemarketingTrackingData,
                });

                const resduration = dayjs
                    .duration(endsTime.diff(startsTime))
                    .asMinutes();

                const resStart = dayjs
                    .duration(startsTime.diff(dayjs()))
                    .asMinutes();

                SegmentUtils.page({
                    name: 'Checkout',
                    properties: {
                        currency: newCurrencyType,
                        price: newPrice,
                        'spot id': id,
                        'reservation duration': resduration,
                        'time until reservation start': resStart,
                        'from screen': fromScreen,
                        'parking type': rentalType,
                        referrer: referrer || document.referrer,
                        'referrer affiliate': referrerAffiliate,
                    },
                });
            }

            GTMUtils.push({
                event: 'priceUpdated',
                rentalPrice: newPrice,
                currencyType: newCurrencyType,
                spotId: id,
                rentalType,
            });

            break;
        }

        case SEARCH_PAN_MAP:
        case SEARCH_SET_ZOOM: {
            // setting ideal distance causes zooming, use ignoreAnalytics to not fire tracking events
            const {
                level,
                previousLevel,
                ignoreAnalytics,
                mapCenter,
                mapBounds,
            } = payload;
            const storeState = store.getState();

            if (!ignoreAnalytics) {
                const actionType =
                    type === SEARCH_PAN_MAP
                        ? 'PAN'
                        : level > previousLevel
                        ? 'ZOOM_IN'
                        : 'ZOOM_OUT';

                if (mapCenter && mapBounds) {
                    trackMapAdjusted({
                        state: storeState,
                        args: {
                            actionType,
                            mapCenter,
                            mapBounds,
                        },
                    });
                }
            }

            break;
        }
    }

    return next(action);
};
