export const USER_TYPES = {
    AUTH_STATE: {
        USER: 'user',
        GUEST: 'guest',
        LOGGED_OUT: 'logged-out',
    },
    INCLUDES: 'phone_number,admin,is_seller,credit_cards,license_plates,vehicles,date_joined,has_usable_password',
    CURRENCY_TYPES: {
        USD: 'USD',
        DEFAULT: 'USD',
        CAD: 'CAD',
    },
};
