/**
 * @module api/search-v2/search-api
 */
import { APIRegistry } from '@spothero/utils/api';
import { formatTransientSearchResponse } from '../utils/formatTransientSearchResponse';
import { setBackendExperiments } from 'utils/session-storage/backend-experiment';
/**
 * Searches for available transient parking.
 *
 * @function getTransientFacilities
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientParking|Documentation}
 * @param {object} params - The parameters to pass to the endpoint.
 * @param {object} formatOptions - Additional options that don't get passed to the endpoint but are used for data transformation
 * @example
 * getTransientFacilities({lat: 41.8781, lon: -87.6298});
 * @returns {Array} The available transient facilities, with data transformation applied.
 */
export async function getTransientFacilities(params, formatOptions) {
    const api = APIRegistry.get('craigSearch');
    const { 
    // @ts-expect-error - not in craig docs
    data: { results, '@next': loadMoreSpotsUrl, experiment }, } = (await api.get('search/transient', {
        oversize: false,
        page_size: 25,
        sort_by: 'relevance',
        include_walking_distance: true,
        ...params,
    }));
    setBackendExperiments(experiment);
    return {
        loadMoreSpotsUrl,
        results: formatTransientSearchResponse(results, formatOptions),
    };
}
/**
 * Load more available transient parking after initial search for transient parking.
 * Uses the @next URL returned by search api to load next set of spots.
 *
 * @function getTransientFacilitiesLoadMoreSpots
 * @see {@link https://api.spothero.com/v2/docs#operation/searchTransientParking|Documentation}
 * @param {object} params - The parameters to pass to the endpoint.
 * @param {string} params.loadMoreSpotsUrl - The @next URL returned by search api to load next set of spots.
 * @param {object} [formatOptions] - Additional options that don't get passed to the endpoint but are used for data transformation
 * @example
 * getTransientFacilitiesLoadMoreSpots({loadMoreSpotsUrl: 'https://api.staging.spothero.com/v2/search/transie…w_unavailable=true&starts=2021-07-23T08%3A30%3A00'});
 * @returns {Array} The available transient facilities, with data transformation applied.
 */
export async function getTransientFacilitiesLoadMoreSpots({ loadMoreSpotsUrl }, formatOptions) {
    const api = APIRegistry.get('craigSearch');
    const { 
    // @ts-expect-error - not in craig docs
    data: { results, '@next': nextLoadMoreSpotsUrl, experiment }, } = (await api.get(loadMoreSpotsUrl));
    setBackendExperiments(experiment);
    return {
        loadMoreSpotsUrl: nextLoadMoreSpotsUrl,
        results: formatTransientSearchResponse(results, formatOptions),
    };
}
