import ErrorUtils from 'utils/error-utils';
import SearchTracking from 'utils/search-tracking';
import SegmentUtils from 'utils/segment';
export default function trackOpenedSpotDetails({ spotId, rank, }) {
    try {
        const { searchUUID, actionUUID } = SearchTracking.getValues();
        const properties = {
            spot_id: spotId,
            search_id: searchUUID,
            action_id: actionUUID,
            ...(rank ? { rank } : {}),
        };
        SegmentUtils.track({
            event: 'Pin Preview Clicked',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 2,
                    },
                },
            },
        });
    }
    catch (err) {
        ErrorUtils.sendSentryException(err);
    }
}
