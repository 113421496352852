import {formatAccessHours} from 'utils/spot-format-access-hours';
import ErrorUtils from 'utils/error-utils';
import {
    handleCancellation,
    handleIllustrations,
    handleHours,
} from 'api/search-v2/utils/format-v2-to-v1-common';
import {RateTransient, SingleFacilityResponseTransient} from '../../types';
import FormatUtils from 'utils/format';
import {FACILITY_TYPES} from 'utils/types/facility';
import Config from '@/config/index';
import {formatAddressTypes} from 'api/search-v2/utils';
const {currencySymbol} = FormatUtils;
const EVENT_RATE_TYPE = 'event';

/**
 * Transform just rate array from v2 to v1 format
 *
 * @todo APIv1 data schema
 * @param {object} arg - Top Level Param
 * @param {RateTransient} arg.rate - Single Rate
 * @param {any[]} arg.access_hours - Access hours array
 * @returns {any} - V1 Formatted Rate
 */
export const transformRateV2ToRateV1Transient = (arg = {}) => {
    const {rate = {}, access_hours = []} = arg;
    const priceBreakdown = rate?.priceBreakdown || [];
    const amenities = rate?.amenities || [];
    const parsedRate =
        rate?.rateId === FACILITY_TYPES.RATE_EXCEPTIONS.EVENT_RATE
            ? FACILITY_TYPES.RATE_EXCEPTIONS.EVENT_RATE
            : parseInt(rate?.rateId, 10);
    const starts = rate?.starts;
    const ends = rate?.ends;
    const earlyBird = rate?.earlyBird;

    return {
        rule_type: FACILITY_TYPES.RULE_TYPES.MULTIRATE,
        title: rate?.title,
        rule_id: null, // rule_id is for monthly
        price: rate?.totalPrice?.value,
        display_price: rate?.advertisedPrice?.value,
        rule_group_id: parsedRate, // rule_group_id is for transient and airport
        event_id: rate?.eventId || null,
        starts,
        ends,
        url: `${Config.siteUrl}${rate?.fullUrl}`,
        currency_type: rate?.advertisedPrice?.currencyCode?.toLowerCase(),
        currency_symbol: currencySymbol(
            rate?.advertisedPrice?.currencyCode?.toLowerCase()
        ),
        price_breakdown: {
            items: priceBreakdown.map(
                ({
                    price,
                    type,
                    shortDescription,
                    fullDescription,
                    priceBreakdown,
                }) => ({
                    price: price.value,
                    type,
                    priceBreakdown,
                    short_description: shortDescription,
                    full_description: fullDescription,
                })
            ),
            currency: rate?.totalPrice?.currencyCode?.toLowerCase(),
            total_price: rate?.totalPrice?.value,
        },
        online_commuter_rate: Boolean(earlyBird),
        online_commuter_rate_description: earlyBird?.description || null,
        online_commuter_rate_enter_start:
            earlyBird?.enterPeriod?.starts || null,
        online_commuter_rate_enter_end: earlyBird?.enterPeriod?.ends || null,
        parking_pass_type: rate?.parkingPass?.type,
        amenities: amenities.map(({type, displayName}, index) => ({
            slug: type,
            name: displayName,
            sort_order: index,
            visible: true,
            icon_url: '', // Not in the amenity array
        })),
        parking_type: rate?.redemptionType,
        hidden: false, // Not present in v2 transient unformatted response - tell abhi
        newMonthlyRate: false, // Not present in v2 transient unformatted response, removed
        fullUrl: rate?.fullUrl,
        access_hours_formatted: access_hours,
        fullRule: parsedRate, // Mocking as ruleId. For monthly, this will need to be rule_id
        rate_type:
            parsedRate === FACILITY_TYPES.RATE_EXCEPTIONS.EVENT_RATE
                ? EVENT_RATE_TYPE
                : rate?.rateType,
        additionalRateDetails: rate?.additionalRateDetails,
        redemptionType: rate?.redemptionType,
        totalPrice: rate.totalPrice,
        advertisedPrice: rate.advertisedPrice,
    };
};

/**
 * This is to format V2 Facility repsonse to V1 Rates Response
 *
 * @param {SingleFacilityResponseTransient} v2Response - This is the response from the API
 * @returns {*} - This is the transformed
 */
export const transformV2SingleFacilityToV1RatesTransient = (
    v2Response = {}
) => {
    const {
        spotId,
        title,
        distance,
        availability,
        requirements,
        selectedRate,
        rating,
        addresses = [],
        images = [],
        rates = [],
        restrictions,
        hoursOfOperation,
        navigationTip,
        facilityType,
        visualFlags,
        allAmenities,
        parkingTypes,
        redemptionInstructions,
        operatorDisplayName,
        supportedFeeTypes,
        cancellation,
        slug,
        isScanToPay,
        barcodeType,
        operatorId,
        oversizeFeesChargedOnsite,
        reservationExtensionEnabled,
        allowUpdatingReservationAfterEntry,
        requireCreditCard,
        status,
        vehicle,
    } = v2Response;

    try {
        const primeAddress = addresses[0] || {};
        const currencyCode = selectedRate?.advertisedPrice?.currencyCode?.toLowerCase();
        const hours_of_operation = handleHours(hoursOfOperation);
        const access_hours = formatAccessHours(hours_of_operation);
        const numberId = parseInt(spotId, 10);

        const topUrl = `${Config.siteUrl}/checkout/${numberId}/${slug}/`;

        const valueToReturn = {
            parking_spot_id: numberId,
            title,
            distance,
            latitude: primeAddress?.latitude,
            longitude: primeAddress?.longitude,

            // Below four are here but not used for transient, probably removable
            lowest_price: 0,
            highest_price: 0,
            lowest_monthly_price: 0,
            highest_monthly_price: 0,

            default_image_url: images[0]?.url,
            hourly_rates: availability?.available
                ? rates.map(rate =>
                      transformRateV2ToRateV1Transient({
                          rate,
                          access_hours,
                      })
                  )
                : [],
            monthly_rates: [], // Not used for transient
            bulk_event_rates: [], // Not used for transient
            license_plate_required: requirements?.licensePlate,
            phone_number_required: requirements?.phoneNumber,
            timezone: primeAddress?.timeZone,
            spot_url: topUrl,
            wd_latitude: primeAddress?.latitude,
            wd_longitude: primeAddress?.longitude,
            commuter_card_eligible: false, // in unformatted but not used for spotDetails
            publish_to_mobile: true, // Not used, probably removable
            currency_type: currencyCode,
            currency_symbol: currencySymbol(currencyCode),
            event_cancellation_minutes:
                selectedRate?.cancellationThresholdMinutes || 0,
            rating_info: {
                number_of_ratings: rating?.count,
                star_rating: rating?.average,
            },
            available_transient_inventory: availability?.availableSpaces,
            availability,
            facility: {
                id: numberId,
                parking_spot_id: numberId,
                title,
                description: '', // No longer used
                status,
                street_address: primeAddress.streetAddress,
                city: primeAddress.city,
                state: primeAddress.state,
                zipcode: primeAddress.postalCode,
                latitude: primeAddress.latitude,
                longitude: primeAddress.longitude,
                country: primeAddress.country,
                company_id: null, // No longer used
                timezone: primeAddress.timeZone,
                display_price_on_receipt: false, // No longer used
                height_restriction: null, // is restrictions which isn't height specific, will need refactor for monthly as used there.
                height_restriction_description: '', // is restrictions which isn't height specific, will need refactor for monthly as used there.
                barcode_type: barcodeType,
                post_purchase_instructions: '', // Remade to redemptionInstructions, no longer needed
                restrictions,
                hours_of_operation,
                getting_here: navigationTip,
                slug,
                addresses: addresses.map(
                    ({id, postalCode, streetAddress, types, ...address}) => ({
                        ...address,
                        types: formatAddressTypes(types),
                        street_address: streetAddress,
                        zipcode: postalCode,
                        id: parseInt(id, 10),
                    })
                ),
                redemption_instructions: redemptionInstructions.driveUp.map(
                    ({text, illustration, id}, i) => {
                        const illustrationValues = illustration?.url
                            ? handleIllustrations(illustration?.url)
                            : [];

                        return {
                            illustration_id: illustrationValues[2],
                            illustration_version: illustrationValues[1],
                            text,
                            id: parseInt(id, 10),
                            position: i,
                        };
                    }
                ), // For now we default to driveUp, will need other types build out for other systems
                mobile_enabled: true, // Not used, removable
                support_description: '', // Not used, removable
                facility_type: {
                    slug: facilityType,
                    display_name: facilityType, // just show slug for now as unused
                },
                cancellation_allowed: handleCancellation(cancellation),
                cancellation_minutes: cancellation?.minutes,
                is_scan_to_pay: isScanToPay,
                visual_flags: visualFlags,
                operator_display_name: operatorDisplayName,
                amenities_full: allAmenities.map(
                    ({type, displayName}, index) => ({
                        slug: type,
                        name: displayName,
                        sort_order: index,
                        visible: true,
                        icon_url: '', // Not in the amenity array, removable?
                    })
                ),
                images: images.map((image, i) => {
                    const {url: imageUrl, urlTemplate} = image;
                    const [, version, id] = handleIllustrations(imageUrl);

                    return {
                        id,
                        version,
                        center_x: 375, // not in new response, removable?
                        center_y: 250, // not in new response, removable?
                        order: i,
                        url_template: urlTemplate,
                    };
                }),
                facility_url: topUrl,
                rating_info: {
                    number_of_ratings: rating.count,
                    star_rating: rating.average,
                },
                transient_available: parkingTypes.includes('transient'),
                monthly_available: parkingTypes.includes('monthly'),
                airport_available: parkingTypes.includes('airport'),
                supported_fee_types: supportedFeeTypes,
                operator_id: operatorId,
                access_hours_formatted: access_hours,
                oversize_fees_charged_onsite: oversizeFeesChargedOnsite,
                reservationExtensionEnabled,
                allowUpdatingReservationAfterEntry,
                freePark: requireCreditCard === false,
            },
            spotId: numberId,
            event_package: {}, // Not for transient - tell abhi
            isFavorite: false, // where does this hydrate?  or do we even hydrate it?
            hasTravelDistanceUpdated: false, // Not needed
            hidden: false, // Unsure if we need check with FE team
            selectedRate: availability?.available
                ? transformRateV2ToRateV1Transient({
                      rate: selectedRate,
                  })
                : null,
            url: topUrl,
            facilityVehicle: vehicle,
        };

        return valueToReturn;
    } catch (e) {
        ErrorUtils.sendSentryMessage({
            error: e,
            customErrorMessage:
                'transformV2SingleFacilityToV1RatesTransient ERROR',
        });
    }

    return {};
};
