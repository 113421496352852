import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Button} from '@spothero/ui';

const GettingThere = ({instructions}) => {
    const [showFullInstructions, setShowFullInstructions] = useState(false);
    const toggleFullInstructions = () => setShowFullInstructions(show => !show);
    const classes = classNames('SpotDetails-section', 'GettingThere', {
        'GettingThere-show-full-instructions': showFullInstructions,
    });
    const formattedInstructions = instructions.replace(/\r?\n/g, '<br>');

    return (
        <div className={classes} data-testid="GettingThere">
            <h4 className="subtitle">Getting There</h4>
            <div className="preview-container">
                <p
                    className="instructions"
                    dangerouslySetInnerHTML={{__html: formattedInstructions}}
                />
                <div className="overlay" />
            </div>
            <Button
                variant="tertiary"
                fontSize="sm"
                className="read-more"
                onClick={toggleFullInstructions}
            >
                Show More
            </Button>
        </div>
    );
};

GettingThere.propTypes = {
    instructions: PropTypes.string.isRequired,
};

export default GettingThere;
