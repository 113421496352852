const TYPE_MULTI_DAY_DISCOUNT = 'multiday_discount';
const TYPE_RENTAL = 'rental';
const TYPE_OVERSIZE = 'oversize';
export const formatMultiDayOrMonthlyItem = (item, isMonthly = false, monthlyShortDescription = '') => {
    if (item.type === TYPE_MULTI_DAY_DISCOUNT) {
        return {
            ...item,
            short_description: '24+ Hour Discount',
            full_description: 'This location offers a discounted rate to drivers who make a reservation longer than 24 hours.',
        };
    }
    if (isMonthly && item.type === TYPE_RENTAL) {
        return {
            ...item,
            short_description: monthlyShortDescription,
            full_description: null,
        };
    }
    // The multi-day nested price breakdown items have a full description for 'rental' item type,
    // but we don't want to show it in the UI
    if (item.type === TYPE_RENTAL) {
        return {
            ...item,
            full_description: null,
        };
    }
    return item;
};
/**
 * Sorts multi-day items by type.
 * Sort order: [First item is of type 'rental', All positive price items, All negative price items, Oversize items]
 *
 * @param items
 */
export const sortMultiDayItems = (items) => {
    return [...items].sort((a, b) => {
        // move rental to the top
        if (a.type === TYPE_RENTAL) {
            return -1;
        }
        if (b.type === TYPE_RENTAL) {
            return 1;
        }
        // move oversize to the bottom
        if (a.type.startsWith(TYPE_OVERSIZE)) {
            return 1;
        }
        if (b.type.startsWith(TYPE_OVERSIZE)) {
            return -1;
        }
        // The price could be a number or an object with a 'value' key
        const aPriceValue = typeof a.price === 'number'
            ? a.price
            : 'value' in a.price
                ? a.price.value
                : 0;
        const bPriceValue = typeof b.price === 'number'
            ? b.price
            : 'value' in b.price
                ? b.price.value
                : 0;
        if (aPriceValue > 0 && bPriceValue < 0) {
            return -1;
        }
        return 0;
    });
};
/**
 * This takes in a price breakdown and returns a price object to show the price without the discount
 * showTotal allows us to comply with CA fees
 */
export const multidayWODiscountGenerator = (priceBreakdown = [], showTotal) => {
    let priceWithoutDiscount = {
        value: 0,
        currencyCode: '',
    };
    try {
        const objContainingMultiday = priceBreakdown.find(el => el.type === 'multiday_discount')?.price;
        if (objContainingMultiday) {
            if (showTotal) {
                priceWithoutDiscount = priceBreakdown.reduce((prev, { type, price }) => {
                    if (type !== 'multiday_discount')
                        return {
                            value: prev.value + price.value,
                            currencyCode: price.currencyCode,
                        };
                    else
                        return prev;
                }, priceWithoutDiscount);
            }
            else {
                const { value: valueWODiscount = 0, currencyCode: currencyCodeWODiscount = '', } = priceBreakdown.find(el => el.type === 'rental')
                    ?.price;
                priceWithoutDiscount.value = valueWODiscount;
                priceWithoutDiscount.currencyCode = currencyCodeWODiscount;
            }
        }
    }
    catch {
        // fail silently, powerbooking has a different key and breaks this logic.  Also doing it this way better supports tests
    }
    return priceWithoutDiscount;
};
export function getPriceBreakdown(baseRate) {
    const { items } = baseRate;
    const nestedPriceBreakdown = items &&
        items[0] &&
        items[0].priceBreakdown &&
        items[0].priceBreakdown.items;
    // immediately return if there is no nested (multi-day) price breakdown
    if (!nestedPriceBreakdown) {
        return items;
    }
    // combine the base rate items with the nested price breakdown items
    return sortMultiDayItems([
        ...items.filter(i => i.type !== TYPE_RENTAL),
        ...nestedPriceBreakdown,
    ]);
}
/* eslint-enable camelcase */
