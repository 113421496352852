export const styles = {
    fontSize: 'xs',
    display: 'flex',
    alignItems: 'center',
    padding: '2',
    borderColor: 'accent',
    fontWeight: 'medium',
    borderRadius: '0.5rem',
    backgroundColor: 'inherit',
    color: 'text.primary',
    gap: 1,
};
