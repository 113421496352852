import isString from 'lodash/isString';
import { getWindow } from 'ssr-window';
const window = getWindow();
/**
 * Utility methods for use throughout our code base.
 *
 * @module SHUtils
 */
const SHUtils = {
    prettyPrice(price, format) {
        let prettyPrice = price;
        if (isString(prettyPrice)) {
            prettyPrice = parseInt(prettyPrice, 10);
        }
        if (!format && prettyPrice.toFixed(2).split('.')[1] === '00') {
            return prettyPrice;
        }
        else {
            return prettyPrice.toFixed(2);
        }
    },
    determineInitialLoadBodyClasses({ pageType = 'home', isSearchPage = false, searchType = 'address', isAirport = false, } = {}) {
        if (isSearchPage && isAirport) {
            // airport search
            return 'search-airport-page';
        }
        else if (isAirport && pageType === 'facility') {
            // airport spot details
            return 'spot-details-airport-page';
        }
        else if (pageType === 'checkout') {
            // checkout
            return 'checkout-page';
        }
        else if (isSearchPage) {
            // all other search pages
            return `search-page ${searchType}-page`;
        }
        return '';
    },
    /**
     * Wrapper around window.scrollTo in order to bypass Mobile Safari strangeness
     * If you need animated scrolling, use AnimationUtils.scrollTo instead of staticScrollTo or window.scrollTo
     *
     * @param {object} [options] - Options to pass in.
     * @param {number} [options.x=0] - The X coordinate to scroll to.
     * @param {number} [options.y=0] - The Y coordinate to scroll to.
     * @param {number} [options.delay=500] - The delay to apply to the scrolling behavior, in milliseconds.
     * @returns {void}
     */
    staticScrollTo({ x = 0, y = 0, delay = 500 } = {}) {
        window.setTimeout(() => {
            window.scrollTo(x, y);
        }, delay);
    },
    getRatingsCategory(rating) {
        let category = null;
        if (rating >= 3.5 && rating < 4) {
            category = 'Good';
        }
        else if (rating >= 4 && rating < 4.5) {
            category = 'Great';
        }
        else if (rating >= 4.5) {
            category = 'Excellent';
        }
        return category;
    },
};
export default SHUtils;
