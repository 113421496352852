import { useDispatch } from 'react-redux';
import { loadMoreSpotsTransientV2, loadSpotsTransientV2, loadSpotsMonthlyV2, loadMoreSpotsMonthlyV2, loadBulkSearchSpotsTransientV2, loadMoreBulkSearchSpotsTransientV2, } from 'store/spots/spots-actions-v2';
import { useCallback, useEffect, useRef } from 'react';
import { SEARCH_TRACKING_ACTIONS } from 'utils/search-tracking';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
export const useFetchSearchData = () => {
    const isInitialMount = useRef(true);
    const dispatch = useDispatch();
    const { searchType, parkingType, starts, ends, event, } = useReduxSearchState();
    const prevStarts = useRef(starts);
    const prevEnds = useRef(ends);
    const loadMoreSpots = useCallback(() => {
        if (parkingType === 'power_booking') {
            const loadSpotsArgs = {
                searchTrackingAction: SEARCH_TRACKING_ACTIONS.LIST_SEARCH,
                locationContext: searchType,
            };
            dispatch(loadMoreBulkSearchSpotsTransientV2(loadSpotsArgs));
        }
        else if (parkingType === 'monthly') {
            dispatch(loadMoreSpotsMonthlyV2());
        }
        else if (parkingType === 'transient' || parkingType === 'event') {
            dispatch(loadMoreSpotsTransientV2());
        }
    }, [dispatch, parkingType, searchType]);
    const loadSpots = useCallback(({ searchTrackingAction, mapCenter = undefined, mapBounds = undefined, }) => {
        if (parkingType === 'power_booking') {
            dispatch(loadBulkSearchSpotsTransientV2({
                searchTrackingAction,
                locationContext: searchType,
                mapCenter,
                mapBounds,
            }));
        }
        else if (parkingType === 'transient') {
            dispatch(loadSpotsTransientV2({
                searchTrackingAction,
                locationContext: searchType,
                mapCenter,
                mapBounds,
                eventId: undefined,
            }));
        }
        else if (parkingType === 'monthly') {
            dispatch(loadSpotsMonthlyV2({
                searchTrackingAction,
                locationContext: searchType,
                mapCenter,
                mapBounds,
            }));
        }
        else if (parkingType === 'event') {
            dispatch(loadSpotsTransientV2({
                searchTrackingAction,
                locationContext: searchType,
                mapCenter,
                mapBounds,
                eventId: event.id,
            }));
        }
    }, [dispatch, parkingType, searchType, event]);
    // load initial spots
    useEffect(() => {
        loadSpots({
            searchTrackingAction: parkingType === 'event'
                ? SEARCH_TRACKING_ACTIONS.LIST_EVENT
                : SEARCH_TRACKING_ACTIONS.LIST_SEARCH,
        });
        isInitialMount.current = false; // Set isInitialMount to false after the initial load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //
    useEffect(() => {
        if (!isInitialMount.current &&
            (prevStarts.current !== starts || prevEnds.current !== ends) &&
            (parkingType === 'transient' ||
                parkingType === 'event' ||
                parkingType === 'monthly' ||
                parkingType === 'power_booking')) {
            loadSpots({
                searchTrackingAction: SEARCH_TRACKING_ACTIONS.LIST_DATE_CHANGED,
            });
        }
        // Update the previous values
        prevStarts.current = starts;
        prevEnds.current = ends;
    }, [loadSpots, starts, ends, parkingType]);
    return {
        loadSpots,
        loadMoreSpots,
    };
};
