import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';
import SpotUtils from 'utils/spot';
import { isSpotOversize } from 'utils/search-vehicle';
const getIsMonthly = state => state.searchRequest.monthly;
const getSortBy = state => state.search.data.sortBy;
const getAllSpots = state => state.spots.data;
const getSelectedSpotId = state => state.spots.selectedSpotId;
const getCurrentFilters = state => state.filters;
export const getVehicleInfo = state => state.search?.data?.vehicle;
export const canFetchLoadMoreSpots = state => Boolean(state.spots.loadMoreSpotsUrl);
export const getSelectedSpotById = createSelector([getAllSpots, getSelectedSpotId], (spots, selectedSpotId) => {
    if (!spots.length || !selectedSpotId) {
        return null;
    }
    return spots.find(spot => `${spot.spotId}` === `${selectedSpotId}`);
});
/* V2-CLEANUP
Remove getSpots and rename getSpotsV2 when Search V2 Integration is complete
This is used on Search for Transient, Monthly, Airport, and Event verticals
*/
export const getSpots = createSelector([getIsMonthly, getSortBy, getAllSpots, getCurrentFilters], (isMonthly, storedSort, spots, filters) => {
    if (!spots.length || spots[0].isV2) {
        return spots;
    }
    const preparedSpots = SpotUtils.sortByAttribute(SpotUtils.filter(spots, filters, isMonthly), storedSort);
    return uniqBy(preparedSpots, spot => spot.parking_spot_id);
});
export const getSpotsV2 = createSelector([getIsMonthly, getSortBy, getAllSpots, getCurrentFilters], (isMonthly, storedSort, spots, filters) => {
    if (!spots.length || !spots[0].isV2) {
        return spots;
    }
    const preparedSpots = SpotUtils.sortByAttributeV2(SpotUtils.filterV2(spots, filters, isMonthly), storedSort);
    return uniqBy(preparedSpots, spot => spot.spotId);
});
export const isSomeSpotOversize = createSelector([getSpots], spots => spots.some((spot) => isSpotOversize(spot)));
export const getSpotById = (spotId) => createSelector([getSpots], (spots) => spots.find(spot => `${spot.spotId}` === `${spotId}`));
