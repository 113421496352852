import uniqBy from 'lodash/uniqBy';
/**
 * Build an array of the amenities provided across all rates.
 *
 * @function buildUniqueRateAmenities
 * @param rates.amenities
 * @param {Array} rates - An array containing rate data
 * @returns {Array} a unique list of all the available amenities
 */
export const buildUniqueRateAmenities = (rates) => {
    const allRateAmenities = rates.reduce((accmAmenities, rate) => {
        // Case for undefined amenities
        if (rate.amenities) {
            return accmAmenities.concat(rate.amenities);
        }
        else {
            return accmAmenities;
        }
    }, []);
    const uniqueAmenities = uniqBy(allRateAmenities, 'type');
    return uniqueAmenities;
};
