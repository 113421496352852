import React from 'react';
const EventEmptyStateSvg = () => (<svg width="88px" height="88px" viewBox="0 0 88 88" version="1.1" xmlns="http://www.w3.org/2000/svg" data-testid="event-upcoming-image">
        <title id="svgTitle">No Upcoming Events</title>
        <desc id="svgDesc">There are no upcoming events at this venue.</desc>
        <defs>
            <path d="M44.3391461,-0.0004 C20.3121798,-0.0004 0.83352809,19.6996 0.83352809,43.9996 L0.83352809,43.9996 C0.83352809,68.3006 20.3121798,87.9996 44.3391461,87.9996 L44.3391461,87.9996 C68.3671011,87.9996 87.844764,68.3006 87.844764,43.9996 L87.844764,43.9996 C87.844764,19.6996 68.3671011,-0.0004 44.3391461,-0.0004 Z" id="path-1"/>
        </defs>
        <g id="Destination-Pages-&amp;-Event-Pages---AT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="A.3-desktop-empty-state" transform="translate(-532.000000, -382.000000)">
                <g id="Group-33" transform="translate(532.000000, 382.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"/>
                    </mask>
                    <g id="Clip-2"/>
                    <polygon id="Fill-1" fill="#E5E7EA" mask="url(#mask-2)" points="-3.60997753 47.4466 97.2617528 47.4466 97.2617528 35.3436 -3.60997753 35.3436"/>
                    <path d="M0.356251685,28.5239 C1.8324764,27.7659 12.0562966,23.8649 12.0562966,23.8649 C12.0562966,23.8649 18.0650157,25.7609 18.8026337,25.5979 C19.5412404,25.4359 27.5522067,23.4309 28.2898247,23.3769 C29.0284315,23.3229 37.671218,26.0859 38.6204315,25.9779 C39.5686562,25.8689 48.9500494,23.5399 49.6886562,23.4309 C50.4262742,23.3229 60.3346787,28.6319 60.8616899,28.4699 C61.3887011,28.3069 68.2398472,22.2389 68.2398472,22.2389 C68.2398472,22.2389 75.3025888,27.3859 76.7788135,27.3319 C78.2540494,27.2779 89.2322966,23.5939 89.2322966,23.5939 L89.6535101,38.7099 L0.832835955,38.6549 L0.356251685,28.5239 Z" id="Fill-3" fill="#F1F1F1" mask="url(#mask-2)"/>
                    <path d="M12.067964,23.8857 C14.4686831,27.6847 18.8587955,34.7187 21.270391,38.5867 L22.1513798,38.5877 L18.240818,25.5207 C17.0978067,25.4267 13.2218517,24.2437 12.067964,23.8857" id="Fill-4" fill="#EAEAEA" mask="url(#mask-2)"/>
                    <path d="M28.5559011,23.3886 L35.462418,38.5956 L38.0252944,38.5966 L38.1123056,25.8916 C36.731991,25.7616 30.5611146,23.8386 28.5559011,23.3886" id="Fill-5" fill="#EAEAEA" mask="url(#mask-2)"/>
                    <path d="M60.5453843,28.3886 C60.0786876,28.5326 52.2753618,24.3956 49.9675865,23.5126 L53.9137438,38.6076 L58.1861933,38.6106 L60.576036,28.3756 C60.5641708,28.3816 60.5542831,28.3856 60.5453843,28.3886" id="Fill-6" fill="#EAEAEA" mask="url(#mask-2)"/>
                    <path d="M78.2572135,38.623 L76.7819775,27.206 C76.6554157,27.232 76.5476404,27.248 76.4626067,27.251 C75.196,27.297 69.8141573,23.512 68.3132135,22.438 C70.0119101,25.84 73.276809,33.014 75.8001348,38.622 L78.2572135,38.623 Z" id="Fill-7" fill="#EAEAEA" mask="url(#mask-2)"/>
                    <path d="M33.473618,8.3554 C32.6381124,8.2804 31.8035955,8.8024 31.3537079,9.6234 C30.6477303,9.4734 29.940764,9.4734 29.2337978,9.6974 C28.7839101,8.9524 28.0779326,8.5794 27.3066966,8.6534 C26.1508315,8.6534 25.2520449,9.3244 25.2520449,10.1444 C25.2520449,10.2194 25.2520449,10.2934 25.3163146,10.4424 C24.9949663,10.3684 24.673618,10.3684 24.3522697,10.3684 C22.9393258,10.3684 21.7824719,10.8154 21.7824719,11.3374 L35.4649888,11.3374 C35.5292584,11.2634 35.5935281,11.1884 35.5935281,11.1134 C35.7210787,10.8904 35.7853483,10.5924 35.7853483,10.2934 C35.7210787,9.1754 34.6937528,8.3554 33.473618,8.3554" id="Fill-8" fill="#E5E7EA" mask="url(#mask-2)"/>
                    <polygon id="Fill-9" fill="#F4F6F9" mask="url(#mask-2)" points="-4.94382022 99.9746 91.8729888 99.9746 91.8729888 47.2146 -4.94382022 47.2146"/>
                    <path d="M24.8909483,43.6811 L23.6381843,43.6811 C23.4275775,43.6811 23.2565213,43.5081 23.2565213,43.2951 L23.2565213,28.1531 C23.2565213,27.5901 23.7083865,27.1331 24.2650607,27.1331 C24.8217348,27.1331 25.2736,27.5901 25.2736,28.1531 L25.2736,43.2951 C25.2736,43.5081 25.1015551,43.6811 24.8909483,43.6811" id="Fill-10" fill="#5C7996" mask="url(#mask-2)"/>
                    <path d="M20.756827,34.1831 L20.756827,34.1831 C20.3741753,34.1831 20.0637034,33.8691 20.0637034,33.4821 L20.0637034,29.8381 C20.0637034,29.4511 20.3741753,29.1371 20.756827,29.1371 C21.1404674,29.1371 21.4509393,29.4511 21.4509393,29.8381 L21.4509393,33.4821 C21.4509393,33.8691 21.1404674,34.1831 20.756827,34.1831" id="Fill-11" fill="#5C7996" mask="url(#mask-2)"/>
                    <path d="M27.7312719,36.1123 L27.6581034,36.1123 C27.3130247,36.1123 27.0341933,35.8303 27.0341933,35.4813 L27.0341933,32.2163 C27.0341933,31.8683 27.3130247,31.5853 27.6581034,31.5853 L27.7312719,31.5853 C28.0753618,31.5853 28.355182,31.8683 28.355182,32.2163 L28.355182,35.4813 C28.355182,35.8303 28.0753618,36.1123 27.7312719,36.1123" id="Fill-12" fill="#5C7996" mask="url(#mask-2)"/>
                    <path d="M27.7312719,36.1123 L25.2366202,36.1123 C24.8915416,36.1123 24.6127101,35.8303 24.6127101,35.4813 C24.6127101,35.1333 24.8915416,34.8503 25.2366202,34.8503 L27.7312719,34.8503 C28.0753618,34.8503 28.355182,35.1333 28.355182,35.4813 C28.355182,35.8303 28.0753618,36.1123 27.7312719,36.1123" id="Fill-13" fill="#5C7996" mask="url(#mask-2)"/>
                    <path d="M23.9195865,34.1831 L20.7565303,34.1831 C20.3738787,34.1831 20.0634067,33.8691 20.0634067,33.4821 L20.0634067,33.1771 C20.0634067,32.7901 20.3738787,32.4761 20.7565303,32.4761 L23.9195865,32.4761 C24.3022382,32.4761 24.6127101,32.7901 24.6127101,33.1771 L24.6127101,33.4821 C24.6127101,33.8691 24.3022382,34.1831 23.9195865,34.1831" id="Fill-14" fill="#5C7996" mask="url(#mask-2)"/>
                    <path d="M67.982373,43.7417 L67.1508225,43.7417 C67.0054742,43.7417 66.8868225,43.6217 66.8868225,43.4747 L66.8868225,33.2697 C66.8868225,32.8907 67.190373,32.5827 67.5661034,32.5827 C67.9418337,32.5827 68.2473618,32.8907 68.2473618,33.2697 L68.2473618,43.4747 C68.2473618,43.6217 68.1277213,43.7417 67.982373,43.7417" id="Fill-15" fill="#5C7996" mask="url(#mask-2)"/>
                    <path d="M65.2014742,37.3364 L65.2014742,37.3364 C64.942418,37.3364 64.7328,37.1244 64.7328,36.8634 L64.7328,34.4064 C64.7328,34.1454 64.942418,33.9334 65.2014742,33.9334 C65.4595416,33.9334 65.6691596,34.1454 65.6691596,34.4064 L65.6691596,36.8634 C65.6691596,37.1244 65.4595416,37.3364 65.2014742,37.3364" id="Fill-16" fill="#5C7996" mask="url(#mask-2)"/>
                    <path d="M69.9038382,38.6377 L69.8544,38.6377 C69.6220404,38.6377 69.4331865,38.4477 69.4331865,38.2117 L69.4331865,36.0107 C69.4331865,35.7757 69.6220404,35.5847 69.8544,35.5847 L69.9038382,35.5847 C70.1361978,35.5847 70.3250517,35.7757 70.3250517,36.0107 L70.3250517,38.2117 C70.3250517,38.4477 70.1361978,38.6377 69.9038382,38.6377" id="Fill-17" fill="#5C7996" mask="url(#mask-2)"/>
                    <path d="M69.9038382,38.6377 L68.2219506,38.6377 C67.9886022,38.6377 67.8007371,38.4477 67.8007371,38.2117 C67.8007371,37.9767 67.9886022,37.7867 68.2219506,37.7867 L69.9038382,37.7867 C70.1361978,37.7867 70.3250517,37.9767 70.3250517,38.2117 C70.3250517,38.4477 70.1361978,38.6377 69.9038382,38.6377" id="Fill-18" fill="#5C7996" mask="url(#mask-2)"/>
                    <path d="M67.3334472,37.3364 L65.2016719,37.3364 C64.9426157,37.3364 64.7329978,37.1244 64.7329978,36.8634 L64.7329978,36.6584 C64.7329978,36.3974 64.9426157,36.1854 65.2016719,36.1854 L67.3334472,36.1854 C67.5925034,36.1854 67.8011326,36.3974 67.8011326,36.6584 L67.8011326,36.8634 C67.8011326,37.1244 67.5925034,37.3364 67.3334472,37.3364" id="Fill-19" fill="#5C7996" mask="url(#mask-2)"/>
                    <polyline id="Stroke-20" stroke="#FFBF02" strokeLinecap="round" strokeLinejoin="round" mask="url(#mask-2)" points="9.98028764 54.0234 44.8332315 54.0234 74.8194787 54.0234"/>
                    <line x1="44.8941393" y1="71.333" x2="44.832836" y2="54.023" id="Stroke-21" stroke="#FFBF02" strokeLinecap="round" strokeLinejoin="round" mask="url(#mask-2)"/>
                    <line x1="55.0792" y1="54.0712" x2="67.3863461" y2="71.3332" id="Stroke-22" stroke="#FFBF02" strokeLinecap="round" strokeLinejoin="round" mask="url(#mask-2)"/>
                    <line x1="64.8645034" y1="54.0234" x2="82.8273798" y2="67.5224" id="Stroke-23" stroke="#FFBF02" strokeLinecap="round" strokeLinejoin="round" mask="url(#mask-2)"/>
                    <line x1="34.9185978" y1="54.0234" x2="22.4018337" y2="71.3334" id="Stroke-24" stroke="#FFBF02" strokeLinecap="round" strokeLinejoin="round" mask="url(#mask-2)"/>
                    <line x1="24.9430562" y1="54.0234" x2="4.42026966" y2="69.2604" id="Stroke-25" stroke="#FFBF02" strokeLinecap="round" strokeLinejoin="round" mask="url(#mask-2)"/>
                    <polygon id="Fill-26" fill="#FFBF02" mask="url(#mask-2)" points="35.0210337 52.8096 43.5590112 52.8096 43.5590112 51.5246 35.0210337 51.5246"/>
                    <polygon id="Fill-27" fill="#FFBF02" mask="url(#mask-2)" points="25.0513258 52.8096 33.5893034 52.8096 33.5893034 51.5246 25.0513258 51.5246"/>
                    <polygon id="Fill-28" fill="#FFBF02" mask="url(#mask-2)" points="15.081618 52.8096 23.6195955 52.8096 23.6195955 51.5246 15.081618 51.5246"/>
                    <polygon id="Fill-29" fill="#FFBF02" mask="url(#mask-2)" points="44.9917303 52.8096 53.5297079 52.8096 53.5297079 51.5246 44.9917303 51.5246"/>
                    <polygon id="Fill-30" fill="#FFBF02" mask="url(#mask-2)" points="54.9604494 52.8096 63.4994157 52.8096 63.4994157 51.5246 54.9604494 51.5246"/>
                    <polygon id="Fill-31" fill="#FFBF02" mask="url(#mask-2)" points="64.9301573 52.8096 73.4691236 52.8096 73.4691236 51.5246 64.9301573 51.5246"/>
                    <path d="M60.6207281,15.5332 C59.7852225,15.4582 58.9507056,15.9802 58.4998292,16.8002 C57.7948404,16.6512 57.0878742,16.6512 56.3809079,16.8752 C55.9310202,16.1292 55.2250427,15.7572 54.4538067,15.8312 C53.2979416,15.8312 52.3991551,16.5022 52.3991551,17.3222 C52.3991551,17.3972 52.3991551,17.4712 52.462436,17.6202 C52.1410876,17.5462 51.8207281,17.5462 51.498391,17.5462 C50.086436,17.5462 48.929582,17.9932 48.929582,18.5152 L62.6120989,18.5152 C62.6753798,18.4402 62.7406382,18.3662 62.7406382,18.2912 C62.8681888,18.0682 62.9324584,17.7692 62.9324584,17.4712 C62.8681888,16.3532 61.8408629,15.5332 60.6207281,15.5332" id="Fill-32" fill="#E5E7EA" mask="url(#mask-2)"/>
                </g>
            </g>
        </g>
    </svg>);
export default EventEmptyStateSvg;
