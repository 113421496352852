/* eslint-disable camelcase */
// More info: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
export const getReduxEnhancerOptions = () => ({
    actionTransformer: action => {
        // Here we strip the payload rather than adding a ton of data to Sentry / combing every action for PII
        // feel free to add payload back for specific actions (keeping PII in mind) temporarily to solve an issue
        return { ...action, payload: 'FILTERED' };
    },
    stateTransformer: state => {
        try {
            const destinationData = state.destination.data;
            // Here we transform the state to remove sensitive information or bulky info that would
            // clog up out sentry reporting. If some of the dropped info would be useful to you,
            // feel free to add it back (keeping PII in mind) temporarily
            const transformedState = {
                ...state,
                user: {
                    ...state.user,
                    data: {
                        ...state.user.data,
                        phoneNumber: 'FILTERED',
                        creditCards: state.user?.data?.creditCards?.map(card => ({
                            externalId: card.externalId,
                            wallet: card.wallet,
                            isDefault: card.isDefault,
                        })),
                        paypalCard: state.user?.data?.paypalCard
                            ? {
                                externalId: state.user.data.paypalCard.externalId,
                                wallet: state.user.data.paypalCard.wallet,
                            }
                            : null,
                        displayName: 'FILTERED',
                        lastName: 'FILTERED',
                        firstName: 'FILTERED',
                        email: 'FILTERED',
                        licensePlates: 'FILTERED',
                        vehicles: state.user?.data?.vehicles?.map(vehicle => ({
                            ...vehicle,
                            licensePlateValue: 'FILTERED',
                            licensePlateState: 'FILTERED',
                        })),
                    },
                },
                checkout: {
                    ...state.checkout,
                    phone: 'FILTERED',
                    spot: state.checkout?.spot?.parking_spot_id ||
                        state.checkout?.spot?.spotId,
                    selectedLicensePlate: state.checkout?.selectedLicensePlate
                        ? 'FILTERED'
                        : null,
                },
                city: {
                    ...state.city,
                    data: state.city?.data?.id
                        ? {
                            city: state.city.data.city,
                            id: state.city.data.id,
                            country_code: state.city.data.country_code,
                        }
                        : {},
                },
                filters: 'FILTERED',
                spot: {
                    ...state.spot,
                    selected: state.spot?.selected
                        ? state.spot.selected.parking_spot_id ||
                            state.spot.selected.spotId
                        : null,
                },
                spots: {
                    ...state.spots,
                    data: state.spots?.data?.map(spot => spot.parking_spot_id || spot.spotId),
                },
                destination: {
                    data: {
                        id: destinationData?.id,
                        spothero_city: destinationData?.spothero_city,
                        street_address: destinationData?.street_address,
                        zipcode: destinationData?.zipcode,
                        title: destinationData?.title,
                        longitude: destinationData?.longitude,
                        latitude: destinationData?.latitude,
                        relative_url: destinationData?.relative_url,
                        parent_url: destinationData?.parent_url,
                        override_ideal_zoom: destinationData?.override_ideal_zoom,
                        can_have_events: destinationData?.can_have_events,
                        categories: destinationData?.categories,
                        airport: destinationData?.airport,
                        short_description: destinationData?.short_description,
                        events: destinationData?.events?.map(event => ({
                            id: event.id,
                            title: event.title,
                        })),
                    },
                },
            };
            return transformedState;
        }
        catch {
            return null;
        }
    },
});
/* eslint-enable camelcase */
