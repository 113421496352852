import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {PROPTYPE_SPOT_ID} from '@/proptypes/index';
import {Box} from '@spothero/ui';
import {setHighlightedSpotId} from 'store/spots/spots-actions';
import {useDispatch} from 'react-redux';
import useSpotInView from 'hooks/use-spot-in-view';
import useTouch from 'hooks/use-touch';

const SpotListItemBase = ({
    spotId,
    additionalClasses,
    MainContent,
    onClick,
}) => {
    const dispatch = useDispatch();
    const {ref} = useSpotInView(spotId);

    // mouse enter/leave handlers for desktop
    const handleMouseEnter = e => {
        e.preventDefault();
        dispatch(setHighlightedSpotId({spotId}));
    };

    const handleMouseLeave = e => {
        e.preventDefault();

        // clear highlighted spot id
        dispatch(setHighlightedSpotId({spotId: ''}));
    };

    const handleClick = useCallback(
        e => {
            // prevent Book Now button clicks from bubbling up
            if (e?.target?.tagName === 'BUTTON') {
                return;
            }

            onClick(e);
        },
        [onClick]
    );

    const {onTouchStart, onTouchEnd} = useTouch(handleClick);

    return (
        <div
            ref={ref}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={`SpotListItem result${spotId} ${additionalClasses}`}
            data-spot={spotId}
            onClick={handleClick}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
        >
            <Box height="100%">{MainContent}</Box>
        </div>
    );
};

SpotListItemBase.propTypes = {
    spotId: PROPTYPE_SPOT_ID.isRequired,
    additionalClasses: PropTypes.string,
    onClick: PropTypes.func,
    MainContent: PropTypes.node,
};

export default SpotListItemBase;
