const buildVehicleDetails = ({ checkoutData, licensePlateRequired, skipUnknownForSavedVehicles = false, }) => {
    const { user, purchaseForCustomer, vehicleProfileId, vehicleInfoId, vehicleMake, vehicleModel, saveVehicleAsDefault, selectedLicensePlate, licensePlateState, newLicensePlate, newLicensePlateIsDefault, newLicensePlateName, licensePlateUnknown, vehicleSelectPresent, } = checkoutData;
    const vehicleDetails = {};
    if (!vehicleSelectPresent && !licensePlateRequired) {
        return vehicleDetails;
    }
    // vehicle data
    if (vehicleProfileId) {
        vehicleDetails.vehicle_profile_id = vehicleProfileId;
    }
    else if (vehicleInfoId) {
        vehicleDetails.vehicle_info_id = vehicleInfoId;
        if (vehicleMake && vehicleModel) {
            vehicleDetails.vehicle_make = vehicleMake;
            vehicleDetails.vehicle_model = vehicleModel;
            vehicleDetails.set_vehicle_default = Boolean(saveVehicleAsDefault);
        }
    }
    // license plate
    if ((licensePlateRequired || !user.id) && !vehicleProfileId) {
        vehicleDetails.license_plate_str = selectedLicensePlate;
        // unknown license plate flag
        if (selectedLicensePlate === 'DONTKNOW' || !selectedLicensePlate) {
            vehicleDetails.license_plate_unknown = true;
        }
        if (licensePlateState) {
            vehicleDetails.license_plate_state = licensePlateState;
        }
        // new license plate
        if (newLicensePlate && selectedLicensePlate) {
            vehicleDetails.license_plate_is_default = Boolean(newLicensePlateIsDefault);
            if (newLicensePlateName) {
                vehicleDetails.license_plate_name = newLicensePlateName;
            }
            if (!purchaseForCustomer) {
                vehicleDetails.save_license_plate = true;
            }
        }
    }
    if (licensePlateUnknown &&
        (!skipUnknownForSavedVehicles || !vehicleProfileId)) {
        vehicleDetails.license_plate_unknown = true;
    }
    return vehicleDetails;
};
export default buildVehicleDetails;
