/* V2-CLEANUP
Delete this file or merge with spots-actions-v2 when Search V2 Integration is complete
This is used on Search for Transient, Monthly, Airport, and Event verticals
*/
export const SPOTS_GET_TRAVEL_DISTANCES = 'SPOTS_GET_TRAVEL_DISTANCES';
export const SPOTS_SET_SPOT_SELECTED_RATE = 'SPOTS_SET_SPOT_SELECTED_RATE';
export const SPOTS_SET_SELECTED_SPOT_ID = 'SPOTS_SET_SELECTED_SPOT_ID';
export const SPOTS_SET_HIGHLIGHTED_SPOT_ID = 'SPOTS_SET_HIGHLIGHTED_SPOT_ID';
export const SPOTS_SET_PREVIEWED_SPOT_ID = 'SPOTS_SET_PREVIEWED_SPOT_ID';
export const SPOTS_SET_MOBILE_MAPVIEW_INTERACTION_TRIGGER =
    'SPOTS_SET_MOBILE_MAPVIEW_INTERACTION_TRIGGER';

export const setSpotSelectedRate = spotWithNewRate => {
    return {
        type: SPOTS_SET_SPOT_SELECTED_RATE,
        payload: {
            spotWithNewRate,
        },
    };
};

export const setSelectedSpotId = ({spotId}) => {
    return {
        type: SPOTS_SET_SELECTED_SPOT_ID,
        payload: {
            spotId,
        },
    };
};

export const setHighlightedSpotId = ({spotId}) => {
    return {
        type: SPOTS_SET_HIGHLIGHTED_SPOT_ID,
        payload: {
            spotId,
        },
    };
};

export const setPreviewedSpotId = ({spotId}) => {
    return {
        type: SPOTS_SET_PREVIEWED_SPOT_ID,
        payload: {
            spotId,
        },
    };
};

export const setMobileMapViewInteractionTrigger = ({trigger}) => {
    return {
        type: SPOTS_SET_MOBILE_MAPVIEW_INTERACTION_TRIGGER,
        payload: {
            trigger,
        },
    };
};
