import { Box, Text, Icon } from '@spothero/ui';
import IconInfoCircleFilled from '@spothero/icons/info-circle-filled';
import React, { useMemo } from 'react';
import { getSpotheroOversizeFee, OversizeType } from 'utils/search-vehicle';
import { useSelector } from 'react-redux';
import { getSelectedSpotById, getVehicleInfo, isSomeSpotOversize, } from 'store/spots/spots-selectors';
import useOversizeEnabled from 'hooks/use-oversize-enabled';
const VehicleBanner = () => {
    const isOversizeEnabled = useOversizeEnabled();
    const selectedSpot = useSelector(getSelectedSpotById);
    const isAnySearchResultOversize = useSelector(isSomeSpotOversize);
    const vehicle = useSelector(getVehicleInfo);
    const oversizeFee = useMemo(() => (selectedSpot ? getSpotheroOversizeFee(selectedSpot) : null), [selectedSpot]);
    if (!isOversizeEnabled ||
        !isAnySearchResultOversize ||
        !oversizeFee ||
        !oversizeFee.isOversize ||
        !vehicle) {
        return null;
    }
    return (<Box display="flex" alignItems="center" marginTop={-4} marginBottom={4} marginX={-4} padding={4} backgroundColor="yellow.100">
            <Box width={5} height={5}>
                <Icon as={IconInfoCircleFilled} color="yellow.500" width={6} height={6}/>
            </Box>
            <Box marginLeft={4}>
                <Text fontSize="sm" fontWeight="semibold" as="strong">
                    Oversized Vehicle
                </Text>
                {oversizeFee.type === OversizeType.ChargedBySpotHero ? (<Box>
                        An oversize fee applies for{' '}
                        {vehicle.display_description}.
                    </Box>) : oversizeFee.type === OversizeType.ChargedOnsiteKnown ? (<Box>
                        An oversize fee may apply for{' '}
                        {vehicle.display_description}, pay the{' '}
                        {oversizeFee.currencySymbol}
                        {oversizeFee.dollars} fee directly onsite.
                    </Box>) : (<Box>
                        An oversize fee may apply for{' '}
                        {vehicle.display_description}, pay the fee directly
                        onsite.
                    </Box>)}
            </Box>
        </Box>);
};
export default VehicleBanner;
