import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import throttle from 'lodash/throttle';
import React, {Component, createRef} from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet-async';
import {getWindow} from 'ssr-window';
import IconArrowLeft from '@spothero/icons/arrow-left';
import IconTimes from '@spothero/icons/x';
import {Alert} from '@spothero/ui-backlog';
import {Flex, Text, Box, Button} from '@spothero/ui';
import Image from 'common/image';
import DOMUtils from '@spothero/utils/dom';
import FacilitySummary from 'common/facility-summary/v1';
import {push} from 'store/router/router-actions';
import {updateData} from 'store/search-request/search-request-actions';
import trackAvailabilityCheck from 'segment/events/availability-check';
import trackSpotDetails from 'segment/pages/spot-details';
import GTMUtils from 'utils/gtm';
import SpotUtils from 'utils/spot';
import {updateSelected} from 'store/spot/spot-actions';
import ParkingOptions from './parking-options';
import ParkingRestrictions from 'common/spot-details/parking-restrictions';
import AccessHours from 'common/spot-details/access-hours';
import AmenitiesList from 'common/spot-details/amenities-list';
import SpotDetailsPlaceholder from 'common/spot-details/placeholder';
import SpotImages from 'common/spot-details/spot-images';
import SpotNotes from 'common/spot-details/operator-and-admin-notes';
import ThingsYouShouldKnow from 'common/spot-details/things-you-should-know';
import GettingThere from 'common/spot-details/getting-there';
import OnlineCommuterRateModal from 'common/spot-details/online-commuter-rate-modal';
import RateDetailsModal from 'common/spot-details/rate-details-modal';
import HowToRedeem from 'common/spot-details/redemption-instructions';
import Reviews from 'common/spot-details/reviews';
import PaymentMethodsIcons from 'common/payment-methods-icons';
import FAQPanels from 'common/spot-details/faq';
import TrustSealValueProps from 'common/spot-details/trust-seal-and-value-props';
import withConfig from '@/config/withConfig';
import {showSpotsLeft} from 'utils/spot-left';
import StarRating from 'common/star-rating';
import EditTimesModal from '../edit-times-modal/spot-details';
import trackAppleMapsViewNearbySpotsClicked from 'segment/events/apple-maps-view-nearby-spots-clicked';
import {
    getRankOfSpot,
    getSelectedSpot,
    getSelectedSpotDistance,
} from 'pages/search-transient-event-monthly/utils/spot-details-utils';
import ErrorUtils from 'utils/error-utils';
import SpotValueProps from 'pages/search-transient-event-monthly/components/spot-details-transient/spot-value-props';
import trackOpenedSpotDetails from 'segment/events/opened-spot-details';
import {withRouter} from 'react-router-dom/';
import {CarouselVariant} from 'common/image-carousel';
import {FACILITY_SUMMARY_VIEW_TYPES} from 'common/facility-summary/utils';
import SpotDetailPriceBreakdownModal from 'common/spot-details/price-breakdown-modal/SpotDetailPriceBreakdownModal';
import {getVehicleInfo} from 'store/spots/spots-selectors';
import {ReservationSummary} from './reservation-summary/ReservationSummary';
import {withOptimizely} from '@optimizely/react-sdk';
import {
    getFeatureVariation,
    OPTIMIZELY_FEATURE_ON,
} from 'plugins/optimizely/hooks/use-feature-variation';
import {IMMEDIATE_FEATURES} from 'utils/experiment';
const window = getWindow();

// For showing Spots Left badge we rely on Search V2 API data
// as it accounts for Inventory Control and thus is more accurate
// Reference thread: https://spothero.slack.com/archives/C025ELN6L2G/p1666811071313479
const computeShowSpotsLeftState = spots => {
    const spot = getSelectedSpot(spots);
    const availableSpaces = spot?.availability?.availableSpaces;
    const available = spot?.availability?.available;
    const facilityType = spot?.facilityType;
    const isSpotsLeftBannerVisible = showSpotsLeft({
        available,
        availableSpaces,
        facilityType,
    });

    return {
        availableSpaces,
        isSpotsLeftBannerVisible,
    };
};

class SpotDetails extends Component {
    static propTypes = {
        isMobile: PropTypes.bool,
        user: PropTypes.object,
        spotId: PropTypes.number,
        city: PropTypes.object,
        destination: PropTypes.object,
        event: PropTypes.object,
        searchRequest: PropTypes.object,
        referrerAffiliate: PropTypes.string,
        onCloseClick: PropTypes.func.isRequired,
        pushTo: PropTypes.func.isRequired,
        onUpdateData: PropTypes.func.isRequired,
        appleMapsPartner: PropTypes.bool.isRequired,
        appRating: PropTypes.shape({
            rating: PropTypes.number,
            ratingCount: PropTypes.number,
        }),
        spots: PropTypes.array,
        spotDetailReferrer: PropTypes.string,
        activeAmenities: PropTypes.array,
        updateSelectedSpot: PropTypes.func,
        onEditTimesModalClose: PropTypes.func,
        promptForTime: PropTypes.bool,
        vehicleInfoId: PropTypes.string,
        showTotal: PropTypes.bool,
        isRebookingReservation: PropTypes.bool,
        optimizely: PropTypes.object,
    };
    _container = createRef();
    constructor(props) {
        super(props);

        const {promptForTime, appleMapsPartner, isMobile, destination} = props;
        const editTimesModalOpen = appleMapsPartner
            ? appleMapsPartner && isMobile && isEmpty(destination)
            : promptForTime && isMobile;

        this.state = {
            updatedSpotData: null,
            isShowing: false,
            isLoadingRates: true,
            isRateLoadError: false,
            showSpotUnavailable: false,
            showRateDetailsModal: false,
            showPriceBreakdownModal: false,
            showOnlineCommuterRateModal: false,
            rateDetailsSection: null,
            showStickySpotDetailsReservationSummary: false,
            editTimesModalOpen,
            isSpotsLeftBannerVisible: false,
            availableSpaces: null,
        };
        this._initSpotDetails = this._initSpotDetails.bind(this);
        this._isUserInExpressCheckoutExperiment =
            getFeatureVariation(
                props.optimizely,
                IMMEDIATE_FEATURES.EXPRESS_CHECKOUT_WEB
            ) === OPTIMIZELY_FEATURE_ON;
    }

    componentDidMount() {
        DOMUtils.addClass('html', 'SpotDetails-open');

        this._initSpotDetails();

        window.addEventListener('keydown', this._onKeyDown);
    }

    componentDidUpdate(prevProps) {
        const {starts: newStarts, ends: newEnds} = this.props.searchRequest;
        const {starts: prevStarts, ends: prevEnds} = prevProps.searchRequest;
        const hasSearchRequestTimeChanged = !(
            prevStarts === newStarts && prevEnds === newEnds
        );

        if (
            prevProps.spotId !== this.props.spotId ||
            hasSearchRequestTimeChanged
        ) {
            this._initSpotDetails();
        }
    }

    componentWillUnmount() {
        this._isUnmounted = true;

        DOMUtils.removeClass('html', 'SpotDetails-open');

        if (this._rateLoader) {
            this._rateLoader.cancel();
            this._rateLoader = null;
        }

        window.removeEventListener('keydown', this._onKeyDown);
    }

    _onKeyDown = evt => {
        // close spot details if escape is pressed on keyboard
        if (evt.which === 27) {
            this._onCloseClick();
        }
    };

    _onRetryClick = () => {
        this._loadRates();
    };

    _onScroll = () => {
        const {isLoadingRates, isRateLoadError} = this.state;

        if (isLoadingRates || isRateLoadError) {
            return;
        }

        const summaryEl = DOMUtils.el('.SpotDetailsReservationSummary')[0];
        const {top: summaryTop} = DOMUtils.getDocumentOffset(summaryEl);
        const summaryHeight = DOMUtils.height(summaryEl);
        const headerHeight = DOMUtils.height('.Header');
        const summaryBottom = summaryTop + summaryHeight;

        this.setState({
            showStickySpotDetailsReservationSummary:
                summaryBottom < headerHeight,
        });
    };

    _onMultiRateChange = rateId => {
        const {updatedSpotData: spotData} = this.state;
        const rateType = 'hourly_rates';
        const newRate = find(spotData[rateType], {
            fullRule: rateId,
        });
        const newSpotData = {
            ...spotData,
            selectedRate: false,
        };

        if (newRate) {
            newSpotData.selectedRate = newRate;
        }

        this.setState({
            updatedSpotData: newSpotData,
        });
    };

    _onShowRateDetailsClick = section => {
        this.setState({
            showRateDetailsModal: true,
            rateDetailsSection: isString(section) ? section : null,
        });
    };

    _onShowRateDetailsClick = () => {
        this.setState({
            showPriceBreakdownModal: true,
        });
    };

    _onOnlineCommuterRateClick = () => {
        this.setState({
            showOnlineCommuterRateModal: true,
        });
    };

    _onBookSpotClick = evt => {
        evt.preventDefault();

        const {
            pushTo,
            searchRequest,
            isRebookingReservation,
            user: {isAdmin},
        } = this.props;
        const {updatedSpotData: spotData} = this.state;

        if (!spotData.selectedRate) {
            return null;
        }

        const shouldSend = SpotUtils.isExpressCheckoutEligible({
            spotData,
            searchRequest,
            isRebookingReservation,
            isAdmin,
            isUserInExpressCheckoutExperiment: this
                ._isUserInExpressCheckoutExperiment,
        });

        const {
            selectedRate: {fullUrl, expressCheckoutUrl},
        } = spotData;

        if (shouldSend) {
            window.location.href = expressCheckoutUrl;
        } else {
            pushTo(fullUrl);
        }
    };

    _onHideAllModals = () => {
        this.setState({
            showRateDetailsModal: false,
            showOnlineCommuterRateModal: false,
            showPriceBreakdownModal: false,
        });
    };

    _onCloseClick = () => {
        const {isMobile, onCloseClick} = this.props;
        const node = this._container.current;

        this.setState({
            isShowing: false,
            showStickySpotDetailsReservationSummary: false,
        });

        if (isMobile) {
            onCloseClick();
        } else {
            window.transitionEnd(node).bind(() => {
                window.transitionEnd(node).unbind();

                onCloseClick();
            });
        }
    };

    _onToggleEditTimesModal = () => {
        if (this.props.onEditTimesModalClose) {
            this.props.onEditTimesModalClose();
        }

        this.setState(({editTimesModalOpen}) => ({
            editTimesModalOpen: !editTimesModalOpen,
        }));
    };

    _onHandleCloseClick = () => {
        const {destination, onUpdateData, history} = this.props;

        // clear Apple Maps experience unless it's a destination / event
        if (isEmpty(destination)) {
            onUpdateData({
                partner: null,
            });

            // A user can click browser back button or this button to close the spot details
            // They both should have the same behaviour
            history.goBack();
        } else {
            this._onCloseClick();
        }
    };

    _onViewNearbySpotsClick = () => {
        trackAppleMapsViewNearbySpotsClicked({
            facility: this.state.updatedSpotData?.facility,
            location: 'Spot Details',
        });

        this._onHandleCloseClick();
    };

    _initSpotDetails() {
        this._loadRates();

        this.setState({
            isShowing: true,
        });

        trackOpenedSpotDetails({
            referrer: this.props.spotDetailReferrer,
        });
    }

    _trackSegmentEvents(spot) {
        const {
            user: {id: userId, status: userStatus},
            searchRequest,
            referrerAffiliate,
            city,
            destination,
            event,
            spotDetailReferrer,
            spots,
        } = this.props;
        const {monthly: isMonthly, starts, ends, referrer} = searchRequest;
        const {isSpotsLeftBannerVisible} = this.state;
        const selectedSpot = getSelectedSpot(spots);
        const visualFlags = selectedSpot?.visualFlags || [];
        const tag = visualFlags[0]?.title;

        const rank = Array.isArray(this.props.spots)
            ? getRankOfSpot(this.props.spots)
            : undefined;

        trackSpotDetails({
            userId,
            city,
            destination,
            event,
            isMonthly,
            starts,
            ends,
            spot: {
                ...spot,
                facility: {
                    ...spot?.facility,
                    tag,
                    visual_flags: visualFlags,
                },
            },
            referrer,
            referrerAffiliate,
            spotsLeftDisplayed: isSpotsLeftBannerVisible,
            spotDetailReferrer,
            rank,
        });

        trackAvailabilityCheck({
            searchRequest,
            city,
            destination,
            event,
            spot,
            userStatus,
            screen: 'spot details',
        });
    }

    _loadRates() {
        const {
            spotId,
            searchRequest,
            city,
            event,
            spots,
            activeAmenities,
            vehicleInfoId,
        } = this.props;
        const {
            monthly: isMonthly,
            starts,
            ends,
            rebook_reservation_id: rebookId,
            powerBookingPeriods,
            powerBooking: isPowerBooking,
            powerBookingSource,
        } = searchRequest;
        const eventId = event ? event.id : null;

        this.setState({
            isLoadingRates: true,
            isRateLoadError: false,
        });

        this._rateLoader = SpotUtils.loadRatesSpotDetail({
            spotId,
            starts,
            ends,
            powerBookingPeriods,
            isPowerBooking,
            powerBookingSource,
            isMonthly,
            timezone: city.timezone,
            searchDataParams: {
                monthly: isMonthly,
                starts,
                ends,
                eid: eventId,
            },
            spot: null,
            eventId,
            rebookId,
            activeAmenities,
            vehicleInfoId,
            onSuccessWithRates: updatedSpotData => {
                this.setState({
                    updatedSpotData,
                    showSpotUnavailable: false,
                    isLoadingRates: false,
                    ...computeShowSpotsLeftState(spots),
                });

                this._trackSegmentEvents(updatedSpotData);
                this._rateLoader = null;
            },
            onSuccessWithoutRates: partialSpotData => {
                GTMUtils.push({
                    event: 'GAEvent',
                    eventCategory: 'automatic',
                    eventAction: 'rate_unavailable',
                    eventLabel: 'transient_rate_toggle',
                    eventValue: undefined, // eslint-disable-line no-undefined
                    hitCallback: undefined, // eslint-disable-line no-undefined
                });

                this.setState({
                    updatedSpotData: partialSpotData,
                    showSpotUnavailable: true,
                    isLoadingRates: false,
                    ...computeShowSpotsLeftState([]),
                });

                this._rateLoader = null;
            },
            onError: error => {
                if (this._isUnmounted) {
                    return;
                }

                if (searchRequest?.partner === 'apple') {
                    ErrorUtils.sendSentryMessage({
                        error,
                        customErrorMessage: 'Apple Maps problem with spot',
                    });
                }

                this.setState({
                    updatedSpotData: null,
                    showSpotUnavailable: true,
                    isLoadingRates: false,
                    isRateLoadError: true,
                    ...computeShowSpotsLeftState([]),
                });
                this._rateLoader = null;
            },
        });
    }

    _renderSpotImages(facility) {
        const {
            parking_spot_id: facilityId,
            title: facilityTitle,
            images: facilityImages,
        } = facility;

        return (
            <SpotImages
                key={facilityId}
                images={facilityImages}
                alt={facilityTitle}
                carouselVariant={CarouselVariant.SINGLE}
            />
        );
    }

    _renderFacilitySummary(spot, appleMapsPartner) {
        const {
            user: {isAdmin},
            spots,
        } = this.props;
        const {isSpotsLeftBannerVisible, availableSpaces} = this.state;
        const {facility = {}} = spot;
        const selectedSpot = getSelectedSpot(spots);
        const distance = getSelectedSpotDistance(selectedSpot);
        const spotValue = {
            ...spot,
            facility: {
                ...facility,
                visual_flags: selectedSpot?.visualFlags || [],
            },
            distance,
        };

        return (
            <FacilitySummary
                isAdmin={isAdmin}
                spot={spotValue}
                availableSpaces={availableSpaces}
                showImages={false}
                showPrice={false}
                appleMapsPartner={appleMapsPartner}
                showFullTitle
                showAdminLink
                isSpotsLeftBannerVisible={isSpotsLeftBannerVisible}
                view={FACILITY_SUMMARY_VIEW_TYPES.SPOT_DETAIL}
            />
        );
    }

    _renderParkingOptions(spot) {
        const {selectedRate, hourly_rates: hourlyRates} = spot;

        return (
            hourlyRates.length > 1 && (
                <ParkingOptions
                    availableRates={hourlyRates}
                    selectedRate={selectedRate}
                    onMultiRateChange={this._onMultiRateChange}
                    onRateDetailsClick={this._onShowRateDetailsClick}
                />
            )
        );
    }

    _renderParkingRestrictions(spot) {
        const {selectedRate} = spot;
        const {online_commuter_rate: onlineCommuterRate} = selectedRate;

        return (
            <ParkingRestrictions
                hasOnlineCommuterRate={Boolean(onlineCommuterRate)}
                onOnlineCommuterRateClick={this._onOnlineCommuterRateClick}
            />
        );
    }

    _renderThingsYouShouldKnow(spot, appleMapsPartner) {
        const {selectedRate, facility} = spot;
        const {restrictions: facilityRestrictions} = facility;
        const {amenities: rateAmenities} = selectedRate;
        const hasInOutAmenity = Boolean(
            rateAmenities.find(amenity => amenity.slug === 'in-out')
        );

        return (
            <ThingsYouShouldKnow
                hasInOutAmenity={hasInOutAmenity}
                restrictions={facilityRestrictions}
                appleMapsPartner={appleMapsPartner}
            />
        );
    }

    _renderAmenities(spot) {
        const {selectedRate, facility} = spot;
        const {amenities_full: nonRateAmenities} = facility;

        return (
            <AmenitiesList
                rate={selectedRate}
                isMonthly={false}
                nonRateAmenities={nonRateAmenities}
                onShowRateDetailsClick={this._onShowRateDetailsClick}
            />
        );
    }

    _renderAccessHours(spot) {
        const {selectedRate, facility} = spot;
        const {access_hours_formatted: facilityAccessHours} = facility;
        const {
            access_hours_formatted: rateAccessHours,
            display_hours_comment: hoursComment,
        } = selectedRate;
        const showAccessHours = Boolean(
            rateAccessHours || hoursComment || !isEmpty(facilityAccessHours)
        );

        return (
            showAccessHours && (
                <AccessHours
                    rate={selectedRate}
                    facility={facility}
                    sectionTitle="Access Hours"
                />
            )
        );
    }

    _renderRedemptionInstructions(facility) {
        const {
            redemption_instructions: facilityInstructions,
            post_purchase_instructions: postPurchaseInstructions,
        } = facility;

        return facilityInstructions ? (
            <HowToRedeem
                sectionTitle="How to Redeem"
                instructions={facilityInstructions}
            />
        ) : (
            <div
                className="SpotDetails-section HowToRedeem"
                data-testid="SpotDetails-HowToRedeem"
            >
                <h4 className="subtitle">How to Redeem</h4>
                <span
                    dangerouslySetInnerHTML={{
                        __html: postPurchaseInstructions,
                    }}
                />
            </div>
        );
    }

    _renderGettingThere(facility) {
        const {getting_here: gettingHere} = facility;

        return gettingHere && <GettingThere instructions={gettingHere} />;
    }

    _renderReviews(facility) {
        const {rating_info: ratingInfo} = facility;

        return <Reviews ratingInfo={ratingInfo} />;
    }

    _renderSpotNotes(spot) {
        const {operator_notes: operatorNotes, spot_notes: spotNotes} = spot;

        return (
            <>
                <div
                    className="SpotDetails-section"
                    data-testid="SpotDetails-SpotNotes"
                >
                    <h4 className="subtitle">Spot Notes (Admin)</h4>
                    <SpotNotes type="spot" notes={spotNotes} />
                </div>
                <div
                    className="SpotDetails-section"
                    data-testid="SpotDetails-OperatorNotes"
                >
                    <h4 className="subtitle">Operator Notes (Admin)</h4>
                    <SpotNotes type="operator" notes={operatorNotes} />
                </div>
            </>
        );
    }

    _renderFooter(facility, canCancelHPEReservation, eventCancellationMinutes) {
        const {cancellation_allowed: cancellationAllowed} = facility;
        const hpeCancellationProps = {
            canCancelHPEReservation,
            eventCancellationMinutes,
        };

        return (
            <div className="SpotDetails-footer">
                <FAQPanels
                    cancellationAllowed={cancellationAllowed}
                    {...hpeCancellationProps}
                />
                <TrustSealValueProps />
            </div>
        );
    }

    render() {
        const {
            isMobile,
            user: {isAdmin},
            appRating,
            city,
            destination,
            searchRequest: {starts, ends},
            appleMapsPartner,
            spotId,
            spots,
            updateSelectedSpot,
            promptForTime,
        } = this.props;
        const {
            updatedSpotData: spot,
            isShowing,
            isLoadingRates,
            isRateLoadError,
            rateDetailsSection,
            showStickySpotDetailsReservationSummary,
            showRateDetailsModal,
            showPriceBreakdownModal,
            showOnlineCommuterRateModal,
            editTimesModalOpen,
            showSpotUnavailable,
        } = this.state;
        const classes = classNames('SpotDetails', {
            'SpotDetails-showing': isShowing,
        });
        const shimClasses = classNames('SpotDetails-shim', 'shim', {
            'SpotDetails-shim-showing': isShowing,
        });
        // These booleans are used to conditionally display subcomponents
        // Some subcomponents are rate-dependent, while others can be displayed if no rate is available
        const hasFacilityData = Boolean(spot);
        const hasFacilityAndRateData = Boolean(spot?.selectedRate);
        const cancellationAllowedValue =
            hasFacilityAndRateData && spot?.facility?.cancellation_allowed;
        const cancellationAllowed =
            Boolean(cancellationAllowedValue) &&
            cancellationAllowedValue !== 'no';

        const eventCancellationMinutes = spot?.event_cancellation_minutes || 0;
        const canCancelHPEReservation = SpotUtils.canCancelHPEReservation({
            reservationStart: starts,
            reservationTimezone: city?.timezone,
            eventCancellationMinutes,
        });
        const hpeCancellationProps = {
            canCancelHPEReservation,
            eventCancellationMinutes,
        };
        // check if this is a high-profile event that is past the cancellation threshold
        const isNonRefundable =
            cancellationAllowed &&
            eventCancellationMinutes > 0 &&
            !canCancelHPEReservation;

        return (
            <>
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <div className={shimClasses} onClick={this._onCloseClick} />
                <div
                    ref={this._container}
                    className={classes}
                    onScroll={throttle(this._onScroll, 500)}
                >
                    {isMobile ? (
                        (!appleMapsPartner || !isEmpty(destination)) && (
                            <Button
                                _hover={{
                                    backgroundColor: 'transparent',
                                    borderColor: 'transparent',
                                }}
                                position="absolute"
                                backgroundColor="transparent"
                                width={14}
                                height={14}
                                boarderRadius="0"
                                paddingTop={0}
                                paddingLeft={0}
                                paddingRight={0}
                                paddingBottom={0}
                                borderColor="transparent"
                                className="SpotDetails-back"
                                onClick={this._onHandleCloseClick}
                                fontSize="sm"
                            >
                                {/* span allows CSS to create larger tap target for this button */}
                                <span>
                                    <IconArrowLeft />
                                </span>
                            </Button>
                        )
                    ) : (
                        <div className="SpotDetails-close-container">
                            <Button
                                _hover={{
                                    backgroundColor: 'white',
                                    borderColor: 'white',
                                }}
                                position="absolute"
                                backgroundColor="white"
                                width={10}
                                boarderRadius="50%"
                                paddingTop={0}
                                paddingLeft={0}
                                paddingRight={0}
                                paddingBottom={0}
                                borderColor="white"
                                className="SpotDetails-close"
                                onClick={this._onCloseClick}
                                fontSize="sm"
                            >
                                <IconTimes />
                            </Button>
                        </div>
                    )}
                    {hasFacilityData && this._renderSpotImages(spot.facility)}
                    {hasFacilityData &&
                        this._renderFacilitySummary(spot, appleMapsPartner)}
                    {hasFacilityAndRateData && this._renderParkingOptions(spot)}
                    {hasFacilityAndRateData &&
                        this._renderParkingRestrictions(spot)}
                    {hasFacilityData && (
                        <ReservationSummary
                            spot={spot}
                            isSticky={false}
                            isStickyVersionShowing={false}
                            isNonRefundable={isNonRefundable}
                            isLoadingRates={isLoadingRates}
                            showSpotUnavailable={showSpotUnavailable}
                            onBookSpotClick={this._onBookSpotClick}
                            onToggleEditTimesModal={
                                this._onToggleEditTimesModal
                            }
                            onShowRateDetailsClick={
                                this._onShowRateDetailsClick
                            }
                        />
                    )}
                    {cancellationAllowed && (
                        <SpotValueProps
                            cancellationAllowed={cancellationAllowed}
                            {...hpeCancellationProps}
                        />
                    )}
                    {hasFacilityData && (
                        <Box
                            padding={appleMapsPartner ? 4 : 0}
                            bgColor={
                                appleMapsPartner ? 'gray.light' : 'transparent'
                            }
                            margin={appleMapsPartner ? 4 : 0}
                            marginTop={0}
                        >
                            <Flex
                                marginX={!isMobile && appleMapsPartner ? 0 : 4}
                                marginBottom={4}
                                justifyContent="center"
                            >
                                <PaymentMethodsIcons
                                    showAlternatePaymentOptions
                                    alignment="center"
                                />
                            </Flex>
                            {appleMapsPartner && (
                                <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Image
                                        className="digicert-seal-image"
                                        cloudinaryImageId="logos/digicert-secured-seal"
                                        width={100}
                                        height={56}
                                        alt="Norton DigiCert Seal"
                                    />
                                    <Flex
                                        flexDirection="column"
                                        paddingLeft={3}
                                        justifyContent="center"
                                    >
                                        <Text
                                            variant="body2"
                                            fontWeight="semibold"
                                        >
                                            Top Rated App
                                        </Text>
                                        <Flex justifyContent="end">
                                            {appRating?.rating ? (
                                                <StarRating
                                                    stars={appRating?.rating}
                                                />
                                            ) : null}
                                            <Text
                                                variant="caption"
                                                marginLeft={1}
                                            >
                                                {appRating?.rating}/5 iOS
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            )}
                        </Box>
                    )}
                    {appleMapsPartner && (
                        <Button
                            variant="tertiary"
                            onClick={this._onViewNearbySpotsClick}
                            padding={3}
                            margin="auto"
                            display="block"
                            fontSize="sm"
                        >
                            View More Spots Nearby
                        </Button>
                    )}
                    {isLoadingRates && <SpotDetailsPlaceholder />}
                    {isRateLoadError && (
                        <Alert className="SpotDetails-rate-error" type="danger">
                            There was an error retrieving the facility
                            information.{' '}
                            <Button
                                lineHeight="1.4"
                                marginLeft={1}
                                variant="tertiary"
                                fontSize="sm"
                                onClick={this._onRetryClick}
                            >
                                Retry
                            </Button>
                        </Alert>
                    )}
                    {hasFacilityAndRateData &&
                        this._renderThingsYouShouldKnow(spot, appleMapsPartner)}
                    {hasFacilityAndRateData && this._renderAmenities(spot)}
                    {hasFacilityAndRateData && this._renderAccessHours(spot)}
                    {hasFacilityData &&
                        this._renderRedemptionInstructions(spot.facility)}
                    {hasFacilityData && this._renderGettingThere(spot.facility)}
                    {hasFacilityData && this._renderReviews(spot.facility)}
                    {isAdmin && hasFacilityData && this._renderSpotNotes(spot)}
                    {hasFacilityData &&
                        this._renderFooter(
                            spot.facility,
                            canCancelHPEReservation,
                            eventCancellationMinutes
                        )}
                    {hasFacilityData && (
                        <ReservationSummary
                            spot={spot}
                            isSticky
                            isStickyVersionShowing={
                                showStickySpotDetailsReservationSummary
                            }
                            isNonRefundable={false}
                            isLoadingRates={isLoadingRates}
                            showSpotUnavailable={showSpotUnavailable}
                            onBookSpotClick={this._onBookSpotClick}
                            onToggleEditTimesModal={
                                this._onToggleEditTimesModal
                            }
                            onShowRateDetailsClick={
                                this._onShowRateDetailsClick
                            }
                        />
                    )}
                </div>
                {showRateDetailsModal && hasFacilityAndRateData && (
                    <RateDetailsModal
                        spot={spot}
                        section={rateDetailsSection}
                        onHidden={this._onHideAllModals}
                    />
                )}
                {showPriceBreakdownModal && (
                    <SpotDetailPriceBreakdownModal
                        spot={spot}
                        onHidden={this._onHideAllModals}
                    />
                )}

                {showOnlineCommuterRateModal && hasFacilityAndRateData && (
                    <OnlineCommuterRateModal
                        title="Commuter Rate"
                        description={
                            spot.selectedRate?.online_commuter_rate_description
                        }
                        onHidden={this._onHideAllModals}
                        hideContentTitle
                    />
                )}

                {(appleMapsPartner || promptForTime) && isMobile ? (
                    <EditTimesModal
                        isOpen={editTimesModalOpen}
                        showExpandedVersion={
                            promptForTime || isEmpty(destination)
                        }
                        onClose={this._onToggleEditTimesModal}
                        facility={hasFacilityData ? spot.facility : null}
                        isAdmin={isAdmin}
                        timezone={city.timezone}
                        starts={starts}
                        ends={ends}
                        showSpotUnavailable={showSpotUnavailable}
                        isLoading={isLoadingRates}
                        onBackToSearch={this._onHandleCloseClick}
                        spotId={spotId}
                        spots={spots}
                        updateSelectedSpot={updateSelectedSpot}
                        errorLoadingRates={isRateLoadError}
                    />
                ) : null}
            </>
        );
    }
}

const mapStateToProps = state => {
    const {
        city: {data: city},
        destination: {data: destination},
        event: {data: event},
        user: {data: user},
        search: {
            data: {referrerAffiliate},
        },
        searchRequest,
        spot: {spotDetailReferrer},
        checkout: {isRebookingReservation},
        spots: {data: spots},
        appRating: {data: appRating},
        filters: {activeAmenities, showTotal},
    } = state;
    const vehicleInfoId = getVehicleInfo(state)?.id;

    const appleMapsPartner =
        searchRequest.partner === 'apple' ||
        searchRequest.partner === 'appleTest';

    return {
        city,
        destination,
        event,
        user,
        referrerAffiliate,
        searchRequest,
        appRating,
        appleMapsPartner,
        spots,
        spotDetailReferrer,
        activeAmenities,
        vehicleInfoId,
        showTotal,
        isRebookingReservation,
    };
};

const mapDispatchToProps = {
    pushTo: push,
    onUpdateData: updateData,
    updateSelectedSpot: updateSelected,
};

const enhance = compose(
    withRouter,
    withConfig(['isMobile']),
    withOptimizely,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(SpotDetails);
