import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NoResultsForFiltersMessage from '../no-results-for-filters-message';
import { Box, Card, Flex, Image, List, Button, Heading, ListItem, Text, useBreakpointValue, } from '@spothero/ui';
import Link from 'router/Link';
import trackErrorMessageDisplayed from 'segment/events/error-message-displayed';
import SearchTracking from 'utils/search-tracking';
import { Page } from 'utils/page-utils';
import getParkingType from 'segment/utils/parking-type';
import ErrorUtils from 'utils/error-utils';
import { useSelector } from 'react-redux';
import CityAPI from 'api/city';
import isEqual from 'lodash/isEqual';
import { getRouteParam, paramNameOptions } from 'utils/url-utils';
import { formatDateTime } from 'utils/format-date-time';
import UrlUtils from '@spothero/utils/url';
const headingmsg = 'No results found';
const errormsg = `To see available parking, you can try running a search againor explore options in the closest city to you`;
const getSegmentProperties = (state, numFilters) => {
    const coreProperties = {
        message: headingmsg,
        errorMessage: numFilters > 0
            ? `But don't worry, there are a few things you can try to broaden your search: Consider removing the filters to see all available spots. Zoom out on the map to expand your search area. Sometimes, there are great options just a little further away.`
            : errormsg,
        action: numFilters > 0 ? 'remove filters' : 'no available spots',
        screen: 'search',
        errorHeader: numFilters > 0
            ? `We couldn't find any nearby spots that match the filters you applied.`
            : `We couldn't find any available spots nearby`,
        currentScreenName: 'search',
    };
    try {
        const { city: { data: { slug: citySlug }, }, destination: { data: { airport }, }, event: { data: { id: eventId }, }, searchRequest: { monthly, search_string: searchQuery, pageType }, } = state;
        const isEvent = Boolean(eventId);
        const isEventPackage = pageType === Page.EVENT_PACKAGES_SEARCH;
        const { searchUUID } = SearchTracking.getValues();
        const parkingType = getParkingType({
            isMonthly: monthly,
            isAirport: airport,
            isEvent,
            isEventPackage,
        });
        return {
            ...coreProperties,
            city: citySlug,
            pageType,
            parkingType,
            query: searchQuery,
            searchId: searchUUID,
        };
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            customErrorMessage: 'SpotListNoResults: Error getting segment properties',
            error,
        });
        return coreProperties;
    }
};
const SpotListNoResults = ({ numFilters, onResetFiltersClick, 
// eslint-disable-next-line no-empty-function
close = () => { }, }) => {
    const isMobile = useBreakpointValue({ base: true, tablet: false });
    const { city: cityState, destination, event, searchRequest, search, } = useSelector((s) => s);
    const [cities, setCities] = useState([]);
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const { monthly, starts, ends, timezone } = searchRequest;
    const listItemStyles = {
        listStylePosition: 'outside',
        marginY: 2,
    };
    /*
        Below toFixed pattern is due to zoom/pan using different decimal depth than search, this normalizes it
        decimal place 3 === neighborhood, street precision per https://en.wikipedia.org/wiki/Decimal_degrees

        Below isn't defined initially so we needed to check for it
    */
    const searchState = useMemo(() => ({
        latitude: Boolean(search.searchCenterPostZoomOrPan?.latitude) &&
            search.searchCenterPostZoomOrPan?.latitude.toFixed(3),
        longitude: Boolean(search.searchCenterPostZoomOrPan?.longitude) &&
            search.searchCenterPostZoomOrPan?.longitude.toFixed(3),
    }), [search]);
    const searchRequestState = useMemo(() => ({
        latitude: Number(searchRequest.latitude).toFixed(3),
        longitude: Number(searchRequest.longitude).toFixed(3),
    }), [searchRequest]);
    const ContainerComponent = isMobile ? Box : Card;
    // Confirm searchState exists then check equality
    const showButton = searchState.latitude && !isEqual(searchState, searchRequestState);
    const onClick = useCallback(() => {
        // Event cooresponds to google-map-widget
        const returnToCenterEvent = new Event('sh-search-map-return-to-center');
        window.dispatchEvent(returnToCenterEvent);
        close();
    }, [close]);
    useEffect(() => {
        const properties = getSegmentProperties({ city: cityState, destination, event, searchRequest }, numFilters);
        trackErrorMessageDisplayed(properties);
    }, [numFilters, cityState, destination, event, searchRequest]);
    useEffect(() => {
        let mounted = true;
        const getCities = async () => {
            try {
                const citiesResponse = await CityAPI.getNearestCitiesWithLatLon({
                    latitude: searchState?.latitude ||
                        searchRequestState.latitude,
                    longitude: searchState?.longitude ||
                        searchRequestState.longitude,
                }, 2);
                if (citiesResponse && mounted) {
                    const urlParams = {
                        starts: formatDateTime(starts, timezone),
                        ends: formatDateTime(ends, timezone),
                        monthly: getRouteParam(paramNameOptions.MONTHLY),
                        view: getRouteParam(paramNameOptions.VIEW),
                    };
                    const citiesWithURL = citiesResponse.map(city => {
                        const urlParamsWithCity = {
                            ...urlParams,
                            id: city.id,
                            kind: Page.CITY,
                        };
                        return {
                            ...city,
                            cityUrl: `/search?${UrlUtils.createQueryString(urlParamsWithCity)}`,
                        };
                    });
                    setCities(citiesWithURL);
                }
            }
            catch (error) {
                // if it fails show text
                setApiErrorMsg('Error retrieving cities.');
            }
        };
        getCities();
        return () => {
            mounted = false;
        };
    }, [searchState, searchRequestState, monthly, starts, ends, timezone]);
    if (numFilters > 0) {
        return (<NoResultsForFiltersMessage onResetFiltersClick={onResetFiltersClick}/>);
    }
    return (<ContainerComponent data-testid="SpotListNoResults" backgroundColor="white" paddingTop={4}>
            <Flex justifyContent="center">
                <Image src="/img/search/spothero-magnifying-glass.svg" alt="Magnifying glass"/>
            </Flex>

            <Box padding={2} textAlign="center">
                <Heading variant="h5" paddingY={2}>
                    No results in this area
                </Heading>

                <Text variant="body2" paddingBottom={2}>
                    To see available parking, you can try running a search again
                    or explore options in the closest city to you
                </Text>
                {showButton && (<Button marginY="2" textTransform="initial" onClick={onClick} data-testid="SpotListNoResults-button">
                        Return to Original Search
                    </Button>)}

                <Heading variant="h6" marginTop="4" marginBottom="2">
                    Closest Cities:
                </Heading>

                <List type="unstyled" marginBottom={isMobile ? '10' : '0'} minHeight="14">
                    {cities?.map((city, index) => (<ListItem sx={listItemStyles} key={city.id}>
                            <Link forceAnchor data-testid={`SpotListNoResults-link-${index}`} to={city?.cityUrl}>
                                {city.title}
                            </Link>
                        </ListItem>))}
                    {apiErrorMsg && (<ListItem sx={listItemStyles}>
                            <Text>{apiErrorMsg}</Text>
                        </ListItem>)}
                </List>
            </Box>
        </ContainerComponent>);
};
export default SpotListNoResults;
