/* eslint-disable camelcase */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DESTINATION_DATA} from '@/proptypes/index';
import dayjs from 'utils/dayjs-timezone';
import IconChevronRight from '@spothero/icons/chevron-right';
import withConfig from '@/config/withConfig';
import EventSchema from 'seo/event-schema';
import {formatDateTime} from 'utils/format-date-time';
import {Box, Icon, Flex, Text} from '@spothero/ui';
class EventListItem extends Component {
    static propTypes = {
        event: PropTypes.object.isRequired,
        destination: PropTypes.shape(DESTINATION_DATA).isRequired,
        city: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        siteUrl: PropTypes.string.isRequired,
        showVenue: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const {
            event: {starts, ends},
            city: {timezone},
        } = props;

        this._starts = dayjs(formatDateTime(starts, timezone));
        this._ends = dayjs(formatDateTime(ends, timezone));
        this._startsISO = this._starts.toISOString();
        this._endsISO = this._ends.toISOString();
    }

    _onClick = () => {
        const {event, onClick} = this.props;

        if (onClick) {
            onClick(event);
        }
    };

    render() {
        const {
            event: {
                title,
                search_url: eventUrl,
                meta_description: eventMetaDescription,
            },
            destination,
            city,
            siteUrl,
            showVenue,
        } = this.props;
        const startsDateFormat = this._starts.isSame(dayjs(), 'day')
            ? '[Today],  h:mm A'
            : 'ddd, h:mm A';

        const endDateFormat =
            this._ends.isSame(dayjs(), 'day') ||
            this._ends.isSame(this._starts, 'day')
                ? 'h:mm A'
                : 'ddd, h:mm A';

        return (
            <div
                className="EventListItem"
                itemScope
                itemType="http://schema.org/Event"
                onClick={this._onClick}
            >
                <div className="EventListItem-date">
                    <span>{this._starts.format('MMM')}</span>
                    <span>{this._starts.format('DD')}</span>
                </div>
                <Box width="full">
                    <Flex
                        justify="space-between"
                        align="baseline"
                        width="full"
                        position="relative"
                    >
                        <Text
                            itemProp="name"
                            as="p"
                            fontWeight="semibold"
                            color="black"
                            fontSize="base"
                            lineHeight="short"
                            marginBottom="1"
                            paddingRight="6"
                            wordBreak="break-word"
                        >
                            {title}
                        </Text>
                    </Flex>
                    <div>
                        <Text
                            as="p"
                            color="black"
                            fontSize="sm"
                            lineHeight="16.34px"
                        >
                            {showVenue && `${destination.title} · `}
                            {this._starts.format(startsDateFormat)}
                            {` - ${this._ends.format(endDateFormat)}`}
                        </Text>
                    </div>
                </Box>
                <EventSchema
                    url={eventUrl}
                    metaName={title}
                    metaDescription={eventMetaDescription}
                    starts={this._startsISO}
                    ends={this._endsISO}
                    destination={destination}
                    city={city}
                />
                <span
                    itemScope
                    itemProp="offers"
                    itemType="http://schema.org/AggregateOffer"
                >
                    <meta itemProp="url" content={`${siteUrl}/${eventUrl}`} />
                </span>
                <Icon
                    as={IconChevronRight}
                    marginRight="4"
                    data-testid="RightChevronIcon"
                />
            </div>
        );
    }
}

export default withConfig(['siteUrl'])(EventListItem);
