import PropTypes from 'prop-types';
import { USER_TYPES } from 'utils/types/user';
const { CURRENCY_TYPES, AUTH_STATE } = USER_TYPES;
export const CREDIT_WALLET_DATA = {
    credit: PropTypes.number,
    currencySymbol: PropTypes.string,
    currencyType: PropTypes.oneOf(Object.keys(CURRENCY_TYPES).map(key => CURRENCY_TYPES[key])),
    credits: PropTypes.string, // "US$13 | CA$34"
};
export const CREDIT_CARD_DATA = {
    externalId: PropTypes.string,
    type: PropTypes.string,
    last4: PropTypes.string,
    isDefault: PropTypes.bool,
    wallet: PropTypes.string,
};
export const PAYPAL_PAYMENT_DATA = {
    externalId: PropTypes.string.isRequired,
    paypalEmail: PropTypes.string.isRequired,
    wallet: PropTypes.string.isRequired,
};
export const VEHICLE_DATA = {
    id: PropTypes.number,
    make: PropTypes.string,
    makeLabel: PropTypes.string,
    model: PropTypes.string,
    modelLabel: PropTypes.string,
    year: PropTypes.number,
    isDefault: PropTypes.bool, // false
};
export const USER_DATA = {
    creditCards: PropTypes.arrayOf(PropTypes.shape(CREDIT_CARD_DATA)),
    paypalCard: PropTypes.shape(PAYPAL_PAYMENT_DATA),
    creditWallets: PropTypes.objectOf(PropTypes.shape(CREDIT_WALLET_DATA)),
    dateJoined: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.number,
    isAdmin: PropTypes.bool,
    isSeller: PropTypes.bool,
    justCreated: PropTypes.bool,
    lastName: PropTypes.string,
    licensePlates: PropTypes.arrayOf(PropTypes.shape({
        licensePlateId: PropTypes.number,
    })),
    phoneNumber: PropTypes.string,
    referralToken: PropTypes.string,
    status: PropTypes.oneOf(Object.keys(AUTH_STATE).map(key => AUTH_STATE[key])),
    vehicles: PropTypes.arrayOf(PropTypes.shape(VEHICLE_DATA)),
};
