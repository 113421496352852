import { DEFAULT_RATING, DEFAULT_USER_RATING_COUNT, } from 'api/app-rating';
const initialState = {
    data: {
        rating: DEFAULT_RATING,
        ratingCount: DEFAULT_USER_RATING_COUNT,
    },
};
export default function appRating(state = initialState) {
    return state;
}
