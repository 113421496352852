import React from 'react';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';
import { redirect } from 'utils/window';
import useConfig from 'hooks/use-config';
import Auth0APISetupProvider from './Auth0APISetupProvider';
const isString = (toCheck) => typeof toCheck === 'string' || toCheck instanceof String;
const AuthProvider = ({ children }) => {
    const { siteUrl, auth0Domain, auth0ClientID, auth0AudienceV1API, } = useConfig();
    // Below winnows non string values
    if (!isString(auth0Domain) || !isString(auth0ClientID)) {
        // eslint-disable-next-line no-console
        console.debug('Auth0 not configured');
        return children;
    }
    const onRedirectCallback = (appState) => {
        /* we get the state (redirect params) passed in from loginWithRedirect to auth0 here & then send them back to `/auth/callback` with the next param set to the returnTo value.   This is so that we can redirect them back to the  page they were on before they were redirected to auth0 to login. We have to do this because all the auth checks required for monolith don't occur until auth/callback is hit
         */
        redirect(appState
            ? `${siteUrl}/auth/callback?next=${appState?.returnTo}`
            : `${siteUrl}/auth/callback`);
    };
    return (<Auth0Provider domain={auth0Domain} clientId={auth0ClientID} audience={auth0AudienceV1API} redirectUri={`${location.origin}/auth/pending`} scope="read:current_user update:current_user_metadata" onRedirectCallback={onRedirectCallback}>
            <Auth0APISetupProvider>{children}</Auth0APISetupProvider>
        </Auth0Provider>);
};
AuthProvider.propTypes = {
    children: PropTypes.node,
};
export default AuthProvider;
