import {formatAccessHours} from 'utils/spot-format-access-hours';
import ErrorUtils from 'utils/error-utils';
import {
    handleCancellation,
    handleIllustrations,
    handleHours,
} from 'api/search-v2/utils/format-v2-to-v1-common';
import FormatUtils from 'utils/format';
import Config from '@/config/index';
import {formatAddressTypes} from 'api/search-v2/utils';
import {transformRateV2ToRateV1Transient} from 'api/search-v2/transient/format/format-v2-to-v1';
import {formatTransportationType} from '../utils/formatTransportationType';
const {currencySymbol} = FormatUtils;

/**
 * This is to format V2 Facility repsonse to V1 Rates Response
 *
 * @param {*} - This is the response from the API
 * @returns {*} - This is the transformed
 */
export const transformV2SingleFacilityToV1RatesAirport = (v2Response = {}) => {
    const {
        spotId,
        title,
        distance,
        availability,
        requirements,
        selectedRate,
        rating,
        addresses = [],
        images = [],
        rates = [],
        restrictions,
        hoursOfOperation,
        navigationTip,
        facilityType,
        visualFlags,
        allAmenities,
        parkingTypes,
        redemptionInstructions,
        operatorDisplayName,
        supportedFeeTypes,
        cancellation,
        slug,
        isScanToPay,
        barcodeType,
        operatorId,
        oversizeFeesChargedOnsite,
        reservationExtensionEnabled,
        allowUpdatingReservationAfterEntry,
        requireCreditCard,
        status,
        airportCode,
        transportation,
    } = v2Response;

    try {
        const primeAddress = addresses[0] || {};
        const currencyCode = selectedRate?.advertisedPrice?.currencyCode?.toLowerCase();
        const hours_of_operation = handleHours(hoursOfOperation);
        const access_hours = formatAccessHours(hours_of_operation);
        const numberId = parseInt(spotId, 10);

        const topUrl = `${Config.siteUrl}/checkout/${numberId}/${slug}/`;

        const shuttleHoursOfOperation = handleHours(
            transportation?.hoursOfOperation
        );

        const valueToReturn = {
            airportCode,
            parking_spot_id: numberId,
            title,
            distance,
            latitude: primeAddress?.latitude,
            longitude: primeAddress?.longitude,
            default_image_url: images[0]?.url,
            hourly_rates: availability?.available
                ? rates.map(rate =>
                      transformRateV2ToRateV1Transient({
                          rate,
                          access_hours,
                      })
                  )
                : [],
            license_plate_required: requirements?.licensePlate,
            phone_number_required: requirements?.phoneNumber,
            timezone: primeAddress?.timeZone,
            spot_url: topUrl,
            wd_latitude: primeAddress?.latitude,
            wd_longitude: primeAddress?.longitude,
            currency_type: currencyCode,
            currency_symbol: currencySymbol(currencyCode),
            event_cancellation_minutes:
                selectedRate?.cancellationThresholdMinutes || 0,
            rating_info: {
                number_of_ratings: rating?.count,
                star_rating: rating?.average,
            },
            available_transient_inventory: availability?.availableSpaces,
            availability,
            facility: {
                id: numberId,
                parking_spot_id: numberId,
                title,
                status,
                street_address: primeAddress.streetAddress,
                city: primeAddress.city,
                state: primeAddress.state,
                zipcode: primeAddress.postalCode,
                latitude: primeAddress.latitude,
                longitude: primeAddress.longitude,
                country: primeAddress.country,
                timezone: primeAddress.timeZone,
                height_restriction: null, // is restrictions which isn't height specific, will need refactor for monthly as used there.
                height_restriction_description: '', // is restrictions which isn't height specific, will need refactor for monthly as used there.
                barcode_type: barcodeType,
                restrictions,
                hours_of_operation,
                getting_here: navigationTip,
                slug,
                addresses: addresses.map(
                    ({id, postalCode, streetAddress, types, ...address}) => ({
                        ...address,
                        types: formatAddressTypes(types),
                        street_address: streetAddress,
                        zipcode: postalCode,
                        id: parseInt(id, 10),
                    })
                ),
                redemption_instructions_arrival: redemptionInstructions.arrival?.map(
                    ({text, illustration, id}, i) => {
                        const illustrationValues = illustration?.url
                            ? handleIllustrations(illustration?.url)
                            : [];

                        return {
                            illustration_id: illustrationValues[2],
                            illustration_version: illustrationValues[1],
                            text,
                            id: parseInt(id, 10),
                            position: i,
                        };
                    }
                ),
                redemption_instructions_departure: redemptionInstructions.departure?.map(
                    ({text, illustration, id}, i) => {
                        const illustrationValues = illustration?.url
                            ? handleIllustrations(illustration?.url)
                            : [];

                        return {
                            illustration_id: illustrationValues[2],
                            illustration_version: illustrationValues[1],
                            text,
                            id: parseInt(id, 10),
                            position: i,
                        };
                    }
                ),
                facility_type: {
                    slug: facilityType,
                    display_name: facilityType, // just show slug for now as unused
                },
                cancellation_allowed: handleCancellation(cancellation),
                cancellation_minutes: cancellation?.minutes,
                is_scan_to_pay: isScanToPay,
                visual_flags: visualFlags,
                operator_display_name: operatorDisplayName,
                amenities_full: allAmenities.map(
                    ({type, displayName}, index) => ({
                        slug: type,
                        name: displayName,
                        sort_order: index,
                        visible: true,
                        icon_url: '', // Not in the amenity array, removable?
                    })
                ),
                images: images.map((image, i) => {
                    const {url: imageUrl, urlTemplate} = image;
                    const [, version, id] = handleIllustrations(imageUrl);

                    return {
                        id,
                        version,
                        center_x: 375, // not in new response, removable?
                        center_y: 250, // not in new response, removable?
                        order: i,
                        url_template: urlTemplate,
                    };
                }),
                facility_url: topUrl,
                rating_info: {
                    number_of_ratings: rating.count,
                    star_rating: rating.average,
                },
                transient_available: parkingTypes.includes('transient'),
                monthly_available: parkingTypes.includes('monthly'),
                airport_available: parkingTypes.includes('airport'),
                supported_fee_types: supportedFeeTypes,
                operator_id: operatorId,
                access_hours_formatted: access_hours,
                oversize_fees_charged_onsite: oversizeFeesChargedOnsite,
                reservationExtensionEnabled,
                allowUpdatingReservationAfterEntry,
                freePark: requireCreditCard === false,
            },
            spotId: numberId,
            isFavorite: false, // where does this hydrate?  or do we even hydrate it?
            hidden: false, // Unsure if we need check with FE team
            selectedRate: availability?.available
                ? transformRateV2ToRateV1Transient({
                      rate: selectedRate,
                  })
                : null,
            url: topUrl,
            shuttle: {
                duration: transportation?.schedule?.duration,
                fast_frequency: transportation?.schedule?.fastFrequency,
                hours: {
                    ...shuttleHoursOfOperation,
                    periods: shuttleHoursOfOperation?.periods,
                },
                slow_frequency: transportation?.schedule?.slowFrequency,
                images: transportation?.images?.map(image => ({
                    id: image.id,
                    url_template: image.url,
                    alt: image.altText,
                })),
                type: formatTransportationType(transportation?.type),
            },
        };

        return valueToReturn;
    } catch (e) {
        ErrorUtils.sendSentryMessage({
            error: e,
            customErrorMessage:
                'transformV2SingleFacilityToV1RatesAirport ERROR',
        });
    }

    return {};
};
