/* V2-CLEANUP
 Delete this file when Search V2 Integration is complete
 This is used on Spot Details, and Checkout experiences
 This is used on Transient, Monthly, and Event verticals */
import isUndefined from 'lodash/isUndefined';
import React, { Component } from 'react';
import classNames from 'classnames';
import SpotUtils from 'utils/spot';
import Image from 'common/image';
import withConfig from '@/config/withConfig';
import StarRatingWithCategory from 'common/star-rating-with-category';
import WalkingDistanceDisplay from 'common/spot-details/walking-distance';
import { Box, Button } from '@spothero/ui';
import AvailableSpotsBanner from './components/available-spots-banner';
import VisualFlag from '../visual-flag';
import VehicleBanner from 'common/vehicle-banner';
export class FacilitySummary extends Component {
    constructor(props) {
        super(props);
        this._renderPrice = () => {
            const { spot: { selectedRate }, } = this.props;
            let displayPriceFormatted = 'N/A';
            if (selectedRate) {
                const { display_price: displayPrice, currency_type: currencyType, } = selectedRate;
                displayPriceFormatted = SpotUtils.formatRatePrice({
                    price: displayPrice,
                    currencyType,
                });
            }
            return (<div className="price" dangerouslySetInnerHTML={{ __html: displayPriceFormatted }}/>);
        };
        const { imageWidth, imageHeight, spot: { facility, title }, staticBaseUrl, } = props;
        const firstImage = !isUndefined(facility.images[0])
            ? facility.images[0]
            : null;
        this._imgProps = {
            width: imageWidth,
            height: imageHeight,
            alt: `Photo of ${title}`,
            lazyLoad: true,
            ...(firstImage
                ? { cloudinaryTemplate: firstImage.url_template }
                : { src: `${staticBaseUrl}img/missing-image.png` }),
        };
    }
    render() {
        const { isAdmin, showAdminLink, spot: { spotId, title, selectedRate, distance: { linearMeters, walkingMeters, walkingDuration }, facility: { rating_info: { number_of_ratings: numberOfReviews, star_rating: starRating, }, visual_flags: visualFlags = [], }, }, priceLabel, showFullTitle, showPrice, showImages, isSpotsLeftBannerVisible, availableSpaces, appleMapsPartner, view, } = this.props;
        const unavailableReason = selectedRate?.unavailable_reason;
        const classes = classNames('FacilitySummary', {
            'FacilitySummary-hide-images': !showImages,
        });
        const titleClasses = classNames('FacilitySummary-title', { 'show-full-title': showFullTitle }, { 'hide-price': !showPrice });
        return (<div className={classes}>
                {showImages && (<div className="images">
                        {/* @ts-ignore*/}
                        <Image {...this._imgProps}/>
                    </div>)}
                <div className="detail">
                    <VehicleBanner />
                    {isSpotsLeftBannerVisible ? (<AvailableSpotsBanner availableSpots={availableSpaces}/>) : null}
                    {visualFlags.map((flag, i) => (<VisualFlag {...flag} key={`VisualFlag:${i}:FacilitySummaryV1`} view={view}/>))}
                    <h2 className="heading-md">
                        <span className={titleClasses}>{title}</span>
                        {isAdmin && showAdminLink && (<Button variant="tertiary" fontSize="sm" marginLeft={2} display={{
                    base: 'none',
                    desktop: 'inline-block',
                }} as="a" href={`/admin/spots/${spotId}`}>
                                Facility Admin
                            </Button>)}
                    </h2>
                    <Box paddingBottom={1}>
                        <StarRatingWithCategory numberOfReviews={numberOfReviews} starRating={starRating}/>
                    </Box>
                    {!appleMapsPartner && (<WalkingDistanceDisplay linearMeters={linearMeters} walkingMeters={walkingMeters} walkingDuration={walkingDuration} isSpotDetails/>)}
                    {isAdmin && unavailableReason && (<span className="not-available-reason text-light">
                            <sup>*</sup> {unavailableReason}
                        </span>)}
                </div>
                {showPrice && (<div className="FacilitySummary-price-container">
                        {this._renderPrice()}
                        <span>{priceLabel}</span>
                    </div>)}
            </div>);
    }
}
FacilitySummary.defaultProps = {
    imageWidth: 192,
    imageHeight: 192,
    priceLabel: 'subtotal',
    lowInventoryThreshold: 3,
    showPrice: true,
    showImages: true,
    showFullTitle: false,
};
export default withConfig(['staticBaseUrl'])(FacilitySummary);
