export const NOTIFY = 'NOTIFY';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const notify = (data) => {
    return {
        type: NOTIFY,
        payload: data,
    };
};
export const clearNotification = () => {
    return {
        type: CLEAR_NOTIFICATION,
    };
};
