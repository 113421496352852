import isInteger from 'lodash/isInteger';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StarRating from 'common/star-rating';
import FormatUtils from 'utils/format';
import SHUtils from 'utils/sh-utils';

const Reviews = ({ratingInfo}) => {
    const {number_of_ratings: count, star_rating: rating} = ratingInfo;
    const category = SHUtils.getRatingsCategory(rating);
    const truncatedRating =
        typeof rating === 'number'
            ? isInteger(rating)
                ? rating
                : rating.toFixed(1)
            : null;
    const classes = classNames('SpotDetails-section', 'Reviews');

    return (
        <div className={classes} data-testid="Reviews">
            <h4 className="subtitle">Facility Reviews</h4>
            {count >= 10 ? (
                <>
                    <div className="Reviews-rating">
                        <span className="Reviews-fraction">
                            {truncatedRating}/5
                        </span>
                        <StarRating stars={rating} />
                        {category && (
                            <span className="Reviews-category">{category}</span>
                        )}
                    </div>
                    <span className="Reviews-based-on">
                        Based on {FormatUtils.numWithCommas(count)} reviews from
                        SpotHero customers.
                    </span>
                </>
            ) : (
                <>
                    <div className="Reviews-rating">
                        <StarRating stars={0} />
                        <span className="Reviews-category">No Reviews</span>
                    </div>
                    <span className="Reviews-based-on">
                        No reviews from SpotHero customers.
                    </span>
                </>
            )}
        </div>
    );
};

Reviews.propTypes = {
    ratingInfo: PropTypes.object.isRequired,
};

export default Reviews;
