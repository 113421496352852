import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import SearchActions from 'common/search-controls/submit-cancel-actions';
import SearchInput from 'common/search-controls/location-input';
import SearchTermToggle from 'common/search-controls/search-term-toggle';
import {GoogleMapsProvider} from 'common/google-map-context';

const NotFound = ({
    isMobile,
    isSearching,
    isMonthly,
    isValidStart,
    onInputChange,
    onSubmit,
    onSearchClick,
}) => (
    <GoogleMapsProvider>
        <div className="NotFound">
            <Helmet>
                <title>D&apos;oh! This page doesn&apos;t exist.</title>
                <body className="standalone-page" />
            </Helmet>
            <div className="NotFound-hero">
                <h1 className="NotFound-error-code">404</h1>
                <div className="NotFound-content-wrap">
                    <div className="NotFound-content">
                        <h2 className="NotFound-error-message">
                            Looks like you made a wrong turn.
                            <br />
                            This page doesn&apos;t exist, but you can still get
                            parking below.
                        </h2>
                        <div className="NotFound-search-controls">
                            <SearchTermToggle />
                            <SearchInput
                                isMonthly={isMonthly}
                                isSearching={isSearching}
                                onChange={onInputChange}
                                onSubmit={onSubmit}
                            />
                            <SearchActions
                                addKeyListeners={!isMobile}
                                isSearching={isSearching}
                                isValidStart={isValidStart}
                                onSearchClick={onSearchClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </GoogleMapsProvider>
);

NotFound.propTypes = {
    isMobile: PropTypes.bool,
    isSearching: PropTypes.bool,
    isMonthly: PropTypes.bool,
    isValidStart: PropTypes.bool,
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSearchClick: PropTypes.func,
};

export default NotFound;
