/*
    Custom sizes and spacing that are specific to Consumer Web
    Dimensions of common UI elements will likely be referenced for both size and spacing purposes.

    SIZE
    Sizes can be referenced by the width, height, and maxWidth, minWidth, maxHeight, minHeight styles.
    More: https://chakra-ui.com/docs/theming/theme#sizes

    SPACING
    Spacing values can be referenced by the padding, margin, and top, left, right, bottom styles.
    More: https://chakra-ui.com/docs/theming/theme#spacing
*/

// Global headers
const headers = {
    'header-height-mobile': '52px',
    'header-height-desktop': '64px',
};
// Transient, Monthly, and Event Package Search page
const searchUI = {
    'zone-1-width-desktop': '512px',
    'event-list-width-desktop': '300px',
    'spot-details-width-desktop': '512px',
};

export const sizes = {
    ...headers,
    ...searchUI,
};

export const space = {
    ...headers,
    ...searchUI,
};
