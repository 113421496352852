import {
    Page,
    fetchCity,
    foldWithSearchState,
    isMonthlyPage,
} from 'utils/page-utils';
import {initialState} from 'store/destination/destination-reducer';

const getInitialState = async ({state, params, query, cookies, route}) => {
    const pathname = route?.location?.pathname || params?.city;
    const slug = pathname.split('/').filter(Boolean);
    const cleanedSlug = slug[slug.length - 1].replace(/-parking$/, '');
    const city = await fetchCity(state, cleanedSlug);
    const isMonthly = isMonthlyPage(route);

    if (isMonthly) {
        query.monthly = isMonthly;
    }

    const {
        data: {latitude, longitude, timezone},
    } = city;

    // clear stale destination state if any as it will cause wrong components to render
    const destination = initialState();

    return foldWithSearchState({
        state: {...state, destination, city},
        type: Page.CITY,
        latitude,
        longitude,
        timezone,
        query,
        cookies,
    });
};

export default getInitialState;
