import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toggleShowTotal } from 'store/filters/filters-actions';
import { getTotalPriceUserPreference, TotalPriceSessionPreference, } from 'utils/total-price-session-utils';
export const useSyncTotalPriceToggleOnMount = () => {
    const totalPricePreferenceFromSession = getTotalPriceUserPreference();
    const dispatch = useDispatch();
    const showTotal = useSelector((state) => state.filters.showTotal);
    const sessionShowTotal = totalPricePreferenceFromSession === TotalPriceSessionPreference.On;
    // Sync the show total state with the user preference from session
    useEffect(() => {
        if (totalPricePreferenceFromSession !==
            TotalPriceSessionPreference.NotSelected &&
            showTotal !== sessionShowTotal) {
            dispatch(toggleShowTotal(sessionShowTotal));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
