import React from 'react';
import { useSelector } from 'react-redux';
import { isMoreThan24Hours } from 'utils/time';
import { FilterAmenity } from '../../../filter-bases';
export const FilterInOut = () => {
    const { city: { data }, searchRequest: { starts, ends }, } = useSelector((state) => state);
    // moved down to satisfy TS
    const { display_name: cityName } = data || {};
    if (starts && ends) {
        // Search uses 30 minute increments, so we need to round up to the nearest hour
        const isSearchDurationMoreThan24Hours = isMoreThan24Hours({
            starts,
            ends,
        });
        const isSearchCityNYC = cityName === 'NYC';
        const isVisible = isSearchDurationMoreThan24Hours && !isSearchCityNYC;
        return isVisible ? (<FilterAmenity amenity={'in-out'}>In & Out Allowed</FilterAmenity>) : null;
    }
    return null;
};
export default FilterInOut;
