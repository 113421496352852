import APIUtils from '@spothero/utils/api';
import ObjectUtils from '@spothero/utils/object';
import ErrorUtils from 'utils/error-utils';
export const transformRentalAddons = (rawRentalAddonResults) => {
    const results = rawRentalAddonResults.results;
    if (!results || results.length === 0) {
        return [];
    }
    const camelizedRawRentalAddons = ObjectUtils.camelizeKeys(results);
    const transformedAddOns = camelizedRawRentalAddons.map(rawRentalAddon => ({
        type: rawRentalAddon.type,
        price: rawRentalAddon.price,
        sku: rawRentalAddon.sku,
        context: rawRentalAddon.context,
    }));
    return { rentalAddons: transformedAddOns };
};
export const getRentalAddons = async ({ ruleGroupId, facilityId, starts, ends, email, }) => {
    try {
        const response = await APIUtils.post('oip/item-add-ons/available/', {
            item_type: 'rental',
            item_context: {
                facility: facilityId,
                rule_group_id: ruleGroupId,
                starts,
                ends,
            },
            email,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'SpotHero-Version': '2022-02-22',
            },
        }, false);
        return transformRentalAddons(response.data.data);
    }
    catch (err) {
        return ErrorUtils.transformAndReject(err);
    }
};
