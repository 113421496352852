import React from 'react';
import { Text, Box, RadioGroup, SelectionCard } from '@spothero/ui';
import SingleRate from './single-rate/SingleRate';
import dayjs from 'utils/dayjs';
import { getPrice } from 'hooks/spot/price/use-price';
import { useSelector } from 'react-redux';
import { useShowTotal } from 'store/selectors/hooks/useShowTotal';
export const isInOut = 'You can enter and exit this facility multiple times within your reservation time frame';
export const isNotInOut = 'Once you exit, you will not be able to re-enter this facility using the same reservation';
const MultiRateChooser = ({ rates, selectedRate, priceLabel, onMultiRateChange, onInfoButtonClick, onPriceLabelClick, }) => {
    const isPowerBooking = useSelector((state) => state.searchRequest.powerBooking);
    const showTotal = useShowTotal();
    const onRateChange = (evt) => {
        const ruleId = parseInt(evt, 10);
        onMultiRateChange(Number.isNaN(ruleId) ? evt : ruleId);
    };
    const differentDurationsArray = rates?.reduce((acc, { starts, ends }) => [...acc, dayjs(starts).diff(dayjs(ends))], []);
    const isDifferentDurations = new Set(differentDurationsArray).size > 1;
    return (<Box marginBottom="5">
            <RadioGroup id="MultiRateChooser-available-rates" data-testid="MultiRateChooser-available-rates" name="available-rates" value={`${selectedRate.fullRule}`} onChange={onRateChange} padding="0" sx={{
            '.chakra-radio__label': { width: '100%' },
            '.chakra-radio__control': { marginTop: '0' },
        }}>
                {rates?.map(rate => {
            const { fullRule, title, starts, ends, rule_type: ruleType, amenities, } = rate;
            const price = getPrice({
                spot: {
                    // @ts-expect-error
                    selectedRate: rate,
                },
                isPowerBooking,
                showTotal,
            });
            const isMonthly = Boolean(ruleType === 'monthly' || ruleType === 'monthly_rates');
            const isChecked = selectedRate?.fullRule === fullRule;
            const hasInOutAmenity = Boolean((ruleType === 'hourly_rates' ||
                ruleType === 'multirate') &&
                amenities?.find(amenity => amenity.slug === 'in-out'));
            const childText = hasInOutAmenity ? isInOut : isNotInOut;
            const ExpansionChildren = () => !isMonthly && (<Text data-testid={`radiorate-${fullRule}-children`} fontSize="xs">
                                {childText}
                            </Text>);
            return (<SelectionCard key={fullRule} isRadio isExpandable={!isMonthly} isChecked={isChecked} value={`${fullRule}`} size="xs" data-testid={`radiorate-${fullRule}`} label={<SingleRate title={title} price={price} priceLabel={priceLabel} starts={starts} ends={ends} showTotal={showTotal} differentDurations={!isMonthly && isDifferentDurations} onInfoButtonClick={onInfoButtonClick} onPriceLabelClick={() => {
                        if (onPriceLabelClick) {
                            const formattedRate = {
                                totalPrice: rate.totalPrice,
                                advertisedPrice: rate.advertisedPrice,
                                priceBreakdown: rate.priceBreakdown,
                            };
                            onPriceLabelClick(formattedRate);
                        }
                    }}/>} expandableChildren={<ExpansionChildren />}/>);
        })}
            </RadioGroup>
        </Box>);
};
export default MultiRateChooser;
