import React from 'react';
import { Modal, ModalBody } from '@spothero/ui';
import SpotListNoResults from 'common/spot-list/no-results-message';
const NoResultsModal = ({ isMobile, isFiltered, onResetFilters, onHidden, isOpen, close, }) => {
    return (<Modal className="NoResultsModal" isMobile={isMobile} isOpen={isOpen} onClose={close} title="" onHidden={onHidden}>
            <ModalBody>
                <SpotListNoResults numFilters={isFiltered ? 1 : 0} onResetFiltersClick={onResetFilters} close={close}/>
            </ModalBody>
        </Modal>);
};
export default NoResultsModal;
