import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Flex, Text, Icon, Button, Box, Link} from '@spothero/ui';
import IconInfoCircle from '@spothero/icons/info-circle-filled';
import IconSemiCircleCheck from '@spothero/icons/checkmark-circle-filled';
import {Modal, ModalContent, ModalFooter} from '@spothero/ui-backlog';
import useConfig from 'hooks/use-config';
import dayjs from 'utils/dayjs-duration';

export const getParkingValues = (
    cancellationAllowed,
    siteUrl,
    canCancelHPEReservation,
    eventCancellationMinutes
) => {
    const cancellationValues = {
        id: 0,
        text:
            // is this a high profile event and can the user cancel?
            eventCancellationMinutes > 0 && canCancelHPEReservation
                ? `Free cancellation up to ${dayjs
                      .duration(eventCancellationMinutes, 'minutes')
                      .asHours()} hours before reservation starts`
                : 'Free Cancellation',
        modalTitle: 'Free Cancellation Policy',
        modalContent: (
            <>
                <Text paddingBottom={4}>
                    {eventCancellationMinutes > 0 && canCancelHPEReservation
                        ? `This reservation can be cancelled up to ${dayjs
                              .duration(eventCancellationMinutes, 'minutes')
                              .asHours()} hours before the reservation start time`
                        : 'At this facility, you have up until the minute your reservation begins to cancel your parking'}{' '}
                    for a full refund. You can cancel your reservation on the
                    Spothero website or app.
                </Text>
                <Text>
                    If you&apos;re having trouble with your reservation and
                    it&apos;s after the start time, please reach out to our
                    Customer Heroes who are more than happy to help rectify any
                    situation!
                </Text>
            </>
        ),
    };

    const guaranteedParkingValues = {
        id: 1,
        text: 'Guaranteed parking',
        modalTitle: 'Guaranteed Parking by SpotHero',
        modalContent: (
            <>
                <Text paddingBottom={4}>
                    When you park and pay with SpotHero, we guarantee you will
                    have a spot to park in at the price you paid or your money
                    back.
                </Text>
                <Text paddingBottom={4}>
                    If you need help with your reservation, please contact us,
                    and we&apos;ll do our best to make it right. Our world-class
                    Customer Hero team is available 7 days a week, 365 days a
                    year.
                </Text>
                <Text>
                    For specifics, please refer to the{' '}
                    <Link href={`${siteUrl}/about/parking-guarantee`}>
                        SpotHero Parking Guarantee
                    </Link>
                    .
                </Text>
            </>
        ),
    };

    return cancellationAllowed
        ? [{...cancellationValues}, {...guaranteedParkingValues}]
        : [{...guaranteedParkingValues}];
};

const SpotValueProps = ({
    cancellationAllowed,
    canCancelHPEReservation,
    eventCancellationMinutes,
}) => {
    const {siteUrl} = useConfig();
    const canCancel =
        cancellationAllowed && eventCancellationMinutes > 0
            ? canCancelHPEReservation
            : cancellationAllowed;
    const parkingValues = getParkingValues(
        canCancel,
        siteUrl,
        canCancelHPEReservation,
        eventCancellationMinutes
    );
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalHeader, setModalHeader] = useState('');

    const handleOpenModalClick = id => {
        const value = parkingValues.find(val => val.id === id);

        setModalHeader(value.modalTitle);
        setModalContent(value.modalContent);
        setOpenModal(true);
    };

    const handleCloseModalClick = () => {
        setModalHeader('');
        setModalContent('');
        setOpenModal(false);
    };

    return (
        <Box
            margin={4}
            marginTop={0}
            padding={4}
            background="green.100"
            borderRadius={8}
        >
            <Flex
                justifyContent="space-between"
                height="inherit"
                alignItems="center"
            >
                <Box>
                    {parkingValues.map(({text, id}) => (
                        <Flex key={id} alignItems="center">
                            <Icon
                                as={IconSemiCircleCheck}
                                alt="Filled Check Circle Icon"
                                fontSize="md"
                                fill="green.700"
                            />
                            <Text
                                fontSize="sm"
                                fontWeight="semibold"
                                paddingRight="1.5"
                                paddingLeft="1"
                                data-testid="SpotValueProps-parking-value"
                            >
                                {text}
                            </Text>
                            <Button
                                variant="tertiary"
                                onClick={() => handleOpenModalClick(id)}
                            >
                                <Icon
                                    alt="Info Circle Icon"
                                    as={IconInfoCircle}
                                    fontSize="sm"
                                />
                            </Button>
                        </Flex>
                    ))}
                    {openModal && (
                        <Modal onHidden={handleCloseModalClick}>
                            <div className="SpotValuePropsBareMin-Modal">
                                <ModalContent>
                                    <Text
                                        fontSize="lg"
                                        fontWeight="semibold"
                                        paddingBottom="3"
                                    >
                                        {modalHeader}
                                    </Text>
                                    {modalContent}
                                </ModalContent>
                                <ModalFooter>
                                    <Button
                                        onClick={handleCloseModalClick}
                                        variant="primary"
                                    >
                                        Okay
                                    </Button>
                                </ModalFooter>
                            </div>
                        </Modal>
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

SpotValueProps.propTypes = {
    cancellationAllowed: PropTypes.bool,
    canCancelHPEReservation: PropTypes.bool,
    eventCancellationMinutes: PropTypes.number,
};

SpotValueProps.defaultProps = {
    cancellationAllowed: true,
    canCancelHPEReservation: false,
    eventCancellationMinutes: 0,
};

export default SpotValueProps;
