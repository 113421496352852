import React from 'react';
import classNames from 'classnames';
import IconLock from '@spothero/icons/lock-filled';
import IconSpothero from '@spothero/icons/spothero';
import Link from 'router/Link';
const HeaderCheckout = ({ className }) => {
    const classes = classNames('Header', 'HeaderCheckout', className);
    return (<header className={classes} role="banner">
            <div className="Navigation container">
                <div className="Navigation-bar">
                    <Link to="/" forceAnchor>
                        <IconSpothero className="Navigation-logo"/>
                    </Link>
                    <div className="HeaderCheckout-text-with-lock">
                        <IconLock />
                        <span className="HeaderCheckout-secure-checkout">
                            Secure Checkout
                        </span>
                    </div>
                </div>
            </div>
        </header>);
};
export default HeaderCheckout;
