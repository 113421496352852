import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FilterItem from '../filter-item/FilterItem';
import { ratingSlugToColor, } from 'store/filters/rating-filter-utils';
import { isStaff } from 'utils/user-utils';
import { useFeatureVariation, OPTIMIZELY_VARIATION_REFRESHED, } from 'plugins/optimizely/hooks/use-feature-variation';
import { IMMEDIATE_FEATURES } from 'utils/experiment';
const RatingFilters = ({ activeRatingTypes, onChange, allRatings, }) => {
    const userEmail = useSelector((state) => state.user.data.email);
    const isViewRatingsFilterEnabled = useFeatureVariation(IMMEDIATE_FEATURES.VIEW_RATINGS_FILTER) ===
        OPTIMIZELY_VARIATION_REFRESHED;
    const [showRatings, setShowRatings] = useState(activeRatingTypes.length > 0);
    const showViewRatings = isStaff(userEmail) && isViewRatingsFilterEnabled;
    const onViewRatingsChange = () => {
        setShowRatings(!showRatings);
        onChange('view-ratings', !showRatings);
    };
    useEffect(() => {
        if (activeRatingTypes.length > 0) {
            setShowRatings(true);
        }
        else {
            setShowRatings(false);
        }
    }, [activeRatingTypes]);
    const styleProps = {
        marginLeft: 6,
    };
    if (!showViewRatings) {
        return null;
    }
    return (<>
            <FilterItem id="view-ratings" key="view-ratings" slug="view-ratings" label="View Ratings" defaultChecked={showRatings} showIcon onChange={onViewRatingsChange}/>
            {showRatings &&
            Object.values(allRatings).map(({ slug, name, count, active }) => {
                return (<FilterItem id={slug} key={slug} slug={slug} label={name} defaultChecked={activeRatingTypes.includes(slug)} disabled={!active} count={count} showIcon showCount onChange={onChange} styleProps={styleProps} iconColor={ratingSlugToColor(slug)}/>);
            })}
        </>);
};
export default RatingFilters;
