import React from 'react';
import classNames from 'classnames';
import FormatUtils from 'utils/format';
const Price = ({ className, amount, currencyType, showCents }) => {
    const classes = classNames('Price', className);
    const currencySymbol = FormatUtils.currencySymbol(currencyType);
    const { dollars, cents } = FormatUtils.price(amount);
    const displayCents = showCents && !cents ? '00' : cents;
    const isCredit = amount < 0;
    return (<span className={classes}>
            {isCredit && '-'}
            {currencySymbol}
            {dollars.replace('-', '')}
            {displayCents ? `.${displayCents}` : ''}
        </span>);
};
export default Price;
