export const OVERSIZE_FEE_TYPE = 'oversize_fee';

const ReservationUtils = {
    PARKING_PASS_TYPES_ENUM: {
        OPERATOR_SCAN: 'operator_scan',
        PRINTOUT: 'printout',
        LICENSE_PLATE: 'license_plate',
        SCAN_IN_OUT: 'scan_in_out',
        SCAN_IN_TICKET_OUT: 'scan_in_ticket_out',
        PULL_TICKET_SCAN_OUT: 'pull_ticket_scan_out',
        PULL_TICKET_CALL_BUTTON: 'pull_ticket_call_button',
        PULL_TICKET_SEE_ATTENDANT: 'pull_ticket_see_attendant',
        SELF_PARK_SEE_ATTENDANT: 'self_park_see_attendant',
        VALET: 'valet',
        VENDGATE: 'vendgate',
        VENDGATE_WITH_SCAN: 'vendgate_with_scan',
        HANG: 'hang',
        ACCESS_CARD: 'access_card',
        STICKER: 'sticker',
        LPR: 'lpr',
        GATED_LPR_WITH_SCAN: 'gated_lpr_with_scan',
    },

    RESERVATION_TYPES: {
        AIRPORT: 'airport',
        EVENT_PACKAGE: 'event_package',
        MONTHLY: 'monthly',
        TRANSIENT: 'transient',
        EVENT: 'event',
    },

    // Unavailable reason returned from availability when user's vehicle is too large for the facility
    VEHICLE_SIZE_EXCEEDED_REASONS: [
        'Vehicle Size Exceeded',
        'Oversize Vehicle Not Allowed',
    ],

    // array of all parking pass types that likely use license plate for redemption
    get LICENSE_PLATE_REDEMPTION_TYPES() {
        return [
            this.PARKING_PASS_TYPES_ENUM.PRINTOUT,
            this.PARKING_PASS_TYPES_ENUM.LICENSE_PLATE,
            this.PARKING_PASS_TYPES_ENUM.LPR,
            this.PARKING_PASS_TYPES_ENUM.GATED_LPR_WITH_SCAN,
        ];
    },
};

export default ReservationUtils;
