import React from 'react';
import { Button, Flex, Box, Text } from '@spothero/ui';
import DisplayPriceFormatted from 'common/display-price-formatted';
import { OversizeType } from 'utils/search-vehicle';
import useTouch from 'hooks/use-touch';
import { useShowTotal } from 'store/selectors/hooks/useShowTotal';
const variants = {
    md: {
        woDiscount: {
            fontSize: { base: 'sm', tablet: 'xs' },
        },
        price: {
            fontSize: 'md',
        },
    },
    lg: {
        woDiscount: {
            fontSize: { base: 'sm', tablet: 'sm' },
        },
        price: {
            fontSize: 'lg',
        },
    },
};
const OversizePriceSpotHero = ({ oversizeFee, powerBookingClasses, }) => (<>
        <Text as="span" className={powerBookingClasses}>
            {oversizeFee.dollars ? '+ ' : null} {oversizeFee.currencySymbol}
            {oversizeFee.dollars}
        </Text>
        <Text as="span" className={powerBookingClasses}>
            Oversize Fee
        </Text>
    </>);
const OversizePriceOnsiteKnown = ({ oversizeFee, powerBookingClasses, }) => (<>
        <Text as="span" className={powerBookingClasses}>
            {oversizeFee.dollars ? '+ ' : null} {oversizeFee.currencySymbol}
            {oversizeFee.dollars} Oversize
        </Text>
        <Text as="span" className={powerBookingClasses}>
            Fee Onsite
        </Text>
    </>);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OversizePriceUnknown = ({ oversizeFee, powerBookingClasses }) => (<Text as="span" className={powerBookingClasses}>
        Oversize Fee Onsite
    </Text>);
const oversizeMapper = {
    [OversizeType.ChargedBySpotHero]: OversizePriceSpotHero,
    [OversizeType.ChargedOnsiteKnown]: OversizePriceOnsiteKnown,
    [OversizeType.ChargedOnsiteUnknown]: OversizePriceUnknown,
};
export const Price = ({ oversizeFee, priceWithoutDiscount = {}, price = {}, priceLabel, powerBookingClasses, hasMultipleRates, parentHTMLOptionsOverride = {}, variant = 'md', onShowPriceBreakdown = null, }) => {
    const { onTouchStart, onTouchEnd } = useTouch(onShowPriceBreakdown);
    const { value: priceWithoutDiscountTotal, currencyCode: priceWithoutDiscountCurrencyCode, } = priceWithoutDiscount;
    const showTotal = useShowTotal();
    const { value, currencyCode } = price;
    const OversizeComponent = oversizeFee?.type
        ? oversizeMapper[oversizeFee.type]
        : null;
    const handleTotalClick = (e) => {
        e.stopPropagation();
        if (onShowPriceBreakdown) {
            onShowPriceBreakdown(e);
        }
    };
    const handleTouchStart = (e) => {
        e.stopPropagation();
        onTouchStart(e);
    };
    const handleTouchEnd = (e) => {
        e.stopPropagation();
        onTouchEnd(e);
    };
    return (<Flex className="FacilitySummary-price-container" data-testid="FacilitySummary-Price" flexDirection="column" alignItems="flex-end" minWidth="2.8rem" {...parentHTMLOptionsOverride}>
            <Box whiteSpace="nowrap" textAlign="right" sx={{
            sup: {
                top: '-0.3em',
                fontSize: '70%',
            },
        }}>
                {Boolean(priceWithoutDiscountTotal) && (<Box data-testid="multiday-discount" display="inline-block" verticalAlign="top" position="relative" color="text.secondary.light" marginRight="1" _after={{
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '1px',
                top: '50%',
                left: 0,
                background: 'var(--chakra-colors-text-secondary-light)',
            }} {...variants[variant].woDiscount}>
                        <DisplayPriceFormatted price={priceWithoutDiscountTotal} currencyType={priceWithoutDiscountCurrencyCode} hideCents/>
                    </Box>)}
                <Box className="FacilitySummary-FormattedPrice" display="inline-block" fontWeight="bold" lineHeight="1" {...variants[variant].price}>
                    <DisplayPriceFormatted price={value} currencyType={currencyCode}/>
                    {hasMultipleRates && <>+</>}
                </Box>
            </Box>
            {OversizeComponent && !showTotal ? (<OversizeComponent oversizeFee={oversizeFee} powerBookingClasses={powerBookingClasses}/>) : showTotal ? (typeof priceLabel === 'string' && onShowPriceBreakdown ? (<Button data-testid="PriceBreakdown-Button" variant="tertiary" onClick={handleTotalClick} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} color="text.secondary.light" textDecoration="underline" fontSize="sm" fontWeight="normal" backgroundColor="transparent !important" // Important used due to specificity of backgroundColor
        >
                        {priceLabel}
                    </Button>) : (priceLabel)) : (<Text as="span" className={powerBookingClasses}>
                    {priceLabel}
                </Text>)}
        </Flex>);
};
