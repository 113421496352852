import isArray from 'lodash/isArray';
import merge from 'lodash/merge';
import { getDocument, getWindow } from 'ssr-window';
import EnvironmentUtils from '@spothero/utils/environment';
import { getOptimizelyUserId } from './experiment';
import FESegmentUtils from '@spothero/utils/segment';
import StorageUtils from '@spothero/utils/storage';
import isEmpty from 'lodash/isEmpty';
import Config from '@/config/index';
const window = getWindow();
const document = getDocument();
const isBrowser = EnvironmentUtils.isBrowser();
const getMediaSource = () => {
    if (!isBrowser) {
        return 'Other';
    }
    // NOTE: In some cases, IE11 doesn't set document.referrer (or sets it to an empty string), so we can't destructure this.
    const search = s => document.referrer.search(s);
    if (search('https?://(.*)google.([^/?]*)') === 0) {
        return 'Google';
    }
    else if (search('https?://(.*)bing.([^/?]*)') === 0) {
        return 'Bing';
    }
    else if (search('https?://(.*)yahoo.([^/?]*)') === 0) {
        return 'Yahoo';
    }
    else if (search('https?://(.*)facebook.([^/?]*)') === 0) {
        return 'Facebook';
    }
    else if (search('https?://(.*)twitter.([^/?]*)') === 0) {
        return 'Twitter';
    }
    else {
        return 'Other';
    }
};
const withLoggedInStatus = () => {
    const traits = FESegmentUtils.getUserTraits();
    return traits?.email ? { user_logged_in: true } : { user_logged_in: false };
};
export const getOS = () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes('Android'))
        return 'Android';
    if (userAgent.includes('iPhone') || userAgent.includes('iPad'))
        return 'iOS';
    if (userAgent.includes('Mac OS X'))
        return 'macOS';
    if (userAgent.includes('Windows'))
        return 'Windows';
    if (userAgent.includes('Linux'))
        return 'Linux';
    return 'Unknown OS';
};
const withDevice = () => {
    const { innerHeight: height, innerWidth: width } = window;
    const devicePlatform = Config.isMobile ? 'mobile web' : 'desktop web';
    const screenDimensions = { height, width };
    return {
        'device platform': devicePlatform,
        device_platform: devicePlatform,
        'screen dimensions': screenDimensions,
        screen_dimensions: screenDimensions,
    };
};
/**
 * Structures optimizely data for use in additionalOptions
 *
 * @static
 * @function withOptimizely
 * @example
 * withOptimizely()
 * @returns {object} [{}] - Optimizely object to merge into additionalOptions
 */
const withOptimizely = () => ({
    Optimizely: {
        userId: getOptimizelyUserId(),
    },
});
const enhanceData = data => {
    const newData = isArray(data) ? data : [data];
    return newData.map(item => ({
        ...item,
        additionalOptions: {
            ...merge(item.additionalOptions, {
                context: { app: { build: Config.release } },
            }),
            ...withOptimizely(),
        },
        properties: {
            ...item.properties,
            ...withDevice(),
            ...withLoggedInStatus(),
        },
    }));
};
let ttfrTimer = 0;
let ttfrInterval = null;
let isTrackingFirstLoad = false;
/**
 * Utilities for working with Segment.
 * https://spothero.com/uniform/docs/module-SegmentUtils.html
 * https://segment.com/docs/sources/website/analytics.js/
 *
 * @module SegmentUtils
 */
const SegmentUtils = {
    /**
     * It is not necessary to manually identify an anonymous user
     * Should be called before FESegmentUtils.getId
     * If passed a user, sends user id and traits to Segment
     * If not passed a user, resets the Segment user and clears traits
     *
     * @static
     * @function identify
     * @param {object} user - the current user
     * @returns {void}
     */
    identify(user) {
        // Log in or user change
        if (!isEmpty(user)) {
            const segmentTraits = {
                'media source': getMediaSource(),
                'user id': user.id,
                email: user.email,
                created: user.dateJoined,
                name: user.displayName,
                'new user': user.justCreated,
                'referrer code': user.referralToken,
                staff: user.admin,
            };
            FESegmentUtils.onReady(() => {
                FESegmentUtils.identify({
                    uniqueId: user.id,
                    // Only send traits with values
                    traits: Object.entries(segmentTraits).reduce((traits, [key, value]) => ({
                        ...traits,
                        ...(value && { [key]: value }),
                    }), {}),
                    options: {
                        anonymousId: FESegmentUtils.getAnonymousId(),
                    },
                });
            });
        }
        else {
            // Log out
            // - Resets the id, including anonymousId, and clear traits for the currently identified user and group
            FESegmentUtils.reset();
        }
    },
    /**
     * Adds traits to an existing or anonymous user.
     *
     * @function addTraits
     * @param {object} traits - Segment traits
     * @returns {void}
     */
    addTraits(traits) {
        FESegmentUtils.onReady(() => {
            FESegmentUtils.identify({
                traits,
            });
        });
    },
    /**
     * Sends page view data to Segment.
     *
     * @static
     * @function page
     * @param {object} [data={}] - The data to send to segment.
     * @param {string} data.name - The page name.
     * @param {object} [data.properties=null] - The page properties object. See the properties object information <a href="https://segment.com/docs/spec/page/#properties" target="_blank" rel="noopener noreferrer">here</a> for details.
     * @param {object} [data.additionalOptions=null] - Additional options to send with the event.
     * @example
     * SegmentUtils.page({
     *     name: 'Web Landing',
     *     properties: {
     *          referrer: referrer || document.referrer,
     *          'referrer affiliate': referrerAffiliate
     *     }
     * });
     * @returns {void}
     */
    page(data) {
        FESegmentUtils.onReady(() => {
            enhanceData(data).forEach(d => FESegmentUtils.page(d));
        });
    },
    /**
     * Sends tracking event data to Segment.
     *
     * @static
     * @function track
     * @param {object} [data={}] - The data to send to segment.
     * @param {string} data.event - The event name.
     * @param {object} [data.properties=null] - The event properties object. See the properties object information <a href="https://segment.com/docs/spec/track/#properties" target="_blank" rel="noopener noreferrer">here</a> for details.
     * @param {object} [data.additionalOptions=null] - Additional options to send with the event.
     * @example
     * SegmentUtils.track({
     *     event: 'Searched',
     *     properties: {
     *         city: 'Chicago'
     *     }
     * });
     * @returns {void}
     */
    track(data) {
        FESegmentUtils.onReady(() => {
            enhanceData(data).forEach(d => FESegmentUtils.track(d));
        });
    },
    /**
     * Starts a timer to help decide how long it takes to show the first result on a search map.
     *
     * @static
     * @function startTimeToFirstResultTimer
     * @param {object} [data={}] - Optional data to help accumulate the timer correctly if initial load was performed at a search map page.
     * @param {boolean} [data.isInitialLoad=false] - Whether the timer is starting on initial page load at a search map page.
     * @param {number} [data.timerAddition=0] - The additional time to add to the timer from a global perspective on page load only.
     * @example
     * SegmentUtils.startTimeToFirstResultTimer();
     * @returns {void}
     */
    startTimeToFirstResultTimer({ isInitialLoad = false, timerAddition = 0, } = {}) {
        // if its already defined that means it was started by the initial page load that landed on search, just let it continue
        if (ttfrInterval) {
            return;
        }
        const ttfrStart = Date.now();
        isTrackingFirstLoad = isInitialLoad;
        ttfrTimer = 0;
        window.clearInterval(ttfrInterval);
        ttfrInterval = window.setInterval(() => {
            ttfrTimer = Date.now() - ttfrStart + timerAddition;
        }, 100);
    },
    /**
     * Sends a tracking event to Segment to signify how long it took to show the first result on a search map.
     *
     * @static
     * @function trackTimeToFirstResult
     * @example
     * SegmentUtils.trackTimeToFirstResult();
     * @returns {void}
     */
    trackTimeToFirstResult() {
        window.clearInterval(ttfrInterval);
        ttfrInterval = null;
        this.track({
            event: 'Time To First Result',
            properties: {
                /* eslint-disable camelcase */
                full_page_load: isTrackingFirstLoad,
                time_elapsed: ttfrTimer,
                /* eslint-enable camelcase */
            },
        });
        StorageUtils.set('sh-ttfr-tracked', true, 'session');
    },
    /**
     * Waits for Segment SDK to load and then runs function.
     *
     * @static
     * @function waitForSegmentThenLoad
     * @param {number} maxWaitTime - Amount of time to wait for Segment SDK to load (in milliseconds)
     * @param {Function} loadFunction - function to be run after waiting for Segment SDK
     * @param {object} objectToPass - Object to pass to the loadFunction (probably for tracking)
     * @example
     * SegmentUtils.waitForSegmentThenLoad(
     *      5000,
     *      loadSpots,
     *      {searchTrackingAction: 'EVENT'}
     * )
     * @returns {void}
     */
    waitForSegmentThenLoad(maxWaitTime, loadFunction, objectToPass = null) {
        const runFunction = () => {
            if (objectToPass) {
                loadFunction(objectToPass);
            }
            else {
                loadFunction();
            }
        };
        // if we have Segment enabled and expect it to run
        if (Config.isSegmentEnabled && Config.isDeployed) {
            // It is possible that the Segment SDK fails for some
            // reason and our onReady callback never gets called.
            // Set a timeout in that case, for the maximum
            // amount of time we want to wait for Segment to be ready.
            const segmentReadyTimeoutID = setTimeout(() => {
                runFunction();
            }, maxWaitTime);
            FESegmentUtils.onReady(() => {
                // clear the timeout, since Segment is ready now
                clearTimeout(segmentReadyTimeoutID);
                // It is technically possible that this happens after the
                // timeout function has been executed.
                // Is it worth solving for?
                runFunction();
            });
        }
        else {
            // else we are not enabling Segment at all
            // so go ahead and just the function
            runFunction();
        }
    },
};
export default {
    ...FESegmentUtils,
    ...SegmentUtils,
};
